import {TransferPickupRegistration} from '../interfaces/TransferPickupRegistration';

let transferPickupRegistration ={} as TransferPickupRegistration;

export const TransferPickupRegistrationctx = {
  
    intialize: (transferPickupReg:TransferPickupRegistration) => {
        transferPickupRegistration=transferPickupReg;
     },

     getTransferPickupRegistrationctx:()=>{
        return transferPickupRegistration
     }

     

}