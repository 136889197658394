import {
    DeliveryPresentDTO, General, MarginsDTO, ReferralCommissionDTO, RegistrationDTO, VAT
} from '../interfaces/GeneralData';

let general = {} as General;
let hasVariablesModified = false;

export interface variablesError {
   general: Array<string>;
   parts: Array<string>;
   models: Array<string>;
   rvm: Array<string>;
   transfer: Array<string>;
   paw: Array<string>;
}

let errors = {
   general: [],
   parts: [],
   models: [],
   rvm: [],
   transfer: [],
   paw: [],
} as variablesError;

export const Generalctx = {
   intialize: (generalData: General) => {
      general = generalData;
   },

   getGeneralctx: () => {
      return general;
   },

   getErrors: () => {
      return errors;
   },

   setErrors: (fieldId : string, section: string) => {

      switch(section) {
         case "general":
            break;

         case "parts":
            break;

         case "models":
            break;

         case "rvm":
            break;

         case "transfer":
            break;

         case "paw":
            let pawErrors = errors.paw;
            if (pawErrors.length > 0) {
            const index  = pawErrors.indexOf(fieldId);
            if(index > -1) {
               pawErrors.splice(index);
            }
            } else {
               pawErrors = [fieldId];
            }
            break;
      }
    }
};
