import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { Stack, Tooltip } from "@mui/material";
import {
  IconName,
  PButton,
  PDivider,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PIcon,
  PPopover,
  PSelectWrapper,
  PSwitch,
  PText,
  PTextFieldWrapper,
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../../../assets/styles/CustomStyles";
import { TradeInOptions } from "../../../../../configs/Enums";
import { OrderContext } from "../../../../../contexts/OrderContext";
import {
  AdditionalCostsPriceDTO,
  CalculationDraftDTO,
  CalculationPaymentDetailsDTO,
  CommissionSharingDTO,
  CommissionSharingUserDetailsDTO,
  DiscountNetPriceDTO,
  KickbackNetPriceDTO,
  TradeInPriceDTO,
  VehiclePriceDTO,
} from "../../../../../interfaces/Calculation";
import {
  MarginsDTO,
  ReferralCommissionDTO,
} from "../../../../../interfaces/GeneralData";
import { Model } from "../../../../../interfaces/Model";
import { OrderDTO } from "../../../../../interfaces/Order";
import { PDSFileDTO } from '../../../../../interfaces/Parts';
import { TransferPickupRegistration } from "../../../../../interfaces/TransferPickupRegistration";
import {
  UpsaleDetailsDTO,
  UpsaleItemDTO,
} from "../../../../../interfaces/UpsaleNew";
import { UserDetailsDTO } from "../../../../../interfaces/User";
import { GetPaw, GetRvm } from "../../../../../interfaces/Variables";
import {
  CategoryCommissionDTO,
  CommissionScalingDTO,
  VehicleCommissionDTO,
} from "../../../../../interfaces/commission";
import {
  formatCurrency,
  formatCurrencyToDecimal,
  formatCurrencyWithNoUnit,
  formatToWholeNumber,
  getPayementTypeName,
  getReferenceType,
  getTradeInHeaderLabel,
  getTradeInName,
  getUpsaleItemsTotal,
  getWorkshopServiceName,
  negativeValueHandler
} from "../../../../../mock/helper";
import { type } from "../../../../../services/Constants";
import {
  dcsGetGeneralTabs,
  dcscommissionScalingByUser,
  dcsgetCityBasedUsers,
  dcsgetModels,
  dcsgetParts,
  dcsgetPaw,
  dcsgetRvm,
  dcsgetTransferPickupRegistrationData,
  dcsgetVat
} from "../../../../../services/data/Helpers";
import { CustomDelete } from "../../../../common/CustomDelete";
import getSessionStorage from "../../../../../helpers/getSessionStorage";
import { Constant } from "../../../../../configs/Constants";

interface Props {
  onNextTabChange: (i: number) => void;
  changedMode: string;
  refreshHeader: (cdd: CalculationDraftDTO) => void;
  handleModified: () => void;
  setStateCalDraft: (state: CalculationDraftDTO) => void;
}

export const DemoCarSingle = (props: Props): JSX.Element => {
  const orderContext = useContext(OrderContext);
  const orderValues = getSessionStorage(Constant.SingleOrdDetail); 
  const [fileInfo, setFileInfo] = useState<PDSFileDTO>({} as PDSFileDTO);
  const [finalTotalCammission, setFinalTotalCom] = useState<UpsaleDetailsDTO>(orderContext.getCtxUpsaleDetails() as UpsaleDetailsDTO);
  const { t } = useTranslation();
  const {
    onNextTabChange,
    changedMode,
    refreshHeader,
    handleModified,
    setStateCalDraft,
  } = props;
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [calDraft, setCalDraft] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );
  const [vehiclePrices, setVehiclePrices] = useState<Array<VehiclePriceDTO>>(
    []
  );
  const [discountNetPrices, setDiscountNetPrices] = useState<
    Array<DiscountNetPriceDTO>
  >([]);
  const [kickbackNetPrices, setKickbackNetPrices] = useState<
    Array<KickbackNetPriceDTO>
  >([]);
  const [additionalCosts, setAdditionalCosts] = useState<
    Array<AdditionalCostsPriceDTO>
  >([]);
  const [workshopCosts, setWorkshopCosts] = useState<UpsaleDetailsDTO>(
    {} as UpsaleDetailsDTO
  );
  const [tradeInFields, setTradeInFields] = useState<Array<TradeInPriceDTO>>(
    []
  );
  const [calPaymentDetails, setCalPaymentDetails] =
    useState<CalculationPaymentDetailsDTO>({} as CalculationPaymentDetailsDTO);
  const [category, setCategory] = useState("");
  const [vat, setVat] = useState(0.0);
  const [reload, setReload] = useState("");
  const [gross, setGross] = useState("");
  const [margins, setMargins] = useState<Array<MarginsDTO>>([]);
  const [referals, setReferals] = useState<Array<ReferralCommissionDTO>>([]);
  const [models, setModels] = useState<Array<Model>>([]);
  const [rvmData, setRvmData] = useState<Array<GetRvm>>([]);
  const [rvm, setRvm] = useState<GetRvm>({} as GetRvm);
  const [searchParams, setSearchParams] = useSearchParams();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isValid, setIsValid] = useState(false);
  const [openExit, setOpenExit] = useState(false);
  const [purchaseMargin, setPurchaseMargin] = useState(0.0);
  const [vpPercent, setVpPercent] = useState(0.0);
  const [scalingobj, setScalingobj] = useState<CommissionScalingDTO>(
    {} as CommissionScalingDTO
  );
  const [commissionObj, setCommissionObj] = useState<CategoryCommissionDTO>(
    {} as CategoryCommissionDTO
  );
  const [parts, setParts] = useState<Array<UpsaleItemDTO>>([]);
  const [tires, setTires] = useState<Array<UpsaleItemDTO>>([]);
  const [discount, setDiscount] = useState(0.0);
  const [showErrorCarInternal, setShowErrorCarInternal] = useState(false);
  const [showErrorCarCustomer, setShowErrorCarCustomer] = useState(false);
  const [showCs, setShowCs] = useState(false);
  const [csError, setCsError] = useState(false);
  const [sellers, setSellers] = useState<Array<UserDetailsDTO>>([]);
  const [orderCreator, setOrderCreator] = useState("");
  const [commissionType, setCommissionType] = useState("");
  const [canViewCommission, setCanViewCommission] = useState(false);
  const [upsaleTotalCommission, setUpsaleTotalCommission] = useState(0)
  const [showFixedCommission, setShowFixedCommission] = useState(false)
  const [final, setFinal] = useState({
    invoiceNet: 0.0,
    invoiceGross: 0.0,
    preNet: 0.0,
    preGross: 0.0,
    paymentNet: 0.0,
    paymentGross: 0.0,
  });

  const inputFieldMaxLength:number = 15

  const vp = [
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
  ];
  const mRef = useRef<HTMLInputElement>(null);
  const bpRef = useRef<HTMLInputElement>(null);
  const dref = [
    {
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
    {
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
  ];
  const kbRef = [
    {
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
    {
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
  ];
  const acRef = [
    {
      net: useRef<HTMLInputElement>(null),
      share: useRef<HTMLInputElement>(null),
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      share: useRef<HTMLInputElement>(null),
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      share: useRef<HTMLInputElement>(null),
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      share: useRef<HTMLInputElement>(null),
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      share: useRef<HTMLInputElement>(null),
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
  ];
  const cwtRef = useRef<HTMLInputElement>(null);
  const cwtServiceRef = [
    { share: useRef<HTMLInputElement>(null) },
    { share: useRef<HTMLInputElement>(null) },
  ];
  const pdsRef = useRef<HTMLInputElement>(null);
  const pdsServiceRef = useRef<HTMLInputElement>(null);
  const tRef = [
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
  ];
  const dpRef = [
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
  ];
  const upRef = [
    {
      net: useRef<HTMLInputElement>(null),
      gross: useRef<HTMLInputElement>(null),
    },
  ];
  const csRef = [
    {
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
    {
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
    {
      euro: useRef<HTMLInputElement>(null),
      percent: useRef<HTMLInputElement>(null),
    },
  ];
  const pfsRef = useRef<HTMLInputElement>(null);
  const cpRef = useRef<HTMLInputElement>(null);
  const pcsRef = useRef<HTMLInputElement>(null);
  const iRef = useRef<HTMLInputElement>(null);
  const vcRef = useRef<HTMLInputElement>(null);
  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }
  const getPartTotals = () => {
    let usDetails = orderContext.getCtxUpsaleDetails();
    const partsTotals = {
      amountNet: 0.0,
      customerShare: 0.0,
      discountEuro: 0.0,
      discountPercent: 0.0,
      serviceNet: 0.0,
      serviceShare: 0.0,
      serviceEuro: 0.0,
      servicePercent: 0.0,
    };
    let partsData = usDetails.upsaleItems.filter((x) => x.partType === "part");
    partsData.map((item, i) => {
      //
      partsTotals.customerShare = item.customerShare
        ? partsTotals.customerShare + item.customerShare
        : partsTotals.customerShare + 0.0;

      if (item.customerShare > 0) {
        partsTotals.amountNet = item.part.priceBeforeCustomerNet
          ? partsTotals.amountNet + item.part.priceBeforeCustomerNet
          : partsTotals.amountNet + 0.0;
      } else {
        partsTotals.amountNet = item.part.priceInternal
          ? partsTotals.amountNet + item.part.priceInternal
          : partsTotals.amountNet + 0.0;
      }

      if (item.serviceItems && item.serviceItems.length > 0) {
        partsTotals.serviceShare = +item.serviceItems[0].customerShare;
        if (
          item.serviceItems[0].customerShare > 0 &&
          item.serviceItems[0].timeUnits
        ) {
          partsTotals.serviceNet =
            (+item.serviceItems[0].services.priceBeforeCustomerNet *
              item.serviceItems[0].timeUnits) /
            100;
        } else if (item.serviceItems[0].timeUnits) {
          partsTotals.serviceNet =
            (+item.serviceItems[0].services.priceInternal *
              item.serviceItems[0].timeUnits) /
            100;
        }
      }
    });

    if (partsData && partsData.length > 0) {
      if (partsTotals.amountNet > 0) {
        partsTotals.discountEuro =
          partsTotals.amountNet - partsTotals.customerShare;
        //partsTotals.discountPercent = partsTotals.discountEuro / partsTotals.amountNet * 100;
        let cdd = {} as CalculationDraftDTO;
        
        switch (category) {
          case "NewCar":
            cdd = orderContext.getCtxCalculationDraftDetails("NewCar");
            
            
            if (cdd.vehiclePrices && cdd.vehiclePrices[2].amountNet > 0) {
              partsTotals.discountPercent =
                (partsTotals.discountEuro / cdd.vehiclePrices[2].amountNet) *
                100;
            } else {
              partsTotals.discountPercent = 0.0;
            }
            break;
          case "DemoCar":
            cdd = orderContext.getCtxCalculationDraftDetails("DemoCar");
            if (cdd.vehiclePrices && cdd.vehiclePrices[0].amountNet > 0) {
              partsTotals.discountPercent =
                (partsTotals.discountEuro / cdd.vehiclePrices[0].amountNet) *
                100;
            } else {
              partsTotals.discountPercent = 0.0;
            }
            break;

          case "UsedCar":
            cdd = orderContext.getCtxCalculationDraftDetails("UsedCar");
            if (cdd.vehiclePrices && cdd.vehiclePrices[0].amountNet > 0) {
              partsTotals.discountPercent =
                (partsTotals.discountEuro / cdd.vehiclePrices[0].amountNet) *
                100;
            } else {
              partsTotals.discountPercent = 0.0;
            }
            break;
        }
      }

      if (partsTotals.serviceNet > 0) {
        partsTotals.serviceEuro =
          partsTotals.serviceNet - partsTotals.serviceShare;
        //partsTotals.servicePercent = partsTotals.serviceEuro / partsTotals.serviceNet * 100;
        let cdd = {} as CalculationDraftDTO;
        
        switch (category) {
          case "NewCar":
            cdd = orderContext.getCtxCalculationDraftDetails("NewCar");
            
            
            if (cdd.vehiclePrices && cdd.vehiclePrices[2].amountNet > 0) {
              partsTotals.servicePercent =
                (partsTotals.serviceEuro / cdd.vehiclePrices[2].amountNet) *
                100;
            } else {
              partsTotals.servicePercent = 0.0;
            }
            break;
          case "DemoCar":
            cdd = orderContext.getCtxCalculationDraftDetails("DemoCar");
            if (cdd.vehiclePrices && cdd.vehiclePrices[0].amountNet > 0) {
              partsTotals.servicePercent =
                (partsTotals.serviceEuro / cdd.vehiclePrices[0].amountNet) *
                100;
            } else {
              partsTotals.servicePercent = 0.0;
            }
            break;

          case "UsedCar":
            cdd = orderContext.getCtxCalculationDraftDetails("UsedCar");
            if (cdd.vehiclePrices && cdd.vehiclePrices[0].amountNet > 0) {
              partsTotals.servicePercent =
                (partsTotals.serviceEuro / cdd.vehiclePrices[0].amountNet) *
                100;
            } else {
              partsTotals.servicePercent = 0.0;
            }
            break;
        }
      }
    }

    return partsTotals;
  };

  useEffect(() => {
    setStateCalDraft(calDraft);
  }, [calDraft]);

  useEffect(() => {
    const id = orderValues?.id ? orderValues?.id+'' :'';
    const mode = orderValues?.mode ? orderValues?.mode+'' :''
    // setMode(mode)
    if (id && (mode === "view" || mode === "edit")) {
      if (mode === "view") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, []);

  useEffect(() => {
    if (changedMode === "edit") {
      setDisabled(false);
    } else if (changedMode === "view") {
      setDisabled(true);
    }
  }, [changedMode]);

  useEffect(() => {
    const init = async () => {
      const vatResponse = await dcsgetVat();
      if(vatResponse.status===type.SUCCESS){

        setVat(vatResponse.response?.value);
      }
      
      let scaleResponse = await dcscommissionScalingByUser(
        orderContext.getCtxOrderDetails().orderCreator.id
      );
      if (scaleResponse.status===type.SUCCESS) {
        setScalingobj(scaleResponse.response);
      }

      const genResp = await dcsGetGeneralTabs();
      if(genResp.status===type.SUCCESS){
        const general = genResp.response;
        if (general?.margins) {
          setMargins(general.margins);
          let mrgs = general.margins as Array<MarginsDTO>;
          const mrg = mrgs.filter(
            (x) => x.categoryType === category && x.marginType === "Commission"
          );
          if (mrg.length > 0) {
            setPurchaseMargin(mrg[0].value);
          }
        }
      }
      //
     
    };
    init();
  }, []);

  useEffect(() => {
    const mrg = margins.filter(
      (x) => x.categoryType === "DemoCar" && x.marginType === "Commission"
    );
    if (mrg.length > 0) {
      setPurchaseMargin(mrg[0].value);
    }
  });

  useEffect(() => {
    
    let ord = orderContext.getCtxOrderDetails();
    setOrder(ord);
    if (ord?.vehicleDetails?.category?.type) {
      setCategory(ord?.vehicleDetails?.category?.type);
      let cdd = orderContext.getCtxCalculationDraftDetails(
        ord?.vehicleDetails?.category?.type
      );
      if (
        cdd.calculationType !== undefined &&
        cdd.calculationType.length === 0
      ) {
        cdd.calculationType = "DemoCar";
        orderContext.updateCtxCalculationDraftDetails(
          cdd,
          ord?.vehicleDetails?.category?.type
        );
      }
      

      const init = async () => {
        const apiResponse = await dcsGetGeneralTabs();
        if(apiResponse.status===type.SUCCESS){
          const general = apiResponse.response;
          if (general?.margins) {
            setMargins(general.margins);
          }
  
          //handling referral type of order details
          if (general?.referallCommissions) {
            //
            setReferals(general.referallCommissions);
            if (
              ord?.referral &&
              (ord?.referral?.referralType === "External_Private" ||
                ord?.referral?.referralType === "External_Common")
            ) {
              
              const referals =
                general.referallCommissions as Array<ReferralCommissionDTO>;
              const refer = referals.filter((x) => x.referralType === "DemoCar");
              //
              if (
                cdd.discountNetPrices &&
                cdd.discountNetPrices[0].discountInPercent === 0.0 &&
                refer.length > 0
              ) {
                cdd.discountNetPrices[0].discountInPercent = refer[0].value;
  
                if (dref[0] && dref[1].percent && dref[0].percent.current) {
                  dref[0].percent.current.value = formatCurrencyWithNoUnit(
                    refer[0].value
                  );
                }
                if (cdd.vehiclePrices) {
                  cdd.discountNetPrices[0].discountInEuro =
                    (cdd.vehiclePrices[0].amountNet * refer[0].value) / 100;
                  if (dref[0] && dref[0].euro && dref[0].euro.current) {
                    dref[0].euro.current.value = formatCurrencyWithNoUnit(
                      cdd.discountNetPrices[0].discountInEuro
                    );
                  }
                }
              }
            } else {
              //
              if (cdd.vehiclePrices && cdd.discountNetPrices) {
                cdd.discountNetPrices[0].discountInEuro = 0.0;
                cdd.discountNetPrices[0].discountInPercent = 0.0;
              }
            }
            orderContext.updateCtxCalculationDraftDetails(cdd, "DemoCar");
          }
  
          // Transfer and pickup values
          const trasferResp = await dcsgetTransferPickupRegistrationData();
          let transferPickupRegistrationdata ={
            registrations:[],
            transferPickups:[],
        } 
          if(trasferResp.status===type.SUCCESS){
            transferPickupRegistrationdata= trasferResp.response
          }
  
          const pawResponse = await dcsgetPaw();
          let pawRes = []
          if(pawResponse.status===type.SUCCESS){
            pawRes = apiResponse.response
          }
          
          // setPawData(pawRes);
          // setTransferPickupRegistration(transferPickupRegistration);
  
          const modelResp = await dcsgetModels("");
          
          if (modelResp.status===type.SUCCESS) {
            setModels(modelResp.response);
          }
  
          // Upsale, workshop details
          let usDetails = orderContext.getCtxUpsaleDetails();
          const parts = usDetails.upsaleItems?.filter(
            (x) => x.partType === "part"
          );
          setParts(parts);
          const tires = usDetails.upsaleItems?.filter(
            (x) => x.partType === "tire"
          );
          setTires(tires);
          setWorkshopCosts(usDetails);
          cdd.workshopCosts = usDetails;
  
          // TradeIn
          if (ord?.tradeInDetails?.tradeInType) {
            cdd.tradeInTitle = ord?.tradeInDetails?.tradeInType;
          } else {
            cdd.tradeInTitle = null;
          }
          if (cdd.tradeInPrices) {
            setTradeInFields(cdd.tradeInPrices);
          } else {
            const tradeInPrices = [
              {
                displayLabel: "Car value (internal)",
                tradeInColumn: "CarValueInternal",
                amountNet: 0.0,
                amountGross: 0.0,
              } as unknown as TradeInPriceDTO,
              {
                displayLabel: "Car value (customer)",
                tradeInColumn: "CarValueCustomer",
                amountNet: 0.0,
                amountGross: 0.0,
              } as unknown as TradeInPriceDTO,
              {
                displayLabel: "Profit booking",
                tradeInColumn: "ProfitBooking",
                amountNet: 0.0,
                amountGross: 0.0,
              } as unknown as TradeInPriceDTO,
              {
                displayLabel: "Share PC",
                tradeInColumn: "SharePc",
                amountNet: 0.0,
                amountGross: 0.0,
              } as unknown as TradeInPriceDTO,
              {
                displayLabel: "Share customer",
                tradeInColumn: "ShareCustomer",
                amountNet: 0.0,
                amountGross: 0.0,
              } as unknown as TradeInPriceDTO,
            ];
            setTradeInFields(tradeInPrices);
          }
  
          // Payment Details
          if (cdd.calculationPaymentDetails) {
            cdd.calculationPaymentDetails.paymentType =
              ord.paymentDetails.paymentType;
            setCalPaymentDetails(cdd.calculationPaymentDetails);
          }
          if (dpRef[0].net.current) {
            dpRef[0].net.current.value = cdd?.calculationPaymentDetails
              ?.paymentNet
              ? formatCurrencyWithNoUnit(
                  cdd?.calculationPaymentDetails?.paymentNet
                )
              : "0,00";
          }
          if (dpRef[0].gross.current) {
            dpRef[0].gross.current.value = cdd?.calculationPaymentDetails
              ?.paymentGross
              ? formatCurrencyWithNoUnit(
                  cdd?.calculationPaymentDetails?.paymentGross
                )
              : "0,00";
          }
          if (upRef[0].net.current) {
            upRef[0].net.current.value = cdd?.calculationPaymentDetails
              ?.upFrontPaymentNet
              ? formatCurrencyWithNoUnit(
                  cdd?.calculationPaymentDetails?.upFrontPaymentNet
                )
              : "0,00";
          }
          if (upRef[0].gross.current) {
            upRef[0].gross.current.value = cdd?.calculationPaymentDetails
              ?.upFrontPaymentGross
              ? formatCurrencyWithNoUnit(
                  cdd?.calculationPaymentDetails?.upFrontPaymentGross
                )
              : "0,00";
          }
  
          // Final calculation
          const cityResp = await dcsgetCityBasedUsers();
          if (
           cityResp.status===type.SUCCESS &&
            cityResp.response > 0
          ) {
            const users = cityResp.response as Array<UserDetailsDTO>;
            setSellers(users.filter((x) => x.id !== ord.orderCreator.id));
            const user = users.filter((x) => x.id === ord.orderCreator.id);
            
            if (user.length > 0 && user[0].ppnName) {
              setOrderCreator(user[0].ppnName);
            }
          }
  
          setShowCs(cdd.isCommissionSharingActive);
  
          if (cpRef.current) {
            cpRef.current.value = formatCurrencyWithNoUnit(
              cdd?.commissionCalculation?.carPolicyCommission
                ? cdd.commissionCalculation.carPolicyCommission
                : 0.0
            );
          }
          if (pcsRef.current) {
            pcsRef.current.value = formatCurrencyWithNoUnit(
              cdd?.commissionCalculation?.porscheCardS
                ? cdd.commissionCalculation.porscheCardS
                : 0.0
            );
          }
          if (pfsRef.current) {
            pfsRef.current.value = formatCurrencyWithNoUnit(
              cdd?.commissionCalculation?.pfsCommission
                ? cdd.commissionCalculation.pfsCommission
                : 0.0
            );
          }
          if (iRef.current) {
            iRef.current.value = formatCurrencyWithNoUnit(
              cdd?.commissionCalculation?.incentive
                ? cdd.commissionCalculation.incentive
                : 0.0
            );
          }
          if (vcRef.current) {
            vcRef.current.value = formatCurrencyWithNoUnit(
              cdd.commissionCalculation?.vehicleCommissionEuro
                ? cdd.commissionCalculation.vehicleCommissionEuro
                : 0.0
            );
          }
          if (cdd.isCommissionSharingActive && cdd.commissionSharing) {
            if (
              cdd.commissionSharing[0].sellerNames.id === undefined ||
              cdd.commissionSharing[0].sellerNames.id === null
            ) {
              cdd.commissionSharing[0].sellerNames = {
                id: ord.orderCreator?.id,
              } as CommissionSharingUserDetailsDTO;
              orderContext.updateCtxCalculationDraftDetails(cdd, "DemoCar");
            }
          }
  
          const rvmResp = await dcsgetRvm();
          if(rvmResp.status===type.SUCCESS){
            setRvmData(rvmResp.response);
          }
          orderContext.updateCtxCalculationDraftDetails(
            cdd,
            ord?.vehicleDetails?.category?.type
          );
          setVehiclePrices(cdd?.vehiclePrices!);
          setDiscountNetPrices(cdd?.discountNetPrices!);
          setKickbackNetPrices(cdd?.kickbackNetPrices!);
  
          setAdditionalCosts(cdd?.additionalCostsPrices!);
          loadAdditionalCosts(
            transferPickupRegistrationdata,
            cdd?.additionalCostsPrices!,
            pawRes
          );
          // let usDetails = orderContext.getCtxUpsaleDetails();
          // setUpsale(usDetails);
          setTradeInFields(cdd?.tradeInPrices!);
          //Calculate Header
          //calculateHeader(cdd);
          setCalDraft(cdd);
          
          const sessionUser = sessionStorage.getItem("userDetails");
          if (sessionUser) {
            const user = JSON.parse(sessionUser);
            let viewCommission = user.canViewOrderCommissions
              ? user.canViewOrderCommissions
              : false;
            if (ord?.orderCreator?.id === user.id) viewCommission = true;
            setCanViewCommission(viewCommission);
          }
          setReload(new Date().toLocaleString());
        }
        
      };
      init();
    } else {
      
    }
  }, []);

  useEffect(() => {
    let ord = orderContext.getCtxOrderDetails();
    setOrder(ord);
    if (ord?.vehicleDetails?.category?.type) {
      setCategory(ord?.vehicleDetails?.category?.type);
      let cdd = orderContext.getCtxCalculationDraftDetails(
        ord?.vehicleDetails?.category?.type
      );
      //setCalDraft(cdd);
      setVehiclePrices(cdd?.vehiclePrices!);
      setDiscountNetPrices(cdd?.discountNetPrices!);
      setKickbackNetPrices(cdd?.kickbackNetPrices!);
      setAdditionalCosts(cdd?.additionalCostsPrices!);
      setWorkshopCosts(cdd?.workshopCosts!);
      setCalPaymentDetails(cdd?.calculationPaymentDetails!);
      setShowCs(cdd.isCommissionSharingActive);
      //Calculate Header
      calculateHeader(cdd);
    }
  }, [reload, scalingobj, rvm, models, margins, purchaseMargin, vpPercent]);

  const handleAmountNet = (value: string, index: number, elementId: string) => {
    // if (validateCurrencyFormat(value)) {
    vehiclePrices[index].amountNet = formatCurrencyToDecimal(value);
    vehiclePrices[index].amountGross =
      formatCurrencyToDecimal(value) * (1 + vat / 100);

    // Purchase price calculation
    vehiclePrices[2].amountNet = getPurchasePriceNet();
    vehiclePrices[2].amountGross = getPurchasePriceGross();

    // Discount calculation
    vehiclePrices[3].amountNet =
      vehiclePrices[0].amountNet - vehiclePrices[1].amountNet;
    vehiclePrices[3].amountGross =
      vehiclePrices[0].amountGross - vehiclePrices[1].amountGross;

    if (vehiclePrices[0].amountNet > 0) {
      let vpp = (vehiclePrices[3].amountNet / vehiclePrices[0].amountNet) * 100;
      setVpPercent(vpp);
    }
    switch (index) {
      case 0:
        if (vp[0].gross.current) {
          vp[0].gross.current.value = formatCurrencyWithNoUnit(
            vehiclePrices[index].amountGross
          );
        }
        break;
      case 1:
        if (vp[1].gross.current) {
          vp[1].gross.current.value = formatCurrencyWithNoUnit(
            vehiclePrices[index].amountGross
          );
        }
        break;
      case 2:
        if (vp[2].gross.current) {
          vp[2].gross.current.value = formatCurrencyWithNoUnit(
            vehiclePrices[index].amountGross
          );
        }
        break;
    }
    if (index == 0) {
      updateDiscountForRRPValue();
      updateKickBackBasedOnRRP();
      updateAdditionalCostsBasedRRP();
    }

    let cal = orderContext.getCtxCalculationDraftDetails(category);
    cal.vehiclePrices = vehiclePrices;
    orderContext.updateCtxCalculationDraftDetails(cal, category);
    
    setVehiclePrices(vehiclePrices);
    setCalDraft(orderContext.getCtxCalculationDraftDetails(category));
    //Calculate Header
    calculateHeader(cal);
    handleModified();
    setReload(new Date().toLocaleString());
    // } else {
    //   
    // }
  };

  const handleAmountGross = (
    value: string,
    index: number,
    elementId: string
  ) => {
    // if (validateCurrencyFormat(value)) {
    vehiclePrices[index].amountGross = formatCurrencyToDecimal(value);
    vehiclePrices[index].amountNet =
      formatCurrencyToDecimal(value) / (1 + vat / 100);

    // Purchase price calculation
    vehiclePrices[2].amountNet = getPurchasePriceNet();
    vehiclePrices[2].amountGross = getPurchasePriceGross();

    // Discount calculation
    vehiclePrices[3].amountNet =
      vehiclePrices[0].amountNet - vehiclePrices[1].amountNet;
    vehiclePrices[3].amountGross =
      vehiclePrices[0].amountGross - vehiclePrices[1].amountGross;

    if (vehiclePrices[0].amountNet > 0) {
      let vpp = (vehiclePrices[3].amountNet / vehiclePrices[0].amountNet) * 100;
      setVpPercent(vpp);
    }

    switch (index) {
      case 0:
        if (vp[0].net.current) {
          vp[0].net.current.value = formatCurrencyWithNoUnit(
            vehiclePrices[index].amountNet
          );
        }
        break;
      case 1:
        if (vp[1].net.current) {
          vp[1].net.current.value = formatCurrencyWithNoUnit(
            vehiclePrices[index].amountNet
          );
        }
        break;
      case 2:
        if (vp[2].net.current) {
          vp[2].net.current.value = formatCurrencyWithNoUnit(
            vehiclePrices[index].amountNet
          );
        }
        break;
    }

    setGross(value);

    let cal = orderContext.getCtxCalculationDraftDetails(category);
    cal.vehiclePrices = vehiclePrices;
    orderContext.updateCtxCalculationDraftDetails(cal, category);
    setVehiclePrices(vehiclePrices);
    //Calculate Header
    calculateHeader(cal);
    setReload(new Date().toLocaleString());
    // } else {
    //   
    // }
  };

  const labels = {
    demoCar: ["Referral commission", "Dealer contribution"],
  };

  const handleDiscountEuro = (value: string, i: number, elementId: string) => {
    // if(validateCurrencyFormat(value)) {
    discountNetPrices[i].discountInEuro = formatCurrencyToDecimal(value);
    let disPercent = 0.0;
    
    const rrp = vehiclePrices[0].amountNet;
    if (rrp > 0) {
      
      disPercent = (discountNetPrices[i].discountInEuro / rrp) * 100;
      discountNetPrices[i].discountInPercent = disPercent;
      setGross(value);
    }
    // Setting discount percent value using useRef
    switch (i) {
      case 0:
        if (dref[0] && dref[0].percent && dref[0].percent.current) {
          dref[0].percent.current.value = formatCurrencyWithNoUnit(
            discountNetPrices[i].discountInPercent
          );
        }
        break;
      case 1:
        if (dref[1] && dref[1].percent && dref[1].percent.current) {
          dref[1].percent.current.value = formatCurrencyWithNoUnit(
            discountNetPrices[i].discountInPercent
          );
        }
        break;
    }

    calDraft.discountNetPrices = discountNetPrices;
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);

    setCalDraft(calDraft);
    setDiscountNetPrices(discountNetPrices);
    //Calculate Header
    calculateHeader(calDraft);
    handleModified();
    setReload(new Date().toLocaleString());
    // } else{
    //     
    // }
  };

  const handleDiscountPercentage = (
    value: string,
    i: number,
    elementId: string
  ) => {
    // if(validateCurrencyFormat(value)) {
    const dom = document.getElementById(elementId);
    discountNetPrices[i].discountInPercent = formatCurrencyToDecimal(value);
    const refer = referals.filter((x) => x.referralType === "DemoCar");
    if (refer.length > 0 && order.referral && i === 0) {
      if (refer[0].value < discountNetPrices[i].discountInPercent) {
        if (dom) {
          dom.setAttribute("state", "error");
          dom.setAttribute("message", "Referral commission is too high.");
        }
      } else {
        if (dom) {
          dom.setAttribute("state", "none");
          dom.setAttribute("message", "");
        }
      }
    }
    let disEuro = 0.0;
    
    const rrp = vehiclePrices[0].amountNet;
    if (rrp > 0) {
      
      disEuro = (rrp * formatCurrencyToDecimal(value)) / 100;
      discountNetPrices[i].discountInEuro = disEuro;
      setGross(value);
    }
    switch (i) {
      case 0:
        if (dref[0] && dref[0].euro && dref[0].euro.current) {
          dref[0].euro.current.value = formatCurrencyWithNoUnit(
            discountNetPrices[i].discountInEuro
          );
        }
        break;
      case 1:
        if (dref[1] && dref[1].euro && dref[1].euro.current) {
          dref[1].euro.current.value = formatCurrencyWithNoUnit(
            discountNetPrices[i].discountInEuro
          );
        }
        break;
    }

    calDraft.discountNetPrices = discountNetPrices;
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    setCalDraft(calDraft);
    setDiscountNetPrices(discountNetPrices);
    //Calculate Header
    calculateHeader(calDraft);
    setReload(new Date().toLocaleString());
    // } else{
    //   
    // }
  };

  const handleDiscountComment = (value: string) => {
    calDraft.discountComment = value;
    setCalDraft(calDraft);
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    setGross(value);
  };

  const kickbackLabels = {
    demoCar: ["Sales promotion"],
  };

  const handleKickbackEuro = (value: string, i: number, elementId: string) => {
    // if (validateCurrencyFormat(value)) {
    kickbackNetPrices[i].kickbackInEuro = formatCurrencyToDecimal(value);
    let kPercent = 0.0;
    
    const rrp = vehiclePrices[0].amountNet;
    if (rrp > 0) {
      
      kPercent = (kickbackNetPrices[i].kickbackInEuro / rrp) * 100;
      kickbackNetPrices[i].kickbackInPercent = kPercent;
      setGross(value);
    }
    switch (i) {
      case 0:
        if (kbRef[0].percent.current) {
          kbRef[0].percent.current.value = formatCurrencyWithNoUnit(
            kickbackNetPrices[i].kickbackInPercent
          );
        }
        break;
      case 1:
        if (kbRef[1].percent.current) {
          kbRef[1].percent.current.value = formatCurrencyWithNoUnit(
            kickbackNetPrices[i].kickbackInPercent
          );
        }
        break;
    }
    calDraft.kickbackNetPrices = kickbackNetPrices;
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);

    setCalDraft(calDraft);
    setKickbackNetPrices(kickbackNetPrices);
    //Calculate Header
    calculateHeader(calDraft);
    handleModified();
    setReload(new Date().toLocaleString());
    // } else {
    //   
    // }
  };

  const updateDiscountForRRPValue = () => {
    const rrp = vehiclePrices[0].amountNet;
    if (rrp > 0 && dref) {
      
      for (let i = 0; i < discountNetPrices.length; i++) {
        let euro = (discountNetPrices[i].discountInPercent * rrp) / 100;
        discountNetPrices[i].discountInEuro = euro;
      }

      if (dref[0] && dref[0].euro && dref[0].euro.current) {
        dref[0].euro.current.value = formatCurrencyWithNoUnit(
          discountNetPrices[0].discountInEuro
        );
      }
      if (dref[1] && dref[1].euro && dref[1].euro.current) {
        dref[1].euro.current.value = formatCurrencyWithNoUnit(
          discountNetPrices[1].discountInEuro
        );
      }

      calDraft.discountNetPrices = discountNetPrices;
      orderContext.updateCtxCalculationDraftDetails(calDraft, category);
      setCalDraft(calDraft);
      setDiscountNetPrices(discountNetPrices);
      //Calculate Header
      calculateHeader(calDraft);
      setReload(new Date().toLocaleString());
    }
  };
  const updateKickBackBasedOnRRP = () => {
    const rrp = vehiclePrices[0].amountNet;
    if (rrp > 0) {
      for (let i = 0; i < kickbackNetPrices.length; i++) {
        let euro = (kickbackNetPrices[i].kickbackInPercent * rrp) / 100;
        kickbackNetPrices[i].kickbackInEuro = euro;
      }
      if (kbRef[0].euro && kbRef[0].euro.current) {
        kbRef[0].euro.current.value = formatCurrencyWithNoUnit(
          kickbackNetPrices[0].kickbackInEuro
        );
      }
      if (kbRef[1].euro.current) {
        kbRef[1].euro.current.value = formatCurrencyWithNoUnit(
          kickbackNetPrices[1].kickbackInEuro
        );
      }

      calDraft.kickbackNetPrices = kickbackNetPrices;
      orderContext.updateCtxCalculationDraftDetails(calDraft, category);

      setCalDraft(calDraft);
      setKickbackNetPrices(kickbackNetPrices);
      //Calculate Header
      calculateHeader(calDraft);
      setReload(new Date().toLocaleString());
    }
  };

  const handleKickbackPercentage = (
    value: string,
    i: number,
    elementId: string
  ) => {
    // if (validateCurrencyFormat(value)) {
    kickbackNetPrices[i].kickbackInPercent = formatCurrencyToDecimal(value);
    let kEuro = 0.0;
    
    const rrp = vehiclePrices[0].amountNet;
    if (rrp > 0) {
      
      kEuro = (rrp * formatCurrencyToDecimal(value)) / 100;
      kickbackNetPrices[i].kickbackInEuro = kEuro;
      setGross(value);
    }

    switch (i) {
      case 0:
        if (kbRef[0].euro.current) {
          kbRef[0].euro.current.value = formatCurrencyWithNoUnit(
            kickbackNetPrices[i].kickbackInEuro
          );
        }
        break;
      case 1:
        if (kbRef[1].euro.current) {
          kbRef[1].euro.current.value = formatCurrencyWithNoUnit(
            kickbackNetPrices[i].kickbackInEuro
          );
        }
        break;
    }

    calDraft.kickbackNetPrices = kickbackNetPrices;
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);

    setCalDraft(calDraft);
    setKickbackNetPrices(kickbackNetPrices);
    //Calculate Header
    calculateHeader(calDraft);
    handleModified();
    setReload(new Date().toLocaleString());
    // } else {
    //   
    // }
  };

  const handleKickbackDescription = (value: string) => {
    calDraft.kickbackDescription = value;
    setCalDraft(calDraft);
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    setGross(value);
  };

  {
    /** Additional costs handlers and related functions */
  }
  const loadAdditionalCosts = (
    tprs: TransferPickupRegistration,
    additionalCosts: Array<AdditionalCostsPriceDTO>,
    paw: Array<GetPaw>
  ) => {
    let ord = orderContext.getCtxOrderDetails();
    let usDetails = orderContext.getCtxUpsaleDetails();
    
    
    const mode =orderValues?.mode ? orderValues?.mode+'' :''

    if (
      additionalCosts &&
      ord?.deliveryDetails?.deliveryLocation &&
      ord.deliveryDetails?.deliveryLocation?.id > 0 &&
      additionalCosts?.length > 0
    ) {
      let tpr: any;
      switch (ord.deliveryDetails.deliveryLocation.name) {
        case "Factory pickup Zuffenhausen":
          tpr = tprs.transferPickups.filter(
            (x) => x.seriesDTO.id === ord.vehicleDetails.series.id
          );
          if (tpr.length > 0) {
            additionalCosts[0].amountNet = tpr[0].factoryPickUpZuffenhausen;
            if (
              (additionalCosts[0].customerShare === 0 && mode.length === 0) ||
              (additionalCosts[0].customerShare === 0 &&
                additionalCosts[0].label !== "Factory pickup Zuffenhausen")
            ) {
              additionalCosts[0].customerShare =
                tpr[0].factoryPickUpZuffenhausen;
            }
            if (acRef[0].share.current)
              acRef[0].share.current.value = formatCurrencyWithNoUnit(
                additionalCosts[0].customerShare
              );
          }
          additionalCosts[0].label = "Factory pickup Zuffenhausen";
          setAdditionalCosts(additionalCosts);
          break;
        case "Factory pickup Leipzig":
          tpr = tprs.transferPickups.filter(
            (x) => x.seriesDTO.id === ord.vehicleDetails.series.id
          );
          if (tpr.length > 0) {
            additionalCosts[0].amountNet = tpr[0].factoryPickUpLeipzig;
            if (
              (additionalCosts[0].customerShare === 0 && mode.length === 0) ||
              (additionalCosts[0].customerShare === 0 &&
                additionalCosts[0].label !== "Factory pickup Leipzig")
            ) {
              additionalCosts[0].customerShare = tpr[0].factoryPickUpLeipzig;
            }
            if (acRef[0].share.current)
              acRef[0].share.current.value = formatCurrencyWithNoUnit(
                additionalCosts[0].customerShare
              );
          }
          additionalCosts[0].label = "Factory pickup Leipzig";
          setAdditionalCosts(additionalCosts);
          break;
        case "PNHNW":
          tpr = tprs.transferPickups.filter(
            (x) => x.seriesDTO.id === ord.vehicleDetails.series.id
          );
          
          if (tpr.length > 0) {
            if (ord.vehicleDetails.category.option === "PreSold") {
              additionalCosts[0].amountNet = tpr[0].demoCarPreSold;
              if (
                (additionalCosts[0].customerShare === 0 && mode.length === 0) ||
                (additionalCosts[0].customerShare === 0 &&
                  additionalCosts[0].label !== "Transfer PNHNW")
              ) {
                additionalCosts[0].customerShare = tpr[0].demoCarPreSold;
              }
              if (acRef[0].net.current)
                acRef[0].net.current.value = formatCurrencyWithNoUnit(
                  additionalCosts[0].amountNet
                );
              if (acRef[0].share.current)
                acRef[0].share.current.value = formatCurrencyWithNoUnit(
                  additionalCosts[0].customerShare
                );
            } else if (
              ord.vehicleDetails.category.option === "Stock" ||
              ord.vehicleDetails.category.option === "Active" ||
              ord.vehicleDetails.category.option === "Passive"
            ) {
              additionalCosts[0].amountNet = tpr[0].demoCarStock;
              if (
                (additionalCosts[0].customerShare === 0 && mode.length === 0) ||
                (additionalCosts[0].customerShare === 0 &&
                  additionalCosts[0].label !== "Transfer PNHNW")
              ) {
                additionalCosts[0].customerShare = tpr[0].demoCarStock;
              }
              if (acRef[0].net.current)
                acRef[0].net.current.value = formatCurrencyWithNoUnit(
                  additionalCosts[0].amountNet
                );
              if (acRef[0].share.current)
                acRef[0].share.current.value = formatCurrencyWithNoUnit(
                  additionalCosts[0].customerShare
                );
            }
          }
          additionalCosts[0].label = "Transfer PNHNW";
          setAdditionalCosts(additionalCosts);
          break;
        case "PNHC":
          tpr = tprs.transferPickups.filter(
            (x) => x.seriesDTO.id === ord.vehicleDetails.series.id
          );
          if (tpr.length > 0) {
            if (ord.vehicleDetails.category.option === "PreSold") {
              additionalCosts[0].amountNet = tpr[0].demoCarPreSold;
              if (
                (additionalCosts[0].customerShare === 0 && mode.length === 0) ||
                (additionalCosts[0].customerShare === 0 &&
                  additionalCosts[0].label !== "Transfer PNHC")
              ) {
                additionalCosts[0].customerShare = tpr[0].demoCarPreSold;
              }
              if (acRef[0].net.current)
                acRef[0].net.current.value = formatCurrencyWithNoUnit(
                  additionalCosts[0].amountNet
                );
              if (acRef[0].share.current)
                acRef[0].share.current.value = formatCurrencyWithNoUnit(
                  additionalCosts[0].customerShare
                );
            } else if (
              ord.vehicleDetails.category.option === "Stock" ||
              ord.vehicleDetails.category.option === "Active" ||
              ord.vehicleDetails.category.option === "Passive"
            ) {
              additionalCosts[0].amountNet = tpr[0].demoCarStock;
              if (
                (additionalCosts[0].customerShare === 0 && mode.length === 0) ||
                (additionalCosts[0].customerShare === 0 &&
                  additionalCosts[0].label !== "Transfer PNHC")
              ) {
                additionalCosts[0].customerShare = tpr[0].demoCarStock;
              }
              if (acRef[0].net.current)
                acRef[0].net.current.value = formatCurrencyWithNoUnit(
                  additionalCosts[0].amountNet
                );
              if (acRef[0].share.current)
                acRef[0].share.current.value = formatCurrencyWithNoUnit(
                  additionalCosts[0].customerShare
                );
            }
          }
          additionalCosts[0].label = "Transfer PNHC";
          setAdditionalCosts(additionalCosts);
          break;
        case "Other":
          additionalCosts[0].label = "Transfer (Other)";
          setAdditionalCosts(additionalCosts);
          break;
        case "Home Delivery":
          additionalCosts[0].label = "Home Delivery";
          setAdditionalCosts(additionalCosts);
          break;
        case "European Union":
          additionalCosts[0].label = "Export (European union)";
          setAdditionalCosts(additionalCosts);
          break;
        case "European Economy Area":
          additionalCosts[0].label = "Export (European economy area)";
          setAdditionalCosts(additionalCosts);
          break;
        case "Third Country":
          additionalCosts[0].label = "Export (Third country)";
          setAdditionalCosts(additionalCosts);
          break;
      }
    } else {
      additionalCosts[0].label = "";
      additionalCosts[0].amountNet = 0.0;
      additionalCosts[0].customerShare = 0.0;
      setAdditionalCosts(additionalCosts);
    }

    if (
      ord.deliveryDetails?.registrationDto?.label !== "Registration by customer"
    ) {
      const regFilter = tprs.registrations.filter(
        (x) =>
          x.registrationType ===
          ord?.deliveryDetails?.registrationDto?.registrationType
      );
      
      if (regFilter.length > 0 && additionalCosts?.length > 0) {
        additionalCosts[1].amountNet = regFilter[0].priceBeforeCustomerNet;
        if (
          (additionalCosts[1].customerShare === 0 && mode.length === 0) ||
          (additionalCosts[1].customerShare === 0 &&
            additionalCosts[1].label !== regFilter[0].label + " registration")
        ) {
          additionalCosts[1].customerShare =
            regFilter[0].priceBeforeCustomerNet;
        }
        if (acRef[1].share.current)
          acRef[1].share.current.value = formatCurrencyWithNoUnit(
            additionalCosts[1].customerShare
          );
        additionalCosts[1].label =  `${t("Registration") + ": "}`+ regFilter[0].label;
        setAdditionalCosts(additionalCosts);
        
      }
    } else if (additionalCosts && additionalCosts.length > 1) {
      additionalCosts[1].label = "";
      additionalCosts[1].amountNet = 0.0;
      additionalCosts[1].customerShare = 0.0;
      setAdditionalCosts(additionalCosts);
    }

    if (
      usDetails.durationInMonths &&
      usDetails.durationInMonths > 0 &&
      additionalCosts &&
      additionalCosts.length > 0 &&
      additionalCosts[2]
    ) {
      
      if (paw.length > 0) {
        const pawRes = paw.filter(
          (x) => x.seriesDTO.id === ord.vehicleDetails.series.id
        );
        if (pawRes.length > 0) {
          switch (usDetails.durationInMonths) {
            case 12:
             
              additionalCosts[2].amountNet = pawRes[0].pawMonth12Price;
              if (
                (additionalCosts[2].customerShare === 0 && mode.length === 0) ||
                (additionalCosts[2].customerShare === 0 &&
                  additionalCosts[2].label !==
                    `PAW (${usDetails.durationInMonths} months)`)
              ) {
                additionalCosts[2].customerShare = pawRes[0].pawMonth12Price;
              }
              if (acRef[2].share.current)
                acRef[2].share.current.value = formatCurrencyWithNoUnit(
                  pawRes[0].pawMonth12Price
                );
              break;
            case 24:
              additionalCosts[2].amountNet = pawRes[0].pawMonth24Price;
              if (
                (additionalCosts[2].customerShare === 0 && mode.length === 0) ||
                (additionalCosts[2].customerShare === 0 &&
                  additionalCosts[2].label !==
                    `PAW (${usDetails.durationInMonths} months)`)
              ) {
                additionalCosts[2].customerShare = pawRes[0].pawMonth24Price;
              }
              if (acRef[2].share.current)
                acRef[2].share.current.value = formatCurrencyWithNoUnit(
                  pawRes[0].pawMonth24Price
                );
              break;
            case 36:
              additionalCosts[2].amountNet = pawRes[0].pawMonth36Price;
              if (
                (additionalCosts[2].customerShare === 0 && mode.length === 0) ||
                (additionalCosts[2].customerShare === 0 &&
                  additionalCosts[2].label !==
                    `PAW (${usDetails.durationInMonths} months)`)
              ) {
                additionalCosts[2].customerShare = pawRes[0].pawMonth36Price;
              }
              if (acRef[2].share.current)
                acRef[2].share.current.value = formatCurrencyWithNoUnit(
                  pawRes[0].pawMonth36Price
                );
              break;
          }
        }
      }
      additionalCosts[2].label = `PAW (${usDetails.durationInMonths} months)`;
      

      setAdditionalCosts(additionalCosts);
    } else if (additionalCosts && additionalCosts.length > 2) {
      additionalCosts[2].label = "";
      additionalCosts[2].amountNet = 0.0;
      additionalCosts[2].customerShare = 0.0;
      setAdditionalCosts(additionalCosts);
    }

    if (
      usDetails.includeAssistance !== null &&
      usDetails.includeAssistance === true &&
      additionalCosts &&
      additionalCosts.length > 0
    ) {
      if (
        usDetails.durationInMonths > 0 &&
        additionalCosts &&
        additionalCosts.length > 3
      ) {
        if (paw.length > 0) {
          const pawRes = paw.filter(
            (x) => x.seriesDTO.id === ord.vehicleDetails.series.id
          );
          if (pawRes.length > 0) {
            switch (usDetails.durationInMonths) {
              case 12:
                additionalCosts[3].amountNet = pawRes[0].assistanceMonth12Price;
                if (
                  (additionalCosts[3].customerShare === 0 &&
                    mode.length === 0) ||
                  (additionalCosts[3].customerShare === 0 &&
                    additionalCosts[3].label !==
                      `Assistance (${usDetails.durationInMonths} months)`)
                ) {
                  additionalCosts[3].customerShare =
                    pawRes[0].assistanceMonth12Price;
                }
                if (acRef[3].share.current)
                  acRef[3].share.current.value = formatCurrencyWithNoUnit(
                    pawRes[0].assistanceMonth12Price
                  );
                break;
              case 24:
                additionalCosts[3].amountNet = pawRes[0].assistanceMonth24Price;
                if (
                  (additionalCosts[3].customerShare === 0 &&
                    mode.length === 0) ||
                  (additionalCosts[3].customerShare === 0 &&
                    additionalCosts[3].label !==
                      `Assistance (${usDetails.durationInMonths} months)`)
                ) {
                  additionalCosts[3].customerShare =
                    pawRes[0].assistanceMonth24Price;
                }
                if (acRef[3].share.current)
                  acRef[3].share.current.value = formatCurrencyWithNoUnit(
                    pawRes[0].assistanceMonth24Price
                  );
                break;
              case 36:
                additionalCosts[3].amountNet = pawRes[0].assistanceMonth36Price;
                if (
                  (additionalCosts[3].customerShare === 0 &&
                    mode.length === 0) ||
                  (additionalCosts[3].customerShare === 0 &&
                    additionalCosts[3].label !==
                      `Assistance (${usDetails.durationInMonths} months)`)
                ) {
                  additionalCosts[3].customerShare =
                    pawRes[0].assistanceMonth36Price;
                }
                if (acRef[3].share.current)
                  acRef[3].share.current.value = formatCurrencyWithNoUnit(
                    pawRes[0].assistanceMonth36Price
                  );
                break;
            }
          }
        }
        additionalCosts[3].label = `Assistance (${usDetails.durationInMonths} months)`;
      } else if (additionalCosts && additionalCosts.length > 3) {
        additionalCosts[3].label = additionalCosts[3].type;
      }
      setAdditionalCosts(additionalCosts);
    } else {
      additionalCosts[3].label = "";
      additionalCosts[3].amountNet = 0.0;
      additionalCosts[3].customerShare = 0.0;
      setAdditionalCosts(additionalCosts);
    }

    let temp = orderContext.getCtxCalculationDraftDetails("DemoCar");
    
    if (additionalCosts.length > 0) {
      temp.additionalCostsPrices = additionalCosts;
      orderContext.updateCtxCalculationDraftDetails(temp, "DemoCar");
      setCalDraft(temp);
    }

    // Calculate header
    //calculateHeader(calDraft);
    setReload(new Date().toLocaleString());
  };

  const updateAdditionalCostsBasedRRP = () => {
    const rrp = vehiclePrices[0].amountNet;
    if (rrp > 0 && acRef) {
      
      for (let i = 0; i < additionalCosts.length; i++) {
        let percent = (additionalCosts[i].discountInEuro / rrp) * 100;
        additionalCosts[i].discountInPercent = percent;
      }

      if (acRef[0] && acRef[0].percent && acRef[0].percent.current) {
        acRef[0].percent.current.value = formatCurrencyWithNoUnit(
          additionalCosts[0].discountInPercent
        );
      }
      if (acRef[1] && acRef[1].percent && acRef[1].percent.current) {
        acRef[1].percent.current.value = formatCurrencyWithNoUnit(
          additionalCosts[1].discountInPercent
        );
      }
      if (acRef[2] && acRef[2].percent && acRef[2].percent.current) {
        acRef[2].percent.current.value = formatCurrencyWithNoUnit(
          additionalCosts[2].discountInPercent
        );
      }
      if (acRef[3] && acRef[3].percent && acRef[3].percent.current) {
        acRef[3].percent.current.value = formatCurrencyWithNoUnit(
          additionalCosts[3].discountInPercent
        );
      }
      if (acRef[4] && acRef[4].percent && acRef[4].percent.current) {
        acRef[4].percent.current.value = formatCurrencyWithNoUnit(
          additionalCosts[4].discountInPercent
        );
      }

      calDraft.discountNetPrices = discountNetPrices;
      orderContext.updateCtxCalculationDraftDetails(calDraft, category);

      setCalDraft(calDraft);
      setDiscountNetPrices(discountNetPrices);
      //Calculate Header
      calculateHeader(calDraft);
      setReload(new Date().toLocaleString());
    }
  };

  const handleAddAmountGross = (
    value: string,
    i: number,
    elementId: string
  ) => {
    // if(validateCurrencyFormat(value)) {
    additionalCosts[i].amountNet = formatCurrencyToDecimal(value);
    additionalCosts[i].discountInEuro =
      additionalCosts[i].amountNet - additionalCosts[i].customerShare;
    if (calDraft.vehiclePrices) {
      if (calDraft.vehiclePrices[0].amountNet > 0) {
        additionalCosts[i].discountInPercent =
          (additionalCosts[i].discountInEuro /
            calDraft.vehiclePrices[0].amountNet) *
          100;
      } else {
        additionalCosts[i].discountInPercent = 0.0;
      }
    }
    switch (i) {
      case 0:
        if (acRef[0].euro.current)
          acRef[0].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[0].percent.current)
          acRef[0].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
      case 1:
        if (acRef[1].euro.current)
          acRef[1].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[1].percent.current)
          acRef[1].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
      case 2:
        if (acRef[2].euro.current)
          acRef[2].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[2].percent.current)
          acRef[2].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
      case 3:
        if (acRef[3].euro.current)
          acRef[3].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[3].percent.current)
          acRef[3].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
      case 4:
        if (acRef[4].euro.current)
          acRef[4].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[4].percent.current)
          acRef[4].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
    }
    setAdditionalCosts(additionalCosts);
    calDraft.additionalCostsPrices = additionalCosts;
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    setCalDraft(calDraft);
    calculateHeader(calDraft);
    handleModified();
    setReload(new Date().toLocaleString());
    // }
  };

  const handleCustomerShare = (value: string, i: number, elementId: string) => {
    // if(validateCurrencyFormat(value)) {
    additionalCosts[i].customerShare = formatCurrencyToDecimal(value);
    additionalCosts[i].discountInEuro =
      additionalCosts[i].amountNet - additionalCosts[i].customerShare;
    //additionalCosts[i].discountInPercent = additionalCosts[i].discountInEuro / additionalCosts[i].amountNet * 100;

    if (calDraft.vehiclePrices) {
      if (calDraft.vehiclePrices[0].amountNet > 0) {
        additionalCosts[i].discountInPercent =
          (additionalCosts[i].discountInEuro /
            calDraft.vehiclePrices[0].amountNet) *
          100;
      } else {
        additionalCosts[i].discountInPercent = 0.0;
      }
    }

    switch (i) {
      case 0:
        if (acRef[0].euro.current)
          acRef[0].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[0].percent.current)
          acRef[0].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
      case 1:
        if (acRef[1].euro.current)
          acRef[1].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[1].percent.current)
          acRef[1].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
      case 2:
        if (acRef[2].euro.current)
          acRef[2].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[2].percent.current)
          acRef[2].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
      case 3:
        if (acRef[3].euro.current)
          acRef[3].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[3].percent.current)
          acRef[3].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
      case 4:
        if (acRef[4].euro.current)
          acRef[4].euro.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInEuro
          );
        if (acRef[4].percent.current)
          acRef[4].percent.current.value = formatCurrencyWithNoUnit(
            additionalCosts[i].discountInPercent
          );
        break;
    }

    setAdditionalCosts(additionalCosts);
    calDraft.additionalCostsPrices = additionalCosts;
    //
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    setCalDraft(calDraft);

    //Calculate header
    calculateHeader(calDraft);
    handleModified();
    setReload(new Date().toLocaleString());
    // }
  };

  const handleAdditionalCostComment = (value: string) => {
    calDraft.additionalCostPriceComment = value;
    setCalDraft(calDraft);
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    handleModified();
  };

  {
    /** Handlers and functions related to Workshop costs */
  }
  const handleWsCustomerShare = (
    value: string,
    partType: string,
    services: boolean,
    i: number,
    elementId: string
  ) => {
    // if(validateCurrencyFormat(value)){
    let usDetails = orderContext.getCtxUpsaleDetails();
    if (services) {
      usDetails.upsaleItems.map((item, j) => {
        if (item.partType === partType) {
          item.serviceItems.map((service, k) => {
            usDetails.upsaleItems[j].serviceItems[i].customerShare =
              formatCurrencyToDecimal(value);
          });
        }
      });
    } else {
      usDetails.upsaleItems.map((item, j) => {
        if (item.partType === partType) {
          usDetails.upsaleItems[j].customerShare =
            formatCurrencyToDecimal(value);
        }
      });
      //usDetails.upsaleItems[i].customerShare = formatCurrencyToDecimal(value);
    }

    orderContext.updateCtxUpsaleDetails(usDetails);
    calDraft.workshopCosts = usDetails;
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    setCalDraft(calDraft);

    // Calculate header
    calculateHeader(calDraft);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const getAmountNet = (item: UpsaleItemDTO, service: boolean, i: number) => {
    if (service) {
      if (item.serviceItems && item.serviceItems.length > 0) {
        if (item.serviceItems[i].customerShare > 0) {
          const ret = item.serviceItems[i].services.priceBeforeCustomerNet
            ? item.serviceItems[i].services.priceBeforeCustomerNet
            : 0.0;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return (ret * item?.serviceItems?.[i].timeUnits!) / 100;
            } else {
              return 0.0;
            }
          } else {
            return ret;
          }
        } else {
          const ret1 = item.serviceItems[i].services.priceInternal
            ? item.serviceItems[i].services.priceInternal
            : 0.0;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return (ret1 * item?.serviceItems?.[i].timeUnits!) / 100;
            } else {
              return 0.0;
            }
          } else {
            return ret1;
          }
        }
      } else {
        return 0.0;
      }
    } else {
      if (item.customerShare > 0) {
        return item.part.priceBeforeCustomerNet
          ? item.part.priceBeforeCustomerNet
          : 0.0;
      } else {
        return item.part.priceInternal ? item.part.priceInternal : 0.0;
      }
    }
  };

  const getPercent = (item: UpsaleItemDTO, service: boolean, i: number) => {
    
    
    
    let disPercent = 0.0;
    let dis = 0.0;
    if (service) {
      const net = getAmountNet(item, service, i);
      dis = net - item.serviceItems[i].customerShare;
      // if (dis > 0 ) {
      //   disPercent =  (dis / net) * 100;
      // }
    } else {
      const net = getAmountNet(item, service, i);
      dis = net - item.customerShare;
      // if (net > 0) {
      //   disPercent =   (dis / net) * 100;
      // }
    }

    switch (category) {
      case "NewCar":
        if (calDraft.vehiclePrices && calDraft.vehiclePrices[2].amountNet > 0) {
          disPercent = (dis / calDraft.vehiclePrices[2].amountNet) * 100;
        }
        break;
      case "DemoCar":
      case "UsedCar":
        if (calDraft.vehiclePrices && calDraft.vehiclePrices[0].amountNet > 0) {
          disPercent = (dis / calDraft.vehiclePrices[0].amountNet) * 100;
        }
        break;
    }

    return disPercent;
  };

  // Handlers for TradeIn
  const handleTradeInAmountNet = (
    value: string,
    index: number,
    elementId: string
  ) => {
    // if(validateCurrencyFormat(value)) {
    tradeInFields[index].amountNet = formatCurrencyToDecimal(value);
    if (order.tradeInDetails) {
      switch (order.tradeInDetails.tradeInType) {
        case "TradeIn_Regular":
        case "Financing_Redemption_Regular":
        case "Leasing_Return":
        case "Leasing_Redemption":
          tradeInFields[index].amountGross =
            formatCurrencyToDecimal(value) * (1 + vat / 100);
          break;
        case "TradeIn_Differential":
        case "Financing_Redemption_Differential":
          tradeInFields[index].amountGross = formatCurrencyToDecimal(value);
          break;
      }
    }

    if (index < 2) {
      tradeInFields[2].amountNet =
        tradeInFields[0].amountNet - tradeInFields[1].amountNet;
      tradeInFields[2].amountGross =
        tradeInFields[0].amountGross - tradeInFields[1].amountGross;

      // Share pc and share cuatomer update
      tradeInFields[2].amountGross =
        tradeInFields[0].amountGross - tradeInFields[1].amountGross;
      tradeInFields[3].amountNet =
        tradeInFields[0].amountNet - tradeInFields[1].amountNet;
      tradeInFields[3].amountGross =
        tradeInFields[0].amountGross - tradeInFields[1].amountGross;
      tradeInFields[4].amountNet = 0.0;
      tradeInFields[4].amountGross = 0.0;

      if (tradeInFields[2].amountNet < 0) {
        tradeInFields[2].tradeInColumn = TradeInOptions.LossBooking;
      } else {
        tradeInFields[2].tradeInColumn = TradeInOptions.ProfitBooking;
      }

      if (category === "DemoCar" && calDraft && calDraft.vehiclePrices) {
        if (calDraft?.vehiclePrices[0]?.amountNet > 0)
          setDiscount(
            (tradeInFields[2].amountNet /
              calDraft?.vehiclePrices[0]?.amountNet) *
              -1 *
              100
          );
      }
    }

    if (index > 2) {
      if (category === "NewCar" && calDraft && calDraft.vehiclePrices) {
        if (calDraft?.vehiclePrices[2]?.amountNet > 0)
          setDiscount(
            (tradeInFields[3].amountNet /
              calDraft?.vehiclePrices[2]?.amountNet) *
              -1 *
              100
          );
      }
    }

    switch (index) {
      case 0:
        if (tRef[0] && tRef[0].gross && tRef[0].gross.current) {
          tRef[0].gross.current.value = formatCurrencyWithNoUnit(
            tradeInFields[0].amountGross
          );
        }
        break;
      case 1:
        if (tRef[1] && tRef[1].gross && tRef[1].gross.current) {
          tRef[1].gross.current.value = formatCurrencyWithNoUnit(
            tradeInFields[1].amountGross
          );
        }
        break;
      case 2:
        if (tRef[2] && tRef[2].gross && tRef[2].gross.current) {
          tRef[2].gross.current.value = formatCurrencyWithNoUnit(
            tradeInFields[2].amountGross
          );
        }
        break;
      case 3:
        if (tRef[3] && tRef[3].gross && tRef[3].gross.current) {
          tRef[3].gross.current.value = formatCurrencyWithNoUnit(
            tradeInFields[3].amountGross
          );
        }
        break;
      case 4:
        if (tRef[4] && tRef[4].gross && tRef[4].gross.current) {
          tRef[4].gross.current.value = formatCurrencyWithNoUnit(
            tradeInFields[4].amountGross
          );
        }
        break;
    }

    let cal = orderContext.getCtxCalculationDraftDetails(category);
    cal.tradeInPrices = tradeInFields;
    orderContext.updateCtxCalculationDraftDetails(cal, category);

    setTradeInFields(tradeInFields);
    //setCalDraft(orderContext.getCtxCalculationDraftDetails(category))
    setCalDraft(cal);
    // Calculate header
    calculateHeader(cal);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleTradeInAmountGross = (
    value: string,
    index: number,
    elementId: string
  ) => {
    // if(validateCurrencyFormat(value)) {
    tradeInFields[index].amountGross = formatCurrencyToDecimal(value);
    if (order.tradeInDetails) {
      switch (order.tradeInDetails.tradeInType) {
        case "TradeIn_Regular":
        case "Financing_Redemption_Regular":
        case "Leasing_Return":
        case "Leasing_Redemption":
          tradeInFields[index].amountNet =
            formatCurrencyToDecimal(value) / (1 + vat / 100);
          break;
        case "TradeIn_Differential":
        case "Financing_Redemption_Differential":
          tradeInFields[index].amountNet = formatCurrencyToDecimal(value);
          break;
      }
    }

    if (index < 2) {
      tradeInFields[2].amountNet =
        tradeInFields[0].amountNet - tradeInFields[1].amountNet;
      tradeInFields[2].amountGross =
        tradeInFields[0].amountGross - tradeInFields[1].amountGross;

      // Share PC and share customer update
      tradeInFields[3].amountNet =
        tradeInFields[0].amountNet - tradeInFields[1].amountNet;
      tradeInFields[3].amountGross =
        tradeInFields[0].amountGross - tradeInFields[1].amountGross;
      tradeInFields[4].amountNet = 0.0;
      tradeInFields[4].amountGross = 0.0;
      if (tradeInFields[2].amountNet < 0) {
        tradeInFields[2].tradeInColumn = TradeInOptions.LossBooking;
      } else {
        tradeInFields[2].tradeInColumn = TradeInOptions.ProfitBooking;
      }

      if (category === "DemoCar" && calDraft && calDraft.vehiclePrices) {
        if (calDraft?.vehiclePrices[0]?.amountNet > 0)
          setDiscount(
            (tradeInFields[2].amountNet /
              calDraft?.vehiclePrices[0]?.amountNet) *
              -1 *
              100
          );
      }
    }

    if (index > 2) {
      if (category === "DemoCar" && calDraft && calDraft.vehiclePrices) {
        if (calDraft?.vehiclePrices[0]?.amountNet > 0)
          setDiscount(
            (tradeInFields[3].amountNet /
              calDraft?.vehiclePrices[0]?.amountNet) *
              -1 *
              100
          );
      }
    }

    switch (index) {
      case 0:
        if (tRef[0] && tRef[0].net && tRef[0].net.current) {
          tRef[0].net.current.value = formatCurrencyWithNoUnit(
            tradeInFields[0].amountNet
          );
        }
        break;
      case 1:
        if (tRef[1] && tRef[1].net && tRef[1].net.current) {
          tRef[1].net.current.value = formatCurrencyWithNoUnit(
            tradeInFields[1].amountNet
          );
        }
        break;
      case 2:
        if (tRef[2] && tRef[2].net && tRef[2].net.current) {
          tRef[2].net.current.value = formatCurrencyWithNoUnit(
            tradeInFields[2].amountNet
          );
        }
        break;
      case 3:
        if (tRef[3] && tRef[3].net && tRef[3].net.current) {
          tRef[3].net.current.value = formatCurrencyWithNoUnit(
            tradeInFields[3].amountNet
          );
        }
        break;
      case 4:
        if (tRef[4] && tRef[4].net && tRef[4].net.current) {
          tRef[4].net.current.value = formatCurrencyWithNoUnit(
            tradeInFields[4].amountNet
          );
        }
        break;
    }

    let cal = orderContext.getCtxCalculationDraftDetails(category);
    cal.tradeInPrices = tradeInFields;
    orderContext.updateCtxCalculationDraftDetails(cal, category);

    setTradeInFields(tradeInFields);
    setCalDraft(cal);
    calculateHeader(cal);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const validateTradeInFields = (
    value: string,
    index: number,
    elementId: string
  ) => {
    let val = parseInt(value);
    
    switch (index) {
      case 0:
        if (val > 0) {
          setShowErrorCarInternal(false);
        } else {
          setShowErrorCarInternal(true);
        }
        break;
      case 1:
        if (val > 0) {
          setShowErrorCarCustomer(false);
        } else {
          setShowErrorCarCustomer(true);
        }
        break;
    }
  };

  const handleTradeInComment = (value: string) => {
    calDraft.tradeInComment = value;
    setCalDraft(calDraft);
    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  // Handlers for payment section
  const handlePaymentAmountNet = (
    value: string,
    type: string,
    elementId: string
  ) => {
    // if(validateCurrencyFormat(value)) {
    switch (type) {
      case "down payment":
        calPaymentDetails.paymentNet = formatCurrencyToDecimal(value);
        calPaymentDetails.paymentGross =
          formatCurrencyToDecimal(value) * (1 + vat / 100);
        if (dpRef[0].gross.current) {
          dpRef[0].gross.current.value = formatCurrencyWithNoUnit(
            calPaymentDetails.paymentGross
          );
        }
        break;

      case "upfront payment":
        calPaymentDetails.upFrontPaymentNet = formatCurrencyToDecimal(value);
        calPaymentDetails.upFrontPaymentGross =
          formatCurrencyToDecimal(value) * (1 + vat / 100);
        if (upRef[0].gross.current) {
          upRef[0].gross.current.value = formatCurrencyWithNoUnit(
            calPaymentDetails.upFrontPaymentGross
          );
        }
        break;
    }

    let cal = orderContext.getCtxCalculationDraftDetails(category);
    cal.calculationPaymentDetails = calPaymentDetails;
    orderContext.updateCtxCalculationDraftDetails(cal, category);
    setCalPaymentDetails(calPaymentDetails);
    setCalDraft(orderContext.getCtxCalculationDraftDetails(category));
    calculateHeader(cal);
    handleModified();
    setReload(new Date().toLocaleString());
    // } else {
    //   
    // }
  };

  const handlePaymentAmountGross = (
    value: string,
    type: string,
    elementId: string
  ) => {
    // if(validateCurrencyFormat(value)) {
    switch (type) {
      case "down payment":
        calPaymentDetails.paymentGross = formatCurrencyToDecimal(value);
        calPaymentDetails.paymentNet =
          formatCurrencyToDecimal(value) / (1 + vat / 100);
        if (dpRef[0].net.current) {
          dpRef[0].net.current.value = formatCurrencyWithNoUnit(
            calPaymentDetails.paymentNet
          );
        }
        break;

      case "upfront payment":
        calPaymentDetails.upFrontPaymentGross = formatCurrencyToDecimal(value);
        calPaymentDetails.upFrontPaymentNet =
          formatCurrencyToDecimal(value) / (1 + vat / 100);
        if (upRef[0].net.current) {
          upRef[0].net.current.value = formatCurrencyWithNoUnit(
            calPaymentDetails.upFrontPaymentNet
          );
        }
        break;
    }

    let cal = orderContext.getCtxCalculationDraftDetails(category);
    cal.calculationPaymentDetails = calPaymentDetails;
    orderContext.updateCtxCalculationDraftDetails(cal, category);

    setCalPaymentDetails(calPaymentDetails);
    setCalDraft(orderContext.getCtxCalculationDraftDetails(category));
    calculateHeader(orderContext.getCtxCalculationDraftDetails(category));
    handleModified();
    setReload(new Date().toLocaleString());
    // } else {
    //   
    // }
  };

  const handleIncludeOptions = (value: string) => {
    
    let options = calPaymentDetails.includeOptionals
      ? calPaymentDetails.includeOptionals
      : [];
    if (options.length > 0) {
      let index = options.indexOf(value);
      if (index > -1) {
        options.splice(index, 1);
      } else {
        options.push(value);
      }
      calPaymentDetails.includeOptionals = options;
    } else {
      calPaymentDetails.includeOptionals = [value];
    }
    
    setCalPaymentDetails(calPaymentDetails);

    let calDraft = orderContext.getCtxCalculationDraftDetails(category);
    calDraft.calculationPaymentDetails = calPaymentDetails;

    orderContext.updateCtxCalculationDraftDetails(calDraft, category);
    setCalDraft(calDraft);
    calculateHeader(calDraft);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  // Handler for final calculation
  const handleCs = () => {
    setShowCs(!showCs);
    calDraft.isCommissionSharingActive = !calDraft.isCommissionSharingActive;
    if (calDraft.isCommissionSharingActive) {
      if (order.orderCreator?.id) {
        calDraft.commissionSharing = [];
        let vc = 0.0;
        
        if (calDraft.calculationHeader && calDraft.commissionCalculation) {
          vc = calDraft.commissionCalculation.vehicleCommissionEuro
            ? calDraft.commissionCalculation.vehicleCommissionEuro
            : 0.0;
        }

        calDraft.commissionSharing.push({
          shareCommission: true,
          sellerNames: {
            id: order.orderCreator?.id,
          } as CommissionSharingUserDetailsDTO,
          shareInPercent: 50.0,
          shareInEuro: vc * 0.5,
          isOrderCreator: true,
        } as CommissionSharingDTO);

        if (sellers.length > 0) {
          calDraft.commissionSharing.push({
            shareCommission: true,
            sellerNames: {
              id: sellers[0].id,
            } as CommissionSharingUserDetailsDTO,
            shareInPercent: 50.0,
            shareInEuro: vc * 0.5,
            isOrderCreator: true,
          } as CommissionSharingDTO);
        }
      }
    } else {
      calDraft.commissionSharing = null;
    }
    orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
    calculateHeader(orderContext.getCtxCalculationDraftDetails("DemoCar"));
    handleModified();
  };

  const getImage = (seriesName: string) => {
    let ret = (
      <img
        src={require("../../../../../assets/images/cars/png/Boxster.png")}
        alt={"Boxster"}
        width="700"
        height="400"
      />
    );
    switch (seriesName) {
      case "Boxster":
        ret = (
          <img
            src={require("../../../../../assets/images/cars/png/Boxster.png")}
            alt={"Boxster"}
            width="700"
            height="400"
            style={{ marginBottom: "-4px" }}
          />
        );
        break;
      case "Cayenne":
        ret = (
          <img
            src={require("../../../../../assets/images/cars/png/Cayenne.png")}
            alt={"Cayenne"}
            width="700"
            height="400"
            style={{ marginBottom: "-4px" }}
          />
        );
        break;
      case "Cayman":
        ret = (
          <img
            src={require("../../../../../assets/images/cars/png/Cayman.png")}
            alt={"Cayman"}
            width="500"
            height="250"
            style={{ marginBottom: "-4px" }}
          />
        );
        break;
      case "Macan":
        ret = (
          <img
            src={require("../../../../../assets/images/cars/png/Macan.png")}
            alt={"Macan"}
            width="700"
            height="400"
            style={{ marginBottom: "-4px" }}
          />
        );
        break;
      case "Panamera":
        ret = (
          <img
            src={require("../../../../../assets/images/cars/png/Panamera.png")}
            alt={"Panamera"}
            width="700"
            height="400"
            style={{ marginBottom: "-4px" }}
          />
        );
        break;
      case "Taycan":
        ret = (
          <img
            src={require("../../../../../assets/images/cars/png/Taycan.png")}
            alt={"Taycan"}
            width="700"
            height="400"
            style={{ marginBottom: "-4px" }}
          />
        );
        break;
      case "911":
      default:
        ret = (
          <img
            src={require("../../../../../assets/images/cars/png/911.png")}
            alt={"911"}
            width="700"
            height="400"
            style={{ marginBottom: "-4px" }}
          />
        );
        break;
    }
    return ret;
  };

  const getInvoiceGross = () => {
    let invoice = getInvoiceNet();
    let gross = invoice * (1 + vat / 100);
    if (calDraft.additionalCostsPrices) {
      const addCost = calDraft.additionalCostsPrices.filter(
        (x) => x.type === "Paw"
      );
      if (addCost.length > 0) {
        gross = gross - (addCost[0].customerShare * vat) / 100;
      }
    }
    return gross;
  };

  const getInvoiceNet = () => {
    let net = 0.0;
    if (calDraft.vehiclePrices && calDraft.discountNetPrices) {
      net = calDraft.vehiclePrices[1].amountNet;
      net += getAdditionalCostsCustomerShareSum();
      // const upsale = orderContext.getCtxUpsaleDetails();
      // if (upsale.upsaleItems) {
      //   const totals = getUpsaleItemsTotal(upsale.upsaleItems);
      //   net += totals.customerShare;
      // }
      const wcs = calDraft.workshopCosts;
      if (wcs && wcs.upsaleItems) {
        const totals = getUpsaleItemsTotal(wcs.upsaleItems);
        net += totals.customerShare;
      }
    }
    return net;
  };

  const getAdditionalCostsCustomerShareSum = () => {
    let sum = 0.0;
    if (calDraft.additionalCostsPrices) {
      calDraft.additionalCostsPrices.map((x, i) => {
        sum += x.customerShare;
      });
    }
    return sum;
  };

  const getPredeliveryNet = () => {
    let pdn = 0.0;
    if (calDraft.calculationPaymentDetails) {
      switch (order.paymentDetails?.paymentType) {
        case "DebitCredit":
          pdn =
            getInvoiceNet() -
            calDraft.calculationPaymentDetails.upFrontPaymentNet;
          break;
        case "Leasing_PFS":
        case "Leasing_Other":
        case "SUBSCRIPTION":
        case "Financing_PFS":
        case "Financing_Other":
          pdn =
            calDraft.calculationPaymentDetails.paymentNet -
            calDraft.calculationPaymentDetails.upFrontPaymentNet;
          pdn += getNotIncludedCustomerShare();
          break;
      }

      if (order.tradeInDetails) {
        if (calDraft.tradeInPrices) {
          switch (order.tradeInDetails.tradeInType) {
            case "Financing_Redemption":
            case "Leasing_Redemption":
              // const tip = calDraft.tradeInPrices.filter(x => x.tradeInColumn === "ShareCustomer")
              // if (tip.length > 0) {
              //   pdn = pdn - tip[0].amountNet;
              // }
              pdn = pdn - calDraft.tradeInPrices[4].amountNet;
              break;
            case "TradeIn_Regular":
            case "TradeIn_Differential":
              // const tips = calDraft.tradeInPrices.filter(x => x.tradeInColumn === "CarValueCustomer")
              // if (tips.length > 0) {
              //   pdn = pdn - tips[0].amountNet;
              // }
              pdn = pdn - calDraft.tradeInPrices[1].amountNet;
              break;
          }
        }
      }
    }
    return pdn;
  };

  const getNotIncludedCustomerShare = () => {
    let customerShare = 0.0;
    calDraft.additionalCostsPrices?.map((x, i) => {
      const optionals = calDraft.calculationPaymentDetails?.includeOptionals;
      if (optionals && optionals?.length > 0) {
        const selected = optionals.filter((option) => option === x.type);
        if (selected.length === 0) {
          customerShare += x.customerShare;
        }
      } else {
        customerShare += x.customerShare;
      }
    });

    if (calDraft.workshopCosts) {
      let usItems = calDraft.workshopCosts.upsaleItems;
      usItems?.map((item, i) => {
        const optionals = calDraft.calculationPaymentDetails?.includeOptionals;
        if (optionals && optionals?.length > 0) {
          if (item.partType === "tire") {
            const selectedTire = optionals.filter(
              (option) => option === "CompleteWinterTires"
            );
            if (selectedTire.length === 0) {
              customerShare += item.customerShare;
            }

            item.serviceItems.map((service) => {
              const selectedService = optionals.filter(
                (option) => option === service.services.serviceNameUpsale
              );
              if (selectedService.length === 0) {
                customerShare += service.customerShare;
              }
            });
          }

          if (item.partType === "part") {
            const selectedPart = optionals.filter(
              (option) => option === "PDS / Tequipment"
            );
            if (selectedPart.length === 0) {
              customerShare += item.customerShare;
            }

            item.serviceItems.map((service) => {
              const selectedService = optionals.filter(
                (option) => option === service.services.serviceNameUpsale
              );
              if (selectedService.length === 0) {
                customerShare += service.customerShare;
              }
            });
          }
        } else {
          customerShare += item.customerShare;
          item.serviceItems.map((service) => {
            customerShare += service.customerShare;
          });
        }
      });
    }
    return customerShare;
  };

  const getIncludedCustomerShare = () => {
    let customerShare = 0.0;
    calDraft.additionalCostsPrices?.map((x, i) => {
      const optionals = calDraft.calculationPaymentDetails?.includeOptionals;
      if (optionals && optionals?.length > 0) {
        const selected = optionals.filter((option) => option === x.type);
        if (selected.length > 0) {
          customerShare += x.customerShare;
        }
      }
    });

    if (calDraft.workshopCosts) {
      let usItems = calDraft.workshopCosts.upsaleItems;
      usItems?.map((item, i) => {
        const optionals = calDraft.calculationPaymentDetails?.includeOptionals;
        if (optionals && optionals?.length > 0) {
          if (item.partType === "tire") {
            const selectedTire = optionals.filter(
              (option) => option === "CompleteWinterTires"
            );
            if (selectedTire.length > 0) {
              customerShare += item.customerShare;
            }

            item.serviceItems.map((service) => {
              const selectedService = optionals.filter(
                (option) => option === service.services.serviceNameUpsale
              );
              if (selectedService.length > 0) {
                customerShare += service.customerShare;
              }
            });
          }

          if (item.partType === "part") {
            const selectedPart = optionals.filter(
              (option) => option === "PDS / Tequipment"
            );
            if (selectedPart.length > 0) {
              customerShare += item.customerShare;
            }

            item.serviceItems.map((service) => {
              const selectedService = optionals.filter(
                (option) => option === service.services.serviceNameUpsale
              );
              if (selectedService.length > 0) {
                customerShare += service.customerShare;
              }
            });
          }
        }
      });
    }
    return customerShare;
  };

  const calculateHeader = (cdd: CalculationDraftDTO) => {
    let dicountNetInternalDicount = 0.0;
    if (cdd.discountNetPrices) {
      const length = cdd.discountNetPrices.length;
      for (let i = 0; i < length; i++) {
        dicountNetInternalDicount += cdd.discountNetPrices[i].discountInEuro;
      }
    }

    let kickbackNetInternalDicount = 0.0;
    if (cdd.kickbackNetPrices) {
      const length = cdd.kickbackNetPrices.length;
      for (let i = 0; i < length; i++) {
        kickbackNetInternalDicount += cdd.kickbackNetPrices[i].kickbackInEuro;
      }
    }

    let additionalCostsDicount = 0.0;
    if (cdd.additionalCostsPrices) {
      const length = cdd.additionalCostsPrices.length;
      for (let i = 0; i < length; i++) {
        additionalCostsDicount += cdd.additionalCostsPrices[i].discountInEuro;
      }
    }

    let workshopCostsDicount = 0.0;
    if (
      cdd.workshopCosts &&
      cdd.workshopCosts.upsaleItems &&
      cdd.workshopCosts.upsaleItems.length > 0
    ) {
      const total = getUpsaleItemsTotal(cdd.workshopCosts.upsaleItems);
      workshopCostsDicount += total.discount;
    }

    let tradeInPrices = 0.0;
    if (cdd.tradeInPrices) {
      tradeInPrices += cdd.tradeInPrices[3].amountNet;
    }

    if (cdd.calculationHeader) {
      if (cdd.vehiclePrices) {
        cdd.calculationHeader.internalDiscount = cdd.vehiclePrices[3].amountNet;
      }
      cdd.calculationHeader.internalDiscount +=
        dicountNetInternalDicount -
        kickbackNetInternalDicount +
        additionalCostsDicount +
        workshopCostsDicount -
        tradeInPrices;
    }

    if (cdd.calculationHeader) {
      cdd.calculationHeader.externalDiscount =
        additionalCostsDicount + workshopCostsDicount;
    }

    if (cdd.calculationHeader && cdd.vehiclePrices) {
      cdd.calculationHeader.grossProfit =
        cdd.vehiclePrices[1].amountNet -
        cdd.vehiclePrices[2].amountNet -
        cdd.calculationHeader.internalDiscount +
        cdd.vehiclePrices[3].amountNet;
    }

    // Calulation for total commission
    if (cdd.calculationHeader && cdd.commissionCalculation) {
      if (cdd.commissionCalculation.vehicleCommission === "Standard") {
        cdd.commissionCalculation.vehicleCommissionEuro =
          calculateVehicleCommissionForDemoCar(cdd);
        cdd.commissionCalculation.totalCommission =
          calculateTotalCommission(cdd);
      }
      if (cdd.commissionCalculation.vehicleCommission === "Fixed") {
        cdd.commissionCalculation.totalCommission =
          calculateTotalCommission(cdd);
        cdd.calculationHeader.totalCommission =
          cdd.commissionCalculation.totalCommission;
      }
    }

    orderContext.updateCtxCalculationDraftDetails(cdd, "DemoCar");
    setCalDraft(cdd);
    //calculateFinalheader
    calculateFinal();
    refreshHeader(cdd);
    setReload(new Date().toLocaleString());
  };
  const calculateTotalCommission = (calDraft: CalculationDraftDTO) => {
    let total = 0.0;
    if (
      calDraft.commissionCalculation &&
      calDraft.commissionCalculation.vehicleCommissionEuro !== undefined
    ) {
      if (calDraft.commissionCalculation.vehicleCommission === "Standard") {
        total = calDraft.commissionCalculation.vehicleCommissionEuro;
        // If commission sharing is active
        if (calDraft.isCommissionSharingActive && calDraft.commissionSharing) {
          total = (total * calDraft.commissionSharing[0].shareInPercent) / 100;
        }
      }

      // vehicle commission is of fixed type then vehicle cmmission is enterd value
      if (calDraft.commissionCalculation.vehicleCommission === "Fixed") {
        total = calDraft.commissionCalculation.vehicleCommissionEuro;
        // If commission sharing is active
        if (calDraft.isCommissionSharingActive && calDraft.commissionSharing) {
          total = (total * calDraft.commissionSharing[0].shareInPercent) / 100;
        }
      }
    }

    if (
      calDraft.commissionCalculation &&
      calDraft.commissionCalculation.carPolicyCommission
    )
      total =
        total +
        (calDraft.commissionCalculation.carPolicyCommission *
          scalingobj.carPolicy) /
          100;
    if (
      calDraft.commissionCalculation &&
      calDraft.commissionCalculation.porscheCardS
    )
      total =
        total +
        (calDraft.commissionCalculation.porscheCardS *
          scalingobj.porscheCardS) /
          100;
    if (
      calDraft.commissionCalculation &&
      calDraft.commissionCalculation?.pfsCommission
    )
      total =
        total +
        (calDraft.commissionCalculation.pfsCommission *
          calculatePFSMaxExtraData()) /
          100;

    if (
      calDraft.commissionCalculation &&
      calDraft.commissionCalculation?.incentive
    )
      total = total + calDraft.commissionCalculation?.incentive;
    return total;
  };
  const calculatePFSMaxExtraData = () => {
    let pfs = scalingobj.pfs;
    let pfsList: Array<number> = [];
    let order = orderContext.getCtxOrderDetails();
    if (
      order.paymentDetails.paymentType === "Leasing_PFS" ||
      order.paymentDetails.paymentType === "Financing_PFS"
    ) {
      pfsList.push(scalingobj.pfs);
    }
    if (order.paymentDetails.gap) {
      pfsList.push(scalingobj.gap);
    }
    if (order.paymentDetails.leasing_s) {
      pfsList.push(scalingobj.leasings);
    }
    if (order.paymentDetails.servicePlus) {
      pfsList.push(scalingobj.servicePlus);
    }
    let sortedList = pfsList.sort(function (x, y) {
      return x - y;
    });

    sortedList.reverse();
    
    return sortedList[0];
  };

  const handleCloseTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  const enableNextButton = (isValid: boolean) => {
    setIsValid(isValid);
  };


  const incentiveProgramCheckers = () => {
    let dom = document.getElementById("ccip");
    if (
      calDraft.commissionCalculation &&
      calDraft.commissionCalculation.incentive > 0
    ) {
      if (
        calDraft.commissionCalculation.incentiveProgram &&
        calDraft.commissionCalculation.incentiveProgram?.trim().length > 0
      ) {
        if (dom) dom.setAttribute("state", "none");
        return true;
      } else {
        if (dom) dom.setAttribute("state", "error");
        return false;
      }
    } else {
      return true;
    }
  };

  const handleNextButton = () => {
    const incentiveProgrammerFlag = incentiveProgramCheckers()
    incentiveProgrammerFlag && onNextTabChange(4);
  };

  //Account via DCS flag
  useEffect(() => {
    const init = async () => {
    const apiResponse = await dcsgetParts(false);
    //
    if(apiResponse.status===type.SUCCESS){
      if (apiResponse.response?.info) {
        setFileInfo(apiResponse.response?.info);
      }
    }
    //setTequipmentCommission(partsDta.result?.info)
    //
    };
    init();
  },[]);

  
  useEffect(()=>{
    let upsaleTotalCommsValue = 0
    if( Object.keys(fileInfo).length>0 && calDraft.commissionCalculation?.totalCommission!==undefined && Object.keys(finalTotalCammission).length > 0  ){
      finalTotalCammission?.upsaleItems?.forEach((item, _i) => {
        if ((item.partType === "tire") && (finalTotalCammission?.upsaleItems.length >0)) {
          upsaleTotalCommsValue +=finalTotalCammission.upsaleItems[_i]?.commission
        } else if((item.partType === "part")&& (finalTotalCammission?.upsaleItems.length >0)){
            upsaleTotalCommsValue +=finalTotalCammission.upsaleItems[_i]?.commission
          } 
        })
      }
      setUpsaleTotalCommission(upsaleTotalCommsValue)
    },[calDraft.commissionCalculation?.totalCommission, finalTotalCammission,fileInfo])
  const handleOpenExit = () => {
    setOpenExit(true);
  };

  const handleCloseExit = () => {
    setOpenExit(false);
  };

  const handleLeaveWithoutSaving = () => {
    setOpenExit(false);
    navigate("/");
  };

  const handleExit = () => {
    window.close();
  };

  const handleSaveAndLeave = () => {
    // Need to handle save
    setOpenExit(false);
    navigate("/");
  };

  const getPurchasePriceNet = () => {
    const mrg = margins.filter(
      (x) => x.categoryType === category && x.marginType === "Commission"
    );
    if (mrg.length > 0) {
      setPurchaseMargin(mrg[0].value);
      vehiclePrices[2].amountNet =
        (vehiclePrices[0].amountNet * mrg[0].value) / 100;
      let cal = orderContext.getCtxCalculationDraftDetails(category);
      cal.vehiclePrices = vehiclePrices;
      //orderContext.updateCtxCalculationDraftDetails(cal, category);
      return vehiclePrices[2].amountNet;
    } else {
      return 0.0;
    }
  };

  const getPurchasePriceGross = () => {
    const mrg = margins.filter(
      (x) => x.categoryType === category && x.marginType === "Commission"
    );
    if (mrg.length > 0) {
      setPurchaseMargin(mrg[0].value);
      vehiclePrices[2].amountGross =
        (vehiclePrices[0].amountGross * mrg[0].value) / 100;
      let cal = orderContext.getCtxCalculationDraftDetails(category);
      cal.vehiclePrices = vehiclePrices;
      orderContext.updateCtxCalculationDraftDetails(cal, category);
      setVehiclePrices(vehiclePrices);
      //Calculate Header
      //calculateHeader(cal);
      return vehiclePrices[2].amountGross;
    } else {
      return 0.0;
    }
  };

  const handleStandingDays = (value: string, elementId: string) => {
    // if (validateCurrencyFormat(value)) {
    if (calDraft.onlineCarSaleInfo) {
      calDraft.onlineCarSaleInfo.standingDays = formatCurrencyToDecimal(value);

      orderContext.updateCtxCalculationDraftDetails(calDraft, category);
      setCalDraft(calDraft);
      //Calculate Header
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else {
    //   
    // }
  };

  const handleOnlineRegistration = (value: string): void => {
    if (calDraft.onlineCarSaleInfo) {
      calDraft.onlineCarSaleInfo.initialRegistration = value;
      orderContext.updateCtxCalculationDraftDetails(calDraft, category);
      setCalDraft(calDraft);
      //Calculate Header
      //calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    } else {
      
    }
  };

  const handleMileage = (value: string, elementId: string) => {
    if (calDraft.onlineCarSaleInfo) {
      calDraft.onlineCarSaleInfo.mileage = formatCurrencyToDecimal(value);
      //calDraft.onlineCarSaleInfo.mileage = value.length > 0 ? parseInt(value) : 0;
      orderContext.updateCtxCalculationDraftDetails(calDraft, category);
      setCalDraft(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleBiddingPrice = (value: string, elementId: string) => {
    // if (validateCurrencyFormat(value)) {
    if (calDraft.onlineCarSaleInfo) {
      calDraft.onlineCarSaleInfo.lastGrossBiddingPrice =
        formatCurrencyToDecimal(value);

      orderContext.updateCtxCalculationDraftDetails(calDraft, category);
      setCalDraft(calDraft);
      //Calculate Header
      //calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else {
    //   
    // }
  };

  const handleOnlineComment = (value: string) => {
    if (calDraft.onlineCarSaleInfo) {
      calDraft.onlineCarSaleInfo.comments = value;
      setCalDraft(calDraft);
      orderContext.updateCtxCalculationDraftDetails(calDraft, category);
      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  // Final calucation
  const calculateFinal = () => {
    final.invoiceNet = getInvoiceNet();
    final.invoiceGross = getInvoiceGross();
    final.preNet = getPredeliveryNet();
    final.preGross = getPredeliveryGross();
    final.paymentNet = getPaymentNet();
    final.paymentGross = getPaymentGross();

    if (calDraft.calculationCustomer && calDraft.calculationPaymentDetails) {
      calDraft.calculationCustomer.invoiceNet = final.invoiceNet;
      calDraft.calculationCustomer.invoiceGross = final.invoiceGross;
      calDraft.calculationCustomer.preDeliveryNet = final.preNet;
      calDraft.calculationCustomer.preDeliveryGross = final.preGross;

      if (calDraft.calculationPaymentDetails.paymentType !== "DebitCredit") {
        calDraft.calculationCustomer.paymentNet = final.paymentNet;
        calDraft.calculationCustomer.paymentGross = final.paymentGross;
      }

      orderContext.updateCtxCalculationDraftDetails(calDraft, category);
      setCalDraft(calDraft);
    }

    setFinal(final);
    
  };

  const getPredeliveryGross = () => {
    let pdg = getPredeliveryNet() * (1 + vat / 100);
    if (order.tradeInDetails) {
      if (calDraft.tradeInPrices) {
        switch (order.tradeInDetails.tradeInType) {
          case "TradeIn_Differential":
            // const tips = calDraft.tradeInPrices.filter(x => x.tradeInColumn === "CarValueCustomer")
            // if (tips.length > 0) {
            //   pdg = pdg - tips[0].amountNet * vat / 100;
            // }
            pdg = pdg - (calDraft.tradeInPrices[1].amountNet * vat) / 100;
            break;
        }
      }
    }

    if (calDraft.calculationPaymentDetails) {
      const checkPaw =
        calDraft.calculationPaymentDetails.includeOptionals?.filter(
          (x) => x === "Paw"
        );
      if (checkPaw?.length === 0) {
        if (calDraft.additionalCostsPrices) {
          const addCost = calDraft.additionalCostsPrices.filter(
            (x) => x.type === "Paw"
          );
          if (addCost.length > 0) {
            pdg = pdg - addCost[0].customerShare * (vat / 100);
          }
        }
      }
    }

    return pdg;
  };

  const getPaymentNet = () => {
    let pn = 0.0;
    if (calDraft.vehiclePrices) {
      pn = pn + calDraft.vehiclePrices[1].amountNet;
    }

    if (calDraft.calculationPaymentDetails) {
      pn = pn - calDraft.calculationPaymentDetails.paymentNet;
    }

    pn = pn + getIncludedCustomerShare();
    return pn;
  };

  const getPaymentGross = () => {
    let pg = getPaymentNet() * (1 + vat / 100);
    if (calDraft.calculationPaymentDetails) {
      const checkPaw =
        calDraft.calculationPaymentDetails.includeOptionals?.filter(
          (x) => x === "Paw"
        );
      if (checkPaw && checkPaw.length > 0) {
        if (calDraft.additionalCostsPrices) {
          const addCost = calDraft.additionalCostsPrices.filter(
            (x) => x.type === "Paw"
          );
          if (addCost.length > 0) {
            pg = pg - addCost[0].amountNet * (vat / 100);
          }
        }
      }
    }
    return pg;
  };

  const handleDelete = (i: number) => {
    setCsError(false);
    if (calDraft.commissionSharing) {
      calDraft.commissionSharing.splice(i, 1);
      let vc = 0.0;
      if (calDraft.calculationHeader && calDraft.commissionCalculation) {
        vc = calDraft.commissionCalculation.vehicleCommissionEuro
          ? calDraft.commissionCalculation.vehicleCommissionEuro
          : 0.0;
      }
      calDraft.commissionSharing.map((sharing) => {
        sharing.shareInPercent = 50.0;
        sharing.shareInEuro = vc * 0.5;
      });

      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      setReload(new Date().toLocaleString());
      calDraft.commissionSharing?.map((sharing, i) => {
        switch (i) {
          case 0:
            if (csRef[0] && csRef[0].euro && csRef[0].euro.current) {
              csRef[0].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[0] && csRef[0].percent && csRef[0].percent.current) {
              csRef[0].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
          case 1:
            if (csRef[1] && csRef[1].euro && csRef[1].euro.current) {
              csRef[1].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[1] && csRef[1].percent && csRef[1].percent.current) {
              csRef[1].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
          case 2:
            if (csRef[2] && csRef[2].euro && csRef[2].euro.current) {
              csRef[2].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[2] && csRef[2].percent && csRef[2].percent.current) {
              csRef[2].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
        }
      });
      handleModified();
    }
  };

  const handleAddSeller = () => {
    setCsError(false);
    if (calDraft.commissionSharing) {
      let vc = 0.0;
      
      if (calDraft.calculationHeader && calDraft.commissionCalculation) {
        vc = calDraft.commissionCalculation.vehicleCommissionEuro
          ? calDraft.commissionCalculation.vehicleCommissionEuro
          : 0.0;
      }

      calDraft.commissionSharing?.map((sharing, i) => {
        sharing.shareInPercent = i === 0 ? 33.34 : 33.33;
        sharing.shareInEuro = i === 0 ? (vc * 33.34) / 100 : (vc * 33.33) / 100;
      });

      if (sellers.length > 2) {
        //const ord = orderContext.getCtxOrderDetails();
        const sellerNotSelected = sellers.filter((x) => {
          if (
            calDraft.commissionSharing &&
            calDraft.commissionSharing.length > 1
          ) {
            if (
              x.id !== calDraft.commissionSharing[0].id &&
              x.id !== calDraft.commissionSharing[1].id
            ) {
              return x;
            }
          }
        });
        if (sellerNotSelected.length > 0) {
          let newSharing = {
            shareCommission: true,
            sellerNames: {
              id: sellerNotSelected[0].id,
            } as CommissionSharingUserDetailsDTO,
            shareInPercent: 33.33,
            shareInEuro: (vc * 33.33) / 100,
          } as unknown as CommissionSharingDTO;
          calDraft.commissionSharing.push(newSharing);
        }
      }

      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());

      calDraft.commissionSharing?.map((sharing, i) => {
        switch (i) {
          case 0:
            if (csRef[0] && csRef[0].euro && csRef[0].euro.current) {
              csRef[0].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[0] && csRef[0].percent && csRef[0].percent.current) {
              csRef[0].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
          case 1:
            if (csRef[1] && csRef[1].euro && csRef[1].euro.current) {
              csRef[1].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[1] && csRef[1].percent && csRef[1].percent.current) {
              csRef[1].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
          case 2:
            if (csRef[2] && csRef[2].euro && csRef[2].euro.current) {
              csRef[2].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[2] && csRef[2].percent && csRef[2].percent.current) {
              csRef[2].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
        }
      });
    }
  };
  const updateSeller = () => {
    if (
      calDraft.commissionCalculation &&
      calDraft.commissionCalculation.vehicleCommission
    ) {
      let vc = 0.0;
      if (calDraft.calculationHeader && calDraft.commissionCalculation) {
        vc = calDraft.commissionCalculation.vehicleCommissionEuro
          ? calDraft.commissionCalculation.vehicleCommissionEuro
          : 0.0;
      }

      calDraft.commissionSharing?.map((sharing, i) => {
        switch (i) {
          case 0:
            if (csRef[0] && csRef[0].euro && csRef[0].euro.current) {
              sharing.shareInEuro = (vc * sharing.shareInPercent) / 100;
              csRef[0].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[0] && csRef[0].percent && csRef[0].percent.current) {
              csRef[0].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
          case 1:
            if (csRef[1] && csRef[1].euro && csRef[1].euro.current) {
              sharing.shareInEuro = (vc * sharing.shareInPercent) / 100;
              csRef[1].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[1] && csRef[1].percent && csRef[1].percent.current) {
              csRef[1].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
          case 2:
            if (csRef[2] && csRef[2].euro && csRef[2].euro.current) {
              sharing.shareInEuro = (vc * sharing.shareInPercent) / 100;
              csRef[2].euro.current.value = formatCurrencyWithNoUnit(
                sharing.shareInEuro
              );
            }
            if (csRef[2] && csRef[2].percent && csRef[2].percent.current) {
              csRef[2].percent.current.value = formatCurrencyWithNoUnit(
                sharing.shareInPercent
              );
            }
            break;
        }
      });
    }
  };
  const handleSeller = (value: string, i: number) => {
    if (calDraft.commissionSharing) {
      let user = sellers.filter((x) => x.id === parseInt(value));
      if (user.length > 0 && user[0].id) {
        calDraft.commissionSharing[i].sellerNames = {
          id: user[0].id,
          ppnName: user[0].ppnFirstName + " " + user[0].ppnLastName,
        };
        orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
        calculateHeader(calDraft);
        handleModified();
        setReload(new Date().toLocaleString());
      }
    }
  };

  const isValidCommissionSharing = (
    commissionSharing: Array<CommissionSharingDTO>
  ) => {
    let percent = 0.0;
    commissionSharing.forEach((sharing) => {
      percent += sharing.shareInPercent;
    });

    // Sum of percentages 100
    if (percent === 100) {
      return true;
    } else if (percent === 99.99 || (percent > 99.99 && percent < 100)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSharingPercent = (
    value: string,
    i: number,
    elementId: string
  ) => {
    //if(validateCurrencyFormat(value)) {
    if (calDraft.commissionSharing) {
      calDraft.commissionSharing[i].shareInPercent =
        formatCurrencyToDecimal(value);
      if (calDraft.calculationHeader && calDraft.commissionCalculation) {
        //let vc = 6.75/100 * calDraft.calculationHeader.grossProfit;
        let vc = calDraft.commissionCalculation.vehicleCommissionEuro
          ? calDraft.commissionCalculation.vehicleCommissionEuro
          : 0.0;
        calDraft.commissionSharing[i].shareInEuro =
          (vc * calDraft.commissionSharing[i].shareInPercent) / 100;
        switch (i) {
          case 0:
            if (csRef[0] && csRef[0].euro && csRef[0].euro.current) {
              csRef[0].euro.current.value = formatCurrencyWithNoUnit(
                calDraft.commissionSharing[i].shareInEuro
              );
            }
            break;
          case 1:
            if (csRef[1] && csRef[1].euro && csRef[1].euro.current) {
              csRef[1].euro.current.value = formatCurrencyWithNoUnit(
                calDraft.commissionSharing[i].shareInEuro
              );
            }
            break;
          case 2:
            if (csRef[2] && csRef[2].euro && csRef[2].euro.current) {
              csRef[2].euro.current.value = formatCurrencyWithNoUnit(
                calDraft.commissionSharing[i].shareInEuro
              );
            }
            break;
        }
      }
      setCsError(!isValidCommissionSharing(calDraft.commissionSharing));
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else{
    //   
    // }
  };

  const handleSharingEuro = (value: string, i: number, elementId: string) => {
    //if(validateCurrencyFormat(value)) {
    if (calDraft.commissionSharing) {
      calDraft.commissionSharing[i].shareInEuro =
        formatCurrencyToDecimal(value);
      if (calDraft.calculationHeader && calDraft.commissionCalculation) {
        //TODO: Need to be modified
        //let vc = 6.75/100 * calDraft.calculationHeader.grossProfit;
        let vc = calDraft.commissionCalculation.vehicleCommissionEuro
          ? calDraft.commissionCalculation.vehicleCommissionEuro
          : 0.0;
        calDraft.commissionSharing[i].shareInPercent =
          (calDraft.commissionSharing[i].shareInEuro / vc) * 100;
        switch (i) {
          case 0:
            if (csRef[0] && csRef[0].percent && csRef[0].percent.current) {
              csRef[0].percent.current.value = formatCurrencyWithNoUnit(
                calDraft.commissionSharing[i].shareInPercent
              );
            }
            break;
          case 1:
            if (csRef[1] && csRef[1].percent && csRef[1].percent.current) {
              csRef[1].percent.current.value = formatCurrencyWithNoUnit(
                calDraft.commissionSharing[i].shareInPercent
              );
            }
            break;
          case 2:
            if (csRef[2] && csRef[2].percent && csRef[2].percent.current) {
              csRef[2].percent.current.value = formatCurrencyWithNoUnit(
                calDraft.commissionSharing[i].shareInPercent
              );
            }
            break;
        }
        setCsError(isValidCommissionSharing(calDraft.commissionSharing));
      }
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else{
    //   
    // }
  };

  const handleVehicleCommission = (value: string) => {
    if(value==="Fixed"){
      setShowFixedCommission(true)
    }
    else{
      setShowFixedCommission(false)
    }
    if (calDraft.commissionCalculation) {
      
      calDraft.commissionCalculation.vehicleCommission = value;
      calDraft.commissionCalculation.vehicleCommissionEuro = 0.0;
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      updateSeller();
      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleVehicleCommissionEuro = (value: string, elementId: string) => {
    // if (validateCurrencyFormat(value)) {
    if (calDraft.commissionCalculation) {
      calDraft.commissionCalculation.vehicleCommissionEuro =
        formatCurrencyToDecimal(value);
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      updateSeller();
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else {
    //   
    // }
  };

  const handlePFS = (value: string, elementId: string) => {
    //if (validateCurrencyFormat(value)) {
    if (calDraft.commissionCalculation) {
      calDraft.commissionCalculation.pfsCommission =
        formatCurrencyToDecimal(value);
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else {
    //   
    // }
  };

  const handleCpc = (value: string, elementId: string) => {
    // if (validateCurrencyFormat(value)) {
    if (calDraft.commissionCalculation) {
      calDraft.commissionCalculation.carPolicyCommission =
        formatCurrencyToDecimal(value);
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else {
    //   
    // }
  };

  const handlePcs = (value: string, elementId: string) => {
    //if (validateCurrencyFormat(value)) {
    if (calDraft.commissionCalculation) {
      calDraft.commissionCalculation.porscheCardS =
        formatCurrencyToDecimal(value);
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else {
    //   
    // }
  };

  const handleIncentive = (value: string, elementId: string) => {
    // if (validateCurrencyFormat(value)) {
    if (calDraft.commissionCalculation) {
      calDraft.commissionCalculation.incentive = formatCurrencyToDecimal(value);
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else {
    //   
    // }
  };

  const handleIncentiveProgram = (value: string, elementId: string) => {
    let dom = document.getElementById("ccip");
    if (calDraft.commissionCalculation) {
      calDraft.commissionCalculation.incentiveProgram = value;
      if(value.trim().length>0){
        if (dom) dom.setAttribute("state", "none");
      }
      orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
      calculateHeader(calDraft);
      handleModified();
      setReload(new Date().toLocaleString());
    }
    // } else {
    //   
    // }
  };

  const calculateVehicleCommissionForDemoCar = (
    calDraft: CalculationDraftDTO
  ) => {
    let vc = 0.0;
    
    if (scalingobj.commissions) {
      //let order = orderContext.getCtxOrderDetails();
      const catObj = order.vehicleDetails.category;

      if (catObj.type === "DemoCar" && catObj.option === "PreSold") {
        
        calculateVariable("DemoCarPreSold");
        if (
          commissionObj.variable === "Discount" &&
          calDraft.calculationHeader?.internalDiscount !== undefined
        ) {
          
          let internaldiscoutPercent = 0.0;

          if (
            calDraft.vehiclePrices &&
            calDraft.vehiclePrices[0]?.amountNet &&
            calDraft.vehiclePrices[0]?.amountNet > 0
          ) {
            internaldiscoutPercent =
              (calDraft.calculationHeader?.internalDiscount /
                calDraft.vehiclePrices[0]?.amountNet) *
              100;
          }

          let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined =
            calculateRangeValue(internaldiscoutPercent, "DemoCarPreSold");
          if (vehicleCommissionDTO != null && commissionObj != null) {
            vc = vehicleCommissionByDiscount(vehicleCommissionDTO);
          }
        } else if (
          commissionObj.variable === "StandingDays" &&
          calDraft?.onlineCarSaleInfo &&
          calDraft?.onlineCarSaleInfo.standingDays !== undefined
        ) {
          
          let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
          let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined =
            calculateRangeValue(standingdays, "DemoCarPreSold");
          if (vehicleCommissionDTO != null && commissionObj != null) {
            vc = vehicleCommissionByDiscount(vehicleCommissionDTO);
          }
        }
      } else if (catObj.type === "DemoCar" && catObj.option === "Stock") {
        
        calculateVariable("DemoCarStock");
        // need to check  with client.
        if (
          commissionObj.variable === "Discount" &&
          calDraft.calculationHeader?.internalDiscount !== undefined
        ) {
          
          let internaldiscoutPercent = 0.0;

          if (
            calDraft.vehiclePrices &&
            calDraft.vehiclePrices[0]?.amountNet &&
            calDraft.vehiclePrices[0]?.amountNet > 0
          ) {
            internaldiscoutPercent =
              (calDraft.calculationHeader?.internalDiscount /
                calDraft.vehiclePrices[0]?.amountNet) *
              100;
          }

          let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined =
            calculateRangeValue(internaldiscoutPercent, "DemoCarStock");
          if (vehicleCommissionDTO != null && commissionObj != null) {
            vc = vehicleCommissionByDiscount(vehicleCommissionDTO);
          }
        } else if (
          commissionObj.variable === "StandingDays" &&
          calDraft?.onlineCarSaleInfo &&
          calDraft?.onlineCarSaleInfo.standingDays !== undefined
        ) {
          
          let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
          let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined =
            calculateRangeValue(standingdays, "DemoCarStock");
          if (vehicleCommissionDTO != null && commissionObj != null) {
            vc = vehicleCommissionByDiscount(vehicleCommissionDTO);
          }
        }
      } else {
        
        calculateVariable("DemoCarStock");
        // need to check  with client.
        if (
          commissionObj.variable === "Discount" &&
          calDraft.calculationHeader?.internalDiscount !== undefined
        ) {
          
          let internaldiscoutPercent = 0.0;

          if (
            calDraft.vehiclePrices &&
            calDraft.vehiclePrices[0]?.amountNet &&
            calDraft.vehiclePrices[0]?.amountNet > 0
          ) {
            internaldiscoutPercent =
              (calDraft.calculationHeader?.internalDiscount /
                calDraft.vehiclePrices[0]?.amountNet) *
              100;
          }
          let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined =
            calculateRangeValue(internaldiscoutPercent, "DemoCarStock");
          if (vehicleCommissionDTO != null && commissionObj != null) {
            vc = vehicleCommissionByDiscount(vehicleCommissionDTO);
          }
        } else if (
          commissionObj.variable === "StandingDays" &&
          calDraft?.onlineCarSaleInfo &&
          calDraft?.onlineCarSaleInfo.standingDays !== undefined
        ) {
          
          let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
          let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined =
            calculateRangeValue(standingdays, "DemoCarStock");
          if (vehicleCommissionDTO != null && commissionObj != null) {
            vc = vehicleCommissionByDiscount(vehicleCommissionDTO);
          }
        }
      }
    }
    setCommissionType("ScalingCommission");
    
    if (order?.orderCreator?.share != undefined) {
      vc = (vc * order?.orderCreator?.share) / 100;
      
    }
    
    if (
      commissionObj.noProfitCommission &&
      commissionObj.noProfitCommissionReference &&
      calDraft?.calculationHeader?.grossProfit &&
      calDraft?.calculationHeader?.grossProfit < 0
    ) {
      if (
        calDraft?.calculationHeader?.grossProfit &&
        commissionObj?.noProfitCommissionValue
      ) {
        vc = vehicleTotalCommissionByReferenceType(
          commissionObj?.noProfitCommissionReference,
          commissionObj?.noProfitCommissionValue
        );
        setCommissionType("NoProfitCommission");
        if (calDraft?.commissionCalculation) {
          calDraft.commissionCalculation.commissionTypeText =
            "NoProfitCommission";
          calDraft.commissionCalculation.referenceType =
            commissionObj?.noProfitCommissionReference;
          calDraft.commissionCalculation.referenceValue =
            commissionObj?.noProfitCommissionValue;
        }
        
      }
    } else if (
      commissionObj.minCommission &&
      commissionObj?.minCommissionReference &&
      commissionObj?.minCommissionValue &&
      vc <
        vehicleTotalCommissionByReferenceType(
          commissionObj?.minCommissionReference,
          commissionObj?.minCommissionValue
        )
    ) {
      let refCal = vehicleTotalCommissionByReferenceType(
        commissionObj?.minCommissionReference,
        commissionObj?.minCommissionValue
      );
      if (vc < refCal) {
        vc = refCal;
        setCommissionType("MinCommission");
        if (calDraft?.commissionCalculation) {
          calDraft.commissionCalculation.commissionTypeText = "MinCommission";
          calDraft.commissionCalculation.referenceType =
            commissionObj?.minCommissionReference;
          calDraft.commissionCalculation.referenceValue =
            commissionObj?.minCommissionValue;
        }
        
      }
    } else if (
      commissionObj.maxCommission &&
      commissionObj?.maxCommissionReference &&
      commissionObj?.maxCommissionValue
    ) {
      let refCal = vehicleTotalCommissionByReferenceType(
        commissionObj?.maxCommissionReference,
        commissionObj?.maxCommissionValue
      );
      if (vc > refCal) {
        vc = refCal;
        setCommissionType("MaxCommission");
        if (calDraft?.commissionCalculation) {
          calDraft.commissionCalculation.commissionTypeText = "MaxCommission";
          calDraft.commissionCalculation.referenceType =
            commissionObj?.maxCommissionReference;
          calDraft.commissionCalculation.referenceValue =
            commissionObj?.maxCommissionValue;
        }
        
      }
    }
    orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");

    return parseFloat(vc.toFixed(2));
  };
  const vehicleTotalCommissionByReferenceType = (
    referenceType: string,
    value: number
  ) => {
    let tc = 0.0;
    
    switch (referenceType) {
      case "Euro":
        tc = value;
        break;

      case "GrossProfit":
        if (calDraft?.calculationHeader?.grossProfit) {
          tc = calDraft.calculationHeader.grossProfit * (value / 100);
        }
        break;
      case "NetTurnover":
        if (
          calDraft?.vehiclePrices &&
          calDraft?.vehiclePrices[1] != undefined
        ) {
          tc = (value / 100) * calDraft?.vehiclePrices[1]?.amountNet;
        }
        break;
    }

    return tc;
  };
  //need to pass the internal discount/standay as range value

  const calculateRangeValue = (rangeValue: number, category: string) => {
    const filterConf: Array<CategoryCommissionDTO> =
      scalingobj.commissions.filter(
        (x) => x.commissionScalingCategoryType === category
      );
    if (filterConf.length > 0) {
      const conf = filterConf[0];
      
      setCommissionObj(conf);
      if (rangeValue >= 0) {
        for (let i = 0; i < conf.vehicleCommissions.length; i++) {
          if (
            conf.vehicleCommissions[i].fromValue <= rangeValue &&
            conf.vehicleCommissions[i].toValue >= rangeValue
          ) {
            
            calDraft.referenceType = conf.vehicleCommissions[i].payoutRefence1;
            calDraft.referenceValue = conf.vehicleCommissions[i].payoutValue1;
            if (calDraft.commissionCalculation) {
              calDraft.commissionCalculation.commissionTypeText =
                "ScalingCommission";
              calDraft.commissionCalculation.referenceType =
                conf.vehicleCommissions[i].payoutRefence1;
              calDraft.commissionCalculation.referenceValue =
                conf.vehicleCommissions[i].payoutValue1;
            }
            if (conf.payOut2) {
              calDraft.payout2 = conf.payOut2;
              calDraft.referenceType2 =
                conf.vehicleCommissions[i].payoutRefence2;
              calDraft.referenceValue2 =
                conf.vehicleCommissions[i].payoutValue2;
              if (calDraft.commissionCalculation) {
                calDraft.commissionCalculation.payout2 = conf.payOut2;
                calDraft.commissionCalculation.referenceType2 =
                  conf.vehicleCommissions[i].payoutRefence2;
                calDraft.commissionCalculation.referenceValue2 =
                  conf.vehicleCommissions[i].payoutValue2;
              }
            }
            orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
            return conf.vehicleCommissions[i];
          }
        }
      } else {
        calDraft.referenceType = conf.vehicleCommissions[0].payoutRefence1;
        calDraft.referenceValue = conf.vehicleCommissions[0].payoutValue1;
        if (calDraft.commissionCalculation) {
          calDraft.commissionCalculation.commissionTypeText =
            "ScalingCommission";
          calDraft.commissionCalculation.referenceType =
            conf.vehicleCommissions[0].payoutRefence1;
          calDraft.commissionCalculation.referenceValue =
            conf.vehicleCommissions[0].payoutValue1;
        }
        if (conf.payOut2) {
          calDraft.payout2 = conf.payOut2;
          calDraft.referenceType2 = conf.vehicleCommissions[0].payoutRefence2;
          calDraft.referenceValue2 = conf.vehicleCommissions[0].payoutValue2;
          if (calDraft.commissionCalculation) {
            calDraft.commissionCalculation.payout2 = conf.payOut2;
            calDraft.commissionCalculation.referenceType2 =
              conf.vehicleCommissions[0].payoutRefence2;
            calDraft.commissionCalculation.referenceValue2 =
              conf.vehicleCommissions[0].payoutValue2;
          }
        }
        orderContext.updateCtxCalculationDraftDetails(calDraft, "DemoCar");
        return conf.vehicleCommissions[0];
      }
      // if  we wont find value and within category range.
      return null;
    }
  };
  const calculateVariable = (category: string) => {
    const filterConf: Array<CategoryCommissionDTO> =
      scalingobj.commissions.filter(
        (x) => x.commissionScalingCategoryType === category
      );
    
    if (filterConf.length > 0) {
      const conf = filterConf[0];
      setCommissionObj(conf);
      //return null;
    }
  };
  const vehicleCommissionByDiscount = (
    vehicleCommission: VehicleCommissionDTO
  ) => {
    let vc = 0.0;
    
    switch (vehicleCommission.payoutRefence1) {
      case "Euro":
        vc = vehicleCommission.payoutValue1;
        break;

      case "GrossProfit":
        if (calDraft?.calculationHeader?.grossProfit) {
          vc =
            (vehicleCommission.payoutValue1 / 100) *
            calDraft.calculationHeader.grossProfit;
        }
        break;
      case "NetTurnover":
        //vehiclePrices[1] -->selling price net
        if (
          calDraft?.vehiclePrices &&
          calDraft?.vehiclePrices[1] != undefined
        ) {
          vc =
            (vehicleCommission.payoutValue1 / 100) *
            calDraft?.vehiclePrices[1]?.amountNet;
        }
        break;
    }
    if (commissionObj.payOut2 === true) {
      switch (vehicleCommission.payoutRefence2) {
        case "Euro":
          if (vehicleCommission.payoutValue2) {
            vc += vehicleCommission.payoutValue2;
          }
          break;

        case "GrossProfit":
          if (
            vehicleCommission.payoutValue2 &&
            calDraft?.calculationHeader?.grossProfit
          ) {
            vc +=
              (vehicleCommission.payoutValue2 / 100) *
              calDraft.calculationHeader.grossProfit;
          }
          break;
        case "NetTurnover":
          //vehiclePrices[1] -->selling price net
          if (
            calDraft?.vehiclePrices &&
            vehicleCommission.payoutValue2 &&
            calDraft?.vehiclePrices[1] != undefined
          ) {
            vc +=
              (vehicleCommission.payoutValue2 / 100) *
              calDraft?.vehiclePrices[1]?.amountNet;
          }
          break;
      }
    }

    return vc;
  };
  
  
  return (
    <>
      {/** Calculation Header **/}
      <div className="gross" style={{ position: "fixed", zIndex: 3 }}>
        <PFlex justifyContent={"space-between"}>
          <PFlexItem>
            <PText>
              <span className="dcs-title">{t("Gross profit") + ": "}</span>
              <span className="dcs-heading">
                {`${
                  calDraft.calculationHeader?.grossProfit &&
                  calDraft?.vehiclePrices &&
                  calDraft?.vehiclePrices[0].amountNet > 0
                    ? formatCurrencyWithNoUnit(
                        (calDraft.calculationHeader.grossProfit /
                          calDraft.vehiclePrices[0].amountNet) *
                          100
                      )
                    : "0,00"
                } %`}
              </span>
              <span className="dcs-title">
                &nbsp;
                {` ${formatCurrencyWithNoUnit(
                  calDraft.calculationHeader?.grossProfit
                    ? calDraft.calculationHeader?.grossProfit
                    : 0.0
                )} €  `}
              </span>
            </PText>
          </PFlexItem>
          <PFlexItem>
            <PText>
              <span className="dcs-title">{t("External discount") + ": "}</span>
              <span className="dcs-heading">
                {`${
                  calDraft.calculationHeader?.externalDiscount &&
                  calDraft?.vehiclePrices &&
                  calDraft?.vehiclePrices[0].amountNet > 0
                    ? formatCurrencyWithNoUnit(
                        (calDraft.calculationHeader.externalDiscount /
                          calDraft.vehiclePrices[0].amountNet) *
                          100
                      )
                    : "0,00"
                } %`}
              </span>
              <span className="dcs-title">
                &nbsp;
                {` ${formatCurrencyWithNoUnit(
                  calDraft.calculationHeader?.externalDiscount
                    ? calDraft.calculationHeader?.externalDiscount
                    : 0.0
                )} €  `}
              </span>
            </PText>
          </PFlexItem>
          <PFlexItem>
            <PText>
              <span className="dcs-title">{t("Internal discount") + ": "}</span>
              <span className="dcs-heading">
                {`${
                  calDraft.calculationHeader?.internalDiscount &&
                  calDraft?.vehiclePrices &&
                  calDraft?.vehiclePrices[0].amountNet > 0
                    ? formatCurrencyWithNoUnit(
                        (calDraft.calculationHeader.internalDiscount /
                          calDraft.vehiclePrices[0].amountNet) *
                          100
                      )
                    : "0,00"
                } %`}
              </span>
              <span className="dcs-title">
                &nbsp;
                {` ${formatCurrencyWithNoUnit(
                  calDraft.calculationHeader?.internalDiscount
                    ? calDraft.calculationHeader?.internalDiscount
                    : 0.0
                )} €  `}
              </span>
            </PText>
          </PFlexItem>
          <PFlexItem>
            <PText>
              <span className="dcs-title">{t("Total commission") + ": "}</span>
              <span className="dcs-heading">
                {canViewCommission && (
                  <>
                    {   showFixedCommission? ` ${formatCurrencyWithNoUnit(negativeValueHandler(calDraft.commissionCalculation?.totalCommission
                        ? calDraft.commissionCalculation?.totalCommission
                        : 0.0)
                      
                    )} €  ` : ` ${formatCurrencyWithNoUnit(negativeValueHandler(  (calDraft.commissionCalculation?.totalCommission !==undefined && calDraft.commissionCalculation?.totalCommission!==null)
                      ? fileInfo.tequipmentCommission ? calDraft.commissionCalculation?.totalCommission + upsaleTotalCommission : calDraft.commissionCalculation?.totalCommission
                      : 0.0
                  ))
                    } €  `}


                  </>
                )}
              </span>
            </PText>
          </PFlexItem>
        </PFlex>
      </div>

      {/** Vehicle Price **/}
      <div className="order-card vehicle-price" style={{ marginTop: "71px" }}>
        <div>
          <PText>
            <span className="dcs-section-heading">{t("Vehicle Price")}</span>
          </PText>
        </div>
        <div>
          <PGrid>
            <PGridItem size={3}></PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Amount (net)")}</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Amount (gross)")}</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={5}></PGridItem>
          </PGrid>
          <PDivider color="neutral-contrast-high"></PDivider>

          {/** Demo Car  **/}

          {category === "DemoCar" && calDraft.vehiclePrices && (
            <>
              <PGrid style={{ marginTop: "10px" }}>
                <PGridItem size={3}>
                  <PText style={{ paddingTop: "5px" }}>
                    <span className="dcs-title">
                      {t("RRP (original price)")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  </PText>
                </PGridItem>
                <PGridItem size={2}>
                  <PTextFieldWrapper
                    id="vprrpn0"
                    unit="€"
                    unitPosition="suffix"
                    showCharacterCount={false}
                  >
                    <input
                      maxLength={inputFieldMaxLength}
                      aria-autocomplete="none"
                      autoComplete="off"
                      id="ivprrpn0"
                      key="kvprrpn0"
                      type="text"
                      ref={vp[0].net}
                      defaultValue={formatCurrencyWithNoUnit(
                        vehiclePrices?.[0].amountNet
                      )}
                      //value={formatCurrencyWithNoUnit(vehiclePrices?.[0].amountNet)}
                      onChange={(e) =>
                        handleAmountNet(e.target.value, 0, "vprrpn0")
                      }
                      onBlur={(e) => {
                        handleAmountNet(e.target.value, 0, "vprrpn0");
                        if (vp[0].net.current)
                          vp[0].net.current.value = formatCurrencyWithNoUnit(
                            vehiclePrices?.[0].amountNet
                          );
                      }}
                      style={{ textAlign: "right" }}
                      disabled={disabled}
                    />
                  </PTextFieldWrapper>
                </PGridItem>
                <PGridItem size={2}>
                  <PTextFieldWrapper
                    id="vprrpg0"
                    unit="€"
                    unitPosition="suffix"
                    showCharacterCount={false}
                  >
                    <input
                      maxLength={inputFieldMaxLength}
                      aria-autocomplete="none"
                      autoComplete="off"
                      ref={vp[0].gross}
                      id="ivprrpg0"
                      key="kvpbpg0"
                      type="text"
                      //defaultValue={ formatCurrencyWithNoUnit(vehiclePrices?.[0].amountGross) }
                      defaultValue={formatCurrencyWithNoUnit(
                        vehiclePrices?.[0].amountGross
                      )}
                      onChange={(e) =>
                        handleAmountGross(e.target.value, 0, "vprrpg0")
                      }
                      onBlur={(e) => {
                        handleAmountGross(e.target.value, 0, "vprrpg0");
                        if (vp[0].gross.current)
                          vp[0].gross.current.value = formatCurrencyWithNoUnit(
                            vehiclePrices?.[0].amountGross
                          );
                      }}
                      style={{ textAlign: "right" }}
                      disabled={disabled}
                    />
                  </PTextFieldWrapper>
                </PGridItem>
                <PGridItem size={5}></PGridItem>
              </PGrid>

              <PGrid style={{ marginTop: "10px" }}>
                <PGridItem size={3}>
                  <PText style={{ paddingTop: "5px" }}>
                    <span className="dcs-title">
                      {t("Selling price")}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  </PText>
                </PGridItem>
                <PGridItem size={2}>
                  <PTextFieldWrapper id="vpon1" unit="€" unitPosition="suffix" showCharacterCount={false}>
                    <input
                      maxLength={inputFieldMaxLength}
                      aria-autocomplete="none"
                      autoComplete="off"
                      id="ivpon1"
                      key="ivpon1"
                      type="text"
                      ref={vp[1].net}
                      //defaultValue={ formatCurrencyWithNoUnit(vehiclePrices?.[1].amountNet) }
                      defaultValue={formatCurrencyWithNoUnit(
                        vehiclePrices?.[1].amountNet
                      )}
                      onChange={(e) =>
                        handleAmountNet(e.target.value, 1, "vpon1")
                      }
                      onBlur={(e) => {
                        handleAmountNet(e.target.value, 1, "vpon1");
                        if (vp[1].net.current)
                          vp[1].net.current.value = formatCurrencyWithNoUnit(
                            vehiclePrices?.[1].amountNet
                          );
                      }}
                      style={{ textAlign: "right" }}
                      disabled={disabled}
                    />
                  </PTextFieldWrapper>
                </PGridItem>
                <PGridItem size={2}>
                  <PTextFieldWrapper id="vpog1" unit="€" unitPosition="suffix" showCharacterCount={false}>
                    <input
                      maxLength={inputFieldMaxLength}
                      aria-autocomplete="none"
                      autoComplete="off"
                      id="ivpog1"
                      key="ivpog1"
                      type="text"
                      ref={vp[1].gross}
                      //defaultValue={ formatCurrencyWithNoUnit(vehiclePrices?.[1].amountGross) }
                      defaultValue={formatCurrencyWithNoUnit(
                        vehiclePrices?.[1].amountGross
                      )}
                      onChange={(e) =>
                        handleAmountGross(e.target.value, 1, "vpog1")
                      }
                      onBlur={(e) => {
                        handleAmountGross(e.target.value, 1, "vpog1");
                        if (vp[1].gross.current)
                          vp[1].gross.current.value = formatCurrencyWithNoUnit(
                            vehiclePrices?.[1].amountGross
                          );
                      }}
                      style={{ textAlign: "right" }}
                      disabled={disabled}
                    />
                  </PTextFieldWrapper>
                </PGridItem>
                <PGridItem size={5}></PGridItem>
              </PGrid>

              <PGrid style={{ marginTop: "10px" }}>
                <PGridItem size={3}>
                  <PFlex justifyContent={"space-between"}>
                    <PFlexItem>
                      <PText style={{ paddingTop: "5px" }}>
                        <span className="dcs-title">{t("Purchase price")}</span>
                      </PText>
                    </PFlexItem>
                    <PFlexItem style={{ paddingTop: "10px" }}>
                      <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                        purchaseMargin
                      )}%`}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </PGridItem>
                <PGridItem size={2}>
                  <div className="read">
                    <PorscheFont>
                      {formatCurrencyWithNoUnit(vehiclePrices?.[2].amountNet)}{" "}
                      &nbsp;&nbsp;
                      <span style={{ color: "#626669" }}>{"€"}</span>
                    </PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={2}>
                  <div className="read">
                    <PorscheFont>
                      {formatCurrencyWithNoUnit(vehiclePrices?.[2].amountGross)}{" "}
                      &nbsp;&nbsp;
                      <span style={{ color: "#626669" }}>{"€"}</span>
                    </PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={5}></PGridItem>
              </PGrid>

              <PGrid style={{ marginTop: "10px" }}>
                <PGridItem size={3}>
                  <PFlex justifyContent={"space-between"}>
                    <PFlexItem>
                      <PText style={{ paddingTop: "5px" }}>
                        <span className="dcs-title">{t("Discount")}</span>
                      </PText>
                    </PFlexItem>
                    <PFlexItem style={{ paddingTop: "10px" }}>
                      {/* <PorscheFont className='dcs-heading'>{`${formatCurrencyWithNoUnit(parseFloat(vpPercent.toFixed(2)))}%`}</PorscheFont> */}
                      <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                        vehiclePrices[0].amountNet > 0
                          ? (vehiclePrices[3].amountNet /
                              vehiclePrices[0].amountNet) *
                              100
                          : 0.0
                      )}%`}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </PGridItem>
                <PGridItem size={2}>
                  <div className="read">
                    <PorscheFont>
                      {formatCurrencyWithNoUnit(vehiclePrices?.[3].amountNet)}{" "}
                      &nbsp;&nbsp;
                      <span style={{ color: "#626669" }}>{"€"}</span>
                    </PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={2}>
                  <div className="read">
                    <PorscheFont>
                      {formatCurrencyWithNoUnit(vehiclePrices?.[3].amountGross)}{" "}
                      &nbsp;&nbsp;
                      <span style={{ color: "#626669" }}>{"€"}</span>
                    </PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={5}></PGridItem>
              </PGrid>
            </>
          )}
        </div>
      </div>

      {/** Information from online car sales */}
      <div className="order-card vehicle-price">
        <div>
          <PText>
            <span className="dcs-section-heading">
              {t("Information from Online Car sales")}
            </span>
          </PText>
        </div>

        <div>
          <PGrid>
            <PGridItem size={3}></PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Information")}</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={7}></PGridItem>
          </PGrid>
        </div>

        <PDivider color="neutral-contrast-high"></PDivider>

        {category === "DemoCar" && (
          <>
            <PGrid style={{ marginTop: "10px" }}>
              <PGridItem size={3}>
                <PText style={{ paddingTop: "5px" }}>
                  <span className="dcs-title">
                    {t("Standing days")}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                </PText>
              </PGridItem>
              <PGridItem size={2}>
                <PTextFieldWrapper id={`oisd`} showCharacterCount={false}>
                  <input
                    maxLength={inputFieldMaxLength}
                    aria-autocomplete="none"
                    autoComplete="off"
                    id={`ioisd`}
                    key={`koisd`}
                    type="text"
                    //defaultValue={ formatCurrencyWithNoUnit(item.discountInEuro) }
                    defaultValue={formatToWholeNumber(
                      calDraft.onlineCarSaleInfo?.standingDays
                        ? calDraft.onlineCarSaleInfo.standingDays
                        : 0
                    )}
                    onChange={(e) => handleStandingDays(e.target.value, "oisd")}
                    style={{ textAlign: "right" }}
                    disabled={disabled}
                  />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={7}></PGridItem>
            </PGrid>

            <PGrid style={{ marginTop: "10px" }}>
              <PGridItem size={3}>
                <PText style={{ paddingTop: "5px" }}>
                  <span className="dcs-title">
                    {t("Initial registration")}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                </PText>
              </PGridItem>
              <PGridItem size={2}>
                <PTextFieldWrapper showCharacterCount={false}>
                  <input
                    maxLength={inputFieldMaxLength}
                    type="month"
                    name="registration"
                    value={calDraft?.onlineCarSaleInfo?.initialRegistration}
                    required
                    onChange={(e) => handleOnlineRegistration(e.target.value)}
                    disabled={disabled}
                  />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={7}></PGridItem>
            </PGrid>

            <PGrid style={{ marginTop: "10px" }}>
              <PGridItem size={3}>
                <PText style={{ paddingTop: "5px" }}>
                  <span className="dcs-title">
                    {t("Mileage")}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                </PText>
              </PGridItem>
              <PGridItem size={2}>
                <PTextFieldWrapper
                  id={`mileage`}
                  unit="km"
                  unitPosition="suffix"
                  showCharacterCount={false}
                >
                  <input
                    maxLength={inputFieldMaxLength}
                    aria-autocomplete="none"
                    autoComplete="off"
                    id={`imileage`}
                    key={`kmileage`}
                    type="text"
                    ref={mRef}
                    //defaultValue={ formatCurrencyWithNoUnit(item.discountInEuro) }
                    defaultValue={
                      calDraft.onlineCarSaleInfo?.mileage
                        ? formatToWholeNumber(
                            calDraft.onlineCarSaleInfo.mileage
                          )
                        : 0
                    }
                    onChange={(e) => handleMileage(e.target.value, "mileage")}
                    onBlur={(e) => {
                      handleMileage(e.target.value, "mileage");
                      if (mRef.current)
                        mRef.current.value = formatToWholeNumber(
                          calDraft.onlineCarSaleInfo?.mileage
                            ? calDraft.onlineCarSaleInfo.mileage
                            : 0
                        );
                    }}
                    style={{ textAlign: "right" }}
                    disabled={disabled}
                  />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={7}></PGridItem>
            </PGrid>

            <PGrid style={{ marginTop: "10px" }}>
              <PGridItem size={3}>
                <PText style={{ paddingTop: "5px" }}>
                  <span className="dcs-title">
                    {t("Last gross bidding price")}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                </PText>
              </PGridItem>
              <PGridItem size={2}>
                <PTextFieldWrapper id={`lgbp`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                  <input
                    maxLength={inputFieldMaxLength}
                    aria-autocomplete="none"
                    autoComplete="off"
                    id={`ilgbp`}
                    key={`klgbp`}
                    type="text"
                    ref={bpRef}
                    //defaultValue={ formatCurrencyWithNoUnit(item.discountInEuro) }
                    defaultValue={formatCurrencyWithNoUnit(
                      calDraft.onlineCarSaleInfo?.lastGrossBiddingPrice
                        ? calDraft.onlineCarSaleInfo?.lastGrossBiddingPrice
                        : 0.0
                    )}
                    onChange={(e) => handleBiddingPrice(e.target.value, "lgbp")}
                    onBlur={(e) => {
                      handleBiddingPrice(e.target.value, "lgbp");
                      if (bpRef.current)
                        bpRef.current.value = formatCurrencyWithNoUnit(
                          calDraft.onlineCarSaleInfo?.lastGrossBiddingPrice
                            ? calDraft.onlineCarSaleInfo?.lastGrossBiddingPrice
                            : 0.0
                        );
                    }}
                    style={{ textAlign: "right" }}
                    disabled={disabled}
                  />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={7}></PGridItem>
            </PGrid>

            <PDivider
              style={{ marginTop: "20px", marginBottom: "24px" }}
              color="neutral-contrast-low"
            ></PDivider>
            <PGrid>
              <PGridItem size={12}>
                <PText style={{ paddingBottom: "4px" }}>
                  <span className="dcs-title">{t("Comment (Optional)")}</span>
                </PText>
              </PGridItem>
              <PGridItem size={12}>
                <PTextFieldWrapper id={`oncomment`}>
                  <input
                    aria-autocomplete="none"
                    autoComplete="off"
                    id={`ioncomment`}
                    key={`koncomment`}
                    type="text"
                    defaultValue={
                      calDraft?.onlineCarSaleInfo?.comments
                        ? calDraft?.onlineCarSaleInfo?.comments
                        : ""
                    }
                    onChange={(e) => handleOnlineComment(e.target.value)}
                    disabled={disabled}
                  />
                </PTextFieldWrapper>
              </PGridItem>
            </PGrid>
          </>
        )}
      </div>

      {/** Discount Net **/}
      <div className="order-card vehicle-price">
        <div>
          <PText>
            <span className="dcs-section-heading">{t("Discount (net)")}</span>
          </PText>
        </div>
        <div>
          <PGrid>
            <PGridItem size={3}></PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Discount in")} €</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Discount in")} %</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={5}></PGridItem>
          </PGrid>

          <PDivider color="neutral-contrast-high"></PDivider>
          {category === "DemoCar" && (
            <>
              {discountNetPrices?.map((item, i) => {
                if (i === 0 && order.referral === null) return <></>;

                if (item.displayLabel === "Referral commission") {
                  if (order.referral) {
                    if (order.referral.referralType === "Internal_Empl") {
                      return <></>;
                    }
                  } else {
                    return <></>;
                  }
                }

                return (
                  <PGrid style={{ marginTop: "10px" }}>
                    <PGridItem size={3}>
                      <PText style={{ paddingTop: "5px" }}>
                        <span className="dcs-title">
                          {t(labels.demoCar[i])}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      </PText>
                    </PGridItem>
                    <PGridItem size={2}>
                      <PTextFieldWrapper
                        id={`dnde${i}`}
                        unit="€"
                        unitPosition="suffix"
                        showCharacterCount={false}
                      >
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete="none"
                          autoComplete="off"
                          id={`idnde${i}`}
                          key={`idnde${i}`}
                          type="text"
                          ref={dref[i].euro}
                          //defaultValue={ formatCurrencyWithNoUnit(item.discountInEuro) }
                          defaultValue={formatCurrencyWithNoUnit(
                            item.discountInEuro
                          )}
                          onChange={(e) =>
                            handleDiscountEuro(e.target.value, i, `idnde${i}`)
                          }
                          onBlur={(e) => {
                            handleDiscountEuro(e.target.value, i, `idnde${i}`);
                            if (i === 0 && dref[0].euro.current)
                              dref[0].euro.current.value =
                                formatCurrencyWithNoUnit(item.discountInEuro);
                            if (i === 1 && dref[1].euro.current)
                              dref[1].euro.current.value =
                                formatCurrencyWithNoUnit(item.discountInEuro);
                          }}
                          style={{ textAlign: "right" }}
                          disabled={disabled}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={2}>
                      <PTextFieldWrapper
                        id={`dndp${i}`}
                        unit="%"
                        unitPosition="suffix"
                        showCharacterCount={false}
                      >
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete="none"
                          autoComplete="off"
                          id={`dndp${i}`}
                          key={`dndp${i}`}
                          type="text"
                          ref={dref[i].percent}
                          defaultValue={formatCurrencyWithNoUnit(
                            item.discountInPercent
                          )}
                          //value={formatCurrencyWithNoUnit(item.discountInPercent)}
                          onChange={(e) =>
                            handleDiscountPercentage(
                              e.target.value,
                              i,
                              `dndp${i}`
                            )
                          }
                          onBlur={(e) => {
                            handleDiscountPercentage(
                              e.target.value,
                              i,
                              `dndp${i}`
                            );
                            if (i === 0 && dref[0].percent.current)
                              dref[0].percent.current.value =
                                formatCurrencyWithNoUnit(
                                  item.discountInPercent
                                );
                            if (i === 1 && dref[1].percent.current)
                              dref[1].percent.current.value =
                                formatCurrencyWithNoUnit(
                                  item.discountInPercent
                                );
                          }}
                          style={{ textAlign: "right" }}
                          disabled={disabled}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={5}></PGridItem>
                  </PGrid>
                );
              })}

              <PDivider
                style={{ marginTop: "20px", marginBottom: "24px" }}
                color="neutral-contrast-low"
              ></PDivider>
              <PGrid>
                <PGridItem size={12}>
                  <PText style={{ paddingBottom: "4px" }}>
                    <span className="dcs-title">{t("Comment (Optional)")}</span>
                  </PText>
                </PGridItem>
                <PGridItem size={12}>
                  <PTextFieldWrapper id={`dncomment`}>
                    <input
                      aria-autocomplete="none"
                      autoComplete="off"
                      id={`dncomment`}
                      key={`dncomment`}
                      type="text"
                      //defaultValue={ calDraft.discountComment ?  calDraft.discountComment : ""}
                      defaultValue={
                        calDraft.discountComment ? calDraft.discountComment : ""
                      }
                      onChange={(e) => handleDiscountComment(e.target.value)}
                      disabled={disabled}
                    />
                  </PTextFieldWrapper>
                </PGridItem>
              </PGrid>
            </>
          )}
        </div>
      </div>

      {/** Kickback (net) **/}
      <div className="order-card vehicle-price">
        <div>
          <PText>
            <span className="dcs-section-heading">{t("Kickback (net)")}</span>
          </PText>
        </div>
        <div>
          <PGrid>
            <PGridItem size={3}></PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Kickback in")} €</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Kickback in")} %</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={5}></PGridItem>
          </PGrid>

          <PDivider color="neutral-contrast-high"></PDivider>

          {kickbackNetPrices?.map((item, i) => {
            return (
              <>
                <PGrid style={{ marginTop: "10px" }}>
                  <PGridItem size={3}>
                    <PText style={{ paddingTop: "5px" }}>
                      <span className="dcs-title">
                        {t(kickbackLabels.demoCar[i])}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    </PText>
                  </PGridItem>
                  <PGridItem size={2}>
                    <PTextFieldWrapper
                      id={`knde${i}`}
                      unit="€"
                      unitPosition="suffix"
                      showCharacterCount={false}
                    >
                      <input
                        maxLength={inputFieldMaxLength}
                        ref={kbRef[i].euro}
                        aria-autocomplete="none"
                        autoComplete="off"
                        id={`iknde${i}`}
                        key={`iknde${i}`}
                        type="text"
                        //defaultValue={ formatCurrencyWithNoUnit(item.kickbackInEuro) }
                        defaultValue={formatCurrencyWithNoUnit(
                          item.kickbackInEuro
                        )}
                        onChange={(e) =>
                          handleKickbackEuro(e.target.value, i, `iknde${i}`)
                        }
                        onBlur={(e) => {
                          handleKickbackEuro(e.target.value, i, `iknde${i}`);
                          if (i === 0 && kbRef[0].euro.current)
                            kbRef[0].euro.current.value =
                              formatCurrencyWithNoUnit(item.kickbackInEuro);
                          //if(i === 1 && kbRef[1].euro.current) kbRef[1].euro.current.value = formatCurrencyWithNoUnit(item.kickbackInEuro)
                        }}
                        style={{ textAlign: "right" }}
                        disabled={disabled}
                      />
                    </PTextFieldWrapper>
                  </PGridItem>
                  <PGridItem size={2}>
                    <PTextFieldWrapper
                      id={`kndp${i}`}
                      unit="%"
                      unitPosition="suffix"
                      showCharacterCount={false}
                    >
                      <input
                        maxLength={inputFieldMaxLength}
                        ref={kbRef[i].percent}
                        aria-autocomplete="none"
                        autoComplete="off"
                        id={`ikndp${i}`}
                        key={`idndp${i}`}
                        type="text"
                        //defaultValue={ formatCurrencyWithNoUnit(item.kickbackInPercent) }
                        defaultValue={formatCurrencyWithNoUnit(
                          item.kickbackInPercent
                        )}
                        onChange={(e) =>
                          handleKickbackPercentage(
                            e.target.value,
                            i,
                            `kndp${i}`
                          )
                        }
                        onBlur={(e) => {
                          handleKickbackPercentage(
                            e.target.value,
                            i,
                            `kndp${i}`
                          );
                          if (i === 0 && kbRef[0].percent.current)
                            kbRef[0].percent.current.value =
                              formatCurrencyWithNoUnit(item.kickbackInPercent);
                          //if(i === 1 && kbRef[1].percent.current) kbRef[1].percent.current.value = formatCurrencyWithNoUnit(item.kickbackInPercent)
                        }}
                        style={{ textAlign: "right" }}
                        disabled={disabled}
                      />
                    </PTextFieldWrapper>
                  </PGridItem>
                  <PGridItem size={5}></PGridItem>
                </PGrid>
              </>
            );
          })}
          {kickbackNetPrices?.length > 0 &&
            kickbackNetPrices?.[0].kickbackInEuro! > 0 && (
              <>
                <PGrid style={{ marginTop: "10px" }}>
                  <PGridItem size={3}>
                    <PText style={{ paddingTop: "5px" }}>
                      <span className="dcs-title">
                        {t("Description")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    </PText>
                  </PGridItem>
                  <PGridItem size={4}>
                    <PTextFieldWrapper id={`kndescription`}>
                      <input
                        aria-autocomplete="none"
                        autoComplete="off"
                        id={`kndescription`}
                        key={`kndescription`}
                        type="text"
                        //defaultValue={ calDraft.kickbackDescription ?  calDraft.kickbackDescription : ""}
                        defaultValue={
                          calDraft.kickbackDescription
                            ? calDraft.kickbackDescription
                            : ""
                        }
                        onChange={(e) =>
                          handleKickbackDescription(e.target.value)
                        }
                        disabled={disabled}
                      />
                    </PTextFieldWrapper>
                  </PGridItem>
                </PGrid>
                <PGrid style={{ marginTop: "10px" }}>
                  <PGridItem size={3}></PGridItem>
                  <PGridItem size={4} style={{ color: "#909090" }}>
                    <PIcon name={"information" as IconName} />
                    {t("Please describe sales promotion.")}
                  </PGridItem>
                </PGrid>
              </>
            )}
        </div>
      </div>

      {/** Additional Costs start*/}
      <div className="order-card vehicle-price">
        <div>
          <PText>
            <span className="dcs-section-heading">{t("Additional costs")}</span>
          </PText>
        </div>
        <div>
          <PGrid>
            <PGridItem size={3}></PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Amount (net)")}</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Customer share")}</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Discount in")} €</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={2}>
              <div style={{ textAlign: "right" }}>
                <PorscheFont>
                  <span className="dcs-heading">{t("Discount in")} %</span>
                </PorscheFont>
              </div>
            </PGridItem>
            <PGridItem size={1}></PGridItem>
          </PGrid>

          <PDivider color="neutral-contrast-high"></PDivider>

          {(category === "NewCar" ||
            category === "DemoCar" ||
            category === "UsedCar") && (
            <>
              {additionalCosts?.map((item, i) => {
                if (
                  item.type === "Registration" &&
                  item.label === "Registration by customer"
                )
                  return <></>;
                if (item.type === "Paw" && item.label === item.type)
                  return <></>;
                if (item.type === "Assistance" && item.label === item.type)
                  return <></>;

                if (item.label.length > 0 && item.label !== "Registration") {
                  return (
                    <PGrid style={{ marginTop: "10px" }}>
                      <PGridItem size={3}>
                        <PText style={{ paddingTop: "5px" }}>
                          <span className="dcs-title">
                            {t(item.label)}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        </PText>
                      </PGridItem>
                      <PGridItem size={2}>
                        {item.label === "Transfer (Other)" ||
                        item.label === "Home Delivery" ||
                        item.label === "Export (European union)" ||
                        item.label === "Export (European economy area)" ||
                        item.label === "Export (Third country)" ||
                        item.type === "Others" ? (
                          <PTextFieldWrapper
                            id={`acan${i}`}
                            unit="€"
                            unitPosition="suffix"
                            showCharacterCount={false}
                          >
                            <input
                              maxLength={inputFieldMaxLength}
                              aria-autocomplete="none"
                              autoComplete="off"
                              ref={acRef[i].net}
                              id={`iacan${i}`}
                              key={`iacan${i}`}
                              type="text"
                              defaultValue={formatCurrencyWithNoUnit(
                                item.amountNet
                              )}
                              onChange={(e) =>
                                handleAddAmountGross(
                                  e.target.value,
                                  i,
                                  `acan${i}`
                                )
                              }
                              onBlur={(e) => {
                                handleAddAmountGross(
                                  e.target.value,
                                  i,
                                  `acan${i}`
                                );
                                if (i === 0 && acRef[0].net.current)
                                  acRef[0].net.current.value =
                                    formatCurrencyWithNoUnit(item.amountNet);
                                if (i === 1 && acRef[1].net.current)
                                  acRef[1].net.current.value =
                                    formatCurrencyWithNoUnit(item.amountNet);
                                if (i === 2 && acRef[2].net.current)
                                  acRef[2].net.current.value =
                                    formatCurrencyWithNoUnit(item.amountNet);
                                if (i === 3 && acRef[3].net.current)
                                  acRef[3].net.current.value =
                                    formatCurrencyWithNoUnit(item.amountNet);
                                if (i === 4 && acRef[4].net.current)
                                  acRef[4].net.current.value =
                                    formatCurrencyWithNoUnit(item.amountNet);
                              }}
                              style={{ textAlign: "right" }}
                              disabled={disabled}
                            />
                          </PTextFieldWrapper>
                        ) : (
                          <div className="read">
                            {formatCurrencyWithNoUnit(item.amountNet)}{" "}
                            &nbsp;&nbsp;
                            <span style={{ color: "#626669" }}>{"€"}</span>
                          </div>
                        )}
                      </PGridItem>
                      <PGridItem size={2}>
                        <PTextFieldWrapper
                          id={`accs${i}`}
                          unit="€"
                          unitPosition="suffix"
                          showCharacterCount={false}
                        >
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete="none"
                            autoComplete="off"
                            ref={acRef[i].share}
                            id={`iaccs${i}`}
                            key={`kaccs${i}`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(
                              item.customerShare
                            )}
                            onChange={(e) =>
                              handleCustomerShare(e.target.value, i, `accs${i}`)
                            }
                            onBlur={(e) => {
                              handleCustomerShare(
                                e.target.value,
                                i,
                                `accs${i}`
                              );
                              if (i === 0 && acRef[0].share.current)
                                acRef[0].share.current.value =
                                  formatCurrencyWithNoUnit(item.customerShare);
                              if (i === 1 && acRef[1].share.current)
                                acRef[1].share.current.value =
                                  formatCurrencyWithNoUnit(item.customerShare);
                              if (i === 2 && acRef[2].share.current)
                                acRef[2].share.current.value =
                                  formatCurrencyWithNoUnit(item.customerShare);
                              if (i === 3 && acRef[3].share.current)
                                acRef[3].share.current.value =
                                  formatCurrencyWithNoUnit(item.customerShare);
                              if (i === 4 && acRef[4].share.current)
                                acRef[4].share.current.value =
                                  formatCurrencyWithNoUnit(item.customerShare);
                            }}
                            style={{ textAlign: "right" }}
                            disabled={disabled}
                          />
                        </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={2}>
                        {/* <div className="read">
                              <PorscheFont>{ formatCurrencyWithNoUnit(item.discountInEuro)} &nbsp;&nbsp;<span style={{color: "#626669"}}>{"€"}</span></PorscheFont>
                            </div> */}
                        <PTextFieldWrapper
                          id={`acde${i}`}
                          unit="€"
                          unitPosition="suffix"
                          showCharacterCount={false}
                        >
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete="none"
                            autoComplete="off"
                            ref={acRef[i].euro}
                            id={`iacde${i}`}
                            key={`kacde${i}`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(
                              item.discountInEuro
                            )}
                            style={{ textAlign: "right" }}
                            readOnly
                          />
                        </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={2}>
                        {/* <div className="read">
                            <PorscheFont></PorscheFont>{ formatCurrencyWithNoUnit(item.discountInPercent)} &nbsp;&nbsp;<span style={{color: "#626669"}}>{"%"}</span>
                            </div> */}
                        <PTextFieldWrapper
                          id={`acdp${i}`}
                          unit="%"
                          unitPosition="suffix"
                          showCharacterCount={false}
                        >
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete="none"
                            autoComplete="off"
                            ref={acRef[i].percent}
                            id={`iacdp${i}`}
                            key={`kacdp${i}`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(
                              item.discountInPercent
                            )}
                            style={{ textAlign: "right" }}
                            readOnly
                          />
                        </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={1}></PGridItem>
                    </PGrid>
                  );
                }
              })}
            </>
          )}

          <PDivider
            style={{ marginTop: "20px", marginBottom: "24px" }}
            color="neutral-contrast-low"
          ></PDivider>
          <PGrid>
            <PGridItem size={12}>
              <PText style={{ paddingBottom: "4px" }}>
                <span className="dcs-title">
                  {additionalCosts &&
                  additionalCosts.length > 0 &&
                  additionalCosts[4].amountNet > 0 ? (
                    <>
                      {t("Description other")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </>
                  ) : (
                    t("Comment (Optional)")
                  )}
                </span>
              </PText>
            </PGridItem>
            <PGridItem size={12}>
              <PTextFieldWrapper id={`accomment`}>
                <input
                  aria-autocomplete="none"
                  autoComplete="off"
                  id={`iaccomment`}
                  key={`kaccomment`}
                  type="text"
                  defaultValue={
                    calDraft.additionalCostPriceComment
                      ? calDraft.additionalCostPriceComment
                      : ""
                  }
                  onChange={(e) => handleAdditionalCostComment(e.target.value)}
                  disabled={disabled}
                />
              </PTextFieldWrapper>
            </PGridItem>
          </PGrid>
        </div>
      </div>
      {/** Additional Costs end*/}

      {/** Workshop Costs start*/}
      {/* {orderContext?.getCtxUpsaleDetails()?.upsaleItems?.length > 0 && ( */}
      {workshopCosts?.upsaleItems?.length > 0 && (
        <div className="order-card vehicle-price">
          <div>
            <PText>
              <span className="dcs-section-heading">{t("Workshop costs")}</span>
            </PText>
          </div>
          <div>
            <PGrid>
              <PGridItem size={3}></PGridItem>
              <PGridItem size={2}>
                <div style={{ textAlign: "right" }}>
                  <PorscheFont>
                    <span className="dcs-heading">{t("Amount (net)")}</span>
                  </PorscheFont>
                </div>
              </PGridItem>
              <PGridItem size={2}>
                <div style={{ textAlign: "right" }}>
                  <PorscheFont>
                    <span className="dcs-heading">{t("Customer share")}</span>
                  </PorscheFont>
                </div>
              </PGridItem>
              <PGridItem size={2}>
                <div style={{ textAlign: "right" }}>
                  <PorscheFont>
                    <span className="dcs-heading">{t("Discount in")} €</span>
                  </PorscheFont>
                </div>
              </PGridItem>
              <PGridItem size={2}>
                <div style={{ textAlign: "right" }}>
                  <PorscheFont>
                    <span className="dcs-heading">{t("Discount in")} %</span>
                  </PorscheFont>
                </div>
              </PGridItem>
              <PGridItem size={1}></PGridItem>
            </PGrid>

            <PDivider color="neutral-contrast-high"></PDivider>
            {/* { orderContext?.getCtxUpsaleDetails()?.upsaleItems?.map((item, i) => { */}
            {workshopCosts?.upsaleItems?.map((item, i) => {
              if (item.partType === "tire") {
                return (
                  <>
                    <PGrid style={{ marginTop: "10px" }}>
                      <PGridItem size={3}>
                        <PText style={{ paddingTop: "5px" }}>
                          <span className="dcs-title">
                            {t("Complete Winter Tires")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        </PText>
                      </PGridItem>
                      <PGridItem size={2}>
                        <div className="read">
                          <PFlex justifyContent={"space-between"}>
                            <PFlexItem alignSelf={"flex-end"}>
                              {!(item.customerShare > 0) && (
                                <PPopover
                                  style={{
                                    paddingTop: "12px",
                                    paddingLeft: "4px",
                                  }}
                                  direction={"bottom"}
                                >
                                  {t(
                                    "For gifted items, the internal price is applied."
                                  )}
                                </PPopover>
                              )}
                            </PFlexItem>
                            <PFlexItem>
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(
                                  getAmountNet(item, false, 0)
                                )}{" "}
                                &nbsp;&nbsp;
                                <span style={{ color: "#626669" }}>{"€"}</span>
                              </PorscheFont>
                            </PFlexItem>
                          </PFlex>
                          {/* <PorscheFont>
                              { formatCurrencyWithNoUnit(getAmountNet(item, false, 0)) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"€"}</span>
                              </PorscheFont> */}
                        </div>
                      </PGridItem>
                      <PGridItem size={2}>
                        <PTextFieldWrapper
                          id={`wscwt`}
                          unit="€"
                          unitPosition="suffix"
                          showCharacterCount={false}
                        >
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete="none"
                            autoComplete="off"
                            ref={cwtRef}
                            id={`iwscwt`}
                            key={`iwscwt`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(
                              item.customerShare
                            )}
                            //value={formatCurrencyWithNoUnit(item.customerShare)}
                            onChange={(e) =>
                              handleWsCustomerShare(
                                e.target.value,
                                "tire",
                                false,
                                0,
                                `iwscwt`
                              )
                            }
                            onBlur={(e) => {
                              handleWsCustomerShare(
                                e.target.value,
                                "tire",
                                false,
                                0,
                                `iwscwt`
                              );
                              if (cwtRef.current)
                                cwtRef.current.value = formatCurrencyWithNoUnit(
                                  item.customerShare
                                );
                            }}
                            style={{ textAlign: "right" }}
                            disabled={true}
                          />
                        </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={2}>
                        <div className="read">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              getAmountNet(item, false, 0) - item.customerShare
                            )}{" "}
                            &nbsp;&nbsp;
                            <span style={{ color: "#626669" }}>{"€"}</span>
                          </PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={2}>
                        <div className="read">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              getPercent(item, false, 0)
                            )}{" "}
                            &nbsp;&nbsp;
                            <span style={{ color: "#626669" }}>{"%"}</span>
                          </PorscheFont>
                        </div>
                      </PGridItem>
                    </PGrid>

                    {item.serviceItems.map((service, j) => {
                      return (
                        <PGrid style={{ marginTop: "10px" }}>
                          <PGridItem size={3}>
                            <PText style={{ paddingTop: "5px" }}>
                              <span className="dcs-title">
                                {t(
                                  getWorkshopServiceName(
                                    item.serviceItems[j].services
                                      .serviceNameUpsale,
                                    j === 0 ? "winter" : "summer"
                                  )
                                )}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            </PText>
                          </PGridItem>
                          <PGridItem size={2}>
                            <div className="read">
                              <PFlex justifyContent={"space-between"}>
                                <PFlexItem alignSelf={"flex-end"}>
                                  {!(
                                    item.serviceItems[j].customerShare > 0
                                  ) && (
                                    <PPopover
                                      style={{
                                        paddingTop: "12px",
                                        paddingLeft: "4px",
                                      }}
                                      direction={"bottom"}
                                    >
                                      {t(
                                        "For gifted items, the internal price is applied."
                                      )}
                                    </PPopover>
                                  )}
                                </PFlexItem>
                                <PFlexItem>
                                  <PorscheFont>
                                    {formatCurrencyWithNoUnit(
                                      getAmountNet(item, true, j)
                                    )}{" "}
                                    &nbsp;&nbsp;
                                    <span style={{ color: "#626669" }}>
                                      {"€"}
                                    </span>
                                  </PorscheFont>
                                </PFlexItem>
                              </PFlex>
                              {/* <PorscheFont>
                                    { formatCurrencyWithNoUnit(getAmountNet(item, true, j)) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"€"}</span>
                                    </PorscheFont> */}
                            </div>
                          </PGridItem>
                          <PGridItem size={2}>
                            <PTextFieldWrapper
                              id={`wscwts${j}`}
                              unit="€"
                              unitPosition="suffix"
                              showCharacterCount={false}
                            >
                              <input
                                maxLength={inputFieldMaxLength}
                                aria-autocomplete="none"
                                autoComplete="off"
                                ref={cwtServiceRef[j].share}
                                id={`wscwts${j}`}
                                key={`wscwts${j}`}
                                type="text"
                                defaultValue={formatCurrencyWithNoUnit(
                                  item.serviceItems[j].customerShare
                                )}
                                //value={formatCurrencyWithNoUnit(item.serviceItems[j].customerShare)}
                                onChange={(e) =>
                                  handleWsCustomerShare(
                                    e.target.value,
                                    "tire",
                                    true,
                                    j,
                                    `wscwts${j}`
                                  )
                                }
                                onBlur={(e) => {
                                  handleWsCustomerShare(
                                    e.target.value,
                                    "tire",
                                    true,
                                    j,
                                    `wscwts${j}`
                                  );
                                  if (j === 0 && cwtServiceRef[0].share.current)
                                    cwtServiceRef[0].share.current.value =
                                      formatCurrencyWithNoUnit(
                                        item.serviceItems[0].customerShare
                                      );
                                  if (j === 1 && cwtServiceRef[1].share.current)
                                    cwtServiceRef[1].share.current.value =
                                      formatCurrencyWithNoUnit(
                                        item.serviceItems[1].customerShare
                                      );
                                }}
                                style={{ textAlign: "right" }}
                                disabled={true}
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={2}>
                            <div className="read">
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(service.discount)}
                                &nbsp;&nbsp;
                                <span style={{ color: "#626669" }}>{"€"}</span>
                              </PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={2}>
                            <div className="read">
                              <PorscheFont>
                                {/* { formatCurrencyWithNoUnit(getPercent(item, true, j)) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"%"}</span> */}
                                {/* { formatCurrencyWithNoUnit( (getAmountNet(item, true, j) - service.customerShare) / getAmountNet(item, true, j) * 100) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"%"}</span> */}
                                {calDraft.vehiclePrices
                                  ? category === "NewCar"
                                    ? formatCurrencyWithNoUnit(
                                        ((getAmountNet(item, true, j) -
                                          service.customerShare) /
                                          50) *
                                          100
                                      )
                                    : category === "DemoCar" ||
                                      category === "UsedCar"
                                    ? formatCurrencyWithNoUnit(
                                        ((getAmountNet(item, true, j) -
                                          service.customerShare) /
                                          calDraft.vehiclePrices[0].amountNet) *
                                          100
                                      )
                                    : ""
                                  : "0.00"}
                                &nbsp;&nbsp;
                                <span style={{ color: "#626669" }}>{"%"}</span>
                              </PorscheFont>
                            </div>
                          </PGridItem>
                        </PGrid>
                      );
                    })}
                  </>
                );
              }
            })}

            {/** Single part */}
            {parts &&
              parts.length === 1 &&
              orderContext
                ?.getCtxUpsaleDetails()
                ?.upsaleItems?.map((item, i) => {
                  if (item.partType === "part") {
                    return (
                      <>
                        <PGrid style={{ marginTop: "10px" }}>
                          <PGridItem size={3}>
                            <PText style={{ paddingTop: "5px" }}>
                              <span className="dcs-title">
                                {t("PDS / Tequipment")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            </PText>
                          </PGridItem>
                          <PGridItem size={2}>
                            <div className="read">
                              <PFlex justifyContent={"space-between"}>
                                <PFlexItem alignSelf={"flex-end"}>
                                  {!(item.customerShare > 0) && (
                                    <PPopover
                                      style={{
                                        paddingTop: "12px",
                                        paddingLeft: "4px",
                                      }}
                                      direction={"bottom"}
                                    >
                                      {t(
                                        "For gifted items, the internal price is applied."
                                      )}
                                    </PPopover>
                                  )}
                                </PFlexItem>
                                <PFlexItem>
                                  <PorscheFont>
                                    {formatCurrencyWithNoUnit(
                                      getAmountNet(item, false, 0)
                                    )}{" "}
                                    &nbsp;&nbsp;
                                    <span style={{ color: "#626669" }}>
                                      {"€"}
                                    </span>
                                  </PorscheFont>
                                </PFlexItem>
                              </PFlex>
                              {/* <PorscheFont>
                            { formatCurrencyWithNoUnit(getAmountNet(item, false, 0)) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"€"}</span>
                            </PorscheFont> */}
                            </div>
                          </PGridItem>
                          <PGridItem size={2}>
                            <PTextFieldWrapper
                              id={`wspds`}
                              unit="€"
                              unitPosition="suffix"
                              showCharacterCount={false}
                            >
                              <input
                                maxLength={inputFieldMaxLength}
                                aria-autocomplete="none"
                                autoComplete="off"
                                ref={pdsRef}
                                id={`iwspds`}
                                key={`iwspds`}
                                type="text"
                                defaultValue={formatCurrencyWithNoUnit(
                                  item.customerShare
                                )}
                                //value={formatCurrencyWithNoUnit(item.customerShare)}
                                onChange={(e) =>
                                  handleWsCustomerShare(
                                    e.target.value,
                                    "part",
                                    false,
                                    0,
                                    `wspds`
                                  )
                                }
                                onBlur={(e) => {
                                  handleWsCustomerShare(
                                    e.target.value,
                                    "part",
                                    false,
                                    0,
                                    `wspds`
                                  );
                                  if (pdsRef.current)
                                    pdsRef.current.value =
                                      formatCurrencyWithNoUnit(
                                        item.customerShare
                                      );
                                }}
                                style={{ textAlign: "right" }}
                                disabled={true}
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={2}>
                            <div className="read">
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(item.discount)}
                                &nbsp;&nbsp;
                                <span style={{ color: "#626669" }}>{"€"}</span>
                              </PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={2}>
                            <div className="read">
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(
                                  getPercent(item, false, 0)
                                )}{" "}
                                &nbsp;&nbsp;
                                <span style={{ color: "#626669" }}>{"%"}</span>
                              </PorscheFont>
                            </div>
                          </PGridItem>
                        </PGrid>

                        {item.serviceItems.map((service, j) => {
                          if (!(service.services.priceBeforeCustomerNet > 0))
                            return <></>;

                          return (
                            <PGrid style={{ marginTop: "10px" }}>
                              <PGridItem size={3}>
                                <PText style={{ paddingTop: "5px" }}>
                                  <span className="dcs-title">
                                    {t("Installation (PDS/TEQ)")}{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                </PText>
                              </PGridItem>
                              <PGridItem size={2}>
                                <div className="read">
                                  <PFlex justifyContent={"space-between"}>
                                    <PFlexItem alignSelf={"flex-end"}>
                                      {!(
                                        item.serviceItems[j].customerShare > 0
                                      ) && (
                                        <PPopover
                                          style={{
                                            paddingTop: "12px",
                                            paddingLeft: "4px",
                                          }}
                                          direction={"bottom"}
                                        >
                                          {t(
                                            "For gifted items, the internal price is applied."
                                          )}
                                        </PPopover>
                                      )}
                                    </PFlexItem>
                                    <PFlexItem>
                                      <PorscheFont>
                                        {formatCurrencyWithNoUnit(
                                          getAmountNet(item, true, j)
                                        )}{" "}
                                        &nbsp;&nbsp;
                                        <span style={{ color: "#626669" }}>
                                          {"€"}
                                        </span>
                                      </PorscheFont>
                                    </PFlexItem>
                                  </PFlex>
                                  {/* <PorscheFont>
                                  { formatCurrencyWithNoUnit(getAmountNet(item, true, j)) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"€"}</span>
                                  </PorscheFont> */}
                                </div>
                              </PGridItem>
                              <PGridItem size={2}>
                                <PTextFieldWrapper
                                  id={`wspdss${j}`}
                                  unit="€"
                                  unitPosition="suffix"
                                  showCharacterCount={false}
                                >
                                  <input
                                    maxLength={inputFieldMaxLength}
                                    aria-autocomplete="none"
                                    autoComplete="off"
                                    ref={pdsServiceRef}
                                    id={`iwspdss${j}`}
                                    key={`kwspdss${j}`}
                                    type="text"
                                    //defaultValue={ formatCurrencyWithNoUnit(item.customerShare) }
                                    defaultValue={formatCurrencyWithNoUnit(
                                      item.serviceItems[j].customerShare
                                    )}
                                    onChange={(e) =>
                                      handleWsCustomerShare(
                                        e.target.value,
                                        "part",
                                        true,
                                        j,
                                        `wspdss${j}`
                                      )
                                    }
                                    onBlur={(e) => {
                                      handleWsCustomerShare(
                                        e.target.value,
                                        "part",
                                        true,
                                        j,
                                        `wspdss${j}`
                                      );
                                      if (pdsServiceRef.current)
                                        pdsServiceRef.current.value =
                                          formatCurrencyWithNoUnit(
                                            item.serviceItems[j].customerShare
                                          );
                                    }}
                                    style={{ textAlign: "right" }}
                                    disabled={true}
                                  />
                                </PTextFieldWrapper>
                              </PGridItem>
                              <PGridItem size={2}>
                                <div className="read">
                                  <PorscheFont>
                                    {formatCurrencyWithNoUnit(service.discount)}
                                    &nbsp;&nbsp;
                                    <span style={{ color: "#626669" }}>
                                      {"€"}
                                    </span>
                                  </PorscheFont>
                                </div>
                              </PGridItem>
                              <PGridItem size={2}>
                                <div className="read">
                                  <PorscheFont>
                                    {formatCurrencyWithNoUnit(
                                      getPercent(item, true, j)
                                    )}{" "}
                                    &nbsp;&nbsp;
                                    <span style={{ color: "#626669" }}>
                                      {"%"}
                                    </span>
                                  </PorscheFont>
                                </div>
                              </PGridItem>
                            </PGrid>
                          );
                        })}
                      </>
                    );
                  }
                })}

            {/** Multiple parts */}
            {parts && parts.length > 1 && (
              <>
                <PGrid style={{ marginTop: "10px" }}>
                  <PGridItem size={3}>
                    <PText style={{ paddingTop: "5px" }}>
                      <span className="dcs-title">
                        {t("PDS / Tequipment")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    </PText>
                  </PGridItem>
                  <PGridItem size={2}>
                    <div className="read">
                      <PFlex justifyContent={"space-between"}>
                        <PFlexItem alignSelf={"flex-end"}>
                          {!(getPartTotals().customerShare > 0) && (
                            <PPopover
                              style={{ paddingTop: "12px", paddingLeft: "4px" }}
                              direction={"bottom"}
                            >
                              {t(
                                "For gifted items, the internal price is applied."
                              )}
                            </PPopover>
                          )}
                        </PFlexItem>
                        <PFlexItem>
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              getPartTotals().amountNet
                            )}{" "}
                            &nbsp;&nbsp;
                            <span style={{ color: "#626669" }}>{"€"}</span>
                          </PorscheFont>
                        </PFlexItem>
                      </PFlex>
                      {/* <PorscheFont>
                      { formatCurrencyWithNoUnit(getPartTotals().amountNet) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"€"}</span>
                      </PorscheFont> */}
                    </div>
                  </PGridItem>
                  <PGridItem size={2}>
                    <div className="read">
                      <PFlex justifyContent={"space-between"}>
                        <PFlexItem alignSelf={"flex-end"}>
                          <PPopover
                            style={{ paddingTop: "12px", paddingLeft: "4px" }}
                            direction={"bottom"}
                          >
                            {t("This order has multiple parts.")}
                          </PPopover>
                        </PFlexItem>
                        <PFlexItem>
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              getPartTotals().customerShare
                            )}{" "}
                            &nbsp;&nbsp;
                            <span style={{ color: "#626669" }}>{"€"}</span>
                          </PorscheFont>
                        </PFlexItem>
                      </PFlex>
                      {/* <PorscheFont>
                      { formatCurrencyWithNoUnit(getPartTotals().customerShare) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"€"}</span>
                      </PorscheFont> */}
                    </div>
                  </PGridItem>
                  <PGridItem size={2}>
                    <div className="read">
                      <PorscheFont>
                        {formatCurrencyWithNoUnit(getPartTotals().discountEuro)}{" "}
                        &nbsp;&nbsp;
                        <span style={{ color: "#626669" }}>{"€"}</span>
                      </PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={2}>
                    <div className="read">
                      <PorscheFont>
                        {formatCurrencyWithNoUnit(
                          getPartTotals().discountPercent
                        )}{" "}
                        &nbsp;&nbsp;
                        <span style={{ color: "#626669" }}>{"%"}</span>
                      </PorscheFont>
                    </div>
                  </PGridItem>
                </PGrid>

                {getPartTotals().serviceNet > 0 && (
                  <PGrid style={{ marginTop: "10px" }}>
                    <PGridItem size={3}>
                      <PText style={{ paddingTop: "5px" }}>
                        <span className="dcs-title">
                          {t("Installation  (PDS/TEQ)")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      </PText>
                    </PGridItem>
                    <PGridItem size={2}>
                      <div className="read">
                        <PFlex justifyContent={"space-between"}>
                          <PFlexItem alignSelf={"flex-end"}>
                            {!(getPartTotals().serviceShare > 0) && (
                              <PPopover
                                style={{
                                  paddingTop: "12px",
                                  paddingLeft: "4px",
                                }}
                                direction={"bottom"}
                              >
                                {t(
                                  "For gifted items, the internal price is applied."
                                )}
                              </PPopover>
                            )}
                          </PFlexItem>
                          <PFlexItem>
                            <PorscheFont>
                              {formatCurrencyWithNoUnit(
                                getPartTotals().serviceNet
                              )}{" "}
                              &nbsp;&nbsp;
                              <span style={{ color: "#626669" }}>{"€"}</span>
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                      </div>
                    </PGridItem>
                    <PGridItem size={2}>
                      <div className="read">
                        <PFlex justifyContent={"space-between"}>
                          <PFlexItem alignSelf={"flex-end"}>
                            <PPopover
                              style={{ paddingTop: "12px", paddingLeft: "4px" }}
                              direction={"bottom"}
                            >
                              {t("This order has multiple parts.")}
                            </PPopover>
                          </PFlexItem>
                          <PFlexItem>
                            <PorscheFont>
                              {formatCurrencyWithNoUnit(
                                getPartTotals().serviceShare
                              )}{" "}
                              &nbsp;&nbsp;
                              <span style={{ color: "#626669" }}>{"€"}</span>
                            </PorscheFont>
                          </PFlexItem>
                        </PFlex>
                        {/* <PorscheFont>
                        { formatCurrencyWithNoUnit(getPartTotals().serviceShare) } &nbsp;&nbsp;<span style={{color: "#626669"}}>{"€"}</span>
                        </PorscheFont> */}
                      </div>
                    </PGridItem>
                    <PGridItem size={2}>
                      <div className="read">
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            getPartTotals().serviceEuro
                          )}{" "}
                          &nbsp;&nbsp;
                          <span style={{ color: "#626669" }}>{"€"}</span>
                        </PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={2}>
                      <div className="read">
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            getPartTotals().servicePercent
                          )}{" "}
                          &nbsp;&nbsp;
                          <span style={{ color: "#626669" }}>{"%"}</span>
                        </PorscheFont>
                      </div>
                    </PGridItem>
                  </PGrid>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {/** Workshop Costs end*/}

      {/** Trade In */}
      {order.tradeInDetails && (
        <div className="order-card vehicle-price">
          <div>
            <PText>
              <span className="dcs-section-heading">
                {t(getTradeInHeaderLabel(order?.tradeInDetails?.tradeInType))}
              </span>
            </PText>
          </div>
          <div>
            <PGrid>
              <PGridItem size={3}></PGridItem>
              <PGridItem size={2}>
                <div style={{ textAlign: "right" }}>
                  <PorscheFont>
                    <span className="dcs-heading">{t("Amount (net)")}</span>
                  </PorscheFont>
                </div>
              </PGridItem>
              <PGridItem size={2}>
                <div style={{ textAlign: "right" }}>
                  <PorscheFont>
                    <span className="dcs-heading">{t("Amount (gross)")}</span>
                  </PorscheFont>
                </div>
              </PGridItem>
              <PGridItem size={2}>
                <div style={{ textAlign: "right" }}>
                  <PorscheFont>
                    <span className="dcs-heading">
                      {t("Discount in") + " %"}
                    </span>
                  </PorscheFont>
                </div>
              </PGridItem>
              <PGridItem size={5}></PGridItem>
            </PGrid>

            <PDivider color="neutral-contrast-high"></PDivider>
            {tradeInFields?.map((trade, i) => {
              if (i > 2) {
                let show = false;
                if (
                  order.tradeInDetails &&
                  (order.tradeInDetails.tradeInType === "Leasing_Redemption" ||
                    order.tradeInDetails.tradeInType ===
                      "Financing_Redemption_Regular" ||
                    order.tradeInDetails.tradeInType ===
                      "Financing_Redemption_Differential") &&
                  tradeInFields[2].amountNet < 0
                ) {
                  show = true;
                }
                // if (tradeInFields[2].amountNet < 0) show = true;
                if (!show) return <></>;
              }

              if (i === 2) {
                return (
                  <PGrid style={{ marginTop: "10px" }}>
                    <PGridItem size={3}>
                      <PText style={{ paddingTop: "5px" }}>
                        <span className="dcs-title">
                          {t(getTradeInName(trade.tradeInColumn))}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      </PText>
                    </PGridItem>
                    <PGridItem size={2}>
                      <div className="read">
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(trade.amountNet)}{" "}
                          &nbsp;&nbsp;
                          <span style={{ color: "#626669" }}>{"€"}</span>
                        </PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={2}>
                      <div className="read">
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(trade.amountGross)}{" "}
                          &nbsp;&nbsp;
                          <span style={{ color: "#626669" }}>{"€"}</span>
                        </PorscheFont>
                      </div>
                    </PGridItem>
                    {order.tradeInDetails &&
                      order.tradeInDetails.tradeInType !==
                        "Leasing_Redemption" &&
                      order.tradeInDetails.tradeInType !==
                        "Financing_Redemption_Regular" &&
                      order.tradeInDetails.tradeInType !==
                        "Financing_Redemption_Differential" && (
                        <PGridItem size={2}>
                          <div className="read">
                            <PorscheFont>
                              {category === "DemoCar" &&
                              calDraft?.vehiclePrices &&
                              calDraft?.vehiclePrices[0].amountNet > 0
                                ? formatCurrencyWithNoUnit(
                                    (tradeInFields[2].amountNet /
                                      calDraft?.vehiclePrices[0]?.amountNet) *
                                      -1 *
                                      100
                                  )
                                : "0.00"}{" "}
                              &nbsp;&nbsp;
                              <span style={{ color: "#626669" }}>{"%"}</span>
                            </PorscheFont>
                          </div>
                        </PGridItem>
                      )}

                    {order.tradeInDetails &&
                      (order.tradeInDetails.tradeInType ===
                        "Leasing_Redemption" ||
                        order.tradeInDetails.tradeInType ===
                          "Financing_Redemption_Regular" ||
                        order.tradeInDetails.tradeInType ===
                          "Financing_Redemption_Differential") &&
                      tradeInFields[2].amountNet >= 0 && (
                        <PGridItem size={2}>
                          <div className="read">
                            <PorscheFont>
                              {category === "DemoCar" &&
                              calDraft?.vehiclePrices &&
                              calDraft?.vehiclePrices[0].amountNet > 0
                                ? formatCurrencyWithNoUnit(
                                    (tradeInFields[2].amountNet /
                                      calDraft?.vehiclePrices[0]?.amountNet) *
                                      -1 *
                                      100
                                  )
                                : "0.00"}{" "}
                              &nbsp;&nbsp;
                              <span style={{ color: "#626669" }}>{"%"}</span>
                            </PorscheFont>
                          </div>
                        </PGridItem>
                      )}

                    <PGridItem size={5}></PGridItem>
                  </PGrid>
                );
              }

              return (
                <>
                  <PGrid style={{ marginTop: "10px" }}>
                    <PGridItem size={3}>
                      <PText style={{ paddingTop: "5px" }}>
                        <span className="dcs-title">
                          {t(getTradeInName(trade.tradeInColumn))}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      </PText>
                    </PGridItem>
                    <PGridItem size={2}>
                      <PTextFieldWrapper
                        id={`tian${i}`}
                        unit="€"
                        unitPosition="suffix"
                        showCharacterCount={false}
                      >
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete="none"
                          autoComplete="off"
                          ref={tRef[i].net}
                          id={`itian${i}`}
                          key={`itian${i}`}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(
                            trade.amountNet
                          )}
                          onChange={(e) =>
                            handleTradeInAmountNet(
                              e.target.value,
                              i,
                              `itian${i}`
                            )
                          }
                          onBlur={(e) => {
                            handleTradeInAmountNet(
                              e.target.value,
                              i,
                              `itian${i}`
                            );
                            if (i === 0 || i === 1)
                              validateTradeInFields(
                                e.target.value,
                                i,
                                `itian${i}`
                              );
                            if (i === 0 && tRef[0].net.current)
                              tRef[0].net.current.value =
                                formatCurrencyWithNoUnit(trade.amountNet);
                            if (i === 1 && tRef[1].net.current)
                              tRef[1].net.current.value =
                                formatCurrencyWithNoUnit(trade.amountNet);
                            if (i === 2 && tRef[2].net.current)
                              tRef[2].net.current.value =
                                formatCurrencyWithNoUnit(trade.amountNet);
                            if (i === 3 && tRef[3].net.current)
                              tRef[3].net.current.value =
                                formatCurrencyWithNoUnit(trade.amountNet);
                            if (i === 4 && tRef[4].net.current)
                              tRef[4].net.current.value =
                                formatCurrencyWithNoUnit(trade.amountNet);
                          }}
                          style={{ textAlign: "right" }}
                          disabled={disabled}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={2}>
                      <PTextFieldWrapper
                        id={`tiag${i}`}
                        unit="€"
                        unitPosition="suffix"
                        showCharacterCount={false}
                      >
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete="none"
                          ref={tRef[i].gross}
                          autoComplete="off"
                          id={`itiag${i}`}
                          key={`itiag${i}`}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(
                            trade.amountGross
                          )}
                          onChange={(e) =>
                            handleTradeInAmountGross(
                              e.target.value,
                              i,
                              `tiag${i}`
                            )
                          }
                          onBlur={(e) => {
                            handleTradeInAmountGross(
                              e.target.value,
                              i,
                              `tiag${i}`
                            );
                            if (i === 0 || i === 1)
                              validateTradeInFields(
                                e.target.value,
                                i,
                                `tiag${i}`
                              );
                            if (i === 0 && tRef[0].gross.current)
                              tRef[0].gross.current.value =
                                formatCurrencyWithNoUnit(trade.amountGross);
                            if (i === 1 && tRef[1].gross.current)
                              tRef[1].gross.current.value =
                                formatCurrencyWithNoUnit(trade.amountGross);
                            if (i === 2 && tRef[2].gross.current)
                              tRef[2].gross.current.value =
                                formatCurrencyWithNoUnit(trade.amountGross);
                            if (i === 3 && tRef[3].gross.current)
                              tRef[3].gross.current.value =
                                formatCurrencyWithNoUnit(trade.amountGross);
                            if (i === 4 && tRef[4].gross.current)
                              tRef[4].gross.current.value =
                                formatCurrencyWithNoUnit(trade.amountGross);
                          }}
                          style={{ textAlign: "right" }}
                          disabled={disabled}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    {order.tradeInDetails &&
                      (order.tradeInDetails.tradeInType ===
                        "Leasing_Redemption" ||
                        order.tradeInDetails.tradeInType ===
                          "Financing_Redemption_Regular" ||
                        order.tradeInDetails.tradeInType ===
                          "Financing_Redemption_Differential") &&
                      tradeInFields[2].amountNet < 0 &&
                      i === 3 && (
                        <PGridItem size={2}>
                          <div className="read">
                            <PorscheFont>
                              {category === "DemoCar" &&
                              calDraft?.vehiclePrices &&
                              calDraft?.vehiclePrices[0].amountNet > 0
                                ? formatCurrencyWithNoUnit(
                                    (tradeInFields[3].amountNet /
                                      calDraft?.vehiclePrices[0]?.amountNet) *
                                      -1 *
                                      100
                                  )
                                : "0.00"}{" "}
                              &nbsp;&nbsp;
                              <span style={{ color: "#626669" }}>{"%"}</span>
                            </PorscheFont>
                          </div>
                        </PGridItem>
                      )}
                    <PGridItem size={5}></PGridItem>
                  </PGrid>
                  {i === 0 && (
                    <>
                      {showErrorCarInternal && (
                        <PGrid
                          style={{ marginTop: "10px", marginBottom: "30px" }}
                        >
                          <PGridItem size={3}></PGridItem>
                          <PGridItem size={4}>
                            <Stack direction={"row"}>
                              <PIcon
                                name={"information" as IconName}
                                color="brand"
                              ></PIcon>
                              <PorscheFont
                                style={{
                                  fontSize: "16px",
                                  color: "#D5001B",
                                  marginTop: "0px",
                                }}
                              >
                                {t("Amount can not be 0,00 € or negative")}
                              </PorscheFont>
                            </Stack>
                          </PGridItem>
                          <PGridItem size={5}></PGridItem>
                        </PGrid>
                      )}
                    </>
                  )}
                  {i === 1 && (
                    <>
                      {showErrorCarCustomer && (
                        <PGrid
                          style={{ marginTop: "10px", marginBottom: "30px" }}
                        >
                          <PGridItem size={3}></PGridItem>
                          <PGridItem size={4}>
                            <Stack direction={"row"}>
                              <PIcon
                                name={"information" as IconName}
                                color="brand"
                              ></PIcon>
                              <PorscheFont
                                style={{
                                  fontSize: "16px",
                                  color: "#D5001B",
                                  marginTop: "0px",
                                }}
                              >
                                {t("Amount can not be 0,00 € or negative")}
                              </PorscheFont>
                            </Stack>
                          </PGridItem>
                          <PGridItem size={5}></PGridItem>
                        </PGrid>
                      )}
                    </>
                  )}
                </>
              );
            })}

            <PDivider
              style={{ marginTop: "20px", marginBottom: "24px" }}
              color="neutral-contrast-low"
            ></PDivider>
            <PGrid>
              <PGridItem size={12}>
                <PText style={{ paddingBottom: "4px" }}>
                  <span className="dcs-title">
                    {tradeInFields?.length > 3 &&
                    tradeInFields[4].amountNet > 0 ? (
                      <>
                        {t("Comment")} <span style={{ color: "red" }}>*</span>
                      </>
                    ) : (
                      t("Comment (Optional)")
                    )}
                  </span>
                </PText>
              </PGridItem>
              <PGridItem size={12}>
                <PTextFieldWrapper id={`ticomment`}>
                  <input
                    aria-autocomplete="none"
                    autoComplete="off"
                    id={`iticomment`}
                    key={`kticomment`}
                    type="text"
                    defaultValue={
                      calDraft.tradeInComment ? calDraft.tradeInComment : ""
                    }
                    onChange={(e) => handleTradeInComment(e.target.value)}
                    disabled={disabled}
                  />
                </PTextFieldWrapper>
              </PGridItem>
            </PGrid>
          </div>
        </div>
      )}

      {/** Payment Details*/}
      {order?.paymentDetails?.paymentType !== "SUBSCRIPTION" && (
        <div className="order-card vehicle-price">
          {order?.paymentDetails?.paymentType !== "SUBSCRIPTION" && (
            <>
              <div>
                <PText>
                  <span className="dcs-section-heading">
                    {t(getPayementTypeName(order?.paymentDetails?.paymentType))}
                  </span>
                </PText>
              </div>
              <div>
                <PGrid>
                  <PGridItem size={3}></PGridItem>
                  <PGridItem size={2}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont>
                        <span className="dcs-heading">{t("Amount (net)")}</span>
                      </PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={2}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont>
                        <span className="dcs-heading">
                          {t("Amount (gross)")}
                        </span>
                      </PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={5}></PGridItem>
                </PGrid>

                <PDivider color="neutral-contrast-high"></PDivider>

                {order?.paymentDetails?.paymentType !== "DebitCredit" && (
                  <PGrid style={{ marginTop: "10px" }}>
                    <PGridItem size={3}>
                      <PText style={{ paddingTop: "5px" }}>
                        <span className="dcs-title">
                          {getPayementTypeName(
                            order?.paymentDetails?.paymentType
                          ) === "Leasing"
                            ? t("Special leasing payment")
                            : t("Down payment")}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      </PText>
                    </PGridItem>
                    <PGridItem size={2}>
                      <PTextFieldWrapper
                        id={`pddpan`}
                        unit="€"
                        unitPosition="suffix"
                        showCharacterCount={false}
                      >
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete="none"
                          autoComplete="off"
                          ref={dpRef[0].net}
                          id={`ipddpan`}
                          key={`kpddpan`}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(
                            calDraft?.calculationPaymentDetails?.paymentNet
                              ? calDraft?.calculationPaymentDetails?.paymentNet
                              : 0.0
                          )}
                          onChange={(e) =>
                            handlePaymentAmountNet(
                              e.target.value,
                              "down payment",
                              `pddpan`
                            )
                          }
                          onBlur={(e) => {
                            handlePaymentAmountNet(
                              e.target.value,
                              "down payment",
                              `pddpan`
                            );
                            if (dpRef[0].net.current)
                              dpRef[0].net.current.value =
                                formatCurrencyWithNoUnit(
                                  calDraft?.calculationPaymentDetails
                                    ?.paymentNet
                                    ? calDraft?.calculationPaymentDetails
                                        ?.paymentNet
                                    : 0.0
                                );
                          }}
                          style={{ textAlign: "right" }}
                          disabled={disabled}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={2}>
                      <PTextFieldWrapper
                        id={`pddpag`}
                        unit="€"
                        unitPosition="suffix"
                        showCharacterCount={false}
                      >
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete="none"
                          autoComplete="off"
                          ref={dpRef[0].gross}
                          id={`ipddpag`}
                          key={`kpddpag`}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(
                            calDraft?.calculationPaymentDetails?.paymentGross
                              ? calDraft?.calculationPaymentDetails
                                  ?.paymentGross
                              : 0.0
                          )}
                          onChange={(e) =>
                            handlePaymentAmountGross(
                              e.target.value,
                              "down payment",
                              `pddpag`
                            )
                          }
                          onBlur={(e) => {
                            handlePaymentAmountGross(
                              e.target.value,
                              "down payment",
                              `pddpag`
                            );
                            if (dpRef[0].gross.current)
                              dpRef[0].gross.current.value =
                                formatCurrencyWithNoUnit(
                                  calDraft?.calculationPaymentDetails
                                    ?.paymentGross
                                    ? calDraft?.calculationPaymentDetails
                                        ?.paymentGross
                                    : 0.0
                                );
                          }}
                          style={{ textAlign: "right" }}
                          disabled={disabled}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={5}></PGridItem>
                  </PGrid>
                )}

                <PGrid style={{ marginTop: "10px" }}>
                  <PGridItem size={3}>
                    <PText style={{ paddingTop: "5px" }}>
                      <span className="dcs-title">
                        {t("Up-front payment")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    </PText>
                  </PGridItem>
                  <PGridItem size={2}>
                    <PTextFieldWrapper
                      id={`pdufpan`}
                      unit="€"
                      unitPosition="suffix"
                      showCharacterCount={false}
                    >
                      <input
                        maxLength={inputFieldMaxLength}
                        aria-autocomplete="none"
                        autoComplete="off"
                        ref={upRef[0].net}
                        id={`ipdufpan`}
                        key={`kpdufpan`}
                        type="text"
                        defaultValue={formatCurrencyWithNoUnit(
                          calPaymentDetails?.upFrontPaymentNet
                            ? calPaymentDetails.upFrontPaymentNet
                            : 0.0
                        )}
                        onChange={(e) =>
                          handlePaymentAmountNet(
                            e.target.value,
                            "upfront payment",
                            `pdufpan`
                          )
                        }
                        onBlur={(e) => {
                          handlePaymentAmountNet(
                            e.target.value,
                            "upfront payment",
                            `pdufpan`
                          );
                          if (upRef[0].net.current)
                            upRef[0].net.current.value =
                              formatCurrencyWithNoUnit(
                                calPaymentDetails?.upFrontPaymentNet
                                  ? calPaymentDetails.upFrontPaymentNet
                                  : 0.0
                              );
                        }}
                        style={{ textAlign: "right" }}
                        disabled={disabled}
                      />
                    </PTextFieldWrapper>
                  </PGridItem>
                  <PGridItem size={2}>
                    <PTextFieldWrapper
                      id={`pdufpag`}
                      unit="€"
                      unitPosition="suffix"
                      showCharacterCount={false}
                    >
                      <input
                        maxLength={inputFieldMaxLength}
                        aria-autocomplete="none"
                        autoComplete="off"
                        ref={upRef[0].gross}
                        id={`ipdufpag`}
                        key={`kpdufpag`}
                        type="text"
                        defaultValue={formatCurrencyWithNoUnit(
                          calPaymentDetails?.upFrontPaymentGross!
                        )}
                        onChange={(e) =>
                          handlePaymentAmountGross(
                            e.target.value,
                            "upfront payment",
                            `pdufpag`
                          )
                        }
                        onBlur={(e) => {
                          handlePaymentAmountGross(
                            e.target.value,
                            "upfront payment",
                            `pdufpag`
                          );
                          if (upRef[0].gross.current)
                            upRef[0].gross.current.value =
                              formatCurrencyWithNoUnit(
                                calPaymentDetails?.upFrontPaymentGross
                                  ? calPaymentDetails.upFrontPaymentGross
                                  : 0.0
                              );
                        }}
                        style={{ textAlign: "right" }}
                        disabled={disabled}
                      />
                    </PTextFieldWrapper>
                  </PGridItem>
                  <PGridItem size={5}></PGridItem>
                </PGrid>

                {/** Include buttons */}
                {order?.paymentDetails?.paymentType !== "DebitCredit" && (
                  <>
                    <PGrid>
                      <PGridItem size={3}>
                        <PText style={{ paddingTop: "5px" }}>
                          <span className="dcs-heading">
                            {t(
                              `Include in ${getPayementTypeName(
                                order?.paymentDetails?.paymentType
                              )} (Optional)`
                            )}
                          </span>
                        </PText>
                      </PGridItem>
                      <PGridItem size={9}></PGridItem>
                    </PGrid>
                    <PDivider color="neutral-contrast-high"></PDivider>

                    <PFlex>
                      {additionalCosts?.map((item, i) => {
                        let index = calPaymentDetails?.includeOptionals
                          ? calPaymentDetails.includeOptionals.indexOf(
                              item.type
                            )
                          : null;

                        if (item.customerShare > 0) {
                          return (
                            <PFlexItem style={{ marginRight: "8px" }}>
                              <div
                                className={`mt12 include-btn ${
                                  index !== null && index > -1
                                    ? "include-btn-selected"
                                    : ""
                                } ${disabled ? "disabled" : ""}`}
                                style={{ paddingRight: "8px" }}
                                onClick={() => handleIncludeOptions(item.type)}
                              >
                                <Stack direction={"row"} spacing={1}>
                                  {index !== null && index < 0 && (
                                    <PIcon
                                      name="add"
                                      style={{ marginTop: "8px" }}
                                    />
                                  )}

                                  <PorscheFont className="dcs-title">
                                    {t(item.label)}
                                  </PorscheFont>

                                  {index !== null && index > -1 && (
                                    <PIcon
                                      name="close"
                                      style={{ marginTop: "8px" }}
                                      color="neutral-contrast-low"
                                    />
                                  )}
                                </Stack>
                              </div>
                            </PFlexItem>
                          );
                        }
                      })}
                    </PFlex>
                    <PFlex>
                      {tires?.map((item, i) => {
                        let index = calPaymentDetails.includeOptionals
                          ? calPaymentDetails.includeOptionals.indexOf(
                              "CompleteWinterTires"
                            )
                          : null;
                        return (
                          <>
                            {item.customerShare > 0 && (
                              <PFlexItem style={{ marginRight: "8px" }}>
                                <div
                                  className={`mt12 include-btn ${
                                    index !== null && index > -1
                                      ? "include-btn-selected"
                                      : ""
                                  } ${disabled ? "disabled" : ""}`}
                                  style={{ paddingRight: "8px" }}
                                  onClick={() =>
                                    handleIncludeOptions("CompleteWinterTires")
                                  }
                                >
                                  <Stack direction={"row"} spacing={1}>
                                    {index !== null && index < 0 && (
                                      <PIcon
                                        name="add"
                                        style={{ marginTop: "8px" }}
                                      />
                                    )}

                                    <PorscheFont className="dcs-title">
                                      {t("Complete Winter Tires")}
                                    </PorscheFont>

                                    {index !== null && index > -1 && (
                                      <PIcon
                                        name="close"
                                        style={{ marginTop: "8px" }}
                                        color="neutral-contrast-low"
                                      />
                                    )}
                                  </Stack>
                                </div>
                              </PFlexItem>
                            )}

                            {item.serviceItems.length > 0 && (
                              <>
                                {item.serviceItems.map((service, j) => {
                                  let index1 =
                                    calPaymentDetails.includeOptionals
                                      ? calPaymentDetails.includeOptionals.indexOf(
                                          service.services.serviceNameUpsale
                                        )
                                      : null;
                                  if (service.customerShare > 0) {
                                    return (
                                      <PFlexItem style={{ marginRight: "8px" }}>
                                        <div
                                          className={`mt12 include-btn ${
                                            index1 !== null && index1 > -1
                                              ? "include-btn-selected"
                                              : ""
                                          } ${disabled ? "disabled" : ""}`}
                                          style={{ paddingRight: "6px" }}
                                          onClick={() =>
                                            handleIncludeOptions(
                                              service.services.serviceNameUpsale
                                            )
                                          }
                                        >
                                          <Stack direction={"row"} spacing={1}>
                                            {index1 !== null && index1 < 0 && (
                                              <PIcon
                                                name="add"
                                                style={{ marginTop: "8px" }}
                                              />
                                            )}

                                            <PorscheFont className="dcs-title">
                                              {t(
                                                getWorkshopServiceName(
                                                  item.serviceItems[j].services
                                                    .serviceNameUpsale,
                                                  j === 0 ? "winter" : "summer"
                                                )
                                              )}
                                            </PorscheFont>

                                            {index1 !== null && index1 > -1 && (
                                              <PIcon
                                                name="close"
                                                style={{ marginTop: "8px" }}
                                                color="neutral-contrast-low"
                                              />
                                            )}
                                          </Stack>
                                        </div>
                                      </PFlexItem>
                                    );
                                  }
                                })}
                              </>
                            )}
                          </>
                        );
                      })}
                      {parts?.map((item, i) => {
                        if (i > 0) return <></>;
                        let index = calPaymentDetails.includeOptionals
                          ? calPaymentDetails.includeOptionals.indexOf(
                              "PDS / Tequipment"
                            )
                          : null;
                        return (
                          <>
                            {item.customerShare > 0 && (
                              <PFlexItem style={{ marginRight: "8px" }}>
                                <div
                                  className={`mt12 include-btn ${
                                    index !== null && index > -1
                                      ? "include-btn-selected"
                                      : ""
                                  } ${disabled ? "disabled" : ""}`}
                                  style={{ paddingRight: "8px" }}
                                  onClick={() =>
                                    handleIncludeOptions("PDS / Tequipment")
                                  }
                                >
                                  <Stack direction={"row"} spacing={1}>
                                    {index !== null && index < 0 && (
                                      <PIcon
                                        name="add"
                                        style={{ marginTop: "8px" }}
                                      />
                                    )}
                                    <PorscheFont className="dcs-title">
                                      {t("PDS / Tequipment")}
                                    </PorscheFont>
                                    {index !== null && index > -1 && (
                                      <PIcon
                                        name="close"
                                        style={{ marginTop: "8px" }}
                                        color="neutral-contrast-low"
                                      />
                                    )}
                                  </Stack>
                                </div>
                              </PFlexItem>
                            )}

                            {item.serviceItems.length > 0 && (
                              <>
                                {item.serviceItems.map((service, j) => {
                                  if (service.services === null) return <></>;

                                  let index1 =
                                    calPaymentDetails.includeOptionals
                                      ? calPaymentDetails.includeOptionals.indexOf(
                                          service.services.serviceNameUpsale
                                        )
                                      : null;
                                  if (service.customerShare > 0) {
                                    return (
                                      <PFlexItem style={{ marginRight: "8px" }}>
                                        <div
                                          className={`mt12 include-btn ${
                                            index1 !== null && index1 > -1
                                              ? "include-btn-selected"
                                              : ""
                                          } ${disabled ? "disabled" : ""}`}
                                          style={{ paddingRight: "8px" }}
                                          onClick={() =>
                                            handleIncludeOptions(
                                              item.serviceItems[0].services
                                                .serviceNameUpsale
                                            )
                                          }
                                        >
                                          <Stack direction={"row"} spacing={1}>
                                            {index1 !== null && index1 < 0 && (
                                              <PIcon
                                                name="add"
                                                style={{ marginTop: "8px" }}
                                              />
                                            )}
                                            <PorscheFont className="dcs-title">
                                              {t("Installation (PDS/TEQ)")}
                                            </PorscheFont>
                                            {index1 !== null && index1 > -1 && (
                                              <PIcon
                                                name="close"
                                                style={{ marginTop: "8px" }}
                                                color="neutral-contrast-low"
                                              />
                                            )}
                                          </Stack>
                                        </div>
                                      </PFlexItem>
                                    );
                                  }
                                })}
                                {/* <PFlexItem style={{marginRight: "8px"}}>
                                <div className='mt12 include-btn' style={{paddingRight: "8px"}} onClick={() => handleIncludeOptions(item.serviceItems[0].services.serviceNameUpsale)}>
                                <Stack direction={"row"} spacing={1}>
                                    <PIcon name="add" style={{marginTop: "8px"}}/>
                                    <PorscheFont className='dcs-title'>
                                    {"Installation  (PDS/TEQ)"}
                                    </PorscheFont>
                                </Stack>
                                </div>
                              </PFlexItem> */}
                              </>
                            )}
                          </>
                        );
                      })}
                    </PFlex>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {/** Final Calculation */}
      <div className="order-card vehicle-price">
        {/* <Final calDraft={calDraft} calculateHeader={(calDraft) => calculateHeader(calDraft)} rvm={rvm} vat={vat} commissionObj={commissionObj} disabled={disabled} handleModified={handleModified}/> */}
        <PFlex>
          <PFlexItem style={{ width: "70%" }}>
            <PFlex direction={"column"}>
              <PFlexItem
                style={{
                  background: "#F8F8F8",
                  padding: "10px",
                  marginRight: "10px",
                }}
              >
                <div>
                  <PorscheFont className="final-section-heading">
                    {t("Final calculation")}
                  </PorscheFont>
                </div>
                <div>
                  <PFlex>
                    <PFlexItem style={{ width: "20%" }}>
                      <PFlex direction={"column"}>
                        <PFlexItem>
                          <div>
                            <PorscheFont className="dcs-heading">
                              {`${
                                calDraft.calculationHeader?.externalDiscount &&
                                calDraft?.vehiclePrices &&
                                calDraft?.vehiclePrices[0].amountNet > 0
                                  ? formatCurrencyWithNoUnit(
                                      (calDraft.calculationHeader
                                        .externalDiscount /
                                        calDraft.vehiclePrices[0].amountNet) *
                                        100
                                    )
                                  : "0,00"
                              } %`}
                              &nbsp;
                              {` ${formatCurrencyWithNoUnit(
                                calDraft.calculationHeader?.externalDiscount
                                  ? calDraft.calculationHeader?.externalDiscount
                                  : 0.0
                              )} €  `}
                            </PorscheFont>
                          </div>
                        </PFlexItem>
                        <PFlexItem>
                          <PorscheFont
                            className="dcs-title"
                            style={{ color: "#909090" }}
                          >
                            {t("External discount")}
                          </PorscheFont>
                        </PFlexItem>
                      </PFlex>
                    </PFlexItem>
                    <PFlexItem style={{ width: "5%", marginTop: "14px" }}>
                      <PDivider
                        orientation={"vertical"}
                        style={{ border: "#C8CACB", height: "20px" }}
                      />
                    </PFlexItem>
                    <PFlexItem style={{ width: "20%" }}>
                      <PFlex direction={"column"}>
                        <PFlexItem>
                          <div>
                            <PorscheFont className="dcs-heading">
                              {`${
                                calDraft.calculationHeader?.internalDiscount &&
                                calDraft?.vehiclePrices &&
                                calDraft?.vehiclePrices[0].amountNet > 0
                                  ? formatCurrencyWithNoUnit(
                                      (calDraft.calculationHeader
                                        .internalDiscount /
                                        calDraft.vehiclePrices[0].amountNet) *
                                        100
                                    )
                                  : "0,00"
                              } %`}
                              &nbsp;
                              {` ${formatCurrencyWithNoUnit(
                                calDraft.calculationHeader?.internalDiscount
                                  ? calDraft.calculationHeader?.internalDiscount
                                  : 0.0
                              )} €  `}
                            </PorscheFont>
                          </div>
                        </PFlexItem>
                        <PFlexItem>
                          <PorscheFont
                            className="dcs-title"
                            style={{ color: "#909090" }}
                          >
                            {t("Internal discount")}
                          </PorscheFont>
                        </PFlexItem>
                      </PFlex>
                    </PFlexItem>
                    <PFlexItem style={{ width: "5%", marginTop: "14px" }}>
                      <PDivider
                        orientation={"vertical"}
                        style={{ border: "#C8CACB", height: "20px" }}
                      />
                    </PFlexItem>

                    {/* { (order.paymentDetails?.paymentType === "Leasing_PFS" || order.paymentDetails?.paymentType === "Leasing_Other")
                    && rvm && (
                      <>
                        <PFlexItem style={{width: "20%"}}>
                          <PFlex direction={"column"}>
                            <PFlexItem>
                              <div>
                                <PorscheFont className='dcs-heading'>
                                {`${ rvm.rrp ? formatCurrencyWithNoUnit(rvm.rrp) : "0,00"} %`}
                                &nbsp;
                                {` ${formatCurrencyWithNoUnit(calDraft.vehiclePrices ? rvm.rrp * calDraft.vehiclePrices[2].amountNet : 0.00)} €  `}
                                </PorscheFont>
                              </div>
                            </PFlexItem>
                            <PFlexItem>
                              <PorscheFont className='dcs-title' style={{color: "#909090"}}>Residual value model</PorscheFont>
                            </PFlexItem>
                          </PFlex>
                        </PFlexItem>
                        <PFlexItem style={{width: "5%", marginTop: "14px"}}>
                          <PDivider orientation={"vertical"} style={{border: "#C8CACB", height: "20px"}} />
                        </PFlexItem>
                      </>
                    )
                  } */}
                    <PFlexItem style={{ width: "20%" }}>
                      <PFlex direction={"column"}>
                        <PFlexItem>
                          <div>
                            <PorscheFont
                              className="dcs-heading"
                              style={{ color: "#313639" }}
                            >
                              {`${
                                calDraft.calculationHeader?.grossProfit &&
                                calDraft?.vehiclePrices &&
                                calDraft?.vehiclePrices[0].amountNet > 0
                                  ? formatCurrencyWithNoUnit(
                                      (calDraft.calculationHeader.grossProfit /
                                        calDraft.vehiclePrices[0].amountNet) *
                                        100
                                    )
                                  : "0,00"
                              } %`}
                              &nbsp;
                              {` ${formatCurrencyWithNoUnit(
                                calDraft.calculationHeader?.grossProfit
                                  ? calDraft.calculationHeader?.grossProfit
                                  : 0.0
                              )} €  `}
                            </PorscheFont>
                          </div>
                        </PFlexItem>
                        <PFlexItem>
                          <PorscheFont
                            className="dcs-title"
                            style={{ color: "#909090" }}
                          >
                            {t("Gross profit")}
                          </PorscheFont>
                        </PFlexItem>
                      </PFlex>
                    </PFlexItem>

                    <PFlexItem style={{ width: "5%", marginTop: "14px" }}>
                      <PDivider
                        orientation={"vertical"}
                        style={{ border: "#C8CACB", height: "20px" }}
                      />
                    </PFlexItem>

                    <PFlexItem style={{ width: "20%" }}>
                      <PFlex direction={"column"}>
                        <PFlexItem>
                          <div>
                            <PorscheFont
                              className="dcs-heading"
                              style={{ color: "#313639" }}
                            >
                              {formatToWholeNumber(
                                calDraft?.onlineCarSaleInfo?.standingDays
                                  ? calDraft?.onlineCarSaleInfo?.standingDays
                                  : 0
                              )}
                            </PorscheFont>
                          </div>
                        </PFlexItem>
                        <PFlexItem>
                          <PorscheFont
                            className="dcs-title"
                            style={{ color: "#909090" }}
                          >
                            {t("Standing days")}
                          </PorscheFont>
                        </PFlexItem>
                      </PFlex>
                    </PFlexItem>
                  </PFlex>
                </div>
              </PFlexItem>
              <PFlexItem
                style={{
                  background: "#F8F8F8",
                  padding: "10px",
                  marginRight: "10px",
                  height: "300px",
                }}
              >
                <div>
                  <PorscheFont className="final-section-heading">
                    {t("Calculation customer")}
                  </PorscheFont>
                </div>
                <PFlex direction={"column"} style={{ marginTop: "10px" }}>
                  {/** First row */}
                  <PFlexItem>
                    <PFlex>
                      <PFlexItem style={{ width: "10%" }}></PFlexItem>
                      <PFlexItem style={{ width: "5%" }}></PFlexItem>
                      <PFlexItem style={{ width: "15%" }} alignSelf="center">
                        <PorscheFont
                          className="dcs-title"
                          style={{ color: "#909090" }}
                        >
                          {t("Invoice")}
                        </PorscheFont>
                      </PFlexItem>
                      <PFlexItem style={{ width: "5%" }}></PFlexItem>
                      <PFlexItem style={{ width: "15%" }} alignSelf="center">
                        <PorscheFont
                          className="dcs-title"
                          style={{ color: "#909090" }}
                        >
                          {t("Pre-delivery")}
                        </PorscheFont>
                      </PFlexItem>
                      {order.paymentDetails?.paymentType !== "DebitCredit" && (
                        <>
                          <PFlexItem style={{ width: "5%" }}></PFlexItem>
                          <PFlexItem
                            style={{ width: "15%" }}
                            alignSelf="center"
                          >
                            <PorscheFont
                              className="dcs-title"
                              style={{ color: "#909090" }}
                            >
                              {getPayementTypeName(
                                order.paymentDetails?.paymentType
                              )}
                            </PorscheFont>
                          </PFlexItem>
                        </>
                      )}
                    </PFlex>
                  </PFlexItem>

                  {/** Second row */}
                  <PFlexItem>
                    <PFlex>
                      <PFlexItem style={{ width: "10%" }}>
                        <PorscheFont
                          className="dcs-title"
                          style={{ color: "#909090" }}
                        >
                          {t("Net")}
                        </PorscheFont>
                      </PFlexItem>
                      <PFlexItem style={{ width: "5%" }}>
                        <PDivider
                          orientation={"vertical"}
                          style={{ border: "#C8CACB", height: "20px" }}
                        />
                      </PFlexItem>
                      <PFlexItem style={{ width: "15%" }}>
                        <PorscheFont
                          className="dcs-title"
                          style={{ color: "#909090" }}
                        >{`${formatCurrencyWithNoUnit(
                          calDraft.calculationCustomer
                            ? calDraft.calculationCustomer.invoiceNet
                            : 0.0
                        )} €`}</PorscheFont>
                      </PFlexItem>
                      <PFlexItem style={{ width: "5%" }}>
                        <PDivider
                          orientation={"vertical"}
                          style={{ border: "#C8CACB", height: "20px" }}
                        />
                      </PFlexItem>
                      <PFlexItem style={{ width: "15%" }}>
                        <PorscheFont
                          className="dcs-title"
                          style={{ color: "#909090" }}
                        >{`${formatCurrencyWithNoUnit(
                          calDraft.calculationCustomer
                            ? calDraft.calculationCustomer.preDeliveryNet
                            : 0.0
                        )} €`}</PorscheFont>
                      </PFlexItem>
                      {order.paymentDetails?.paymentType !== "DebitCredit" && (
                        <>
                          <PFlexItem style={{ width: "5%" }}></PFlexItem>
                          <PFlexItem style={{ width: "15%" }}>
                            <PorscheFont
                              className="dcs-title"
                              style={{ color: "#909090" }}
                            >{`${formatCurrencyWithNoUnit(
                              calDraft.calculationCustomer
                                ? calDraft.calculationCustomer.paymentNet
                                : 0.0
                            )} €`}</PorscheFont>
                          </PFlexItem>
                        </>
                      )}
                    </PFlex>
                  </PFlexItem>

                  {/** Third row */}
                  <PFlexItem style={{ marginTop: "6px", marginBottom: "6px" }}>
                    <PDivider />
                  </PFlexItem>
                  <PFlexItem>
                    <PFlex>
                      <PFlexItem style={{ width: "10%" }}>
                        <PorscheFont
                          className="dcs-title"
                          style={{ color: "#909090" }}
                        >
                          {t("Gross")}
                        </PorscheFont>
                      </PFlexItem>
                      <PFlexItem style={{ width: "5%" }}>
                        <PDivider
                          orientation={"vertical"}
                          style={{ border: "#C8CACB", height: "20px" }}
                        />
                      </PFlexItem>
                      <PFlexItem style={{ width: "15%" }}>
                        <PorscheFont
                          className="dcs-title"
                          style={{ color: "#909090" }}
                        >{`${formatCurrencyWithNoUnit(
                          calDraft.calculationCustomer
                            ? calDraft.calculationCustomer.invoiceGross
                            : 0.0
                        )} €`}</PorscheFont>
                      </PFlexItem>
                      <PFlexItem style={{ width: "5%" }}>
                        <PDivider
                          orientation={"vertical"}
                          style={{ border: "#C8CACB", height: "20px" }}
                        />
                      </PFlexItem>
                      <PFlexItem style={{ width: "15%" }}>
                        <PorscheFont
                          className="dcs-title"
                          style={{ color: "#909090" }}
                        >{`${formatCurrencyWithNoUnit(
                          calDraft.calculationCustomer
                            ? calDraft.calculationCustomer.preDeliveryGross
                            : 0.0
                        )} €`}</PorscheFont>
                      </PFlexItem>
                      {order.paymentDetails?.paymentType !== "DebitCredit" && (
                        <>
                          <PFlexItem style={{ width: "5%" }}></PFlexItem>
                          <PFlexItem style={{ width: "15%" }}>
                            <PorscheFont
                              className="dcs-title"
                              style={{ color: "#909090" }}
                            >{`${formatCurrencyWithNoUnit(
                              getPaymentGross()
                            )} €`}</PorscheFont>
                          </PFlexItem>
                        </>
                      )}
                    </PFlex>
                  </PFlexItem>
                </PFlex>
              </PFlexItem>
              <PFlexItem alignSelf={"center"} style={{ marginTop: "-200px" }}>
                {getImage(
                  orderContext.getCtxOrderDetails().vehicleDetails?.series
                    ?.seriesName
                )}
              </PFlexItem>
              {/** Commission Sharing */}
              {canViewCommission && (
                <PFlexItem style={{ marginTop: "-50px" }}>
                  <PFlex>
                    <PFlexItem>
                      <PorscheFont className="final-section-heading">
                        {t("Commission Sharing")}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem>
                      <PSwitch
                        checked={showCs}
                        alignLabel="left"
                        style={{ paddingTop: "10px" }}
                        onClick={handleCs}
                        //className={disabledClassName}
                        disabled={disabled}
                      ></PSwitch>
                    </PFlexItem>
                  </PFlex>
                </PFlexItem>
              )}
              {showCs && (
                <>
                  <PFlexItem className="mt12">
                    <PorscheFont className="dcs-title">
                      {t("Vehicle commission")}{" "}
                      <span className="dcs-heading">{`${formatCurrencyWithNoUnit(
                        calDraft.commissionCalculation?.vehicleCommissionEuro
                          ? calDraft.commissionCalculation.vehicleCommissionEuro
                          : 0.0
                      )} €`}</span>
                    </PorscheFont>
                  </PFlexItem>
                  <PFlexItem className="mt12">
                    <PGrid>
                      <PGridItem size={4}>
                        <PorscheFont className="dcs-title">
                          {t("Sellers involved")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </PorscheFont>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont className="dcs-title">
                            {t("Share in")} %{" "}
                            <span style={{ color: "red" }}>*</span>
                          </PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont className="dcs-title">
                            {t("Share in")} €{" "}
                            <span style={{ color: "red" }}>*</span>
                          </PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={2}></PGridItem>
                    </PGrid>
                    {calDraft.isCommissionSharingActive &&
                      calDraft.commissionSharing?.map((sharing, i) => {
                        return (
                          <>
                            <PGrid style={{ marginBottom: "6px" }}>
                              <PGridItem size={4}>
                                {i === 0 ? (
                                  <div
                                    className="read"
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <PorscheFont>{orderCreator}</PorscheFont>
                                  </div>
                                ) : (
                                  <>
                                    <PSelectWrapper>
                                      <select
                                        onChange={(e) =>
                                          handleSeller(e.target.value, i)
                                        }
                                        disabled={disabled}
                                      >
                                        {sellers.map((x, i) => {
                                          return (
                                            <option
                                              value={x.id}
                                              selected={
                                                sharing.sellerNames.id === x.id
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {x.ppnName}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </PSelectWrapper>
                                  </>
                                )}
                              </PGridItem>
                              <PGridItem size={3}>
                                <PTextFieldWrapper
                                  id={`fcsp${i}`}
                                  unit="%"
                                  unitPosition="suffix"
                                  showCharacterCount={false}
                                >
                                  <input
                                    maxLength={inputFieldMaxLength}
                                    ref={csRef[i].percent}
                                    aria-autocomplete="none"
                                    autoComplete="off"
                                    id={`ifcsp${i}`}
                                    key={`kfcsp${i}`}
                                    type="text"
                                    defaultValue={formatCurrencyWithNoUnit(
                                      sharing.shareInPercent
                                    )}
                                    //value={formatCurrencyWithNoUnit(sharing.shareInPercent)}
                                    onChange={(e) =>
                                      handleSharingPercent(
                                        e.target.value,
                                        i,
                                        `kfcsp${i}`
                                      )
                                    }
                                    onBlur={(e) => {
                                      handleSharingPercent(
                                        e.target.value,
                                        i,
                                        `kfcsp${i}`
                                      );
                                      if (i === 0 && csRef[0].percent.current)
                                        csRef[0].percent.current.value =
                                          formatCurrencyWithNoUnit(
                                            sharing.shareInPercent
                                          );
                                      if (i === 1 && csRef[1].percent.current)
                                        csRef[1].percent.current.value =
                                          formatCurrencyWithNoUnit(
                                            sharing.shareInPercent
                                          );
                                      if (i === 2 && csRef[2].percent.current)
                                        csRef[2].percent.current.value =
                                          formatCurrencyWithNoUnit(
                                            sharing.shareInPercent
                                          );
                                    }}
                                    style={{ textAlign: "right" }}
                                    disabled={disabled}
                                  />
                                </PTextFieldWrapper>
                              </PGridItem>
                              <PGridItem size={3}>
                                <PTextFieldWrapper
                                  id={`fcse${i}`}
                                  unit="€"
                                  unitPosition="suffix"
                                  showCharacterCount={false}
                                >
                                  <input
                                    maxLength={inputFieldMaxLength}
                                    ref={csRef[i].euro}
                                    aria-autocomplete="none"
                                    autoComplete="off"
                                    id={`ifcse${i}`}
                                    key={`kfcse${i}`}
                                    type="text"
                                    defaultValue={formatCurrencyWithNoUnit(
                                      sharing.shareInEuro
                                    )}
                                    //value={formatCurrencyWithNoUnit(sharing.shareInEuro)}
                                    onChange={(e) =>
                                      handleSharingEuro(
                                        e.target.value,
                                        i,
                                        `ifcse${i}`
                                      )
                                    }
                                    onBlur={(e) => {
                                      handleSharingEuro(
                                        e.target.value,
                                        i,
                                        `ifcse${i}`
                                      );
                                      if (i === 0 && csRef[0].euro.current)
                                        csRef[0].euro.current.value =
                                          formatCurrencyWithNoUnit(
                                            sharing.shareInEuro
                                          );
                                      if (i === 1 && csRef[1].euro.current)
                                        csRef[1].euro.current.value =
                                          formatCurrencyWithNoUnit(
                                            sharing.shareInEuro
                                          );
                                      if (i === 2 && csRef[2].euro.current)
                                        csRef[2].euro.current.value =
                                          formatCurrencyWithNoUnit(
                                            sharing.shareInEuro
                                          );
                                    }}
                                    style={{ textAlign: "right" }}
                                    disabled={disabled}
                                  />
                                </PTextFieldWrapper>
                              </PGridItem>
                              {calDraft.commissionSharing &&
                              calDraft.commissionSharing.length > 2 &&
                              i !== 0 ? (
                                <>
                                  <PGridItem
                                    size={1}
                                    style={{ marginTop: "8px" }}
                                  >
                                    <CustomDelete
                                      partId={i}
                                      message={`${
                                        t("Delete this share") + "?"
                                      }`}
                                      handleDelete={(index) =>
                                        handleDelete(index)
                                      }
                                      disabled={disabled}
                                    />
                                  </PGridItem>
                                  <PGridItem size={1}></PGridItem>
                                </>
                              ) : (
                                <PGridItem size={1}></PGridItem>
                              )}
                            </PGrid>
                          </>
                        );
                      })}

                    {csError && (
                      <PGrid>
                        <PGridItem size={12}>
                          <PorscheFont style={{ color: "#D5001B" }}>
                            {t(
                              "Commission sharing invalid. Sum of the shares must add up to 100%."
                            )}
                          </PorscheFont>
                        </PGridItem>
                      </PGrid>
                    )}
                    {calDraft.commissionSharing &&
                      calDraft.commissionSharing.length < 3 && (
                        <PGrid>
                          <PGridItem size={3}>
                            <PButton
                              variant="tertiary"
                              icon="add"
                              onClick={handleAddSeller}
                              disabled={disabled}
                            >
                              {t("Add another seller")}
                            </PButton>
                          </PGridItem>
                          <PGridItem size={7}></PGridItem>
                        </PGrid>
                      )}
                  </PFlexItem>
                </>
              )}
            </PFlex>
          </PFlexItem>
          <PFlexItem
            style={{ width: "30%", background: "#F8F8F8", padding: "8px" }}
          >
            {canViewCommission && (
              <PGrid>
                <PGridItem size={12}>
                  <PorscheFont className="final-section-heading">
                    {t("Commission calculation")}
                  </PorscheFont>
                </PGridItem>
                <PGridItem size={12}>
                  <PFlex>
                    <PFlexItem className="final-cal-symbol">
                      <PorscheFont>{"€"}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem>
                      <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(negativeValueHandler(  calDraft.commissionCalculation
                          ? calDraft.commissionCalculation.totalCommission : 0.0)
                      )}  €  `}</PorscheFont>
                      <PorscheFont
                        className="dcs-title"
                        style={{ color: "#909090" }}
                      >
                        {t("Total commission")}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </PGridItem>
                <PGridItem
                  size={12}
                  style={{ marginTop: "8px", marginBottom: "8px" }}
                >
                  <PDivider></PDivider>
                </PGridItem>
                <PGridItem size={12}>
                  <PorscheFont className="dcs-title">
                    {t("Vehicle commission")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </PorscheFont>
                  <PFlex justifyContent={"space-between"}>
                    <PFlexItem width={"full"} style={{ marginRight: "4px" }}>
                      <div
                        className={`mt12 include-btn ${
                          calDraft.commissionCalculation &&
                          calDraft.commissionCalculation.vehicleCommission ===
                            "Standard"
                            ? "include-btn-selected"
                            : ""
                        }`}
                        style={{ paddingRight: "6px", textAlign: "center" }}
                        onClick={() => handleVehicleCommission("Standard")}
                      >
                        <PorscheFont className="dcs-title">
                          Standard
                        </PorscheFont>
                      </div>
                    </PFlexItem>
                    <PFlexItem width={"full"} style={{ marginLeft: "4px" }}>
                      <div
                        className={`mt12 include-btn ${
                          calDraft.commissionCalculation &&
                          calDraft.commissionCalculation.vehicleCommission ===
                            "Fixed"
                            ? "include-btn-selected"
                            : ""
                        }`}
                        style={{ paddingRight: "6px", textAlign: "center" }}
                        onClick={() => handleVehicleCommission("Fixed")}
                      >
                        <PorscheFont className="dcs-title">
                          {t("Fixed")}
                        </PorscheFont>
                      </div>
                    </PFlexItem>
                  </PFlex>
                </PGridItem>
                {calDraft.commissionCalculation &&
                  calDraft.commissionCalculation.vehicleCommission ===
                    "Standard" &&
                  commissionType === "ScalingCommission" && (
                    // commissionObj.vehicleCommissions && commissionObj.vehicleCommissions.length > 0 &&
                    // commissionObj.minCommission !== true &&
                    // commissionObj.maxCommission !== true &&
                    <PGridItem size={12} className="mt12">
                      <PFlex>
                        <PFlexItem width={"full"}>
                          <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                            calDraft.commissionCalculation
                              ?.vehicleCommissionEuro
                              ? calDraft.commissionCalculation
                                  .vehicleCommissionEuro
                              : 0.0
                          )}  €`}</PorscheFont>
                          <PorscheFont className="dcs-title">
                            {t("Vehicle commission")}
                          </PorscheFont>
                        </PFlexItem>
                        <PFlexItem width={"full"}>
                          {((calDraft.referenceType &&
                            calDraft.referenceType !== "Euro" &&
                            calDraft.referenceValue !== undefined &&
                            !calDraft.payout2) ||
                            (calDraft.referenceType &&
                              calDraft.referenceType === "Euro" &&
                              calDraft.referenceValue !== undefined &&
                              calDraft.referenceValue > 0 &&
                              !calDraft.payout2)) && (
                            <>
                              <Stack direction={"row"} spacing={1}>
                                <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                                  calDraft.referenceValue
                                )}${
                                  calDraft.referenceType !== "Euro" ? "%" : ""
                                }`}</PorscheFont>
                                <PorscheFont className="dcs-title">{` ${
                                  calDraft.referenceType !== "Euro"
                                    ? t("of") +
                                      " " +
                                      t(
                                        getReferenceType(
                                          calDraft.referenceType
                                        ).toLowerCase()
                                      )
                                    : t("Euro")
                                }`}</PorscheFont>
                              </Stack>
                            </>
                          )}
                          {((calDraft.referenceType &&
                            calDraft.referenceType !== "Euro" &&
                            calDraft.referenceValue !== undefined &&
                            calDraft.payout2) ||
                            (calDraft.referenceType &&
                              calDraft.referenceType === "Euro" &&
                              calDraft.referenceValue !== undefined &&
                              calDraft.referenceValue > 0 &&
                              calDraft.payout2)) && (
                            <>
                              <Stack direction={"row"} spacing={1}>
                                <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                                  calDraft.referenceValue
                                )}${
                                  calDraft.referenceType !== "Euro" ? "%" : ""
                                }`}</PorscheFont>
                                <PorscheFont className="dcs-title">
                                  {` ${
                                    calDraft.referenceType !== "Euro"
                                      ? t("of") +
                                        " " +
                                        t(
                                          getReferenceType(
                                            calDraft.referenceType
                                          ).toLowerCase()
                                        )
                                      : t("Euro")
                                  }  
                                ${
                                  (calDraft.payout2 &&
                                    calDraft.referenceType2 &&
                                    calDraft.referenceType2 !== "Euro") ||
                                  (calDraft.payout2 &&
                                    calDraft.referenceType2 &&
                                    calDraft.referenceType2 === "Euro" &&
                                    calDraft.referenceValue2 !== undefined &&
                                    calDraft.referenceValue2 > 0)
                                    ? "&"
                                    : ""
                                }`}
                                </PorscheFont>
                              </Stack>
                            </>
                          )}
                          {((calDraft.payout2 &&
                            calDraft.referenceType2 &&
                            calDraft.referenceType2 !== "Euro" &&
                            calDraft.referenceValue2 !== undefined) ||
                            (calDraft.payout2 &&
                              calDraft.referenceType2 &&
                              calDraft.referenceType2 === "Euro" &&
                              calDraft.referenceValue2 !== undefined &&
                              calDraft.referenceValue2 > 0)) && (
                            <>
                              <Stack direction={"row"} spacing={1}>
                                {/* <PorscheFont className='dcs-heading' style={{marginTop: "8px"}}>{`${formatCurrencyWithNoUnit(calDraft.referenceValue2)}% `}</PorscheFont>  */}
                                <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                                  calDraft.referenceValue2
                                )}${
                                  calDraft.referenceType2 !== "Euro" ? "%" : ""
                                }`}</PorscheFont>
                                <PorscheFont className="dcs-title">
                                  {" "}
                                  {` ${
                                    calDraft.referenceType2 !== "Euro"
                                      ? t("of") +
                                        " " +
                                        t(
                                          getReferenceType(
                                            calDraft.referenceType2
                                          ).toLowerCase()
                                        )
                                      : t("Euro")
                                  }`}
                                </PorscheFont>
                              </Stack>
                            </>
                          )}
                        </PFlexItem>
                      </PFlex>
                    </PGridItem>
                  )}

                {/** Minimum commission */}

                {calDraft.commissionCalculation &&
                  calDraft.commissionCalculation.vehicleCommission ===
                    "Standard" &&
                  commissionType === "MinCommission" &&
                  commissionObj.minCommission === true &&
                  commissionObj.minCommissionValue !== undefined &&
                  calDraft.commissionCalculation?.vehicleCommissionEuro !==
                    undefined && (
                    // (commissionObj.minCommissionValue > calDraft.commissionCalculation?.vehicleCommissionEuro) &&
                    <PGridItem size={12} className="mt12">
                      <PFlex>
                        <PFlexItem width={"full"}>
                          <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                            calDraft.commissionCalculation
                              ?.vehicleCommissionEuro
                              ? calDraft.commissionCalculation
                                  .vehicleCommissionEuro
                              : 0.0
                          )}  €`}</PorscheFont>
                          <PorscheFont className="dcs-title">
                            {t("Minimum commission")}
                          </PorscheFont>
                        </PFlexItem>
                        <PFlexItem width={"full"}>
                          {commissionObj.minCommissionReference !== "Euro" && (
                            <>
                              <PorscheFont className="dcs-heading">{`${
                                commissionObj.minCommissionValue
                                  ? formatCurrencyWithNoUnit(
                                      commissionObj.minCommissionValue
                                    )
                                  : "0,00"
                              }%`}</PorscheFont>
                              <PorscheFont className="dcs-title">
                                {t("of")}{" "}
                                {t(
                                  getReferenceType(
                                    commissionObj?.minCommissionReference!
                                  )
                                )}
                              </PorscheFont>
                            </>
                          )}
                        </PFlexItem>
                      </PFlex>
                    </PGridItem>
                  )}

                {/**Maximum commission */}
                {calDraft.commissionCalculation &&
                  commissionType === "MaxCommission" &&
                  calDraft.commissionCalculation.vehicleCommission ===
                    "Standard" &&
                  commissionObj.maxCommission === true &&
                  commissionObj.maxCommissionValue !== undefined &&
                  calDraft.commissionCalculation?.vehicleCommissionEuro !==
                    undefined && (
                    //   (commissionObj.maxCommissionValue < calDraft.commissionCalculation?.vehicleCommissionEuro) &&
                    <PGridItem size={12} className="mt12">
                      <PFlex>
                        <PFlexItem width={"full"}>
                          <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                            calDraft.commissionCalculation
                              ?.vehicleCommissionEuro
                              ? calDraft.commissionCalculation
                                  .vehicleCommissionEuro
                              : 0.0
                          )}  €`}</PorscheFont>
                          <PorscheFont className="dcs-title">
                            {t("Maximum commission")}
                          </PorscheFont>
                        </PFlexItem>
                        <PFlexItem width={"full"}>
                          {commissionObj.maxCommissionReference !== "Euro" && (
                            <>
                              <PorscheFont className="dcs-heading">{`${
                                commissionObj.maxCommissionValue
                                  ? formatCurrencyWithNoUnit(
                                      commissionObj.maxCommissionValue
                                    )
                                  : "0,00"
                              } %`}</PorscheFont>

                              <PorscheFont className="dcs-title">
                                {t("of")}{" "}
                                {t(
                                  getReferenceType(
                                    commissionObj?.maxCommissionReference!
                                  )
                                )}
                              </PorscheFont>
                            </>
                          )}
                        </PFlexItem>
                      </PFlex>
                    </PGridItem>
                  )}

                {/**negative commission */}
                {calDraft.commissionCalculation &&
                  commissionType === "NoProfitCommission" &&
                  calDraft.commissionCalculation.vehicleCommission ===
                    "Standard" && (
                    //  commissionObj.noProfitCommission === true &&
                    //calDraft.commissionCalculation?.vehicleCommissionEuro !== undefined &&
                    //   commissionObj.noProfitCommissionValue !== undefined &&
                    //  commissionObj.noProfitCommissionValue < 0 &&
                    <PGridItem size={12} className="mt12">
                      <PFlex>
                        <PFlexItem width={"full"}>
                          <PorscheFont className="dcs-heading">{`${formatCurrencyWithNoUnit(
                            calDraft.commissionCalculation
                              ?.vehicleCommissionEuro
                              ? calDraft.commissionCalculation
                                  .vehicleCommissionEuro
                              : 0.0
                          )}  €`}</PorscheFont>
                          <PorscheFont className="dcs-title">
                            {t("Loss deal commission")}
                          </PorscheFont>
                        </PFlexItem>
                        <PFlexItem width={"full"}>
                          {commissionObj?.noProfitCommissionReference !==
                            "Euro" && (
                            <>
                              <PorscheFont className="dcs-heading">{`${
                                commissionObj.noProfitCommissionValue
                                  ? formatCurrencyWithNoUnit(
                                      commissionObj.noProfitCommissionValue
                                    )
                                  : "0,00"
                              }%`}</PorscheFont>
                              <PorscheFont className="dcs-title">
                                {t("of")}{" "}
                                {t(
                                  getReferenceType(
                                    commissionObj?.noProfitCommissionReference!
                                  )
                                )}
                              </PorscheFont>
                            </>
                          )}
                        </PFlexItem>
                      </PFlex>
                    </PGridItem>
                  )}

                {calDraft.commissionCalculation &&
                  calDraft.commissionCalculation.vehicleCommission ===
                    "Fixed" && (
                    <PGridItem size={12} className="mt12">
                      <PFlex direction={"column"}>
                        <PFlexItem width={"full"}>
                          <PTextFieldWrapper
                            id={`ccvc`}
                            unit="€"
                            unitPosition="suffix"
                            showCharacterCount={false}
                          >
                            <input
                              maxLength={inputFieldMaxLength}
                              aria-autocomplete="none"
                              autoComplete="off"
                              id={`iccvc`}
                              key={`kccvc`}
                              type="text"
                              ref={vcRef}
                              defaultValue={formatCurrencyWithNoUnit(
                                calDraft.commissionCalculation
                                  ?.vehicleCommissionEuro
                                  ? calDraft.commissionCalculation
                                      .vehicleCommissionEuro
                                  : 0.0
                              )}
                              onChange={(e) =>
                                handleVehicleCommissionEuro(
                                  e.target.value,
                                  `iccvc`
                                )
                              }
                              onBlur={(e) => {
                                handleVehicleCommissionEuro(
                                  e.target.value,
                                  `iccvc`
                                );
                                if (vcRef.current)
                                  vcRef.current.value =
                                    formatCurrencyWithNoUnit(
                                      calDraft.commissionCalculation
                                        ?.vehicleCommissionEuro
                                        ? calDraft.commissionCalculation
                                            .vehicleCommissionEuro
                                        : 0.0
                                    );
                              }}
                              //style={{textAlign: "right"}}
                              disabled={disabled}
                            />
                          </PTextFieldWrapper>
                        </PFlexItem>
                        <PFlexItem width={"full"} className="mt12">
                          <PorscheFont
                            className="dcs-title"
                            style={{ color: "#909090" }}
                          >
                            <PIcon name="information" />
                            {t("Please enter vehicle commission manually")}
                          </PorscheFont>
                        </PFlexItem>
                      </PFlex>
                    </PGridItem>
                  )}

                {(order?.paymentDetails?.paymentType === "Leasing_PFS" ||
                  order?.paymentDetails?.paymentType === "Financing_PFS") && (
                  <PGridItem size={12} className="mt12">
                    <PFlex direction={"column"}>
                      <PFlexItem width={"full"} className="mt12">
                        <PorscheFont className="dcs-title">
                          {order?.paymentDetails?.paymentType === "Leasing_PFS"
                            ? t("PFS commission (net)")
                            : order?.paymentDetails?.paymentType ===
                              "Financing_PFS"
                            ? t("PFS commission (gross)")
                            : t("PFS commission")}{" "}
                          <span style={{ color: "red" }}> *</span>
                        </PorscheFont>
                      </PFlexItem>
                      <PFlexItem width={"full"} className="mt12">
                        <PTextFieldWrapper
                          id={`ccvpfs`}
                          unit="€"
                          unitPosition="suffix"
                          showCharacterCount={false}
                        >
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete="none"
                            autoComplete="off"
                            ref={pfsRef}
                            id={`iccvpfs`}
                            key={`kccvpfs`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(
                              calDraft.commissionCalculation
                                ? calDraft.commissionCalculation.pfsCommission
                                : 0.0
                            )}
                            onChange={(e) =>
                              handlePFS(e.target.value, `iccvpfs`)
                            }
                            onBlur={(e) => {
                              handlePFS(e.target.value, `iccvpfs`);
                              if (pfsRef.current)
                                pfsRef.current.value = formatCurrencyWithNoUnit(
                                  calDraft.commissionCalculation
                                    ? calDraft.commissionCalculation
                                        .pfsCommission
                                    : 0.0
                                );
                            }}
                            //style={{textAlign: "right"}}
                            disabled={disabled}
                          />
                        </PTextFieldWrapper>
                      </PFlexItem>
                    </PFlex>
                  </PGridItem>
                )}

                {order?.paymentDetails?.carPolicy && (
                  <PGridItem size={12} className="mt12">
                    <PFlex direction={"column"}>
                      <PFlexItem width={"full"} className="mt12">
                        <PorscheFont className="dcs-title">
                          {t("Car Policy commission")}{" "}
                          <span style={{ color: "red" }}> *</span>
                        </PorscheFont>
                      </PFlexItem>
                      <PFlexItem width={"full"} className="mt12">
                        <PTextFieldWrapper
                          id={`cccpc`}
                          unit="€"
                          unitPosition="suffix"
                          showCharacterCount={false}
                        >
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete="none"
                            autoComplete="off"
                            ref={cpRef}
                            id={`icccpc`}
                            key={`kcccpc`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(
                              calDraft.commissionCalculation
                                ? calDraft.commissionCalculation
                                    .carPolicyCommission
                                : 0.0
                            )}
                            onChange={(e) =>
                              handleCpc(e.target.value, `icccpc`)
                            }
                            //style={{textAlign: "right"}}
                            onBlur={(e) => {
                              handleCpc(e.target.value, `icccpc`);
                              if (cpRef.current)
                                cpRef.current.value = formatCurrencyWithNoUnit(
                                  calDraft.commissionCalculation
                                    ? calDraft.commissionCalculation
                                        .carPolicyCommission
                                    : 0.0
                                );
                            }}
                            disabled={disabled}
                          />
                        </PTextFieldWrapper>
                      </PFlexItem>
                    </PFlex>
                  </PGridItem>
                )}

                {order?.paymentDetails?.porscheCardS && (
                  <PGridItem size={12} className="mt12">
                    <PFlex direction={"column"}>
                      <PFlexItem width={"full"} className="mt12">
                        <PorscheFont className="dcs-title">
                          {t("Porsche Card S commission")}{" "}
                          <span style={{ color: "red" }}> *</span>
                        </PorscheFont>
                      </PFlexItem>
                      <PFlexItem width={"full"} className="mt12">
                        <PTextFieldWrapper
                          id={`cccpc`}
                          unit="€"
                          unitPosition="suffix"
                          showCharacterCount={false}
                        >
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete="none"
                            autoComplete="off"
                            ref={pcsRef}
                            id={`icccpcs`}
                            key={`kcccpcs`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(
                              calDraft.commissionCalculation
                                ? calDraft.commissionCalculation.porscheCardS
                                : 0.0
                            )}
                            onChange={(e) =>
                              handlePcs(e.target.value, `icccpcs`)
                            }
                            //style={{textAlign: "right"}}
                            onBlur={(e) => {
                              handlePcs(e.target.value, `icccpcs`);
                              if (pcsRef.current)
                                pcsRef.current.value = formatCurrencyWithNoUnit(
                                  calDraft.commissionCalculation
                                    ? calDraft.commissionCalculation
                                        .porscheCardS
                                    : 0.0
                                );
                            }}
                            disabled={disabled}
                          />
                        </PTextFieldWrapper>
                      </PFlexItem>
                    </PFlex>
                  </PGridItem>
                )}

                <PGridItem size={12} className="mt12">
                  <PFlex direction={"column"}>
                    <PFlexItem width={"full"} className="mt12">
                      <PorscheFont className="dcs-title">
                        {t("Incentive / Bonus")}{" "}
                        <span style={{ color: "red" }}> *</span>
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem width={"full"} className="mt12">
                      <PTextFieldWrapper
                        id={`cci`}
                        unit="€"
                        unitPosition="suffix"
                        showCharacterCount={false}
                      >
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete="none"
                          autoComplete="off"
                          ref={iRef}
                          id={`icci`}
                          key={`kcci`}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(
                            calDraft.commissionCalculation
                              ? calDraft.commissionCalculation.incentive
                              : 0.0
                          )}
                          onChange={(e) =>
                            handleIncentive(e.target.value, `icci`)
                          }
                          onBlur={(e) => {
                            handleIncentive(e.target.value, `icci`);
                            if (iRef.current)
                              iRef.current.value = formatCurrencyWithNoUnit(
                                calDraft.commissionCalculation
                                  ? calDraft.commissionCalculation.incentive
                                  : 0.0
                              );
                          }}
                          //style={{textAlign: "right"}}
                          disabled={disabled}
                        />
                      </PTextFieldWrapper>
                    </PFlexItem>
                    <PFlexItem width={"full"} className="mt12">
                  <PorscheFont className='dcs-title'>
                    {t("PDS/TEQ commission")}
                  </PorscheFont>
                </PFlexItem>
                <PFlexItem width={"full"} className="mt12">
                  {/* <PTextFieldWrapper id={`cci`} unitPosition="suffix" actionIcon="locate">
                  
                                  
                    <input
                      aria-autocomplete='none'
                      autoComplete='off'
                      id={`icci`}
                      key={`kcci`}
                      type="text"
                      value={formatCurrency(total.commission)}
                     style={{padding:"0.6875rem"}}
                      //style={{textAlign: "right"}}
                      readOnly
                    />
                   
                  </PTextFieldWrapper> */}
                  <div className="read" style={{border:"1px solid #C8CACB"}}>
                                    <PFlex justifyContent={"space-between"}>
                                      
                          <PFlexItem>
                            <PorscheFont><span style={{ marginLeft: "10px" }}>
                              { finalTotalCammission?.upsaleItems.length > 0 &&
                                (upsaleTotalCommission > 0) ? formatCurrency(upsaleTotalCommission) : '0,00 €'}
                            </span>
                            </PorscheFont>
                                      </PFlexItem>
                                      <PFlexItem alignSelf={"flex-end"} style={{marginTop:"10px"}} >
                                      {fileInfo?.tequipmentCommission == true ?
                                      <Tooltip title={"Included in total commission"} style={{ color: "#96989A", fontSize: "12px", fontWeight: "400", textAlign: "left", padding: "0px", textTransform: "none" }}>
                                         <PIcon name={"information" as IconName} />
                                         </Tooltip> : <Tooltip title={"Accounted seperately by parts service"} style={{ color: "#96989A", fontSize: "12px", fontWeight: "400", textAlign: "left", padding: "0px", textTransform: "none" }}>
                                         <PIcon name={"information" as IconName} />
                                         </Tooltip>}
                                      </PFlexItem>
                                    </PFlex>
                                  </div>
                </PFlexItem>
                  </PFlex>
                </PGridItem>

                {calDraft.commissionCalculation &&
                  calDraft.commissionCalculation.incentive > 0 && (
                    <PGridItem size={12} className="mt12">
                      <PFlex direction={"column"}>
                        <PFlexItem width={"full"} className="mt12">
                          <PorscheFont className="dcs-title">
                            {t("Incentive Program")}{" "}
                            <span style={{ color: "red" }}> *</span>
                          </PorscheFont>
                        </PFlexItem>
                        <PFlexItem width={"full"} className="mt12">
                          <PTextFieldWrapper id={`ccip`} showCharacterCount={false}>
                            <input
                              maxLength={inputFieldMaxLength}
                              aria-autocomplete="none"
                              autoComplete="off"
                              id={`iccip`}
                              key={`kccip`}
                              type="text"
                              defaultValue={
                                calDraft.commissionCalculation
                                  ? calDraft.commissionCalculation
                                      .incentiveProgram
                                  : ""
                              }
                              //value={calDraft.commissionCalculation ? calDraft.commissionCalculation.incentiveProgram : ""}
                              onChange={(e) =>
                                handleIncentiveProgram(e.target.value, `iccip`)
                              }
                              //style={{textAlign: "right"}}
                              disabled={disabled}
                            />
                          </PTextFieldWrapper>
                        </PFlexItem>
                      </PFlex>
                    </PGridItem>
                  )}
              </PGrid>
            )}
          </PFlexItem>
        </PFlex>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "44px 30px 34px 30px",
        }}
      >
        <PButton icon="close" onClick={handleExit} className="exit-btn">
          <PorscheFont>{t("Exit")}</PorscheFont>
        </PButton>
        {/* <PButton variant="primary" className='next-btn' onClick={handleNextButton} disabled={isValid ? false: true}><PorscheFont>Next</PorscheFont></PButton> */}
        {changedMode !== "view" && (
          <PButton
            variant="primary"
            className="next-btn"
            onClick={handleNextButton}
            disabled={false}
          >
            <PorscheFont>{t("Next")}</PorscheFont>
          </PButton>
        )}
      </div>
    </>
  );
};
