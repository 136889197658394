import { SubsequentApprovalConfigResponse } from '../../components/order/approvals/SubsequentApprovalTab';
import { API_DEV_URL, API_DEV_URL2 } from '../../configs/Constants';
import { CustomerDeliveryDTO, OrderRegistrationDTO } from '../../interfaces/ActionsOrderProcessing';
import { ApprovalStepDTO } from '../../interfaces/Approvals';
import { HistoryLogTypes, HistoryOrderLogTypes } from '../../interfaces/changeHistory';
import { CommissionScalingDTO } from '../../interfaces/commission';
import { Model } from '../../interfaces/Model';
import {
    CustomerType, DeliveryLocation, DeliveryPresent, OrderDTO, OrderSummaryDTO, PaymentType,
    PorscheCenterDto, Registration, SaveAsDraftDTO, TradeInType, UsedCarCollection,
    VehicleCategoryDto
} from '../../interfaces/Order';
import { Series } from '../../interfaces/Upsale';
import {
    TequipmentAndPorscheDriverSelectionDTO, UpsaleDetailsDTO, WorkshopAndServiceCostsDTO
} from '../../interfaces/UpsaleNew';
import { PorscheCenterDTO, User, UserDetailsDTO, UserDTO } from '../../interfaces/User';
import { UserDetails } from '../../interfaces/UserDetails';
import { GetPaw, GetRvm } from '../../interfaces/Variables';
import { WorkshopAndServiceCostsDTO as Workshop } from '../../interfaces/WorkService';
import { type } from '../Constants';
import { dcsdeleteRequest, dcsgetRequest, dcspostRequest, dcsputRequest, deleteRequest, getRequest, postRequest, putRequest } from './Rest';

// import {OrderRegistration} from '../../components/order/actionsOrderProcess/OrderRegistration';

/** API HELPERS **/

/** Get user profile **/

export type ApiReturnType={
  status:String, response:any, responseFlag:boolean|undefined
}
export const getUserProfile = async (userId: number) => {
  const path = `${API_DEV_URL}/user-profile/${userId}`;
  const response = await getRequest(path);

  return response.rawResponse ? (response.rawResponse.data as User) : null;
};

export const getOrderManagedVariables = async (userId: number) => {
  const path = `${API_DEV_URL}/variables/all/${getLogedInUser()?.id}/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const categories = response.rawResponse?.data.result[0] || [];
  const centers = response.rawResponse?.data.result[1] || [];
  const cTypes = response.rawResponse?.data.result[2] || [];
  const pTypes = response.rawResponse?.data.result[3] || [];
  const tTypes = response.rawResponse?.data.result[4] || [];
  const dLocation = response.rawResponse?.data.result[5] || [];
  const dPrest = response.rawResponse?.data.result[6] || [];
  const reg = response.rawResponse?.data.result[7] || [];
  const rawSeries = response.rawResponse?.data.result[8] || [];
  const uCollection = response.rawResponse?.data.result[9] || [];
  let vehicleCategories: Array<VehicleCategoryDto> = categories.category || [];
  let porscheCenters: Array<PorscheCenterDto> = centers.porscheCenters || [];
  let customerTypes: Array<CustomerType> = cTypes.customerTypes || [];
  let paymentTypes: Array<PaymentType> = pTypes.paymentTypes || [];
  let tradeIns: Array<TradeInType> = tTypes.tradeIns || [];
  let deliveryLocation: Array<DeliveryLocation> =
    dLocation.deliveryLocation || [];
  let deliveryPresent: Array<DeliveryPresent> = dPrest.deliveryPresent || [];
  let registration: Array<Registration> = reg.registration || [];
  let series: Array<Series> = rawSeries.series || [];
  let usedCarCollections: Array<UsedCarCollection> =
    uCollection.userCarCollections || [];

  let variables: Array<any> = [];
  variables[0] = vehicleCategories;
  variables[1] = porscheCenters;
  variables[2] = customerTypes;
  variables[3] = paymentTypes;
  variables[4] = tradeIns;
  variables[5] = deliveryLocation;
  variables[6] = deliveryPresent;
  variables[7] = registration;
  variables[8] = series;
  variables[9] = usedCarCollections;

  return variables;
};

export const dcsgetOrderManagedVariables = async (userId: number, isOrder:boolean) => {
  const path = `${API_DEV_URL2}/variables/all`;
  const apiResponse:any = await dcsgetRequest(path, {isOrder});
  let variables: Array<any> = [];
  if(apiResponse.status===type.SUCCESS  && apiResponse.response){
    const response = apiResponse.response
  const categories = response[0] || [];
  const centers = response[1] || [];
  const cTypes = response[2] || [];
  const pTypes = response[3] || [];
  const tTypes = response[4] || [];
  const dLocation = response[5] || [];
  const dPrest = response[6] || [];
  const reg = response[7] || [];
  const rawSeries = response[8] || [];
  const uCollection = response[9] || [];
  let vehicleCategories: Array<VehicleCategoryDto> = categories.category || [];
  let porscheCenters: Array<PorscheCenterDto> = centers.porscheCenters || [];
  let customerTypes: Array<CustomerType> = cTypes.customerTypes || [];
  let paymentTypes: Array<PaymentType> = pTypes.paymentTypes || [];
  let tradeIns: Array<TradeInType> = tTypes.tradeIns || [];
  let deliveryLocation: Array<DeliveryLocation> =
    dLocation.deliveryLocation || [];
  let deliveryPresent: Array<DeliveryPresent> = dPrest.deliveryPresent || [];
  let registration: Array<Registration> = reg.registration || [];
  let series: Array<Series> = rawSeries.series || [];
  let usedCarCollections: Array<UsedCarCollection> =
    uCollection.userCarCollections || [];

  variables[0] = vehicleCategories;
  variables[1] = porscheCenters;
  variables[2] = customerTypes;
  variables[3] = paymentTypes;
  variables[4] = tradeIns;
  variables[5] = deliveryLocation;
  variables[6] = deliveryPresent;
  variables[7] = registration;
  variables[8] = series;
  variables[9] = usedCarCollections;
}
  return variables;
};

export const getUpsaleVariblesNew = async (search: string) => {
  const path = `${API_DEV_URL}/variables/upsale/search/${search}/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);

  const data = response.rawResponse ? response.rawResponse.data : [{}, {}];
  let parts: Array<TequipmentAndPorscheDriverSelectionDTO> = [];
  let services: Array<WorkshopAndServiceCostsDTO> = [];

  if (data[0].parts && data[1].services) {
    parts = data[0].parts as [TequipmentAndPorscheDriverSelectionDTO];
    services = data[1].services as [WorkshopAndServiceCostsDTO];
  }

  return {
    parts: parts,
    services: services,
  };
};

export const dcsgetUpsaleVariblesNew = async (search: string) => {
  const path = `${API_DEV_URL2}/variables/upsale/search/origin`;
  const apiResponse = await dcsgetRequest(path, {searchterm:search});

  const data:any = apiResponse.response ? apiResponse.response : [{}, {}];
  let parts: Array<TequipmentAndPorscheDriverSelectionDTO> = [];
  let services: Array<WorkshopAndServiceCostsDTO> = [];

  if (data[0].parts && data[1].services) {
    parts = data[0].parts as [TequipmentAndPorscheDriverSelectionDTO];
    services = data[1].services as [WorkshopAndServiceCostsDTO];
  }

  return {
    parts: parts,
    services: services,
  };
};

export const postUpsaleDetails = async (upsaleDetails: UpsaleDetailsDTO) => {
  const path = `${API_DEV_URL}/upsale/3`;
  const response = await postRequest(path, upsaleDetails);

  return response;
};

export const putUpsaleDetails = async (upsaleDetails: UpsaleDetailsDTO) => {
  const path = `${API_DEV_URL}/upsale/3`;
  const response = await putRequest(path, upsaleDetails);

  return response;
};

export const postOrderDetails = async (orderDetails: OrderDTO) => {
  const path = `${API_DEV_URL}/order`;
  const response = await postRequest(path, orderDetails);


  return response;
};

export const putOrderDetails = async (orderDetails: OrderDTO) => {
  const path = `${API_DEV_URL}/order/${orderDetails.id}`;
  const response = await putRequest(path, orderDetails);

  return response;
};

export const postSaveDraftOrderDetails = async (
  saveAsDraftOrderDetails: SaveAsDraftDTO
) => {

  const path = `${API_DEV_URL}/saveasdraft/order/user/${getLogedInUser().id}`;
  const response = await postRequest(path, saveAsDraftOrderDetails);


  return response;
};

export const dcspostSaveDraftOrderDetails = async (
  saveAsDraftOrderDetails: SaveAsDraftDTO) => {
  const path = `${API_DEV_URL2}/saveasdraft/order/user`;
  const response = await dcspostRequest(path, saveAsDraftOrderDetails);
  return response as ApiReturnType;
};

export const postOrderSummary = async (post: OrderSummaryDTO) => {
  const path = `${API_DEV_URL}/order/summary`;
  const response = await postRequest(path, post);
  return response;
};

export const dcspostOrderSummary = async (post: OrderSummaryDTO) => {
  const path = `${API_DEV_URL2}/order/summary`;
  const response = await dcspostRequest(path, post);
  return response as ApiReturnType;
};

export const getOrderDetails = async (
  draftVersion: boolean,
  orderId: number
) => {
  let path = `${API_DEV_URL}/order/${orderId}`;
  if (draftVersion) path = `${API_DEV_URL}/saveasdraft/order/${orderId}`;

  const response = await getRequest(path);
  return response;
};

export const dcsgetOrderDetails = async (
  draftVersion: boolean,
  orderId: number
) => {
  let path = `${API_DEV_URL2}/order`;
  if (draftVersion) path = `${API_DEV_URL2}/saveasdraft/order`;

  const response = draftVersion? await dcsgetRequest(path, {draftId: orderId}) :  await dcsgetRequest(path, {order_id: orderId});
  return response as ApiReturnType;
};

export const getOrderSummary = async (orderId: number) => {
  let path = `${API_DEV_URL}/print-summary/${orderId}/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  return response;
};

export const dcsgetOrderSummary = async (orderId: number) => {
  let path = `${API_DEV_URL2}/print-summary`;
  const response = await dcsgetRequest(path, {order_id: orderId});
  return response as ApiReturnType;
};

export const getAllOrders = async () => {
  //const path = `${API_DEV_URL}/order/all`;
  const path = `${API_DEV_URL}/order/category/NewCar/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const orderResponse = response?.rawResponse?.data;


  return orderResponse;
};

export const dcsgetAllOrders = async () => {
  const path = `${API_DEV_URL2}/order/category/NewCar`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getOrdersByCategory = async (category: string) => {
  //const path = `${API_DEV_URL}/order/all`;
  const path = `${API_DEV_URL}/order/category/${category}/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const orderResponse = response?.rawResponse?.data;


  return orderResponse;
};
export const dcsgetOrdersByCategory = async (category: string) => {
  const path = `${API_DEV_URL2}/order/category`;
  const response = await dcsgetRequest(path, { categoryType: category});
  return response as ApiReturnType;
};

export const getOrderBookCategory = async (category: string) => {
  //const path = `${API_DEV_URL}/order/all`;
  const path = `${API_DEV_URL}/order/category/confirmed/${category}/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const orderResponse = response?.rawResponse?.data;


  return orderResponse;
};

export const dcsgetOrderBookCategory = async (category: string) => {
  const path = `${API_DEV_URL2}/order/category/confirmed`;
  const response = await dcsgetRequest(path, {categoryType: category});
  return response as ApiReturnType;
};

export const getOrderManagedVariablesNew = async (userId: number) => {
  const path = `${API_DEV_URL}/variables/all/${getLogedInUser()?.id}/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);

  const data = response.rawResponse?.data.result;

  const categories = data[0] || [];
  const centers = data[1] || [];
  const cTypes = data[2] || [];
  const pTypes = data[3] || [];
  const tTypes = data[4] || [];
  const dLocation = data[5] || [];
  const dPrest = data[6] || [];
  const reg = data[7] || [];
  const rawSeries = data[8] || [];

  let vehicleCategories: Array<VehicleCategoryDto> = categories.category || [];
  let porscheCenters: Array<PorscheCenterDto> = centers.porscheCenters || [];
  let customerTypes: Array<CustomerType> = cTypes.customerTypes || [];
  let paymentTypes: Array<PaymentType> = pTypes.paymentTypes || [];
  let tradeIns: Array<TradeInType> = tTypes.tradeIns || [];
  let deliveryLocation: Array<DeliveryLocation> =
    dLocation.deliveryLocation || [];
  let deliveryPresent: Array<DeliveryPresent> = dPrest.deliveryPresent || [];
  let registration: Array<Registration> = reg.registration || [];
  let series: Array<Series> = rawSeries.series || [];

  let variables: Array<any> = [];
  variables[0] = vehicleCategories;
  variables[1] = porscheCenters;
  variables[2] = customerTypes;
  variables[3] = paymentTypes;
  variables[4] = tradeIns;
  variables[5] = deliveryLocation;
  variables[6] = deliveryPresent;
  variables[7] = registration;
  variables[8] = series;

  return variables;
};
export const dcsgetOrderManagedVariablesNew = async (userId: number, isOrder:boolean) => {
  const path = `${API_DEV_URL2}/variables/all`;
  const response = await dcsgetRequest(path, {isOrder});
  const apiResponse = response as any
  
  let variables: Array<any> = [];
  if(apiResponse.status===type.SUCCESS  && apiResponse.response){

    const data = apiResponse.response;
    const categories = data[0] || [];
    const centers = data[1] || [];
    const cTypes = data[2] || [];
    const pTypes = data[3] || [];
    const tTypes = data[4] || [];
    const dLocation = data[5] || [];
    const dPrest = data[6] || [];
    const reg = data[7] || [];
    const rawSeries = data[8] || [];

  let vehicleCategories: Array<VehicleCategoryDto> = categories?.category || [];
  let porscheCenters: Array<PorscheCenterDto> = centers?.porscheCenters || [];
  let customerTypes: Array<CustomerType> = cTypes?.customerTypes || [];
  let paymentTypes: Array<PaymentType> = pTypes?.paymentTypes || [];
  let tradeIns: Array<TradeInType> = tTypes?.tradeIns || [];
  let deliveryLocation: Array<DeliveryLocation> =
    dLocation?.deliveryLocation || [];
  let deliveryPresent: Array<DeliveryPresent> = dPrest?.deliveryPresent || [];
  let registration: Array<Registration> = reg?.registration || [];
  let series: Array<Series> = rawSeries?.series || [];

  variables[0] = vehicleCategories;
  variables[1] = porscheCenters;
  variables[2] = customerTypes;
  variables[3] = paymentTypes;
  variables[4] = tradeIns;
  variables[5] = deliveryLocation;
  variables[6] = deliveryPresent;
  variables[7] = registration;
  variables[8] = series;
  }

  return variables;
};

export const getGeneralTabs = async () => {
  const path = `${API_DEV_URL}/variables/general/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const generaldata = response?.rawResponse?.data;


  return generaldata;
};

export const dcsGetGeneralTabs = async () => {
  const path = `${API_DEV_URL2}/variables/general/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getDealers= async () => {
  const path = `${API_DEV_URL}/variables/dealers/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);

  const generaldata = response?.rawResponse?.data;


  return generaldata;
}

export const dcsgetDealers= async () => {
  const path = `${API_DEV_URL2}/variables/dealers/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
}

export const getSeries = async () => {
  const path = `${API_DEV_URL}/variables/series`;
  const response = await getRequest(path);
  const responseData = response?.rawResponse?.data;

  return responseData;
};

export const getPaw = async () => {
  const path = `${API_DEV_URL}/variables/paw/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const getResponse = response?.rawResponse?.data;


  if (getResponse.status === "OK") {
    return getResponse.result;
  } else {
    return [];
  }
};

export const dcsgetPaw = async () => {
  const path = `${API_DEV_URL2}/variables/paw/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType
};

export const putPaw = async (pawData: Array<GetPaw>) => {
  const path = `${API_DEV_URL}/variables/paw/origin/${getLogedInOrigin()?.id}`;
  const response = await putRequest(path, pawData);


  return response;
};
export const dcsputPaw = async (pawData: Array<GetPaw>) => {
  const path = `${API_DEV_URL2}/variables/paw/origin`;
  const response = await dcsputRequest(path, pawData);
  return response as ApiReturnType;
};

export const getRvm = async () => {
  const path = `${API_DEV_URL}/variables/rvm/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const getResponse = response?.rawResponse?.data;


  if (getResponse.status === "OK") {
    return getResponse.result;
  } else {
    return [];
  }
};

export const dcsgetRvm = async () => {
  const path = `${API_DEV_URL2}/variables/rvm/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const putRvm = async (rvmData: Array<GetRvm>) => {
  const path = `${API_DEV_URL}/variables/rvm/origin/${getLogedInOrigin()?.id}`;
  const response = await putRequest(path, rvmData);


  return response;
};

export const dcsputRvm = async (rvmData: Array<GetRvm>) => {
  const path = `${API_DEV_URL2}/variables/rvm/origin`;
  const response = await dcsputRequest(path, rvmData);
  return response as ApiReturnType;
};

export const putServices = async (service: Array<Workshop>) => {
  const path = `${API_DEV_URL}/variables/services/origin/${getLogedInOrigin()?.id}`;
  const response = await putRequest(path, service);

  return response;
};
export const dcsputServices = async (service: Array<Workshop>) => {
  const path = `${API_DEV_URL2}/variables/services/origin`;
  const response = await dcsputRequest(path, service);

  return response as ApiReturnType;
};

export const getWorkshopServices = async () => {
  const path = `${API_DEV_URL}/variables/services/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const workshopServicesResponse = response?.rawResponse?.data;
  return workshopServicesResponse;
};

export const dcsgetWorkshopServices = async () => {
  const path = `${API_DEV_URL2}/variables/services/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getParts = async (showAll: boolean) => {
  const path = `${API_DEV_URL}/variables/parts/origin/${getLogedInOrigin()?.id}/${showAll}`;
  const response = await getRequest(path);
  const partsResponse = response?.rawResponse?.data;
  return partsResponse;
};

export const dcsgetParts = async (showAll: boolean) => {
  const path = `${API_DEV_URL2}/variables/parts/origin`;
  const response = await dcsgetRequest(path, {ShowAll:showAll} );
  return response as ApiReturnType;
};


export const uploadParts = async (formData: FormData) => {
  const path = `${API_DEV_URL}/variables/parts`;
  try {
  const response = await putRequest(path, formData, {
    "Content-Type": "multipart/form-data",
  });
  const partsResponse = response?.rawResponse?.data;
  if (partsResponse.status === "OK") {
    return getParts(false);
  }
  } catch(error){
  }
  

  return [];
};

export const dcsuploadParts = async (formData: FormData) => {
  const path = `${API_DEV_URL2}/variables/parts`;
   let response = {}
  const uplodRresponse = await dcsputRequest(path, formData,  {"Content-Type": "multipart/form-data"});
  response = uplodRresponse
  if(uplodRresponse.status===type.SUCCESS){
      const partResponse = await dcsgetParts(false)
      if(partResponse.status===type.SUCCESS){
        response= partResponse
      }
  } 
  return response as ApiReturnType;
};

export const getVat = async () => {
  const path = `${API_DEV_URL}/variables/vat/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const vatResponse = response?.rawResponse?.data;
  return vatResponse;
};
export const dcsgetVat = async () => {
  const path = `${API_DEV_URL2}/variables/vat/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType ;
};



export const updateGeneralData = async (data: any) => {
  const path = `${API_DEV_URL}/variables/general/origin/${getLogedInOrigin()?.id}`;
  const response = await putRequest(path, data);
  const generalResponse = response?.rawResponse?.data;
  return generalResponse;
};

export const dcsUpdateGeneralData = async (data: any) => {
  const path = `${API_DEV_URL2}/variables/general/origin`;
  const response = await dcsputRequest(path, data);
  return response as ApiReturnType;
};


export const getTransferPickupRegistrationData = async () => {
  const path = `${API_DEV_URL}/variables/transferPickupRegistration/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const transferPickupRegistrationData = response?.rawResponse?.data;


  return transferPickupRegistrationData;
};

export const dcsgetTransferPickupRegistrationData = async () => {
  const path = `${API_DEV_URL2}/variables/transferPickupRegistration/origin`;
  const response = await dcsgetRequest(path);
  return response as  ApiReturnType;
}; 

export const putTransferPickupRegistrationData = async (data: any) => {
  const path = `${API_DEV_URL}/variables/transferPickupRegistration/origin/${getLogedInOrigin()?.id}`;
  const response = await putRequest(path, data);
  const transferPickupRegistrationData = response?.rawResponse?.data;



  return transferPickupRegistrationData;
};
export const dcsputTransferPickupRegistrationData = async (data: any) => {
  const path = `${API_DEV_URL2}/variables/transferPickupRegistration/origin`;
  const response = await dcsputRequest(path, data);
  return response as ApiReturnType
};

export const getModels = async (query: string) => {
  const path =
    query.length > 0
      ? `${API_DEV_URL}/variables/model/filter/${getLogedInOrigin()?.id}?${query}`
      : `${API_DEV_URL}/variables/model/filter/${getLogedInOrigin()?.id}`;

  const response = await getRequest(path);
  const models = response?.rawResponse?.data;


  return models;
  //return test;
};

export const dcsgetModels = async (query: string) => {
  const path =
    query.length > 0
      ? `${API_DEV_URL2}/variables/model/filter/?${query}`
      : `${API_DEV_URL2}/variables/model/filter`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};



export const getMargins = async () => {
  const path = `${API_DEV_URL}/variables/margins/origin/${getLogedInOrigin()?.id}`;

  const response = await getRequest(path);
  const models = response?.rawResponse?.data?.result;
  return response;
};

export const getModelById = async (id: number) => {
  const res = await getModels("");
  const models = res["result"] as Array<Model>;

  if (models.length > 0) {
    const fill = models.filter((x) => x.id === id);

    if (fill.length > 0) {
      return fill[0];
    }
  }
  return false;
};

export const putModels = async (postModels: Array<Model>) => {
  const path = `${API_DEV_URL}/variables/model/origin/${getLogedInOrigin()?.id}`;
  const response = await putRequest(path, postModels);
  const models = response?.rawResponse?.data;


  return models;
};

export const dcsputModels = async (postModels: Array<Model>) => {
  const path = `${API_DEV_URL2}/variables/model/origin`;
  const response = await dcsputRequest(path, postModels);
 return response as ApiReturnType;
};

export const getCommissionScaling = async () => {
  //const path = `${API_DEV_URL}/order/all`;
  const path = `${API_DEV_URL}/commission-scaling/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const commissionResponse = response?.rawResponse?.data;


  return commissionResponse;
};
export const dcsgetCommissionScaling = async () => {
  const path = `${API_DEV_URL2}/commission-scaling/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType
};

export const getActiveCommissionScaling = async () => {
  //const path = `${API_DEV_URL}/order/all`;
  const path = `${API_DEV_URL}/commission-scaling/activated/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const commissionResponse = response?.rawResponse?.data;


  return commissionResponse;
};

export const dcsgetActiveCommissionScaling = async () => {
  const path = `${API_DEV_URL2}/commission-scaling/activated/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};


export const putCommissionScaling = async (
  commissionScalingDTO: CommissionScalingDTO
) => {
  const path = `${API_DEV_URL}/commission-scaling`;
  const response = await putRequest(path, commissionScalingDTO);
  return response;
};

export const dcsputCommissionScaling = async (
  commissionScalingDTO: CommissionScalingDTO
) => {
  const path = `${API_DEV_URL2}/commission-scaling`;
  const response = await dcsputRequest(path, commissionScalingDTO);
  return response as ApiReturnType;
};


export const validateCommissionScaling = async (
  commissionScalingDTO: CommissionScalingDTO
) => {
  const path = `${API_DEV_URL}/commission-scaling/validate`;
  const response = await postRequest(path, commissionScalingDTO);
  return response;
};

export const getActiveUsers = async () => {
  
  const path = `${API_DEV_URL}/userdetails/active/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const activeUsers = response?.rawResponse?.data;
  return activeUsers;
};

export const dcsgetActiveUsers = async () => {
  const path = `${API_DEV_URL2}/userdetails/active/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getInActiveUsers = async () => {
  const path = `${API_DEV_URL}/userdetails/inactive/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const inActiveUsers = response?.rawResponse?.data;
  return inActiveUsers;
};

export const dcsgetInActiveUsers = async () => {
  const path = `${API_DEV_URL2}/userdetails/inactive/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getNewUsers = async () => {
  const path = `${API_DEV_URL}/userdetails/newusers/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const newUsers = response?.rawResponse?.data;
  return newUsers;
};

export const dcsgetNewUsers = async () => {
  const path = `${API_DEV_URL2}/userdetails/newusers/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const putUsers = async (users: Array<UserDetails>) => {
  const path = `${API_DEV_URL}/userdetails/bulkupdate`;
  const response = await putRequest(path, users);
  const newUsers = response?.rawResponse?.data;

  return response;
};

export const getDashboardTasks = async (showall: boolean) => {
  const path = `${API_DEV_URL}/tasks/user/${getLogedInUser()?.id}/${showall}`;
  const response = await getRequest(path);
  const tasks = response?.rawResponse?.data;
  return tasks;
};

export const dcsgetDashboardTasks = async (showall: boolean) => {
  const path = `${API_DEV_URL2}/tasks/user`;
  const response = await dcsgetRequest(path, {showAll:showall});
  return response as ApiReturnType;
};

export const getDashboardNotifications = async (showall: boolean) => {
  const path = `${API_DEV_URL}/notifications/all/${getLogedInUser()?.id}/${showall}`;
  const response = await getRequest(path);
  const notifications = response?.rawResponse?.data;
  return notifications;
};

export const dcsgetDashboardNotifications = async (showall: boolean) => {
  const path = `${API_DEV_URL2}/notifications/all`;
  const response = await dcsgetRequest(path, {showAll:showall});
  return response as ApiReturnType;
};

export const deleteNotifications = async (
  notificationId: number,
  // userId: number
) => {
  const path = `${API_DEV_URL}/notifications/${notificationId}/${await getLogedInUser()?.id}`;
  const response = await deleteRequest(path);
  const notifications = response?.rawResponse?.data;
  return notifications;
};

export const dcsdeleteNotifications = async (
  notificationId: number
) => {
  const path = `${API_DEV_URL2}/notifications`;
  const response = await dcsdeleteRequest(path, {
    notificationId: notificationId,
  });
  return response as ApiReturnType;
};

export const markAsReadNotifications = async (
  notificationId: number,
  userId: number
) => {
  const path = `${API_DEV_URL}/notifications/${notificationId}/${userId}`;
  const response = await putRequest(path, {});
  const notifications = response?.rawResponse?.data;
  return notifications;
};

export const dcsmarkAsReadNotifications = async (notificationId: number) => {
  const path = `${API_DEV_URL2}/notifications/read`;
  const response = await dcsputRequest(path, '', { notificationId: notificationId });
  return response as ApiReturnType;
};

export const putTasksData = async (data: any, loggedInUserId: number) => {
  const path = `${API_DEV_URL}/tasks/${await getLogedInUser()?.id}`;
  const response = await putRequest(path, data);
  const taskresponse = response?.rawResponse?.data;
  return taskresponse;
};
export const dcsputTasksData = async (data: any) => {
  const path = `${API_DEV_URL2}/tasks`;
  const response = await dcsputRequest(path, data);
  return response as ApiReturnType;
};

export const getUsers = async () => {
  const path = `${API_DEV_URL}/userdetails/active/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const partsResponse = response?.rawResponse?.data;
  return partsResponse;
};

export const dcsgetUsers = async () => {
  const path = `${API_DEV_URL2}/userdetails/active/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getTaskUsers = async () => {
  const path = `${API_DEV_URL}/userdetails/active/tasks/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const partsResponse = response?.rawResponse?.data;
  return partsResponse;
};

export const dcsgetTaskUsers = async () => {
  const path = `${API_DEV_URL2}/userdetails/active/tasks/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getAllNotifications = async (userId: number, all: boolean) => {
  const path = `${API_DEV_URL}/notifications/all/${getLogedInUser()?.id}/${all}`;
  const response = await getRequest(path);
  const notificationsResponse = response?.rawResponse?.data;
  return notificationsResponse;
};

export const getNotificationsByOrderId = async (orderId: number, loggedInUserId: number, isDraft: boolean) => {
  const path = `${API_DEV_URL}/notifications/${orderId}/${getLogedInUser()?.id}/${isDraft}`;
  const response = await getRequest(path);
  const notificationsResponse = response?.rawResponse?.data;
  return notificationsResponse;
};

export const dcsgetNotificationsByOrderId = async (orderId: number, isDraft: boolean) => {
  const path = `${API_DEV_URL2}/notifications`;
  const response = await dcsgetRequest(path, {order_id:orderId, draft:isDraft});
  return response as ApiReturnType;
};

//export const getTasksByOrderId = async (orderId: number, loggedInUserId: number, isDraft: boolean) => {
export const getTasksByOrderId = async (orderId: number, isDraft: boolean) => {
  const path = `${API_DEV_URL}/tasks/${orderId}/${isDraft}`;
  const response = await getRequest(path);
  const notificationsResponse = response?.rawResponse?.data;
  return notificationsResponse;
};

export const dcsgetTasksByOrderId = async (orderId: number, isDraft: boolean) => {
  const path = `${API_DEV_URL2}/tasks`;
  const response = await dcsgetRequest(path, {order_id:orderId, draft:isDraft});
  return response as ApiReturnType;
};

export const deleteTasks = async (
  taskId: number,
  userId: number
) => {
  //const path = `${API_DEV_URL}/tasks/${userId}/${taskId}`;
  const path = `${API_DEV_URL}/tasks/${userId}/${taskId}`;
  const response = await deleteRequest(path);
  const notifications = response?.rawResponse?.data;
  return notifications;
};

export const dcsdeleteTasks = async (taskId: number) => {
  const path = `${API_DEV_URL2}/tasks`;
  const response = await dcsdeleteRequest(path, { taskId: taskId });
  return response as ApiReturnType;
};

export const putNotificationsData = async(data:any) => {
  const path = `${API_DEV_URL}/notifications`;
  const response = await putRequest(path,data);
  const notificationsdata = response?.rawResponse?.data;
  return notificationsdata;
}

export const dcsputNotificationsData = async(data:any) => {
  const path = `${API_DEV_URL2}/notifications`;
  const response = await dcsputRequest(path,data);
  return response as ApiReturnType;
}

export const getAdminApprovalConfiguration = async (category: string, originId: number) => {
  //const path = `${API_DEV_URL}/approval-configuration/category/${category}/origin/${getLogedInOrigin()?.id}`;
  const path = `${API_DEV_URL}/approval-configuration/category/${category}/origin/${originId}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const dcsgetAdminApprovalConfiguration = async (category: string) => {
  //const path = `${API_DEV_URL}/approval-configuration/category/${category}/origin/${getLogedInOrigin()?.id}`;
  const path = `${API_DEV_URL2}/approval-configuration/category/origin`;
  const response = await dcsgetRequest(path,{categoryType:category} );
  return response as ApiReturnType;
};

export const getApprovalConfiguration = async (category: string, originId: number, orderId: number) => {
  //const path = `${API_DEV_URL}/approval-configuration/category/${category}/origin/${getLogedInOrigin()?.id}`;
  const path = `${API_DEV_URL}/approval-configuration/category/${category}/origin/${originId}/order/${orderId}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const dcsgetApprovalConfiguration = async (category: string, originId: number, orderId: number) => {
  const path = `${API_DEV_URL2}/approval-configuration/category/origin`;
  const response = await dcsgetRequest(path, {categoryType: category, order_id:orderId});
  return response as ApiReturnType;
};

export const getFirstApprovalConfiguration = async (category: string, originId: number) => {
  const path = `${API_DEV_URL}/approval-configuration/category/${category}/origin/${originId}/isDraft/true`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};
export const dcsgetFirstApprovalConfiguration = async (category: string, originId: number) => {
  const path = `${API_DEV_URL2}/approval-configuration/category/${category}/origin/${originId}/isDraft/true`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const postApprovalConfiguration = async (data: any) => {
  const path = `${API_DEV_URL}/approval-configuration`;
  const response = await postRequest(path,data);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const dcspostApprovalConfiguration = async (data: any) => {
  const path = `${API_DEV_URL2}/approval-configuration`;
  const response = await dcspostRequest(path,data);
  return response as ApiReturnType;
};

export const getUserWithPermissions = async() => {
  // Need to change the URL
  const path = `${API_DEV_URL}/user-profile`;
  const response = await getRequest(path);
  const resData = response?.rawResponse?.data;
  return resData;
}

export const dcsgetUserWithPermissions = async() => {
  // Need to change the URL
  const path = `${API_DEV_URL2}/user-profile`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
}

export const getNotification = async (loggedInUserId: number,) => {
  const path = `${API_DEV_URL}/tasks-notifications/count/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const notificationResponse = response?.rawResponse?.data;
  return notificationResponse;
};

export const dcsgetNotification = async () => {
  const path = `${API_DEV_URL2}/tasks-notifications/count`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType ;
};


export const getLogedInOrigin=():PorscheCenterDTO => {
  const userDetails = sessionStorage.getItem("userDetails")!!;
  const parsedUser = JSON.parse(userDetails) as UserDetailsDTO;
  return parsedUser.origin;
 
}

export const getLogedInUser=():UserDTO => {
  const userDetails = sessionStorage.getItem("userDetails")!!;
  const parsedUser = JSON.parse(userDetails) as UserDTO;
  return parsedUser;
}

export const getCommissionRecalc = async () => {
  const path = `${API_DEV_URL}/commissions/recalculation/origin/${getLogedInOrigin()?.id}/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const commissionRecalc = response?.rawResponse?.data;
  return commissionRecalc;
};

export const dcsgetCommissionRecalc = async () => {
  const path = `${API_DEV_URL2}/commissions/recalculation/origin/user`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getCommissionApproval = async () => {
  const path = `${API_DEV_URL}/commissions/approval/origin/${getLogedInOrigin()?.id}/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const commissionApproval = response?.rawResponse?.data;
  return commissionApproval;
};

export const dcsgetCommissionApproval = async () => {
  const path = `${API_DEV_URL2}/commissions/approval/origin/user`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};
export const getCommissionHistory = async () => {
  const path = `${API_DEV_URL}/commissions/history/origin/${getLogedInOrigin()?.id}/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const commissionHistory = response?.rawResponse?.data;
  return commissionHistory;
};
export const dcsgetCommissionHistory = async () => {
  const path = `${API_DEV_URL2}/commissions/history/origin/user`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType; 
};

export const getCommissionAccounting = async () => {
  const path = `${API_DEV_URL}/commissions/approval/origin/${getLogedInOrigin()?.id}/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const commissionAccounting = response?.rawResponse?.data;
  return commissionAccounting;
};
export const getCommissionHistoryExportDetails = async (month:string) => {
  const path = `${API_DEV_URL}/commissions/history/origin/${month}`;
  const response = await getRequest(path);
  return(response.rawResponse?.data.result)
};

export const dcsgetCommissionHistoryExportDetails = async (month:string) => {
  const path = `${API_DEV_URL2}/commissions/history/origin`;
  const response = await dcsgetRequest(path, {accounting_month:month});
  return response as ApiReturnType
};
export const putConvertOrder = async(orderId:number,isDraft: boolean,orderStatus: string ) => {
  const path = `${API_DEV_URL}/order/user/${getLogedInUser()?.id}/order/${isDraft}/${orderId}/status/${orderStatus}`;
  const response = await putRequest(path,{});
  const responseData = response?.rawResponse?.data;
  return responseData;
};
export const dcsputConvertOrder = async(orderId:number, isDraft: boolean, orderStatus: string ) => {
  const path = `${API_DEV_URL2}/order/user/order/status`;
  const response = await dcsputRequest(path, '', {order_id: orderId,draft:isDraft, orderStatus: orderStatus});
  return response as ApiReturnType;
};
export const putSubmitOrderRegistration = async(data: OrderRegistrationDTO,orderId:Number) => {
  const path = `${API_DEV_URL}/order/registration/user/${getLogedInUser()?.id}/order/${orderId}`;
  const response = await putRequest(path,data);
  const responseData = response?.rawResponse?.data;
  return responseData;
};

export const dcsputSubmitOrderRegistration = async(data: OrderRegistrationDTO,orderId:Number) => {
  const path = `${API_DEV_URL2}/order/registration/user/order`;
  const response = await dcsputRequest(path,data, {order_id:orderId});
  return response as ApiReturnType;
};

export const putSubmitCustomerDelivery = async(data: CustomerDeliveryDTO,orderId:Number) => {
  const path = `${API_DEV_URL}/order/customerdelivery/user/${getLogedInUser()?.id}/order/${orderId}`;
  const response = await putRequest(path,data);
  const responseData = response?.rawResponse?.data;
  return responseData;
};

export const dcsputSubmitCustomerDelivery = async(data: CustomerDeliveryDTO,orderId:Number) => {
  const path = `${API_DEV_URL2}/order/customerdelivery/user/order`;
  const response = await dcsputRequest(path,data, {order_id:orderId});
  return response as ApiReturnType;
};


export const getLoggedInUserDetails= ():UserDetailsDTO=> {
  const userDetails = sessionStorage.getItem("userDetails")!!;
  const parsedUser = JSON.parse(userDetails) as UserDetailsDTO;
  return parsedUser;
}



export const updateUser = async (user:UserDetails) => {
  const path = `${API_DEV_URL}/userdetails`;
  const response = await putRequest(path, user);
  const newUsers = response?.rawResponse?.data;

  return response;
};

export const dcsupdateUser = async (user:UserDetails) => {
  const path = `${API_DEV_URL2}/userdetails`;
  const response = await dcsputRequest(path, user);
  return response as ApiReturnType;
};

/** Approval api helpers */

/** Order */
export const postOrderApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL}/approvals/order/create`;
  const response = await putRequest(path, body);


  const responseData = response?.rawResponse?.data;
  return responseData;
};

export const dcspostOrderApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL2}/approvals/order/create`;
  const response = await dcsputRequest(path, body);
  return response as ApiReturnType;
};

export const putOrderApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL}/approvals/order/update`;
  const response = await putRequest(path, body);

  const responseData = response?.rawResponse?.data;
  return responseData;
};

export const dcsputOrderApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL2}/approvals/order/update`;
  const response = await dcsputRequest(path, body);
  return response as ApiReturnType;
};

export const changeStatusOrderApproval = async(body: ApprovalStepDTO) => {
  const path = `${API_DEV_URL}/approvals/order/change-status`;
  const response = await putRequest(path, body);
  const responseData = response?.rawResponse?.data;
  return responseData;
};

export const dcschangeStatusOrderApproval = async(body: ApprovalStepDTO) => {
  const path = `${API_DEV_URL2}/approvals/order/change-status`;
  const response = await dcsputRequest(path, body);
  return response as ApiReturnType;
};

/** Commission */
export const postCommissionApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL}/approvals/commission/create`;
  const response = await putRequest(path, body);

  const responseData = response?.rawResponse?.data;
  return responseData;
};
export const dcspostCommissionApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL2}/approvals/commission/create`;
  const response = await dcsputRequest(path, body);
  return response as ApiReturnType;
};

export const putCommissionApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL}/approvals/commission/update`;
  const response = await putRequest(path, body);

  const responseData = response?.rawResponse?.data;
  return responseData;
};

export const dcsputCommissionApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL2}/approvals/commission/update`;
  const response = await dcsputRequest(path, body);
  return response as ApiReturnType;
};

export const resubmitCommissionApprovals = async(body: Array<ApprovalStepDTO>) => {
  const path = `${API_DEV_URL}/approvals/commission/update`;
  const response = await putRequest(path, body);

  const responseData = response?.rawResponse?.data;
  return responseData;
};

export const changeStatusCommissionApproval = async(body: ApprovalStepDTO) => {
  const path = `${API_DEV_URL}/approvals/commission/change-status`;
  const response = await putRequest(path, body);

  const responseData = response?.rawResponse?.data;
  return responseData;
};

export const dcschangeStatusCommissionApproval = async(body: ApprovalStepDTO) => {
  const path = `${API_DEV_URL2}/approvals/commission/change-status`;
  const response = await dcsputRequest(path, body);
  return response as ApiReturnType;
};

export const getApprovals = async(orderId: number) => {
  const path = `${API_DEV_URL}/approvals/order/${orderId}`;
  const response = await getRequest(path);

  const getResponse = response?.rawResponse?.data;
  return response?.rawResponse?.data;
};
export const dcsgetApprovals = async(orderId: number) => {
  const path = `${API_DEV_URL2}/approvals/order`;
  const response = await dcsgetRequest(path, {order_id: orderId});;
  return response as ApiReturnType;
};



export const getAprrovalSetpById = async(id: number) => {
  const path = `${API_DEV_URL}/approvals/${id}`;
  const response = await getRequest(path);

  const getResponse = response?.rawResponse?.data;
  return response?.rawResponse?.data;
};

export const putAccountingApprovalConfiguration = async (data: any) => {
  const path = `${API_DEV_URL}/approval-configuration/accounting`;
  const response = await putRequest(path,data);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const dcsputAccountingApprovalConfiguration = async (data: any) => {
  const path = `${API_DEV_URL2}/approval-configuration/accounting`;
  const response = await dcsputRequest(path,data);
  return response as ApiReturnType;
};

export const getAccountingApprovalConfiguration = async () => {
  const path = `${API_DEV_URL}/approval-elconfiguration/accounting/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const dcsgetAccountingApprovalConfiguration = async () => {
  const path = `${API_DEV_URL2}/approval-configuration/accounting/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getCityBasedUsers = async () => {
  const path = `${API_DEV_URL}/user-profile/cityusers/origin/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const users = response?.rawResponse?.data;
  return users;
};
export const dcsgetCityBasedUsers = async () => {
  const path = `${API_DEV_URL2}/user-profile/cityusers/origin`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getOrdersForMonthActive = async () => {
  const path = `${API_DEV_URL}/commissions/accounting/origin/${getLogedInOrigin()?.id}/active/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const dcsgetOrdersForMonthActive = async () => {
  const path = `${API_DEV_URL2}/commissions/accounting/origin/active/user`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getComissionsAccounting = async () => {
  const path = `${API_DEV_URL}/commissions/accounting/origin/${getLogedInOrigin()?.id}/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const commissionHistory = response?.rawResponse?.data;
  return commissionHistory;
};

export const getOrdersByAccountingMonth = async (monthYear:string) => {
  const path = `${API_DEV_URL}/commissions/accounting/origin/${getLogedInOrigin()?.id}/user/${getLogedInUser()?.id}/date/${monthYear}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const  dcsgetOrdersByAccountingMonth = async (monthYear:string) => {
  const path = `${API_DEV_URL2}/commissions/accounting/origin/user`;
  const response = await dcsgetRequest(path, {accounting_month:monthYear});
  return response as ApiReturnType;
};

export const getOrdersForAccounting = async () => {
  const path = `${API_DEV_URL}/commissions/accounting/origin/${getLogedInOrigin()?.id}/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const dcsgetOrdersForAccounting = async () => {
  const path = `${API_DEV_URL2}/commissions/accounting/origin/user`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getAccountingUsers = async () => {
  const path = `${API_DEV_URL}/commissions/accounting/origin/${getLogedInOrigin()?.id}/users`;
  const response = await getRequest(path);
  const commissionHistory = response?.rawResponse?.data;
  return commissionHistory;
};

export const postComissionsAccounting = async (data:any) => {
  const path = `${API_DEV_URL}/commissions/accounting/start/user/${getLogedInUser()?.id}`;
  const response = await putRequest(path,data);
  const commissionAccount = response?.rawResponse?.data;
  return commissionAccount;
};

export const dcspostComissionsAccounting = async (data:any) => {
  const path = `${API_DEV_URL2}/commissions/accounting/start/user`;
  const response = await dcsputRequest(path,data);
  return response as ApiReturnType;
};

export const approveComissionsAccounting = async (userId:number,accountingData:any) => {
  const path = `${API_DEV_URL}/commissions/accounting/approve/user/${userId}`;
  const response = await putRequest(path,accountingData);
  const commissionAccount = response?.rawResponse?.data;
  return commissionAccount;
};

export const dcsapproveComissionsAccounting = async (accountingData:any) => {
  const path = `${API_DEV_URL2}/commissions/accounting/approve/user`;
  const response = await dcsputRequest(path,accountingData);
  return response as ApiReturnType;
};

export const resetComissionsAccounting = async (accountingData:any) => {
  const path = `${API_DEV_URL}/commissions/accounting/reset`;
  const response = await putRequest(path,accountingData);
  const commissionAccount = response?.rawResponse?.data;
  return commissionAccount;
};

export const dcsresetComissionsAccounting = async (accountingData:any) => {
  const path = `${API_DEV_URL2}/commissions/accounting/reset`;
  const response = await dcsputRequest(path,accountingData);
  return response as ApiReturnType;
};

export const excludeOrderFromAccounting = async (orderId:any,accountingData:any) => {
  const path = `${API_DEV_URL}/commissions/accounting/excludeOrder/order/${orderId}`;
  const response = await putRequest(path,accountingData);
  const commissionAccount = response?.rawResponse?.data;
  return commissionAccount;
};

export const dcsexcludeOrderFromAccounting = async (orderId:any,accountingData:any) => {
  const path = `${API_DEV_URL2}/commissions/accounting/excludeOrder/order`;
  const response = await dcsputRequest(path,accountingData,{originId: orderId} );
  return response as ApiReturnType;
};

export const commissionScalingByUser = async (userId: number) => {
  const path = `${API_DEV_URL}/commission-scaling/user/${userId}`;
  const response = await getRequest(path);
  const commissionScaling = response?.rawResponse?.data;
  return commissionScaling;
};

export const dcscommissionScalingByUser = async (userId: number) => {
  const path = `${API_DEV_URL2}/commission-scaling/user`;
  const response = await dcsgetRequest(path, {userId});
  return response as ApiReturnType;
};

export const getReferral = async () => {
  const path = `${API_DEV_URL}/variables/referralcommission/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const getResponse = response?.rawResponse?.data;


  if (getResponse.status === "OK") {
    return getResponse.result;
  } else {
    return [];
  }
};

export const findRecalculationByUserIdAndOrderId = async (orderId:any) => {
  const path = `${API_DEV_URL}/recalculation/user/${getLogedInUser()?.id}/order/${orderId}`;
  const response = await getRequest(path);
  const recalculation = response?.rawResponse?.data;
  return recalculation;
};
export const dcsfindRecalculationByUserIdAndOrderId = async (orderId:any) => {
  const path = `${API_DEV_URL2}/recalculation/user/order`;
  const response = await dcsgetRequest(path, {order_id:orderId});
  return response as ApiReturnType;
};

export const submitRecalculation=async(orderId:any,data:any)=>{
  const path = `${API_DEV_URL}/recalculation/user/${getLogedInUser()?.id}/order/${orderId}/submit`;
  const response = await postRequest(path,data);
  const recalculation = response?.rawResponse?.data;
  return recalculation;
}

export const dcssubmitRecalculation=async(orderId:any,data:any)=>{
  const path = `${API_DEV_URL2}/recalculation/user/order/submit`;
  const response = await dcspostRequest(path,data, {order_id: orderId});
  return response as ApiReturnType;
}

export const saveRecalculation=async(orderId:any,data:any)=>{
  const path = `${API_DEV_URL}/recalculation/user/${getLogedInUser()?.id}/order/${orderId}`;
  const response = await putRequest(path,data);
  const recalculation = response?.rawResponse?.data;
  return recalculation; 
}

export const dcssaveRecalculation=async(orderId:any,data:any)=>{
  const path = `${API_DEV_URL2}/recalculation/user/order`;
  const response = await dcsputRequest(path,data, {order_id : orderId});
  return response as ApiReturnType; 
}

export const saveSubsequentChange=async(orderId:any,data:any)=>{
  const path = `${API_DEV_URL}/subsequentchange/${getLogedInUser()?.id}/${orderId}`;
  const response = await putRequest(path,data);
  const recalculation = response?.rawResponse?.data;
  return recalculation; 
}

export const dcssaveSubsequentChange=async(orderId:any,data:any)=>{
  const path = `${API_DEV_URL2}/subsequentchange`;
  const response = await dcsputRequest(path,data, {order_id: orderId});
  return response as ApiReturnType; 
}

export const getUserApprovalAccess=async(category:any,approvalStep:any)=>{
  const path = `${API_DEV_URL}/approval-step-users-info/category/${category}/approvalStep/${approvalStep}/origin/${getLogedInOrigin()?.id}/user/${getLogedInUser()?.id}`;
  const response = await getRequest(path);
  const userApproval = response?.rawResponse?.data;
  return userApproval;
}
export const dcsgetUserApprovalAccess=async(category:any,approvalStep:any)=>{
  const path = `${API_DEV_URL2}/approval-step-users-info/category/approvalStep/origin/user`;
  const response = await dcsgetRequest(path, {categoryType:category, approvalStep: approvalStep});
  return response as ApiReturnType;
}

export const rolloutComissionsAccounting = async (accountingData:any) => {
  const path = `${API_DEV_URL}/commissions/accounting/rollout/user/${getLogedInUser()?.id}`;
  const response = await putRequest(path,accountingData);
  const commissionAccount = response?.rawResponse?.data;
  return commissionAccount;
};

export const dcsrolloutComissionsAccounting = async (accountingData:any) => {
  const path = `${API_DEV_URL2}/commissions/accounting/rollout/user`;
  const response = await dcsputRequest(path,accountingData);
  return response as ApiReturnType;
};

export const getChangeHistoryLogs = async () => {
  const path = `${API_DEV_URL}/changehistory/admin`;
  const response = await getRequest(path);
  return(response.rawResponse?.data.result)
};

export const dcsgetChangeHistoryLogs = async (showAllHistory:boolean) => {
  const path = `${API_DEV_URL2}/changehistory/admin`;
  const response = await dcsgetRequest(path, {showAll:showAllHistory});
  return response as ApiReturnType
};

export const postChangeHistoryLogs = async (data: HistoryLogTypes) => {
  const path = `${API_DEV_URL}/changehistory/admin`;
  const response = await postRequest(path,data);
  return response
};

export const dcspostChangeHistoryLogs = async (data: HistoryLogTypes|HistoryOrderLogTypes, type:"order"|"admin") => {
  const path = `${API_DEV_URL2}/changehistory`;
  const response = await dcspostRequest(path,data, {tag:type});
  return response as ApiReturnType
};

export const getOrderChangeHistoryLogs = async (orderId : number) => {
  const path = `${API_DEV_URL}/changehistory/order/${orderId}`;
  const response = await getRequest(path);
  return(response.rawResponse?.data.result)
};
export const dcsgetOrderChangeHistoryLogs = async (orderId : number) => {
  const path = `${API_DEV_URL2}/changehistory/order`;
  const response = await dcsgetRequest(path, {order_id:orderId});
  return response as ApiReturnType
};

export const postOrderChangeHistoryLogs = async (data: HistoryOrderLogTypes) => {
  const path = `${API_DEV_URL}/changehistory/order`;
  const response = await postRequest(path,data);
  return response 
};



export const postVariableTequipment = async ({originId,tab,value}:{originId:string, tab:string, value:boolean}) => {
  const path = `${API_DEV_URL}/variables/parts/origin/${originId}/${tab}/${value}`;
  const response = await postRequest(path,"");
  return response
}


export const dcspostVariableTequipment = async ({originId,tab,value}:{originId:string, tab:string, value:boolean}) => {
  const path = `${API_DEV_URL2}/variables/parts/origin`;
  const response = await dcspostRequest(path,"",{tab:tab, active:value});
  return response as ApiReturnType
}

export const postCreateManualCommission = async (data: any) => {
  const path = `${API_DEV_URL}/manual/commission/${getLogedInUser()?.id}`;
  const response = await postRequest(path,data);
  return response;
};

export const dcspostCreateManualCommission = async (data: any) => {
  const path = `${API_DEV_URL2}/manual/commission`;
  const response = await dcspostRequest(path,data);
  return response as ApiReturnType;
};

export const getManualCommission = async (commissionId: string) => {
  let path = `${API_DEV_URL}/manual/commission/order/${commissionId}`;
  const response = await getRequest(path);
  return response;
};

export const dcsgetManualCommission = async (commissionId: string) => {
  let path = `${API_DEV_URL2}/manual/commission/order`;
  const response = await dcsgetRequest(path, {order_id: commissionId});
  return response as ApiReturnType;
};

export const deleteManualCommission = async (orderId:any,data:any = {}) =>{
  const path = `${API_DEV_URL}/manual/commission/${orderId}/${getLogedInUser()?.id}`;
  const response = await putRequest(path,data);
  return response;
};

export const dcsdeleteManualCommission = async (orderId:any,data:any = {}) =>{
  const path = `${API_DEV_URL2}/manual/commission`;
  const response = await dcsputRequest(path,data, {order_id:orderId});
  return response as ApiReturnType;
};

export const getSubsequentApprovalConfig = async (orderId: number) => {
  const path = `${API_DEV_URL}/subsequentchange/order/approval/${orderId}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};
export const dcsgetSubsequentApprovalConfig = async (orderId: number) => {
  const path = `${API_DEV_URL2}/subsequentchange/order/approval`;
  const response = await dcsgetRequest(path, {order_id: orderId});
  return response as ApiReturnType;
};

export const postSubequentApproval = async (payload: SubsequentApprovalConfigResponse, orderId:number) => {
  const path = `${API_DEV_URL}/subsequentchange/order/approval/${orderId}`;
  const response = await postRequest(path, payload);
  return response;
};

export const dcspostSubequentApproval = async (payload: SubsequentApprovalConfigResponse, orderId:number) => {
  const path = `${API_DEV_URL2}/subsequentchange/order/approval`;
  const response = await dcspostRequest(path, payload, {order_id: orderId});
  return response as ApiReturnType;
};

export const getUserActivationStatus = async (uidID: string) => {
  const path = `${API_DEV_URL}/user-profile/new-user/userId/${uidID}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};

export const dcsgetUserActivationStatus = async () => {
  const path = `${API_DEV_URL2}/user-profile/new-user/userId`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};

export const getManualCommissionUserDispatchData = async () => {
  const path = `${API_DEV_URL}/manual/commission/role/${getLogedInOrigin()?.id}`;
  const response = await getRequest(path);
  const approvalConfig = response?.rawResponse?.data;
  return approvalConfig;
};
export const dcsgetManualCommissionUserDispatchData = async () => {
  const path = `${API_DEV_URL2}/manual/commission/role`;
  const response = await dcsgetRequest(path);
  return response as ApiReturnType;
};
export const dscgetExportOrder = async (category:string) => {
  const path = `${API_DEV_URL2}/order/export`;
  const response = await dcsgetRequest(path, {categoryType:category});
  return response as ApiReturnType
};

