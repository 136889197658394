export const navSideBar = [
  // {
  //   main: "Dashboard",
  //   subPages: [],
  //   icon: "",
  // },
  {
    main: "Orders",
    subPages: [
      {
        name: "In Review",
        path: "/order/inreview",
      },
      {
        name: "Order Book",
        path: "/order/book",
      },
    ],
    icon: "work",
    expand: false,
  },
  {
    main: "Commissions",
    subPages: [
      {
        name: "Recalculation",
        path: "/commission/recalculation",
      },
      {
        name: "Approval",
        path: "/commission/approval",
      },
      {
        name: "Accounting",
        path: "/commission/accounting",
      },
      {
        name: "History",
        path: "/commission/history",
      },
    ],
    icon: "purchase",
    expand: false,
  },
  {
    main: "Administration",
    subPages: [
      {
        name: "Variables",
        path: "/admin/variables",
        permissionName:"enableVariabales"

      },
      {
        name: "Commission Scaling",
        path: "/admin/commissionscaling",
        permissionName:"enableScaling"

      }, 
      {
        name: "User Management",
        path: "/admin/usermanagement",
        permissionName:"enableUserMgmt"

      },
      {
        name: "Approval Process",
        path: "/admin/aprrovalprocess",
        permissionName:"enableApprovals"

      },
      {
        name: "Change History",
        path: "/admin/history",
        permissionName:"enableChangeHistory"

      } 
    ],
    icon: "configurate",
    expand: false,
  },
];
