import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


function PageNotFound() {
    const navigate = useNavigate()
    useEffect(()=>{
        navigate("/dashboard")
    },[])
  return (
    <div>PageNotFound</div>
  )
}

export default PageNotFound