export enum ReferralType {
  ExternalPrivate = "external (private)",
  ExternalCommercial = "external (comm.)",
  InternalEmployee = "internal (empl.)",
}

export enum CustomerGroup {
  NewCustomer = "New Customer",
  RegularCustomer = "Regular Customer",
}

export enum CustomerType {
  PrivateCustomer = "Private Customer",
  BusinessClient = "Business Client",
  Dealer = "Dealer",
  DealerBiddingPlatform = "Dealer Bidding platform",
  VIPPress = "VIP / Press",
  KeyAccount = "Key Account",
  Supplier = "Supplier",
  Multiplier = "Multiplier",
  Employee="Employee"
}

export enum PaymentType {
  CASH = "Cash payment",
  BankTransfer = "Bank transfer",
  SUBSCRIPTION = "Subscription" ,
  Leasing_PFS = "Leasing (PFS)",
  Leasing_Other = "Leasing (Other)",
  Financing_PFS = "Financing (PFS)",
  Financing_Other = "Financing (Other)",
}

export enum UsedCarCollection {
  TradeIn = "Trade in",
  LeasingRedemptionPFS = "Leasing redemption PFS",
  LeasingRedemptionThirdParty = "Leasing redemption third party",
  LeasingReturnPFS = "Leasing return PFS",
  LeasingReturnThirdParty = "Leasing return third party",
  LeasingTakeover = "Leasing takeover",
  FinancingTakeover = "Financing takeover",
  FreePurchase = "Free purchase",
  FactoryCar = "Factory car",
  ServiceLoaner = "Service Loaner",
}

export enum OrderStatus {
  Draft,
  InReview,
  Cancelled,
  Converted,
  Approved,
  Paid
}

export enum TradeIn {
  TradeIn_Regular = "TradeIn_Regular",
  TradeIn_Differential = "TradeIn_Differential",
  Leasing_Return = "Leasing_Return",
  Leasing_Redemption = "Leasing_Redemption",
  Financing_Redemption = "Financing_Redemption", 
  Financing_Redemption_Differential = "Financing_Redemption_Differential",
}

export enum WinterOrSummer {
  winter = "winter",
  summer = "summer",
  na = "na",
}

export enum PartType {
  tire = "tire" ,
  part = "part"
}

export enum ServiceNameAdmin {
  TireChangePorscheCenter = "TireChangePorscheCenter",
  TireChangePorscheCenterCenterLock = "TireChangePorscheCenterCenterLock",
  TireChangeFactory = "TireChangeFactory",
  TireStorage = "TireStorage",
  TireShipment = "TireShipment",
  WorkshopWork = "WorkshopWork",
  None = "None",
}

export enum ModelRarity {
  StandardModel = "Standard model",
  SpecialModel = "Special model"
}

export enum EngineTypeModel {
  Combustion = "combustion", 
  Electro_hybrid = "electro / hybrid"
}

export enum BookingType {
  Profit = "Profit",
  Loss = "Loss",
}

export enum AdditionalCostsPriceType {
  Pickup = "Pickup",
  Registration = "Registration",
  Paw = "Paw",
  Assistance = "Assistance",
  Others = "Others"
}

export enum DiscountNetPriceType {
  VehicleDiscountNet = "Vehicle discount",
  ReferralCommissionNew = "Referral commission",
  DealerContributionNew = "Dealer Contribution",
  ReferralCommissionUsed = "Referral commission",
  DealerContributionUsed = "Dealer Contribution",
  ReferralCommissionDemo = "Referral commission",
  DealerContributionDemo = "Dealer Contribution",
}

export enum KickbackNetPriceType {
  SpecialConditions = "Special conditions",
  SalesPromotionNew = "Referral commission",
  SalesPromotionUsed = "Referral commission",
  SalesPromotionDemo = "Referral commission",
}

export enum Pickup {
  Zuffenhausen = "Factory pickup Zuffenhausen",
  Liepig = "Factory pickup Liepig",
}

export enum TradeInOptions {
  CarValueInternal = "CarValueInternal",
  CarValueCustomer = "CarValueCustomer",
  LossBooking = "LossBooking",
  ProfitBooking = "ProfitBooking",
  SharePc = "SharePc",
  ShareCustomer = "ShareCustomer",
}

export enum VehiclePriceType {
  VehicleBasePrice = "Vehicle base price",
  Options = "Options",
  RRP = "RRP",
  SellingPriceDemo = "Selling price",
  PurchasePriceDemo = "Purchase price",
  Discount = "Discount",
  SellingPriceUsed = "Selling price",
  PurchasePriceUsed = "Purchase price",
}

export enum Taxation {
  Regural = "Regular",
  Differential = "Differential"
}

export enum CategoryType {
  NewCar,
  DemoCar,
  UsedCar
}

export enum VehicleCommission {
  Standard = "Standard",
  Fixed = "Fixed"
}

export enum Reference {
  Euro = "Euro",
  GrossProfit="Gross Profit",
  NetTurnover="Net Turnover",
  GrossTurnover="Gross Turnover",
  RRPNet="RRP (net)",
  RRPGross="RRP (gross)",
  SellingPriceNet="Selling price (net)",
  SellingPriceGross="Selling price (gross)"
}

enum CommissionScalingCategoryType {
  NewCarConfigured = "New Car (Configured)",
  NewCarStock = "New car (Stock)",
  NewCarSpecialCase = "New Car (Special case)",
  DemoCarPreSold = "Demo Car (Pre-sold)",
  DemoCarStock = "Demo Car (Stock)",
  UsedCarStock = "Used Car (Stock)",
  UsedCarSpecialCasePrivateCustomer = "Used Car (Special case)",
  UsedCarSpecialCaseDealer = "Used Car (Special case)",
  UsedCarSpecialCaseDealerBiddingPlatform = "Used Car (Special case)",
  CompleteWinterTires = "Tires & Parts",
  PDSTequipment = "Tires & Parts"
}

enum  ApprovalStep {
  OrderCheck1="1st Order Check",
  Export="Export",
  Discount="Discount",
  ManagerDeal="Manager Deal",
  PEPMatch="PEP Match",
  Others="Others (optional)",
  OrderCheck2="Final Check",
  DispatchOrderProcessing="Dispatch Order Processing",
  Recalculation="Recalculation",
  CommissionCheck1="1st Commission check",
  CommissionCheck2=" 2nd Commission check",
  FixedCommission=" Fixed Commission",
  StartPayroll="Start payroll",
  CommissionAccounting="Commission Accounting",
}

enum  ActionType {
  Approved="Approved",
  Rejected="Rejected",
  Reassigned="Reassigned",
  Assigned="Assigned",
  Completed="Completed"
}

export enum tradeInPrintTypes {
  TradeIn_Regular = "Trade-In (regularly taxed)",
  TradeIn_Differential = "Trade-In (differential taxed)",
  Leasing_Return = "Leasing return",
  Leasing_Redemption = "Leasing redemption",
  Financing_Redemption_Regular = "Financing redemption (regularly taxed)",
  Financing_Redemption_Differential = "Financing redemption (differential taxed)",
}

export enum paymentPrintTypes {
  DebitCredit = "Debit / Credit",
  SUBSCRIPTION = "Subscription",
  Leasing_PFS = "Leasing (PFS)",
  Leasing_Other = "Leasing (Other)",
  Financing_PFS = "Financing (PFS)",
  Financing_Other = "Financing (Other)",
}