import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/system";
import {
  PButtonGroup,
  PGrid,
  PGridItem,
  PSelectWrapper,
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../../assets/styles/CustomStyles";
import { OrderContext } from "../../../../contexts/OrderContext";
import {
  OrderDTO,
  TradeInDetails,
  TradeInType,
} from "../../../../interfaces/Order";
import { CustomTextField } from "../../../common/CustomTextField";

interface Props {
  tradeIns: Array<TradeInType>;
  enableNextButton: (isValid: boolean) => void;
  tradeInToggle: boolean;
  referralToggle: boolean;
  disabled: boolean;
  tradeInDetails?: OrderDTO["tradeInDetails"];
  handleModified: () => void;
  updateError: (id: string) => void;
}

const tradeInTypeConfig = [
  {
    value: "Porsche",
    selected: false,
  },
  {
    value: "Other",
    selected: false,
  },
];

const tradeInSubTypeConfig = [
  {
    value: "PFS",
    selected: false,
  },
  {
    value: "Other",
    selected: false,
  },
];

export const TradeIn = (props: Props): JSX.Element => {
  const {
    tradeIns,
    enableNextButton,
    tradeInToggle,
    referralToggle,
    disabled,
    tradeInDetails,
    handleModified,
    updateError,
  } = props;
  const orderContext = useContext(OrderContext);
  const { t } = useTranslation();
  const [tradeInType, setTradeInType] = useState<string>("");
  const [tradeInTypes, setTradeInTypes] = useState(tradeInTypeConfig);
  const [tradeInSubTypes, setTradeInSubTypes] = useState(tradeInSubTypeConfig);
  const [reload, setReload] = useState("");
  const [showTradeInInfo, setTradeInInfo] = useState<boolean>(false);
  const [showLeasingInfo, setLeasingInfo] = useState<boolean>(false);
  const [showFinancialInfo, setFinancialInfo] = useState<boolean>(false);
  const [handleTradeInError, setTradeInError] = useState<boolean>(false);
  const [disabledClassName, setDisabledClassName] = useState("");
  const [showWarning, setWarning] = useState<boolean>(false);
  const [vin, setVin] = useState<string>(tradeInDetails?.vin || "");

  useEffect(() => {
    if (tradeInDetails) {
      setTradeInType(
        tradeInDetails && tradeInDetails.tradeInType
          ? tradeInDetails.tradeInType
          : ""
      );
      showTradeInDetails(
        tradeInDetails && tradeInDetails.tradeInType
          ? tradeInDetails.tradeInType
          : ""
      );
    }
    if (disabled) setDisabledClassName("disabled");
    else setDisabledClassName("");
  }, [tradeInDetails, disabled]);

  useEffect(() => {
    if (tradeInToggle) {
      orderContext.setErrors({
        field: "tradeInDetails",
        message: "",
        section: "tradeInDetails",
      });
      orderContext.setErrors({
        field: "tradeInType",
        message: "",
        section: "tradeInDetails",
      });
      orderContext.setErrors({
        field: "tradeInCategory",
        message: "",
        section: "tradeInDetails",
      });
      orderContext.setErrors({
        field: "vin",
        message: "",
        section: "tradeInDetails",
      });
    }
  }, []);

  useEffect(() => {
    let isValid = isValidTradeInDetailsSection();
    orderContext.updateFormValidation(0, isValid);
    enableNextButton(isValid);
  });

  const isValidTradeInDetailsSection = (): boolean => {
    const errors = orderContext.getErrors();
    return errors.every((error) => {
      if (error.section === "vehicleDetails") return false;
      if (error.section === "customerDetails") return false;
      if (error.section === "paymentDetails") return false;
      if (error.section === "deliveryDetails") return false;
      if (error.section === "tradeInDetails") return false;
      if (referralToggle && error.section === "referalDetails") return false;

      return true;
    });
  };

  useEffect(() => {
    if (tradeInTypes) {
      tradeInTypes.map((v) => {
        if (v.value === tradeInType) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      });

      setTradeInTypes(tradeInTypes);
    }
  }, [reload]);

  const updateTradeInDetails = (tradeInDetails: OrderDTO["tradeInDetails"]) => {
    let orderDto = getOrderDetails();
    orderDto["tradeInDetails"] = tradeInDetails;
    orderContext.updateCtxOrderDetails(orderDto);
  };

  const getOrderDetails = (): OrderDTO => {
    return orderContext.getCtxOrderDetails();
  };

  const getTradeInDetailsFromOrder = (
    orderDto: OrderDTO
  ): OrderDTO["tradeInDetails"] => {
    return orderDto["tradeInDetails"] || null;
  };

  const clearTradeInDetails = () => {
    let tradeInDetails = getTradeInDetailsFromOrder(getOrderDetails());
    // tradeInDetails.tradeInType = ""
    // tradeInDetails.vin = ""
    updateTradeInDetails(tradeInDetails);
    orderContext.removeError("tradeInDetails");
    orderContext.setErrors({
      field: "tradeInType",
      message: "",
      section: "tradeInDetails",
    });
    orderContext.setErrors({
      field: "tradeInCategory",
      message: "",
      section: "tradeInDetails",
    });
    orderContext.setErrors({
      field: "vin",
      message: "",
      section: "tradeInDetails",
    });
  };
  const handleTradeIn = (value: string) => {
    setTradeInType(value);
    let tradeInDetails = getTradeInDetailsFromOrder(getOrderDetails());
    if (tradeInDetails === null || tradeInDetails === undefined) {
      tradeInDetails = {} as TradeInDetails;
    }

    tradeInDetails.value = value;
    updateTradeInDetails(tradeInDetails);
    updateError("manufacturer");
    orderContext.removeError("tradeInType");
    if (tradeIns) {
      tradeInTypes.map((v) => {
        if (v.value === value) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      });

      setTradeInTypes(tradeInTypes);
      setReload(new Date().toLocaleString());
    }
  };

  const handleTradeInSubType = (value: string) => {
    setTradeInType(value);
    let tradeInDetails = getTradeInDetailsFromOrder(getOrderDetails());
    if (tradeInDetails === null || tradeInDetails === undefined) {
      tradeInDetails = {} as TradeInDetails;
    }
    tradeInDetails.value = value;
    handleModified();
    updateTradeInDetails(tradeInDetails);

    if (tradeIns) {
      tradeInSubTypes.map((v) => {
        if (v.value === value) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      });

      setTradeInSubTypes(tradeInSubTypes);
      setReload(new Date().toLocaleString());
    }
  };

  const handleTradeInCategory = (e: ChangeEvent<HTMLSelectElement>): void => {
    clearTradeInDetails();
    let tradeInDetails = getTradeInDetailsFromOrder(getOrderDetails());
    if (tradeInDetails === null || tradeInDetails === undefined) {
      tradeInDetails = {} as TradeInDetails;
    }
    if (e.target.value === "default") {
      tradeInDetails.tradeInType = null;
      tradeInDetails.value = null;
      tradeInDetails.vin = null;
      updateTradeInDetails(tradeInDetails);
      return;
    }
    const type = e.target.value;

    tradeInDetails.tradeInType = type;
    handleModified();
    updateTradeInDetails(tradeInDetails);
    updateError("tradeInCategory");
    orderContext.removeError("tradeInCategory");
    setTradeInInfo(false);
    setLeasingInfo(false);
    setFinancialInfo(false);
    showTradeInDetails(type);
    setTradeInError(false);
    setReload(new Date().toLocaleString());
  };

  const showTradeInDetails = (type: string) => {
    if (type === "TradeIn_Regular" || type === "TradeIn_Differential") {
      setTradeInInfo(true);
      setLeasingInfo(false);
      setFinancialInfo(false);
    }

    if (type === "Leasing_Return" || type === "Leasing_Redemption") {
      setTradeInInfo(false);
      setLeasingInfo(true);
      setFinancialInfo(false);
    }

    if (
      type === "Financing_Redemption_Regular" ||
      type === "Financing_Redemption_Differential"
    ) {
      setTradeInInfo(false);
      setLeasingInfo(false);
      setFinancialInfo(true);
    }
  };

  const handleVin = (value: string): void => {
    // Regular expression to match alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (!alphanumericRegex.test(value)) {
      value = value.replace(/[^a-zA-Z0-9]/g, "");
    }

    setVin(value);
    let tradeInDetails = getTradeInDetailsFromOrder(getOrderDetails());

    if (tradeInDetails === null || tradeInDetails === undefined) {
      tradeInDetails = {} as TradeInDetails;
    }

    tradeInDetails.vin = value;
    updateTradeInDetails(tradeInDetails);
    updateError("tradeInVin");
    orderContext.removeError("vin");

    handleModified();
    setReload(new Date().toLocaleString());
  };

  const testVin = (value: string): void => {
    const checkbox = document.querySelector(`input[name="tradeInVin"]`) as HTMLInputElement
    if(checkbox.value.length<=17){
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (!alphanumericRegex.test(value)) {
        value = value.replace(/[^a-zA-Z0-9]/g, "");
      }
      setVin(value);
      let tradeInDetails = getTradeInDetailsFromOrder(getOrderDetails());

      if (tradeInDetails === null || tradeInDetails === undefined) {
        tradeInDetails = {} as TradeInDetails;
      }

      tradeInDetails.vin = value;
      updateTradeInDetails(tradeInDetails);
      updateError("tradeInVin");
      orderContext.removeError("vin");

      handleModified();
      setReload(new Date().toLocaleString());
    }

  }

  const handleLengthAftrFocus = (value: string) => {
    const userLength = value.length;
    if (userLength < 17) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <PGrid>
          <PGridItem size={3}>
            <PSelectWrapper
              id={"tradeInCategoryWrap"}
              label={`${t("Trade-In Type")}`}
              state={!handleTradeInError ? "none" : "error"}
            >
              <select
                id={"tradeInCategory"}
                name="payment_id"
                required
                onChange={(e) => handleTradeInCategory(e)}
                disabled={disabled}
              >
                <option key="default" value="default">
                  {t("Select Trade-In Type")}
                </option>
                {tradeIns?.map((tradeIn, index) => {
                  return (
                    <option
                      key={index}
                      value={tradeIn.second}
                      selected={tradeIn.second === tradeInDetails?.tradeInType}
                    >
                      {t(tradeIn.first)}
                    </option>
                  );
                })}
              </select>
            </PSelectWrapper>
          </PGridItem>
          <PGridItem size={5} style={{ fontFamily: "Porsche Next" }}>
            {showTradeInInfo && (
              <>
                <span>
                  {t("Manufacturer")}
                  <span style={{ color: "#D5001B" }}>*</span>
                </span>
                <PButtonGroup style={{ paddingTop: "7px", marginTop: "-10px" }}>
                  {tradeInTypes.map((tradeInType, index) => {
                    if (tradeInType.value === tradeInDetails?.value)
                      tradeInType.selected = true;
                    return (
                      <Stack direction="row">
                        <div
                          className={
                            tradeInType.selected
                              ? `btn-group btn-group-selected ${
                                  disabled ? "disabled" : ""
                                }`
                              : `tradeInGroup  btn-group ${
                                  disabled ? "disabled" : ""
                                }`
                          }
                          onClick={() =>
                            disabled ? {} : handleTradeIn(tradeInType.value)
                          }
                        >
                          {t(tradeInType.value)}
                        </div>
                      </Stack>
                    );
                  })}
                </PButtonGroup>
                <div
                  id="manufacturer"
                  className={"series-model-error-hide"}
                  style={{ marginTop: "8px" }}
                >
                  <PorscheFont>
                    {t("Please select manufacturer to proceed further")}{" "}
                  </PorscheFont>
                </div>
              </>
            )}

            {showLeasingInfo && (
              <>
                <span>
                  {t("Leasing company")}
                  <span style={{ color: "#D5001B" }}>*</span>
                </span>
                <PButtonGroup style={{ paddingTop: "7px" }}>
                  {tradeInSubTypes.map((tradeInType, index) => {
                    if (tradeInType.value === tradeInDetails?.value)
                      tradeInType.selected = true;
                    return (
                      <div
                        className={
                          tradeInType.selected
                            ? `btn-group btn-group-selected  ${
                                disabled ? "disabled" : ""
                              }`
                            : `tradeInGroup btn-group  ${
                                disabled ? "disabled" : ""
                              }`
                        }
                        onClick={() => handleTradeInSubType(tradeInType.value)}
                      >
                        {t(tradeInType.value)}
                      </div>
                    );
                  })}
                </PButtonGroup>
              </>
            )}

            {showFinancialInfo && (
              <>
                <span>
                  {t("Financing company")}
                  <span style={{ color: "#D5001B" }}>*</span>
                </span>

                <PButtonGroup style={{ paddingTop: "7px" }}>
                  {tradeInSubTypes.map((tradeInType, index) => {
                    if (tradeInType.value === tradeInDetails?.value)
                      tradeInType.selected = true;
                    return (
                      <div
                        className={
                          tradeInType.selected
                            ? `btn-group btn-group-selected  ${
                                disabled ? "disabled" : ""
                              }`
                            : `tradeInGroup btn-group  ${
                                disabled ? "disabled" : ""
                              }`
                        }
                        onClick={() => handleTradeInSubType(tradeInType.value)}
                      >
                        {t(tradeInType.value)}
                      </div>
                    );
                  })}
                </PButtonGroup>
              </>
            )}
          </PGridItem>
          {(showFinancialInfo || showLeasingInfo || showTradeInInfo) && (
            <PGridItem size={4} style={{ marginTop: "-25px" }}>
              <div className="txt-tradein-vin">
                <CustomTextField
                  id="tradeInVin"
                  type="text"
                  // maxLength={17}
                  name="tradeInVin"
                  defaultValue={tradeInDetails?.vin || vin}
                  label={`${t("VIN")}`}
                  // onChange={(e) => handleVin(e.target.value)}
                    onChange={(e) => testVin(e.target.value)}
                  onBlur={() => handleLengthAftrFocus(vin)}
                  required={true}
                  disabled={disabled}
                  value={vin}
                />
                {showWarning && (
                  <span style={{ color: "#D5001B" }}>{t("VIN must be of 17 characters.")}</span>
                )}
              </div>
            </PGridItem>
          )}
        </PGrid>
      </div>
    </>
  );
};
