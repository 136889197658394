import { config } from 'process';
import { ApprovalConfigurationStep } from '../interfaces/adminApproval/ApprovalConfiguration';
import { UserDetailsDTO } from '../interfaces/User';
import { ManualCommissionUserObject } from '../interfaces/commission';

export const sortApprovalConfiguration = (data: Array<ApprovalConfigurationStep>): Array<ApprovalConfigurationStep> => {
    let temp: Array<ApprovalConfigurationStep> = [];
    let sorted: Array<ApprovalConfigurationStep> = [];
    data.map((item, i) => {
        if (item.id) temp[item.id] = item;
    })
    temp.map((item) => {
        sorted.push(item);
    })

    return sorted.length > 0 ? sorted : data;
}

export const isValidUserForDispatchOrderProcessing = (userDetails: UserDetailsDTO) => {
    // if (manualCommissionDispatchData) {
    //     let dispatchOrderUsers = manualCommissionDispatchData?.dispatchOrderUsers;
    //     if (dispatchOrderUsers) {
    //         let user = dispatchOrderUsers?.filter((user: any) => user?.id === userDetails?.id);
    //         return user?.length;
    //     }
    //     return false;
    // }
    // return false;

    if(userDetails?.enableManualCommission) return true;
    return false
}