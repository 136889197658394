import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PIcon } from '@porsche-design-system/components-react';

interface Props {
    handleLogout: () => void;
}

export default function MenuButton(props: Props) {
  const { t } = useTranslation();
  const { handleLogout } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if(!anchorEl){
      setAnchorEl(event.currentTarget);
    }else{
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    handleLogout();
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          "&:hover": { backgroundColor: "white" }, 
          marginTop: "-5px",
          transform: "none",
        }}
      >
        <PIcon className="menu-align" name="menu-dots-vertical" size="medium" aria={{ 'aria-label': 'Menu Dots Vertical icon' }} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade} 
        className='setting-dropbox'
      >
        <MenuItem onClick={logout}>
            <PIcon name="logout" aria={{ 'aria-label': 'Logout icon' }} />{t("Logout")}
        </MenuItem>
      </Menu>
    </div>
  );
}
