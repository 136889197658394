import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Stack } from '@mui/material';
import {
    PButton, PButtonGroup, PTextareaWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';
import { multiCommissionValueType } from './Subsequent';
import { CalculationDraftDTO } from '../../../interfaces/Calculation';
import { formatCurrencyWithNoUnit } from '../../../mock/helper';

interface Props {
    orderId: number,
    handleClose: () => void;
    handleapprovalConfirmation: () => void;
    multiCommission: boolean;
    singleCommissionValue: number;
    multiCommissionValue?: multiCommissionValueType[]
    sharingVehicleCommission?: number | undefined
    persistSingleCommissionValue?: number
    setSubsequentSubmitMessage: React.Dispatch<React.SetStateAction<string>>
}

export const SubseqeuentSubmitPopup = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleClose, orderId, handleapprovalConfirmation, multiCommission, singleCommissionValue, multiCommissionValue, sharingVehicleCommission, persistSingleCommissionValue, setSubsequentSubmitMessage } = props;
    const [subsqMessage, setSubsqMessage] = useState("")


    const orderContext = useContext(OrderContext);

    const updatedValues: CalculationDraftDTO = orderContext.getCtxReCalculation()?.reCalculation



    useEffect(() => {
        //    updatedValues && updatedValues?.calculationHeader && setCalculationheader(updatedValues?.calculationHeader)
    }, [])

    let loggedInUserId = 1;
    const sessionUser = sessionStorage.getItem("userDetails");
    if (sessionUser) {
        const user = JSON.parse(sessionUser);
        loggedInUserId = user.id;
    }

    const handleCancel = () => {
        handleClose();
    }
    const handleDiscard = () => {
        let order = orderContext.getCtxOrderDetails();
        if (order.id) {
            let urlParams = `?id=${order.id}&mode=view&draft=false&tab=calculation`
            navigate(`/order/create/${urlParams}`);
        }
    }

    const handleSubmit = () => {
        handleapprovalConfirmation()
        setSubsequentSubmitMessage(subsqMessage)
    }


    return (
        <>
            <div className={`${multiCommission ? 'subs-submit-multiCommission' : 'subs-submit-single'}`}>
                <PorscheFont style={{ fontWeight: "700", fontSize: "16px", lineHeight: "23.42px" }}>{t("Submit subsequent change?")}</PorscheFont><br></br>
                <PorscheFont style={{ fontWeight: "400", fontSize: "16px", lineHeight: "23.42px", marginTop: "-16px" }}>{t("The subsequent change must be approved again. The deviation in commission will be accounted after approval.")}</PorscheFont><br></br>
                <Stack direction="row" gap={'32px'} >
                    <Stack direction={"column"} justifyContent={'space-around'} height={'100px'}>
                        {multiCommission && <><PorscheFont className='subs-text subs-light'>{t("")}</PorscheFont><br /><br /></>}
                        <PorscheFont className='subs-text subs-light'>{t("Previous total commission")}</PorscheFont><br></br>
                        <PorscheFont className='subs-text subs-light'>{t("New total commission")}</PorscheFont><br></br>
                        <PorscheFont className='subs-text subs-light'>{t("Deviation")}</PorscheFont><br></br>
                    </Stack>
                    {multiCommission &&

                        <Stack direction={"row"} gap={4}>
                            {multiCommissionValue && multiCommissionValue.map((item, index) => {
                                return (
                                    <Stack direction={'column'} justifyContent={'space-around'} height={'100px'}>
                                        <PorscheFont className='subs-text subs-light'>{item.sellerNames.ppnName}</PorscheFont><br></br>
                                        <PorscheFont className='subs-text subs-text-value'>{`${sharingVehicleCommission && formatCurrencyWithNoUnit(sharingVehicleCommission * item.shareInPercent / 100)} €`}</PorscheFont><br></br>
                                        <PorscheFont className='subs-text subs-text-value'>{updatedValues?.commissionSharing && `${formatCurrencyWithNoUnit(updatedValues?.commissionSharing[index]?.shareInEuro)} €`}</PorscheFont><br></br>
                                        {updatedValues?.commissionSharing && sharingVehicleCommission && <PorscheFont className='subs-text subs-text-value' style={{ color: `${updatedValues?.commissionSharing[index]?.shareInEuro - (sharingVehicleCommission * item.shareInPercent / 100) === 0 ? 'black' : updatedValues?.commissionSharing[index]?.shareInEuro - (sharingVehicleCommission * item.shareInPercent / 100) < 0 ? "#A90000" : "#00950F"}` }}>{sharingVehicleCommission && `${formatCurrencyWithNoUnit(updatedValues?.commissionSharing[index]?.shareInEuro - (sharingVehicleCommission * item.shareInPercent / 100))} €`}</PorscheFont>}<br />
                                    </Stack>
                                )
                            })}


                        </Stack>}

                    {!multiCommission && (
                        <Stack direction="column" justifyContent={'space-around'} height={'100px'}>
                            <PorscheFont className="subs-text subs-text-value">{`${persistSingleCommissionValue && formatCurrencyWithNoUnit(persistSingleCommissionValue)} €`}</PorscheFont>
                            <br />
                            {updatedValues.commissionCalculation?.totalCommission ? (
                                <>
                                    <PorscheFont className="subs-text subs-text-value">{`${formatCurrencyWithNoUnit(updatedValues.commissionCalculation?.totalCommission)} €`}</PorscheFont>
                                    <br />
                                    <PorscheFont
                                        className="subs-text subs-text-value"
                                        style={{
                                            color: `${persistSingleCommissionValue && updatedValues.commissionCalculation?.totalCommission - persistSingleCommissionValue === 0
                                                ? "black"
                                                : persistSingleCommissionValue && updatedValues.commissionCalculation?.totalCommission - persistSingleCommissionValue < 0
                                                    ? "#A90000"
                                                    : "#00950F"
                                                }`,
                                        }}
                                    >
                                        {`${persistSingleCommissionValue && formatCurrencyWithNoUnit(updatedValues.commissionCalculation?.totalCommission - persistSingleCommissionValue)} €`}
                                    </PorscheFont>
                                    <br />
                                </>
                            ) : <>
                                <PorscheFont className="subs-text subs-text-value">0,00 €</PorscheFont>
                                <br />
                                <PorscheFont className="subs-text subs-text-value">0,00 €</PorscheFont></>}

                        </Stack>
                    )}
                </Stack>
                <Stack direction={"column"} justifyContent={'space-between'} sx={{ height: "225px" }}>
                    <PTextareaWrapper label={`${t("Reason for subsequent change")}`} className='subs-textarea' >
                        <textarea name="some-name"
                            className='subs-textarea-field'
                            maxLength={500}
                            required onChange={(e) => setSubsqMessage(e.target.value)}> </textarea>
                    </PTextareaWrapper>
                    <PButtonGroup style={{ marginTop: "12px" }}>
                        <Stack direction="row" spacing={1} justifyContent={`${multiCommission ? 'end' : 'center'}`} sx={{ width: '100%' }}>
                            <PButton style={{ fontWeight: "400", fontSize: "16px", lineHeight: "23.42px" }} variant="tertiary" icon='close' onClick={handleCancel}>{t("Cancel")}</PButton>
                            <PButton disabled={subsqMessage.trim().length < 1} style={{ fontWeight: "400", fontSize: "16px", lineHeight: "23.42px" }} variant="secondary"
                                onClick={handleSubmit}

                            >{t("Understood, open approvals popup")}</PButton>
                        </Stack>
                    </PButtonGroup>
                </Stack>
            </div>
        </>
    );
}
