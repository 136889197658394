import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";
import {
  PButton,
  PDivider,
  PFlex,
  PFlexItem,
  PIcon,
  PSelectWrapper,
  PTextareaWrapper
} from "@porsche-design-system/components-react";
import { ReactComponent as InfoIcon } from "../../assets/images/info-icon.svg";
import { PorscheFont } from "../../assets/styles/CustomStyles";
import { UserDetailsDTO } from "../../interfaces/User";
import { UserDetails } from "../../interfaces/UserDetails";
import { type } from "../../services/Constants";
import { dcspostCreateManualCommission } from "../../services/data/Helpers";

interface Props {
  edit: boolean;
  activeOriginUsers: Array<UserDetails>;
  setFormData: Function;
  formData: any;
  handleModified: Function;
  isCommissionDeleted: boolean;
  isSavedApprovalData: Array<any>;
  commissionCheck1Users: Array<any>,
  commissionCheck2Users: Array<any>,
}

export const ManualCommissionApproval = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    edit,
    activeOriginUsers,
    setFormData,
    formData,
    handleModified,
    isCommissionDeleted,
    isSavedApprovalData,
    commissionCheck1Users,
    commissionCheck2Users
  } = props;
  const { commissionApproval } = formData;
  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  const [toggleApproval, setToggleApproval] = useState<null | number>(null);
  const [toggleReassign, setToggleReassign] = useState<null | number>(null);
  const [selectReAssignUser, setSelectReAssignUser] = useState(0);
  const [toggleReject, setToggleReject] = useState<null | number>(null);
  const [isValidReassign, setIsValidReassign] = useState(false);
  const [isValidRejected, setIsValidRejected] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState<null | number>(
    null
  );
  if (sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }
  const [stepApproval, setStepApproval] = useState(["1st commission check"]);
  const [enableNextStep, setEnableNextStep] = useState(false);
  useEffect(() => {
    setStepApproval((prev) =>
      prev.includes("2nd commission check")
        ? prev
        : [...prev, "2nd commission check"]
    );

    setEnableNextStep(
      commissionApproval[0]?.id && commissionApproval[0]?.action === "Approved"
    );
  }, [commissionApproval]);

  const getIcon = (step: string) => {
    let ret = <></>;
    switch (step) {
      case "1st commission check":
        ret = (
          <img
            src={require("../../assets/images/approvals/1stordercheck.png")}
            alt=""
          />
        );
        break;
      case "2nd commission check":
        ret = (
          <img
            src={require("../../assets/images/approvals/2ndordercheck.png")}
            alt=""
          />
        );
        break;
    }
    return ret;
  };

  const handleApprover = (id: string, index: number) => {
    handleModified();
    setFormData((prev: any) => {
      let findSelectedUser = (index === 0 ? commissionCheck1Users : commissionCheck2Users).find(
        (user) => user.id === parseInt(id)
      );
      const updatedCommissionApproval = [...prev.commissionApproval];

      if (updatedCommissionApproval.length > 0) {
        if (!id) {
          updatedCommissionApproval[index].id = null;
          updatedCommissionApproval[index].action = "Pending";
          updatedCommissionApproval[index].ppnName = "";
        } else {
          updatedCommissionApproval[index].id = parseInt(id);
          updatedCommissionApproval[index].action = "Assigned";
          updatedCommissionApproval[index].ppnName = findSelectedUser?.ppnName;
        }
      }

      return {
        ...prev,
        commissionApproval: updatedCommissionApproval,
      };
    });
  };

  const handleChangeComment = (value: string, index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      commissionApproval: prev.commissionApproval.map((item: any, i: number) =>
        i === index ? { ...item, comment: value } : item
      ),
    }));
  };

  const handleSaveApproval = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      commissionApproval: prev.commissionApproval.map((item: any, i: number) =>
        i === index ? { ...item, action: "Approved" } : item
      ),
    }));
  };

  const handleCancelApproval = () => {
    setToggleApproval(null);
  };

  const handleApproval = (index: number) => {
    setToggleReject(null);
    setToggleReassign(null);
    setToggleApproval((prev) => (prev ? null : index));
    setFormData((prev: any) => ({
      ...prev,
      commissionApproval: prev.commissionApproval.map((item: any, i: number) =>
        i === index - 1 ? { ...item, comment: "" } : item
      ),
    }));
  };

  const handleReassign = (index: number) => {
    setToggleReject(null);
    setToggleApproval(null);
    setToggleReassign((prev) => (prev ? null : index));
    setFormData((prev: any) => ({
      ...prev,
      commissionApproval: prev.commissionApproval.map((item: any, i: number) =>
        i === index - 1 ? { ...item, comment: "" } : item
      ),
    }));
  };
  const handleReject = (index: number) => {
    setToggleApproval(null);
    setToggleReassign(null);
    setToggleReject((prev) => (prev ? null : index));
    setFormData((prev: any) => ({
      ...prev,
      commissionApproval: prev.commissionApproval.map((item: any, i: number) =>
        i === index - 1 ? { ...item, comment: "" } : item
      ),
    }));
  };

  const handleSubmitApprovalCommission = async (data: any) => {
    try {
      const apiResp = await dcspostCreateManualCommission(data);
      if (apiResp.status===type.SUCCESS ) {
        window.location.reload();
      }
    } catch (error) {
    }
  };

  const handleSaveReassign = async (index: number) => {
    try {
      let optionalFormData = JSON.parse(JSON.stringify(formData));

      let reAssignObject = {
        ...optionalFormData,
        commissionApproval: optionalFormData.commissionApproval.map(
          (item: any, i: number) =>
            i === index
              ? {
                ...item,
                action: "ReAssigned",
                id: selectReAssignUser,
                ppnName: activeOriginUsers.find(
                  (user) => user.id === selectReAssignUser
                )?.ppnName,
              }
              : item
        ),
      };

      await handleSubmitApprovalCommission(reAssignObject);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelReassign = () => {
    setToggleReassign(null);
  };

  const handleSaveReject = async (index: number) => {
    let optionalFormData = JSON.parse(JSON.stringify(formData));

    let rejectedObject = {
      ...optionalFormData,
      commissionApproval: optionalFormData.commissionApproval.map(
        (item: any, i: number) =>
          i === index ? { ...item, action: "Rejected" } : item
      ),
    };

    await handleSubmitApprovalCommission(rejectedObject);
  };
  const handleCancelReject = () => {
    setToggleReject(null);
  };

  const isShowApprovedValueFlag = (index: number): boolean => {
    if (isCommissionDeleted) {
      return true;
    }

    if (
      (isSavedApprovalData[0]?.id &&
        isSavedApprovalData[0]?.action === "Approved" &&
        isSavedApprovalData[1]?.id &&
        isSavedApprovalData[1]?.action === "Approved") ||
      (isSavedApprovalData[0]?.id &&
        isSavedApprovalData[1]?.id &&
        isSavedApprovalData[1]?.action === "Assigned")
    ) {
      return true;
    } else if (
      isSavedApprovalData[index]?.id &&
      isSavedApprovalData[index]?.action === "Rejected"
    ) {
      return true;
    } else if (
      index === 1 &&
      commissionApproval[1]?.action !== "Approved" &&
      commissionApproval[0]?.action === "Approved"
    ) {
      return false;
    } else if (index === 1 &&
      isSavedApprovalData[0]?.action === "Approved" &&
      isSavedApprovalData[1]?.action === "ReAssigned"
      ) { 
        return false;
    }
    else {
      if (edit && index === 0) {
        return false;
      } else if (edit && index === 1) {
        return true;
      } else {
        return true;
      }
    }
  };

  const isShowSelectValueFlag = (index: number): boolean => {
    if (isCommissionDeleted) {
      return false;
    }
    if (index === 0) {
      if (
        commissionApproval[index]?.id &&
        commissionApproval[index]?.action !== "Approved"
      ) {
        return edit;
      }
      return edit;
    }
    if (index === 1) {
      if (
        commissionApproval[0]?.action === "Approved" &&
        !isSavedApprovalData[1]?.id
      ) {
        return true;
      } else if (index === 1 &&
        isSavedApprovalData[0]?.action === "Approved" &&
        isSavedApprovalData[1]?.action === "ReAssigned"
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isShowActionButton = (index: number): boolean => {
    if (isCommissionDeleted) {
      return false;
    }
    if (
      (commissionApproval[index]?.id &&
        commissionApproval[index]?.action === "Approved") ||
      edit
    ) {
      return false;
    } else {
      if (
        index === 0 &&
        commissionApproval[index]?.id === userDetails.id &&
        commissionApproval[index]?.action !== "Approved"
      ) {
        return true;
      } else if (
        isSavedApprovalData[index]?.id &&
        isSavedApprovalData[index]?.action === "Rejected"
      ) {
        return false;
      } else if (
        index === 1 &&
        isSavedApprovalData[index]?.id &&
        commissionApproval[index]?.id === userDetails.id &&
        commissionApproval[index]?.action !== "Approved"
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <>
      {stepApproval.map((step, index) => {
        // ["Assigned","Approved","Rejected","ReAssigned"].includes(commissionApproval[index]?.action)
        return (
          <>
            {(index === 0 || index === 1 && commissionCheck2Users?.length) ? <>
              <PFlex className="commission-approv-container" key={index}>
                <PFlexItem className="label-image-section steps-col">
                  <PFlex className="item-label-container">
                    <PFlexItem>
                      <PDivider
                        orientation="vertical"
                        style={{
                          marginTop: "2px",
                          width: "4px",
                          height: "95%",
                          backgroundColor: "#C8CACB",
                        }}
                      />
                    </PFlexItem>
                    &nbsp;&nbsp;
                    <PFlexItem className="commission-image-container">
                      {getIcon(step)}
                    </PFlexItem>
                    &nbsp;&nbsp;
                    <PFlexItem className="step-label-item">
                      <PorscheFont className="step-label-text">
                        {t(step)}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </PFlexItem>
                <PFlexItem
                  style={{
                    padding: "10px 16px",
                  }}
                  className="assign-col"
                >
                  <PFlexItem>
                    {isShowApprovedValueFlag(index) ? (
                      <div className="user-assigned" style={{ width: "100%" }}>
                        <Stack direction="row">
                          {!isCommissionDeleted &&
                            commissionApproval[index]?.action ===
                            "ReAssigned" && (
                              <PIcon
                                name="switch"
                                className="re-assign-icon"
                                color="inherit"
                              />
                            )}
                          <PorscheFont className="approved-user-name">
                            {commissionApproval[index]?.ppnName &&
                              commissionApproval[index]?.action !== "Pending"
                              ? isCommissionDeleted
                                ? ""
                                : commissionApproval[index]?.ppnName
                              : ""}
                          </PorscheFont>
                        </Stack>
                      </div>
                    ) : null}

                    {isShowSelectValueFlag(index) ? (
                      <PSelectWrapper hideLabel={false} style={{ width: "100%" }}>
                        <select
                          name={`s${step}`}
                          value={
                            commissionApproval[index]?.id &&
                              commissionApproval[index]?.action !== "Pending"
                              ? commissionApproval[index]?.id
                              : 0
                          }
                          onChange={(e) => handleApprover(e.target.value, index)}
                        >
                          <option
                            value={""}
                            selected={
                              commissionApproval[index]?.id
                                ? commissionApproval[index]?.action === "Assigned"
                                  ? true
                                  : false
                                : true
                            }
                          >
                            {t("Assign to user")}
                          </option>
                          {(index === 0 ? commissionCheck1Users : commissionCheck2Users)?.map((user, index, array) => {
                            return (
                              <option
                                key={index}
                                value={user.id}
                                selected={
                                  commissionApproval[index]?.action !==
                                    "Assigned" &&
                                    commissionApproval[index]?.id &&
                                    commissionApproval[index]?.id === user.id
                                    ? true
                                    : false
                                }
                              >
                                {user.ppnName}
                              </option>
                            );
                          })}
                        </select>
                      </PSelectWrapper>
                    ) : null}
                  </PFlexItem>
                </PFlexItem>
                <PFlexItem
                  style={{
                    padding: "10px 0px",
                    display: "flex",
                  }}
                >
                  <PFlex justifyContent={"flex-end"}>
                    <PFlexItem alignSelf={"center"}>
                      {isShowActionButton(index) ? (
                        <>
                          {/* {!toggleOnApproved && ( */}
                          <PFlex>
                            <PFlexItem>
                              <div
                                className="draft-button"
                                style={
                                  toggleApproval && toggleApproval === index + 1
                                    ? { background: "#1E7A53" }
                                    : { background: "#FFFFFF" }
                                }
                              >
                                <PIcon
                                  name="success"
                                  color={`${toggleApproval && toggleApproval === index + 1
                                    ? "neutral-contrast-low"
                                    : "notification-success"
                                    }`}
                                  aria={{ "aria-label": "Success icon" }}
                                  onClick={() => handleApproval(index + 1)}
                                />
                                {toggleApproval &&
                                  toggleApproval === index + 1 ? (
                                  <div className="approved-container commission-approved-container" style={{position:"absolute"}}>
                                    <PorscheFont className="popup-heading">
                                      {t("Approve")}
                                    </PorscheFont>
                                    <div className="popup-container">
                                      <PTextareaWrapper
                                        label={`${t("Comment (Optional)")}`}
                                      >
                                        <textarea
                                          aria-autocomplete="none"
                                          autoComplete="off"
                                          onChange={(e) =>
                                            handleChangeComment(
                                              e.target.value,
                                              index
                                            )
                                          }
                                          maxLength={200}
                                          name="approvedComment"
                                        />
                                      </PTextareaWrapper>
                                    </div>
                                    <div className="action-btn-popup">
                                      <PButton
                                        icon="success"
                                        onClick={() => handleSaveApproval(index)}
                                        className="approve-btn"
                                      >
                                        {t("Approve")}
                                      </PButton>
                                      <PButton
                                        onClick={handleCancelApproval}
                                        type="button"
                                        variant="tertiary"
                                        icon="close"
                                      >
                                        {t("Cancel")}
                                      </PButton>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </PFlexItem>
                            &nbsp;
                            <PFlexItem>
                              <div
                                className="draft-button"
                                style={
                                  toggleReassign && toggleReassign === index + 1
                                    ? { background: "#FF9B00" }
                                    : { background: "#FFFFFF" }
                                }
                              >
                                <PIcon
                                  name="switch"
                                  color={`${toggleReassign && toggleReassign === index + 1
                                    ? "neutral-contrast-low"
                                    : "notification-warning"
                                    }`}
                                  aria={{ "aria-label": "Switch icon" }}
                                  onClick={() => handleReassign(index + 1)}
                                />
                                {toggleReassign &&
                                  toggleReassign === index + 1 && (
                                    <div className="actions-container commission-approved-container rejected-container" style={{position:'absolute', top:'-440px'}}>
                                      <PorscheFont className="popup-heading">
                                        {t("Reassign")}
                                      </PorscheFont>
                                      <PSelectWrapper
                                        className="select-reject-user"
                                        label={`${t("Assign to")}`}
                                      >
                                        <select
                                          name={`s${step}`}
                                          onChange={(e) =>
                                            setSelectReAssignUser(
                                              parseInt(e.target.value)
                                            )
                                          }
                                          value={selectReAssignUser}
                                          required
                                        >
                                          <option value={0}>
                                            {t("Assign to user")}
                                          </option>
                                          {activeOriginUsers.map(
                                            (user, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={user.id}
                                                  selected={
                                                    user.id ===
                                                      commissionApproval[index]?.id
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  {user.ppnName}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </PSelectWrapper>
                                      <div className="popup-container">
                                        <PTextareaWrapper
                                          label={`${t("Comment")}`}
                                        >
                                          <textarea
                                            name="reassignComment"
                                            required
                                            onChange={(e) =>
                                              handleChangeComment(
                                                e.target.value,
                                                index
                                              )
                                            }
                                            maxLength={200}
                                          ></textarea>
                                        </PTextareaWrapper>
                                      </div>
                                      <div className="action-btn-popup">
                                        <PButton
                                          icon="switch"
                                          disabled={
                                            !(
                                              commissionApproval[index]?.comment
                                                ?.length && selectReAssignUser
                                            )
                                          }
                                          onClick={() =>
                                            handleSaveReassign(index)
                                          }
                                          className="approve-btn"
                                        >
                                          {t("Reassign")}
                                        </PButton>
                                        &nbsp;&nbsp;
                                        <PButton
                                          onClick={handleCancelReassign}
                                          type="button"
                                          variant="tertiary"
                                          icon="close"
                                        >
                                          {t("Cancel")}
                                        </PButton>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </PFlexItem>
                            &nbsp;
                            <PFlexItem>
                              <div
                                className="draft-button"
                                style={
                                  toggleReject && toggleReject === index + 1
                                    ? { background: "#D5001B" }
                                    : { background: "#FFFFFF" }
                                }
                              >
                                <PIcon
                                  name="disable"
                                  color={`${toggleReject && toggleReject === index + 1
                                    ? "neutral-contrast-low"
                                    : "brand"
                                    }`}
                                  aria={{ "aria-label": "Disable icon" }}
                                  onClick={() => handleReject(index + 1)}
                                />
                                {toggleReject && toggleReject === index + 1 && (
                                  <div className="actions-container commission-approved-container" style={{position:'absolute', top:'-354px'}}>
                                    <PorscheFont className="popup-heading">
                                      {t("Reject")}
                                    </PorscheFont>
                                    <div className="popup-container">
                                      <PTextareaWrapper label={`${t("Comment")}`}>
                                        <textarea
                                          name="rejectComment"
                                          required
                                          onChange={(e) =>
                                            handleChangeComment(
                                              e.target.value,
                                              index
                                            )
                                          }
                                          maxLength={200}
                                        ></textarea>
                                      </PTextareaWrapper>
                                    </div>
                                    <div className="action-btn-popup">
                                      <PButton
                                        icon="disable"
                                        disabled={
                                          !commissionApproval[index]?.comment
                                            ?.length
                                        }
                                        onClick={() => handleSaveReject(index)}
                                        className="approve-btn"
                                      >
                                        {t("Reject")}
                                      </PButton>
                                      <PButton
                                        onClick={handleCancelReject}
                                        type="button"
                                        variant="tertiary"
                                        icon="close"
                                      >
                                        {t("Cancel")}
                                      </PButton>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </PFlexItem>
                            &nbsp;
                          </PFlex>
                          {/* )} */}
                        </>
                      ) : null}
                    </PFlexItem>
                  </PFlex>
                </PFlexItem>
                {commissionApproval[index]?.id &&
                  commissionApproval[index]?.action === "Approved" ? (
                  <PFlexItem className="approval-action-label">
                    <div className="approve-rejeccted-label">
                      <div
                        className="icon-container"
                        onMouseOver={() => setIsPopoverVisible(index)}
                        onMouseLeave={() => setIsPopoverVisible(null)}
                      >
                        <i className="info-icon">
                          <InfoIcon />
                        </i>
                        {isPopoverVisible === index ? (
                          <PorscheFont className="approval-popover">
                            {commissionApproval[index]?.comment?.length ? commissionApproval[index]?.comment : isSavedApprovalData[index]?.comment}
                          </PorscheFont>
                        ) : null}
                      </div>

                      <PorscheFont className="approved-text">
                        {t("Approved")}
                      </PorscheFont>
                    </div>
                  </PFlexItem>
                ) : null}
                {commissionApproval[index]?.id &&
                  commissionApproval[index]?.action === "ReAssigned" && userDetails?.id !== isSavedApprovalData[index]?.id ? (
                  <PFlexItem className="approval-action-label">
                    <div className="approve-rejeccted-label">
                      <div
                        className="icon-container"
                        onMouseOver={() => setIsPopoverVisible(index)}
                        onMouseLeave={() => setIsPopoverVisible(null)}
                      >
                        <i className="info-icon">
                          <InfoIcon />
                        </i>
                        {isPopoverVisible === index ? (
                          <PorscheFont className="approval-popover">
                            {isSavedApprovalData[index]?.comment}
                          </PorscheFont>
                        ) : null}
                      </div>
                    </div>
                  </PFlexItem>
                ) : null}
                {commissionApproval[index]?.id &&
                  commissionApproval[index]?.action === "Rejected" ? (
                  <PFlexItem className="approval-action-label">
                    <div className="approve-rejeccted-label">
                      <div
                        className="icon-container"
                        onMouseOver={() => setIsPopoverVisible(index)}
                        onMouseLeave={() => setIsPopoverVisible(null)}
                      >
                        <i className="info-icon">
                          <InfoIcon />
                        </i>
                        {isPopoverVisible === index ? (
                          <PorscheFont className="approval-popover">
                            {isSavedApprovalData[index]?.comment}
                          </PorscheFont>
                        ) : null}
                      </div>
                      <PorscheFont className="reject approve-reject">
                        {t(
                          "Rejected - Please cancel this commission and, if needed, create a new one."
                        )}
                      </PorscheFont>
                    </div>
                  </PFlexItem>
                ) : null}
              </PFlex>
              <PDivider color="neutral-contrast-low"></PDivider> </> : null}
          </>
        );
      })}
    </>
  );
};
