import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Stack } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {
  PButton, PButtonGroup,
  PDivider, PFlex, PFlexItem, PGrid,
  PGridItem, PIcon,
  PSelectWrapper,
  PTextFieldWrapper,
  PTextareaWrapper,
  PModal,
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../assets/styles/CustomStyles';
import { TaskRequestDTO, TaskResponseDTO } from '../../interfaces/TasksAndNotifications';
import { UserDetailsDTO } from '../../interfaces/User';
import {
  dcsdeleteTasks,
  dcsgetTaskUsers,
  dcsputTasksData,
  deleteTasks,
  getTaskUsers,
  putTasksData
} from '../../services/data/Helpers';
import { type } from '../../services/Constants';
import CustomSnakebar from '../common/CustomSnakebar';
import { Constant } from '../../configs/Constants';
import { SingleOrderDetailType } from '../../interfaces/OrderDetails';
import { t } from 'i18next';
//import { Notifications } from './Notifications';

//import { TaskRow } from './TaskRow';

interface Props {
  task: TaskResponseDTO;
  closeDetails: () => void;
  handleDelete: () => void;
}

export const TaskPopup = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { task, closeDetails, handleDelete } = props;
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState("");
  const [users, setUsers] = useState<Array<UserDetailsDTO>>([]);
  const [taskRequest, setTaskRequest] = useState<TaskRequestDTO>({} as TaskRequestDTO);
  const [showMarkAsComplete, setShowMarkAsComplete] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [reassignError, setReassignError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
  const [reassignMessage, setReassignMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }

  useEffect(() => {
    taskRequest.createdBy = { id: task.createdBy.id ? task.createdBy.id : 0 };
    taskRequest.subject = task.subject;
    taskRequest.assignedTo = { id: task.assignedTo.id ? task.assignedTo.id : 0 };
    taskRequest.status = task.status;
    taskRequest.taskType = task.taskType;
    taskRequest.id = task.id;
    taskRequest.description = task.description;
    taskRequest.reassigned = task.reassigned;
    taskRequest.reassignedReason = task.reassignedReason;
    taskRequest.statusComment = task.statusComment;


    if (task.order?.isDraft && task.order?.id) {
      taskRequest.draft = {
        id: task.order.id
      }
    } else if (task.order?.id) {
      taskRequest.order = {
        id: task.order.id
      }
    }

    setTaskRequest(taskRequest);
  }, [])

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetTaskUsers()
      if(apiResponse.status=== type.SUCCESS){
        setUsers(apiResponse.response);


      }
    };
    init();
  }, []);

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
    sessionStorage.setItem("messageFlag", ("false" || false));
  };

  const handleCancel = () => {
    //closeDetails();
    setEdit(false);
  }

  const handleMarkAsComplete = () => {
    setShowReassign(false);
    setShowMarkAsComplete(!showMarkAsComplete);
  }

  const handleStatusComment = (value: string) => {
    taskRequest.statusComment = value;
    setTaskRequest(taskRequest);
  }

  const handleCompleteComment = () => {
    try {
      const init = async () => {
        taskRequest.status = "Completed";
        setShowMarkAsComplete(true);
        const apiResponse = await dcsputTasksData(taskRequest)
        if (apiResponse.status === type.SUCCESS) {
          setShowAlert(Constant.Success)    
          setAlertMessage(t('Task completed') || 'Task completed');
          // setReload(new Date().toLocaleString());
          window.location.reload(); 
          // closeDetails();
        }

        if(apiResponse.status===type.ERROR){
          setShowAlert(Constant.Error)
          setAlertMessage(apiResponse.response)        
        }
      };
      init();
    } catch (e) {
    }
  }

  const handleCancelComplete = () => {
    setShowMarkAsComplete(false);
  }

  const handleShowReassign = () => {
    setShowMarkAsComplete(false);
    setShowReassign(!showReassign);
  }

  const handleCancelReassign = () => {
    setReassignError(false);
    setShowReassign(false);
  }

  const handleReassign = () => {
    if (taskRequest.reassignedReason === undefined || taskRequest.reassignedReason === null) {
      setReassignError(true);
      closeDetails();
      return;
    }

    try {
      const init = async () => {
        taskRequest.reassigned = true;
        taskRequest.reassignedBy = {
          id: loggedInUserId
        }
        const apiResponse = await dcsputTasksData(taskRequest)
        if(apiResponse.status===type.SUCCESS){
          setShowAlert(Constant.Success)    
          setAlertMessage(Constant.Savedsuccessfully)
          window.location.reload();
        }
        if(apiResponse.status===type.ERROR){
          setShowAlert(Constant.Error)
          setAlertMessage(apiResponse.response)        
        }
      };
      init();
    } catch (e) {
    }
  }

  const handleAssignee = (value: string) => {
    if (task.assignedTo.id && task.assignedTo.id !== parseInt(value)) {
      taskRequest.reassigned = true;
      taskRequest.reassignedBy = { id: loggedInUserId };
      taskRequest.reassignedReason = "Reassigned";
    }

    taskRequest.assignedTo = { id: parseInt(value) };
    setTaskRequest(taskRequest);
  };

  const handleReassignComment = (value: string) => {
    taskRequest.reassignedReason = value;
    setTaskRequest(taskRequest);
  }

  const handleSubject = (value: string) => {
    taskRequest.subject = value;
    setTaskRequest(taskRequest);
    //isValidTaskRequest();
    //setReload(new Date().toLocaleString());
  };

  const handleDescription = (value: string) => {
    taskRequest.description = value;
    setTaskRequest(taskRequest);
    //setReload(new Date().toLocaleString());
  };

  const isValidTaskRequest = () => {
    if (taskRequest.subject === undefined || taskRequest.subject.length === 0) {
      setValidationError(true);
      return false;
    }
    if (taskRequest.assignedTo === undefined || taskRequest.assignedTo.id === undefined) {
      setValidationError(true);
      return false;
    }
    return true;
  }

  const handleSave = () => {
    if (isValidTaskRequest()) {
      try {
        const init = async () => {
          const apiResponse = await dcsputTasksData(taskRequest)
          if(apiResponse.status===type.SUCCESS){
            setShowAlert(Constant.Success)    
            setAlertMessage(Constant.Savedsuccessfully)
            window.location.reload();
          }
          if(apiResponse.status===type.ERROR){
            setShowAlert(Constant.Error)
            setAlertMessage(apiResponse.response)        
          }
        };
        init();
      } catch (e) {
        setIsError(true);
      }
    } else {
      setValidationError(true);
    }
  }

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setReassignMessage(false);
    setSaveSuccess(false);
  
  };
  const handlePage = (orderId: number, isDraft: boolean) => {
    if (orderId) {
      let singOrderDetails:SingleOrderDetailType = { id: orderId, mode:'view', draft: isDraft };
      const newWindow=  window.open(`/#/order/create`, "_blank") 
      newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
      
    }
  };

  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const handleYes = () => {
    try {
      const init = async () => {
        const apiResponse = await dcsdeleteTasks(task.id)
        if(apiResponse.status===type.SUCCESS){
          setShowAlert(Constant.Success)
          setAlertMessage("Successfully deleted")
          handleDelete();
          closeDetails();
          setShowDelete(false);
        }
        if(apiResponse.status===type.ERROR){
          setShowAlert(Constant.Error)
          setAlertMessage(apiResponse.response)
        }
      };
      init();
    } catch (e) {
      setIsError(true);
    }
  }

  const handleNo = () => {
    setShowDelete(false);
  }

  return (
    <>
      {task.taskType === "Approval" ?
        /////////////////   approval mode

        <div style={{ height: "700px", width: "900px" }}>
          <div>
            <PorscheFont className="dcs-section-heading" >{t('Task details')}</PorscheFont>
          </div>
          <PDivider color="neutral-contrast-medium" style={{ marginTop: "8px", marginBottom: "8px" }} />

          <div style={{ background: "#F5F5F5" }}>
            <PFlex style={{ padding: "6px" }} justifyContent={"space-between"}>
              <PFlexItem >
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-text-16-600">{t('Created on')}</PorscheFont>
                  <PorscheFont className="dcs-heading">{task.createdOn.substring(0, 10).replaceAll("-", ".")}</PorscheFont>
                </Stack>
              </PFlexItem>
              <PFlexItem>
                <PDivider orientation={"vertical"} color="neutral-contrast-low" style={{ height: "20px", width: "2px" }} />
              </PFlexItem>
              <PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-text-16-600">{t('Created by')}</PorscheFont>
                  <PorscheFont className="dcs-heading">{task.createdBy.ppnName}</PorscheFont>
                </Stack>
              </PFlexItem>
              <PFlexItem>
                <PDivider orientation={"vertical"} color="neutral-contrast-low" style={{ height: "20px", width: "2px" }} />
              </PFlexItem>
              <PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-text-16-600">Status</PorscheFont>
                  {task.status === "Pending" && (
                    <PorscheFont className="dcs-heading" style={{ color: "#FF9B00" }}>{t(task.status)}</PorscheFont>
                  )}
                  {task.status === "Completed" && (
                    <PorscheFont className="dcs-heading">
                      <span>
                        <span style={{ color: "#1E7A53" }}>{t("Completed") + "  "}</span>
                        {t('on')}  {task.createdOn.substring(0, 10).replaceAll("-", ".")}
                      </span>
                    </PorscheFont>
                  )}
                </Stack>
              </PFlexItem>
              <PFlexItem style={{ width: "20%" }}></PFlexItem>
              <PFlexItem>
                {task.createdBy.id === loggedInUserId && task.status !== "Completed" && task.taskType !== "Approval" && (
                  <Stack direction={"row"} spacing={1} onClick={() => setEdit(true)}>
                    <PIcon name="edit" />
                    <PorscheFont className="dcs-title">{t('Edit')}</PorscheFont>
                  </Stack>
                )}
              </PFlexItem>
              <PFlexItem>
                {task.createdBy.id === loggedInUserId && task.status !== "Completed" && task.taskType !== "Approval" && (
                  <>
                    <Stack direction={"row"} spacing={1} onClick={() => setShowDelete(!showDelete)}>
                      <PIcon name="delete" />
                      <PorscheFont className="dcs-title" >{t('Delete task')}</PorscheFont>
                    </Stack>
                  </>
                )}
              </PFlexItem>
            </PFlex>
            {task.status === "Completed" && (
              <PFlex>
                <PFlexItem>
                  <PorscheFont>
                    <span className="dcs-title"><span className="dcs-heading">{t('Comment')}: </span>{task.statusComment}</span>
                  </PorscheFont>
                </PFlexItem>
              </PFlex>
            )}
          </div>

          {/** Mark as complete */}
          {/* { (task.createdBy.id === loggedInUserId ) && task.status !== "Completed" && (
        <div>
        <PFlex>
          <PFlexItem style={{width: "80%"}}></PFlexItem>
          <PFlexItem style={{width: "20%", marginRight:"20px", marginTop: "6px"}}>
            <div>
              <Stack direction={"row"} spacing={1} className="tn-mark-complete" onClick={handleMarkAsComplete}>
                <PIcon name="success" color={"notification-success"} style={{marginTop: "8px"}}/>
                <PorscheFont className="dcs-title">Mark as complete</PorscheFont>
              </Stack>
            {showMarkAsComplete && (
              <div className="mark-as-complete">
                <PFlex direction={"column"}>
                  <PFlexItem>
                    <PorscheFont className="dcs-heading">Mark as complete</PorscheFont>
                  </PFlexItem>
                  <PFlexItem>
                    <PorscheFont className="dcs-title">Comment (Optional)</PorscheFont>
                  </PFlexItem>
                  <PFlexItem>
                    <PTextareaWrapper label="Some label" hideLabel={true}>
                      <textarea onChange={(e) => handleStatusComment(e.target.value)} maxLength={200}>
                      </textarea>
                    </PTextareaWrapper>
                  </PFlexItem>
                  <PFlexItem className="mt4">
                    <PButtonGroup>
                      <PButton icon="success" onClick={handleCompleteComment}>Complete</PButton>
                      <PButton variant="tertiary" icon="close" onClick={handleCancelComplete}>Cancel</PButton>
                    </PButtonGroup>
                  </PFlexItem>
                </PFlex>
              </div>
            )}
            </div>
          </PFlexItem>
        </PFlex>
        </div>
      )} */}

          {/* {(task.assignedTo.id === loggedInUserId && task.assignedTo.id !== task.createdBy.id) && task.status !== "Completed" && (
        <div>
        <PFlex>
          <PFlexItem style={{width: "67%"}}></PFlexItem>
          <PFlexItem style={{width: "20%", marginRight:"20px", marginTop: "6px"}}>
            <div>
              <Stack direction={"row"} spacing={1} className="tn-mark-complete" onClick={handleMarkAsComplete}>
                <PIcon name="success" color={"notification-success"} style={{marginTop: "8px"}}/>
                <PorscheFont className="dcs-title">Mark as complete</PorscheFont>
              </Stack>
            {showMarkAsComplete && (
              <div className="mark-as-complete">
                <PFlex direction={"column"}>
                  <PFlexItem>
                    <PorscheFont className="dcs-heading">Mark as complete</PorscheFont>
                  </PFlexItem>
                  <PFlexItem>
                    <PorscheFont className="dcs-title">Comment (Optional)</PorscheFont>
                  </PFlexItem>
                  <PFlexItem>
                    <PTextareaWrapper label="Some label" hideLabel={true}>
                      <textarea onChange={(e) => handleStatusComment(e.target.value)} maxLength={200}>
                        {task.statusComment}
                      </textarea>
                    </PTextareaWrapper>
                  </PFlexItem>
                  <PFlexItem className="mt4">
                    <PButtonGroup>
                      <PButton icon="success" onClick={handleCompleteComment}>Complete</PButton>
                      <PButton variant="tertiary" icon="close" onClick={handleCancelComplete}>Cancel</PButton>
                    </PButtonGroup>
                  </PFlexItem>
                </PFlex>
              </div>
            )}
            </div>
          </PFlexItem>
          {/** Reassign */}
          {/* <PFlexItem style={{width: "13%", marginRight:"20px", marginTop: "6px"}}>
            <Stack direction={"row"} spacing={1} className="tn-reassign" onClick={handleShowReassign}>
              <PIcon name="switch" color={"notification-warning"} style={{marginTop: "8px"}}/>
              <PorscheFont className="dcs-title">Reassign</PorscheFont>
            </Stack>
            {showReassign && (
              <div className="reassign">
                <PFlex direction={"column"}>
                  <PFlexItem>
                    <PorscheFont className="dcs-heading">Reassign task</PorscheFont>
                  </PFlexItem>
                  <PFlexItem>
                    <PSelectWrapper label="Assign to">
                      <select name="assigneeTo"
                        onChange={(e) => handleAssignee(e.target.value)}
                        // disabled={disabled}
                        required
                      >
                        {/* <option key={"assignedNone"} value={0}>Select</option> */}
          {/* {users.map((user, index) => {
                          return (
                            <option 
                              key={index} 
                              value={user.id}
                              selected={task.assignedTo && task.assignedTo.id === user.id ? true : false}
                            >
                            {user.ppnName}
                            </option>
                          );
                        })}
                      </select>
                    </PSelectWrapper>
                  </PFlexItem> */}
          {/* //   <PFlexItem className="mt4">
                //     <PTextareaWrapper label="Comment">
                //       <textarea onChange={(e) => handleReassignComment(e.target.value)} maxLength={200} required>
                //       </textarea>
                //     </PTextareaWrapper>
                //   </PFlexItem> */}

          {/* { reassignError && (
                    <PFlexItem>
                      <PorscheFont className="dcs-title">
                        <span style={{color: "red"}}>Please enter valid value.</span>
                      </PorscheFont>
                    </PFlexItem>
                  )} */}

          {/* <PFlexItem className="mt4">
                    <PButtonGroup>
                      <PButton icon="switch" onClick={handleReassign}>Reassign</PButton>
                      <PButton variant="tertiary" icon="close" onClick={handleCancelReassign}>Cancel</PButton>
                    </PButtonGroup>
                  </PFlexItem> */}
          {/* </PFlex> */}
          {/* </div> */}
          {/* )} */}
          {/* </PFlexItem> */}
          {/* </PFlex> */}
          {/* </div> */}
          {/* )} */}

          <div>
            <PFlex direction={"column"} className="mt10">
              {!edit && (
                <PFlexItem>
                  <PorscheFont className="dcs-title">{t('Assigned to')}</PorscheFont>
                </PFlexItem>
              )}
              <PFlexItem style={{ width: "40%" }}>
                {!edit && (
                  <PTextFieldWrapper id={`assignedTo`}>
                    <input
                      id={`iassignedTo`}
                      key={`kassignedTo`}
                      type="text"
                      defaultValue={task.assignedTo.ppnName}
                      //value={user.staffNumber}
                      //disabled={edit ? false : true}
                      //onChange={ (e) => handleAssignedTo(e, user.id)}
                      readOnly={edit ? false : true}
                    />
                  </PTextFieldWrapper>
                )}

                {edit && (
                  <PSelectWrapper className="task-h" label={`${t("Assign to")}`}>
                    <select name="assigne" onChange={(e) => handleAssignee(e.target.value)} required>
                      {users.map((user, index) => {
                        return (
                          <option
                            key={index}
                            value={user.id}
                            selected={task.assignedTo && task.assignedTo.id === user.id ? true : false}
                          >
                            {user.ppnName}
                          </option>
                        );
                      })}
                    </select>
                  </PSelectWrapper>
                )}
              </PFlexItem>
              {task.reassigned && task.reassignedBy && task.status === "Completed" && (
                <PFlexItem>
                  <PorscheFont>
                    <span className="dcs-heading">{t('Reassigned')}</span> {t('by')} {task.reassignedBy.ppnName} {t('on')} {task.assignedOn.substring(0, 10).replaceAll("-", ".")} |
                  </PorscheFont>
                </PFlexItem>
              )}
            </PFlex>
          </div>
          {task.reassigned && task.reassignedBy && task.status === "Pending" && (
            <div>
              <PorscheFont>
                <span className="dcs-heading">{t('Reassigned')}</span> {t('by')} {task.reassignedBy.ppnName} {t('on')} {task.assignedOn.substring(0, 10).replaceAll("-", ".")} |
                <span className="dcs-heading">{t('Comment')}: </span> {task.reassignedReason}
              </PorscheFont>
            </div>
          )}
          {task.reassigned && task.status === "Completed" && (
            <div>
              <PorscheFont>
                {/* <span className="dcs-heading">Reassigned</span> by {task.createdBy.ppnName} on {task.assignedOn.substring(0, 10).replaceAll("-", ".")} |  */}
                <span className="dcs-heading">{t('Comment')}:  </span> {task.reassignedReason}
              </PorscheFont>
            </div>
          )}
          <div>
            <PFlex direction={"column"} className="mt10">
              <PFlexItem style={{ width: "70%" }}>
                <PorscheFont className="dcs-title">{t('Subject')}</PorscheFont>
              </PFlexItem>
              <PFlexItem style={{width: "70%"}}>
                <PTextFieldWrapper id={`subjectTo`}>
                  {/*<input*/}
                  {/*    id={`isubjectTo`}*/}
                  {/*    key={`ksubjectTo`}*/}
                  {/*    type="text"*/}
                  {/*    defaultValue={t(task.subject) as string}*/}
                  {/*    //value={user.staffNumber}*/}
                  {/*    //disabled={edit ? false : true}*/}
                  {/*    onChange={(e) => handleSubject(e.target.value)}*/}
                  {/*    readOnly={edit ? false : true}*/}
                  {/*    maxLength={50}*/}
                  {/*/>*/}
                </PTextFieldWrapper>
                <div className="dashboardPopupInputBox"><span>{t(task.subject)}</span></div>
              </PFlexItem>
            </PFlex>
          </div>
          <div>
            <PFlex direction={"column"} className="mt10">
              <PFlexItem style={{ width: "70%" }}>
                <PorscheFont className="dcs-title">{t('Description')}</PorscheFont>
              </PFlexItem>
              <PFlexItem style={{ width: "70%" }}>
                <PTextareaWrapper hideLabel={true}>
                  <textarea
                    name="description"
                    readOnly={edit ? false : true}
                    onChange={(e) => handleDescription(e.target.value)}
                  >
                    {t(task.description)}
                  </textarea>
                </PTextareaWrapper>
              </PFlexItem>
            </PFlex>
          </div>
          <PFlex justifyContent={"flex-end"}>
            <PFlexItem >
              <PButton
                onClick={() =>
                  task?.order
                    ? handlePage(task.order.id, task.order.isDraft)
                    : {}
                }
                >
                  {t("Open order")}
                </PButton>
              </PFlexItem>
          </PFlex>

          {validationError && (
            <div className='mt10'>
              <PorscheFont><span style={{ color: "#D5001B" }}>{t("Please enter valid values.")}</span></PorscheFont>
            </div>
          )}

          {edit && (
            <div className="mt10">
              <PButtonGroup>
                <PButton variant="secondary" icon="save" onClick={handleSave}>{t('Save')}</PButton>
                <PButton variant="tertiary" icon="close" onClick={handleCancel}>{t('Cancel')}</PButton>
              </PButtonGroup>
            </div>
          )}

          <div className="flex30 pt20">
            <Snackbar
              open={saveSuccess}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleSuccessClose}
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                {saveSuccessMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              open={isError}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleErrorClose}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                {t("Error while updating task!")}
              </Alert>
            </Snackbar>
          </div>
        </div>

        :
        /// SECOND HALF OF TERNARY STATEMENT 
        //TODO: SPLIT THOSE INTO SEPERATE COMPONENTS, SINCE ITS GETTING WAY TO COMPLEX TO MAINTAIN
        //////////////////////   non-approval mode
        <div style={{ height: "700px", width: "900px" }}>
          <div>
            <PorscheFont className="dcs-section-heading" >{t('Task details')}</PorscheFont>
          </div>
          {/* <div style={{ marginTop: "15px" }} >
            <PFlex>
              <PFlexItem>
                <PIcon name={"arrow-left"} onClick={closeDetails} />
              </PFlexItem>
              <PFlexItem>
                <PorscheFont className="tn-text-16-600" >{t('Tasks overview')}</PorscheFont>
              </PFlexItem>
            </PFlex>
          </div> */}

          <PDivider color="neutral-contrast-medium" style={{ marginTop: "8px", marginBottom: "8px" }} />

          <div style={{ background: "#F5F5F5" }}>
            <PFlex style={{ padding: "6px" }} justifyContent={"space-between"}>
              <PFlexItem >
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-text-16-600">{t('Created on')}</PorscheFont>
                  <PorscheFont className="dcs-heading">{task.createdOn.substring(0, 10).replaceAll("-", ".")}</PorscheFont>
                </Stack>
              </PFlexItem>
              <PFlexItem>
                <PDivider orientation={"vertical"} color="neutral-contrast-low" style={{ height: "20px", width: "2px" }} />
              </PFlexItem>
              <PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-text-16-600">{t('Created by')}</PorscheFont>
                  <PorscheFont className="dcs-heading">{task.createdBy.ppnName}</PorscheFont>
                </Stack>
              </PFlexItem>
              <PFlexItem>
                <PDivider orientation={"vertical"} color="neutral-contrast-low" style={{ height: "20px", width: "2px" }} />
              </PFlexItem>
              <PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  <PorscheFont className="tn-text-16-600">Status</PorscheFont>
                  {task.status === "Pending" && (
                      <PorscheFont className="dcs-heading" style={{ color: "#FF9B00" }}>
                        {t(task.status)}
                      </PorscheFont>
                  )}
                  {task.status === "Completed" && (
                    <PorscheFont className="dcs-heading">
                      <span>
                        <span style={{ color: "#1E7A53" }}>{"Completed  "}</span>
                        on  {task.createdOn.substring(0, 10).replaceAll("-", ".")}
                      </span>
                    </PorscheFont>
                  )}
                </Stack>
              </PFlexItem>
              <PFlexItem style={{ width: "15%" }}></PFlexItem>
              <PFlexItem>
                {task.createdBy.id === loggedInUserId && task.status !== "Completed" && (
                  <Stack direction={"row"} spacing={1} onClick={() => setEdit(true)}>
                    <PIcon name="edit" />
                    <PorscheFont className="dcs-title">{t('Edit')}</PorscheFont>
                  </Stack>
                )}
              </PFlexItem>
              <PFlexItem >
                {task.createdBy.id === loggedInUserId && task.status !== "Completed" && (
                  <>
                    <Stack direction={"row"} spacing={1} onClick={() => setShowDelete(!showDelete)}>
                      <PIcon name="delete" />
                      <PorscheFont className="dcs-title" >{t('Delete task')}</PorscheFont>
                    </Stack>
                  </>
                )}
              </PFlexItem>
            </PFlex>
            {task.status === "Completed" && (
              <PFlex>
                <PFlexItem>
                  <PorscheFont>
                    <span className="dcs-title"><span className="dcs-heading">{t('Comment')}: </span>{task.statusComment}</span>
                  </PorscheFont>
                </PFlexItem>
              </PFlex>
            )}
          </div>

          {/** Mark as complete */}
          {(task.createdBy.id === loggedInUserId) && task.status !== "Completed" && (
            <div>
              <PFlex>
                <PFlexItem style={{ width: "100%", marginTop: "6px", display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <Stack direction={"row"} spacing={1} className="tn-mark-complete" onClick={handleMarkAsComplete}>
                      <PIcon name="success" color={"notification-success"} style={{ marginTop: "8px" }} />
                      <PorscheFont className="dcs-title" style={{ padding: "-10px" }}>{t('Mark as complete')}</PorscheFont>
                    </Stack>
                    {showMarkAsComplete && (
                      <div className="mark-as-complete">
                        <PFlex direction={"column"}>
                          <PFlexItem>
                            <PorscheFont className="dcs-heading">{t('Mark as complete')}</PorscheFont>
                          </PFlexItem>
                          <PFlexItem>
                            <PorscheFont className="dcs-title">{t('Comment (Optional)')}</PorscheFont>
                          </PFlexItem>
                          <PFlexItem>
                            <PTextareaWrapper label="Some label" hideLabel={true}>
                              <textarea onChange={(e) => handleStatusComment(e.target.value)} maxLength={500}>
                              </textarea>
                            </PTextareaWrapper>
                          </PFlexItem>
                          <PFlexItem className="mt4">
                            <PButtonGroup>
                              <PButton icon="success" onClick={handleCompleteComment}>{t('Complete')}</PButton>
                              <PButton variant="tertiary" icon="close" onClick={handleCancelComplete}>{t('Cancel')}</PButton>
                            </PButtonGroup>
                          </PFlexItem>
                        </PFlex>
                      </div>
                    )}
                  </div>
                </PFlexItem>
              </PFlex>
            </div>
          )}

          {(task.assignedTo.id === loggedInUserId && task.assignedTo.id !== task.createdBy.id) && task.status !== "Completed" && (
            <div>
              <PFlex>
                <PFlexItem style={{ width: "100%", marginTop: "6px", display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <Stack direction={"row"} spacing={1} className="tn-mark-complete" onClick={handleMarkAsComplete}>
                      <PIcon name="success" color={"notification-success"} style={{ marginTop: "8px" }} />
                      <PorscheFont className="dcs-title">{t('Mark as complete')}</PorscheFont>
                    </Stack>
                    {showMarkAsComplete && (
                      <div className="mark-as-complete">
                        <PFlex direction={"column"}>
                          <PFlexItem>
                            <PorscheFont className="dcs-heading">{t('Mark as complete')}</PorscheFont>
                          </PFlexItem>
                          <PFlexItem>
                            <PorscheFont className="dcs-title">{t('Comment (Optional)')}</PorscheFont>
                          </PFlexItem>
                          <PFlexItem>
                            <PTextareaWrapper label="Some label" hideLabel={true}>
                              <textarea onChange={(e) => handleStatusComment(e.target.value)} maxLength={500}>
                                {task.statusComment}
                              </textarea>
                            </PTextareaWrapper>
                          </PFlexItem>
                          <PFlexItem className="mt4">
                            <PButtonGroup>
                              <PButton icon="success" onClick={handleCompleteComment}>{t('Complete')}</PButton>
                              <PButton variant="tertiary" icon="close" onClick={handleCancelComplete}>{t('Cancel')}</PButton>
                            </PButtonGroup>
                          </PFlexItem>
                        </PFlex>
                      </div>
                    )}
                  </div>
                </PFlexItem>
                {/** Reassign */}
                <PFlexItem style={{ width: "13%", marginRight: "20px", marginTop: "6px" }}>
                  <Stack direction={"row"} spacing={1} className="tn-reassign" onClick={handleShowReassign}>
                    <PIcon name="switch" color={"notification-warning"} style={{ marginTop: "8px" }} />
                    <PorscheFont className="dcs-title">{t('Reassign')}</PorscheFont>
                  </Stack>
                  {showReassign && (
                    <div className="reassign">
                      <PFlex direction={"column"}>
                        <PFlexItem>
                          <PorscheFont className="dcs-heading">{t('Reassign task')}</PorscheFont>
                        </PFlexItem>
                        <PFlexItem>
                          <PSelectWrapper label={`${t("Assign to")}`}>
                            <select name="assigneeTo"
                              onChange={(e) => handleAssignee(e.target.value)}
                              // disabled={disabled}
                              required
                            >
                              {/* <option key={"assignedNone"} value={0}>Select</option> */}
                              {users.map((user, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={user.id}
                                    selected={task.assignedTo && task.assignedTo.id === user.id ? true : false}
                                  >
                                    {user.ppnName}
                                  </option>
                                );
                              })}
                            </select>
                          </PSelectWrapper>
                        </PFlexItem>
                        <PFlexItem className="mt4">
                          <PTextareaWrapper label="Comment">
                            <textarea onChange={(e) => handleReassignComment(e.target.value)} maxLength={500} required>
                            </textarea>
                          </PTextareaWrapper>
                        </PFlexItem>

                        {reassignError && (
                          <PFlexItem>
                            <PorscheFont className="dcs-title">
                              <span style={{ color: "#D5001B" }}>{t("Please enter valid value.")}</span>
                            </PorscheFont>
                          </PFlexItem>
                        )}

                        <PFlexItem className="mt4">
                          <PButtonGroup>
                            <PButton icon="switch" onClick={handleReassign}>{t('Reassign')}</PButton>
                            <PButton variant="tertiary" icon="close" onClick={handleCancelReassign}>{t('Cancel')}</PButton>
                          </PButtonGroup>
                        </PFlexItem>
                      </PFlex>
                    </div>
                  )}
                </PFlexItem>
              </PFlex>
            </div>
          )}

          <div>
            <PFlex direction={"column"} className="mt10">
              {!edit && (
                <PFlexItem>
                  <PorscheFont className="dcs-title">{t('Assigned to')}</PorscheFont>
                </PFlexItem>
              )}
              <PFlexItem style={{ width: "40%" }}>
                {!edit && (
                  <PTextFieldWrapper id={`assignedTo`}>
                    <input
                      id={`iassignedTo`}
                      key={`kassignedTo`}
                      type="text"
                      defaultValue={task.assignedTo.ppnName}
                      //value={user.staffNumber}
                      //disabled={edit ? false : true}
                      //onChange={ (e) => handleAssignedTo(e, user.id)}
                      readOnly={edit ? false : true}
                    />
                  </PTextFieldWrapper>
                )}

                {edit && (
                  <PSelectWrapper className="task-h" label={`${t("Assign to")}`}>
                    <select name="assigne" onChange={(e) => handleAssignee(e.target.value)} required>
                      {users.map((user, index) => {
                        return (
                          <option
                            key={index}
                            value={user.id}
                            selected={task.assignedTo && task.assignedTo.id === user.id ? true : false}
                          >
                            {user.ppnName}
                          </option>
                        );
                      })}
                    </select>
                  </PSelectWrapper>
                )}
              </PFlexItem>
              {task.reassigned && task.reassignedBy && task.status === "Completed" && (
                <PFlexItem>
                  <PorscheFont>
                    <span className="dcs-heading">{t('Reassigned')}</span> {t('by')} {task.reassignedBy.ppnName} {t('on')} {task.assignedOn.substring(0, 10).replaceAll("-", ".")} |
                  </PorscheFont>
                </PFlexItem>
              )}
            </PFlex>
          </div>
          {task.reassigned && task.reassignedBy && task.status === "Pending" && (
            <div>
              <PorscheFont>
                <span className="dcs-heading">{t('Reassigned')}</span> {t('by')} {task.reassignedBy.ppnName} {t('on')} {task.assignedOn.substring(0, 10).replaceAll("-", ".")} |
                <span className="dcs-heading">{t('Comment')}: </span> {task.reassignedReason}
              </PorscheFont>
            </div>
          )}
          {task.reassigned && task.status === "Completed" && (
            <div>
              <PorscheFont>
                {/* <span className="dcs-heading">Reassigned</span> by {task.createdBy.ppnName} on {task.assignedOn.substring(0, 10).replaceAll("-", ".")} |  */}
                <span className="dcs-heading">{t('Comment')}: </span> {task.reassignedReason}
              </PorscheFont>
            </div>
          )}
          <div>
            <PFlex direction={"column"} className="mt10">
              <PFlexItem style={{ width: "70%" }}>
                <PorscheFont className="dcs-title">{t('Subject')}</PorscheFont>
              </PFlexItem>
              <PFlexItem style={{ width: "70%" }}>
                {edit ?
                  <PTextFieldWrapper id={`subjectTo`}>
                    <input
                      id={`isubjectTo`}
                      key={`ksubjectTo`}
                      type="text"
                      defaultValue={t(task.subject) as string}
                      //value={user.staffNumber}
                      //disabled={edit ? false : true}
                      onChange={(e) => handleSubject(e.target.value)}
                      readOnly={edit ? false : true}
                      maxLength={50}
                    />
                  </PTextFieldWrapper>
                : 
                  <div className="dashboardPopupInputBox">
                    <span>{t(task.subject)}</span>
                  </div>
                }
              </PFlexItem>
            </PFlex>
          </div>
          <div>
            <PFlex direction={"column"} className="mt10">
              <PFlexItem style={{ width: "70%" }}>
                <PorscheFont className="dcs-title">{t('Description')}</PorscheFont>
              </PFlexItem>
              <PFlexItem style={{ width: "70%" }}>
                {edit ?
                  <PTextareaWrapper> 
                    <textarea
                    name="description"
                    readOnly={edit ? false : true}
                    onChange={(e) => handleDescription(e.target.value)}
                    maxLength={500}
                    >
                      {task.description}
                    </textarea>
                  </PTextareaWrapper> 
                : 
                  <PTextareaWrapper> 
                    <textarea
                      name="description"
                      readOnly={edit ? false : true}
                      onChange={(e) => handleDescription(e.target.value)}
                      >
                      {task.description}
                    </textarea>
                  </PTextareaWrapper>
                }
              </PFlexItem>
            </PFlex>
          </div>
          <PFlex justifyContent={"space-between"}>
            <PFlexItem>
              {edit && (
              <div className="mt10">
                <PButtonGroup>
                  <PButton variant="secondary" icon="save" onClick={handleSave}>{t('Save')}</PButton>
                  <PButton variant="tertiary" icon="close" onClick={handleCancel}>{t('Cancel')}</PButton>
                </PButtonGroup>
              </div>
              )}
            </PFlexItem>
            <PFlexItem alignSelf={"flex-end"}>
              <div className="mt10">
                <PButton
                  onClick={() =>
                    task?.order
                      ? handlePage(task.order.id, task.order.isDraft)
                      : {}
                  }
                >
                  {t("Open order")}
                </PButton>
              </div>
            </PFlexItem>
          </PFlex>

          {validationError && (
            <div className='mt10'>
              <PorscheFont><span style={{ color: "red" }}>{t("Please enter valid values.")}</span></PorscheFont>
            </div>
          )}

          <div className="flex50 pt20">
            <Snackbar
              open={saveSuccess}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleSuccessClose}
              style={{zIndex:9999, marginTop:"20px"}}      
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                {`${t(saveSuccessMsg)}`}
              </Alert>
            </Snackbar>
            <Snackbar
              open={reassignMessage}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleSuccessClose}
              style={{zIndex:9999, marginTop:"20px"}}
              message="Task reassigned"
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                {"Task reassigned"}
              </Alert>
            </Snackbar>
            <Snackbar
              open={isError}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleErrorClose}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                {t("Error while updating task!")}
              </Alert>
            </Snackbar>
            <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>
          </div>
        </div>
      }
      <PModal
        className="overview-modal"
        open={showDelete}
        onClose={handleNo}
      >
        <PFlex>
            <PFlexItem style={{ marginRight: "4px", marginTop: "16px" }}>
              <PorscheFont className="dcs-heading">
                {t('Are you sure you want to delete this task?')}
              </PorscheFont>
            </PFlexItem>
          </PFlex>
          <PFlex dir="rtl" style={{ marginTop: "14px" }}>
            <PFlexItem>
              <PButtonGroup>
                <PButton 
                  icon={"arrow-head-right"} 
                  onClick={handleYes}
                >
                  {t('Yes, delete task')}
                </PButton>
                <PButton
                  variant="tertiary"
                  icon={"close"}
                  onClick={handleNo}
                >
                  <PorscheFont>
                    {t('Cancel')}
                  </PorscheFont>
                </PButton>
              </PButtonGroup>
            </PFlexItem>
          </PFlex>
      </PModal>
    </>
  );
}

