import { Dispatch,  SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";
import {
  PButton,
  PCheckboxWrapper,
  PDivider,
  PFlex,
  PFlexItem,
  PIcon,
  PSelectWrapper,
  PTextFieldWrapper,
} from "@porsche-design-system/components-react";

import {
  PorscheFont,
} from "../../../../assets/styles/CustomStyles";
import {
  CategoryCommissionDTO,
  VehicleCommissionDTO,
} from "../../../../interfaces/commission";
import {
  adminCurrencyFormatter,
  formatCurrencyToDecimal,
  formatCurrencyWithNoUnit,
  onBlurCurrencyFormatter,
  onBlurPercentageFormatter,
  validateCurrency,
  validatePercentage,
  validateStrandingDays,
} from "../../../../mock/helper";
import { CustomDelete } from "../../../common/CustomDelete";

interface Props {
  handleCommissionPost: (
    i: number,
    categoryCommission: CategoryCommissionDTO
  ) => void;
  commissions: CategoryCommissionDTO;
  cindex: number;
  edit: boolean;
  description: string;
  customerType: string;
  collection: string;
  setShowSaveButton: Dispatch<SetStateAction<boolean>>;
  setErrorState :Dispatch<SetStateAction<Record<string, boolean> | undefined>>
  errorState:Record<string, boolean>|undefined
}


export const NewCarCom = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    commissions,
    handleCommissionPost,
    cindex,
    edit,
    description,
    customerType,
    collection,
    setShowSaveButton,
    setErrorState,
    errorState,
  } = props;
  
  const [hideRow, setHideRow] = useState<boolean>(false);
  const [reload, setReload] = useState("");
  const [gross, setGross] = useState("");
  const [rangeError, setRangeError] = useState<boolean>(false);
  const [rangeInputError1, setRangeInputError1] = useState<boolean>(false);
  const [rangeInputError2, setRangeInputError2] = useState<boolean>(false);
  const [rangeInputError3, setRangeInputError3] = useState<boolean>(false);
  const [rangeInputError, setRangeInputError] = useState<boolean>(false);
  const [vehicleCommErrorState, setVehicleCommErrorState] = useState<Record<string,boolean>>()

  const [variable, setVariable] = useState(commissions.variable);
  const [commissionData, setCommissionData] =
  useState<CategoryCommissionDTO>(commissions);
  const inputMaxProfit = useRef<HTMLInputElement>(null);
  const inputMinProfit = useRef<HTMLInputElement>(null);
  const inputNoProfit = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setVariable(commissionData.variable);
  }, [reload, gross]);
  
  
  useEffect(() => {
    commissionData.vehicleCommissions.forEach((x, i) => {
      handleClickTo(i, `${x.toValue}`, true);
    });

  }, [variable]);

  const isAddButtonActive = ()=>{
     const lastVehicleCommValue =  commissionData.vehicleCommissions[commissionData.vehicleCommissions.length-1].toValue
     if(variable ==="Discount"){
        if(lastVehicleCommValue < 100 && !(vehicleCommErrorState && Object.values(vehicleCommErrorState).includes(true)) ){
          return true
        }
        else{
          return false
        }
     }
     else{
      if(lastVehicleCommValue < 9999 && !(vehicleCommErrorState && Object.values(vehicleCommErrorState).includes(true)) ){
        return true
      }
      else{
        return false
      }
     }
         
    }
    

   

  const handleClickMin = () => {
    commissionData.minCommission = !commissionData.minCommission;
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);
    //setMin(!showMin);
  };

const errorStateHandler=(wrapperId:string , state:boolean)=>{
  setErrorState((prev)=>{
    return {...prev, [wrapperId]:state}
  })
}

const vehicleCommErrorStateHandler = (wrapperId:string , state:boolean)=>{
  setVehicleCommErrorState((prev)=>{
    return {...prev, [wrapperId]:state}
  })
}

const specialCommissionHandler = (value: string, elementDomId:string, refrenceName:string|undefined )=>{

  if(refrenceName=== "Euro"){
    if(validateCurrency(value)){
      errorStateHandler(elementDomId, false)
      return(adminCurrencyFormatter(value)) 
    }
    else{
      errorStateHandler(elementDomId, true)
      return 0
    }
}
else{
 if(validatePercentage(value)){
   errorStateHandler(elementDomId, false)
   return(adminCurrencyFormatter(value)) 
  }
  else{
    errorStateHandler(elementDomId, true)
    return 0
  }
}
}

  const handleValue = (value: string, type: string, i: number) => {
    switch (type) {
      case "min":
        const minWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+'min'}`);
        let minElementDomId= minWrapperDom?.id as string
       commissionData.minCommissionValue =  specialCommissionHandler(value, minElementDomId,commissionData.minCommissionReference)
        break;
      case "max":
        const maxWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+'max'}`);
        let maxElementDomId= maxWrapperDom?.id as string
        commissionData.maxCommissionValue =specialCommissionHandler(value, maxElementDomId,commissionData.maxCommissionReference)
        break;
      case "no":
        const noWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+'no'}`);
        let noElementDomId= noWrapperDom?.id as string
        commissionData.noProfitCommissionValue =specialCommissionHandler(value, noElementDomId,commissionData.noProfitCommissionReference)

        // commissionData.noProfitCommissionValue = formatCurrencyToDecimal(value);
        // if(commissionData.noProfitCommissionReference != "Euro" && (Number(value) >100 || Number(value) <= 0)){
        //   setShowSaveButton(false)
        //   setRangeInputError3(true)
        //   return false
        // }
        // setRangeInputError3(false)
        break;
      case "val1":
        const val1WrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+`vcvarpv1${i}`}`);
        let val1ElementDomId= val1WrapperDom?.id as string
        commissionData.vehicleCommissions[i].payoutValue1 =specialCommissionHandler(value, val1ElementDomId,commissionData.vehicleCommissions[i].payoutRefence1)
       
        // commissionData.vehicleCommissions[i].payoutValue1 =
        //     formatCurrencyToDecimal(value);
        // if(commissionData.vehicleCommissions[i].payoutRefence1 != "Euro" && (Number(value) >100 || Number(value) <= 0)){
        //   setShowSaveButton(false)
        //   setRangeInputError(true)
        //   setHideRow(true);
        //   return false
        // }
        // setRangeInputError(false)
        // setHideRow(false);
        break;
      case "val2":
      const val2WrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+`vcvarval2${i}`}`);
      let val2ElementDomId= val2WrapperDom?.id as string
      commissionData.vehicleCommissions[i].payoutValue2 =specialCommissionHandler(value, val2ElementDomId,commissionData.vehicleCommissions[i].payoutRefence2)
        // commissionData.vehicleCommissions[i].payoutValue2 =
        //     formatCurrencyToDecimal(value);
        // if(commissionData.vehicleCommissions[i].payoutRefence2 != "Euro" && (Number(value) >100 || Number(value) <= 0)){
        //   setShowSaveButton(false)
        //   setRangeInputError(true)
        //   setHideRow(true);
        //   return false
        // }
        // setRangeInputError(false)
        // setHideRow(false);
        break;
    }
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);
  };

  const handleClickMax = () => {
    commissionData.maxCommission = !commissionData.maxCommission;
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);
    //setMax(!showMax);
  };
  const handleClickNoProfit = () => {
    commissionData.noProfitCommission = !commissionData.noProfitCommission;
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);

    //setNoProfit(!showNoProfit);
  };
  const handleClickPayOut = () => {
    commissionData.payOut2 = !commissionData.payOut2;
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);

    //setPayOut(!showPayOut);
  };
  const handleDeleteRow = (index: number) => {
    let vc = commissionData.vehicleCommissions;

    if (vc && vc.length - 1 === index) {
      vc.splice(index, 1);
      if (commissionData.variable === "Discount") {
        if (vc[vc.length - 1].toValue < 100 && hideRow === true) {
          setHideRow(false);
        }
      }
      if (commissionData.variable === "StandingDays") {
        if (vc[vc.length - 1].toValue < 9999 && hideRow === true) {
          setHideRow(false);
        }
      }
      commissionData.vehicleCommissions = vc;
      setCommissionData(commissionData);
      handleCommissionPost(cindex, commissionData);
      setReload(new Date().toLocaleString());
    }
  };

const nextCommissionFromValueHandler = (index:number,  VehicleCommValue: string,apiResponse?:boolean,)=>{
  
  if (commissionData.vehicleCommissions[index + 1]?.fromValue && !apiResponse && commissionData.variable !== "StandingDays") {
    commissionData.vehicleCommissions[index + 1].fromValue = adminCurrencyFormatter(VehicleCommValue) + 0.01
    if (commissionData.vehicleCommissions[index + 1].fromValue > commissionData.vehicleCommissions[index + 1].toValue) {
      vehicleCommErrorStateHandler(commissions.commissionScalingCategoryType + `vcvar${index + 1}`, true)
      errorStateHandler(commissions.commissionScalingCategoryType + `vcvar${index + 1}`, true)
    }
    else {
      vehicleCommErrorStateHandler(commissions.commissionScalingCategoryType + `vcvar${index + 1}`, false)
      errorStateHandler(commissions.commissionScalingCategoryType + `vcvar${index + 1}`, false)
    }
  }
  if (commissionData.vehicleCommissions[index + 1]?.fromValue && !apiResponse && commissionData.variable === "StandingDays") {
    commissionData.vehicleCommissions[index + 1].fromValue = adminCurrencyFormatter(VehicleCommValue) + 1
    if (commissionData.vehicleCommissions[index + 1].fromValue > commissionData.vehicleCommissions[index + 1].toValue) {
      vehicleCommErrorStateHandler(commissions.commissionScalingCategoryType + `vcvar${index + 1}`, true)
      errorStateHandler(commissions.commissionScalingCategoryType + `vcvar${index + 1}`, true)
    }
    else {
      vehicleCommErrorStateHandler(commissions.commissionScalingCategoryType + `vcvar${index + 1}`, false)
      errorStateHandler(commissions.commissionScalingCategoryType + `vcvar${index + 1}`, false)
    }
  }
}

  const handleClickTo = (index: number, VehicleCommValue: string, apiResponse?: boolean) => {
    const value = apiResponse ? parseFloat(VehicleCommValue) : adminCurrencyFormatter(VehicleCommValue);
    const inputValue = apiResponse ? variable === "Discount" ? parseFloat(VehicleCommValue).toLocaleString('de-DE') : value + '' : VehicleCommValue
    const vehicleCommInputWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType + `vcvar${index}`}`);
    let vehElementDomId = vehicleCommInputWrapperDom?.id as string
      if (
      (commissionData.variable === "StandingDays"
        ? validateStrandingDays(inputValue)
        : validatePercentage(inputValue)) &&
      commissionData.vehicleCommissions[index].fromValue <= value
    ) {
      nextCommissionFromValueHandler(index, VehicleCommValue, apiResponse)

      setRangeError(false);
      setShowSaveButton(true);
      errorStateHandler(vehElementDomId, false)
      vehicleCommErrorStateHandler(vehElementDomId, false)
      commissionData.vehicleCommissions[index].toValue =value
      setCommissionData(commissionData);
      handleCommissionPost(cindex, commissionData);
      setReload(new Date().toLocaleString());
    } else {
      setRangeError(true);
      setHideRow(true);
      errorStateHandler(vehElementDomId, true)
      vehicleCommErrorStateHandler(vehElementDomId, true)
      setShowSaveButton(false);
    }
  };

  const handleAddRow = () => {
    let vc = commissionData.vehicleCommissions;
    let last = vc[vc.length - 1];
    switch (commissionData.variable) {
      case "Discount":
        if (last.fromValue === 0 && last.toValue < 99.99) {
          let next = {
            fromValue: last.toValue + 0.01,
            toValue: 0,
            payoutValue1: 0,
            payoutRefence1: "Euro",

            payoutValue2: 0,
            payoutRefence2: "Euro",
          } as VehicleCommissionDTO;

          vc.push(next);
          commissionData.vehicleCommissions = vc;
          setCommissionData(commissionData);
          handleCommissionPost(cindex, commissionData);
          setHideRow(false);
          setReload(new Date().toLocaleString());
        } else if(last.fromValue >0 && last.toValue< 99.99){
          let next = {
            fromValue: last.toValue + 0.01,
            toValue: 0,
            payoutValue1: 0,
            payoutRefence1: "Euro",

            payoutValue2: 0,
            payoutRefence2: "Euro",
          } as VehicleCommissionDTO;

          vc.push(next);
          commissionData.vehicleCommissions = vc;
          setCommissionData(commissionData);
          handleCommissionPost(cindex, commissionData);
          setHideRow(false);
          setReload(new Date().toLocaleString());
        }else {
          setHideRow(true);
        }

        break;
      case "StandingDays":
        if (last.fromValue === 0 && last.toValue < 9999) {
          let next = {
            fromValue: last.toValue + 1,
            toValue: 0,
            payoutValue1: 0,
            payoutRefence1: "Euro",

            payoutValue2: 0,
            payoutRefence2: "Euro",
          } as VehicleCommissionDTO;

          vc.push(next);
          commissionData.vehicleCommissions = vc;
          setCommissionData(commissionData);
          handleCommissionPost(cindex, commissionData);
          setHideRow(false);
          setReload(new Date().toLocaleString());
        } else if (last.fromValue > 0 && last.toValue < 9999) {
          let next = {
            fromValue: last.toValue + 1,
            toValue: 0,
            payoutValue1: 0,
            payoutRefence1: "Euro",

            payoutValue2: 0,
            payoutRefence2: "Euro",
          } as VehicleCommissionDTO;

          vc.push(next);
          commissionData.vehicleCommissions = vc;
          setCommissionData(commissionData);
          handleCommissionPost(cindex, commissionData);
          setHideRow(false);
          setReload(new Date().toLocaleString());
        } else {
          setHideRow(true);
        }

        break;
    }
  };

  const handleReference = (value: string, type: string, i: number) => {

    switch (type) {
      case "min":
        const minWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType + 'min'}`);
        const minInputDom = document.getElementById(`${commissions.commissionScalingCategoryType + 'min-value'}`) as HTMLInputElement;
        let minWrapperDomId = minWrapperDom?.id as string
        let minInputValue = minInputDom && minInputDom?.value

        commissionData.minCommissionReference = value;
        commissionData.minCommissionValue =  specialCommissionHandler(minInputValue, minWrapperDomId,commissionData.minCommissionReference)
        break;

      case "max":
        const maxWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType + 'max'}`);
        const maxInputDom = document.getElementById(`${commissions.commissionScalingCategoryType + 'max-value'}`) as HTMLInputElement;
        let maxWrapperDomId = maxWrapperDom?.id as string
        let maxInputvalue = maxInputDom && maxInputDom?.value

        commissionData.maxCommissionReference = value;
        commissionData.maxCommissionValue = specialCommissionHandler(maxInputvalue, maxWrapperDomId,commissionData.maxCommissionReference)
        break;

      case "no":
        const noWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType + 'no'}`);
        const noInputDom = document.getElementById(`${commissions.commissionScalingCategoryType + 'no-value'}`) as HTMLInputElement;
        let noWrapperDomId = noWrapperDom?.id as string
        let noInputvalue = noInputDom && noInputDom?.value

        commissionData.noProfitCommissionReference = value;
        commissionData.noProfitCommissionValue = specialCommissionHandler(noInputvalue, noWrapperDomId,value)

        // commissionData.noProfitCommissionReference = value;
        break;
      case "val":
        const valWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+'vcvarpv1'+i}`);
        const valInputDom = document.getElementById(`${commissions.commissionScalingCategoryType+'vcvarpv1-value'+i}`) as HTMLInputElement;
        let valWrapperDomId = valWrapperDom?.id as string
        let valInputvalue = valInputDom && valInputDom?.value

        commissionData.vehicleCommissions[i].payoutRefence1 = value;
        commissionData.vehicleCommissions[i].payoutValue1 = specialCommissionHandler(valInputvalue, valWrapperDomId,value)

        break;
      case "ref":
        const refWrapperDom = document.getElementById(`${commissions.commissionScalingCategoryType+'vcvarval2'+i}`);
        const refInputDom = document.getElementById(`${commissions.commissionScalingCategoryType+'vcvarval2-value'+i}`) as HTMLInputElement;
        let refWrapperDomId = refWrapperDom?.id as string
        let refInputvalue = refInputDom && refInputDom?.value

        commissionData.vehicleCommissions[i].payoutRefence2 = value;
        commissionData.vehicleCommissions[i].payoutValue2=specialCommissionHandler(refInputvalue, refWrapperDomId,value)
        break;
    }

    setCommissionData(commissionData);
    if(validateCommissionData(commissionData)){
      handleCommissionPost(cindex, commissionData);
      setReload(new Date().toLocaleString());
    } else {
      setReload(new Date().toLocaleString());
      setShowSaveButton(false)
      return false
    }


  };
  const handleVariable = (value: string) => {
    commissionData.variable = value;
    setGross(value);
    setVariable(value);
    setCommissionData(commissionData);
    handleCommissionPost(cindex, commissionData);
    setReload(new Date().toLocaleString());
  };

  const getDescription = (
    value: string,
    customerType: string,
    Collection: string
  ) => {
    let ret = "";
    switch (value) {
      case "NewCarSpecialCase":
        ret = "Customer type = KeyAccount";
        break;
      case "UsedCarSpecialCasePrivateCustomer":
        ret =
          "Customer type = Private customer, Collection = Leasing Takeover / Service Loaner";
        break;
      case "UsedCarSpecialCaseDealer":
        ret = "Customer type = Dealer / Dealer Bidding Platform";
        break;
      case "UsedCarSpecialCaseDealerBiddingPlatform":
        ret =
          "Customer type = Dealer Bidding Platform, Collection = Leasing Return";
        break;
    }
    return ret;
  };

  const validateCommissionData = (commissionData : CategoryCommissionDTO) =>{

    let conditionMet = false;

    commissionData.vehicleCommissions.forEach(function (data) {

      if(data.payoutRefence1!= "Euro"){
        let commVal = data.payoutValue1
        if(commVal > 100 || commVal <= 0){
          conditionMet = true
        }
      }

      if(commissionData.payOut2){
        if(data.payoutRefence2!= "Euro"){

          let commVal:number = data.payoutValue2!!
          if(commVal > 100 || commVal <= 0){
            conditionMet = true
          }
        }
      }

      if(data.payoutRefence1 == "Euro"){
        let commVal = data.payoutValue1
        if(commVal<=0){
          conditionMet = true
        } else {
          setRangeInputError(false)
          setHideRow(false)
          return true
        }
      }
    })

    if(conditionMet){
      setRangeInputError(true)
      setHideRow(true)
      return false
    }

    if(commissionData.minCommissionReference != "Euro" && commissionData.minCommission){
      let minCom = commissionData.minCommissionValue? commissionData.minCommissionValue : 0
      if(minCom > 100 || minCom <= 0){
        // setRangeInputError1(true)
        return false
      }
    }
    if (commissionData.maxCommission && commissionData.maxCommissionReference != "Euro"){
      let maxCom = commissionData.maxCommissionValue? commissionData.maxCommissionValue : 0
      if(maxCom > 100 || maxCom <= 0){
        setRangeInputError2(true)
        return false
      }
    }
    if (commissionData.noProfitCommission && commissionData.noProfitCommissionReference != "Euro"){
      let noProfitCom = commissionData.noProfitCommissionValue? commissionData.noProfitCommissionValue : 0
      if(noProfitCom > 100 || noProfitCom <= 0){
        setRangeInputError3(true)
        return false
      }
    }

    if(commissionData.minCommissionReference == "Euro" && commissionData.minCommission){
      let minCom = commissionData.minCommissionValue? commissionData.minCommissionValue : 0
      if(minCom <= 0){
        // setRangeInputError1(true)
        return false
      } else {
        // setRangeInputError1(false)
        return true
      }
    }

    if(commissionData.maxCommissionReference == "Euro" && commissionData.maxCommission){
      let maxCom = commissionData.maxCommissionValue? commissionData.maxCommissionValue : 0
      if(maxCom <= 0){
        setRangeInputError2(true)
        return false
      } else {
        setRangeInputError2(false)
        return true
      }
    }

    if(commissionData.noProfitCommissionReference == "Euro" && commissionData.noProfitCommission){
      let noProfitCom = commissionData.noProfitCommissionValue? commissionData.noProfitCommissionValue : 0
      if(noProfitCom <= 0){
        setRangeInputError3(true)
        return false
      } else {
        setRangeInputError3(false)
        return true
      }
    }

    return true
  };



  return (
    <>
      <div>
        <PorscheFont className="dcs-section-heading">
          {t(commissionData.displayLabel)}
        </PorscheFont>
        <br></br>

        {getDescription(description, customerType, collection).length > 0 && (
          <>
            <Stack direction="row" spacing={1}>
              <PIcon name="information" />
              <PorscheFont>
                {t(getDescription(description, customerType, collection))}
              </PorscheFont>
            </Stack>
            <br></br>
          </>
        )}
        <span className="table-column-header">
          <PorscheFont>{t("Special commissions")}</PorscheFont>
        </span>
        <PDivider
          color="neutral-contrast-high"
          style={{ marginTop: "10px" }}
        ></PDivider>
        <br></br>

        <PFlex direction="row">
          <PFlexItem flex="equal">
            <PFlex direction="column">
              <PFlexItem>
                <PCheckboxWrapper
                  label={`${t("Minimum commission")}`}
                  onClick={handleClickMin}
                >
                  <input
                    aria-autocomplete="none"
                    ref={inputMinProfit}
                    autoComplete="off"
                    type="checkbox"
                    disabled={edit ? false : true}
                    checked={commissionData.minCommission}
                  />
                </PCheckboxWrapper>
              </PFlexItem>
              &nbsp; <br></br>
              <PFlexItem>
                <PDivider
                  color="neutral-contrast-low"
                  style={{ width: "310px" }}
                ></PDivider>
                <br></br>
              </PFlexItem>
              <PFlexItem>
                {commissionData.minCommission && (
                  <PFlex direction="row">
                    <PFlexItem style={{ width: "40%" }}>
                      <PTextFieldWrapper
                      id={`${commissions.commissionScalingCategoryType+'min'}`}
                        label={`${t("Value")}`}
                        unit={
                          commissionData.minCommissionReference === "Euro"
                            ? "€"
                            : "%"
                        }
                        unitPosition="suffix"
                       state={errorState && errorState[`${commissions.commissionScalingCategoryType+'min'}`]===true? 'error':'none'}
                      >

                        <input
                            aria-autocomplete="none"
                            autoComplete="off"
                            type="text"
                            id={`${commissions.commissionScalingCategoryType+'min-value'}`}
                            name="min-value"
                            disabled={edit ? false : true}
                            defaultValue={commissionData.minCommissionValue ? formatCurrencyWithNoUnit( commissionData.minCommissionValue) : "00,00"}
                            onChange={(e) =>
                                handleValue(e.target.value, "min", 0)
                            }
                            onBlur={(e)=>{
                              if(commissionData.minCommissionReference === "Euro"){
                                onBlurCurrencyFormatter(e)
                              }
                              else{
                                onBlurPercentageFormatter(e)
                              }
                            }}
                            required
                        />
                      </PTextFieldWrapper>
                    </PFlexItem>
                    &ensp;
                    <PFlexItem style={{ width: "40%" }}>
                      <PSelectWrapper label={`${t("Reference")}`}>
                        <select
                          name="some-name"
                          disabled={edit ? false : true}
                          required
                          onChange={(e) =>
                            handleReference(e.target.value, "min", 0)
                          }
                        >
                          <option
                            value="Euro"
                            selected={
                              commissionData.minCommissionReference === "Euro"
                                ? true
                                : false
                            }
                          >
                            {t("Euro")}
                          </option>
                          <option
                            value="GrossProfit"
                            selected={
                              commissionData.minCommissionReference ===
                              "GrossProfit"
                                ? true
                                : false
                            }
                          >
                            {t("Gross Profit")}
                          </option>
                          <option
                            value="NetTurnover"
                            selected={
                              commissionData.minCommissionReference ===
                              "NetTurnover"
                                ? true
                                : false
                            }
                          >
                            {t("Net Turnover")}
                          </option>
                        </select>
                      </PSelectWrapper>
                    </PFlexItem>
                  </PFlex>
                )}
                {/* {rangeInputError1 && (
                    <span style={{ color: "#D5001B" }}>
                    <PorscheFont>
                      {t(
                          "Please enter a valid value."
                      )}
                    </PorscheFont>
                  </span>
                )} */}
              </PFlexItem>
            </PFlex>
          </PFlexItem>
          <PFlexItem flex="equal">
            <PFlex direction="column">
              <PFlexItem>
                <PCheckboxWrapper
                  label={`${t("Maximum commission")}`}
                  onClick={handleClickMax}
                >
                  <input
                    aria-autocomplete="none"
                    ref={inputMaxProfit}
                    autoComplete="off"
                    type="checkbox"
                    name="some-name"
                    disabled={edit ? false : true}
                    checked={commissionData.maxCommission}
                  />
                </PCheckboxWrapper>
              </PFlexItem>
              &nbsp; <br></br>
              <PFlexItem>
                <PDivider
                  color="neutral-contrast-low"
                  style={{ width: "310px" }}
                ></PDivider>
                <br></br>
              </PFlexItem>
              <PFlexItem>
                {commissionData.maxCommission && (
                  <PFlex direction="row">
                    <PFlexItem style={{ width: "40%" }}>
                      <PTextFieldWrapper
                        label={`${t("Value")}`}
                        id={`${commissions.commissionScalingCategoryType+'max'}`}

                        unit={
                          commissionData.maxCommissionReference === "Euro"
                            ? "€"
                            : "%"
                        }
                        state={errorState && errorState[`${commissions.commissionScalingCategoryType+'max'}`]===true? 'error':'none'}

                        unitPosition="suffix"
                      >
                        <input
                          type="text"
                          name="some-name"
                          id={`${commissions.commissionScalingCategoryType+'max-value'}`}
                          aria-autocomplete="none"
                          autoComplete="off"
                          disabled={edit ? false : true}
                          defaultValue={
                            commissionData.maxCommissionValue
                              ? formatCurrencyWithNoUnit(commissionData.maxCommissionValue)
                              : 0.0
                          }
                          onChange={(e) =>
                            handleValue(e.target.value, "max", 0)
                          }
                          onBlur={(e)=>{
                            if(commissionData.maxCommissionReference === "Euro"){
                              onBlurCurrencyFormatter(e)
                            }
                            else{
                              onBlurPercentageFormatter(e)
                            }
                          }}
                          required
                        />
                      </PTextFieldWrapper>
                    </PFlexItem>
                    &ensp;
                    <PFlexItem style={{ width: "40%" }}>
                      <PSelectWrapper label={`${t("Reference")}`}>
                        <select
                          name="some-name"
                          disabled={edit ? false : true}
                          required
                          onChange={(e) =>
                            handleReference(e.target.value, "max", 0)
                          }
                        >
                          <option
                            value="Euro"
                            selected={
                              commissionData.maxCommissionReference === "Euro"
                                ? true
                                : false
                            }
                          >
                            {t("Euro")}
                          </option>
                          <option
                            value="GrossProfit"
                            selected={
                              commissionData.maxCommissionReference ===
                              "GrossProfit"
                                ? true
                                : false
                            }
                          >
                            {t("Gross Profit")}
                          </option>
                          <option
                            value="NetTurnover"
                            selected={
                              commissionData.maxCommissionReference ===
                              "NetTurnover"
                                ? true
                                : false
                            }
                          >
                            {t("Net Turnover")}
                          </option>
                        </select>
                      </PSelectWrapper>
                    </PFlexItem>
                  </PFlex>
                )}
                {/* {rangeInputError2 && (
                    <span style={{ color: "#D5001B" }}>
                    <PorscheFont>
                      {t(
                          "Please enter a valid value."
                      )}
                    </PorscheFont>
                  </span>
                )} */}
              </PFlexItem>
            </PFlex>
          </PFlexItem>
          <PFlexItem flex="equal">
            <PFlex direction="column">
              <PFlexItem>
                <PCheckboxWrapper
                  label={`${t("Commission for negative profit")}`}
                  hideLabel={{ base: true, l: false }}
                  onClick={handleClickNoProfit}
                >
                  <input
                    aria-autocomplete="none"
                    autoComplete="off"
                    ref={inputNoProfit}
                    type="checkbox"
                    name="some-name"
                    disabled={edit ? false : true}
                    checked={commissionData.noProfitCommission}
                  />
                </PCheckboxWrapper>
              </PFlexItem>
              &nbsp; <br></br>
              <PFlexItem>
                <PDivider
                  color="neutral-contrast-low"
                  style={{ width: "310px" }}
                ></PDivider>
                <br></br>
              </PFlexItem>
              <PFlexItem>
                {commissionData.noProfitCommission && (
                  <PFlex direction={"row"}>
                    <PFlexItem style={{ width: "40%" }}>
                      <PTextFieldWrapper
                        label={`${t("Value")}`}
                        id={`${commissions.commissionScalingCategoryType+'no'}`}

                        unit={
                          commissionData.noProfitCommissionReference === "Euro"
                            ? "€"
                            : "%"
                        }
                        state={errorState && errorState[`${commissions.commissionScalingCategoryType+'no'}`]===true? 'error':'none'}
                        unitPosition="suffix"
                      >
                        <input
                          aria-autocomplete="none"
                          autoComplete="off"
                          type="text"
                          name="some-name"
                          id={`${commissions.commissionScalingCategoryType+'no-value'}`}
                          onBlur={(e)=>{
                            if(commissionData.noProfitCommissionReference === "Euro"){
                              onBlurCurrencyFormatter(e)
                            }
                            else{
                              onBlurPercentageFormatter(e)
                            }
                          }}
                          disabled={edit ? false : true}
                          defaultValue={(
                            commissionData.noProfitCommissionValue
                              ? formatCurrencyWithNoUnit(commissionData.noProfitCommissionValue) 
                              : 0.0
                          )}
                          onChange={(e) => handleValue(e.target.value, "no", 0)}
                          required
                        />
                      </PTextFieldWrapper>
                    </PFlexItem>
                    &ensp;
                    <PFlexItem style={{ width: "40%" }}>
                      <PSelectWrapper label={`${t("Reference")}`}>
                        <select
                          name={`scalingNoProfit`}
                          disabled={edit ? false : true}
                          required
                          onChange={(e) =>
                            handleReference(e.target.value, "no", 0)
                          }
                        >
                          <option
                            key={`scalingNoProfit1`}
                            value="Euro"
                            selected={
                              commissionData.noProfitCommissionReference ===
                              "Euro"
                                ? true
                                : false
                            }
                          >
                            {t("Euro")}
                          </option>
                          <option
                            key={`scalingNoProfit2`}
                            value="GrossProfit"
                            selected={
                              commissionData.noProfitCommissionReference ===
                              "GrossProfit"
                                ? true
                                : false
                            }
                          >
                            {t("Gross Profit")}
                          </option>
                          <option
                            key={`scalingNoProfit3`}
                            value="NetTurnover"
                            selected={
                              commissionData.noProfitCommissionReference ===
                              "NetTurnover"
                                ? true
                                : false
                            }
                          >
                            {t("Net Turnover")}
                          </option>
                        </select>
                      </PSelectWrapper>
                    </PFlexItem>
                  </PFlex>
                )}
                {/* {rangeInputError3 && (
                    <span style={{ color: "#D5001B" }}>
                    <PorscheFont>
                      {t(
                          "Please enter a valid value."
                      )}
                    </PorscheFont>
                  </span>
                )} */}
              </PFlexItem>
            </PFlex>
          </PFlexItem>
        </PFlex>
        <br></br>
        <br></br>
        <span className="table-column-header">
          <PorscheFont>{t("Vehicle commission")}</PorscheFont>
        </span>
        <PDivider
          color="neutral-contrast-high"
          style={{ width: "50%", marginTop: "10px" }}
        ></PDivider>
        <br></br>
        <div>
          <Stack direction="row">
            <div>
              <PSelectWrapper
                style={{ width: "160px" }}
                label={`${t("Variable")}`}
              >
                <select
                  name={`scalingVariable`}
                  disabled={edit ? false : true}
                  onChange={(e) => handleVariable(e.target.value)}
                  required
                >
                  {/* <option value="Select a value"  selected={commissionData.variable === "Select a value" ? true : false}>Select a value</option>
                <option value="Discount" selected={commissionData.variable === "Discount" ? true : false}>Discount</option>
                <option value="StandingDays" selected={commissionData.variable === "StandingDays" ? true : false}>Standing Days</option> */}
                  <option
                    key={`scalingVariable1`}
                    value="Select a value"
                    selected={variable === "Select a value" ? true : false}
                  >
                    {t("Select a value")}
                  </option>
                  {commissionData.commissionScalingCategoryType.startsWith(
                    "NewCar"
                  ) && (
                    <option
                      key={`scalingVariable2`}
                      value="Discount"
                      selected={variable === "Discount" ? true : false}
                    >
                      {t("Discount")}
                    </option>
                  )}
                  {commissionData.commissionScalingCategoryType.startsWith(
                    "DemoCar"
                  ) && (
                    <>
                      <option
                        key={`scalingVariable2`}
                        value="Discount"
                        selected={variable === "Discount" ? true : false}
                      >
                        {t("Discount")}
                      </option>
                      <option
                        key={`scalingVariable3`}
                        value="StandingDays"
                        selected={variable === "StandingDays" ? true : false}
                      >
                        {t("Standing Days")}
                      </option>
                    </>
                  )}
                  {commissionData.commissionScalingCategoryType.startsWith(
                    "UsedCar"
                  ) && (
                    <option
                      key={`scalingVariable3`}
                      value="StandingDays"
                      selected={variable === "StandingDays" ? true : false}
                    >
                      {t("Standing Days")}
                    </option>
                  )}
                </select>
              </PSelectWrapper>
            </div>
            &ensp;
            <div style={{ paddingLeft: "72px" }}>
              {!commissionData.payOut2 && (
                <PButton
                  variant="tertiary"
                  icon="add"
                  disabled={edit ? false : true}
                  style={{ paddingTop: "28px", marginLeft: "-30%" }}
                  onClick={handleClickPayOut}
                >
                  {t("Add 2nd payout")}
                </PButton>
              )}
              {commissionData.payOut2 && (
                <PButton
                  variant="tertiary"
                  icon="close"
                  disabled={edit ? false : true}
                  style={{ paddingTop: "28px", marginLeft: "-30%" }}
                  onClick={handleClickPayOut}
                >
                  {t("Remove 2nd payout")}
                </PButton>
              )}
            </div>
          </Stack>
        </div>
        <br></br>
        <PFlex>
          <PFlexItem style={{ width: "20%" }}>
            <PorscheFont>
              {t("From")} <span style={{ color: "#D5001B" }}>*</span>
            </PorscheFont>
          </PFlexItem>
          <PFlexItem style={{ width: "20%" }}>
            <PorscheFont>
              {t("To")} <span style={{ color: "#D5001B" }}>*</span>
            </PorscheFont>
          </PFlexItem>
          <PFlexItem style={{ width: "20%" }}>
            <PorscheFont>
              {t("Value")} <span style={{ color: "#D5001B" }}>*</span>
            </PorscheFont>
          </PFlexItem>
          <PFlexItem style={{ width: "28%" }}>
            <PorscheFont>
              {t("Reference")} <span style={{ color: "#D5001B" }}>*</span>
            </PorscheFont>
          </PFlexItem>
          {!commissionData.payOut2 && (
            <PFlexItem style={{ width: "20%" }}></PFlexItem>
          )}
          {commissionData.payOut2 ? (
            <>
              <PFlexItem style={{ width: "20%" }}>
                <PorscheFont>
                  {t("Value ")}
                  <span style={{ color: "#D5001B" }}>*</span>
                </PorscheFont>
              </PFlexItem>
              <PFlexItem style={{ width: "28%" }}>
                <PorscheFont>
                  {t("Reference")} <span style={{ color: "#D5001B" }}>*</span>
                </PorscheFont>
              </PFlexItem>

              <PFlexItem style={{ width: "20%" }}></PFlexItem>
            </>
          ) : (
            // Creates the skeleton of the Pflex
            <>
              <PFlexItem style={{ width: "20%" }}> </PFlexItem>
              <PFlexItem style={{ width: "28%" }}> </PFlexItem>
              <PFlexItem style={{ width: "20%" }}></PFlexItem>
            </>
          )}
        </PFlex>
        {commissionData.vehicleCommissions.map((x, i) => {
          return (
            <>
              <PFlex>
                <PFlexItem style={{ width: "20%" }}>
                  {variable === "Discount" ? (
                    <PTextFieldWrapper
                      id={`from${i}`}
                      unit={"%"}
                      unitPosition="suffix"
                      style={{ marginRight: "16px" }}
                    >
                      <input
                        aria-autocomplete="none"
                        autoComplete="off"
                        type="text"
                        name={`ifrom${i}`}
                        disabled={edit ? false : true}
                        value={formatCurrencyWithNoUnit(x.fromValue) }
                        required
                        readOnly
                      />
                    </PTextFieldWrapper>
                  ) : (
                    <PTextFieldWrapper
                      id={`from${i}`}
                      style={{ marginRight: "16px" }}
                    >
                      <input
                        aria-autocomplete="none"
                        autoComplete="off"
                        type="text"
                        name={`ifrom${i}`}
                        disabled={edit ? false : true}
                        value={  variable === "Discount" ? formatCurrencyWithNoUnit(x.fromValue):x.fromValue }
                        required
                        readOnly
                      />
                    </PTextFieldWrapper>
                  )}
                </PFlexItem>
                <PFlexItem style={{ width: "20%" }}>
                  <PTextFieldWrapper
                    unit={variable === "Discount" ? "%" : ""}
                    unitPosition="suffix"
                    style={{ marginRight: "16px" }}
                    id={`${commissions.commissionScalingCategoryType+`vcvar${i}`}`}
                    state={errorState && errorState[`${commissions.commissionScalingCategoryType+`vcvar${i}`}`]===true? 'error':'none'}

                  >
                    <input
                      aria-autocomplete="none"
                      autoComplete="off"
                      type="text"
                      name={`tovalue${i}`}
                      disabled={edit ? false : true}
                      defaultValue={ variable === "Discount" ? formatCurrencyWithNoUnit(x.toValue):x.toValue }
                      onChange={(e) =>
                        handleClickTo(i, e.target.value)

                        // handleClickTo(i, parseFloat(e.target.value))
                      }
                      onBlur={(e)=>{
                      variable === "Discount"  &&    onBlurPercentageFormatter(e)
                      }}
                      required
                      // min="0"
                      // max="100"
                      // step="0.01"
                    />
                  </PTextFieldWrapper>
                </PFlexItem>
                <PFlexItem style={{ width: "20%" }}>
                  <PTextFieldWrapper
                    unit={x.payoutRefence1 === "Euro" ? "€" : "%"}
                    unitPosition="suffix"
                    style={{ marginRight: "16px" }}
                    id={`${commissions.commissionScalingCategoryType+'vcvarpv1'+i}`}
                    state={errorState && errorState[`${commissions.commissionScalingCategoryType+'vcvarpv1'+i}`]===true? 'error':'none'}


                  >
                    <input
                      aria-autocomplete="none"
                      autoComplete="off"
                      type="text"
                      id={`${commissions.commissionScalingCategoryType+'vcvarpv1-value'+i}`}
                      name={`payoutValue1${i}`}
                      disabled={edit ? false : true}
                      defaultValue={formatCurrencyWithNoUnit(x.payoutValue1) }
                      onChange={(e) => handleValue(e.target.value, "val1", i)}
                      onBlur={(e)=>{
                              if(commissionData.vehicleCommissions[i].payoutRefence1 === "Euro"){
                                onBlurCurrencyFormatter(e)
                              }
                              else{
                                onBlurPercentageFormatter(e)
                              }
                            }}
                      required
                    />
                  </PTextFieldWrapper>
                </PFlexItem>
                <PFlexItem style={{ width: "28%" }}>
                  <PSelectWrapper>
                    <select
                      name="some-name"
                      disabled={edit ? false : true}
                      required
                      onChange={(e) =>
                        handleReference(e.target.value, "val", i)
                      }
                    >
                      <option
                        value="Euro"
                        selected={x.payoutRefence1 === "Euro" ? true : false}
                      >

                        {t("Euro")}
                      </option>
                      <option
                        value="GrossProfit"
                        selected={
                          x.payoutRefence1 === "GrossProfit" ? true : false
                        }
                      >
                        {t("Gross Profit")}
                      </option>
                      <option
                        value="NetTurnover"
                        selected={
                          x.payoutRefence1 === "NetTurnover" ? true : false
                        }
                      >
                        {t("Net Turnover")}
                      </option>
                    </select>
                  </PSelectWrapper>
                </PFlexItem>
                {!commissionData.payOut2 && (
                  <PFlexItem
                    style={{
                      width: "20%",
                      paddingLeft: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {commissionData.vehicleCommissions &&
                      commissionData.vehicleCommissions.length - 1 === i &&
                      i !== 0 && (
                        <span>
                          <CustomDelete
                            partId={i}
                            message={t("Delete this row ?")}
                            handleDelete={(partId) => handleDeleteRow(partId)}
                            disabled={edit ? false : true}
                          />
                          {/* <PIcon name="delete" aria={{ 'aria-label': 'Delete icon' }}  onClick={() => handleDeleteRow(i)} /> */}
                        </span>
                      )}
                  </PFlexItem>
                )}
                &nbsp;&nbsp;&nbsp;
                {commissionData.payOut2 ? (
                  <>
                    {commissionData.displayLabel === "Demo Car (Pre-sold)" && (
                      <PFlexItem style={{ width: "5%" }}> + </PFlexItem>
                    )}
                    <PFlexItem style={{ width: "20%" }}>
                      <PTextFieldWrapper
                        unit={x.payoutRefence2 === "Euro" ? "€" : "%"}
                        unitPosition="suffix"
                        style={{ marginRight: "16px" }}
                        id={`${commissions.commissionScalingCategoryType+'vcvarval2'+i}`}
                        state={errorState && errorState[`${commissions.commissionScalingCategoryType+'vcvarval2'+i}`]===true? 'error':'none'}

                      >
                        <input
                          aria-autocomplete="none"
                          autoComplete="off"
                          type="text"
                          name={`payoutValue2${i}`}
                          id={`${commissions.commissionScalingCategoryType+'vcvarval2-value'+i}`}
                          disabled={edit ? false : true}
                          defaultValue={x.payoutValue2 ? formatCurrencyWithNoUnit(x.payoutValue2): '0,00' }
                          onChange={(e) =>
                            handleValue(e.target.value, "val2", i)
                          }
                          onBlur={(e)=>{
                            if(commissionData.vehicleCommissions[i].payoutRefence1 === "Euro"){
                              onBlurCurrencyFormatter(e)
                            }
                            else{
                              onBlurPercentageFormatter(e)
                            }
                          }}
                          required
                        />
                      </PTextFieldWrapper>
                    </PFlexItem>
                    <PFlexItem style={{ width: "28%" }}>
                      <PSelectWrapper>
                        <select
                          name="some-name"
                          disabled={edit ? false : true}
                          required
                          onChange={(e) =>
                            handleReference(e.target.value, "ref", i)
                          }
                        >
                          <option
                            value="Euro"
                            selected={
                              x.payoutRefence2 === "Euro" ? true : false
                            }
                          >
                            {t("Euro")}
                          </option>
                          <option
                            value="GrossProfit"
                            selected={
                              x.payoutRefence2 === "GrossProfit" ? true : false
                            }
                          >
                            {t("Gross Profit")}
                          </option>
                          <option
                            value="NetTurnover"
                            selected={
                              x.payoutRefence2 === "NetTurnover" ? true : false
                            }
                          >
                            {t("Net Turnover")}
                          </option>
                        </select>
                      </PSelectWrapper>
                    </PFlexItem>
                    <PFlexItem
                      style={{
                        width: "20%",
                        paddingLeft: "20px",
                        marginTop: "8px",
                      }}
                    >
                      {commissionData.vehicleCommissions &&
                        commissionData.vehicleCommissions.length - 1 === i &&
                        i !== 0 && (
                          <span>
                            <CustomDelete
                              partId={i}
                              message={t("Delete this row ?")}
                              handleDelete={(partId) => handleDeleteRow(partId)}
                              disabled={edit ? false : true}
                            />
                            {/* <PIcon name="delete" aria={{ 'aria-label': 'Delete icon' }} onClick={() => handleDeleteRow(i)}/> */}
                          </span>
                        )}
                    </PFlexItem>
                  </>
                ) : (
                  // Creates the skeleton of the flex
                  <>
                    <PFlexItem style={{ width: "20%" }}></PFlexItem>
                    <PFlexItem style={{ width: "28%" }}> </PFlexItem>
                    <PFlexItem style={{ width: "20%" }}> </PFlexItem>
                  </>
                )}
              </PFlex>
              <br></br>
            </>
          );
        })}

        {rangeInputError && (
            <span style={{ color: "#D5001B" }}>
            <PorscheFont>
              {t(
                  "Please enter a valid value."
              )}
            </PorscheFont>
          </span>
        )}
        {rangeError && (
          <span style={{ color: "#D5001B" }}>
            <PorscheFont>
              {t(
                "Please enter a valid value and make sure vehicle commission rules are defined for 0,00% - 100,00% discount or 0 - 9.999 standing days."
              )}
            </PorscheFont>
          </span>
        )}
        {isAddButtonActive() && (
          <PButton
            variant="secondary"
            icon="add"
            disabled={edit ? false : true}
            onClick={handleAddRow}
          >
            {t("Add rule")}
          </PButton>
         )} 
         {/* {!hideRow && ( */}
          {/* <PButton
            variant="secondary"
            icon="add"
            disabled={isAddButtonActive() ? false : true}
            onClick={handleAddRow}
          >
            {t("Add rule")}
          </PButton> */}
        {/* )} */}
      </div>
    </>
  );
};

