import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
  PButton,
  PButtonPure, PDivider, PFlex, PFlexItem, PIcon, PModal, PPopover,
  PSelectWrapper, PText, PTextareaWrapper, PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';
import { ApprovalConfigurationStep } from '../../../interfaces/adminApproval/ApprovalConfiguration';
import { ApprovalStepDTO } from '../../../interfaces/Approvals';
import { UserDetailsDTO } from '../../../interfaces/User';
import {
  changeStatusOrderApproval, dcschangeStatusOrderApproval
} from '../../../services/data/Helpers';
import { Logbook } from './Logbook';
import { type } from '../../../services/Constants';
import CustomSnakebar from '../../common/CustomSnakebar';
import { Constant } from '../../../configs/Constants';

interface Props {
  orderId: number;
  discountIndex: number;
  approval: ApprovalConfigurationStep;
  orderApproval: ApprovalStepDTO;
  edit: boolean;
  label: string;
  userDetails: UserDetailsDTO;
  handleMessage: (value: boolean, msg: string) => void;
  handleAprrovalStepAssignee: (
    approval: ApprovalStepDTO, 
    configuration: ApprovalConfigurationStep, 
    value: string,
    newApprover: boolean
  ) => void;
  enableNextStep: boolean;
  handleApproved: (approval: ApprovalStepDTO) => void;
  handleNewAssignee: (step: ApprovalConfigurationStep, value: string, index: number) => void;
  handleOtherSubject: (value: string, step: string) => void;
  refreshOnSuccess: () => void;
  handleClose: (reload: boolean) => void;
}

export const OrderApprovalRow = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { 
    orderId, 
    discountIndex, 
    approval, 
    orderApproval, 
    edit, 
    label, 
    userDetails, 
    handleAprrovalStepAssignee, 
    enableNextStep,
    handleApproved,
    handleNewAssignee,
    handleOtherSubject,
    refreshOnSuccess,
    handleClose
  } = props;
  const [toggleApproval, setToggleApproval] = useState<boolean>(false);
  const [toggleReassign, setToggleReassign] = useState<boolean>(false);
  const [toggleReject, setToggleReject] = useState<boolean>(false);
  const [isLogBookOpen, setIsLogBookOpen] = useState<boolean>(false);
  const [toggleOnApproved, setToggleOnApproved] = useState<boolean>(false);
  const [toggleApprovalMessage, setToggleApprovalMessage] = useState<boolean>(false);
  const [orderApprovalPut, setOrderApprovalPut] = useState<ApprovalStepDTO>({} as ApprovalStepDTO)
  const [isValidReassign, setIsValidReassign] = useState(false);
  const [isValidRejected, setIsValidRejected] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [subjectOpen, setSubjectOpen] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [subject, setSubject] = useState("");
  const [reassignReason, setReassignReason] = useState("");
  const orderContext = useContext(OrderContext);
  const order = orderContext.getCtxOrderDetails();
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  
  useEffect(() => {
    
    if(orderApproval.id !== undefined) {
      orderApprovalPut.id = orderApproval.id;
      orderApprovalPut.createdBy = {id: orderApproval.createdBy.id}
      if(approval && approval.id) {
          orderApprovalPut.configurationStep = {id: approval.id};
      }
      orderApprovalPut.approver = {id: orderApproval.approver.id, ppnName: orderApproval.approver.ppnName};
      orderApprovalPut.approvalStep = orderApproval.approvalStep;
      orderApprovalPut.isEnabled = orderApproval.isEnabled;
      orderApprovalPut.action = orderApproval.action;
      orderApprovalPut.order = {id: orderId};
      if(orderApproval.otherSubject && orderApproval.otherSubject.length > 0) {
        setSubject(orderApproval.otherSubject);
        setSubjectOpen(true);
      }
      setOrderApprovalPut(orderApprovalPut);
    } else {
      setIsNew(true);
    }
  }, []);

  const handleApproval = () => {
    setToggleReject(false);
    setToggleReassign(false);
    setToggleApproval(!toggleApproval);
  };

  const handleApprovedComment = (value: string) => {
    orderApprovalPut.approvedComment = value;
    setOrderApprovalPut(orderApprovalPut);
  };

  const handleSaveApproval = () => {
    
    try {
        const init = async () => {
          if(approval.id) {
            orderApprovalPut.configurationStep = {id: approval.id}
          }
          orderApprovalPut.action = "Approved";
          orderApprovalPut.order = {id: orderId};
          setToggleApproval(false);
          setShowApproved(true);
          handleApproved(orderApprovalPut);
        };
        init();
    } catch (e) {
     
    }
  };

  const handleCancelApproval = () => {
    setToggleApproval(false);
  };

  const handleReassign = () => {
    setToggleReject(false);
    setToggleApproval(false);
    setToggleReassign(!toggleReassign);
  };

  const  handleReassignAssignee = (value: string) => {
    if(approval.approvers) {
      const user = approval.approvers.filter(x => x.id === parseInt(value));
      if(user.length > 0) {
        orderApprovalPut.approver = { id: parseInt(value), ppnName: user[0].ppnName };
        setOrderApprovalPut(orderApprovalPut);
        setIsValidReassign(validateReassign(orderApprovalPut));
      }
    }
  };
  
  const handleReassignComments = (value: string) => {
    if(value.length > 0) {
      orderApprovalPut.reassignedReason = value;
      setReassignReason(value);
      setOrderApprovalPut(orderApprovalPut);
      setIsValidReassign(validateReassign(orderApprovalPut));
    } else {
      setIsValidReassign(false);
    }
    
  };

  const validateReassign = (obj: ApprovalStepDTO): boolean => {
    if(obj.reassignedReason === undefined || obj.reassignedReason === null) return false;
    if(obj.reassignedReason.length === 0) return false;
    if(obj.approver.id === 0) return false; 

    return true;
  }

  const handleCancelReassign = () => {
    setToggleReassign(false);
  };

  const handleSaveReassign = () => {
        const init = async () => {
          if(approval.id) {
            orderApprovalPut.configurationStep = {id: approval.id}
          }
          orderApprovalPut.action = "Reassigned";
          orderApprovalPut.reAssigned = true;
          orderApprovalPut.reAssignedBy = {
            id: userDetails.id
          };
          if(reassignReason.length > 0) {
            orderApprovalPut.reassignedReason = reassignReason;
          }
          const apiResponse = await dcschangeStatusOrderApproval(orderApprovalPut)
          if (apiResponse.status===type.SUCCESS) {
            setToggleReassign(false);
            refreshOnSuccess();
            setTimeout(() => handleClose(false), 1500);
          
          } else {
            orderApprovalPut.action = orderApproval.action;
            orderApprovalPut.reAssigned = false;
            orderApprovalPut.reAssignedBy = undefined;
            orderApprovalPut.reassignedReason = undefined;
          }
          if(apiResponse.status===type.ERROR){
            setShowAlert(Constant.Error)
            setAlertMessage(apiResponse.response) 
          }
        };
        init();
   
  };

  const handleReject = () => {
    setToggleApproval(false);
    setToggleReassign(false);
    setToggleReject(!toggleReject);
  };

  const handleRejectedComment = (value: string) => {
    if (value.length > 0) {
        orderApprovalPut.rejectedComment = value;
        setOrderApprovalPut(orderApprovalPut);
        setIsValidRejected(true);
    }
  };
  
  const handleSaveReject = () => {
    try {
        const init = async () => {
          
          if(approval.id) {
            orderApprovalPut.configurationStep = {id: approval.id}
          }
          orderApprovalPut.action = "Rejected";
          //orderApprovalPut.approver = {};

          const apiResponse = await dcschangeStatusOrderApproval(orderApprovalPut)
          if (apiResponse.status===type.SUCCESS) {
            refreshOnSuccess();
            setTimeout(() => {handleClose(false); window.close()}, 1500);

          } else {
            orderApprovalPut.action = orderApproval.action;
            orderApprovalPut.rejectedComment = undefined;
            setOrderApprovalPut(orderApprovalPut);
          }
          if(apiResponse.status===type.ERROR){
            setShowAlert(Constant.Error)
            setAlertMessage(apiResponse.response) 
          }
          setToggleReject(false);
        };
        init();
    } catch (e) {
     
    }
  };
  
  const handleCancelReject = () => {
    setToggleReject(false);
  };

  const handleLogbook = () => {
    setIsLogBookOpen(!isLogBookOpen);
  };

  const handleLogbookClose = () => {
    setIsLogBookOpen(false);
  };

  const getIcon = (step: string) => {
    let ret = <></>;
    switch(step) {
      case "1st Order Check": 
        ret = (<img src={require("../../../assets/images/approvals/1stordercheck.png")} alt=""/>);
        break;
      case "2nd Order Check": 
        ret = (<img src={require("../../../assets/images/approvals/2ndordercheck.png")} alt=""/>);
        break;
      case "Export": 
        ret = (<img src={require("../../../assets/images/approvals/Export.png")} alt=""/>);
        break;
      case "Discount": 
        ret = (<img src={require("../../../assets/images/approvals/Discount.png")} alt=""/>);
        break;
      case "PEP match": 
        ret = (<img src={require("../../../assets/images/approvals/PEPmatch.png")} alt=""/>);
        break;
      case "Others (Optional)": 
        ret = (<img src={require("../../../assets/images/approvals/Others.png")} alt=""/>);
        break;
    }
    return ret;
  }

  const handleApprover = (value: string) => {
    if(approval.approvalStep === "Others" && parseInt(value) > 0) {
      setSubjectOpen(true);
    } else {
      setSubjectOpen(false);
    }

    if(parseInt(value) === 0) setSubjectOpen(false);

    if(enableNextStep) {
      
      props.handleNewAssignee(approval, value, discountIndex);
    } else {
      // Changed to handle others step
      //props.handleAprrovalStepAssignee(orderApproval, approval, value, approval.approvalStep === "Others" ? false: isNew);
      props.handleAprrovalStepAssignee(orderApproval, approval, value, approval.approvalStep === "Others" ? false: isNew);
    }
  }

  const handleCustomAlertClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };
  return (
    <>
      <PFlex justifyContent={"space-between"}>
        <PFlexItem style={{ width: "30%" }}>
          <PFlex>
            <PFlexItem>
              <PDivider
                orientation="vertical"
                style={{
                  marginTop: "10px",
                  width: "5px",
                  height: "50px",
                  backgroundColor: `${orderApproval.action === "Approved" ? "#1E7A53" : (orderApproval.action === "Rejected" ? "#D5001B" : "#C8CACB")}`,
                }}
              />
            </PFlexItem>
            &nbsp;&nbsp;
            <PFlexItem style={{ marginTop: "20px", width: "" }}>
              {getIcon(label)}
            </PFlexItem>
            &nbsp;&nbsp;
            <PFlexItem style={{ marginTop: "20px" }}>
              <PorscheFont className="tn-text-14-600">
                {t(label)}<span style={{ color: "#D5001B" }}>{`${approval?.approvalStep === "Others" ? "" : (label.length > 0 ? " *" : "")}`}</span>
              </PorscheFont>
            </PFlexItem>
          </PFlex>
        </PFlexItem>
        <PFlexItem style={{ marginTop: "10px", width: "100%", marginBottom: "10px" }} >
          <PFlexItem>
            {((!edit && !enableNextStep) || orderApproval.action === "Approved" ) && (
              <div className="user-assigned" style={{width: "40%"}}>
                <Stack direction="row" >
                  { orderApproval.reAssigned && (
                    <PIcon name="switch" style={{ paddingLeft: "4px", marginTop: "5px" }} />
                  )}
                  <PorscheFont className="tn-text-14-600" style={{ padding: "5px", color: "#909090" }}>
                    {orderApproval.approver?.ppnName ? orderApproval.approver.ppnName : ""}
                  </PorscheFont>
                </Stack>
              </div>
            )}

            {/* {(edit && !enableNextStep) && ( */}
            {(edit || (!edit && enableNextStep)) && orderApproval.action !== "Approved" && (
              <PSelectWrapper hideLabel={false} style={{width:"40%"}}>
                <select 
                  name={`s${label}`} 
                  defaultValue={orderApproval.approver?.id ? orderApproval.approver.id : 0} 
                  //value={orderApproval.approver?.id ? orderApproval.approver.id : 0}
                  onChange={(e) => handleApprover(e.target.value) }
                >
                <option value={0} selected={orderApproval.approver?.id ? false : true}>{t("Assign to user")}</option>
                {approval && approval.approvers && approval.approvers.map((user, index) => {
                  //if(user.id !== userDetails.id) {
                  if(user.id !== order.orderCreator.id) {
                    return(
                    <option key={index} value={user.id} selected={orderApproval.approver?.id && orderApproval.approver.id === user.id ? true : false}>
                        {user.ppnName}
                    </option>
                    );
                  }
                })}
                </select>
              </PSelectWrapper>        
            )}  
          </PFlexItem>
          {subjectOpen && edit && orderApproval.action !== "Approved" && (
            <Stack direction="row" style={{ marginLeft: "18px", marginRight: "-240px" }}>
                <PorscheFont className="tn-text-14-600" style={{ marginTop: "10px", marginLeft: "-90px" }}>{t("Subject")}</PorscheFont><span style={{ color: "#D5001B", marginTop: "10px" }}>*</span> &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                <div style={{ marginTop: "4px", width: "434px" }}>
                  <PTextFieldWrapper >
                    <input 
                      type="text" 
                      name="other"   
                      maxLength={50} 
                      defaultValue={subject}
                      onChange={(e) => handleOtherSubject(e.target.value, approval.approvalStep)} 
                      readOnly= {edit ? false : true}
                    />
                  </PTextFieldWrapper>
                </div>&nbsp;
                <PText>
                  <PPopover direction="top" style={{ marginTop: "12px"}} aria={{ 'aria-label': 'Some more descriptive label' }}>{t("This approval step is used for individual cases, please summarize the context.")}</PPopover>
                </PText>
            </Stack>
          )}
        </PFlexItem>
        <PFlexItem style={{ marginTop: "10px", width: "20%", marginBottom: "10px" }} >
          <PFlex justifyContent={"flex-end"}>
            <PFlexItem alignSelf={"center"}>
            {/* Based on the condition these buttons have to be showed Approving flow (when user = user assigned) then approved
              Reassign flow (when user = user assigned) when reassigned
              Reject flow (when user = user assigned) when rejected 
            */}
              { orderApproval.approver && userDetails.id === orderApproval.approver.id && ((orderApproval.action === "Assigned" || orderApproval.action === "Reassigned") && !showApproved) && (
                <>
                  {/* {!toggleOnApproved && ( */}
                  <PFlex>
                    <PFlexItem>
                      <div className="draft-button" style={ toggleApproval ? { background: "#1E7A53" } : { background: "#FFFFFF" } } >
                        <PIcon
                          name="success"
                          color={`${
                            toggleApproval
                              ? "neutral-contrast-low"
                              : "notification-success"
                          }`}
                          aria={{ "aria-label": "Success icon" }}
                          onClick={handleApproval}
                        />
                        {toggleApproval && (
                          <div
                            className="approved-container"
                            style={{
                              height: "350px",
                              width: "403px",
                              marginTop: "8px",
                              marginLeft: "-372px",
                            }}
                          >
                            <h4>
                              <PorscheFont className="tn-text-16-700"> {t("Approve")}</PorscheFont>
                            </h4>
                            <br></br>
                            <div className="container">
                              <PTextareaWrapper label={`${t('Comment')}`}>
                                <textarea 
                                  aria-autocomplete='none' 
                                  autoComplete='off'
                                  onChange={(e) =>
                                    handleApprovedComment(e.target.value)
                                  }
                                  maxLength={500}
                                  name="approvedComment"
                                />
                              </PTextareaWrapper>
                            </div>
                            <br></br>
                            <PButton
                              icon="success"
                              onClick={handleSaveApproval}
                            >
                              {t("Approve")}
                            </PButton>
                            &nbsp;&nbsp;&nbsp;
                            <PButton
                              onClick={handleCancelApproval}
                              type="button"
                              variant="tertiary"
                              icon="close"
                            >
                              {t("Cancel")}
                            </PButton>
                          </div>
                        )}
                      </div>
                    </PFlexItem>
                    &nbsp;
                    <PFlexItem>
                      <div
                        className="draft-button"
                        style={
                          toggleReassign
                            ? { background: "#FF9B00" }
                            : { background: "#FFFFFF" }
                        }
                      >
                        <PIcon
                          name="switch"
                          color={`${
                            toggleReassign
                              ? "neutral-contrast-low"
                              : "notification-warning"
                          }`}
                          aria={{ "aria-label": "Switch icon" }}
                          onClick={handleReassign}
                        />
                        {toggleReassign && (
                          <div
                            className="actions-container"
                            style={{
                              height: "430px",
                              width: "381px",
                              marginTop: "8px",
                              marginLeft: "-350px",
                            }}
                          >
                            <h4>
                              <PorscheFont className="tn-text-16-700">{t("Reassign")}</PorscheFont>
                            </h4>
                            <br></br>
                            <PSelectWrapper className="task-h" label={`${t("Assign to")}`}>
                              <select name={`s${label}`} onChange={(e) => handleReassignAssignee(e.target.value)} >
                                <option value={0}>{t("Assign to user")}</option>
                                {approval && approval.approvers && approval.approvers.map((user, index) => {
                                  //if(user.id === orderApproval.approver.id) return <></>;
                                  if(user.id === order.orderCreator.id) return <></>;

                                  return(
                                  <option key={index} value={user.id} selected={user.id === orderApproval.approver.id ? true : false}>
                                      {user.ppnName}
                                  </option>
                                  );
                                })}
                              </select>
                            </PSelectWrapper>      
                            <br></br>
                            <PTextareaWrapper label={`${t("Comment")}`}>
                              <textarea
                                name="reassignComment"
                                required
                                onChange={(e) =>
                                  handleReassignComments(e.target.value)
                                }
                                maxLength={500}
                              ></textarea>
                            </PTextareaWrapper>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              style={{ marginTop: "8px" }}
                            >
                              <PButton
                                icon="switch"
                                disabled={!isValidReassign}
                                onClick={handleSaveReassign}
                                // Reassign is clicked then coresponding row must
                                // update with the assigned user name with reasign arrows and a
                                // arrow at last at the row redirecting to logbook
                              >
                                {t("Reassign")}
                              </PButton>
                              &nbsp;&nbsp;
                              <PButton
                                onClick={handleCancelReassign}
                                type="button"
                                variant="tertiary"
                                icon="close"
                              >
                                {t("Cancel")}
                              </PButton>
                            </Stack>
                          </div>
                        )}
                      </div>
                    </PFlexItem>
                    &nbsp;
                    <PFlexItem>
                      <div
                        className="draft-button"
                        style={
                          toggleReject
                            ? { background: "#D5001B" }
                            : { background: "#FFFFFF" }
                        }
                      >
                        <PIcon
                          name="disable"
                          color={`${toggleReject ? "neutral-contrast-low" : "brand"}`}
                          aria={{ "aria-label": "Disable icon" }}
                          onClick={handleReject}
                        />
                        {toggleReject && (
                          <div
                            className="actions-container"
                            style={{
                              height: "350px",
                              width: "381px",
                              marginTop: "8px",
                              marginLeft: "-350px",
                            }}
                          >
                            <h4>
                              <PorscheFont className="tn-text-16-700">{t("Reject")}</PorscheFont>
                            </h4>
                            <br></br>

                            <PTextareaWrapper label={`${t("Comment")}`}>
                              <textarea
                                name="rejectComment"
                                required
                                onChange={(e) => handleRejectedComment(e.target.value)}
                                maxLength={500}
                              ></textarea>
                            </PTextareaWrapper>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              style={{ marginTop: "8px" }}
                            >
                              <PButton
                                icon="disable"
                                disabled={!isValidRejected}
                                onClick={handleSaveReject}
                              >
                                {t("Reject")}
                              </PButton>
                              &nbsp;&nbsp;
                              <PButton
                                onClick={handleCancelReject}
                                type="button"
                                variant="tertiary"
                                icon="close"
                              >
                                {t("Cancel")}
                              </PButton>
                            </Stack>
                          </div>
                        )}
                      </div>
                    </PFlexItem>
                    &nbsp;
                  </PFlex>
                {/* )} */}
                </>
              )}

              {(orderApproval.action === "Approved" || showApproved) && (
                <div >
                  <Stack direction="row" spacing={1} style={{alignItems: "flex-end"}}>
                    <PorscheFont className="approved" style={{fontSize: "16px"}}>{t("Approved")}</PorscheFont>
                    {/* Here redirects to Draft check - Logbook based on condition [approved,reassigned,reject] */}
                    <PButtonPure hideLabel={true} onClick={() => handleLogbook()}>
                    </PButtonPure>
                  </Stack>
                </div>
              )}
              {orderApproval.action === "Rejected" && (
                <div>
                    <Stack direction="row">
                      <PorscheFont className="reject">{t("Rejected")}</PorscheFont>
                      {/* Here redirects to Draft check - Logbook based on condition [approved,reassigned,reject] */}
                      <PButtonPure hideLabel={true} onClick={() => handleLogbook()}>
                      </PButtonPure>
                    </Stack>
                </div>
              )}
              {orderApproval.action === "Reassigned" && 
                (orderApproval.approver.id !== userDetails.id) && (
                <div>
                    <PButtonPure hideLabel={true} onClick={() => handleLogbook()}>
                    </PButtonPure>
                </div>
              )}
              {orderApproval.action === "Assigned" && orderApproval.approver.id !== userDetails.id && orderApproval.logBook && orderApproval.logBook.length > 1 && (
                <div>
                    <PButtonPure hideLabel={true} onClick={() => handleLogbook()}>
                    </PButtonPure>
                </div>
              ) 
              }
            </PFlexItem>
          </PFlex>
        </PFlexItem>
      </PFlex>

      <PModal open={isLogBookOpen} onClose={handleLogbookClose} >
        <Logbook handleClose={handleLogbookClose} orderId={orderId} approval={orderApproval} mode="order" />
      </PModal>
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleCustomAlertClose()}/>

    </>
  );
};
