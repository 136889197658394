import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import { PDivider } from '@porsche-design-system/components-react';

import { navSideBar } from '../configs/Navigation';
import { CustomNavAccordion } from './common/CustomNavAccordion';

interface Props {
  main: string,
  subPage: string,
  linTextShow?:boolean,
  disabled: boolean;
  variableSave?: () => void;
  commissionSave?: () =>void;
  approvalSave?: () => void;
}
export const NavSideBar = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { main, subPage } = props;
  const [navbar, setNavbar] = useState(navSideBar);
  const [count, setCount] = useState(0);
  const handleClickAccordion = (navMain: string, expanded: boolean) => {
    let tempNav = navbar;
    tempNav.map((nav, i) => {
      if (nav.main === navMain) {
        nav.expand = expanded;
      }
    })
    let count = 0;
    tempNav.map((nav) => {
      if (nav.main !== main && nav.expand) {
        count++;
      }
    })
    setCount(count);
    setNavbar(tempNav);
  }

  useEffect(() => {
    navbar.map((nav) => {
      if (nav.main === main) {
        nav.expand = true;
      }
    })

    setNavbar(navbar);
  }, [])
  const [linkTextShow, setLinkTextShow] = useState<boolean>(false);
  return (
    <>
      <div style={{ width: '40px', marginTop: '0px', marginRight:"20px"}}>
        <div onMouseEnter={() => setLinkTextShow(true)}
          onMouseLeave={() => setLinkTextShow(false)} style={{ width: linkTextShow ? "210px" : "", position: 'absolute', minHeight: '100%', backgroundColor: '#fff', marginTop: '0px', boxShadow:" 0px 1px 8px rgba(0, 0, 0, 0.25)",zIndex: "9999" }}>
          <div className="nav-div">
            <Stack direction="row" spacing={1}>
              {main === "Dashboard"
                ? (<img src={require("../assets/images/Dashboard.png")} alt="" />)
                : (<img src={require("../assets/images/Dashboard-Black.png")} alt="" />)
              }

              <NavLink
                key={"dashboard"}
                to={"/dashboard"}
                style={{ textDecoration: "none" }}
                className={

                  main === "Dashboard"
                    ? "nav-link-text-selected"
                    : ".nav-link-text"
                }
              >
                <div data-testid="nav-header-dashboard"
                  className={main === "Dashboard" ? "nav-link-text-selected" : "nav-link-text"}
                >
                  {linkTextShow ? t("Dashboard") : ""}
                </div>
              </NavLink>
            </Stack>
            { linkTextShow 
              ? ( <PDivider className='mt12'/> )
              : (<div style={{marginTop: "0px", marginBottom: "12px"}}></div>)
            }
            {navSideBar.map((nav, index) => {
              let color = false;

              if (nav.main === main) {
                if (nav.expand) {
                  if (count === 0) {
                    color = true;
                  }
                } else {
                  if (count === 0) {
                    color = true;
                  }
                }
              }

            return(
              <CustomNavAccordion
                key={nav.main}
                heading={nav.main}
                links={nav.subPages}
                icon={nav.icon}
                open={ (nav.main === main) ? true : false}
                subPage={subPage}
                onClick={(main, expanded) => handleClickAccordion(main, expanded)}
                color={color}
                linTextShow={linkTextShow}
                disabled={props.disabled}
                variablePageSave={props.variableSave? props.variableSave : () => {}}
                commissionSave={props.commissionSave ? props.commissionSave: () => {}}
                approvalSave={props.approvalSave ? props.approvalSave: () => {} }
                collapseNav={() => setLinkTextShow(false)}
              />
            );
          })
        }
      </div>
      </div>
      </div>
    </>
  );
};
