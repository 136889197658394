import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Snackbar } from '@mui/material';
import {
  PButton, PButtonGroup, PButtonPure, PDivider, PFlex, PFlexItem, PModal, PSelectWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';
import { sortApprovalConfiguration } from '../../../helpers/sorter';
import {
  ApprovalStepDTO,
  ApprovalsRequestDTO, ApprovalsResponseDTO
} from '../../../interfaces/Approvals';
import { CalculationDraftDTO } from '../../../interfaces/Calculation';
import { OrderDTO } from '../../../interfaces/Order';
import { UserDTO, UserDetailsDTO } from '../../../interfaces/User';
import {
  ApprovalConfiguration, ApprovalConfigurationStep
} from '../../../interfaces/adminApproval/ApprovalConfiguration';
import { HistoryOrderLogTypes } from '../../../interfaces/changeHistory';
import { type } from '../../../services/Constants';
import {
  dcsgetApprovalConfiguration,
  dcsgetApprovals,
  dcspostChangeHistoryLogs,
  dcssaveSubsequentChange,
  dcssubmitRecalculation
} from '../../../services/data/Helpers';
import { CommissionLog } from './CommissionLog';
import { SingleOrderDetailType } from '../../../interfaces/OrderDetails';
import { Constant } from '../../../configs/Constants';
import CustomSnakebar from '../../common/CustomSnakebar';

interface Props {
  handleModalClose: () => void;
  //showMessage: (variant: string, msg: string) => void;
  id: number;
  persistRecalData?:CalculationDraftDTO;
  subsequentActive?:boolean
  subsequentSubmitMessage?:string
  //draft: string | null;
}

export const ResubmitCommission = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    //const {, showMessage, id, draft } = props;
    const { handleModalClose,id,persistRecalData, subsequentActive=false, subsequentSubmitMessage } = props;
    const [approvals, setApprovals] = useState<Array<ApprovalConfigurationStep>>([])
    const [approvalRequest, setApprovalRequest] = useState<ApprovalsRequestDTO>({} as ApprovalsRequestDTO);
    //const [approvalPost, setApprovalPost] = useState<Array<ApprovalStepDTO>>([]);
    const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
    const orderContext = useContext(OrderContext);
    let userDetails = {} as UserDetailsDTO;
    const [subjectOpen, setSubjectOpen] = useState<boolean>(false);
    const [ isValid, setIsValid ] = useState(false);
    const [discountPercent, setDiscountPercent] = useState(0.00);
    // const [saveSuccess, setSaveSuccess] = useState(false);
    // const [isError, setIsError] = useState(false);
    const [reCalculation, setReCalculation] = useState<CalculationDraftDTO>({} as CalculationDraftDTO);
    const [commissionApprovals, setCommissionApprovals] = useState<Array<ApprovalStepDTO>>([]);
    const [firstCommission, setFirstCommission] = useState<Array<ApprovalStepDTO>>([]);
    const [secondCommission, setSecondCommission] = useState<Array<ApprovalStepDTO>>([]);
    const [fixedCommission, setFixedCommission] = useState<Array<ApprovalStepDTO>>([]);
    const [firstCommLogOpen, setFirstCommLogOpen] = useState<boolean>(false);
    const [secondCommLogOpen, setSecondCommLogOpen] = useState<boolean>(false);
    const [fixedCommLogOpen, setFixedCommLogOpen] = useState<boolean>(false);
    const [asigneeUser, setAsigneeUser] = useState<{id: number, ppnName: string,title: string}>()
    const [showAlert, setShowAlert] = useState(Constant.None)
    const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)
 

     useEffect(() => {
      const init = async () => {
        const ord = orderContext.getCtxOrderDetails();
        const ords = orderContext.getCtxReCalculation()
        setOrder(ord);
        
        if(ord?.vehicleDetails?.category?.type.length > 0 && ord.porscheCenter) {
          const category = ord.vehicleDetails.category.type;
          const apiResponse = await dcsgetApprovalConfiguration(ord.vehicleDetails.category.type, ord.porscheCenter.id, ord.id)
          
          if(apiResponse.status===type.SUCCESS){
            const approvalConfig = apiResponse.response as ApprovalConfiguration;
            if (approvalConfig?.commissionApproval) {
              approvalConfig.commissionApproval = sortApprovalConfiguration(approvalConfig.commissionApproval);
              setApprovals(approvalConfig.commissionApproval);
            }
            
            //setApprovalConfig(approvalConfig);
          }
        
        }
        let recalData = orderContext.getCtxReCalculation();
        setReCalculation(orderContext.getCtxReCalculation()?.reCalculation)
        
        //setApprovalConfig(approvalConfig);
      };
      init();
    }, [])

    const loadInit = async () => {
        const apiResponse = await dcsgetApprovals(id)
        
        if(apiResponse.status ===type.SUCCESS){
          const approvalResponse = apiResponse.response as ApprovalsResponseDTO;
          setCommissionApprovals(approvalResponse?.commissionApprovalSteps);
          
          let steps:Array<ApprovalStepDTO> = [];
    
          //if(approvalResponse.commissionApprovalSteps.length === 0) setEnableNextStep(true);
          approvalResponse?.commissionApprovalSteps?.map((commissionStep, i) => {
            let step = {
              createdBy: commissionStep.createdBy,
              configurationStep: commissionStep.configurationStep,
              approver: commissionStep.approver,
              approvalStep: commissionStep.approvalStep,
              isEnabled: commissionStep.isEnabled,
              action: commissionStep.action,
              id: commissionStep.id,
              order: {id: id}
            } as ApprovalStepDTO;
    
            steps.push(step);
          })
    
          if(steps.length > 0) {
            approvalRequest.approvalSteps = steps;
            setApprovalRequest(approvalRequest);
          }
    
          setFirstCommission(approvalResponse.commissionApprovalSteps.filter(x => x.approvalStep === "CommissionCheck1"));
          setSecondCommission(approvalResponse.commissionApprovalSteps.filter(x => x.approvalStep === "CommissionCheck2"));
          setFixedCommission(approvalResponse.commissionApprovalSteps.filter(x => x.approvalStep === "FixedCommission"));
        }
      };
    
      useEffect(() => {
        loadInit();
      }, []);
  
      const ordercalculationData = orderContext.getCtxReCalculation()

    const handleSubmit = async () => {
    

      if (firstCommission.length > 0) {
        let sessionUser = sessionStorage.getItem("userDetails");
        if (sessionUser) {
          userDetails = JSON.parse(sessionUser);
        }
        let recalData = orderContext.getCtxReCalculation();
        recalData.commissionApprovalSteps = approvalRequest.approvalSteps;
        recalData.commissionApprovalSteps[0].id = firstCommission[0].id;
        recalData.commissionApprovalSteps[0].order = { id: id };
        // Modified due to the issue faced on resubmitting with the initial created by user
        if (userDetails?.id) {
          recalData.commissionApprovalSteps[0].createdBy = {
            id: userDetails.id,
            ppnName: userDetails.ppnName,
          };
        }
        orderContext.updateCtxReCalculation(recalData);

        // payloadData.commissionApprovalSteps = recalData.commissionApprovalSteps;
      
       


        if (!subsequentActive) {
          const apiResp = await dcssubmitRecalculation(id, recalData);
          if (apiResp.status===type.SUCCESS) {
          setShowAlert(Constant.Success)    
          setAlertMessage(Constant.Savedsuccessfully)
            const call = async () => {
                props.handleModalClose();
                if(id) {
                  const logData: HistoryOrderLogTypes = {
                    page: "",
                    tab: "Recalculation Editted",
                    userId: userDetails?.id ? userDetails?.id : 1,
                    comment: "",
                    orderId: id
                  };
                await dcspostChangeHistoryLogs(logData, 'order');
   
                const singOrderDetails:SingleOrderDetailType = { id: id, mode:'view', draft: false, tab: 'calculation' };
                const newWindow=  window.open(`/#/order/create`, "_blank") 
                newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
    
                window.close();
              }
            };
            setTimeout(() => call(), 1000);
          }
          if(apiResp.status===type.ERROR){
            setShowAlert(Constant.Error)
             setAlertMessage(apiResp.response) 
          }
        } else {
          const payloadData = {
            preCalculation: ordercalculationData.preCalculation,
            reCalculation: persistRecalData,
            subSequentChange: ordercalculationData.reCalculation,
            commissionApprovalSteps: {},
            subsequentMessage: subsequentSubmitMessage,
          };
    
          payloadData.subSequentChange.calcType = "SubSequentChange";
          delete payloadData.subSequentChange.id;


          const subsequentCommissionApprovalSteps= {
            createdBy: userDetails.id,
            commissionApprovals: [
              {
                id: asigneeUser?.id,
                ppnName: asigneeUser?.ppnName,
                comment: "",
                commissionRank: "1st",
                action: "Assigned",
                approvedOn: new Date().toUTCString(),
                isEnable: true,
                logs: [
                  {
                    actionDate: new Date().toUTCString(),
                    user: asigneeUser?.ppnName,
                    action: "Assigned",
                    comment: ""
                  }
                ]
              },
              {
                id: asigneeUser?.id,
                ppnName: asigneeUser?.ppnName,
                comment: "",
                commissionRank: "2nd",
                action: "Pending",
                approvedOn: "",
                isEnable: false,
                logs: []
              },
              {
                id: asigneeUser?.id,
                ppnName: asigneeUser?.ppnName,
                comment: "",
                commissionRank: "Fixed",
                action: "Pending",
                approvedOn: "",
                isEnable: false,
                logs: []
              }
            ]
          }
  
          if( approvals.length > 0 && approvals[2] && approvals[2].isEnabled && approvals[2].approvers && approvals[2].approvers.length > 0){
          subsequentCommissionApprovalSteps.commissionApprovals[1].isEnable = true
          }
          if(approvals.length > 0 && approvals[3] && approvals[3].isEnabled && approvals[3].approvers && approvals[3].approvers.length > 0 && (reCalculation?.commissionCalculation?.vehicleCommission==="Fixed"||(reCalculation?.commissionCalculation?.vehicleCommission==="Standard" && (reCalculation?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MaxCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MinCommission")) ) ){
            subsequentCommissionApprovalSteps.commissionApprovals[2].isEnable = true
          }
         payloadData.commissionApprovalSteps = subsequentCommissionApprovalSteps;

          const saveSubsqResp = await dcssaveSubsequentChange(id, payloadData);
          if (saveSubsqResp.status===type.SUCCESS) {
            setShowAlert(Constant.Success)    
            setAlertMessage(Constant.Savedsuccessfully)
            const call = async () => {
              props.handleModalClose();
              if (id) {
                const logData: HistoryOrderLogTypes = {
                  page: "",
                  tab: "Subsequent Change",
                  userId: userDetails?.id ? userDetails?.id : 1,
                  comment: subsequentSubmitMessage ?? "",
                  orderId: id
                };
              await dcspostChangeHistoryLogs(logData, 'order');

                const singOrderDetails:SingleOrderDetailType = { id: id, mode:'view', draft: false, tab: 'calculation' };
                const newWindow=  window.open(`/#/order/create`, "_blank") 
                newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));

                window.close();
              }
            };
            setTimeout(() => call(), 1000);
          }
          if(saveSubsqResp.status===type.ERROR){
            setShowAlert(Constant.Error)
            setAlertMessage(saveSubsqResp.response) 
          }
        }
      }
    };
  
    const handleAssignee = (approval: ApprovalConfigurationStep, users: Array<UserDTO>, aprroversIndex: number, value: string, ) => {
      let sessionUser = sessionStorage.getItem("userDetails");
      if(sessionUser) {
        userDetails = JSON.parse(sessionUser);
      }
  
      let user = users.filter(user => user.id === parseInt(value));
      setAsigneeUser({
        id: user[0].id,
        ppnName: user[0]?.ppnName ? user[0]?.ppnName : "",
        title: user[0]?.seller ? user[0]?.seller : "",
      });
      if(approvalRequest.approvalSteps && approvalRequest.approvalSteps.length > 0){
          let exists = false;
          
          approvalRequest.approvalSteps.map((step, i) => {
            if(step.approvalStep === approval.approvalStep) {
              if(step.approvalStep === "Discount") {
                  const count = approvalRequest.approvalSteps.filter(x => x.approvalStep === "Discount");
                  if(count.length === 2) {
                      exists = true;
                      approvalRequest.approvalSteps[i].approver = user.length > 0 ? user[0] : {};
                  } 
              } else {
                  exists = true;
                  approvalRequest.approvalSteps[i].approver = user.length > 0 ? user[0] : {};
              }

              approvalRequest.approvalSteps[i].action = "Assigned";
            }
          })
  
          if(!exists) {
               const newApprover = {
                  createdBy: userDetails ? {id: userDetails.id, ppnName: userDetails.ppnName} : {},
                  configurationStep: {id: approval?.id!},
                  approvalStep: approval.approvalStep,
                  isEnabled: approval.isEnabled,
                  action: "Assigned",
                  approver: user.length > 0 ? user[0] : {},
                  order: id
              } as ApprovalStepDTO;
              
              approvalRequest.approvalSteps.push(newApprover);
          }
      } else {
          let  newApprover = {
              createdBy: userDetails ? {id: userDetails.id, ppnName: userDetails.ppnName} : {},
              configurationStep: {id: approval?.id!},
              approvalStep: approval.approvalStep,
              isEnabled: approval.isEnabled,
              action: "Assigned",
              approver: user.length > 0 ? user[0] : {},
              order: id
          } as ApprovalStepDTO;
  
          approvalRequest.approvalSteps = [newApprover];
      }
  
      
      setApprovalRequest(approvalRequest);
      setIsValid(isValidApproval(approvalRequest));
    }
  
    const isValidApproval = (approvalRequest: ApprovalsRequestDTO) : boolean => {
      
      
      if(approvalRequest.approvalSteps.length > 0 ) {
          if(approvals.length > 0) {
              if (approvals[1].approvers && approvals[1].approvers.length > 0) {
                  const exists0 = approvalRequest.approvalSteps.filter( x => x.approvalStep === approvals[1].approvalStep);
                  if(exists0.length === 0) return false;
                  if(exists0[0].approver.id === undefined) return false;
              }
           
              return true
          }
      } else {
          return false;
      };
  
      return true;
    }
  
   

    const handleFirstCommLogbook = () => {
      setFirstCommLogOpen(true);
      setSecondCommLogOpen(false);
      setFixedCommLogOpen(false);
    };

    const handleSecondCommLogbook = () => {
      setFirstCommLogOpen(false);
      setSecondCommLogOpen(true);
      setFixedCommLogOpen(false);
    };

    const handleFixedCommLogbook = () => {
      setFirstCommLogOpen(false);
      setSecondCommLogOpen(false);
      setFixedCommLogOpen(true);
    };
  
    const firstCommLogClose = () => {
      setFirstCommLogOpen(false);
    };

    const secondCommLogClose = () => {
      setSecondCommLogOpen(false);
    };

    const fixedCommLogClose = () => {
      setFixedCommLogOpen(false);
    };

    const handleClose = ( ) => {
      setShowAlert(Constant.None)
      setAlertMessage("")
    };

    return (
      <>
        <div style={{marginLeft: "-20px", marginTop:"-32px"}}>
          <PDivider color="neutral-contrast-low"></PDivider><br></br>
          <PFlex >
            <PFlexItem width="one-quarter" className="sub-headding"><PorscheFont>{t("Approval steps")}</PorscheFont></PFlexItem>
            <PFlexItem width="one-quarter" className="sub-headding"><PorscheFont>{t("User assigned")}</PorscheFont></PFlexItem>
          </PFlex><br></br>
          <PDivider color="neutral-contrast-high"></PDivider> 
  
          {/** nedd to add here first commission approval step */}
          { approvals.length > 0 && approvals[1] && approvals[1].approvers &&  approvals[1].approvers.length > 0 && (
          <>
            <PFlex>
              <PFlexItem width="one-quarter">
                <PFlex>
                    <PFlexItem>
                        <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                    </PFlexItem>&nbsp;&nbsp;
                    <PFlexItem style={{ marginTop: "20px" }}>
                      <img src={require("../../../assets/images/approvals/1stordercheck.png")}></img>
                    </PFlexItem>&nbsp;&nbsp;
                    <PFlexItem style={{ marginTop: "20px" }}>
                        <PorscheFont className="tn-text-14-600">{t("1st commission check")}<span style={{ color: "#D5001B" }}>*</span></PorscheFont>
                    </PFlexItem>
                </PFlex>
              </PFlexItem>
              <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
                <PFlexItem>
                  <PSelectWrapper hideLabel={false}>
                      <select name="1stordercheck" onChange={(e) => handleAssignee(approvals[1], approvals[1].approvers ? approvals[1].approvers : [] , 0, e.target.value)} >
                      <option value={0}>{t("Assign to user")}</option>
                      {approvals[1].approvers.map((user, index) => {
                        // if (getLogedInUser().id !== user.id) {
                        if(order?.orderCreator?.id !== user.id) {
                          return(
                            <option key={index} value={user.id} >
                              {user.ppnName}
                            </option>
                          );
                        }
                      })}
                      </select>
                  </PSelectWrapper>                              
                </PFlexItem>
              </PFlexItem >
              {subsequentActive?null:<PFlexItem width={"half"}>
                <PFlex justifyContent={"flex-end"}>
                  <PFlexItem>
                    {firstCommission.length > 0 && firstCommission[0].logBook && (
                      <div style={{marginTop: "22px"}}>
                          <PButtonPure hideLabel={true} onClick={() => handleFirstCommLogbook()}>
                          </PButtonPure>
                      </div>
                    )}
                  </PFlexItem>
                </PFlex>
              </PFlexItem>}
              
            </PFlex>
            <PDivider color="neutral-contrast-low"></PDivider>
          </>
        )}
  
        { approvals.length > 0 && approvals[2] && approvals[2].isEnabled && approvals[2].approvers && approvals[2].approvers.length > 0 && (
          <>
            <PFlex>
              <PFlexItem width="one-quarter">
                <PFlex>
                    <PFlexItem>
                        <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                    </PFlexItem>&nbsp;&nbsp;
                    <PFlexItem style={{ marginTop: "20px" }}>
                      <img src={require("../../../assets/images/approvals/2ndordercheck.png")}></img>
                    </PFlexItem>&nbsp;&nbsp;
                    <PFlexItem style={{ marginTop: "20px" }}>
                        <PorscheFont className="tn-text-14-600">{t("2nd commission check")}<span style={{ color: "#D5001B" }}></span></PorscheFont>
                    </PFlexItem>
                </PFlex>
              </PFlexItem>
              <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
                <PFlexItem>
                  <div className='user-assigned' style={{width: "100%"}}>
                    <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                  </div>                           
                </PFlexItem>
              </PFlexItem>

              {subsequentActive?null:<PFlexItem width={"half"}>
                <PFlex justifyContent={"flex-end"}>
                  <PFlexItem>
                    {secondCommission.length > 0 && secondCommission[0].logBook && (
                      <div style={{marginTop: "22px"}}>
                          <PButtonPure hideLabel={true} onClick={() => handleSecondCommLogbook()}>
                          </PButtonPure>
                      </div>
                    )}
                  </PFlexItem>
                </PFlex>
              </PFlexItem>}
              
            </PFlex>
            <PDivider color="neutral-contrast-low"></PDivider>
          </>
        )}
         { approvals.length > 0 && approvals[3] && approvals[3].isEnabled && approvals[3].approvers && approvals[3].approvers.length > 0 && (reCalculation?.commissionCalculation?.vehicleCommission==="Fixed"||(reCalculation?.commissionCalculation?.vehicleCommission==="Standard" && (reCalculation?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MaxCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MinCommission")) ) && (
          <>
            <PFlex>
              <PFlexItem width="one-quarter">
                <PFlex>
                    <PFlexItem>
                        <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                    </PFlexItem>&nbsp;&nbsp;
                    <PFlexItem style={{ marginTop: "20px" }}>
                      <img src={require("../../../assets/images/approvals/FinalCommission.png")}></img>
                    </PFlexItem>&nbsp;&nbsp;
                    <PFlexItem style={{ marginTop: "20px" }}>
                        <PorscheFont className="tn-text-14-600">{t("Fixed commission")}<span style={{ color: "#D5001B" }}></span></PorscheFont>
                    </PFlexItem>
                </PFlex>
              </PFlexItem>
              <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
                <PFlexItem>
                  <div className='user-assigned' style={{width: "100%"}}>
                    <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                  </div>                           
                </PFlexItem>
              </PFlexItem>
              {subsequentActive?null:<PFlexItem width={"half"}>
                <PFlex justifyContent={"flex-end"}>
                  <PFlexItem>
                    {fixedCommission.length > 0 && fixedCommission[0].logBook && fixedCommission[0].logBook.length > 0 && (
                      <div style={{marginTop: "22px"}}>
                        <PButtonPure hideLabel={true} onClick={() => handleFixedCommLogbook()}>
                        </PButtonPure>
                      </div>
                    )}
                  </PFlexItem>
                </PFlex>
              </PFlexItem>}
              
            </PFlex>
            <PDivider color="neutral-contrast-low"></PDivider>
          </>
        )}
         <PFlex justifyContent={"flex-end"} className="mt16">
          <PFlexItem alignSelf={"flex-end"}>
              <PButtonGroup>
                <PButton icon='save' onClick={handleSubmit} disabled={!isValid}>{t("Submit")}</PButton>
              </PButtonGroup>
               </PFlexItem>
        </PFlex>
        </div>

        { firstCommission.length > 0 && firstCommission[0].logBook && firstCommission[0].logBook?.length > 0 && (
          <PModal open={firstCommLogOpen} onClose={firstCommLogClose} >
            {/* <Logbook handleClose={firstCommLogClose} orderId={id} approval={firstCommission.length > 0 ? firstCommission[0] : {} as ApprovalStepDTO} mode="commission" /> */}
            <CommissionLog handleClose={firstCommLogClose} orderId={id} approval={firstCommission.length > 0 ? firstCommission[0] : {} as ApprovalStepDTO} mode="commission" />
          </PModal>
        )}

        { secondCommission.length > 0 && secondCommission[0].logBook && secondCommission[0].logBook?.length > 0 && (
          <PModal open={secondCommLogOpen} onClose={secondCommLogClose} >
            <CommissionLog handleClose={secondCommLogClose} orderId={id} approval={secondCommission.length > 0 ? secondCommission[0] : {} as ApprovalStepDTO} mode="commission" />
          </PModal>
        )}

        { fixedCommission.length > 0 && fixedCommission[0].logBook && fixedCommission[0].logBook?.length > 0 && (
          <PModal open={fixedCommLogOpen} onClose={fixedCommLogClose} >
            {/* <Logbook handleClose={firstCommLogClose} orderId={id} approval={firstCommission.length > 0 ? firstCommission[0] : {} as ApprovalStepDTO} mode="commission" /> */}
            <CommissionLog handleClose={fixedCommLogClose} orderId={id} approval={fixedCommission.length > 0 ? fixedCommission[0] : {} as ApprovalStepDTO} mode="commission" />
          </PModal>
        )}
        <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

      </>
    )
  }