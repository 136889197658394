import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Alert, Snackbar } from '@mui/material';
import Stack from '@mui/material/Stack';
import {
  IconName, PButton, PButtonPure,
  PFlex, PFlexItem, PGrid, PGridItem, PIcon,
  PTable, PTableBody, PTableCell, PTableHead, PTableHeadCell, PTableHeadRow,
  PTableRow, PText, PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { Constant } from '../../../../configs/Constants';
import { PartType, WinterOrSummer } from '../../../../configs/Enums';
import { OrderContext } from '../../../../contexts/OrderContext';
import getSessionStorage from '../../../../helpers/getSessionStorage';
import { UpsaleItemsTotal } from '../../../../interfaces/Upsale';
import {
  ServiceItemDTO, TequipmentAndPorscheDriverSelectionDTO, UpsaleItemDTO,
  WorkshopAndServiceCostsDTO
} from '../../../../interfaces/UpsaleNew';
import { CategoryCommissionDTO, CommissionScalingDTO } from '../../../../interfaces/commission';
import {
  formatCurrency, formatCurrencyToDecimal, formatCurrencyWithNoUnit, getUpsaleItemsTotal
} from '../../../../mock/helper';
import { type } from '../../../../services/Constants';
import { dcscommissionScalingByUser, dcsgetUpsaleVariblesNew } from '../../../../services/data/Helpers';
import { CustomDelete } from '../../../common/CustomDelete';

interface Props {
  open?: boolean;
  enableNextButton: (isValid: boolean) => void;
  disabled: boolean;
  handleModified: () => void;
}

export const PDSNew = (props: Props): JSX.Element => {
  const { open, enableNextButton, disabled, handleModified } = props;
  const inputFieldMaxLength:number = 15
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);

  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const orderContext = useContext(OrderContext);
  const [searchResult, setSearchResult] = useState<
    Array<TequipmentAndPorscheDriverSelectionDTO>
  >([]);
  const [scalingobj, setScalingobj] = useState<CommissionScalingDTO>({} as CommissionScalingDTO);
  const [services, setServices] = useState<Array<WorkshopAndServiceCostsDTO>>(
    []
  );
  const [selectedPart, setSelectedPart] = useState(
    {} as TequipmentAndPorscheDriverSelectionDTO
  );
  const [upsaleItems, setUpsaleItems] = useState<Array<UpsaleItemDTO>>([]);
  const [total, setTotal] = useState<UpsaleItemsTotal>({} as UpsaleItemsTotal);

  const [reload, setReload] = useState("");
  const [disabledClassName, setDisabledClassName] = useState("")
  let upsaleDetails = orderContext.getCtxUpsaleDetails();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mode, setMode] = useState("");
  const [searchParams, setSearchParams] = useSearchParams()
  const [commissionObj, setCommissionObj] = useState<CategoryCommissionDTO>({} as CategoryCommissionDTO);
  const [customerShareError, setCustomerShareError] = useState(false);

  useEffect(() => {
    const mode = orderValues?.mode ? orderValues?.mode+'' :'';
    setMode(mode);
    if (disabled) setDisabledClassName("disabled")
    else setDisabledClassName("")
    if (upsaleDetails.upsaleItems) {
      // let tempItems = upsaleDetails.upsaleItems
      // tempItems = tempItems.filter(items => items.partType === PartType.part)
      // setUpsaleItems(tempItems)
      setUpsaleItems(upsaleDetails.upsaleItems);
    }
  }, [upsaleDetails])

  useEffect(() => {
    setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
  }, [searchValue, selectedPart, upsaleItems]);

  useEffect(() => {

    let isValid = isValidUpsale()
    orderContext.updateFormValidation(1, isValid)
    enableNextButton(isValid);
    const init = async () => {

      const apiResponse = await dcscommissionScalingByUser(orderContext.getCtxOrderDetails().orderCreator.id)
      if (apiResponse.status===type.SUCCESS && apiResponse.response) {
        setScalingobj(apiResponse.response);
      }
    };
    init();
  }, [])

  //PDSTequipment
  const calculateRangeValue = (rangeValue: number, category: string) => {
    const filterConf: Array<CategoryCommissionDTO> = scalingobj.commissions.filter(x => x.commissionScalingCategoryType === category);
    if (filterConf.length > 0) {
      const conf = filterConf[0];
       setCommissionObj(conf);
      for (let i = 0; i < conf.vehicleCommissions.length; i++) {
        if (conf.vehicleCommissions[i].fromValue <= rangeValue && conf.vehicleCommissions[i].toValue >= rangeValue) {
          
          return conf.vehicleCommissions[i];
        }
      }
      // if  we wont find value and within category range.
      return null;
    }

  }


  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };
  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const isValidUpsale = (): boolean => {
    const errors = orderContext.getErrors();
    //
    return errors.every(error => {
      if (error.section === "upsalePaw") return false
      if (error.section === "upsaleCwt") return false
      if (error.section === "upsalePds") return false
      return true
    })
  }

  const handleSearch = async (search: string) => {
    //
    setSearchValue(search);
    if (search.length > 0) {
      const result = await dcsgetUpsaleVariblesNew(search)

      const parts = result.parts;
      //

      setSearchResult(parts);
      setServices(result.services);
    } else {
      setSearchResult([]);
    }
  };

  const handleSelect = (id: number, search: string) => {
    const selected = searchResult.filter((x) => x.id === id);
    setSelectedPart(selected[0]);
    setSearchValue(search);
    setSearchResult([]);
  };

  const SearchResult = (): JSX.Element => {
    if (searchResult.length === 0) {
      return <></>;
    }

    return (
      <ul
        className={`autocomplete ${searchResult.length > 3 ? "autocomplete-overflow" : ""
          }`}
      >
        {searchResult.map((tds) => {
          return (
            <li
              key={tds.id}
              onClick={() => handleSelect(tds.id, tds.part ? tds.part : "")}
            >
              {tds.part ? tds.part + "  |  " + tds.serialNumber : ""}
            </li>
          );
        })}
      </ul>
    );
  };

  const handleAddPart = () => {
    if (selectedPart.id === undefined) return false;

    let exists = false;
    let usItem = {} as UpsaleItemDTO;
    if (upsaleItems.length > 0) {
      const items = upsaleItems.filter((x) => x.part.id === selectedPart.id);
      usItem.part = selectedPart;
      usItem.partType = PartType.part;
      usItem.customerShare = 0.0;
      usItem.commission = 0.0;
      usItem.discount = 0.0;
      usItem.serviceItems = [];
     /**
    * commission calculation
    */
     let discoutPercenatge = 0.0
     if (usItem.customerShare > 0 && usItem.part.priceBeforeCustomerNet) {
        usItem.discount = usItem.part.priceBeforeCustomerNet - usItem.customerShare;
        discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
     } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet) {
        discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
        if(usItem.part.partDiscount){
          usItem.discount = usItem.part.priceBeforeCustomerNet;
        }else{
          usItem.discount = usItem.part.priceInternal;
        }
     }
      if (discoutPercenatge > 0) {
        
        let vc = calculateRangeValue(discoutPercenatge, "PDSTequipment");

        
        let commision = 0.0;
        if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet) {

          commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
        } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceBeforeCustomerNet) {
          commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
        } else if (vc && vc?.payoutRefence1 === "Euro") {
          commision = commision + vc.payoutValue1;
        }
        if (commissionObj.payOut2) {
          if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet) {
            commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet) {
            commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2) {
            commision = commision + vc.payoutValue2;
          }
        }
        usItem.commission = commision;
      }
      upsaleItems.push(usItem);
    } else {
      usItem.part = selectedPart;
      usItem.partType = PartType.part;
      usItem.customerShare = 0.0;
      usItem.commission = 0.0;
      usItem.discount = 0.0;
     

       /**
      * commission calculation
      */
      let discoutPercenatge = 0.0
      
      if (usItem.customerShare > 0 && usItem.part.priceBeforeCustomerNet) {
        usItem.discount = usItem.part.priceBeforeCustomerNet - usItem.customerShare;
        discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
      } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet) {
        discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
        if(usItem.part.partDiscount){
          usItem.discount = usItem.part.priceBeforeCustomerNet;
        }else{
          usItem.discount = usItem.part.priceInternal;
        }
      }
        
      if (discoutPercenatge > 0) {
        
        let vc = calculateRangeValue(discoutPercenatge, "PDSTequipment");

        
        let commision = 0.0;
        if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet) {

          commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
        } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceBeforeCustomerNet) {
          commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
        } else if (vc && vc?.payoutRefence1 === "Euro") {
          commision = commision + vc.payoutValue1;
        }
        if (commissionObj.payOut2) {
          if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet) {
            commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet) {
            commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
          } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2) {
            commision = commision + vc.payoutValue2;
          }
        }
        usItem.commission = commision;
      }
      
      usItem.serviceItems = [];
      
      //setUpsaleItems([usItem]);
      upsaleItems.push(usItem);
    }

    // if (upsaleDetails.upsaleItems !== undefined && upsaleDetails.upsaleItems.length > 0) {
    //   upsaleDetails.upsaleItems = [...upsaleDetails.upsaleItems, usItem];
    // } else {
    //   upsaleDetails.upsaleItems = [usItem];
    // }

    upsaleDetails.upsaleItems = upsaleItems;
    orderContext.updateCtxUpsaleDetails(upsaleDetails);

    // upsaleDetails.upsaleItems = upsaleItems;
    // orderContext.updateCtxUpsaleDetails(upsaleDetails)

    setSearchValue("");
    setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
    orderContext.removeSectionError("upsalePds");
    enableNextButton(true);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleCustomerShare = (partId: number, value: string, index: number) => {
  
    upsaleItems.map((usItem, i) => {
      //PriceBefore customer Net active logic
      if(usItem?.part.partDiscount == true){
          if (usItem.part.id === partId && i === index) {
            usItem.customerShare = formatCurrencyToDecimal(value);
            const dom = document.getElementById(`pcustomerShare${index}`);
            if (usItem.customerShare > 0) {
              if (usItem.part.priceBeforeCustomerNet) {
                usItem.discount =
                  usItem.part.priceBeforeCustomerNet - usItem.customerShare;
                  //
                    if(usItem.discount < 0){
                      usItem.discount = 0.00
                    }
                  if (usItem.customerShare > usItem.part.priceBeforeCustomerNet) {
                    if(dom) dom.setAttribute("state", "error");
                  } else {
                    if(dom) dom.setAttribute("state", "none");
                  }
              } else {
                usItem.discount = 0.0;
              }
            } else {

              if (usItem.customerShare <0) {
                if(dom) dom.setAttribute("state", "error");
              } else {
                if(dom) dom.setAttribute("state", "none");
              }


              usItem.discount = usItem.part.priceBeforeCustomerNet
                ? usItem.part.priceBeforeCustomerNet
                : 0.0;
              // if(dom) dom.setAttribute("state", "none");
            }

            /**
        * commission calculation
        */
          let discoutPercenatge = 0.0
          
          if (usItem.customerShare > 0 && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
            discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
          } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
            discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
          }
            if (discoutPercenatge > 0) {
              
              let vc = calculateRangeValue(discoutPercenatge, "PDSTequipment");
      
              
              let commision = 0.0;
              if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet !== undefined) {
                commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
              } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceBeforeCustomerNet !== undefined) {
                commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
              } else if (vc && vc?.payoutRefence1 === "Euro") {
                commision = commision + vc.payoutValue1;
              }
              if (commissionObj.payOut2) {
                if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
                  commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
                } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
                  commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
                } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2 !== undefined) {
                  commision = commision + vc.payoutValue2;
                }
              }
              usItem.commission = commision;
            }
            else{
              usItem.commission = 0;
            }
          }
      };// PriceBefore customer Net active logic end

       //PriceInternal active logic
      if(usItem?.part.partDiscount == false){

        if (usItem.part.id === partId && i === index) {
          usItem.customerShare = formatCurrencyToDecimal(value);
          const dom = document.getElementById(`pcustomerShare${index}`);
          if (usItem.customerShare > 0) {
            if (usItem.part.priceInternal) {
              usItem.discount =
                usItem.part.priceInternal - usItem.customerShare;
                //
                if(usItem.discount < 0){
                  usItem.discount = 0.00
                }
                // if (usItem.customerShare > usItem.part.priceInternal) {
                //   if(dom) dom.setAttribute("state", "error");
                // } else {
                //   if(dom) dom.setAttribute("state", "none");
                // }
            } else {
              usItem.discount = 0.0;
            }
          } else {
            if (usItem.customerShare <0) {
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            } 
            usItem.discount = usItem.part.priceInternal
              ? usItem.part.priceInternal
              : 0.0;
            if(dom) dom.setAttribute("state", "none");
          }
          //validation for price internal on
        if(usItem?.part?.priceBeforeCustomerNet && usItem?.part?.priceInternal ){
          if (usItem.customerShare > usItem.part.priceBeforeCustomerNet || usItem.customerShare < 0 ) {
            if(dom) dom.setAttribute("state", "error");
          } else {
            if(dom) dom.setAttribute("state", "none");
          }
        }

          /**
      * commission calculation
      */
        let discoutPercenatge = 0.0
        
        if (usItem.customerShare > 0 && usItem.part.priceInternal !== undefined && usItem.part.priceInternal > 0) {
          discoutPercenatge = ((usItem.part.priceInternal - usItem.customerShare) / usItem.part.priceInternal) * 100;
        } else if (usItem.part.priceBeforeCustomerNet && usItem.part.priceInternal !== undefined && usItem.part.priceInternal > 0) {
          discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceInternal) * 100;
        }
          if (discoutPercenatge > 0) {
            
            let vc = calculateRangeValue(discoutPercenatge, "PDSTequipment");
    
            
            let commision = 0.0;
            if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet !== undefined) {
              commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
            } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceInternal !== undefined) {
              commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
            } else if (vc && vc?.payoutRefence1 === "Euro") {
              commision = commision + vc.payoutValue1;
            }
            if (commissionObj.payOut2) {
              if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
                commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
              } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceInternal !== undefined) {
                commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
              } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2 !== undefined) {
                commision = commision + vc.payoutValue2;
              }
            }
            usItem.commission = commision;
          }
          else{
            usItem.commission = 0;
          }
        }

      };//PriceInternal active logic end

      //when user in Draft edit mode and partDiscount is undefined
    if(usItem?.part.partDiscount == undefined){
      if (usItem.part.id === partId && i === index) {
        usItem.customerShare = formatCurrencyToDecimal(value);
        const dom = document.getElementById(`pcustomerShare${index}`);
        if (usItem.customerShare > 0) {
          if (usItem.part.priceBeforeCustomerNet && usItem?.part?.priceInternal) {
            usItem.discount =
              usItem.part.priceBeforeCustomerNet - usItem.customerShare;
              //
                if(usItem.discount < 0){
                  usItem.discount = 0.00
                }
              if (usItem.customerShare > usItem.part.priceBeforeCustomerNet || usItem.customerShare < 0) {
                if(dom) dom.setAttribute("state", "error");
              } else {
                if(dom) dom.setAttribute("state", "none");
              }
          } else {
            usItem.discount = 0.0;
          }
        } else {
          if (usItem.customerShare <0) {
            if(dom) dom.setAttribute("state", "error");
          } else {
            if(dom) dom.setAttribute("state", "none");
          }
          usItem.discount = usItem.part.priceInternal
            ? usItem.part.priceInternal
            : 0.0;
          if(dom) dom.setAttribute("state", "none");
        }

        /**
    * commission calculation
    */
      let discoutPercenatge = 0.0
      
      if (usItem.customerShare > 0 && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
        discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
      } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
        discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
      }
        if (discoutPercenatge > 0) {
          
          let vc = calculateRangeValue(discoutPercenatge, "PDSTequipment");
  
          
          let commision = 0.0;
          if (vc && vc?.payoutRefence1 === "SellingPrice" && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = usItem.part.priceBeforeCustomerNet * vc.payoutValue1 / 100;
          } else if (vc && vc?.payoutRefence1 === "CustomerShare" && usItem.part.priceBeforeCustomerNet !== undefined) {
            commision = commision + usItem.customerShare * vc.payoutValue1 / 100;
          } else if (vc && vc?.payoutRefence1 === "Euro") {
            commision = commision + vc.payoutValue1;
          }
          if (commissionObj.payOut2) {
            if (vc && vc?.payoutRefence2 === "SellingPrice" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
              commision = commision + usItem.part.priceBeforeCustomerNet * vc.payoutValue2 / 100;
            } else if (vc && vc?.payoutRefence2 === "CustomerShare" && vc.payoutValue2 && usItem.part.priceBeforeCustomerNet !== undefined) {
              commision = commision + usItem.customerShare * vc.payoutValue2 / 100;
            } else if (vc && vc?.payoutRefence2 === "Euro" && vc.payoutValue2 !== undefined) {
              commision = commision + vc.payoutValue2;
            }
          }
          usItem.commission = commision;
        }
        else{
          usItem.commission = 0;
        }
      }
    };

  });

    upsaleDetails.upsaleItems = upsaleItems;
    orderContext.updateCtxUpsaleDetails(upsaleDetails)
    setCustomerShareError(!validateCustomerShare(upsaleItems));
    setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
    setUpsaleItems(upsaleItems);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleServiceCustomerShare = (
    partId: number,
    value: string,
    index: number
  ) => {
    upsaleItems.map((usItem, i) => {
      //PriceBefore customer Net active logic
    if(usItem?.part.partDiscount === true){
      if (usItem.part.id === partId && i === index) {
        usItem.serviceItems[0].customerShare = formatCurrencyToDecimal(value);
        usItem.serviceItems[0].discount = 0.0;
        const dom = document.getElementById(`pscustomerShare${usItem.serviceItems[0].services.id}-${i}`);
        if (
          usItem.serviceItems[0].customerShare > 0 &&
          usItem.serviceItems[0].timeUnits
        ) {
      
          if (usItem.serviceItems[0].timeUnits) {
            usItem.serviceItems[0].discount =
              (usItem.serviceItems[0].services.priceBeforeCustomerNet *
                usItem.serviceItems[0].timeUnits / 100) -
              usItem.serviceItems[0].customerShare;

              if(usItem.serviceItems[0].discount < 0){
                usItem.serviceItems[0].discount = 0.00
              }

            if (usItem.serviceItems[0].discount < 0){
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
          }
        } else if (usItem.serviceItems[0].timeUnits) {

          usItem.serviceItems[0].discount =
            usItem.serviceItems[0].services.priceBeforeCustomerNet *
            usItem.serviceItems[0].timeUnits / 100;
          //usItem.serviceItems[0].discount = usItem.serviceItems[0].services.priceInternal;
          if(dom) dom.setAttribute("state", "none");
        }

            
        if (usItem.serviceItems[0].customerShare > usItem.serviceItems[0].services.priceBeforeCustomerNet || usItem.serviceItems[0].customerShare < 0) {  
          if(dom) dom.setAttribute("state", "error");
        } else {
          if(dom) dom.setAttribute("state", "none");
        }

        upsaleDetails.upsaleItems = upsaleItems;
        orderContext.updateCtxUpsaleDetails(upsaleDetails)
        setCustomerShareError(!validateCustomerShare(upsaleItems));
        setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
        setUpsaleItems(upsaleItems);
        handleModified();
        setReload(new Date().toLocaleString());
      }
    };//PriceBefore customer Net active logic End

    //PriceInternal active logic
    if(usItem?.part.partDiscount === false){
      if (usItem.part.id === partId && i === index) {
        usItem.serviceItems[0].customerShare = formatCurrencyToDecimal(value);
        usItem.serviceItems[0].discount = 0.0;
        const dom = document.getElementById(`pscustomerShare${usItem.serviceItems[0].services.id}-${i}`);
        if (
          usItem.serviceItems[0].customerShare > 0 &&
          usItem.serviceItems[0].timeUnits
        ) {
          if (usItem.serviceItems[0].timeUnits) {
            usItem.serviceItems[0].discount =
              (usItem.serviceItems[0].services.priceInternal *
                usItem.serviceItems[0].timeUnits / 100) -
              usItem.serviceItems[0].customerShare;
              if(usItem.serviceItems[0].discount < 0){
                usItem.serviceItems[0].discount = 0.00
              }

            if (usItem.serviceItems[0].discount < 0){
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
          }
        } else if (usItem.serviceItems[0].timeUnits) {
          usItem.serviceItems[0].discount =
            usItem.serviceItems[0].services.priceInternal *
            usItem.serviceItems[0].timeUnits / 100;
          //usItem.serviceItems[0].discount = usItem.serviceItems[0].services.priceInternal;
          if(dom) dom.setAttribute("state", "none");
        }

        if (usItem.serviceItems[0].customerShare > usItem.serviceItems[0].services.priceBeforeCustomerNet || usItem.serviceItems[0].customerShare < 0) {  
          if(dom) dom.setAttribute("state", "error");
        } else {
          if(dom) dom.setAttribute("state", "none");
        }

        upsaleDetails.upsaleItems = upsaleItems;
        orderContext.updateCtxUpsaleDetails(upsaleDetails)
        setCustomerShareError(!validateCustomerShare(upsaleItems));
        
        setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
        setUpsaleItems(upsaleItems);
        handleModified();
        setReload(new Date().toLocaleString());
      }
    };//PriceInternal active logic End

    //when user in Draft edit mode and partDiscount is undefined
    if(usItem?.part.partDiscount === undefined){
      if (usItem.part.id === partId && i === index) {
        usItem.serviceItems[0].customerShare = formatCurrencyToDecimal(value);
        usItem.serviceItems[0].discount = 0.0;
        const dom = document.getElementById(`pscustomerShare${usItem.serviceItems[0].services.id}-${i}`);
        if (
          usItem.serviceItems[0].customerShare > 0 &&
          usItem.serviceItems[0].timeUnits
        ) {
          if (usItem.serviceItems[0].timeUnits) {
            usItem.serviceItems[0].discount =
              (usItem.serviceItems[0].services.priceBeforeCustomerNet *
                usItem.serviceItems[0].timeUnits / 100) -
              usItem.serviceItems[0].customerShare;

              if(usItem.serviceItems[0].discount < 0){
                usItem.serviceItems[0].discount = 0.00
              }

            if (usItem.serviceItems[0].discount < 0){
              if(dom) dom.setAttribute("state", "error");
            } else {
              if(dom) dom.setAttribute("state", "none");
            }
          }
        } else if (usItem.serviceItems[0].timeUnits) {
          usItem.serviceItems[0].discount =
            usItem.serviceItems[0].services.priceInternal *
            usItem.serviceItems[0].timeUnits / 100;
          //usItem.serviceItems[0].discount = usItem.serviceItems[0].services.priceInternal;
          if(dom) dom.setAttribute("state", "none");
        }

        if (usItem.serviceItems[0].customerShare > usItem.serviceItems[0].services.priceBeforeCustomerNet || usItem.serviceItems[0].customerShare < 0) {  
          if(dom) dom.setAttribute("state", "error");
        } else {
          if(dom) dom.setAttribute("state", "none");
        }

        upsaleDetails.upsaleItems = upsaleItems;
        orderContext.updateCtxUpsaleDetails(upsaleDetails)
        setCustomerShareError(!validateCustomerShare(upsaleItems));
        setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
        setUpsaleItems(upsaleItems);
        handleModified();
        setReload(new Date().toLocaleString());
      };
    }
    }); 
  };

  const validateCustomerShare = (usItems: Array<UpsaleItemDTO>) => {
    const parts = usItems.filter(x => x.partType === "part");
    if(parts.length > 0){
      for(let p of parts){
        if(p.customerShare && p.part.priceBeforeCustomerNet !== undefined && p.customerShare > p.part.priceBeforeCustomerNet) {
          return false;
        }
        if(p.serviceItems.length>0){
          if(p.serviceItems[0].customerShare && p.serviceItems[0].services.priceBeforeCustomerNet && 
            p.serviceItems[0].customerShare > p.serviceItems[0].services.priceBeforeCustomerNet) {
              return false;
          }
        }
      }
    }
    return true;
  }

  const handleAddService = (partId: number) => {
    const resServices = services.filter(
      (x) => x?.serviceNameUpsale === "Installation"
    );
    if(resServices.length<=0){
      return
    }
    
    upsaleItems.map((usItem) => {
      if (usItem.part.id === partId) {
        const sIDTO = {} as ServiceItemDTO;
        sIDTO.winterOrSummer = WinterOrSummer.na;
        sIDTO.customerShare = 0.0;
        sIDTO.commission = 0.0;
        sIDTO.timeUnits = 100.0;
        if (resServices.length > 0) {
          sIDTO.services = resServices[0];
        }

        // Modified for discount
        sIDTO.discount =usItem.part?.partDiscount ? sIDTO.services.priceBeforeCustomerNet * sIDTO.timeUnits / 100 : sIDTO.services.priceInternal * sIDTO.timeUnits / 100 ;
        /** */
        usItem.serviceItems = [sIDTO];

        upsaleDetails.upsaleItems = upsaleItems;
        orderContext.updateCtxUpsaleDetails(upsaleDetails);

        setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
        setUpsaleItems(upsaleItems);
        handleModified();
        setReload(new Date().toLocaleString());
      }
    });
  };

  const handleTimeUnits = (index: number, partId: number, value: string) => {
    upsaleItems.map((usItem, i) => {
      if (index === i && usItem.part.id === partId) {
        usItem.serviceItems[0].timeUnits = formatCurrencyToDecimal(value);

        upsaleDetails.upsaleItems = upsaleItems;
        orderContext.updateCtxUpsaleDetails(upsaleDetails);

        setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
        setUpsaleItems(upsaleItems);
        handleModified();
        setReload(new Date().toLocaleString());
      }
    });
  };

  const handleDelete = (partId: number, serviceId?: number) => {
    upsaleItems.map((usItem, i) => {
      if (usItem.part.id === partId) {
        if (serviceId) {
          usItem.serviceItems = [];
        } else {
          upsaleItems.splice(i, 1);
        }

        upsaleDetails.upsaleItems = upsaleItems;
        orderContext.updateCtxUpsaleDetails(upsaleDetails);

        setTotal(getUpsaleItemsTotal(upsaleItems.filter(x => x.partType === PartType.part)));
        setUpsaleItems(upsaleItems);
        handleModified();
        setReload(new Date().toLocaleString());
      }
    });
  };

  return (
    <>
      <div className="pt10">
        <PFlex>
          <PFlexItem>
            <PTextFieldWrapper
              label={`${t("search")}`}
              hideLabel={true}
              style={{ width: "440px" }}
            >
              <input
                type="search"
                name="search"
                placeholder={`${t("Search for number or name")}`}
                onChange={(e) => handleSearch(e.target.value)}
                value={searchValue}
                autoComplete="off"
                disabled={disabled}
              />
            </PTextFieldWrapper>
            <SearchResult />
          </PFlexItem>
          <PFlexItem style={{ paddingLeft: "8px" }}>
            <PButton
              icon={"add" as IconName}
              style={{
                minWidth: "115px",
                height: "40px",
              }}
              type="button"
              onClick={handleAddPart}
              disabled={disabled}
            >
              {t("Add part")}
            </PButton>
          </PFlexItem>
        </PFlex>
      </div>
      <div className="pt32">
        <PTable>
          <PTableHead>
            <PTableHeadRow>
              <PTableHeadCell className="table-column-header">
                <PorscheFont style={{ fontWeight: "700" }}> {t("PDS / Tequipment")} </PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell
                className="table-column-header"
                style={{ textAlign: "right" }}
              >
                <PorscheFont style={{ fontWeight: "700" }}> {t("Price internal")} </PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell style={{ textAlign: "right" }}>
                <PorscheFont style={{ fontWeight: "700" }}> {t("Selling price (net)")}</PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell style={{ textAlign: "right" }}>
                <PorscheFont style={{ fontWeight: "700", paddingRight: "15px" }}> {t("Customer share")} </PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell style={{ textAlign: "right" }}>
                <PorscheFont style={{ fontWeight: "700" }}> {t("Discount")} </PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell style={{ textAlign: "right" }}>
                <PorscheFont style={{ fontWeight: "700" }}> {t("Commission")} </PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell hideLabel={true}></PTableHeadCell>
              <PTableHeadCell hideLabel={true}></PTableHeadCell>
            </PTableHeadRow>
          </PTableHead>
          <PTableBody>
            {upsaleItems.map((row, i) => {
              if (row.partType === "part") {
                return (
                  <>
                    <PTableRow key={i}>
                      <PTableCell>
                        <PFlex direction={"column"}>
                          <PFlexItem>
                            <PFlex direction={"column"}>
                              <PFlexItem>
                                <PText weight="bold">{row.part.part}</PText>
                              </PFlexItem>
                              <PFlexItem>
                                <PText>{row.part.serialNumber}</PText>
                              </PFlexItem>
                            </PFlex>
                          </PFlexItem>

                          {row.serviceItems.length > 0 && (
                            <PFlexItem className="pt10">
                              <PFlex direction={"row"}>
                                <PFlexItem className="pr6">
                                  <PIcon
                                    className="pt8"
                                    name="wrench"
                                    aria={{ "aria-label": "Wrench icon" }}
                                  />
                                </PFlexItem>
                                <PFlexItem className="pr6">
                                  <PText
                                    size="small"
                                    style={{
                                      paddingLeft: "4px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    {
                                      t(row.serviceItems[0].services.serviceNameUpsale)
                                    }
                                  </PText>
                                </PFlexItem>
                                <PFlexItem>
                                  <PTextFieldWrapper
                                    className="pt16"
                                    label={`timeUnits${row.part.id}`}
                                    hideLabel={true}
                                    style={{
                                      width: "75px",
                                      height: "40px",
                                      lineHeight: "40px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      name={`timeUnits${row.part.id}`}
                                      value={row.serviceItems[0].timeUnits}
                                      disabled={disabled}
                                      onChange={(e) =>
                                        handleTimeUnits(
                                          i, row.part.id,
                                          e.target.value
                                        )
                                      }
                                    //style={{width: "30px", height: "40px"}}
                                    />
                                  </PTextFieldWrapper>
                                </PFlexItem>
                                <PFlexItem>
                                  <PText
                                    size="small"
                                    style={{
                                      paddingLeft: "8px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    {t("Time Units")}
                                  </PText>
                                </PFlexItem>
                              </PFlex>
                            </PFlexItem>
                          )}

                          {row.serviceItems.length === 0 && (
                            <>
                              <br />
                              <PFlexItem>
                                <PButtonPure
                                  icon="add"
                                  size="small"
                                  onClick={() => handleAddService(row.part.id)}
                                  disabled={disabled}
                                  type="button"
                                //disabled={row.serviceItems.length > 0 ? true : false}
                                //style={{marginTop: "-12px", marginBottom: "12px"}}
                                  className='installation'
                                >
                                  {t("Add Installation")}
                                </PButtonPure>
                              </PFlexItem>
                            </>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem>
                            {row.part.priceInternal !== undefined
                              ? formatCurrency(row.part.priceInternal)
                              : "0.00 €"}
                          </PFlexItem>
                          {row.serviceItems.length > 0 && (
                            <PFlexItem className="pt32">
                              {row.serviceItems[0].timeUnits !== undefined
                                ? formatCurrency(
                                  row.serviceItems[0].services.priceInternal * row.serviceItems[0].timeUnits / 100
                                )
                                : "0.00 €"}
                            </PFlexItem>
                          )}

                          {row.serviceItems.length === 0 && (
                            <PFlexItem className="pt32">
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem>
                            {row.part.priceBeforeCustomerNet !== undefined
                              ? formatCurrency(row.part.priceBeforeCustomerNet)
                              : "NA"}
                          </PFlexItem>
                          {row.serviceItems.length > 0 && (
                            <PFlexItem className="pt32">
                              {row.serviceItems[0].timeUnits !== undefined
                                ? formatCurrency(
                                  row.serviceItems[0].services
                                    .priceBeforeCustomerNet * row.serviceItems[0].timeUnits / 100.00
                                )
                                : "0.00 €"}
                            </PFlexItem>
                          )}

                          {row.serviceItems.length === 0 && (
                            <PFlexItem className="pt32">
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem>
                            <PTextFieldWrapper
                              className="pt16"
                              id={`pcustomerShare${i}`}
                              label={`pcustomerShare${i}`}
                              hideLabel={true}
                              unit="€"
                              unitPosition="suffix"
                              showCharacterCount={false}
                            >
                              <input
                                maxLength={inputFieldMaxLength}
                                type="text"
                                name={`pcustomerShare${i}`}
                                defaultValue={formatCurrencyWithNoUnit(row.customerShare)}
                                onChange={(e) =>
                                  handleCustomerShare(row.part.id, e.target.value, i)
                                }
                                onBlur={(e) => e.target.value = formatCurrencyWithNoUnit(row.customerShare)}
                                style={{ textAlign: "right" }}
                                disabled={disabled}
                              />
                            </PTextFieldWrapper>
                          </PFlexItem>

                          {row.serviceItems.length > 0 && (
                            <PFlexItem className="pt10">
                              <PTextFieldWrapper
                                className="pt16"
                                id={`pscustomerShare${row.serviceItems[0].services.id}-${i}`}
                                label={`pscustomerShare${row.serviceItems[0].services.id}`}
                                hideLabel={true}
                                unit="€"
                                unitPosition="suffix"
                                showCharacterCount={false}
                              >
                                <input
                                  maxLength={inputFieldMaxLength}
                                  type="text"
                                  name={`pcustomerShare${row.serviceItems[0].services.id}`}
                                  defaultValue={formatCurrencyWithNoUnit(row.serviceItems[0].customerShare)}
                                  onChange={(e) =>
                                    handleServiceCustomerShare(
                                      row.part.id,
                                      e.target.value,
                                      i
                                    )
                                  }
                                  onBlur={(e) => e.target.value = formatCurrencyWithNoUnit(row.serviceItems[0].customerShare)}
                                  style={{ textAlign: "right" }}
                                  disabled={disabled}
                                />
                              </PTextFieldWrapper>
                            </PFlexItem>
                          )}

                          {row.serviceItems.length === 0 && (
                            <PFlexItem className="pt32">
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem>
                            {/* {row.customerShare > 0 &&
                              row.part.priceBeforeCustomerNet !== undefined
                              ? formatCurrency(
                                row.part.priceBeforeCustomerNet -
                                row.customerShare
                              )
                              : row.part.priceInternal !== undefined
                                ? formatCurrency(row.part.priceInternal)
                                : "-"} */}
                           {/* {row.discount != 0 ?(row.customerShare > 0) && (row.part.partDiscount == true) && (row.part.priceBeforeCustomerNet !== undefined)
                              ? formatCurrency(row.part.priceBeforeCustomerNet - row.customerShare) : (row.customerShare > 0) && (row.part.partDiscount == false) && (row.part.priceInternal !== undefined) ? formatCurrency(row.part.priceInternal - row.customerShare) : '-'
                            : formatCurrency(0.00)}       */}
                            {row?.discount && row?.discount<0 ? formatCurrency(0.00) : formatCurrency(row?.discount)}
                          </PFlexItem>
                          {row.serviceItems.length > 0 && (
                            <PFlexItem className="pt32">
                              {/* {row.serviceItems[0].timeUnits &&
                                row.serviceItems[0].timeUnits > 0
                                ? row.serviceItems[0].customerShare > 0
                                  ? formatCurrency(
                                    (row.serviceItems[0].services
                                      .priceBeforeCustomerNet * row.serviceItems[0].timeUnits / 100) -
                                    row.serviceItems[0].customerShare
                                  )
                                  : formatCurrency(
                                    row.serviceItems[0].services.priceInternal * row.serviceItems[0].timeUnits / 100
                                  )
                                : "0.00 €"} */}
                                {/* {row.serviceItems[0].discount} */}

                                {row?.serviceItems[0]?.discount &&  row.serviceItems[0].discount <0? formatCurrency(0.00):  formatCurrency( row.serviceItems[0].discount)}
                            </PFlexItem>
                          )}

                          {row.serviceItems.length === 0 && (
                            <PFlexItem className="pt32">
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"} alignItems={"flex-end"}>
                          <PFlexItem>{formatCurrency(row.commission)}</PFlexItem>
                          {row.serviceItems.length > 0 && (
                            <PFlexItem className="pt32">
                              {formatCurrency(row.serviceItems[0].commission)}
                            </PFlexItem>
                          )}

                          {row.serviceItems.length === 0 && (
                            <PFlexItem className="pt32">
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>

                      <PTableCell>
                        <PFlex direction={"column"}>
                          <PFlexItem>
                            <CustomDelete
                              partId={row.part.id}
                              message={t("Delete this part") +"?"}
                              handleDelete={(partId) => handleDelete(partId)}
                            />
                          </PFlexItem>
                          {row.serviceItems.length > 0 && (
                            <PFlexItem className="pt32">
                              <CustomDelete
                                partId={row.part.id}
                                serviceId={row.serviceItems[0].services.id}
                                message={t("Delete this service")+"?"}
                                handleDelete={(partId, serviceId) =>
                                  handleDelete(partId, serviceId)
                                }
                              />
                            </PFlexItem>
                          )}

                          {row.serviceItems.length === 0 && (
                            <PFlexItem className="pt32">
                            </PFlexItem>
                          )}
                        </PFlex>
                      </PTableCell>
                    </PTableRow>
                  </>
                );
              }
            })}
            {total.priceInternal > 0 && (
              <PTableRow>
                <PTableCell style={{ fontWeight: "700" }}>{t("Total")}</PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.priceInternal)}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.sellingPriceNet)}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.customerShare)}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.discount)}
                </PTableCell>
                <PTableCell style={{ textAlign: "right", fontWeight: "700" }}>
                  {formatCurrency(total.commission)}
                </PTableCell>
                <PTableCell></PTableCell>
                <PTableCell></PTableCell>
              </PTableRow>
            )}
          </PTableBody>
        </PTable>
        { customerShareError && (
          <PGrid>
            {/* <PGridItem size={8}></PGridItem> */}
            <PGridItem size={12} >
              <Stack direction={"row"} style={{ paddingTop: "24px", textAlign: "center", fontSize: "16px", fontWeight: "400" }}>
                <PIcon name={"information" as IconName} color="brand"></PIcon>
                <PorscheFont style={{fontSize: "16px", color: "#D5001B", marginTop: "0px"}}>
                  {t("Customer share can not be higher than selling price.")}
                </PorscheFont>
              </Stack>
            </PGridItem>
          </PGrid>
        )}

        {upsaleItems.length < 1 && (
          <PGrid>
            <PGridItem
              size={12}
              style={{
                paddingTop: "24px",
                paddingBottom: "15px",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {t("No parts selected yet")}
            </PGridItem>
          </PGrid>
        )}
      </div>

      <div className='flex30 pt20'>
        <Snackbar open={saveSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={2000} onClose={handleSuccessClose}>
          <Alert severity="success" sx={{ width: '100%' }}>
            {t("saved successfully")}!
          </Alert>
        </Snackbar>
        <Snackbar open={isError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={2000} onClose={handleErrorClose}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {t("Customer share can not be higher than selling price.")}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
