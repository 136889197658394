import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
  IconName, PButton, PDivider, PFlex, PFlexItem, PGrid, PGridItem, PIcon,
  PSelectWrapper, PSwitch, PText,
  PTextFieldWrapper, SwitchChangeEvent
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { AuthContext } from '../../../../contexts/AuthContext';
import { GeneralContext } from '../../../../contexts/GeneralContext';
import { IsUserAllowed } from '../../../../helpers/permissions';
import { DeliveryPresentDTO, General } from '../../../../interfaces/GeneralData';
import {
  formatCurrencyToDecimal, formatCurrencyWithNoUnit, onBlurCurrencyFormatter, onBlurPercentageFormatter, validateCurrency,
  validatePercentage
} from '../../../../mock/helper';
import { type } from '../../../../services/Constants';
import { dcsGetGeneralTabs, dcsgetVat } from '../../../../services/data/Helpers';
import ScrollToTop from '../../../common/ScrollToTop';

interface Props {
  edit: boolean;
  changedMode: string;
  modified: () => void;
  enableSaveButton: (editMode: boolean) => void;
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
}
export const GeneralTab = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, modified, setShowSaveButton} = props;
  const [general, setGeneral] = useState<General>({} as General);
  const [reload, setReload] = useState("");
  const generalContext = useContext(GeneralContext);
  const [managedDeliveryPresents, setManagedDeliveryPresents] = useState<
    Array<DeliveryPresentDTO>
  >([]);
  const [deliveryPresents, setDeliveryPresents] = useState<
    Array<DeliveryPresentDTO>
  >([]);
  const [showNc, setShowNc] = useState(false);
  const [showDc, setShowDc] = useState(false);
  const [displayVat, setDisplayVat] = useState(0.00);
  const [vat, setVat] = useState(0.00);
  const [edited, setEdit] = useState<boolean>(false);
  const [rangeId, setRangeId] = useState("");
  const [from, setFrom] = useState<any>(null);
  const [to, setTo] = useState<any>(null);
  const [showRangeError, setShowRangeError] = useState(false);

  
  const authCtx = useContext(AuthContext);
  const [showNew, setShowNew] = useState<boolean>(false);
  const [showDemo, setShowDemo] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<Record<string,boolean>>()
  const tRef = [
    { from: useRef<HTMLInputElement>(null), to: useRef<HTMLInputElement>(null) },
    { from: useRef<HTMLInputElement>(null), to: useRef<HTMLInputElement>(null) },
    { from: useRef<HTMLInputElement>(null), to: useRef<HTMLInputElement>(null) },
    { from: useRef<HTMLInputElement>(null), to: useRef<HTMLInputElement>(null) },
    { from: useRef<HTMLInputElement>(null), to: useRef<HTMLInputElement>(null) }
  ];
  const vatRef = [
    { vat: useRef<HTMLInputElement>(null)}
  ];


  let ncs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

useEffect(()=>{
  if(errorState){
  const finalValue  = Object.values(errorState).includes(true)
  setShowSaveButton(!finalValue)
}
},[errorState])

  // useEffect(() => {
  //   enableSaveButton(edited);
  // }, [edited])

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsGetGeneralTabs()
      if(apiResponse.status===type.SUCCESS){
        const general = apiResponse.response as General

        generalContext.intialize(general);
        setGeneral(generalContext.getGeneralctx());
        setVat(general?.vat?.value);
        if (vatRef[0].vat.current){
          vatRef[0].vat.current.value = formatCurrencyWithNoUnit(general.vat.value);
        }
        setManagedDeliveryPresents(
          generalContext.getGeneralctx().deliveryPresents
        );
        setDeliveryPresents(generalContext.getGeneralctx().deliveryPresentsUsedCar);
        var ncount: number = 0;
        if (generalContext.getGeneralctx().deliveryPresents.length > 0) {
          for (let i = 0; i < generalContext.getGeneralctx().deliveryPresents.length; i++) {
  
            if (generalContext.getGeneralctx().deliveryPresents[i].isActive) {
              ncount = ncount + 1;
            }
          }
  
          if (ncount == generalContext.getGeneralctx().deliveryPresents.length) {
  
            setShowNew(true);
          }
  
        }
        var ucount: number = 0;
        if (generalContext.getGeneralctx().deliveryPresentsUsedCar.length > 0) {
  
          for (let i = 0; i < generalContext.getGeneralctx().deliveryPresentsUsedCar.length; i++) {
            if (generalContext.getGeneralctx().deliveryPresentsUsedCar[i].isActive) {
              ucount = ucount + 1;
            }
          }
  
          if (ucount === generalContext.getGeneralctx().deliveryPresentsUsedCar.length) {
  
            setShowDemo(true);
          }
  
        }
      }
     
      
      // const general = response["result"] as General;
     
    };
    init();
  }, []);

  useEffect(() => {
    generalContext.intialize(general);
    const init = async () => {
      const apiResponse = await dcsgetVat()
      if(apiResponse?.status==="success"){
        const vatValue = apiResponse['response'] 

        let str = vatValue.value.toString();
        const strSplit = str.split(".");
        if (strSplit.length === 1) {
          str = str + "00";
        } else {
          str = strSplit[1].length === 1 ? str + "0" : str;
        }
        setDisplayVat(parseInt(str.replace(".", "")));
      }


    }
    init();
  }, [reload]);

  const handleClickNew = (e: CustomEvent<SwitchChangeEvent>) => {
    setShowNew(e.detail.checked);
    for (let i = 0; i < managedDeliveryPresents.length; i++) {
      managedDeliveryPresents[i].isActive = e.detail.checked;
    }
    setManagedDeliveryPresents(managedDeliveryPresents);
    setReload(new Date().toLocaleString());
    modified();
  };

  const handleClickDemo = (e: CustomEvent<SwitchChangeEvent>) => {
    setShowDemo(e.detail.checked);
    for (let i = 0; i < deliveryPresents.length; i++) {
      deliveryPresents[i].isActive = e.detail.checked;;
    }
    setDeliveryPresents(deliveryPresents);
    setReload(new Date().toLocaleString());
    modified();
  };
  const handleVAT = (e: ChangeEvent<HTMLInputElement>,elementId: string): void => {
    const dom = document.getElementById("vat");
    let elementDomId= dom?.id as string
    if (e.target.value.length > 0) {
      if (validatePercentage(e.target.value)) {
        if (dom) {
          dom.setAttribute("state", "none");
          setErrorState((prev)=>{
            return {...prev, [elementDomId]:false}
          })
        }
        general.vat.value = formatCurrencyToDecimal(e.target.value);
        // Setting vat
        setVat(formatCurrencyToDecimal(e.target.value));

        modified();
        setGeneral(general);
        //setEdit(true);
        
        setReload(new Date().toLocaleString());
       
      } else {
        if (dom) {
          dom.setAttribute("state", "error");
          setErrorState((prev)=>{
            return {...prev, [elementDomId]:true}
          })
        }
      }
    }
    else {
      if (dom) {
          setErrorState((prev)=>{
            return {...prev, [elementDomId]:true}
          })
        dom.setAttribute("state", "error");
      }
    }
  };

  const handleMargin = (e: ChangeEvent<HTMLInputElement>, id: number): void => {
    const dom = document.getElementById(`m${id}`);
    let elementDomId= dom?.id as string
    if (e.target.value.length > 0) {
      setEdit(true);
      if (validatePercentage(e.target.value)) {
        if (dom) {
          // modified();
          dom.setAttribute("state", "none");
          setErrorState((prev)=>{
            return {...prev, [elementDomId]:false}
          })
        }
        general?.margins.map((margin) => {
          if (margin.id == id) {
            margin.value = formatCurrencyToDecimal(e.target.value);
          }
        });

        setGeneral(general);
        setReload(new Date().toLocaleString());
      } else {
        if (dom) {
          dom.setAttribute("state", "error");
          setErrorState((prev)=>{
            return {...prev, [elementDomId]:true}
          })
          // dom.setAttribute("message", t("Please enter a valid value."));
        }
      }
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [elementDomId]:true}
        })
      }
    }
  };

  const handleReferralCommission = (
    e: ChangeEvent<HTMLInputElement>,
    id: number,
    label?:string
  ): void => {
    const dom = document.getElementById(`rc${id}`);
    let elementDomId= dom?.id as string

    if (e.target.value.length > 0) {
      setEdit(true);
      let ref = general?.referallCommissions?.filter(x => x.id === id);

      if(ref && ref.length > 0) {
        if(ref[0].referralType === "Employee") {

          const inputValidationAsperLabel = label==="Internal Referral Employee" ? validateCurrency(e.target.value): validatePercentage(e.target.value)
          if (inputValidationAsperLabel) {
            dom?.setAttribute("state", "none");
            setErrorState((prev)=>{
              return {...prev, [elementDomId]:false}
            })
            general?.referallCommissions.map((referal) => {
              if (referal.id === id) {
                referal.value = formatCurrencyToDecimal(e.target.value);
              }
            });
            modified();
            setGeneral(general);
            setReload(new Date().toLocaleString());
          }
          else {
            dom?.setAttribute("state", "error");
            // dom?.setAttribute("message", t("Please enter a valid value."));
            setErrorState((prev)=>{
              return {...prev, [elementDomId]:true}
            })
          }
         
        } 
        
        else {
          if (validatePercentage(e.target.value)) {
            dom?.setAttribute("state", "none");
            setErrorState((prev)=>{
              return {...prev, [elementDomId]:false}
            })
            general?.referallCommissions.map((referal) => {
              if (referal.id === id) {
                referal.value = formatCurrencyToDecimal(e.target.value);
                //return true;
              }
            });
    
            modified();
            setGeneral(general);
            setReload(new Date().toLocaleString());
          } else {
            dom?.setAttribute("state", "error");
            // dom?.setAttribute("message", t("Please enter a valid value."));
            setErrorState((prev)=>{
              return {...prev, [elementDomId]:true}
            })
          }
        }
      }
      
    } else {
      dom?.setAttribute("state", "error");
      // dom?.setAttribute("message", t("Please enter a valid value."));
      setErrorState((prev)=>{
        return {...prev, [elementDomId]:true}
      })
    }
  };

  const handleLocations = (value: string, id: number): void => {
    if (value === "default") return;
    if (general?.deliveryPresents.length > 0) {
      setEdit(true);
      general?.deliveryPresents.map((deliveryPresent) => {
        if (deliveryPresent.id === id) {
          deliveryPresent.locations[0] = value;
        }
      });

      setDeliveryPresents(general?.deliveryPresents);
      setGeneral(general);

      setReload(new Date().toLocaleString());
      modified();
    }
  };

  const checkSelected = (locations: Array<string>, value: string): boolean => {
    const selected = locations.indexOf(value);
    return selected > -1 ? true : false;
  }

  const handleNcContent = (ncid: number, value: string, index: number) => {
    general?.deliveryPresents.map((deliveryPresent) => {
      if (deliveryPresent.id === ncid) {
        setEdit(true);
        if (deliveryPresent.contents.length > 0) {
          if (deliveryPresent.contents[index] !== undefined) {
            deliveryPresent.contents[index] = value;
          } else {
            deliveryPresent.contents.push(value);
          }
        } else {
          deliveryPresent.contents = [value];
        }
        modified();
      }
    })

    setGeneral(general);
    setReload(new Date().toLocaleString());
  }

  const handleUcContent = (ucid: number, value: string, index: number) => {
    general?.deliveryPresentsUsedCar.map((deliveryPresent) => {
      if (deliveryPresent.id === ucid) {
        setEdit(true);
        if (deliveryPresent.contents.length > 0) {
          if (deliveryPresent.contents[index] !== undefined) {
            deliveryPresent.contents[index] = value;
          } else {
            deliveryPresent.contents.push(value);
          }
        } else {
          deliveryPresent.contents = [value];
        }
        modified();
      }
    })

    setGeneral(general);
    setReload(new Date().toLocaleString());
  }

  const handleNcName = (value: string, id: number) => {
    general?.deliveryPresents.map((deliveryPresent) => {
      if (deliveryPresent.id === id) {
        setEdit(true);
        deliveryPresent.presentName = value;
        modified();
      }
    })

    setGeneral(general);
    setReload(new Date().toLocaleString());
  }

  const handleUcName = (value: string, id: number) => {
    general?.deliveryPresentsUsedCar.map((deliveryPresent) => {
      if (deliveryPresent.id === id) {
        setEdit(true);
        deliveryPresent.presentName = value;
        modified();
      }
    })

    setGeneral(general);
    setReload(new Date().toLocaleString());
  }

  const handleRange = (id: string) => {
    setShowRangeError(false);
    if (rangeId === id) {
      setRangeId("");
    } else {
      setRangeId("");
      setRangeId(id);
    }
  }

  const handleApply = (id: number) => {
    if (from === null) {
      general?.deliveryPresentsUsedCar.map((deliveryPresent) => {
        if (deliveryPresent.id === id) {
          setTo(deliveryPresent.sellingPrinceTo);
        }
      })
    }
    if (to === null) {
      general?.deliveryPresentsUsedCar.map((deliveryPresent) => {
        if (deliveryPresent.id === id) {
          setTo(deliveryPresent.sellingPrinceTo);
        }
      })
    }
    const isValid = (from !== null && to !== null) && from > to ? false : true;
    if (isValid) {
      general?.deliveryPresentsUsedCar.map((deliveryPresent) => {
        if (deliveryPresent.id === id) {
          setEdit(true);
          deliveryPresent.sellingPriceFrom = from !== null ? from : deliveryPresent.sellingPriceFrom;
          deliveryPresent.sellingPrinceTo = to !== null ? to : deliveryPresent.sellingPrinceTo;
        }
      })
      setShowRangeError(false);
      setDeliveryPresents(general.deliveryPresentsUsedCar);
      setGeneral(general);
      setRangeId("");
      modified();
      setReload(new Date().toLocaleString());
    } else {
      setShowRangeError(true);
    }
  }

  const handleFrom = (value: string, id: number, i: number) => {
    let fromDom = document.getElementById(`from-${id}`);
    let toDom = document.getElementById(`to-${id}`);
    let fromDomid = fromDom?.id as string

    // fromDom?.setAttribute("state", "error");

    switch (i) {
      case 0:
        if (tRef[0] && tRef[0].from.current && tRef[0].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[0].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[0].to.current.value)
          const isValid = fromRef < toRef ? false : true;
          if ( !validateCurrency(value) && isValid === true) {
            fromDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setFrom(formatCurrencyToDecimal(value));
          } else {
            fromDom?.setAttribute("state", "none");
            toDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setFrom(formatCurrencyToDecimal(value));
          }
        }
        break;
      case 1:
        if (tRef[1] && tRef[1].from.current && tRef[1].to.current) {
          let from = formatCurrencyToDecimal(tRef[1].from.current.value)
          let to = formatCurrencyToDecimal(tRef[1].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = from < to ? true : false;
          if (!validateCurrency(value) && isValid === false ) {
            fromDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setFrom(formatCurrencyToDecimal(value));
          } else {
            fromDom?.setAttribute("state", "none");
            toDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setFrom(formatCurrencyToDecimal(value));
          }
        }
        break;
      case 2:
        if (tRef[2] && tRef[2].from.current && tRef[2].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[2].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[2].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = fromRef < toRef ? true : false;
          if (!validateCurrency(value) && isValid === false) {
            fromDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setFrom(formatCurrencyToDecimal(value));
          } else {
            fromDom?.setAttribute("state", "none");
            toDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setFrom(formatCurrencyToDecimal(value));
          }
        }
        break;
      case 3:
        if (tRef[3] && tRef[3].from.current && tRef[3].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[3].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[3].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = fromRef < toRef ? true : false;
          if (!validateCurrency(value) && isValid === false) {
            fromDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setFrom(formatCurrencyToDecimal(value));
          } else {
            fromDom?.setAttribute("state", "none");
            toDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setFrom(formatCurrencyToDecimal(value));
          }
        }
        break;
      case 4:
        if (tRef[4] && tRef[4].from.current && tRef[4].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[4].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[4].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = fromRef < toRef ? true : false;
          if (!validateCurrency(value) && isValid === false) {
            fromDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setFrom(formatCurrencyToDecimal(value));
          } else {
            fromDom?.setAttribute("state", "none");
            toDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setFrom(formatCurrencyToDecimal(value));
          }
        }
        break;
    }

    if (to === null) {
      general?.deliveryPresentsUsedCar.map((deliveryPresent) => {
        if (deliveryPresent.id === id) {
          setTo(deliveryPresent.sellingPrinceTo);
          // setFrom(formatCurrencyToDecimal(value));
        }
      })
    }
    modified();

  }

  const handleTo = (value: string, id: number, i: number) => {

    // setShowRangeError(0);
    let toDom = document.getElementById(`to-${id}`);
    let fromDom = document.getElementById(`from-${id}`);
    switch (i) {
      case 0:
        if (tRef[0] && tRef[0].from.current && tRef[0].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[0].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[0].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = fromRef < toRef ? true : false;
          if (isValid === false) {
            toDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setTo(formatCurrencyToDecimal(value));
          } else {
            toDom?.setAttribute("state", "none");
            fromDom?.setAttribute("state", "none");

            setShowRangeError(false);
            setTo(formatCurrencyToDecimal(value));
          }
        }
        break;
      case 1:
        if (tRef[1] && tRef[1].from.current && tRef[1].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[1].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[1].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = fromRef < toRef ? true : false;
          if (isValid === false) {
            toDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setTo(formatCurrencyToDecimal(value));
          } else {
            toDom?.setAttribute("state", "none");
            fromDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setTo(formatCurrencyToDecimal(value));
          }
        }
        break;
      case 2:
        if (tRef[2] && tRef[2].from.current && tRef[2].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[2].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[2].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = fromRef < toRef ? true : false;
          if (isValid === false) {
            toDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setTo(formatCurrencyToDecimal(value));
          } else {
            toDom?.setAttribute("state", "none");
            fromDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setTo(formatCurrencyToDecimal(value));
          }
        }
        break;
      case 3:
        if (tRef[3] && tRef[3].from.current && tRef[3].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[3].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[3].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = fromRef < toRef ? true : false;
          if (isValid === false) {
            toDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setTo(formatCurrencyToDecimal(value));
          } else {
            toDom?.setAttribute("state", "none");
            fromDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setTo(formatCurrencyToDecimal(value));
          }
        }
        break;
      case 4:
        if (tRef[4] && tRef[4].from.current && tRef[4].to.current) {
          let fromRef = formatCurrencyToDecimal(tRef[4].from.current.value)
          let toRef = formatCurrencyToDecimal(tRef[4].to.current.value)
          // tRef[0].to.current.value = formatCurrencyWithNoUnit(tradeInFields[0].amountGross);
          const isValid = fromRef < toRef ? true : false;
          if (isValid === false) {
            toDom?.setAttribute("state", "error");
            setShowRangeError(true);
            setTo(formatCurrencyToDecimal(value));
          } else {
            toDom?.setAttribute("state", "none");
            fromDom?.setAttribute("state", "none");
            setShowRangeError(false);
            setTo(formatCurrencyToDecimal(value));
          }
        }
        break;
    }

    if (from === null) {
      general?.deliveryPresentsUsedCar.map((deliveryPresent) => {
        if (deliveryPresent.id === id) {
          setFrom(deliveryPresent.sellingPriceFrom);
        }
      })
    }
    modified();

  }
 

  return (
    <>
      <div className="p1" style={{paddingTop: "30px"}}>
        <ScrollToTop />
        <PorscheFont className="headding">{t('Value added tax (VAT)')}</PorscheFont>
        <div className="vatText">
          <PorscheFont>{t('VAT')} <span style={{ color: "#D5001B" }}>*</span></PorscheFont>
        </div>
        <div style={{width:"200px"}}>
          <PTextFieldWrapper id={`vat`} unit="%" unitPosition="suffix">
            <input
              aria-autocomplete='none'
              autoComplete='off'
              id="vat1"
              key="vat"
              type="text"
              // defaultValue={pfs}
              ref={vatRef[0].vat}
              defaultValue={formatCurrencyWithNoUnit(general?.vat?.value)}
              // value={pfs}
              onChange={(e) => handleVAT(e,"vat")}
            onBlur={(e)=>{onBlurPercentageFormatter(e)}}
              disabled={edit ? false : true}
            />
          </PTextFieldWrapper>
        </div>
      </div>
      <div className="p1">
        <PorscheFont className="headding">{t('Margins')}</PorscheFont>
        <br />
        <PFlex>
          <PFlexItem width="one-quarter" className="sub-headding">
            <PorscheFont>{t('Margins')}</PorscheFont>
          </PFlexItem>
          <PFlexItem width="one-quarter" className="sub-headding">
            <PorscheFont>{t("Value")}</PorscheFont>
          </PFlexItem>
          <PFlexItem width="one-quarter" className="sub-headding">
            <PorscheFont>{t('Reference')}</PorscheFont>
          </PFlexItem>
        </PFlex>
        <PDivider color="neutral-contrast-high" />
        <br />
        <br />

        {general?.margins?.map((margin, i) => {
          return (
            <>
              <PFlex key={i}>
                <PFlexItem width="one-quarter" className="fields">
                  <span style={{ height: "40px", lineHeight: "45px" }}>
                    <PorscheFont> {t(margin.label.trimEnd())} </PorscheFont>
                  </span>
                </PFlexItem>
                <PFlexItem width="one-quarter">
                  <div className="value-box">
                    <PTextFieldWrapper id={`m${margin.id}`} className="fields" unit="%"
                      unitPosition="suffix">
                      <input
                        type="text"
                        defaultValue={formatCurrencyWithNoUnit(margin.value)}
                        required
                        onChange={(e) => handleMargin(e, margin.id)}
                        onBlur={(e)=>{
                          onBlurPercentageFormatter(e)
                        }}
                        disabled={edit ? false : true}
                      />
                    </PTextFieldWrapper>
                  </div>
                </PFlexItem>
                <br />
                <PFlexItem width="one-quarter">
                  <div className="value-box">
                    <PTextFieldWrapper className="fields">
                      <input
                        type="text"
                        value={`${t(margin.reference.replace("P(", "P ("))}`}
                        style={{ backgroundColor: "grey" }}
                        disabled={edit ? false : true}
                        readOnly
                      />
                    </PTextFieldWrapper>
                  </div>
                </PFlexItem>
              </PFlex>
              <br />
              <br />
            </>
          );
        })}
      </div>
      <div className="p1">
        <PorscheFont className="headding">{t('Referral commission')}</PorscheFont>
        <div className="p1">
          <PFlex>
            <PFlexItem width="one-quarter" className="sub-headding">
              <PorscheFont>{t('Referral')}</PorscheFont>
            </PFlexItem>
            <PFlexItem width="one-quarter" className="sub-headding">
              <PorscheFont>{t("Value")}</PorscheFont>
            </PFlexItem>
            <PFlexItem width="one-quarter" className="sub-headding">
              <PorscheFont>{t('Reference')}</PorscheFont>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-high" />
          <br />
          <br />
          {general?.referallCommissions?.map((commission, i) => {
            return (
              <>
                <PFlex key={i}>
                  <PFlexItem width="one-quarter" className="fields">
                    <span style={{ height: "40px", lineHeight: "45px", fontFamily: "Porsche Next" }}>
                      {t(commission.label)}{" "}
                    </span>
                  </PFlexItem>
                  <PFlexItem width="one-quarter">
                    <div className="value-box">
                      <PTextFieldWrapper
                        id={`rc${commission.id}`}
                        className="fields"
                        unit={commission.label === "Internal Referral Employee" ? "€" : "%"}
                        unitPosition="suffix">
                        <input
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(commission.value)}
                          onChange={(e) =>
                            handleReferralCommission(e, commission.id, commission.label )
                          }
                          onBlur={(e)=>{
                            onBlurPercentageFormatter(e)
                          }}                    
                          disabled={edit ? false : true}
                        />
                      </PTextFieldWrapper>
                    </div>
                  </PFlexItem>
                  <br />
                  <PFlexItem>
                    <div className="value-box">
                      {i < 2 && (
                        <PTextFieldWrapper className="fields">
                          <input
                            type="text"
                            value={`${t(commission.reference.replace("P(", "P ("))}`}
                            style={{ backgroundColor: "grey" }}
                            disabled={edit ? false : true}
                            readOnly
                          />
                        </PTextFieldWrapper>
                      )}

                      {i === 2 && (
                        <PTextFieldWrapper className="fields">
                          <input
                            type="text"
                            value={`${t("Selling price (net)")}`}
                            style={{ backgroundColor: "grey" }}
                            disabled={edit ? false : true}
                            readOnly
                          />
                        </PTextFieldWrapper>
                      )}
                    </div>
                  </PFlexItem>
                </PFlex>{" "}
                <br />
                <br />
              </>
            );
          })}
          <PDivider color="neutral-contrast-low" />
          <br />
          <br />
          <PIcon name={"information" as IconName}></PIcon> {t('Value serves as maximum values for referral commissions in the calculation.')}
        </div>
      </div>

      <div className="delivery-present">
        <Stack direction="row" spacing={1}>
          <PorscheFont className="headding">{t("Delivery presents - New Car")}</PorscheFont>
          <PSwitch
            alignLabel="left"
            style={{ paddingTop: "8px" }}

            checked={showNew}
            onSwitchChange={(e) =>
              handleClickNew(e)
            }
            disabled={IsUserAllowed("adminVariables", authCtx) && edit ? false : true}
          >
            {" "}
          </PSwitch>{" "}
        </Stack>
        {showNew && (
          <>
            <div style={{ marginTop: "10px" }}>
              <PIcon name={"information" as IconName}></PIcon> {t("Delivery presents")}&nbsp;
              {t("can be individually named.")}
            </div>
            <div style={{ marginTop: "20px" }}>
              <PFlex>
                <PFlexItem>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</PFlexItem>
                {managedDeliveryPresents.map((deliveryPresent, i) => {
                  return (
                    <PFlexItem style={{ marginRight: "24px" }} key={i}>
                      <PFlex direction={"column"} style={{ width: "175px" }}>
                        <PFlexItem style={{ marginBottom: "10px" }}>
                          {edit && (
                            <PTextFieldWrapper>
                              <input
                                type="text"
                                defaultValue={deliveryPresent.presentName}
                                onChange={(e) => handleNcName(e.target.value, deliveryPresent.id)} />
                            </PTextFieldWrapper>
                          )}

                          {!edit && (
                            <PText size={"small"} weight="bold" style={{'minHeight': "25px"}}>{deliveryPresent.presentName}</PText>
                          )}
                        </PFlexItem>
                        <PFlexItem>
                          <PSelectWrapper>
                            <select
                              onChange={(e) => handleLocations(e.target.value, deliveryPresent.id)}
                              disabled={edit ? false : true}
                            >
                              <option value="" style={{ color: "grey" }}>{t("Select Availability")}</option>
                              <option
                                selected={checkSelected(deliveryPresent.locations, "PorscheCenter")}
                                value="PorscheCenter"
                              >
                                {t('Porsche Center')}
                              </option>
                              <option
                                value="FactoryPickup"
                                selected={checkSelected(deliveryPresent.locations, "FactoryPickup")}
                              >
                                {t("Factory")}
                              </option>
                            </select>
                          </PSelectWrapper>
                        </PFlexItem>
                      </PFlex>
                    </PFlexItem>
                  )
                })}
              </PFlex>
            </div>

            <div style={{ marginTop: "16px", marginBottom: "16px" }}>
              <PDivider color="neutral-contrast-high" />
            </div>
            <div>
              <PFlex style={{paddingLeft: "0px", marginLeft: "-8px"}}>
                <PFlexItem style={{ marginRight: "8px" }}>
                  <PFlex direction={"column"} style={{ width: "25px" }}>
                    {ncs.map((nc, i) => {
                      if (i < 5) {
                        return (
                          <PFlexItem style={{ height: "48px", lineHeight: "48px", textAlign: "center", marginBottom: "10px" }}>
                            <span style={{ fontSize: "16px", fontWeight: "700" }}>{nc}.</span>
                          </PFlexItem>
                        )
                      }
                    })
                    }

                    {showNc && (
                      <>
                        {ncs.map((nc, i) => {
                          if (i > 4) {
                            return (
                              <PFlexItem style={{ height: "48px", lineHeight: "48px", textAlign: "center", marginBottom: "10px" }}>
                                <span style={{ fontSize: "16px", fontWeight: "700" }}>{nc}.</span>
                              </PFlexItem>
                            )
                          }
                        })
                        }
                      </>
                    )}

                  </PFlex>
                </PFlexItem>
                {managedDeliveryPresents.map((deliveryPresent, i) => {
                  return (
                    <>
                      <PFlexItem style={{ marginRight: "24px" }}>
                        <PFlex direction={"column"} style={{ width: "175px" }}>
                          {ncs.map((nc, i) => {
                            if (i < 5) {
                              return (
                                <PFlexItem style={{ marginBottom: "10px" }} key={i}>
                                  <PTextFieldWrapper>
                                    <input
                                      id={`ncc${i}`}
                                      name={`ncc${i}`}
                                      type="text"
                                      defaultValue={
                                        deliveryPresent.contents.length > 0 && deliveryPresent.contents[i] !== undefined
                                          ? deliveryPresent.contents[i]
                                          : ""
                                      }
                                      onChange={(e) => { handleNcContent(deliveryPresent.id, e.target.value, i) }}
                                      disabled={edit ? false : true}
                                    />
                                  </PTextFieldWrapper>
                                </PFlexItem>
                              )
                            }
                          })
                          }

                          {showNc && (
                            <>
                              {ncs.map((nc, i) => {
                                if (i > 4) {
                                  return (
                                    <PFlexItem style={{ marginBottom: "10px" }} key={i}>
                                      <PTextFieldWrapper>
                                        <input
                                          id={`ncc${i}`}
                                          name={`ncc${i}`}
                                          type="text"
                                          defaultValue={
                                            deliveryPresent.contents.length > 0 && deliveryPresent.contents[i] !== undefined
                                              ? deliveryPresent.contents[i]
                                              : ""
                                          }
                                          onChange={(e) => { handleNcContent(deliveryPresent.id, e.target.value, i) }}
                                          disabled={edit ? false : true}
                                        />
                                      </PTextFieldWrapper>
                                    </PFlexItem>
                                  )
                                }
                              })
                              }
                            </>
                          )}
                        </PFlex>
                      </PFlexItem>
                    </>
                  )
                })
                }
              </PFlex>
            </div>
          </>
        )}
      </div>
      {showNew && (
        <div style={{ margin: "0px", height: "32px", background: "#313639" }} onClick={() => setShowNc(!showNc)}>
          <PFlex direction="column" >
            <PFlexItem alignSelf={"center"}>
              <Stack direction={"row"} style={{ marginTop: "4px" }}>
                <span style={{ fontSize: "16px", fontWeight: "400", color: "#FFFFFF", marginRight: "8px" }}>{showNc ? t("Collapse") : t("Expand")}</span>
                <PIcon name={showNc ? "arrow-head-up" : "arrow-head-down"} color="neutral-contrast-low" />
              </Stack>
            </PFlexItem>
          </PFlex>
        </div>
      )}

      {/* Delivery Present Demo Car */}
      <div className="delivery-present">
        <Stack direction="row" spacing={1}>
          <PorscheFont className="headding">{t("Delivery presents - Demo Car & Used Car")}</PorscheFont>
          <PSwitch
            alignLabel="left"
            checked={showDemo}
            style={{ paddingTop: "8px" }}
            onSwitchChange={(e) =>
              handleClickDemo(e)
            }
            disabled={IsUserAllowed("adminVariables", authCtx) && edit ? false : true}
          >
            {" "}
          </PSwitch>{" "}
        </Stack>
        {showDemo && (
          <>
            <div style={{ marginTop: "10px" }}>
              <PIcon name={"information" as IconName}></PIcon> {t("Delivery presents")}&nbsp;
              {t("can be individually named.")}
            </div>
            <div style={{ marginTop: "20px" }}>
              <PFlex>
                <PFlexItem>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</PFlexItem>
                {deliveryPresents.map((deliveryPresent, i) => {
                  return (
                    <PFlexItem style={{ marginRight: "24px" }} key={i}>
                      <PFlex direction={"column"} style={{ width: "175px" }}>
                        <PFlexItem style={{ marginBottom: "10px" }}>
                          {edit && (
                            <PTextFieldWrapper>
                              <input
                                type="text"
                                defaultValue={deliveryPresent.presentName}
                                onChange={(e) => handleUcName(e.target.value, deliveryPresent.id)}
                                style={{ fontSize: "12px" }}
                              />
                            </PTextFieldWrapper>
                          )}

                          {!edit && (
                            <PText size={"small"} weight="bold" style={{ fontSize: "12px" }}>{deliveryPresent.presentName}</PText>
                          )}
                        </PFlexItem>
                        <PFlexItem>
                          <PFlex
                            justifyContent={"space-between"}
                            className="range"
                            onClick={() => edit ? handleRange(`range-${deliveryPresent.id}`) : {}}
                            onChange={(e) => setRangeId("")}
                            // onBlur={(e) => setRangeId("")}
                            style={{ fontSize: "12px", width: "175px" }}
                          >
                            <PFlexItem style={{ paddingTop: "2px" }}>
                              {`${formatCurrencyWithNoUnit(deliveryPresent.sellingPriceFrom)} - ${formatCurrencyWithNoUnit(deliveryPresent.sellingPrinceTo)} €`}
                            </PFlexItem>
                            <PFlexItem>
                              <PIcon name={rangeId === `range-${deliveryPresent.id}` ? "arrow-head-up" : "arrow-head-down"} />
                            </PFlexItem>
                          </PFlex>
                          {rangeId === `range-${deliveryPresent.id}` && (
                            <>
                              <div id={`range-card-${deliveryPresent.id}`} className="range-card" style={(i > 3 && window.innerWidth >= 1280 && window.innerWidth <= 1366) ? { right: "80px" } : {}}>
                                {/* <div id={`range-card-${deliveryPresent.id}`} className="range-card"> */}
                                <PGrid>
                                  <PGridItem size={4} className="dcs-heading">{t('From')}</PGridItem>
                                  <PGridItem size={4} className="dcs-heading">{t("To")}</PGridItem>
                                  <PGridItem size={4} className="dcs-heading"></PGridItem>
                                </PGrid>
                                <PGrid>
                                  <PGridItem size={4} >
                                    <PTextFieldWrapper
                                      id={`from-${deliveryPresent.id}`}
                                      className="text-field"
                                      label={`from-${deliveryPresent.id}`}
                                      hideLabel={true}
                                      unit="€"
                                      unitPosition="suffix"
                                      style={{ marginRight: "-32px" }}
                                    >
                                      <input
                                        id={`ifrom-${deliveryPresent.id}`}
                                        type="text"
                                        name={`ifrom-${deliveryPresent.id}`}
                                        defaultValue={formatCurrencyWithNoUnit(deliveryPresent.sellingPriceFrom)}
                                        ref={tRef[i].from}
                                        onBlur={(e)=>{
                                          onBlurCurrencyFormatter(e)
                                        }}
                                        onChange={(e) => handleFrom(e.target.value, deliveryPresent.id, i)}
                                        disabled={edit ? false : true}
                                        style={{ textAlign: "right" }}
                                      />
                                    </PTextFieldWrapper>
                                  </PGridItem>
                                  <PGridItem size={4}>
                                    <PTextFieldWrapper
                                      id={`to-${deliveryPresent.id}`}
                                      className="text-field"
                                      label={`to-${deliveryPresent.id}`}
                                      hideLabel={true}
                                      unit="€"
                                      unitPosition="suffix"
                                      style={{ marginRight: "-32px" }}
                                    >
                                      <input
                                        id={`ito-${deliveryPresent.id}`}
                                        type="text"
                                        name={`ito-${deliveryPresent.id}`}
                                        defaultValue={formatCurrencyWithNoUnit(deliveryPresent.sellingPrinceTo)}
                                        onBlur={(e)=>{
                                          onBlurCurrencyFormatter(e)
                                        }}
                                        ref={tRef[i].to}
                                        onChange={(e) => handleTo(e.target.value, deliveryPresent.id, i)}
                                        disabled={edit ? false : true}
                                        style={{ textAlign: "right" }}
                                      />
                                    </PTextFieldWrapper>
                                  </PGridItem>
                                  <PGridItem size={4}>
                                    {/* <PButtonPure id="apply-btn" className='apply-btn' icon="none" size={"medium"} onClick={handleApply}><span className="dcs-heading">Apply</span></PButtonPure> */}
                                    <PButton variant='tertiary' onClick={() => handleApply(deliveryPresent.id)} disabled={showRangeError ? true : false}><span className="apply" style={{ marginRight: "10px", paddingRight: "-40px" }}>{t("Apply")}</span></PButton>
                                  </PGridItem>
                                </PGrid>
                                {/* {showRangeError && (
                                  <PGrid>
                                    <PGridItem size={12}>
                                      <PorscheFont><span style={{ color: "#D5001B", paddingBottom: "5px" }}>{t("Please enter a valid value.")}</span></PorscheFont>
                                    </PGridItem>
                                  </PGrid>
                                )} */}

                              </div>
                            </>
                          )}
                        </PFlexItem>
                      </PFlex>
                    </PFlexItem>
                  )
                })}
              </PFlex>
            </div>

            <div style={{ marginTop: "16px", marginBottom: "16px" }}>
              <PDivider color="neutral-contrast-high" />
            </div>
            <div>
              <PFlex style={{paddingLeft: "0px", marginLeft: "-8px"}}>
                <PFlexItem style={{ marginRight: "8px" }}>
                  <PFlex direction={"column"} style={{ width: "25px" }}>
                    {ncs.map((nc, i) => {
                      if (i < 5) {
                        return (
                          <PFlexItem style={{ height: "48px", lineHeight: "48px", textAlign: "center", marginBottom: "10px" }}>
                            <span style={{ fontSize: "16px", fontWeight: "700" }}>{nc}.</span>
                          </PFlexItem>
                        )
                      }
                    })
                    }

                    {showDc && (
                      <>
                        {ncs.map((nc, i) => {
                          if (i > 4) {
                            return (
                              <PFlexItem style={{ height: "48px", lineHeight: "48px", textAlign: "center", marginBottom: "10px" }}>
                                <span style={{ fontSize: "16px", fontWeight: "700" }}>{nc}.</span>
                              </PFlexItem>
                            )
                          }
                        })
                        }
                      </>
                    )}

                  </PFlex>
                </PFlexItem>
                {deliveryPresents.map((deliveryPresent, i) => {
                  return (
                    <>
                      <PFlexItem style={{ marginRight: "24px" }}>
                        <PFlex direction={"column"} style={{ width: "175px" }}>
                          {ncs.map((nc, i) => {
                            if (i < 5) {
                              return (
                                <PFlexItem style={{ marginBottom: "10px" }} key={i}>
                                  <PTextFieldWrapper>
                                    <input
                                      id={`ncc${i}`}
                                      name={`ncc${i}`}
                                      type="text"
                                      defaultValue={
                                        deliveryPresent.contents.length > 0 && deliveryPresent.contents[i] !== undefined
                                          ? deliveryPresent.contents[i]
                                          : ""
                                      }
                                      onChange={(e) => { handleUcContent(deliveryPresent.id, e.target.value, i) }}
                                      disabled={edit ? false : true}
                                    />
                                  </PTextFieldWrapper>
                                </PFlexItem>
                              )
                            }
                          })
                          }

                          {showDc && (
                            <>
                              {ncs.map((nc, i) => {
                                if (i > 4) {
                                  return (
                                    <PFlexItem style={{ marginBottom: "10px" }} key={i}>
                                      <PTextFieldWrapper>
                                        <input
                                          id={`ncc${i}`}
                                          name={`ncc${i}`}
                                          type="text"
                                          defaultValue={
                                            deliveryPresent.contents.length > 0 && deliveryPresent.contents[i] !== undefined
                                              ? deliveryPresent.contents[i]
                                              : ""
                                          }
                                          onChange={(e) => { handleUcContent(deliveryPresent.id, e.target.value, i) }}
                                          disabled={edit ? false : true}
                                        />
                                      </PTextFieldWrapper>
                                    </PFlexItem>
                                  )
                                }
                              })
                              }
                            </>
                          )}
                        </PFlex>
                      </PFlexItem>
                    </>
                  )
                })
                }
              </PFlex>
            </div>

          </>
        )}
      </div>
      {showDemo && (
        <div style={{ margin: "0px", height: "32px", background: "#313639" }} onClick={() => setShowDc(!showDc)}>
          <PFlex direction="column" >
            <PFlexItem alignSelf={"center"}>
              <Stack direction={"row"} style={{ marginTop: "4px" }}>
                <span style={{ fontSize: "16px", fontWeight: "400", color: "#FFFFFF", marginRight: "8px" }}>{showDc ? t("Collapse") : t("Expand")}</span>
                <PIcon name={showDc ? "arrow-head-up" : "arrow-head-down"} color="neutral-contrast-low" />
              </Stack>
            </PFlexItem>
          </PFlex>
        </div>
      )}


    </>
  );
};
function handleNcContent(id: any, value: any, index: any) {
  throw new Error('Function not implemented.');
}
