import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    PTable, PTableBody, PTableCell, PTableHead, PTableHeadCell, PTableHeadRow, PTableRow, PText,
    PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { GeneralContext } from '../../../contexts/GeneralContext';
import { GetPaw } from '../../../interfaces/Variables';
import {
    formatCurrency, formatCurrencyToDecimal, formatCurrencyWithNoUnit, onBlurCurrencyFormatter, validateCurrency
} from '../../../mock/helper';
import { dcsgetPaw, getPaw } from '../../../services/data/Helpers';
import { variablesError } from '../../../services/General';
import ScrollToTop from '../../common/ScrollToTop';
import { type } from '../../../services/Constants';

interface Props {
  edit: boolean;
  save: boolean;
  cancel: boolean;
  changedMode: string;
  handlePawPost: (paw: Array<GetPaw>) => void;
  modified: () => void;
  showHeader:() => void;
  removeHeader:()=> void; 
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Paw = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, save, cancel, handlePawPost, modified,showHeader,removeHeader, setShowSaveButton } = props;
  const [pawData, setPawData] = useState<Array<GetPaw>>([]);
  const [ reload, setReload ] = useState("");
  const [ varriableError, setVariableError ] = useState<variablesError>({} as variablesError);
  const generalContext = useContext(GeneralContext)
  const [errorState, setErrorState] = useState<Record<string,boolean>>()

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetPaw();
      if (apiResponse?.status ===type.SUCCESS) {
        setPawData(apiResponse?.response);
        setVariableError(generalContext.getErrors());
      }
    };
    init();
  }, []);

  useEffect(()=>{
    if(errorState){
    const isErrorPresent  = Object.values(errorState).includes(true)
    setShowSaveButton(!isErrorPresent)
  }
  },[errorState])

  useEffect(() => {
    if (cancel) {
      const init = async () => {
        const apiResponse = await dcsgetPaw();
        if (apiResponse?.status === type.SUCCESS) {
          setPawData(apiResponse?.response);
        }
      };

      init();
    }
  }, [cancel]);

  const checkRange = (value: string) => {
    const valuNumber = parseFloat(value);
    if (valuNumber < 0.00) {
      return false;
    } else if (valuNumber > 1000000.00) {
      return false;
    } else {
      return true;
    }
  };

  const REGEX_INPUT_EXP = /^[0-9]\d{0,6}((\,\d{0,2})?)$/;

  const validateInput = (value: string): boolean => {

    if (REGEX_INPUT_EXP.test(value)) {
      if (value.includes(",")) {
        return checkRange(value.replace(",", "."));
      } else {
        return checkRange(value);
      }
    } else {
      if (value === "") {
        return false;
      }

      if (/\,\d{3}$/g.test(value)) {
        return checkRange(value.replace(",", "."));
      }

      return false;
    }
  };


  const handlePawChange = (value: string, id: number, duration: number, type: string) => {
    modified();
    let domId = type === "paw" ? "p" : "a";
    switch(duration) {
      case 12:
        domId = domId + "12" + id.toString();
        break;
        case 24:
          domId = domId + "24" + id.toString();
          break;
          case 36:
            domId = domId + "36" + id.toString();
            break;
          }
          
          let dom = document.getElementById(domId);
          const pawInputWrapperId = dom?.id as string
    
    if (validateCurrency(value)) {
      if(dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [pawInputWrapperId]:false}
      })

      pawData.forEach(x => {
        if (x.id === id) {
          switch(duration) {
            case 12:
              if (type === "paw") {
                x.pawMonth12Price = formatCurrencyToDecimal(value);
                dom = document.getElementById("p120");
              }
              
              if (type === "assistance") {
                x.assistanceMonth12Price = formatCurrencyToDecimal(value);
                dom = document.getElementById("a120");
              }
  
              break;
            case 24:
              if (type === "paw") {
                x.pawMonth24Price = formatCurrencyToDecimal(value);
              }
              
              if (type === "assistance") {
                x.assistanceMonth24Price = formatCurrencyToDecimal(value);
              }
              break;
            case 36:
              if (type === "paw") {
                x.pawMonth36Price = formatCurrencyToDecimal(value);
              }
              
              if (type === "assistance") {
                x.assistanceMonth36Price = formatCurrencyToDecimal(value);
              }
              break;
          }
        }
      });

      setPawData(pawData);
      modified();
      handlePawPost(pawData);
      setReload(new Date().toLocaleString());
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [pawInputWrapperId]:true}
        })
        //dom.setAttribute("message", "Please enter avalid value.");
      }
    }
  }

  return(
    <>
      <div className="container-variable" onMouseOver={props.removeHeader} onMouseOut={props.showHeader} style={{paddingTop: "30px"}}>
        <ScrollToTop />
        <PText weight="bold" size="medium">
        <PorscheFont> PAW</PorscheFont>
        </PText>
        <div className="container-element">
          <PTable >
            <PTableHead>
              <PTableHeadRow>
                <PTableHeadCell key={"h"} multiline={true}>
                  <span style={{fontWeight:"700"}}>PAW</span>
                </PTableHeadCell>
                { pawData.map((item, i) => {
                    return (
                      <PTableHeadCell key={`h${i}`} style={{ textAlign: "right" }}>
                        <span style={{fontWeight:"700"}}>{item?.seriesDTO?.seriesName}</span>
                      </PTableHeadCell>
                    );
                  })
                }
              </PTableHeadRow>
            </PTableHead>
            <PTableBody>
              <PTableRow key={"p12"}>
                  <PTableCell key={"h12"}>{t("12 months")}</PTableCell>
                  {pawData.map((item, i) => (
                      <PTableCell key={`h12${i}`}>
                        <PTextFieldWrapper
                          id={`p12${item.id}`}
                          label={`p12${i}`}
                          hideLabel={true}
                          unit="€"
                          unitPosition="suffix"
                        >
                          <input
                            id={`ip12${item.id}`}
                            type="text"
                            name={`ip12${item.id}`}
                            defaultValue={ formatCurrencyWithNoUnit(item.pawMonth12Price) }
                            onChange={(e) => {
                              handlePawChange(e.target.value, item.id, 12, "paw")
                            }}
                            onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                            disabled={edit ? false : true}
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                      </PTableCell>
                  ))}
              </PTableRow>
                
              <PTableRow key={"p24"}>
                  <PTableCell key={"h24"}>{t("24 months")}</PTableCell>
                  {pawData.map((item, i) => (
                      <PTableCell key={`h24${i}`}>
                        <PTextFieldWrapper
                          id={`p24${item.id}`}
                          label={`p24${item.id}`}
                          hideLabel={true}
                          unit="€"
                          unitPosition="suffix"
                        >
                          <input
                              type="text"
                              name={`ip24${item.id}`}
                              defaultValue={ formatCurrencyWithNoUnit(item.pawMonth24Price) }
                              onChange={(e) => {
                                handlePawChange(e.target.value, item.id, 24, "paw")
                              }}
                              onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                              disabled={edit ? false : true}
                              style={{ textAlign: "right", height: "40px" }}
                          />
                        </PTextFieldWrapper>
                      </PTableCell>
                  ))}
              </PTableRow>

                <PTableRow key={"p36"}>
                  <PTableCell key={"h36"}>{t("36 months")}</PTableCell>
                    {pawData.map((item, i) => (
                        <PTableCell key={`h36${i}`}>
                          <PTextFieldWrapper
                            id={`p36${item.id}`}
                            label={`p36${item.id}`}
                            hideLabel={true}
                            unit="€"
                            unitPosition="suffix"
                          >
                            <input
                                type="text"
                                name={`ip36${item.id}`}
                                defaultValue={ formatCurrencyWithNoUnit(item.pawMonth36Price) }
                                onChange={(e) => {
                                  handlePawChange(e.target.value, item.id, 36, "paw")
                                }}
                                onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                                disabled={edit ? false : true}
                                style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                      </PTableCell>
                    ))}
                </PTableRow>
            </PTableBody>
          </PTable>
        </div>
      </div>
      <div className="container-variable" onMouseOver={props.removeHeader} onMouseOut={props.showHeader}>
        <PText weight="bold" size="medium">
        <PorscheFont>Assistance</PorscheFont>
        </PText>
        <div className="container-element">
          <PTable caption="Some caption">
            <PTableHead>
              <PTableHeadRow>
                <PTableHeadCell key={"ha"} className="paw-table-content">
                  <span style={{fontWeight:"700"}}>{t("Assistance (net)")}</span>
                </PTableHeadCell>
                { pawData.map((item, i) => {
                    return (
                      <PTableHeadCell key={`ha${i}`} style={{ textAlign: "right" }}>
                        <span style={{fontWeight:"700"}}>{item?.seriesDTO?.seriesName}</span>
                      </PTableHeadCell>
                    );
                  })
                }
              </PTableHeadRow>
            </PTableHead>
            <PTableBody>
              <PTableRow key={"ra12"}>
                  <PTableCell key={"ca12"} className="paw-table-content">{t("12 months")}</PTableCell>
                  {pawData.map((item, i) => (
                      <PTableCell key={`ca12${i}`}>
                        <PTextFieldWrapper
                          id={`a12${item.id}`}
                          label={`a12${i}`}
                          hideLabel={true}
                          unit="€"
                          unitPosition="suffix"
                        >
                          <input
                            id={`ia12${item.id}`}
                            type="text"
                            name={`ia12${item.id}`}
                            defaultValue={ formatCurrencyWithNoUnit(item.assistanceMonth12Price) }
                            onChange={(e) => {
                              handlePawChange(e.target.value, item.id, 12, "assistance")
                            }}
                            onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                            disabled={edit ? false : true}
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                      </PTableCell>
                  ))}
              </PTableRow>
                
              <PTableRow key={"a24"}>
                  <PTableCell key={"ha24"} className="paw-table-content">{t("24 months")}</PTableCell>
                  {pawData.map((item, i) => (
                      <PTableCell key={`ha24${i}`}>
                        <PTextFieldWrapper
                          id={`a24${item.id}`}
                          label={`a24${item.id}`}
                          hideLabel={true}
                          unit="€"
                          unitPosition="suffix"
                        >
                          <input
                              type="text"
                              name={`ia24${item.id}`}
                              defaultValue={ formatCurrencyWithNoUnit(item.assistanceMonth24Price) }
                              onChange={(e) => {
                                handlePawChange(e.target.value, item.id, 24, "assistance")
                              }}
                              onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                              disabled={edit ? false : true}
                              style={{ textAlign: "right", height: "40px" }}
                          />
                        </PTextFieldWrapper>
                      </PTableCell>
                  ))}
              </PTableRow>

                <PTableRow key={"p36"}>
                  <PTableCell key={"h36"} className="paw-table-content">{t("36 months")}</PTableCell>
                    {pawData.map((item, i) => (
                        <PTableCell key={`ha36${i}`}>
                          <PTextFieldWrapper
                            id={`a36${item.id}`}
                            label={`a36${item.id}`}
                            hideLabel={true}
                            unit="€"
                            unitPosition="suffix"
                          >
                            <input
                                type="text"
                                name={`ia36${item.id}`}
                                defaultValue={ formatCurrencyWithNoUnit(item.assistanceMonth36Price) }
                                onChange={(e) => {
                                  handlePawChange(e.target.value, item.id, 36, "assistance")
                                }}
                                onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                                disabled={edit ? false : true}
                                style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                      </PTableCell>
                    ))}
                </PTableRow>
            </PTableBody>
          </PTable>
        </div>
      </div>
    </>
  );
};
