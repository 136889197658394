import {
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { Divider, Stack } from "@mui/material";
import {
  IconName,
  PButton,
  PButtonGroup,
  PFlex,
  PFlexItem,
  PModal
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../assets/styles/CustomStyles";
import { OrderContext } from "../../contexts/OrderContext";
import { CalculationDraftDTO } from "../../interfaces/Calculation";
import { OrderDTO } from "../../interfaces/Order";
import { ReCalculation } from "../../interfaces/ReCalculation";
import { UpsaleDetailsDTO, UpsaleError } from "../../interfaces/Upsale";
import { formatDate } from "../../mock/helper";
import {
  dcsgetOrderDetails,
  dcsgetOrderSummary,
  dcsgetUserApprovalAccess,
  dcspostChangeHistoryLogs,
  dcssaveRecalculation,
  postOrderChangeHistoryLogs
} from "../../services/data/Helpers";
import { ConvertOrder } from "../order/actionsConvert/ConvertOrder";
import { CancelOrder } from "./actionsConvert/CancelOrder";
import { OrderRegistrationTabs } from "./actionsOrderProcess/OrderRegistrationTabs";
import { Approvals } from "./approvals/Approvals";
import { MainPrint } from "./prints/MainPrint";
import { StartRecalc } from "./recalculation/StartRecalc";
import { SaveDialog } from "./tabs/upsale/SaveDialog";
// simport { SaveDialog } from './tabs/upsale/SaveDialog';
import { TasksAndNotifications } from "./taskAndNotifications/TasksAndNotifications";

import type { TabChangeEvent } from "@porsche-design-system/components-react";
import { Constant } from "../../configs/Constants";
import getSessionStorage from "../../helpers/getSessionStorage";
import draftValueHandler from "../../helpers/sessionDraftValueHandler";
import { SingleOrderDetailType } from "../../interfaces/OrderDetails";
import { HistoryOrderLogTypes } from "../../interfaces/changeHistory";
import { type } from "../../services/Constants";
import ChangeHistory from "./ChangeHistory";
import { SubsequentEnterConfirmationPopup } from "./subsequent/SubsequentEnterConfirmationPopup";
import CustomSnakebar from "../common/CustomSnakebar";
interface Props {
  upsaleDetails?: UpsaleDetailsDTO;
  handleSaveUpsale?: () => Promise<number>;
  handleSaveOrder?: () => Promise<number>;
  handleSaveCalculation?: () => Promise<number>;
  handleChangeMode?: (id: number, mode: string) => void;
  saveAsDraft: boolean;
  handleTab: (e: CustomEvent<TabChangeEvent>) => void;
  onNextTabIndex?: number;
  errors: Array<UpsaleError>;
  children: JSX.Element;
  modified: boolean;
  cdd: CalculationDraftDTO;
  pIndex: number;
  handleModified: () => void;
  reload: string;
}

interface HeaderTabPanels {
  name: string;
  enabled: boolean;
  className: string;
}

export const OrderHeader = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);
  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }
  const {
    upsaleDetails,
    handleSaveUpsale,
    handleSaveOrder,
    handleSaveCalculation,
    handleChangeMode,
    saveAsDraft,
    handleTab,
    onNextTabIndex,
    errors,
    children,
    modified,
    cdd,
    pIndex,
    handleModified,
  } = props;

  const [order, setOrder] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderDTO>({} as OrderDTO);
  // const [open, setOpen] = useState<boolean>(false);
  const [isDisabledPrintButton, setIsDisabledPrintButton] =
    useState<boolean>(true);
  // const [openError, setOpenError] = useState<boolean>(false);
  const [isConvertOrder, setIsConvertOrder] = useState<boolean>(false);
  const [isStartRecalc, setIsStartRecalc] = useState<boolean>(false);
  const [isOrderRegistration, setIsOrderRegistration] =
    useState<boolean>(false);
  const [isCustomerDelivery, setIsCustomerDelivery] = useState<boolean>(false);

  const [isCancelOrder, setIsCancelOrder] = useState<boolean>(false);
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState<number>(pIndex);
  const [tabIndexDelivery, setTabIndexDelivery] = useState<number>(1);
  // const [tabIndex, setTabIndex] = useState<number>(1);
  // const [tabIndexDelivery, setTabIndexDelivery] = useState<number>(2);
  const [creationDate, setCreationDate] = useState<string>();
  const [updatedOnDate, setUpdatedOnDate] = useState<string>();
  const orderContext = useContext(OrderContext);
  const [toggle, setToggle] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [disabledClassName, setDisabledClassName] = useState("");
  const [id, setId] = useState(0);
  const [mode, setMode] = useState("");
  const [isDraft, setIsDraft] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isApprovalOpen, setIsApprovalOpen] = useState<boolean>(false);
  const [isPrintOpen, setIsPrintOpen] = useState<boolean>(false);
  const [toggleQuit, setToggleQuit] = useState<boolean>(false);
  const [calDraft, setCalDraft] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );

  const [tabPanels, setTabPanels] = useState<Array<HeaderTabPanels>>([]);
  const [reload, setReload] = useState("");
  const [tc, setTc] = useState(0.0);
  let currentTab = 0;
  const [isRecalculationAllowed, setIsRecalculationAllowed] =
    useState<boolean>(false);
  const [isDispatchOrderProcessing, setIsDispatchOrderProcessing] =
    useState<boolean>(false);
  const [recalStatus, setRecalStatus] = useState("");
  const [showReCalcualtion, setShowReCalcualtion] = useState("");
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [isOpenChangeHistory, setIsOpenChangeHistory] =
    useState<boolean>(false);
  const [subsequentSubmitState, setSubsequentSubmitState] = useState(false);
  const [orderStatus, setOrderStatus] = useState<string>("");
  const [subsequentStatus, setSubsequentStatus] = useState<string>("");
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)
  const [inReviewRejected, setInReviewRejected] = useState(false)


  const draftStatus = draftValueHandler(orderValues?.draft)  
  const handleSubsequentClose = () => {
    setSubsequentSubmitState(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleCustomerDeliveryTab = () => {
    setIsCustomerDelivery(true);
  };
  const handleQuit = () => {
    if (modified) {
      setToggle(false);
      setToggleQuit(true);
    } else {
      changeMode(id, "view");
      setMode("view");
      window.location.reload();
    }
  };
  const handleCancelQuit = () => {
    setToggleQuit(false);
  };
  const handleQuitEditing = () => {
    setMode("view");
    setToggle(false);
    setToggleQuit(false);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
    setReload(new Date().toString());
  };
  const handleApprovalClose = (reload: boolean) => {
    setIsApprovalOpen(false);
    if (reload) window.location.reload();
  };
  const handleConvertOrderClose = () => {
    //changeMode(id, "view");
    setIsConvertOrder(false);
    setToggle(!toggle);
    //  setToggle(false)
    //  setToggleQuit(false)
    //  setMode("view");
    // let url = `/#/order/create?id=${id}&mode=view&draft=${isDraft ? "true" : "false"}`;
    // window.open(url, "_self");
  };
  const handleCancelOrderClose = () => {
    setIsCancelOrder(false);
    setToggle(!toggle);
  };
  const handleStartRecalcClose = () => {
    setIsStartRecalc(false);
    setToggle(false);
  };
  const handleOrderRegistrationClose = () => {
    setIsOrderRegistration(false);
    setToggle(!toggle);
  };

  const handleCustomerDelivery = () => {
    setIsCustomerDelivery(false);
    setToggle(!toggle);
  };
  // Print related
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Newcar-Data1",
    onAfterPrint: () => {
      //alert('Print success')
    },
  });

  const ordId =orderValues?.id ? orderValues?.id+'' :'' ;
  const ordMode = orderValues?.mode ? orderValues?.mode+'' :'';
  const ordIsDraftVersion = draftValueHandler(orderValues?.draft)  


  useEffect(() => {
    const init = async () => {
      const id =ordId
      const mode = ordMode
      const isDraftVersion =ordIsDraftVersion 
      const inReviewRejected = orderValues?.inReviewRejected? orderValues?.inReviewRejected :''
      setIsDraft(isDraftVersion === "true" ? true : false);
      if (id && (mode === "view" || mode === "edit")) {
        setId(parseInt(id));
        setMode(mode);

        if (isDraftVersion === "true" && inReviewRejected!=="Yes") {
          const apiResponse = await dcsgetOrderDetails(true, parseInt(id))
          if (apiResponse.status === type.SUCCESS) {
            let saveAsDraft = apiResponse.response || {};
            let orderDetails =
              (apiResponse.response?.order as OrderDTO) || {};
            let upsaleDetails =
              apiResponse.response?.upsale || {};

            let calDetails = apiResponse.response?.calculation
              ? apiResponse.response?.calculation
              : orderContext.getCtxCalculationDraftDetails(
                  orderDetails?.vehicleDetails?.category?.type
                );

            if (orderDetails.createdOn) {
              setOrderData(orderDetails);
              setCreationDate(
                new Date(orderDetails.createdOn).toISOString().split("T")[0]
              );
            }

            if (orderDetails.updatedOn) {
              setUpdatedOnDate(
                new Date(orderDetails.updatedOn).toISOString().split("T")[0]
              );
            }
            orderContext.updateCtxSaveDraftOrderDetails(saveAsDraft);
            orderContext.updateCtxOrderDetails(orderDetails);
            setOrderData(orderDetails);
            orderContext.updateCtxUpsaleDetails(upsaleDetails);
            orderContext.updateCtxCalculationDraftDetails(
              calDetails,
              orderDetails?.vehicleDetails?.category?.type
            );

            const sessionUser = sessionStorage.getItem("userDetails");
            if (sessionUser) {
              const user = JSON.parse(sessionUser);
              let editPowerUp = user.canEditAllOrders
                ? user.canEditAllOrders
                : false;
              if (orderDetails?.orderCreator?.id === user.id)
                editPowerUp = true;
              setCanEdit(editPowerUp);
              // if(mode === "edit" && editPowerUp === false) {
              //   // let urlParams = `?id=${id}&mode=view&draft=${isDraftVersion}`
              //   // navigate(`/order/create${urlParams}`);
              //   let url = `/#/order/create?id=${id}&mode=view&draft=${isDraft ? "true" : "false"}`;
              //   window.open(url, "_self");
              // }
            }
            setReload(new Date().toString());
          }
        } else if (isDraftVersion === "false" || inReviewRejected === "Yes") {
          const apiResponse = await dcsgetOrderSummary(parseInt(id));
          if (apiResponse.status === type.SUCCESS) {
            const orderResponse = apiResponse.response;
            setInReviewRejected(apiResponse.response?.inReviewRejected) 
            setOrderStatus(orderResponse?.order.status);
            setSubsequentStatus(orderResponse?.subsequentStatus);
            

            let orderDetails =
              (apiResponse.response?.order as OrderDTO) || {};
            let upsaleDetails =
              apiResponse.response?.upsale || {};
            let calDetails = apiResponse.response?.calculation
              ? apiResponse.response?.calculation
              : orderContext.getCtxCalculationDraftDetails(
                  orderDetails?.vehicleDetails?.category?.type
                );
            let recalculation = apiResponse.response?.reCalculation
              ? apiResponse.response?.reCalculation
              : {};
            if (orderDetails.createdOn) {
              setCreationDate(
                new Date(orderDetails.createdOn).toISOString().split("T")[0]
              );
            }

            if (orderDetails.updatedOn) {
              setUpdatedOnDate(
                new Date(orderDetails.updatedOn).toISOString().split("T")[0]
              );
            }
            //orderContext.updateCtxSaveDraftOrderDetails(saveAsDraft);
            orderContext.updateCtxOrderDetails(orderDetails);
            setOrderData(orderDetails);
            orderContext.updateCtxUpsaleDetails(upsaleDetails);
            orderContext.updateCtxCalculationDraftDetails(
              calDetails,
              orderDetails?.vehicleDetails?.category?.type
            );
            if (recalculation?.calculationType !== undefined && calDetails) {
              let recal = {
                preCalculation: calDetails,
                reCalculation: recalculation,
              } as ReCalculation;
              orderContext.updateCtxReCalculation(recal);
            }
            if (
              apiResponse.response?.isReCalculationAllowed !==
                null ||
              apiResponse.response?.isReCalculationAllowed !==
                undefined
            ) {
              setIsRecalculationAllowed(
                apiResponse.response?.isReCalculationAllowed
              );
              setRecalStatus(apiResponse.response?.reCalcStatus);
            }            
            const apporvalApiResponses = await dcsgetUserApprovalAccess(orderDetails.vehicleDetails.category.type,
              "DispatchOrderProcessing")
            if (apporvalApiResponses.status===type.SUCCESS) {
              setIsDispatchOrderProcessing(
                apporvalApiResponses.response?.isCurrentUserAllowed
              );
            }

            const sessionUser = sessionStorage.getItem("userDetails");
            if (sessionUser) {
              const user = JSON.parse(sessionUser);
              let editPowerUp = user.canEditAllOrders
                ? user.canEditAllOrders
                : false;
              if (orderDetails?.orderCreator?.id === user.id)
                editPowerUp = true;
              setCanEdit(editPowerUp);

              // if(mode === "edit" && editPowerUp === false) {
              //   //let urlParams = `?id=${id}&mode=view&draft=${isDraftVersion}`
              //   //navigate(`/order/create${urlParams}`);
              //   let url = `/#/order/create?id=${id}&mode=view&draft=${isDraft ? "true" : "false"}`;
              //   window.open(url, "_self");
              // }
            }
            setReload(new Date().toString());

            setTimeout(() => {
              setIsDisabledPrintButton(false);
            }, 5000);
          }
         
        }
      }
    };

    init();
  }, []);

  useEffect(() => {
    const id = ordId
    const mode = ordMode;
    const isDraft = ordIsDraftVersion  



    let panels = [];
    panels.push({ name: "1. Order Details", enabled: true, className: "" });
    panels.push({
      name: "2. Upsale",
      enabled: isDraft === "false" ? true : false,
      className: "",
    });
    panels.push({
      name: "3. Calculation",
      enabled: isDraft === "false" ? true : false,
      className: "",
    });
    // panels.push({ name: "4. ICS Checklist", enabled: isDraft === "false" ? true : false, className: "disabled" })
    panels.push({
      name: "4. Summary",
      enabled: isDraft === "false" ? true : false,
      className: "disabled",
    });
    // Befor committe do the modificstion enabled 2,3,4,5 as false and claseName disabled
    setTabPanels(panels);
    if (id && mode) {
      setOrder(true);
      setDisabledClassName("");
    } else {
      setDisabledClassName("disabled");
    }

    let ord = orderContext.getCtxOrderDetails();
    if (ord.vehicleDetails?.category?.type !== undefined) {
      let cal = orderContext.getCtxCalculationDraftDetails(
        ord.vehicleDetails?.category?.type
      );
      if (cal.calculationType !== undefined) {
        setCalDraft(cal);
      }
    }
  }, [reload]);

  useEffect(() => {
    const status = orderData.status;
    const mode = "view";
    if (status === "Converted" || status === "Cancelled") {
      changeMode(id, "view");
      setToggle(false);
      setToggleQuit(false);
    }
  },[]);

  const handleActions = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (pIndex) {
      setTabIndex(pIndex);
      currentTab = pIndex;
      tabPanels.map((tabPanel, i) => {
        if (pIndex === i) {
          tabPanel.enabled = true;
          tabPanel.className = "";
        }
      });

      setTabPanels(tabPanels);
    }
    let ord = orderContext.getCtxOrderDetails();
    if (ord.vehicleDetails?.category?.type !== undefined) {
      let cal = orderContext.getCtxCalculationDraftDetails(
        ord.vehicleDetails?.category?.type
      );
      if (cal.calculationType !== undefined) {
        setCalDraft(cal);
        setTc(
          cal?.calculationHeader?.totalCommission
            ? cal?.calculationHeader?.totalCommission
            : 0.0
        );
      }
    }
  },[]);

  useEffect(() => {
    let ord = orderContext.getCtxOrderDetails();
    if (ord.vehicleDetails?.category?.type !== undefined) {
      let cal = orderContext.getCtxCalculationDraftDetails(
        ord.vehicleDetails?.category?.type
      );
      if (cal.calculationType !== undefined) {
        setCalDraft(cal);
      }
    }
    if (recalStatus === "notStarted") {
      //start recal
      if (
        isRecalculationAllowed === true &&
        isDispatchOrderProcessing === true
      ) {
        setShowReCalcualtion("StartRecalcualtionEnabled");
      } else {
        setShowReCalcualtion("StartRecalcualtionDisabled");
      }
    } else if (recalStatus === "inProgress") {
      if (
        isRecalculationAllowed === true &&
        isDispatchOrderProcessing === true
      ) {
        setShowReCalcualtion("EditRecalcualtionEnabled");
      } else {
        setShowReCalcualtion("EditRecalcualtionDisabled");
      }
    } else if (recalStatus === "submitted") {
      if (
        isRecalculationAllowed === true &&
        isDispatchOrderProcessing === true
      ) {
        setShowReCalcualtion("EditRecalcualtionEnabled");
      } else {
        setShowReCalcualtion("EditRecalcualtionDisabled");
      }
    } else if (recalStatus === "reSubmitInProgress") {
      if (
        isRecalculationAllowed === true &&
        isDispatchOrderProcessing === true
      ) {
        setShowReCalcualtion("EditRecalcualtionEnabled");
      } else {
        setShowReCalcualtion("EditRecalcualtionDisabled");
      }
    } else {
      setShowReCalcualtion("StartRecalcualtionDisabled");
    }
  }, [cdd, reload]);

  const handleTabPanel = (tabPanel: HeaderTabPanels): void => {
    if (tabPanel.className === "" && !tabPanel.enabled) {
      tabPanel.enabled = true;
    }
  };

 

  const handleCancel = () => {
    navigate("/");
  };

  const changeMode = (id: number, mode: string) => {
    setId(id);
    setMode(mode);
    setToggle(false);
    setReload(new Date().toString());

    // let urlParams = `?id=${id}&mode=${mode}&draft=${orderValues?.draft}`;

    if (handleChangeMode) handleChangeMode(id, mode);
  };

  const handleSave = async (tabIndex: number) => {
    const mode = orderValues?.mode ? orderValues?.mode+'' :'';
    const isDraftVersion = draftValueHandler(orderValues?.draft)  

    // We are restricting edit for order as of now.
    // It will be handled later
    if (mode === "edit" && isDraftVersion === "false") {
      return true;
    }

    switch (tabIndex) {
      case 1:
        if (handleSaveOrder) {
          try {
            const returnId = await handleSaveOrder();
            setOrder(true);
            const orderDetails = orderContext.getCtxOrderDetails();
            setCreationDate(orderDetails.createdOn);
            setUpdatedOnDate(orderDetails.updatedOn);

            const draft = orderContext.getCtxSaveDraftOrderDetails();
            if (returnId) {
              const logData: HistoryOrderLogTypes = {
                page: "1. Order Details",
                tab: "Order Edited",
                userId: loggedInUserId,
                comment: "",
                orderId: returnId,
              };
              await dcspostChangeHistoryLogs(logData, 'order')
          

              setId(returnId);
              setMode("edit");
              changeMode(returnId, "edit");
              setIsDraft(orderDetails.status === "Draft" ? true : false);
              //   if (mode !== "edit" && mode !=="view" ){
              //   setTimeout(function(){
              //     window.location.reload();
              //  }, 1000);}
              //  setReload(new Date().toLocaleString());}
              // setTimeout(reload, 1000);
              // window.location.reload();
              setReload(new Date().toLocaleString());
              const draftValue =  orderDetails.status === "Draft" ? true : false;
              const singOrderDetails:SingleOrderDetailType = { id: returnId, mode:'edit', draft: draftValue };
              const newWindow=  window.open(`/#/order/create`, "_self") 
              newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
            }
          } catch (e: any) {
          }
        } else {
          setOrder(false);
        }
        break;

      case 2:
        if (handleSaveUpsale) {
          try {
            const returnId = await handleSaveUpsale();
            setOrder(true);
            const orderDetails = orderContext.getCtxOrderDetails();
            setCreationDate(orderDetails.createdOn);
            setUpdatedOnDate(orderDetails.updatedOn);

            const draft = orderContext.getCtxSaveDraftOrderDetails();
            if (returnId) {
              const logData: HistoryOrderLogTypes = {
                page: "2. Upsale",
                tab: "Order Edited",
                userId: loggedInUserId,
                comment: "",
                orderId: returnId,
              };
              await dcspostChangeHistoryLogs(logData, "order")
              setId(returnId);
              setMode("edit");
              changeMode(returnId, "edit");
              setIsDraft(orderDetails.status === "Draft" ? true : false);
              const draftValue =  orderDetails.status === "Draft" ? true : false;
              const singOrderDetails:SingleOrderDetailType = { id: returnId, mode:'edit', draft: draftValue };
              const newWindow=  window.open(`/#/order/create`, "_self") 
              newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));

            }
          } catch (e: any) {
          }
        } else {
          setOrder(false);
        }
        break;
      case 3:
      case 4:
      case 5:
        if (handleSaveCalculation) {
          try {
            const returnId = await handleSaveCalculation();
            setOrder(true);
            const orderDetails = orderContext.getCtxOrderDetails();
            setCreationDate(orderDetails.createdOn);
            setUpdatedOnDate(orderDetails.updatedOn);

            const draft = orderContext.getCtxSaveDraftOrderDetails();
            if (returnId) {
              const logData: HistoryOrderLogTypes = {
                page: "3. Calculation",
                tab: "Order Edited",
                userId: loggedInUserId,
                comment: "",
                orderId: returnId,
              };
              await postOrderChangeHistoryLogs(logData);
              setId(returnId);
              setMode("edit");
              changeMode(returnId, "edit");
              setIsDraft(orderDetails.status === "Draft" ? true : false);
              const draftValue =  orderDetails.status === "Draft" ? true : false;
              const singOrderDetails:SingleOrderDetailType = { id: returnId, mode:'edit', draft: draftValue };
              const newWindow=  window.open(`/#/order/create`, "_self") 
              newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
            }
          } catch (e: any) {
          }
        } else {
          setOrder(false);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const status = orderData.status;
    const mode = "view";
    if (status === "Converted" || status === "Cancelled") {
      changeMode(id, "view");
      setToggle(false);
      setToggleQuit(false);
    }
  },[]);

  const handleReasonDiscount = (value: string) => {
    if (calDraft.calculationHeader) {
      calDraft.calculationHeader.reasonDiscount = value;
      setCalDraft(calDraft);
      const orderDetails = orderContext.getCtxOrderDetails();
      orderContext.updateCtxCalculationDraftDetails(
        calDraft,
        orderDetails.vehicleDetails.category.type
      );
      handleModified();
      setReload(new Date().toLocaleString());
    }
  };

  const handleStartRecalc = async () => {
    setIsStartRecalc(false);
    setToggle(false);
    let order = orderContext.getCtxOrderDetails();
    let recalculation = orderContext.getCtxReCalculation();
    if (order?.id) {
      const apiRresponse = await dcssaveRecalculation(order.id, recalculation);

      if (apiRresponse.status===type.SUCCESS) {
        const logData: HistoryOrderLogTypes = {
          page: "",
          tab: "Recalculation Started",
          userId: loggedInUserId,
          comment: "",
          orderId: order?.id,
        };
        await dcspostChangeHistoryLogs(logData, 'order')
        const singOrderDetails:SingleOrderDetailType = { id: id, mode:'edit', draft: false, isStart:'yes' };
        const newWindow=  window.open(`/#/order/recalculation`, "_self") 
        newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
      } 
      if(apiRresponse.status===type.ERROR){
        setShowAlert(Constant.Error)
        setAlertMessage(apiRresponse.response) 
      }
    }
  };

  const handleEditRecalc = () => {
    setIsStartRecalc(false);
    setToggle(false);
    const singOrderDetails:SingleOrderDetailType = { id: id, mode:'edit', draft: false, isStart:'no' };
    const newWindow=  window.open(`/#/order/recalculation`, "_self") 
    newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
    
  };

  const handleOpenChangeHistroy = () => {
    setIsOpenChangeHistory(true);
  };

  const handleCloseChangeHistroy = () => {
    setIsOpenChangeHistory(false);
  };
  const handleSubsequent = () => {
    let urlParams = `?id=${id}&mode=edit&draft=${"false"}&isStart=${"no"}`;
    navigate(`/order/subsequent${urlParams}`);
  };

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  return (
    <>
      <div
        className="header-fixed"
        //  style={{display:"none"}}
      >
        <div className="order-header">
          <PFlex>
            <PFlexItem className="header-left"></PFlexItem>
            <PFlexItem className="header-middle" style={{ paddingTop: "40px" }}>
              <img
                src={require("../../assets/images/porsche1.png")}
                alt=""
                width="125px"
                height="66px"
              />
              {/* <PMarque             
                style={{ paddingTop: "20px" }}
              /> */}
            </PFlexItem>
            <PFlexItem className="header-right">
              {order && orderData && (
                <PFlex
                  alignItems={{ base: "flex-start", l: "flex-end" }}
                  direction="column"
                >
                  <PFlexItem style={{ paddingTop: "16px" }}>
                    <PorscheFont className="status-title">
                      {orderData.status === "Cancelled"
                        ? t("Order Cancelled")
                        : orderData.status === "Converted"
                        ? t("Order Converted")
                        : orderData.orderNumber
                        ? t("Order No.") + " " + orderData.orderNumber
                        : t("Order Draft")}
                    </PorscheFont>
                  </PFlexItem>
                  {creationDate && (
                    <PFlexItem>
                      <PorscheFont
                        className="status-subtitle"
                        style={{ paddingLeft: "125px" }}
                      >
                        {t("Creation date")}: {formatDate(creationDate)}
                      </PorscheFont>
                    </PFlexItem>
                  )}
                  {updatedOnDate && (
                    <PFlexItem>
                      <PorscheFont
                        className="status-subtitle"
                        style={{ paddingLeft: "125px" }}
                      >
                        &ensp; {t("Last edited")}: {formatDate(updatedOnDate)}
                      </PorscheFont>
                    </PFlexItem>
                  )}
                </PFlex>
              )}
            </PFlexItem>

            
          </PFlex>
          {/* Change starting */}
          <PFlex justifyContent="space-between">
            <PFlexItem>
              <PFlex className="flex80 pt20">
                <PFlexItem style={{ marginLeft: "10px" }}>{children}</PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem alignSelf={"center"}>
              {!order ? (
                <PFlex justifyContent="flex-end">
                  <PFlexItem>
                    <SaveDialog
                      tabIndex={tabIndex}
                      handleSave={(tabIndex) => handleSave(tabIndex)}
                      message={`${t("Save as draft") + "?"}`}
                      disable={saveAsDraft ? false : true}
                    />
                  </PFlexItem>
                </PFlex>
              ) : null}
              <PFlex justifyContent="flex-end" style={{ paddingRight: "45px" }}>
                <PFlexItem>
                  <Stack direction="row">
                    {order && (
                      <>
                        {ordMode === "edit" &&
                          ordIsDraftVersion === "true" && (
                            <>
                              <div style={{ marginRight: "6px" }}>
                                <PButton
                                  variant="tertiary"
                                  icon="close"
                                  className="quit-btn"
                                  onClick={handleQuit}
                                  style={{position: 'relative'}}
                                >
                                  {t("Quit editing")}
                                </PButton>
                              </div>
                              {toggleQuit && modified && (
                                <div className="quit-container-order">
                                  <PorscheFont className="sub-headding">
                                    {t("Quit editing")}?
                                  </PorscheFont>
                                  <PorscheFont className="dcs-title">
                                    {t("Unsaved changes will be discarded.")}
                                  </PorscheFont>
                                  <br></br>
                                  <div style={{ paddingLeft: "35px" }}>
                                    <PButtonGroup>
                                      <PButton
                                        variant="tertiary"
                                        icon="close"
                                        onClick={handleCancelQuit}
                                      >
                                        {t("No, continue")}
                                      </PButton>
                                      <PButton
                                        variant="secondary"
                                        onClick={() => {
                                          changeMode(id, "view");
                                          setToggle(false);
                                          setToggleQuit(false);
                                          window.location.reload();
                                        }}
                                      >
                                        {t("Yes, quit editing")}
                                      </PButton>
                                    </PButtonGroup>
                                  </div>
                                </div>
                              )}
                              {ordMode === "edit" && !inReviewRejected && (
                                <div style={{ marginRight: "6px" }}>
                                  <PButton
                                    variant="primary"
                                    icon="save"
                                    onClick={() => handleSave(tabIndex)}
                                    disabled={modified ? false : true}
                                  >
                                    {t("Save")}
                                  </PButton>
                                </div>
                              )}
                            </>
                          )}
                        {/* Print Button */}
                        {ordMode === "view" &&
                        ["Paid", "Approved", "CommissionsApproved"].includes(
                          orderData?.status
                        ) ? (
                          <div
                            style={
                              orderData.status !== "Converted" &&
                              orderData.status !== "Cancelled"
                                ? {
                                    paddingRight: "20px",
                                    paddingBottom: "8px",
                                    paddingTop: "-16px",
                                  }
                                : { marginRight: "-32px" }
                            }
                          >
                            {/* marginRight: "70px" */}
                            <PButton
                              variant="tertiary"
                              icon={"printer" as IconName}
                              className="print-btn"
                              onClick={handlePrint}
                              disabled={isDisabledPrintButton}
                            >
                              {t("Print")}
                            </PButton>
                          </div>
                        ) : null}
                        {/* Action Button  */}
                        {orderData &&
                          orderData.status !== "Converted" &&
                          orderData.status !== "Cancelled" && (
                            <>
                              <div>
                                <PButton
                                  variant="secondary"
                                  icon={"menuLines" as IconName}
                                  className="actions-btn"
                                  onClick={() => {
                                    handleActions();
                                  }}
                                  // onClick={handleActions}
                                >
                                  {t("Actions")}
                                </PButton>
                              </div>

                              {toggle && !toggleQuit && (
                                <div
                                  className="actions-container"
                                  onBlur={() => setToggle(false)}
                                  onMouseLeave={() => setToggle(false)}
                                  style={{ right: "0" }}
                                >
                                  <ul>
                                    <li>
                                      {(orderData.status === "Approved" ||
                                        orderData.status ===
                                          "CommissionsApproved") &&
                                          orderStatus !=="CommissionsApproved" &&
                                      isDispatchOrderProcessing === true ? (
                                        <PorscheFont
                                          onClick={() =>
                                            setIsOrderRegistration(true)
                                          }
                                        >
                                          {t("Submit Order Registration")}
                                        </PorscheFont>
                                      ) : (
                                        <PorscheFont
                                          style={{
                                            color: "#C8CACB",
                                            cursor: "default",
                                          }}
                                        >
                                          {t("Submit Order Registration")}
                                        </PorscheFont>
                                      )}
                                    </li>
                                    <li>
                                      <PorscheFont
                                        style={{
                                          color: "#C8CACB",
                                          cursor: "default",
                                        }}
                                      >
                                        {t("Prepare Customer Delivery")}
                                      </PorscheFont>
                                    </li>
                                    <li>
                                      {(orderData.status === "Approved" ||
                                        orderData.status ===
                                          "CommissionsApproved") &&
                                        orderStatus !=="CommissionsApproved" &&
                                      isDispatchOrderProcessing === true ? (
                                        <PorscheFont
                                          onClick={handleCustomerDeliveryTab}
                                        >
                                          {t("Submit Customer Delivery")}
                                        </PorscheFont>
                                      ) : (
                                        <PorscheFont
                                          style={{
                                            color: "#C8CACB",
                                            cursor: "default",
                                          }}
                                        >
                                          {t("Submit Customer Delivery")}
                                        </PorscheFont>
                                      )}
                                    </li>
                                    <Divider
                                      style={{ paddingTop: "10px" }}
                                    ></Divider>
                                    <li>
                                      {showReCalcualtion ===
                                        "StartRecalcualtionEnabled" && (
                                        <PorscheFont
                                          onClick={() => setIsStartRecalc(true)}
                                        >
                                          {t("Start Recalculation")}
                                        </PorscheFont>
                                      )}
                                      {showReCalcualtion ===
                                        "StartRecalcualtionDisabled" && (
                                        <PorscheFont
                                          style={{
                                            color: "#C8CACB",
                                            cursor: "default",
                                          }}
                                        >
                                          {t("Start Recalculation")}
                                        </PorscheFont>
                                      )}
                                      {showReCalcualtion ===
                                        "EditRecalcualtionEnabled"  && orderStatus !=="CommissionsApproved" && (
                                        <PorscheFont onClick={handleEditRecalc}>
                                          {t("Edit Recalculation")}
                                        </PorscheFont>
                                      )}
                                      {(showReCalcualtion ===
                                        "EditRecalcualtionDisabled" ||orderStatus ==="CommissionsApproved") && (
                                        <PorscheFont
                                          style={{
                                            color: "#C8CACB",
                                            cursor: "default",
                                          }}
                                        >
                                          {t("Edit Recalculation")}
                                        </PorscheFont>
                                      )}
                                    </li>

                                    {orderStatus === "Paid" &&
                                    subsequentStatus === "notStarted" ? (
                                      <li>
                                        <PorscheFont
                                          onClick={() =>
                                            setSubsequentSubmitState(true)
                                          }
                                        >
                                          {t("Subsequent Change")}
                                        </PorscheFont>
                                      </li>
                                    ) : (
                                      <li>
                                        <PorscheFont
                                          style={{
                                            color: "#C8CACB",
                                            cursor: "default",
                                          }}
                                        >
                                          {t("Subsequent Change")}
                                        </PorscheFont>
                                      </li>
                                    )}

                                    <Divider
                                      style={{ paddingTop: "10px" }}
                                    ></Divider>
                                    {!isDraft ? (
                                      <li
                                        onClick={() => {
                                          if (id > 0 && !isDraft)
                                            setIsApprovalOpen(true);
                                        }}
                                      >
                                        <PorscheFont>
                                          {t("Approvals")}
                                        </PorscheFont>
                                      </li>
                                    ) : (
                                      <li>
                                        <PorscheFont
                                          style={{
                                            color: "#C8CACB",
                                            cursor: "default",
                                          }}
                                        >
                                          {t("Approvals")}
                                        </PorscheFont>
                                      </li>
                                    )}
                                    <li
                                      onClick={() => {
                                        if (id > 0) {
                                          setIsModalOpen(true);
                                          setToggle(!toggle);
                                          setToggleQuit(toggleQuit);
                                        }
                                      }}
                                    >
                                      <PorscheFont>
                                        {t("Tasks & Notifications")}
                                      </PorscheFont>
                                    </li>
                                    <li
                                      onClick={() => handleOpenChangeHistroy()}
                                    >
                                      <PorscheFont>
                                        {t("Change History")}
                                      </PorscheFont>
                                    </li>
                                    <Divider
                                      style={{ paddingTop: "10px" }}
                                    ></Divider>
                                    {(
                                      (!!id && mode === "view" && isDraft) || (!!id && inReviewRejected))
                                       && (
                                      <>
                                        {(canEdit || ( canEdit && inReviewRejected && mode==="edit") ) ? (
                                          <li
                                            onClick={() =>
                                              changeMode(id, "edit")
                                            }
                                          >
                                            <PorscheFont>
                                              {t("Change to Edit Mode")}
                                            </PorscheFont>
                                          </li>
                                        ) : (
                                          <li>
                                            <PorscheFont
                                              style={{
                                                color: "#C8CACB",
                                                cursor: "default",
                                              }}
                                            >
                                              {t("Change to Edit Mode")}
                                            </PorscheFont>
                                          </li>
                                        )}
                                      </>
                                    )}
                                    {/* {id && mode === "edit" && (
                            <li onClick={() => changeMode(id, "view")}><PorscheFont>{t('Change to View Mode')}</PorscheFont></li>
                          )} */}
                                    {!!canEdit ? (
                                      <>
                                        <li>
                                          <PorscheFont
                                            onClick={() =>
                                              setIsConvertOrder(true)
                                            }
                                          >
                                            {t("Convert Order")}
                                          </PorscheFont>
                                        </li>
                                        <li>
                                          <PorscheFont
                                            onClick={() =>
                                              setIsCancelOrder(true)
                                            }
                                          >
                                            {t("Cancel Order")}
                                          </PorscheFont>
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        <li>
                                          <PorscheFont
                                            style={{
                                              color: "#C8CACB",
                                              cursor: "default",
                                            }}
                                          >
                                            {t("Convert Order")}
                                          </PorscheFont>
                                        </li>
                                        <li>
                                          <PorscheFont
                                            style={{
                                              color: "#C8CACB",
                                              cursor: "default",
                                            }}
                                          >
                                            {t("Cancel Order")}
                                          </PorscheFont>
                                        </li>
                                      </>
                                    )}
                                    {/* <li onClick={() => { handlePrint() }}><PorscheFont>{t('Print Order')}</PorscheFont></li> */}
                                  </ul>
                                </div>
                              )}
                            </>
                          )}
                      </>
                    )}
                  </Stack>
                </PFlexItem>
              </PFlex>
            </PFlexItem>
          </PFlex>
        </div>
      </div>
      {id > 0 && isModalOpen && (
        <PModal open={isModalOpen} onClose={handleModalClose}>
          <TasksAndNotifications
            orderId={id}
            isDraft={isDraft}
            open={isModalOpen}
          />
        </PModal>
      )}

      {id > 0 && isApprovalOpen && (
        <PModal
          open={isApprovalOpen}
          onClose={() => handleApprovalClose(false)}
        >
          {/* <Approvals orderId={id} category={orderContext.getCtxOrderDetails().vehicleDetails.category.type} handleClose={(reload) => handleApprovalClose(reload)} /> */}
          <Approvals
            orderId={id}
            status={orderData?.status}
            category={orderData?.vehicleDetails?.category?.type}
            handleClose={(reload) => handleApprovalClose(reload)}
          />
        </PModal>
      )}
      {id > 0 && isConvertOrder && (
        <PModal open={isConvertOrder} onClose={handleConvertOrderClose}>
          <ConvertOrder
            orderId={id}
            //  orderId={id} isDraft={isDraft} order={orderContext.getCtxOrderDetails()}
            handleClosePopup={handleConvertOrderClose}
            isDraft={isDraft}
          />
        </PModal>
      )}
      {id > 0 && isCancelOrder && (
        <PModal open={isCancelOrder} onClose={handleCancelOrderClose}>
          <CancelOrder
            orderId={id}
            //  orderId={id} isDraft={isDraft} order={orderContext.getCtxOrderDetails()}

            handleClosePopupCancel={handleCancelOrderClose}
            isDraft={isDraft}
          />
        </PModal>
      )}
      {id > 0 && isStartRecalc && (
        <PModal
          open={isStartRecalc}
          disableCloseButton={true}
          onClose={handleStartRecalcClose}
        >
          <StartRecalc
            orderId={id}
            handleCloseStartRecalc={handleStartRecalcClose}
            isDraft={isDraft}
            handleStartRecalc={handleStartRecalc}
          />
        </PModal>
      )}
      {id > 0 && isOrderRegistration && (
        <PModal
          open={isOrderRegistration}
          onClose={handleOrderRegistrationClose}
        >
          <OrderRegistrationTabs
            orderId={id}
            tabIndex={0}
            //  orderId={id} isDraft={isDraft} order={orderContext.getCtxOrderDetails()}
            handleCloseOrderRegister={handleOrderRegistrationClose}
          />
        </PModal>
      )}

      {id > 0 && isCustomerDelivery && (
        <PModal
          style={{ height: "650px" }}
          open={isCustomerDelivery}
          onClose={handleCustomerDelivery}
        >
          <OrderRegistrationTabs
            orderId={id}
            tabIndex={1}
            //  orderId={id} isDraft={isDraft} order={orderContext.getCtxOrderDetails()}
            handleCloseOrderRegister={handleCustomerDelivery}
          />
        </PModal>
      )}

      {id > 0 &&
        draftStatus === "false" &&
        ["Paid", "Approved", "CommissionsApproved"].includes(orderStatus) && (
          <div style={{ display: "none" }}>
            <MainPrint ref={componentRef} />
          </div>
        )}

      {id > 0 && isOpenChangeHistory && (
        <PModal
          open={isOpenChangeHistory}
          onClose={handleCloseChangeHistroy}
          className="change-history-modal-container"
        >
          <ChangeHistory orderId={id} />
        </PModal>
      )}
      {subsequentSubmitState && (
        <PModal
          style={{ height: "650px" }}
          open={subsequentSubmitState}
          onClose={handleSubsequentClose}
        >
          <SubsequentEnterConfirmationPopup
            orderId={id}
            handleClose={handleSubsequentClose}
          />
        </PModal>
      )}
    <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

    </>
  );
};
