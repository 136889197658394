import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Alert } from '@mui/material';
import { PButton, PButtonGroup } from '@porsche-design-system/components-react';

import { PorscheFont } from '../../assets/styles/CustomStyles';

interface Props {
    // orderId: number,
    handleClosePopupCancel: () => void;
    heading?: string;
    links: Array<any>;
    subpage?: string;
    toPath: string;
    variablePageSave: () => void;
    commissionSave: () => void;
    approvalSave: () => void; 
}

export const SaveLeavePopup = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { handleClosePopupCancel, heading, links, subpage, variablePageSave, commissionSave,approvalSave } = props;
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
    const [isError, setIsError] = useState(false);
    const [reload, setReload] = useState("");
    const navigate = useNavigate();
    

    let loggedInUserId = 1;
    const sessionUser = sessionStorage.getItem("userDetails");
    if (sessionUser) {
        const user = JSON.parse(sessionUser);
        loggedInUserId = user.id;
    }

    const handleCancel = () => {
        handleClosePopupCancel();
    }
    const handleChanging = () => {
        sessionStorage.setItem("changeFlag", "false");
        if (props && props.toPath) {
            navigate(props.toPath);
        }
    };
    const handleSave = () => {
        if (subpage){
            if (subpage === t("Variables")) {
                variablePageSave();
                setReload(new Date().toString())
            }
            if (subpage === t("Commission Scaling")){
                commissionSave();
                setReload(new Date().toString())
            }
            if (subpage === t("Approval Process")){
                approvalSave();
                setReload(new Date().toString())
            }
        }
        sessionStorage.setItem("changeFlag", "false");
        if (props && props.toPath) {
            navigate(props.toPath);
        }

    }
    const handleCancelOrder = () => {
        const init = async () => {
        }
        init();
    }

    return (
        <>
            <div style={{ height: "138px", minWidth: "504px" }}>

                <PorscheFont className="sub-headding" style={{paddingLeft:"0"}}>{t("Unsaved changes")}</PorscheFont><br></br>
                <PorscheFont>{t("There are unsaved changes. Leave page?")}</PorscheFont><br></br>
                <PButtonGroup>
                    <PButton variant="tertiary" icon='close' onClick={handleCancel}>{t("Cancel")}</PButton>
                    <PButton variant="tertiary"
                        onClick={handleChanging}
                    >{t("Leave without saving")}</PButton>
                    <PButton variant="secondary"
                        onClick={handleSave}
                    >{t("Save and leave")}</PButton>
                </PButtonGroup>
            </div>
            {saveSuccessMsg && (
                <Alert severity="success" sx={{ width: "100%" }}>
                    {t(saveSuccessMsg)}
                </Alert>)}

            {isError && (
                <Alert severity="error" sx={{ width: "100%" }}>
                    {t("Error while converting!")}
                </Alert>)}
        </>
    );
}

