import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {
  PButton, PDivider, PFlex, PFlexItem, PGrid, PGridItem, PSelectWrapper,
  PTextFieldWrapper,
  PTextareaWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';
import { OrderDTO } from '../../../interfaces/Order';
import { TaskRequestDTO, TaskResponseDTO } from '../../../interfaces/TasksAndNotifications';
import { UserDetailsDTO } from '../../../interfaces/User';
import { dcsgetActiveUsers, dcsgetTasksByOrderId, dcsputTasksData, putTasksData } from '../../../services/data/Helpers';
//import { Notifications } from './Notifications';
import { type } from '../../../services/Constants';
import { TaskRow } from './TaskRow';

//import { TaskRow } from './TaskRow';

interface Props {
  orderId: number,
  isDraft:boolean,
  open: boolean,

}

export const Tasks = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { orderId, isDraft, open } = props;

  const [toggle, setToggle] = useState<boolean>(true);
  const [taskRequest, setTaskRequest] = useState<TaskRequestDTO>({} as TaskRequestDTO);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
  const [tasks, setTasks] = useState<Array<TaskResponseDTO>>([]);
  const [users, setUsers] = useState<Array<UserDetailsDTO>>([]);
  const [validationError, setValidationError] = useState(false);
  const [isError, setIsError] = useState(false);
  const orderContext = useContext(OrderContext);
  let loggedInUserId = 0;
  let user = {} as UserDetailsDTO;
  let showCreateTask = false;
  const sessionUser = sessionStorage.getItem("userDetails");
  if(sessionUser) {
    user = JSON.parse(sessionUser) as UserDetailsDTO;
    
    loggedInUserId = user.id ? user.id : 0;
  }

  const order = orderContext.getCtxOrderDetails();

  const setShowCreateTask = (user: UserDetailsDTO, order: OrderDTO) => {
    // if order creator allow to create task
    if(order.orderCreator && order.orderCreator.id) {
      if(order.orderCreator.id === loggedInUserId) return true;
    }

    // If user is not order creator, but has order view and order edit permission, allow
    if(user && user.id) {
      if(user.canViewAllOrders || user.canEditAllOrders) return true;
    }

    return false;
  }

  showCreateTask = setShowCreateTask(user, order);
  
  
  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetActiveUsers()
      //
      if(apiResponse.status===type.SUCCESS) {
        const users = apiResponse.response
        setUsers(users);
        
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (open) {
      taskInit();
    } else {
      setTaskRequest({} as TaskRequestDTO);
      setValidationError(false);
      setIsError(false);
    }
  }, []);

  useEffect(() => {}, [validationError])
  const functProps =() =>{
    taskInit();
  }

  const taskInit = async () => {
    const apiResponse = await dcsgetTasksByOrderId(orderId, isDraft)
    if(apiResponse.status===type.SUCCESS) {
      const tasks = apiResponse.response
      setTasks(tasks);
    }
  };
  
  const handleCreateTask = () => {
    if(isValidTaskRequest()) {
      try {
        const init = async () => {
          taskRequest.status = "Pending";
          taskRequest.taskType = "Manual";
          if(isDraft){
            taskRequest.draft= { id: orderId }
          } else {
            taskRequest.order = { id: orderId }
          }
          taskRequest.createdBy= { id: loggedInUserId }
          const apiResponse = await dcsputTasksData(taskRequest)
          if(apiResponse.status===type.SUCCESS){
            setToggle(!toggle);
            taskInit();
            setSaveSuccess(true);
            setSaveSuccessMsg("Created Successfully");
          }else {
            setIsError(true);
          }
        };
        init();
      } catch (e) {   
        setIsError(true);
      }
    } else {
      
      setValidationError(true);
    }
  }

  const isValidTaskRequest = () => {
    
    if (taskRequest.subject === undefined ||  taskRequest.subject.length === 0) {
      setValidationError(true);
      return false;
    }

    if (taskRequest.assignedTo === undefined || taskRequest.assignedTo.id === undefined) {
      setValidationError(true);
      return false;
    } 

    setValidationError(false);
    return true;
  }

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };

  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const handleSubject = (value: string) => {
    taskRequest.subject = value;
    setTaskRequest(taskRequest);
    isValidTaskRequest();
    //setReload(new Date().toLocaleString());
  };

  const handleAssignee = (value: string) => {
    
    taskRequest.assignedTo = { id: parseInt(value) };
    
    // Set the logged in user id
    taskRequest.createdBy = { id: loggedInUserId };
    setTaskRequest(taskRequest);
    isValidTaskRequest();
    //setReload(new Date().toLocaleString());
  };

  const handleDescription = (value: string) => {
    taskRequest.description = value;
    setTaskRequest(taskRequest);
    //isValidTaskRequest();
    //setReload(new Date().toLocaleString());
  };

  const handleDelete = () => {
    taskInit();
    setSaveSuccess(true);
    setSaveSuccessMsg("Deleted successfully");
  }

  return (
    <>
      <div className="mt24">
        {toggle && showCreateTask && (
          <PButton  
            variant="tertiary" 
            data-testid="new-task" 
            icon="plus" 
            onClick={() => setToggle(!toggle)}
          >
            <PorscheFont className="dcs-heading">{t('Create New Task')}</PorscheFont>
          </PButton>
        )}
        {!toggle && showCreateTask && (
          <>
            <div className="mt24">
            <PGrid>
              <PGridItem size={6}>
                <PTextFieldWrapper className="dcs-heading" label={`${t("Subject")}`} >
                  <input
                    onChange={(e) => handleSubject(e.target.value)}
                    type="text"
                    maxLength={50}
                    name="task"
                    required
                    //defaultValue={taskRequest.subject}
                  />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={3}>
                <PSelectWrapper label={`${t("Assign To")}`}>
                <select
                  data-testid="assignee"
                  name="assignee"
                  onChange={(e) => handleAssignee(e.target.value)}
                  // disabled={disabled}
                  required
                >
                  <option key={"assignedNone"} value={""}>{t("Select user")}</option>
                  {users.map((user, index) => {
                    return (
                        <option key={index} value={user.id}>
                        {user.ppnName}
                        </option>
                    );
                  })}
                </select>
                </PSelectWrapper>
              </PGridItem>
            </PGrid>
            <PGrid className="mt10" >
              <PGridItem size={12}>
                <PTextareaWrapper label={`${t("Description (Optional)")}`} >
                <textarea
                    name="description"
                    maxLength={500}
                    onChange={(e) => handleDescription(e.target.value)}
                >
                    {/* {task.description} */} 
                </textarea>
                </PTextareaWrapper>
              </PGridItem>
            </PGrid>
            { validationError && (
              <div className='mt10'>
                <PorscheFont><span style={{color: "red"}}>{t("Please enter valid values.")}</span></PorscheFont>
              </div>
            )}
            <div className='mt10'>
              <PButton onClick={handleCreateTask}>{t('Create task')}</PButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <PButton
                onClick={() => { 
                  setToggle(!toggle);
                  setValidationError(false);
                }}
                type="button"
                variant="tertiary"
                icon="close"
                >
                {t('Cancel')}
              </PButton>
            </div>
            </div>
          </>
        )}

        <div className="mt24">
          <PorscheFont className="dcs-heading">{t('Existing tasks')}</PorscheFont>
          <PFlex justifyContent={"space-between"}>
            <PFlexItem style={{width:"15%"}}>
              <PorscheFont className="dcs-title">{t('Created on')}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{width:"15%"}}>
              <PorscheFont className="dcs-title">{t('Created by')}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{width:"25%"}}>
              <PorscheFont className="dcs-title">{t('Task')}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{width:"20%"}}>
              <PorscheFont className="dcs-title">{t('User assigned')}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{width:"20%"}}>
              <PorscheFont className="dcs-title">{t("Status")}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{width:"5%"}}> 
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-high" />
          {tasks && tasks.map((task, index) => {
            return (
              <TaskRow task={task} orderId={orderId} functProps={functProps} isDraft={isDraft} index={task.id} key={task.id} handleDelete={handleDelete}/>
            );
          })}
        </div>
        <div className="flex30 pt20">
          <Snackbar
            open={saveSuccess}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleSuccessClose}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              {t(saveSuccessMsg)}
            </Alert>
          </Snackbar>
          <Snackbar
            open={isError}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleErrorClose}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("Error while creating task!")}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};
