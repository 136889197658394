import { useEffect, useState } from 'react';

import { PFlex, PFlexItem, PText } from '@porsche-design-system/components-react';

import { Series } from '../../interfaces/Upsale';

interface Props {
  allSeries: Array<Series>;
  handleChange: (seriresId: number) => void;
  mode?: string;
  selectedSeriesId?: number;
  disabled?: boolean;
}
export const CustomSeries = (props: Props): JSX.Element => {
  const { allSeries, handleChange, mode , selectedSeriesId, disabled } = props;
  const [series, setSeries] = useState("");
  const [reload, setReload] = useState("");
  const [disabledClassName, setDisabledClassName] = useState("")

  useEffect(() => {
    if(selectedSeriesId && ( mode === "view" || mode === "edit") ) {
      const s = allSeries.filter(x => x.id === selectedSeriesId);
      if(s.length > 0) {
        handleChange(s[0].id);
        setSeries(s[0].seriesName);
        //alert(`in handle click series ${series} `);
      }
    }

    if (disabled) {
      setDisabledClassName("disabled")
    } else {
      setDisabledClassName("")
    }

    if(mode === "view") {
      setDisabledClassName("disabled")
    } else {
      setDisabledClassName("")
    }

  },[mode])

  useEffect(() => {
    if (disabled) {
      setDisabledClassName("disabled")
    } else {
      setDisabledClassName("")
    }
  }, [reload]);

  const getImage = (seriesName: string) => {
    let ret = <img src={require("../../assets/images/cars/png/Boxster.png")} alt={"Boxster"} width="108px" height="60px" style={{ marginTop: "-20px" }}/>;
    switch (seriesName) {
      case "Boxster":
        ret = <img src={require("../../assets/images/cars/png/Boxster.png")} alt={"Boxster"} width="108px" height="60px" style={{ marginTop: "-20px" }} />;
        break;

      case "Cayenne":
        ret = <img src={require("../../assets/images/cars/png/Cayenne.png")} alt={"Cayenne"} width="125px" height="70px" style={{ marginTop: "-20px"}} />;
        break;

      case "Cayman":
        ret = <img src={require("../../assets/images/cars/png/Cayman.png")} alt={"Cayman"} width="108px" height="60px" style={{ marginTop: "-20px", marginRight:"5px"}} />;
        break;

      case "Macan":
        ret = <img src={require("../../assets/images/cars/png/Macan.png")} alt={"Macan"} width="112px" height="63px" style={{ marginTop: "-17px", marginRight: "5px" }} />;
        break;

      case "Panamera":
        ret = <img src={require("../../assets/images/cars/png/Panamera.png")} alt={"Panamera"} width="127px" height="73px" style={{ marginTop: "-25px", marginRight: "3px" }} />;
        break;

      case "Taycan":
        ret = <img src={require("../../assets/images/cars/png/Taycan.png")} alt={"Taycan"} width="127px" height="73px" style={{ marginTop: "-25px" }} />;
        break;

      case "911":
      default:
        ret = <img src={require("../../assets/images/cars/png/911.png")} alt={"911"} width="120px" height="70px" style={{ marginTop: "-25px" }} />;
        break;
    }

    return ret;
  }
  const handleClick = (name: string) => {
    setSeries(name);
    const s = allSeries.filter(x => x.seriesName === name);
    if(s.length > 0) {
        handleChange(s[0].id);
        //alert(`in handle click series ${series} `);
    }
    setReload(new Date().toISOString());
  }

  return(
    <>
      <PFlex direction={"column"}>
        <PFlexItem>
          <PFlex direction={"row"} justifyContent={"space-between"} style={{width: "1000px"}}>
            <PFlexItem className={`series ${disabled ? "disabled" : ""}`} style={series === "Cayman" ? { backgroundColor: "#313639", margin: "5px"} : {margin: "5px"}}>
              <PFlex
                direction={"row"}
                justifyContent={"space-between"}
                className={`${disabled ? "disabled" : ""}`}
                onClick={() => handleClick("Cayman")}
                style={{paddingTop: "6px"}}
                >
                <PFlexItem alignSelf={"flex-start"}>
                  <PText className={`series-name`}>
                  <span
                    style={{fontSize:"14px", lineHeight: "0.5", visibility: "visible", color: series === "Cayman" ? "white" : ""}}
                  >
                      {"Cayman"}
                  </span>
                  </PText>
                </PFlexItem>
                <PFlexItem
                    alignSelf={"flex-start"}
                    style={{ paddingRight: "0px", visibility: "visible"}}
                >
                  {getImage("Cayman")}
                </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem className={`series ${disabled ? "disabled" : ""}`} style={{ backgroundColor: series === "Boxster" ? "#313639" : "", margin: "5px"}}>
              <PFlex
                direction={"row"}
                justifyContent={"space-between"}
                className={`${disabled ? "disabled" : ""}`}
                onClick={() => handleClick("Boxster")}
                style={{paddingTop: "6px"}}
                >
                <PFlexItem alignSelf={"flex-start"}>
                    <PText className={`series-name`} >
                    <span
                      style={{fontSize:"14px", lineHeight: "0.5", visibility: "visible", color: series === "Boxster" ? "white" : ""}}
                    >
                        {"Boxster"}
                    </span>
                    </PText>
                </PFlexItem>
                <PFlexItem
                    alignSelf={"flex-start"}
                    style={{ paddingRight: "0px", visibility: "visible"}}
                >
                    {getImage("Boxster")}
                </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem className={`series ${disabled ? "disabled" : ""}`} style={{ backgroundColor: series === "911" ? "#313639" : "", margin: "5px"}}>
              <PFlex
                direction={"row"}
                justifyContent={"space-between"}
                className={`${disabled ? "disabled" : ""}`}
                onClick={() => handleClick("911")}
                style={{paddingTop: "6px"}}
                >
                <PFlexItem alignSelf={"flex-start"}>
                    <PText className={`series-name`} >
                    <span
                      style={{fontSize:"14px", lineHeight: "0.5", visibility: "visible", color: series === "911" ? "white" : ""}}
                    >
                        {"911"}
                    </span>
                    </PText>
                </PFlexItem>
                <PFlexItem
                    alignSelf={"flex-start"}
                    style={{ paddingRight: "0px", visibility: "visible"}}
                >
                    {getImage("911")}
                </PFlexItem>
              </PFlex>
            </PFlexItem>
          </PFlex>
        </PFlexItem>
        <PFlexItem>
          <PFlex direction={"row"} justifyContent={"space-between"} style={{width: "1000px"}}>
            <PFlexItem className={`series ${disabled ? "disabled" : ""}`} style={{ backgroundColor: series === "Macan" ? "#313639" : "", margin: "5px"}}>
              <PFlex
                direction={"row"}
                justifyContent={"space-between"}
                className={`${disabled ? "disabled" : ""}`}
                onClick={() => handleClick("Macan")}
                style={{paddingTop: "6px"}}
                >
                <PFlexItem alignSelf={"flex-start"}>
                    <PText className={`series-name`} >
                    <span
                      style={{fontSize:"14px", lineHeight: "0.5", visibility: "visible", color: series === "Macan" ? "white" : ""}}
                    >
                        {"Macan"}
                    </span>
                    </PText>
                </PFlexItem>
                <PFlexItem
                    alignSelf={"flex-start"}
                    style={{ paddingRight: "0px", visibility: "visible"}}
                >
                    {getImage("Macan")}
                </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem className={`series ${disabled ? "disabled" : ""}`} style={{ backgroundColor: series === "Cayenne" ? "#313639" : "", margin: "5px"}}>
              <PFlex
                direction={"row"}
                justifyContent={"space-between"}
                className={`${disabled ? "disabled" : ""}`}
                onClick={() => handleClick("Cayenne")}
                style={{paddingTop: "6px"}}
                >
                <PFlexItem alignSelf={"flex-start"}>
                    <PText className={`series-name`} >
                    <span
                      style={{fontSize:"14px", lineHeight: "0.5", visibility: "visible", color: series === "Cayenne" ? "white" : ""}}
                    >
                        {"Cayenne"}
                    </span>
                    </PText>
                </PFlexItem>
                <PFlexItem
                    alignSelf={"flex-start"}
                    style={{ paddingRight: "0px", visibility: "visible"}}
                >
                    {getImage("Cayenne")}
                </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem className={`series ${disabled ? "disabled" : ""}`} style={{ backgroundColor: series === "Panamera" ? "#313639" : "", margin: "5px"}}>
              <PFlex
                direction={"row"}
                justifyContent={"space-between"}
                className={`${disabled ? "disabled" : ""}`}
                onClick={() => handleClick("Panamera")}
                style={{paddingTop: "6px"}}
                >
                <PFlexItem alignSelf={"flex-start"}>
                    <PText className={`series-name`} >
                    <span
                      style={{fontSize:"14px", lineHeight: "0.5", visibility: "visible", color: series === "Panamera" ? "white" : ""}}
                    >
                        {"Panamera"}
                    </span>
                    </PText>
                </PFlexItem>
                <PFlexItem
                    alignSelf={"flex-start"}
                    style={{ paddingRight: "0px", visibility: "visible"}}
                >
                    {getImage("Panamera")}
                </PFlexItem>
              </PFlex>
            </PFlexItem>
          </PFlex>
        </PFlexItem>

        <PFlexItem>
          <PFlex direction={"row"} justifyContent={"space-between"} style={{width: "1000px"}}>
          <PFlexItem className={`series ${disabled ? "disabled" : ""}`} style={{ backgroundColor: series === "Taycan" ? "#313639" : "", margin: "5px"}}>
              <PFlex
                direction={"row"}
                justifyContent={"space-between"}
                className={`${disabled ? "disabled" : ""}`}
                onClick={() => handleClick("Taycan")}
                style={{paddingTop: "6px"}}
                >
                <PFlexItem alignSelf={"flex-start"}>
                    <PText className={`series-name`} >
                    <span
                      style={{fontSize:"14px", lineHeight: "0.5", visibility: "visible", color: series === "Taycan" ? "white" : ""}}
                    >
                        {"Taycan"}
                    </span>
                    </PText>
                </PFlexItem>
                <PFlexItem
                    alignSelf={"flex-start"}
                    style={{ paddingRight: "0px", visibility: "visible"}}
                >
                    {getImage("Taycan")}
                </PFlexItem>
              </PFlex>
            </PFlexItem>
          </PFlex>
        </PFlexItem>
      </PFlex>
    </>
  );
}
    