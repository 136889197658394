import dayjs, { Dayjs } from 'dayjs';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  Alert, Button,
  Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Tooltip
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {
  PButton, PButtonGroup, PButtonPure, PDivider, PFlex, PFlexItem,
  PIcon, PModal, PPopover,
  PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { ReactComponent as ManualCommissionIcon } from "../../assets/images/ManualCommissionIcon.svg";
import { ReactComponent as SubsequentIcon } from "../../assets/images/SubsequentIcon.svg";
import { PorscheFont } from '../../assets/styles/CustomStyles';
import { Constant } from '../../configs/Constants';
import { AuthContext } from '../../contexts/AuthContext';
import {
  AccountingRequestDTO, AccountingResponseDTO,
  ExcludingOrderDto,
  UserDTO
} from '../../interfaces/Commissions';
import { UserDetailsDTO } from '../../interfaces/User';
import { formatCurrencyWithNoUnit, getExportRegion } from '../../mock/helper';
import { type } from '../../services/Constants';
import {
  dcsdeleteManualCommission,
  dcsexcludeOrderFromAccounting,
  dcsgetOrdersByAccountingMonth,
  dcsgetOrdersForAccounting,
  dcsgetOrdersForMonthActive,
  dcspostComissionsAccounting,
  dcsresetComissionsAccounting,
  getLogedInOrigin, getLogedInUser
} from '../../services/data/Helpers';
import CustomSnakebar from '../common/CustomSnakebar';
import { ApproveList } from './ApproveListPop';
import { ResetAccounting } from './ResetAccountingPop';
import { SingleOrderDetailType } from '../../interfaces/OrderDetails';


interface Props {
  handleMsg?: (msg: string, type: string) => void;
}

export const Accounting = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { handleMsg } = props;
    const navigate = useNavigate();
    const [ordersAccounting, setOrdersAccounting] = useState<AccountingResponseDTO>({} as AccountingResponseDTO)
    const authCtx = useContext(AuthContext);
    const [disabled, setDisabled] = useState<boolean>(false)
    const [Toggle, setToggle] = useState<boolean>(false)
    const [resetAccount, setResetAccount] = useState<boolean>(false)
    const [modalApprove, setModalApprove] = useState<boolean>(false)
    const [toggleExclude, setToggleExclude] = useState<boolean>(false)
    const [modified, setModified] = useState(false);
    const [startMonth, setStartMonth] = useState("");
    const [reason, setReason] = useState("");
    const [manualReason, setManualReason] = useState("");
    const [reload, setReload] = useState("");
    const [orderNumber, setOrderNumber] = useState(0);
    const [deleteManualCommissionId, setDeleteManualCommissionId] = useState(0);
    const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022.04.07'));

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
    const [isError, setIsError] = useState(false);
    const [isPopoverVisible, setIsPopoverVisible] = useState<null | number>(null);
    const [showAlert, setShowAlert] = useState(Constant.None)
    const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)
  

    const alertHandler = (status:string, message:string)=>{
      setShowAlert(status)
      setAlertMessage(message)
    }

    let userDetails = {} as UserDetailsDTO;
    const sessionUser = sessionStorage.getItem("userDetails");
    if(sessionUser) {
      userDetails = JSON.parse(sessionUser);
    }
    

    const handleCancel = () => {
        //handleClosePopup();
    }
    const handleSuccessClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return false;
        }
        setSaveSuccess(false);
    };
    const handleErrorClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return false;
        }
        setIsError(false);
    };

    const handleClose = ( ) => {
      setShowAlert(Constant.None)
      setAlertMessage("")
    };

    const formatDate = (strDate: string): string => {
        if(!strDate) return "-";
        if(strDate.length === 0) return "-";
        //const date = new Date(strDate).toISOString().split('T')[0];
        const formatDate = strDate.split("-")
        return `${formatDate[2]}.${formatDate[1]}.${formatDate[0]}`;
    };
    useEffect(() => {
        const init = async () => {
            const apiResponse = await dcsgetOrdersForMonthActive()
            if(apiResponse.status===type.SUCCESS){
              const accountingData = apiResponse.response as AccountingResponseDTO;
              setOrdersAccounting(apiResponse.response);
              
              if (accountingData.startMonth !== undefined) {
                  setStartMonth(accountingData.startMonth);
                  setDisabled(true);
              }
              setToggle(true);
            } else {
              const apiResponse = await dcsgetOrdersForAccounting()
              if(apiResponse.status===type.SUCCESS){
                let accountng: AccountingResponseDTO = {
                  orders: apiResponse.response
              };
              setStartMonth("");
              setOrdersAccounting(accountng);
              if(userDetails.startPayroll) {
                  setDisabled(false);
              } else {
                  setDisabled(true);
              }
              setToggle(false);
              }
            }
        }
        init();
    }, []);

    useEffect(() => {}, [startMonth, ordersAccounting, reload]);

    const handleOrder = (order:any) => {
        if(order?.isManualCommission){
            if (order?.id) {
              let singOrderDetails:SingleOrderDetailType = { id: order?.id };
              const newWindow = window.open(`/#/commission/manualcommission`, "_blank");
              newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
              }
        }else{
            if (order?.id) {
                let singOrderDetails:SingleOrderDetailType = { id: order?.id, mode:'view', draft: false };
                const newWindow=  window.open(`/#/order/create`, "_blank") 
                newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
  
            }
        }
    }
    const handleExclude = (id: number): void => {
        setOrderNumber(id);
        setToggleExclude(true);
    }
    const handleStartAccounting = () => {
        var user: UserDTO = getLogedInUser();
        var accountRequest: AccountingRequestDTO = {
            startMonth: startMonth,
            startBy: user,
            porscheCenter: getLogedInOrigin(),
        };

        const init = async () => {
          const apiResponse = await dcspostComissionsAccounting(accountRequest)
          if(apiResponse.status === type.SUCCESS){
            setOrdersAccounting(apiResponse.response);
          //   if(handleMsg) {
          //     handleMsg(t("Accounting started successfully"), "success");
          // }

          setShowAlert(Constant.Success)    
          setAlertMessage("Accounting started successfully")

          
          setDisabled(true);
          setToggle(true);
          setReload(new Date().toLocaleString());
          }

          if(apiResponse.status===type.ERROR){
            setShowAlert(Constant.Error)
            setAlertMessage(apiResponse.response) 
          }
        }
        init();
    }
    const handleApprove = () => {
        setModalApprove(true);
    }
    const handleCancelApproveClose = () => {
        init();
        setModalApprove(false);
        setReload(new Date().toLocaleString());
    }
    const init = () => {
      const init1 = async () => {
        const apiResponse = await dcsgetOrdersForMonthActive();
        if (apiResponse.status === type.SUCCESS) {
          setOrdersAccounting(apiResponse.response);
          setStartMonth(apiResponse.response?.startMonth!);
          setToggle(true);
        } else {
          const apiResponse2 = await dcsgetOrdersForAccounting();
          if (apiResponse2.status === type.SUCCESS) {
            let accountng: AccountingResponseDTO = {
              orders: apiResponse2.response,
            };
            setStartMonth("");
            setOrdersAccounting(accountng);
            setToggle(false);
          }
        }
      };
      init1();
      setReload(new Date().toLocaleString());
    };

    const handleResetAccountClose = (closeType: string) => {
        init();
        setToggle(false);
        setResetAccount(false);
        setStartMonth("");

        // setSaveSuccessMsg("Period successfully reset");
        // setSaveSuccess(true);

        if(handleMsg && closeType === "success") {
            handleMsg(t("Period successfully reset"), "success");
        }
        setReload(new Date().toLocaleString());
    }

    const handleResetAccount = () => {
        setResetAccount(true);
        setDisabled(false);
    }

    const handleCancelExclude = () => {
        setReason("");
        setModified(false);
        setToggleExclude(false);
    }

    const addReason = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value.length > 0) {
            setReason(e.target.value);
        }
        setModified(true);
        setReload(new Date().toLocaleString());
    }

    const onChangeManualReason = (e: ChangeEvent<HTMLInputElement>): void => {
        setManualReason(e.target.value);
        setModified(true);
        setReload(new Date().toLocaleString());
    }

    const dispString = (str: string) => {
        let disStr = str;
        if (str.length > 15) {
            const strArray = str.substring(0, 15);
            disStr = str.substring(0, 15);
        }
        return disStr;
    };
    const handleDate = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value.length > 0) {
           let monthyear=e.target.value;
            setStartMonth(e.target.value);
            const init1 = async () => {
            const apiResponse = await dcsgetOrdersByAccountingMonth(monthyear)
              if(apiResponse.status===type.SUCCESS){
                const accountng: AccountingResponseDTO = {
                  orders: apiResponse.response
              };
                setOrdersAccounting(accountng)
              }


        }
        init1();
        } 
        setReload(new Date().toLocaleString());
    };

    const handleOrderExclude = (): void => {
        var user: UserDTO = getLogedInUser();
        var excludeOrder: ExcludingOrderDto = {
            orderId: orderNumber,
            reason: reason,
            accountingId: ordersAccounting.id!,
            userId:user.id

        };
        const init = async () => {
            const excludResp = await dcsexcludeOrderFromAccounting(orderNumber, excludeOrder);
            if(excludResp.status===type.SUCCESS){

       
            const ordersAccounting = excludResp.response;
            setOrdersAccounting(ordersAccounting);
            if(ordersAccounting["orders"]?.length===0){
                const res = await dcsresetComissionsAccounting(ordersAccounting);
                setToggle(false);
                setStartMonth("");
                setReload(new Date().toLocaleString());
                
            }

            if(handleMsg) {
              handleMsg(t("Order excluded successfully"), "success");
            }
          }
        }
        init();

        setToggleExclude(false);
    }

    const resetOrderAccount = () => {
          ({} as AccountingResponseDTO);
    };

    const handleSubmitDeleteBtn = (order : any) => {
        if(order?.isManualCommission){
            setDeleteManualCommissionId(order?.id);
        }else if(userDetails.startAccounting){
             handleExclude(order?.id!);
        }
    };

    const handleClosedeleteModal = () =>{
        setDeleteManualCommissionId(0);
        setManualReason("");
    }
    const handleSubmitDeleteManualCommission = async () => {
      try {
        if (deleteManualCommissionId) {
          const apiResponse = await dcsdeleteManualCommission(
            deleteManualCommissionId,
            { reason: manualReason }
          );
          if (apiResponse.status === type.SUCCESS) {
            let filteredAccount = ordersAccounting["orders"]?.filter(
              (order) => order.id != deleteManualCommissionId
            );
            setOrdersAccounting((prev) => ({
              ...prev,
              orders: filteredAccount,
            }));
            setDeleteManualCommissionId(0);
            setManualReason("");
            setShowAlert(Constant.Success);
            setAlertMessage("Commission successfully deleted.");
          }
          if (apiResponse.status === type.ERROR) {
            setShowAlert(Constant.Error);
            setAlertMessage(apiResponse.response);
          }
        }
      } catch (error) {}
    };
    return (<>
        <div>
            <PFlex justifyContent={"space-between"} direction="row">
                <PFlexItem>
                    <PFlex>
                        <PFlexItem>
                            <Stack direction='row'>
                                <PTextFieldWrapper label={`${t("Accounting month")}`}>
                                    <input type="month" name="startMonth"
                                        //value={ordersAccounting?.startMonth}
                                        value={startMonth}
                                        //required
                                        onChange={handleDate}
                                        disabled={disabled}
                                        style={{backgroundColor : disabled ? "#C8CACB" : "#FFFFFF"}}
                                    />
                                </PTextFieldWrapper> 
                                <PPopover direction="top" style={{marginLeft: "-50px"}}>
                                    <PorscheFont className="dcs-popover-accounting">
                                        {t("All orders with customer delivery by the end of the selected month are filtered for commission accounting.")}
                                    </PorscheFont>
                                </PPopover>
                            </Stack>
                        </PFlexItem>
                        <PFlexItem>
                            <PFlex>
                                <PFlexItem style={{paddingTop: "28px", paddingLeft:"34px"}}>
                                    {!Toggle &&
                                        <PButton onClick={(e) => handleStartAccounting() } disabled={(userDetails.startPayroll && (ordersAccounting?.orders && ordersAccounting?.orders.length>0) && startMonth.length>0 ? false :true )  } >
                                        {t("Start accounting")}
                                        </PButton>
                                    }

                                    {/* {ResetButton} */}
                                    {Toggle && 
                                        <>
                                        { (ordersAccounting.approvedOneOn!=null && ordersAccounting.approvedTwoOn!=null)
                                          ? (
                                            <PButton icon="duration" onClick={handleResetAccount} variant="primary"  disabled={userDetails.startPayroll ? false : true}>
                                            {t("Reset accounting")}
                                            </PButton>
                                          ) : (
                                            <PButton icon="duration" onClick={handleResetAccount} variant="secondary"  disabled={userDetails.startPayroll ? false : true}>
                                            {t("Cancel accounting")}
                                            </PButton>
                                          )
                                        }
                                        </>
                                    }
                                </PFlexItem>
                            </PFlex>
                        </PFlexItem>
                    </PFlex>
                </PFlexItem>
                
                {(ordersAccounting.approvedOneOn && ordersAccounting.approvedTwoOn)&& (
                    <PFlexItem>
                        <PFlex justifyContent={"flex-end"}>
                            <PFlexItem style={{paddingTop: "53px"}}>
                                <Stack direction={"row"} spacing={1}>
                                    <PIcon name="information" />
                                    <PorscheFont style={{fontSize: "16px", fontWeight: "700"}}>{t("Export was generated and list was added to History.")}</PorscheFont>
                                </Stack>
                            </PFlexItem>
                        </PFlex>
                    </PFlexItem>
                )}
                
            </PFlex>
            <PDivider style={{marginTop: "16px", marginBottom: "8px"}}></PDivider>
            {Toggle && ordersAccounting?.orders && ordersAccounting?.orders.length > 0 && (
                <>
                    <PFlex>
                        <PFlexItem >
                            <Stack direction={"row"} spacing={1}>
                                <PorscheFont style={{ fontSize: "16px" }}>{t('Started by')}:</PorscheFont>
                                <PorscheFont className="dcs-heading">
                                    {ordersAccounting.startBy?.ppnName}

                                </PorscheFont>
                            </Stack>
                        </PFlexItem>&ensp;&ensp;&ensp;
                        <PFlexItem >
                            <Stack direction={"row"} spacing={1}>
                                <PorscheFont style={{ fontSize: "16px" }}>{t('Started on')}:</PorscheFont>
                                <PorscheFont className="dcs-heading">
                                    {ordersAccounting.startedOn && ordersAccounting.startedOn !== "-" ? ordersAccounting.startedOn.replaceAll("-", ".") : ""}
                                    {/* {task.createdOn.substring(0, 10).replaceAll("-", ".")} */}
                                </PorscheFont>
                            </Stack>
                        </PFlexItem></PFlex>
                </>)}
        {toggleExclude  && (
            <div className="actions-container"
                style={{ width: "463px", height: "232px", textAlign: "left",marginLeft: "48%", marginTop: "-150px", zIndex: "20", position: "absolute"}}
            >
                <PorscheFont className="sub-headding">{t("Exclude order from commission accounting?")}</PorscheFont>
                <PorscheFont className='dcs-title'>{t("Order is sent back to the recalculation.")}</PorscheFont>
                <PTextFieldWrapper label={`${t('Reason')}`} id={`assignedTo`} style={{marginTop: "16px"}}>
                    <input
                        id={`iassignedTo`}
                        key={`kassignedTo`}
                        type="text"
                        required
                        value={reason}
                        onChange={addReason}
                        maxLength={50}
                    />
                </PTextFieldWrapper>
                <PFlex justifyContent={"flex-end"} style={{marginTop: "16px"}}>
                    <PFlexItem>
                    <PButtonGroup>
                        <PButton variant="tertiary" icon='close'onClick={handleCancelExclude}>{t("No, cancel")}</PButton>
                        <PButton variant="secondary" onClick={handleOrderExclude} disabled={modified ? false : true}>{t("Yes, exclude order")}</PButton>
                    </PButtonGroup >
                    </PFlexItem>
                </PFlex>
            </div>
        )}
     <div style={{marginTop: "12px"}}>
       <TableContainer sx={ window.innerHeight>1000 ? { maxHeight: "680px" } : { maxHeight: "260px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}> 
                <PFlex direction={"column"}>
                    <PFlexItem className='inreview-text-top'><PorscheFont>{t("Vehicle type")}</PorscheFont></PFlexItem>
                    <PFlexItem className='inreview-text-bottom'><PorscheFont>{t('Model')}</PorscheFont></PFlexItem>
                </PFlex>
                </TableCell>
               
               <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t("Comm No.")}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t("Act No.")}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>
           
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}> 
                <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Planned CD')}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Actual CD')}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>
            
              <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Customer')}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('C@P No.')}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>
            
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Payment')}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('PFS Extras')}</PorscheFont></PFlexItem>
                </PFlex>
              </TableCell>
            
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t("Export")}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t("Region")}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>
            
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Seller')}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Origin')}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>
           
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Commission')}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Recalculation')}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>
            
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Incentive')}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Recalculation')}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>
             
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Total')}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Commission')}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>
             
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t('Recalculation')}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Date')}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell> 
                                      
             <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t("1st Approval")}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Date')}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>  
              <TableCell className='cell-align-demo' style={{borderBottomColor: "#313639"}}>
                 <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top"><PorscheFont>{t("2nd Approval")}</PorscheFont></PFlexItem>
                    <PFlexItem className="inreview-text-bottom"><PorscheFont>{t('Date')}</PorscheFont></PFlexItem>
                 </PFlex>
              </TableCell>
              {Toggle && (ordersAccounting.approvedOneOn === null || ordersAccounting.approvedTwoOn === null) && userDetails.startAccounting && (
                <TableCell style={{borderBottomColor: "#313639"}}>
                </TableCell>
              )}
              <TableCell style={{borderBottomColor: "#313639"}}>
              </TableCell>      
              <TableCell style={{borderBottomColor: "#313639"}}>
              </TableCell>                
            </TableRow>
          </TableHead>
          <TableBody>         
          {ordersAccounting?.orders && ordersAccounting?.orders.map((order, i) => {
            let commissionDiff = order.commissionRecalculation ? parseFloat(order.commissionRecalculation) : 0.00;
            let incentiveDiff = order.incentiveRecalculation ? parseFloat(order.incentiveRecalculation) : 0.00; 

            return (
              <TableRow hover role="checkbox" tabIndex={-4}>
                <TableCell className="cell-align-demo">
                  <PFlex
                    direction={"column"}
                    style={{ marginTop: "8px", marginBottom: "8px" }}
                  >
                    <PFlexItem className="inreview-text-top" style={{ marginRight: order.model && order.model.length > 17 ? '15px' : '0' }}>
                      <PorscheFont>
                        {order.vehicleCategory ? t(order.vehicleCategory) : "-"}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                        {order.model !== undefined && order.model.length > 0 ? (
                            <PorscheFont>{t(order.model)}</PorscheFont>
                        ) : (
                          "-"
                        )}
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{order.commissionNumber}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{order.actNumber}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont style={{ fontWeight: "400" }}>
                        {order.plannedCD !== undefined &&
                        order.plannedCD.length > 0
                          ? order.plannedCD.replaceAll("-", ".")
                          : "-"}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont style={{ fontWeight: "400" }}>
                        {order.actualCD !== undefined &&
                        order.actualCD.length > 0
                          ? order.actualCD.replaceAll("-", ".")
                          : "-"}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{order.customer}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{order.copNumber}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      {order.payment ? t(order.payment) : "-"}
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont style={{ fontWeight: "400" }}>
                        {order.pfsExtras}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont style={{ fontWeight: "400" }}>
                        {order.exportRegion && order.exportRegion !== "-"
                          ? getExportRegion(t(order.exportRegion), 0)
                          : "-"}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem
                      className="inreview-text-bottom"
                      style={{ fontWeight: "400" }}
                    >
                      {order.exportRegion && order.exportRegion !== "-"
                        ? getExportRegion(t(order.exportRegion), 1)
                        : ""}
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>{order.seller}</PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>{order.origin}</PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>
                        {order.commission &&
                        order.commission !== "-" &&
                        order.commission !== ""
                          ? formatCurrencyWithNoUnit(
                              parseFloat(order.commission)
                            ) + " €"
                          : order.commission}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-top">
                      {order.commissionRecalculation &&
                        (order.commissionRecalculation === "-" ||
                          order.commissionRecalculation === "") && (
                          <>{order.commissionRecalculation}</>
                        )}
                      {order.commissionRecalculation &&
                        order.commissionRecalculation !== "-" &&
                        order.commissionRecalculation !== "" && (
                          <PorscheFont
                            style={{
                              color: commissionDiff < 0 ? "#A90000" : "#00950F",
                            }}
                          >
                            {commissionDiff < 0 ? "" : "+"}
                            {formatCurrencyWithNoUnit(commissionDiff)}
                            {" €"}
                          </PorscheFont>
                        )}
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont style={{ fontWeight: "400" }}>
                        {order.incentive &&
                        order.incentive !== "-" &&
                        order.incentive !== ""
                          ? formatCurrencyWithNoUnit(
                              parseFloat(order.incentive)
                            ) + " €"
                          : order.incentive}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-top">
                      {order.incentiveRecalculation &&
                        (order.incentiveRecalculation === "-" ||
                          order.incentiveRecalculation === "") && (
                          <>{order.incentiveRecalculation}</>
                        )}
                      {order.incentiveRecalculation &&
                        order.incentiveRecalculation !== "-" &&
                        order.incentiveRecalculation !== "" && (
                          <PorscheFont
                            style={{
                              color: incentiveDiff < 0 ? "#A90000" : "#00950F",
                            }}
                          >
                            {incentiveDiff < 0 ? "" : "+"}
                            {formatCurrencyWithNoUnit(incentiveDiff)}
                            {" €"}
                          </PorscheFont>
                        )}
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont style={{ fontWeight: "400" }}>
                        {order.totalCommission &&
                        order.totalCommission !== "-" &&
                        order.totalCommission !== ""
                          ? formatCurrencyWithNoUnit(
                              parseFloat(order.totalCommission)
                            ) + " €"
                          : order.totalCommission}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>
                        {order.recalculation ? order.recalculation : "-"}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>
                        {order.recalculationDate &&
                        order.recalculationDate !== "-"
                          ? order.recalculationDate.replaceAll("-", ".")
                          : "-"}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>
                        {order.firstApproval ? order.firstApproval : "-"}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>
                        {order.firstApprovalDate &&
                        order.firstApprovalDate !== "-"
                          ? order.firstApprovalDate.replaceAll("-", ".")
                          : "-"}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
                    <PFlexItem className="inreview-text-top">
                      <PorscheFont>
                        {order.secondApproval && order.secondApproval.length > 0
                          ? order.secondApproval
                          : ""}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem className="inreview-text-bottom">
                      <PorscheFont>
                        {order.secondApprovalDate &&
                        order.secondApprovalDate !== "-"
                          ? order.secondApprovalDate.replaceAll("-", ".")
                          : order.secondApproval &&
                            order.secondApproval.length > 0
                          ? "-"
                          : ""}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
                {(Toggle &&
                  (ordersAccounting.approvedOneOn === null ||
                    ordersAccounting.approvedTwoOn === null) &&
                  userDetails.startAccounting) ||
                order?.isManualCommission ? (
                  <>
                    <TableCell className="cell-align-demo">
                      <PFlex direction={"row"}>
                        <PFlexItem
                          className="manual-icon"
                          style={{
                            marginRight: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {order?.isManualCommission ? (
                            <>
                              <div
                                className="icon-container"
                                onMouseOver={() => setIsPopoverVisible(i)}
                                onMouseLeave={() => setIsPopoverVisible(null)}
                              >
                                <ManualCommissionIcon />
                              </div>
                              {isPopoverVisible === i ? (
                                <PorscheFont className="custom-popover">
                                  {t("Manual commission")}
                                </PorscheFont>
                              ) : null}
                            </>
                          ) : null}
                        </PFlexItem>
                        <PFlexItem>
                            {order?.isManualCommission && <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "4px",
                              marginLeft: "6px",
                            }}
                            className="accounting-delete-button"
                          >
                            <img
                              width="18px"
                              height="18px"
                              src={require("../../assets/images/approvals/circleWithCross.png")}
                              onClick={() => handleSubmitDeleteBtn(order)}
                            />
                          </div>  }

                          {order?.isSubsequentChange ? (
                        <>
                          <div
                            className="icon-container"
                            onMouseOver={() => setIsPopoverVisible(i)}
                            onMouseLeave={() => setIsPopoverVisible(null)}
                          >
                            <SubsequentIcon />
                          </div>
                          {isPopoverVisible === i ? (
                            <PorscheFont className="custom-popover" style={{width:'155px'}}>
                               {t("Subsequent Change")}
                            </PorscheFont>
                          ) : null}
                        </>
                      ) : null}
                          
                        </PFlexItem>
                      </PFlex>
                    </TableCell>
                  </>
                ) : (
                  <TableCell className="cell-align-demo">
                    <PFlexItem  className="manual-icon">
                      {order?.isSubsequentChange ? (
                        <>
                          <div
                            className="icon-container"
                            onMouseOver={() => setIsPopoverVisible(i)}
                            onMouseLeave={() => setIsPopoverVisible(null)}
                          >
                            <SubsequentIcon />
                          </div>
                          {isPopoverVisible === i ? (
                            <PorscheFont className="custom-popover" style={{width:'155px'}}>
                               {t("Subsequent Change")}
                            </PorscheFont>
                          ) : null}
                        </>
                      ) : null}
                    </PFlexItem>
                  </TableCell>
                )}

                <TableCell className="cell-align-demo">
                  <PFlex direction={"column"}>
               

                    <PFlexItem>
                      <PButtonPure
                        hideLabel={true}
                        onClick={() => handleOrder(order)}
                      ></PButtonPure>
                    </PFlexItem>
                  </PFlex>
                </TableCell>
              </TableRow>
            );
              })} 
          </TableBody>
        </Table>
      </TableContainer>
     </div>
        { Toggle && ordersAccounting?.orders && ordersAccounting?.orders.length > 0 && (
            <div className='section-shadow' style={{height:"88px", marginLeft: "-22px", marginRight: "-18px", paddingLeft:"22px", paddingRight:"18px", paddingTop: "12px"}}>
                <PFlex justifyContent={"space-between"}>
                    <PFlexItem alignSelf={"center"}>
                        <PorscheFont className='dcs-heading'>{t("Approval of the list required")}:</PorscheFont>
                    </PFlexItem>
                    <PFlexItem>
                        <PFlex justifyContent={"space-between"}>
                            <PFlexItem className="accounting-approve">
                                <PFlex justifyContent={"space-between"}>
                                    <PFlexItem > 
                                        <PFlex>
                                            <PFlexItem alignSelf={"center"} style={{paddingLeft: "4px", marginTop:"7px"}}>
                                            {ordersAccounting.approvedOneOn && (<PIcon color={"notification-success"} size="large" name="success" aria={{ 'aria-label': 'Success icon' }} /> )}
                                            { !ordersAccounting.approvedOneOn && <img src={require("../../assets/images/approvals/circle.png")} alt="" style={{ width: "48px", height: "48px" }} />}
                                            </PFlexItem>
                                            <PFlexItem alignSelf={"center"}>
                                                <Stack direction={"column"} style={{marginLeft: "8px"}}>
                                                    <PorscheFont style={{fontSize: "14px", fontWeight:"700"}}>{ordersAccounting.approverOne?.ppnName}</PorscheFont>
                                                    {ordersAccounting.approverOne?.title ? (
                                                        <PorscheFont style={{fontSize: "12px", fontWeight:"400"}}>{ordersAccounting.approverOne?.title}</PorscheFont>
                                                        ) : (
                                                            <PorscheFont style={{visibility: "hidden"}}>Display none</PorscheFont>
                                                        )
                                                    }
                                                </Stack>
                                            </PFlexItem>
                                        </PFlex>
                                    </PFlexItem>
                                    <PFlexItem> 
                                        {ordersAccounting.approvedOneOn && (
                                            <Stack direction={"column"} style={{marginTop: "8px", marginRight:"12px"}}>
                                                <PorscheFont style={{fontSize:"14px", fontWeight:"400"}}>{t("Approved on")} </PorscheFont>
                                                <PorscheFont style={{fontSize:"14px", fontWeight:"700"}}>{ordersAccounting.approvedOneOn && ordersAccounting.approvedOneOn !== "-"  ? ordersAccounting.approvedOneOn.substring(0, 10).replaceAll("-", ".").split(".").reverse().join(".") : ""}</PorscheFont>
                                            </Stack>
                                        )}
                                        {!ordersAccounting.approvedOneOn && (
                                            <PButton icon="success" onClick={handleApprove} disabled={(userDetails.startAccounting && (userDetails.id== ordersAccounting?.approverOne?.id))?false:true}
                                                variant="primary" style={{ minWidth: "113px", height: "40px", lineHeight: "40px", paddingTop: "12px", marginTop:"-5px", marginRight:"8px" }} >
                                                {t("Approve")}
                                            </PButton>
                                        )}
                                    </PFlexItem>
                                </PFlex>
                                {/* <Stack direction={"row"} >
                                {ordersAccounting.approvedOneOn && (<PIcon color={"notification-success"} size="large" name="success" aria={{ 'aria-label': 'Success icon' }} /> )}
                                { !ordersAccounting.approvedOneOn && <img src={require("../../assets/images/approvals/circle.png")} alt="" style={{ width: "57px", height: "60px", paddingTop: "12px", paddingRight: "5px" }} />}
                                <PorscheFont>{ordersAccounting.approverOne?.ppnName}<br></br>{ordersAccounting.approverOne?.title}</PorscheFont>&ensp;&ensp;
                                
                                {!ordersAccounting.approvedOneOn && (
                                    <PButton icon="success" onClick={handleApprove} disabled={(userDetails.startAccounting && (userDetails.id== ordersAccounting?.approverOne?.id))?false:true}
                                        variant="primary" style={{ width: "113px", height: "45px", lineHeight: "40px", paddingTop: "12px" }} >{t("Approve")}</PButton>
                                )}
                                </Stack> */}
                            </PFlexItem>
                            <PFlexItem><div style={{width: "16px"}}></div></PFlexItem>
                            <PFlexItem className="accounting-approve">
                                <PFlex justifyContent={"space-between"}>
                                    <PFlexItem > 
                                        <PFlex>
                                            <PFlexItem alignSelf={"center"} style={{paddingLeft: "4px", marginTop:"7px"}}>
                                                {ordersAccounting.approvedTwoOn && (<PIcon color={"notification-success"} size="large" name="success" aria={{ 'aria-label': 'Success icon' }} /> )}
                                                { !ordersAccounting.approvedTwoOn && <img src={require("../../assets/images/approvals/circle.png")} alt="" style={{ width: "48px", height: "48px" }} />}
                                            </PFlexItem>
                                            <PFlexItem alignSelf={"center"}>
                                                <Stack direction={"column"} style={{marginLeft: "8px"}}>
                                                    <PorscheFont style={{fontSize: "14px", fontWeight:"700"}}>{ordersAccounting.approverTwo?.ppnName}</PorscheFont>
                                                    {ordersAccounting.approverTwo?.title ? (
                                                        <PorscheFont style={{fontSize: "12px", fontWeight:"400"}}>{ordersAccounting.approverTwo?.title}</PorscheFont>
                                                        ) : (
                                                            <PorscheFont style={{visibility: "hidden"}}>Display none</PorscheFont>
                                                        )
                                                    }
                                                </Stack>
                                            </PFlexItem>
                                        </PFlex>
                                    </PFlexItem>
                                    <PFlexItem> 
                                        {ordersAccounting.approvedTwoOn && (
                                            <Stack direction={"column"} style={{marginTop: "8px", marginRight:"12px"}}>
                                                <PorscheFont style={{fontSize: "14px", fontWeight:"400"}}>{t("Approved on")} </PorscheFont>
                                                <PorscheFont style={{fontSize: "14px", fontWeight:"700"}}>{ordersAccounting.approvedTwoOn && ordersAccounting.approvedTwoOn !== "-" ? ordersAccounting.approvedTwoOn.substring(0, 10).replaceAll("-", ".").split(".").reverse().join(".") : ""}</PorscheFont>
                                            </Stack>
                                        )}
                                        {!ordersAccounting.approvedTwoOn && (
                                            <PButton icon="success" onClick={handleApprove} disabled={(userDetails.startAccounting && (userDetails.id== ordersAccounting?.approverTwo?.id))?false:true}
                                                variant="primary" style={{ minWidth: "113px", height: "40px", lineHeight: "40px", paddingTop: "12px", marginTop:"-5px", marginRight:"8px" }} >
                                                {t("Approve")}
                                            </PButton>
                                        )}
                                    </PFlexItem>
                                </PFlex>
                            </PFlexItem>
                        </PFlex>
                    </PFlexItem>
                </PFlex>
            </div>
        )}
            {modalApprove && (
                <PModal open={modalApprove} onClose={handleCancelApproveClose} disableCloseButton={true}>
                    <ApproveList
                        //  orderId={id} isDraft={isDraft} order={orderContext.getCtxOrderDetails()}
                        handleClosePopup={handleCancelApproveClose}
                        commissionAccounting={ordersAccounting}
                        alertHandler={alertHandler}
                    />
                </PModal>
            )}

            {resetAccount && (
                <PModal open={resetAccount} onClose={() => handleResetAccountClose("cancel")} disableCloseButton={true}>
                    <ResetAccounting
                        commissionAccounting={ordersAccounting}
                        handleClosePopupReset={(closeType : string) => handleResetAccountClose(closeType)}
                        resetOrderAccount={resetOrderAccount}
                        alertHandler={alertHandler}
                    />
                </PModal>
            )}

        </div>
        <div className="flex30 pt20">
            <Snackbar
            style={{marginTop:"11%",marginRight:"5%"}}
                open={saveSuccess}
                anchorOrigin={{ vertical:"top", horizontal: "right" }}
                autoHideDuration={2000}
                onClose={handleSuccessClose}
            >
                <Alert severity="success" sx={{ width: "100%" }}>
                    {saveSuccessMsg}
                </Alert>
            </Snackbar>
            <Snackbar
                open={isError}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000}
                onClose={handleErrorClose}
            >
                <Alert severity="error" sx={{ width: "100%" }}>
                    {t("Error!")}
                </Alert>
            </Snackbar>
            <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

        </div>
        <PModal
            className="delete-commission-popup-container"
            open={Boolean(deleteManualCommissionId)}
            onClose={handleClosedeleteModal}
      >
                <PorscheFont className="delete-manual-heading">{t("Delete manual commission?")}</PorscheFont>
                <PorscheFont className='delete-manual-sub-heading'>{t("Excluded manual commissions are deleted. The creator and seller will be notified.")}</PorscheFont>
                <PTextFieldWrapper label={`${t('Reason')}`} id={`assignedTo`} className='manual-reason-textfield'>
                    <input
                        id={`iassignedTo`}
                        key={`kassignedTo`}
                        type="text"
                        required
                        value={manualReason}
                        defaultValue={manualReason}
                        onChange={onChangeManualReason}
                        maxLength={50}
                    />
                </PTextFieldWrapper>
                <PFlex direction={"row"} justifyContent={"flex-end"}>
                    <PFlexItem>
                    <PButtonGroup>
                        <PButton variant="tertiary" icon='close'onClick={handleClosedeleteModal}>{t("No, cancel")}</PButton>
                        <PButton variant="secondary" onClick={handleSubmitDeleteManualCommission} disabled={!manualReason?.length}>{t("Yes, delete commission")}</PButton>
                    </PButtonGroup >
                    </PFlexItem>
                </PFlex>
      </PModal>
    </>);
}
