import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PButton} from '@porsche-design-system/components-react';
import { PMainHeader, PorscheFont } from '../../assets/styles/CustomStyles';
import { Tasks } from '../../components/dashboard/Tasks';
import { NavSideBar } from '../../components/NavSideBar';
import { AuthContext } from '../../contexts/AuthContext';
import { Constant } from '../../configs/Constants';
import CustomSnakebar from '../../components/common/CustomSnakebar';

export const DashboardPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [enableCreateOrder, setEnableCreateOrder] = useState(false)
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState("")

  useEffect(() => {
    const init = async() => {
      const user = await authCtx.getUserDetails();
     
      if(user === null || user.isNewUser) {
        navigate("/welcome");
      }
      if(user?.scaling){
       setEnableCreateOrder(true) 
      }
    }
    init();
  }, [])

  const handleClick = () => {
    if (!enableCreateOrder) {
      setShowAlert(Constant.Error);
      setAlertMessage(Constant.NoScalingErrorMsg);
    } else {
      window.open("/#/order/create", "_blank");
    }
  };

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  return (
    <div className="custombody"> 
      <div className="navbar" style={{ position: 'fixed', top: '85px' }}>
        <NavSideBar disabled={!showSaveButton} main="Dashboard" subPage=""/> 
      </div>
      <div className="main" style={{ width: '100%',  backgroundColor: '#F2F2F2', padding: '100px 0px 10px 71px' }}>
        <div className="main-container">
          <div className="main-header" style={{ position: 'sticky', top: '70px', marginRight:'170px' }}>
            <div className='main-header-left'>
              <PMainHeader>
                <PorscheFont data-testid="page-dashboard" className="main-header-text" style={{fontWeight: "800"}}>{t("Dashboard")}</PorscheFont>
                <PorscheFont className="main-header-description" style={{paddingTop: "10px"}}>
                  {t('Welcome to DCS - The Porsche Dealer Calculation System.')}<br/>
                  {t('Within the Dashboard you can create new vehicle orders and follow the latest updates on your orders.')}
                </PorscheFont>
              </PMainHeader>
            </div>
            {/* <div className='main-header-right' style={window.innerHeight>1000 ? {marginRight:"-145px", position:"relative"}: {marginRight:"25px", position:"relative"}}> */}
            <div className='main-header-right' style={{marginRight:"25px", position:"relative"}}>
              <PButton data-testid="new-order" icon='add' onClick={handleClick} style={{position:"absolute",right:"38px"}}>
                <PorscheFont className="create-new-order"  style={{marginRight:"-5px"}}>
                {t('Create New Order')}
                </PorscheFont>
              </PButton>
            </div>            
          </div>
          <div style={{margin: "40px 32px 16px 0px"}}>
            <Tasks /> 
          </div>
        </div>
      </div>
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>
    </div>
  );
}