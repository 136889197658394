
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PButton, PButtonGroup,
  PFlex, PFlexItem
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../assets/styles/CustomStyles';
import { Constant } from '../../configs/Constants';
import { AccountingResponseDTO } from '../../interfaces/Commissions';
import { type } from '../../services/Constants';
import {
  dcsresetComissionsAccounting,
  dcsrolloutComissionsAccounting
} from '../../services/data/Helpers';

//import { TaskRow } from './TaskRow';

interface Props {
  commissionAccounting: AccountingResponseDTO;
  handleClosePopupReset: (closeType: string) => void;
  resetOrderAccount: () => void;
  alertHandler: (status: string, message: string) => void;

}

export const ResetAccounting = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { handleClosePopupReset,commissionAccounting, resetOrderAccount, alertHandler} = props;
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }

  const handleCancel = () => {
    handleClosePopupReset("cancel");
  }
  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };
  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const handleKeepOrder = () => {
    const init = async () => {
      if(commissionAccounting.approvedOneOn!=null && commissionAccounting.approvedTwoOn!=null){

        const apiResponse = await dcsrolloutComissionsAccounting(commissionAccounting)
      if(apiResponse.status===type.SUCCESS){
         alertHandler(Constant.Success, "Reset successfully")
      }
      if(apiResponse.status===type.ERROR){
          alertHandler(Constant.Error, apiResponse.response)
        }
        resetOrderAccount();
      }else{
      const apiResponse = await dcsresetComissionsAccounting(commissionAccounting)
      if(apiResponse.status===type.SUCCESS){
        alertHandler(Constant.Success, "Accounting process cancelled")
      }
      if(apiResponse.status===type.ERROR){
        alertHandler(Constant.Error, apiResponse.response)
      }
      }
      handleClosePopupReset("cancel");
    }
    init();
  }

  return (
    <>
      <div style={{ height: "auto", minWidth: "450px", margin: "-20px"}}>
        { (commissionAccounting.approvedOneOn!=null && commissionAccounting.approvedTwoOn!=null)
          ? (
            <>
              <PorscheFont style={{fontSize:"16px", fontWeight:"700"}}>{t("Reset accounting period?")}</PorscheFont>
              <PorscheFont className='dcs-title'>{t("Approved list is cleared and can be found in History.")}</PorscheFont>
              <PFlex justifyContent={"flex-end"} style={{marginTop: "16px"}}>
                <PFlexItem>
                  <PButtonGroup>
                    <PButton variant="tertiary" icon='close' onClick={handleCancel}>{t("No, cancel")}</PButton>
                    <PButton variant="secondary" onClick={handleKeepOrder}>{t("Yes, reset accounting")}</PButton>
                  </PButtonGroup>
                </PFlexItem>
              </PFlex>
            </>
          ) : (
            <>
              <PorscheFont style={{fontSize:"16px", fontWeight:"700"}}>{t("Cancel accounting process?")}</PorscheFont>
              <PorscheFont className='dcs-title'>{t("Granted accounting approvals and accounting month will be reset.")}</PorscheFont>
              <PFlex justifyContent={"flex-end"} style={{marginTop: "16px"}}>
                <PFlexItem>
                  <PButtonGroup>
                    <PButton variant="tertiary" icon='close' onClick={handleCancel}>{t("No, cancel")}</PButton>
                    <PButton variant="secondary" onClick={handleKeepOrder}>{t("Yes, cancel accounting")}</PButton>
                  </PButtonGroup>
                </PFlexItem>
              </PFlex>
            </>
          )
        }
        
      </div>
      
    </>
  );
}

