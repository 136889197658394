import React, { useEffect } from 'react'

type Props = {
    setActivated:React.Dispatch<React.SetStateAction<boolean>>
}

export default function InactiveUser({setActivated}: Props) {
    useEffect(()=>{
        setActivated(false)
    },[])
  return (
    <>
    <div>InactiveUser</div>  
    </>
    
  )
}