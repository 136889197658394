import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PButton,
  PButtonGroup,
  PButtonPure, PDivider, PFlex, PFlexItem, PGrid, PGridItem, PModal, PPopover, PText
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../assets/styles/CustomStyles';
import { Constant } from '../../configs/Constants';
import { RecipientNotificationResponseDTO } from '../../interfaces/TasksAndNotifications';
import { type } from '../../services/Constants';
import { dcsdeleteNotifications, dcsmarkAsReadNotifications } from '../../services/data/Helpers';
import CustomSnakebar from '../common/CustomSnakebar';
import { NotificationPopup } from './NotificationPopup';
import { SingleOrderDetailType } from '../../interfaces/OrderDetails';

interface Props {
  notificationDetails: RecipientNotificationResponseDTO;
  handleReload: () => void;
}

export const NotificationRow = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { notificationDetails, handleReload } = props;
  const [reload, setReload] = useState("");
  const [showPage, setShowPage] = useState<boolean>(false);
  const [defaultRow, setDefaultRow] = useState<boolean>(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [deleteDashboard, setDeleteDashboard] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  const handleDetailsClose = () => {
    setIsDetailsOpen(false);
    window.location.reload();
  };

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }

  const handlePage = (orderId: number, status: boolean) => {
    if (orderId) {
      const singOrderDetails:SingleOrderDetailType = { id: orderId, mode:'view', draft: true };
      const newWindow=  window.open(`/#/order/create`, "_blank") 
      newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));

    }
  }

  const handleDelete = () => {
    setDeleteDashboard(true);
  }

  const handleDeleteYes = () => {
    try {
      const init = async () => {
        if (notificationDetails.notification) {
          const apiResponse =  await dcsdeleteNotifications(notificationDetails.notification.id)
          if(apiResponse.status===type.SUCCESS){
            setDeleteDashboard(false);
            handleReload();
            setShowAlert(Constant.Success)    
            setAlertMessage("successfully deleted")
            setTimeout(()=>{window.location.reload()}, 500)
          }
          if(apiResponse.status===type.ERROR){
            setShowAlert(Constant.Error)
            setAlertMessage(apiResponse.response)  
          }

        }
      }
      init();
    } catch (e) {
    }
  }

  const handleDeleteNo = () => {
    setDeleteDashboard(false)
  }
  const handleMarkAsRead = async () => {
    setIsDetailsOpen(true);
    const apiResponse = await dcsmarkAsReadNotifications(notificationDetails?.notification?.id!)
  }
  useEffect(() => { }, [reload])

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
    sessionStorage.setItem("messageFlag", ("false" || false));
  };
  return (
    <>
      {/* { notificationDetails.notification?.notificationType ==="Triggered" ?{}:{}} */}
      <div style={{margin: "3px 1px", boxShadow: '0 1px 0px rgba(0,0,0,0.1)'}}>
        <PFlex>
          <PFlexItem>
            {/* have to add changes to vertical line */}
            <PDivider 
              orientation="vertical"
              style={
                notificationDetails.markAsRead 
                ? { backgroundColor: "#C8CACB", width: "5px", height: "100%" } 
                : { backgroundColor: "#D5001B", width: "5px", height: "100%" }} 
            />
          </PFlexItem>
          <PFlexItem width="three-quarters" style={{padding: "0 10px"}}>
            {/* {defaultRow && ( */}
            <PGrid>
              <PGridItem size={12} style={{paddingTop: "8px", paddingBottom: "2px", minHeight:  "34px"}}>
                <PorscheFont style={notificationDetails.markAsRead ? {} : {fontWeight: '800'}}>
                  {notificationDetails?.notification?.subject 
                    ? t(notificationDetails?.notification?.subject)
                    : `${t('Task edited')}`
                  }
                </PorscheFont>
              </PGridItem>
              <PGridItem size={12} style={{paddingTop: "2px", paddingBottom: "8px", minHeight:  "34px"}}>
                <PorscheFont>
                  { notificationDetails?.notification?.order
                    ? (
                      <>
                        {notificationDetails?.notification?.order?.orderNumber ? t(notificationDetails?.notification?.order?.orderNumber) + ' | ': " "}
                        {notificationDetails?.notification?.order?.commissionNumber ? t(notificationDetails?.notification?.order?.commissionNumber) + ' | ': " "}
                        {notificationDetails?.notification?.order?.actNumber ? t(notificationDetails?.notification.order?.actNumber + ' | ') : " "}
                        {/* {notificationDetails?.notification?.order?.customerFirstname ? notificationDetails?.notification?.order?.customerFirstname : ""}
                        {notificationDetails?.notification?.order?.customerLastname ? " " + notificationDetails.notification?.order?.customerLastname + ' |' : "  "} */}
                        {notificationDetails?.notification?.order?.name ? t(notificationDetails?.notification?.order?.name + ' | ') : "" }
                        {notificationDetails?.notification?.order?.vehicleCategory ? t(notificationDetails?.notification?.order?.vehicleCategory) + ' | ': " "}
                        {notificationDetails?.notification?.order?.model ? t(notificationDetails?.notification?.order?.model + ' | '): " "}
                        {notificationDetails?.notification?.order?.paymentType ? t(notificationDetails?.notification?.order?.paymentType) + ' | ': " "}
                        {notificationDetails?.notification?.order?.sellerFirstname ? t(notificationDetails?.notification?.order?.sellerFirstname) : ""}
                        {notificationDetails?.notification?.order?.sellerLastname ? " " + t(notificationDetails?.notification?.order?.sellerLastname) : ""}
                      </>
                    ) : (
                      <>
                        {notificationDetails?.notification?.description ? t(notificationDetails?.notification?.description) : `${t('No matching order')}`}
                      </>
                    )
                  }
                </PorscheFont>
              </PGridItem>
            </PGrid>
            {/* )} */}
          </PFlexItem>
          <PFlexItem 
            width="one-quarter" 
            style={{display:"flex", alignItems: "center", justifyContent: "space-between"}}
          >
            <PText>
              {notificationDetails.notification?.sentOn 
                ? notificationDetails.notification.sentOn.substring(0, 16).replaceAll("-", ".") 
                : `${t('Task edited')}`
              }
            </PText>
            <PText>
              <PPopover 
                description="Some additional content."
                aria={{ 'aria-label': 'Some more descriptive label' }}
                onClick={handleMarkAsRead}>
              </PPopover>
            </PText>
            <PButtonPure icon="delete" onClick={handleDelete} ></PButtonPure>
          </PFlexItem>
        </PFlex>
      </div>
      <PModal
        className="overview-modal"
        open={deleteDashboard}
        onClose={handleDeleteNo}
      >
        <PFlex>
          <PFlexItem style={{ marginRight: "4px", marginTop: "16px" }}>
            <PorscheFont className="dcs-heading">
              {t("Are you sure you want to delete this notification from your dashboard?")}
            </PorscheFont>
          </PFlexItem>
        </PFlex>
        <PFlex dir="rtl" style={{ marginTop: "14px" }}>
          <PFlexItem>
            <PButtonGroup>
              <PButton 
                icon={"arrow-head-right"} 
                onClick={handleDeleteYes}
              >
                {t("Yes, delete")}
              </PButton>
              <PButton
                variant="tertiary"
                icon={"close"}
                onClick={handleDeleteNo}
              >
                <PorscheFont>
                  {t("Cancel")}
                </PorscheFont>
              </PButton>
            </PButtonGroup>
          </PFlexItem>
        </PFlex>
      </PModal>
      {/* <PModal className="overview-modal" open={isDetailsOpen} onClose={handleDetailsClose} >
        <NotificationPopup notification={notification} handleClose={handleDetailsClose} /> 
      </PModal>    */}
      <PModal className="overview-modal" open={isDetailsOpen} onClose={handleDetailsClose} >
        <NotificationPopup notificationDetails={notificationDetails} handleClose={handleDetailsClose} />
      </PModal>
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>
    </>
  );
}