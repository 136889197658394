import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PFlex, PFlexItem, PIcon } from "@porsche-design-system/components-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { type } from "../../../services/Constants";
import { dcsgetChangeHistoryLogs } from "../../../services/data/Helpers";

export interface historyTableDataType {
  date: string;
  time: string;
  user: string;
  page: string;
  tab: string;
}

export interface ReponseDataType {
  dateTime: string;
  user: string;
  page: string;
  tab: string;
}
function HistoryTable() {
  const [historyTableData, setHistoryTableData] = useState<
    historyTableDataType[]
  >([]);

  const [showAllHistory, setShowAllHistory] = useState(false);


  const sortingHandler = (response: ReponseDataType[]) => {
    const sortedData = response.sort((a: any, b: any) => {
      const dateA = new Date(a.dateTime);
      const dateB = new Date(b.dateTime);
      return dateB.getTime() - dateA.getTime();
    });

    const formatedData = sortedData.map((obj: any) => {
      const dateTime = new Date(obj.dateTime);
      const date = dateTime.toLocaleDateString("de-DE", {day:'2-digit', month:'2-digit', year:"numeric"});
      const time = dateTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      return {
        date,
        time,
        user: obj.user,
        page: obj.page,
        tab: obj.tab,
      };
    });
    setHistoryTableData(formatedData);
  };

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetChangeHistoryLogs(showAllHistory)
      if(apiResponse.status===type.SUCCESS){
        sortingHandler(apiResponse.response);
      }
    };
    init();
  }, [showAllHistory]);
  const { t } = useTranslation();

  const handleShowHistory = () => {
    setShowAllHistory(!showAllHistory)
  };
  return (
    <div>
      <TableContainer
        sx={
          window.innerHeight > 1000
            ? { maxHeight: "780px" }
            : { maxHeight: "460px" }
        }
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="cell-align-new">
                <PorscheFont style={{marginBottom:'8px'}} className="sub-headding">{t("Date")}</PorscheFont>
              </TableCell>

              <TableCell className="cell-align-new">
                 <PorscheFont style={{marginBottom:'8px'}} className="sub-headding">{t("Time")}</PorscheFont>

              </TableCell>

              <TableCell className="cell-align-new">
                  <PorscheFont style={{marginBottom:'8px'}} className="sub-headding">{t("User")}</PorscheFont>

              </TableCell>

              <TableCell className="cell-align-new">
                <PorscheFont style={{marginBottom:'8px'}} className="sub-headding">{t("Page")}</PorscheFont>

              </TableCell>

              <TableCell className="cell-align-new">
                <PorscheFont style={{marginBottom:'8px'}} className="sub-headding">{t("Tab")}</PorscheFont>

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyTableData.map((item, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell className="cell-align-new">
                    <PorscheFont className="changeHistory-rowitem">
                      {item.date}
                    </PorscheFont>
                  </TableCell>
                  <TableCell className="cell-align-new">
                    <PorscheFont className="changeHistory-rowitem">
                      {item.time}
                    </PorscheFont>
                  </TableCell>
                  <TableCell className="cell-align-new">
                    <PorscheFont className="changeHistory-rowitem">
                      {item.user}
                    </PorscheFont>
                  </TableCell>
                  <TableCell className="cell-align-new">
                    <PorscheFont className="changeHistory-rowitem">
                      {t(item.page)}
                    </PorscheFont>
                  </TableCell>
                  <TableCell className="cell-align-new">
                    <PorscheFont className="changeHistory-rowitem">
                      {t(item.tab)}
                    </PorscheFont>
                  </TableCell>
                </TableRow>
              );
            })}

{historyTableData.length > 0 && (
              <TableRow>
                  <PFlex  style={{width:'100%', fontSize:'14px', marginTop:'16px'}}>
              <PFlexItem onClick={handleShowHistory} >
              
                <div style={{cursor:"pointer"}}>
                  <Stack direction={'row'} spacing={1}>
                  <PIcon name={!showAllHistory?'arrow-head-down':'arrow-head-up'}></PIcon>
                  <PorscheFont>{!showAllHistory?t('Show all'):t('Show less')}</PorscheFont>
                  </Stack>
                </div>

              </PFlexItem>
            </PFlex>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default HistoryTable;
