import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { IconName, PDivider, PIcon, PModal } from '@porsche-design-system/components-react';

import { PAccordionText } from '../../assets/styles/CustomStyles';
import { AuthContext } from '../../contexts/AuthContext';
import { IsUserAllowed } from '../../helpers/permissions';
import { SaveLeavePopup } from '../../pages/administration/SaveLeavePopup';
import { UserDetailsDTO } from '../../interfaces/User';

interface Props {
  heading: string;
  open: boolean;
  links: Array<any>;
  icon: string;
  subPage: string;
  color: boolean;
  linTextShow?: boolean;
  onClick: (main: string, expanded: boolean) => void;
  disabled: boolean;
  variablePageSave: () => void;
  commissionSave : () => void;
  approvalSave : () => void;
  collapseNav : () => void;
}

export const CustomNavAccordion = (props: Props) => {
  const { t } = useTranslation();
  const { heading, open, links, icon, subPage, color,linTextShow, onClick, collapseNav } = props;
  const [expand, setExpand] = useState<boolean>(open);
  const [allowSection, setAllowSection] = useState(false);
  const authCtx = useContext(AuthContext);
  const [isDataChanged, setIsDataChange] = useState<boolean>(false);
  const [reload, setReload] = useState("");
  const [toPath, setToPath] = useState("");
  useEffect(() => {
    switch(heading) {
      case "Orders":
      case "Commissions":
        setAllowSection(true);
        break;
  
      case "Administration":
        if(!IsUserAllowed("basicUser", authCtx)) {
          setAllowSection(true);
        }
        break;
    }
  }, [])

  const handleClick = () => {
    setExpand((prevState) => !prevState);
    onClick(heading, !expand);
  };

  const handleModalClose = () => {
    setIsDataChange(false);
    collapseNav();
    setReload(new Date().toString())
  };

  const checkChanges = (e: any, link: string) => {
    setToPath(link);
    const checkData: boolean = JSON.parse(sessionStorage.getItem("changeFlag") || "false") || (false);
    setIsDataChange(checkData);
    // alert(checkData)
    if (checkData) {
      e.preventDefault();
    }
  }

  let userDetails = {} as UserDetailsDTO;
  let userDetailsSes = sessionStorage.getItem("userDetails");
  if(userDetailsSes){
    userDetails = JSON.parse(userDetailsSes);
  }

  return (
    <>
    { allowSection && (
      <div className="accordion">
      <div className="collapsible" onClick={handleClick}>
        <div data-testid={`nav-header-${heading.toLowerCase()}`} className="nav-header-right">
          <PIcon name={icon as IconName} color={color  ? "brand" : "neutral-contrast-medium"} />
          <PAccordionText style={color ? { color: "#D5001B" }: { color: "#313639" } }>
           {linTextShow? t(heading) :" "} 
          </PAccordionText>
        </div>
        <div className="nav-header-left">
        {linTextShow ? <PIcon name={expand ? "arrow-head-up" : "arrow-head-down"} style={{color: "white", fill:"white"}} /> : ""}
        </div>
      </div>
      {linTextShow ? <div className={expand ? "show" :  "hide" }>
        <ul>
          {links.map((link, index) => {
            let permissionName : keyof UserDetailsDTO = link.permissionName
            // By default we are making true, reason is to display other subsections other eg: orders
            let allow =true;
            if(allow) {

              if(heading==="Administration" ){
                return (
                
                  <li key={index}>
                    {userDetails[permissionName] &&<NavLink
                      className={
                        subPage === link.name
                        ? "nav-link-text-selected"
                        : "no-underline"
                      }
                      to={link.path}
                      onClick={e => checkChanges(e, link.path)}
                    >
                      <div key={index} className={ subPage === link.name ? "nav-link-text-selected" : "nav-link-text"} > 
                        {  `${t(link.name)}`}
                      </div>
                    </NavLink>}
                    
                  </li>
                );
              }

              return (
                
                <li key={index}>
                  <NavLink
                    className={
                      subPage === link.name
                      ? "nav-link-text-selected"
                      : "no-underline"
                    }
                    to={link.path}
                    onClick={e => checkChanges(e, link.path)}
                  >
                    <div key={index} className={ subPage === link.name ? "nav-link-text-selected" : "nav-link-text"} > 
                      {`${t(link.name)}`}
                    </div>
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>
      </div>:'' }
      { linTextShow && (
        <PDivider />
        )
      }
      
      </div>
    )}

    {isDataChanged && (
      <PModal open={isDataChanged} onClose={handleModalClose} >
        <SaveLeavePopup
          handleClosePopupCancel={handleModalClose}
          heading={heading}
          links={links}
          subpage={subPage}
          toPath={toPath}
          variablePageSave={props.variablePageSave}
          commissionSave={props.commissionSave}
          approvalSave={props.approvalSave}
        />
      </PModal>
    )}
    </>
  );
};
