import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { AuthContext } from '../contexts/AuthContext';
import { AuthService } from '../services/AuthService';
import { getLoggedInUserDetails } from '../services/data/Helpers';

export const LoginPage = (): JSX.Element => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const init = async() => {
      const access_token = sessionStorage.getItem("access_token");
      if(access_token) {
        const user =  getLoggedInUserDetails();
        
        if(user.isNewUser) {
          navigate("/welcome");
        } else {
          navigate("/dashboard");
        }
      } else {
        
        //authCtx.login();
      }
    }
    init();
  }, [])

  return (
    <div className="custombody"> 
      <div className="main">
        <h1>Porsche DCS</h1>
      </div>
    </div>
  );
};
