import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  PButton,
  PButtonGroup,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PTextFieldWrapper
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { OrderContext } from "../../../contexts/OrderContext";
import { CustomerDeliveryDTO } from "../../../interfaces/ActionsOrderProcessing";
import { CalculationDraftDTO } from "../../../interfaces/Calculation";
import { OrderDetailsDTO } from "../../../interfaces/OrderDetails";
import { UserDetailsDTO } from "../../../interfaces/User";
import { HistoryOrderLogTypes } from "../../../interfaces/changeHistory";
import { type } from "../../../services/Constants";
import {
  dcsgetOrderSummary,
  dcspostChangeHistoryLogs,
  dcsputSubmitCustomerDelivery
} from "../../../services/data/Helpers";
import CustomSnakebar from "../../common/CustomSnakebar";
import { Constant } from "../../../configs/Constants";

interface Props {
  orderId: number;
  handleCloseOrderRegister: () => void;

  //   isDraft:boolean,
  //   open: boolean,
}

export const CustomerDelivery = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    orderId,
    handleCloseOrderRegister,
    //  isDraft, open
  } = props;
  const [save, setSave] = useState(false);
  const [reload, setReload] = useState("");
  const orderContext = useContext(OrderContext);
  const customerDeliveryDetails = orderContext.getCtxOrderDetails();
  const [users, setUsers] = useState<Array<UserDetailsDTO>>([]);
  const [customerDel, setCustomerDel] = useState<OrderDetailsDTO>(
    {} as OrderDetailsDTO
  );
  const [validationError, setValidationError] = useState(false);
  const [dateOfOrder, setDateOfOrder] = useState("");
  const [dateOfPlannedDelivery, setDateOfPlannedDelivery] = useState("");
  const [customerDeliverySubmit, setCustomerDeliverySubmit] =
    useState<CustomerDeliveryDTO>({} as CustomerDeliveryDTO);
  const [edit, setEdit] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [modified, setModified] = useState(false);
  const [calDraft, setCalDraft] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetOrderSummary(orderId)
      if(apiResponse.status===type.SUCCESS){

     
      // setOrderResult(orderData?.rawResponse?.data['result'])
      const calDraft = apiResponse.response
        ?.calculation as CalculationDraftDTO;
      setCalDraft(calDraft);
      const customerDel = apiResponse.response
        ?.order as OrderDetailsDTO;
      setCustomerDel(customerDel);
      if (
        calDraft?.copTicketId === undefined ||
        calDraft?.copTicketId === null
      ) {
        setSave(true);
        setEdit(false);
      } else {
        customerDeliverySubmit.copTicketId = calDraft?.copTicketId;
        setCustomerDeliverySubmit(customerDeliverySubmit);
      }
      if (customerDel?.invoiceDate == undefined) {
        setSave(true);
        setEdit(false);
      } else {
        customerDeliverySubmit.invoiceDate = customerDel.invoiceDate;

        setDateOfOrder(
          new Date(customerDel.invoiceDate).toISOString().split("T")[0]
        );
      }
      if (customerDel?.customerDeliveryDate == undefined) {
        setSave(true);
        setEdit(false);
      } else {
        customerDeliverySubmit.customerDeliveryDate =
          customerDel.customerDeliveryDate;
        setDateOfPlannedDelivery(
          new Date(customerDel.customerDeliveryDate).toISOString().split("T")[0]
        );
      }
    }
  }

    init();
  }, []);
  const handleCancel = () => {
    //closeDetails();
    setEdit(false);
  };

  const handleSubmitCustomerDelivery = (actionType : string) => {
    const init = async () => {
      const apiResponse = await dcsputSubmitCustomerDelivery(customerDeliverySubmit,
        orderId)
      if (apiResponse.status===type.SUCCESS) {
        //   handleClosePopup();
        setShowAlert(Constant.Success)    
        setAlertMessage(Constant.Savedsuccessfully)
        const logData: HistoryOrderLogTypes = {
          page: "",
          tab: actionType === "edit" ? "Customer Delivery Edited" : "Customer Delivery Submitted",
          userId: loggedInUserId,
          comment: "",
          orderId: orderId
        };
        await dcspostChangeHistoryLogs(logData, 'order');
        setEdit(false);
        setSave(false);
      } 
      if(apiResponse.status===type.ERROR){
        setShowAlert(Constant.Error)    
        setAlertMessage(apiResponse.response)
      }
              

    };
    init();
  };

  useEffect(() => {}, [validationError]);

  const isValidcustomerDeliverySubmit = () => {
    if (
      customerDeliverySubmit.customerDeliveryDate === undefined ||
      customerDeliverySubmit.customerDeliveryDate.length === 0
    ) {
      setValidationError(true);
      return false;
    }

    if (
      customerDeliverySubmit.invoiceDate === undefined ||
      customerDeliverySubmit.invoiceDate === undefined
    ) {
      setValidationError(true);
      return false;
    }

    if (
      customerDeliverySubmit.copTicketId === undefined ||
      customerDeliverySubmit.copTicketId === undefined
    ) {
      setValidationError(true);
      return false;
    }
    setValidationError(false);
    return true;
  };



  const handleSubject = (value: string) => {
    setModified(true);
    customerDeliverySubmit.copTicketId = value;
    setCustomerDeliverySubmit(customerDeliverySubmit);
    setIsValid(isValidcustomerDeliverySubmit());
  };

  const handleDate = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length > 0) {
      setModified(true);
      setDateOfPlannedDelivery(e.target.value);
      customerDeliverySubmit.customerDeliveryDate = e.target.value;
      setCustomerDeliverySubmit(customerDeliverySubmit);
      setIsValid(isValidcustomerDeliverySubmit());
    } else {
      setModified(true);
      setDateOfPlannedDelivery("");
      customerDeliverySubmit.customerDeliveryDate = "";
      setCustomerDeliverySubmit(customerDeliverySubmit);
      setIsValid(isValidcustomerDeliverySubmit());
    }
  };
  const handleDateInvoice = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length > 0) {
      setModified(true);
      setDateOfOrder(e.target.value);
      customerDeliverySubmit.invoiceDate = e.target.value;
      setCustomerDeliverySubmit(customerDeliverySubmit);
      setIsValid(isValidcustomerDeliverySubmit());
    } else {
      setModified(true);
      setDateOfOrder("");
      customerDeliverySubmit.invoiceDate = "";
      setCustomerDeliverySubmit(customerDeliverySubmit);
      setIsValid(isValidcustomerDeliverySubmit());
    }
  };

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  return (
    <>
      <br></br>
      <PorscheFont className="dcs-heading">
        {t("Submit customer delivery")}
      </PorscheFont>
      <br></br>
      <PFlex direction="column" className="example-grid">
        <PFlexItem style={{ width: "280px" }}>
          <PTextFieldWrapper label={`${t("Customer delivery date")}`}>
            <input
              type="date"
              name="dateOfOrder"
              max="9999-12-31"
              defaultValue={
                customerDel?.customerDeliveryDate
                  ? customerDel?.customerDeliveryDate
                  : ""
              }
              value={dateOfPlannedDelivery}
              required
              onChange={handleDate}
              readOnly={edit || save ? false : true}
            />
          </PTextFieldWrapper>
        </PFlexItem>
        <br></br>
        <PFlexItem style={{ width: "280px" }}>
          <PTextFieldWrapper label={`${t("Invoice date")}`}>
            <input
              type="date"
              name="dateOfOrder"
              max="9999-12-31"
              defaultValue={
                customerDel?.invoiceDate ? customerDel?.invoiceDate : ""
              }
              value={dateOfOrder}
              required
              onChange={handleDateInvoice}
              readOnly={edit || save ? false : true}
            />
          </PTextFieldWrapper>
        </PFlexItem>
        <br></br>
        <PFlexItem style={{ width: "280px" }}>
          {" "}
          <PTextFieldWrapper
            className="dcs-heading"
            label={`${t("C@P Ticket ID")}`}
          >
            <input
              onChange={(e) => handleSubject(e.target.value)}
              defaultValue={calDraft?.copTicketId ? calDraft?.copTicketId : ""}
              type="text"
              name="task"
              required
              readOnly={edit || save ? false : true}
            />
          </PTextFieldWrapper>
        </PFlexItem>
      </PFlex>
      {validationError && (
        <div className="mt10">
          <PorscheFont>
            <span style={{ color: "#D5001B" }}>
              {t("Please enter valid values.")}
            </span>
          </PorscheFont>
        </div>
      )}
      {edit && (
        <PGrid>
          <PGridItem offset={9} size={5} style={{ marginTop: "50px" }}>
            <PButtonGroup>
              <PButton
                variant="secondary"
                icon="save"
                disabled={modified ? false : true}
                onClick={()=>handleSubmitCustomerDelivery("edit")}
              >
                {t("Save")}
              </PButton>
              <PButton variant="tertiary" icon="close" onClick={handleCancel}>
                {t("Cancel")}
              </PButton>
            </PButtonGroup>
          </PGridItem>
        </PGrid>
      )}
      {!edit && !save && (
        <PGrid>
          <PGridItem offset={10} size={5} style={{ marginTop: "50px" }}>
            <PButtonGroup>
              <PButton
                variant="secondary"
                icon="edit"
                onClick={() => {
                  setSave(false);
                  setEdit(true);
                }}
              >
                {t("Edit")}
              </PButton>
            </PButtonGroup>
          </PGridItem>
        </PGrid>
      )}
      {save && (
        <PGrid>
          <PGridItem
            offset={10}
            size={3}
            style={{ marginTop: "50px", marginRight: "50px" }}
          >
            <PButtonGroup>
              <PButton
                variant="secondary"
                icon="save"
                disabled={!isValid}
                onClick={()=>handleSubmitCustomerDelivery("create")}
              >
                {t("Save")}
              </PButton>
              {/* <PButton variant="secondary" onClick={handleCancel}>{t('Cancel')}</PButton> */}
            </PButtonGroup>
          </PGridItem>
        </PGrid>
      )}
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

    </>
  );
};
