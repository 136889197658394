import { PButton, PFlex, PFlexItem, PMarque, PText } from '@porsche-design-system/components-react';

import { Constants } from '../configs/Constants';
import { useTranslation } from 'react-i18next';

export const ActivationStatus = (): JSX.Element => {
    const { t } = useTranslation();

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        let url = `${Constants.domain}`;
        window.open(url, "_self");
    }

    return (
        <>
            <div className="header" style={{ minHeight: '80px' }}>
                <PFlex justifyContent={"center"} >
                    <PMarque className="logo" size="responsive" style={{ paddingTop: "5px" }} />
                </PFlex>
            </div>
            <div className="custombody" >
                <PFlex direction={'column'} justifyContent={'center'} alignItems={'center'} style={{ gap: '40px', width: '100%', height: '400px' }}>
                    <PFlexItem>
                        <PText size="medium" align="center" weight='semibold'>
                            {t("Freischaltung ausstehend")}
                            </PText>
                    </PFlexItem>

                    <PFlexItem style={{ marginTop: '20px' }}>
                        <PText size="medium"  >
                        {t("Die Seite, die Sie aufrufen möchten, ist noch nicht verfügbar.")}
                            </PText>
                    </PFlexItem>

                    <PButton onClick={handleLogout} variant="tertiary">
                    {t("Fenster schließen")}
                        </PButton>
                </PFlex>

            </div>
        </>
    );
};

