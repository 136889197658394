
import { UserDetailsDTO } from '../interfaces/User';
import { UserDetails } from '../interfaces/UserDetails';

export const IsUserAllowed = (permission: string, authCtx: any): Boolean => {
  let allow = false;
  let userDetails = {} as UserDetailsDTO;
  let userDetailsSes = sessionStorage.getItem("userDetails");
  if(userDetailsSes){
    userDetails = JSON.parse(userDetailsSes);
  } else {
    return false;
  }


  
  // Restrict inactive users at the start itself
  if (!userDetails.active) {
    return false;
  }
  switch (permission) {
    case "basicUser":
      if (
        userDetails.isAdmin ||
        userDetails.enableVariabales ||
        userDetails.enableScaling ||
        userDetails.enableUserMgmt ||
        userDetails.enableApprovals ||
        userDetails.canViewAllOrders ||
        userDetails.canEditAllOrders ||
        userDetails.enableCommission ||
        userDetails.canExportOrders ||
        userDetails.canExportCommissions
      ) {
        // has permission
        allow = false;
      } else {
        // Yes user has basic permissions
        allow = true;
      }
      break;
    case "admin":
      allow = userDetails.isAdmin ? true : false;
      break;
    case "adminVariables":
      if (userDetails.enableVariabales) {
        allow = true;
      }
      break;
    case "adminScaling":
      if (userDetails.enableScaling) {
        allow = true;
      }
      break;
    case "adminUserMgmt":
      if (userDetails.enableUserMgmt) {
        allow = true;
      }
      break;
    case "adminAprrovals":
      if (userDetails.enableApprovals) {
        allow = true;
      }
      break;
    case "viewAllOrders":
      if (userDetails.canViewAllOrders) {
        allow = true;
      }
      break;
    case "editAllOrders":
      if (userDetails.canEditAllOrders) {
        allow = true;
      }
      break;
    case "viewCommissions":
      if (userDetails.enableCommission) {
        allow = true;
      }
      break;
    case "exportCommissions":
      if (userDetails.canExportCommissions) {
        allow = true;
      }
      break;
    case "exportOrders":
      if (userDetails.canExportOrders) {
        allow = true;
      }
      break;
  }

  return allow;
};
