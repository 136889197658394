import { createContext } from 'react';

import { Auth } from '../services/Auth';

export const AuthContext = createContext(Auth);

export const AuthContextProvider = AuthContext.Provider;

export const AuthContextConsumer = AuthContext.Consumer;

