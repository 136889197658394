import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Snackbar } from '@mui/material';
import {
  PButton, PButtonGroup, PDivider, PFlex, PFlexItem
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { Constant } from '../../../configs/Constants';
import { OrderContext } from '../../../contexts/OrderContext';
import { sortApprovalConfiguration } from '../../../helpers/sorter';
import {
  ApprovalStepDTO,
  ApprovalsRequestDTO, ApprovalsResponseDTO
} from '../../../interfaces/Approvals';
import { CalculationDraftDTO } from '../../../interfaces/Calculation';
import { UserDetailsDTO } from '../../../interfaces/User';
import {
  ApprovalConfiguration, ApprovalConfigurationStep
} from '../../../interfaces/adminApproval/ApprovalConfiguration';
import { type } from '../../../services/Constants';
import {
  dcschangeStatusOrderApproval, dcsgetApprovalConfiguration, dcsgetApprovals, dcspostCommissionApprovals, dcsputCommissionApprovals
} from '../../../services/data/Helpers';
import CustomSnakebar from '../../common/CustomSnakebar';
import { CommissionApprovalRow } from './CommissionApprovalRow';

interface Props {
  orderId: number;
  category: string;
  handleClose: (reload: boolean) => void;
}
  
export const CommissionApprovalNew = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { orderId, category, handleClose } = props;
  const [edit, setEdit] = useState<boolean>(false);
  const [approvalConfig, setApprovalConfig] = useState<ApprovalConfiguration>({} as ApprovalConfiguration ); 
  const [approvals, setApprovals] = useState<Array<ApprovalConfigurationStep>>([])
  const [commissionApprovals, setCommissionApprovals] = useState<Array<ApprovalStepDTO>>([]);
  const [approvalRequest, setApprovalRequest] = useState<ApprovalsRequestDTO>({} as ApprovalsRequestDTO);
  const [postApprovals, setPostApprovals] = useState<Array<ApprovalStepDTO>>([]);
  const [firstCommission, setFirstCommission] = useState<Array<ApprovalStepDTO>>([]);
  const [secondCommission, setSecondCommission] = useState<Array<ApprovalStepDTO>>([]);
  const [fixedCommission, setFixedCommission] = useState<Array<ApprovalStepDTO>>([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [nextStep, setNextStep] = useState("CommissionCheck1");
  const [enableNextStep, setEnableNextStep] = useState(false);
  const [isNextStepValid, setIsNextStepValid] = useState(false);
  const [approvedStep, setApprovedStep] = useState({} as ApprovalStepDTO);
  const [newAprovalStep, setNewApprovalStep] = useState({} as ApprovalStepDTO);
  const [isApprover, setIsApprover] = useState(false);
  const [reCalculation, setReCalculation] = useState<CalculationDraftDTO>({} as CalculationDraftDTO)
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  const orderContext = useContext(OrderContext);
  const order = orderContext.getCtxOrderDetails();
  const calc = orderContext.getCtxCalculationDraftDetails(order?.vehicleDetails?.category?.type);
  
  
  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if(sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }

  useEffect(() => {
    const init = async () => {
      if(order.porscheCenter) {
        const apiResponse = await dcsgetApprovalConfiguration(category, order.porscheCenter.id, order.id)
        if(apiResponse.status===type.SUCCESS){
          const approvalConfig = apiResponse.response as ApprovalConfiguration;
          if (approvalConfig?.commissionApproval) {
            approvalConfig.commissionApproval = sortApprovalConfiguration(approvalConfig.commissionApproval);
            setApprovals(approvalConfig.commissionApproval);
          }
          
          setApprovalConfig(approvalConfig);
        }
      } else {
        
      }

      setReCalculation(orderContext.getCtxReCalculation()?.reCalculation)
    };
    init();
  }, [])

  const loadInit = async () => {
    const approvalApiResp = await dcsgetApprovals(orderId)
    
    if(approvalApiResp.status===type.SUCCESS && approvalApiResp.response){
      const approvalResponse = approvalApiResp.response as ApprovalsResponseDTO;
      setCommissionApprovals(approvalResponse?.commissionApprovalSteps);
      
      let steps:Array<ApprovalStepDTO> = [];

      //if(approvalResponse.commissionApprovalSteps.length === 0) setEnableNextStep(true);
      approvalResponse.commissionApprovalSteps.map((commissionStep, i) => {
        let step = {
          createdBy: commissionStep.createdBy,
          configurationStep: commissionStep.configurationStep,
          approver: commissionStep.approver,
          approvalStep: commissionStep.approvalStep,
          isEnabled: commissionStep.isEnabled,
          action: commissionStep.action,
          id: commissionStep.id,
          order: commissionStep.order ? commissionStep.order : {id: order.id}
        } as ApprovalStepDTO;

        steps.push(step);
        if(i > 0 && commissionStep.action === "Approved") {
          setNextStep(commissionStep.approvalStep);
        }

        if(!isApprover) {
          setIsApprover(commissionStep.approver.id === userDetails.id ? true : false);

        }
        
      })

      if(steps.length > 0) {
        approvalRequest.approvalSteps = steps;
        setApprovalRequest(approvalRequest);
      }

      setFirstCommission(approvalResponse.commissionApprovalSteps.filter(x => x.approvalStep === "CommissionCheck1"));
      setSecondCommission(approvalResponse.commissionApprovalSteps.filter(x => x.approvalStep === "CommissionCheck2"));
      setFixedCommission(approvalResponse.commissionApprovalSteps.filter(x => x.approvalStep === "FixedCommission"));
    }
  };

  useEffect(() => {
    loadInit();
  }, []);

  const handleEdit = () => {
    setEdit(!edit);
    // if(!edit) {
    //   let post:Array<ApprovalStepDTO> = [];
    //   approvalRequest.approvalSteps.map((step, i) => {
    //     post.push(step)
    //   })
    //   setPostApprovals(post);
    // } else {
    //   setPostApprovals([]);
    // }
  }
  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };

  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const handleMessage = (value: boolean,  msg: string) => {
    if(value === true) {
        setSaveSuccess(true);
        setSaveSuccessMsg(msg);
    }
  }

  const validatePost = (post: Array<ApprovalStepDTO>) : boolean => {
    
    let valid = false;
    // First commission check
    const fc = post.filter(x => x.approvalStep === "CommissionCheck1");
    if(fc.length > 0) {
      if(fc[0].approver.id) {
        valid = true;
      } else {
        return false; 
      }
    }

    // Second commission check
    const sc = post.filter(x => x.approvalStep === "CommissionCheck2");
    
    if(sc.length > 0) {
      if(sc[0].approver.id) {
        valid = true;
      } else {
        return false; 
      }
    }

    const fixc = post.filter(x => x.approvalStep === "FixedCommission");
    if(fixc.length > 0) {
      if(fixc[0].approver.id) {
        valid = true;
      } else {
        return false; 
      }
    }
    
    return valid;
  }

  const handleAssignee = (approval: ApprovalStepDTO, config: ApprovalConfigurationStep, value: string, isNew: boolean) => {
    const uid = parseInt(value);
    if(uid > 0) {
      // Setting new approver truw to determine if any new step approver is created for examplefor ohters step.
      //if(isNew) showNewStepApprover(true);
      let user = config.approvers ? config.approvers.filter(user => user.id === uid) : [];
      if(postApprovals.length > 0){
        let temp = postApprovals;
        if(temp.length > 0){
          let exists = false;
          temp.map((step, i) => {
            if(step.approvalStep === approval.approvalStep) {
              exists = true;
              temp[i].approver = user.length > 0 ? user[0] : {};
            }
          })

          if(!exists) {
            const newApprover = {
                createdBy: approval.createdBy 
                  ? {id: approval.createdBy.id, ppnName: approval.createdBy.ppnName} 
                  : userDetails 
                  ? {id: userDetails.id, ppnName: userDetails.ppnName}
                  : {},
                configurationStep: config.id ? {id: config.id} : {},
                approvalStep: isNew ? config.approvalStep : approval.approvalStep,
                isEnabled: isNew ? config.isEnabled : approval.isEnabled,
                action: "Assigned",
                approver: user.length > 0 ? user[0] : {},
                order: { id: orderId }
            } as ApprovalStepDTO;

            if(approval.id){
              newApprover.id = approval.id; 
            }

            temp.push(newApprover);
          }

          setPostApprovals(temp);
          // Validate post array
          setIsValid(validatePost(temp));
        } 
      } else {
        let  newApprover = {
          createdBy: approval.createdBy 
                ? {id: approval.createdBy.id, ppnName: approval.createdBy.ppnName} 
                : userDetails 
                ? {id: userDetails.id, ppnName: userDetails.ppnName}
                : {},
          configurationStep: config.id ? {id: config.id} : {},
          approvalStep: isNew ? config.approvalStep : approval.approvalStep,
          isEnabled: isNew ? config.isEnabled : approval.isEnabled,
          action: "Assigned",
          approver: user.length > 0 ? user[0] : {},
          order: { id: orderId }
        } as ApprovalStepDTO;
        if(approval.id){
          newApprover.id = approval.id;
        }

        setPostApprovals([newApprover]);
        setIsValid(validatePost([newApprover]));
      }
    } else {
      
    }
  }

  const handleSave = () => {
    
    try{
      const init = async() => {
        const apiResponse = await  dcsputCommissionApprovals(postApprovals)
        if(apiResponse.status===type.SUCCESS) {
          await loadInit();
          setEdit(false);
          setShowAlert(Constant.Success)
          setAlertMessage(Constant.Savedsuccessfully)
        }  if(apiResponse.status===type.ERROR){
          setShowAlert(Constant.Error)
          setAlertMessage(apiResponse.response)
          }
      }
      init();
    } catch(e) {
      
    }
  }

  const handleSubmit = () => {
    
    
    
    try{
      const init = async() => {
        if(approvedStep.approver && approvedStep.approver.id) {
          const chnageApiResponse = await dcschangeStatusOrderApproval(approvedStep)
          if(chnageApiResponse.status===type.SUCCESS) {
            if(newAprovalStep?.approver?.id) {
              const updateApiResp = newAprovalStep?.id ? await dcsputCommissionApprovals([newAprovalStep]) : await dcspostCommissionApprovals([newAprovalStep]);
              if(updateApiResp.status===type.SUCCESS) {
                loadInit();
                setEdit(false);
                setEnableNextStep(false);
                setShowAlert(Constant.Success)
                setAlertMessage(Constant.Savedsuccessfully)
      
                setTimeout(() => handleClose(true), 1000);
              }  if(updateApiResp.status===type.ERROR) {
                setShowAlert(Constant.Error)
                setAlertMessage(updateApiResp.response)
              }
            } else {
              setSaveSuccess(true);
              setSaveSuccessMsg("Submitted successfully");
              setTimeout(() => handleClose(true), 1000);
            }
          }
          if(chnageApiResponse.status===type.ERROR) {
            setShowAlert(Constant.Error)
            setAlertMessage(chnageApiResponse.response)
          }
        } else if(newAprovalStep.approver?.id) {
          const postCommApiResp = await dcspostCommissionApprovals([newAprovalStep])
          
          if(postCommApiResp.status===type.SUCCESS) {
            loadInit();
            setEdit(false);
            setEnableNextStep(false);
            setShowAlert(Constant.Success)
            setAlertMessage(Constant.Savedsuccessfully)
            setTimeout(() => handleClose(true), 1000);
          } if(postCommApiResp.status===type.ERROR) {
            setShowAlert(Constant.Error)
            setAlertMessage(postCommApiResp.response)
          }
        }
      }
      init();
    } catch(e) {
      
    }
  }

  const handleApproved = (step: ApprovalStepDTO) => {
    
    
    let enable = false;
    switch(step.approvalStep) {
      case "CommissionCheck1":
        if(approvalConfig.commissionApproval &&  approvalConfig.commissionApproval.length> 0) {
          if (approvalConfig.commissionApproval[2].isEnabled ) {
            setNextStep("CommissionCheck2");
            enable = true;
          } else if(approvalConfig.commissionApproval[3].isEnabled && approvalConfig.commissionApproval[3].approvers && approvalConfig.commissionApproval[3].approvers.length > 0 && (reCalculation?.commissionCalculation?.vehicleCommission==="Fixed"||(reCalculation?.commissionCalculation?.vehicleCommission==="Standard" && (reCalculation?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MaxCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MinCommission")) )) {
            setNextStep("FixedCommission");
            enable = true;
          }
        }
        break;
      case "CommissionCheck2":
        
        if(approvalConfig.commissionApproval &&  approvalConfig.commissionApproval.length> 0) {
          if(approvalConfig.commissionApproval[3].isEnabled && approvalConfig.commissionApproval[3].approvers && approvalConfig.commissionApproval[3].approvers.length > 0 && 
            (reCalculation?.commissionCalculation?.vehicleCommission==="Fixed"||(reCalculation?.commissionCalculation?.vehicleCommission==="Standard" && (reCalculation?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MaxCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MinCommission")) )) {
            setNextStep("FixedCommission");
            enable = true;
          }
        }
        
        break;
      case "FixedCommission":
        break;
    }
    
    
    if(enable) {
      setEdit(false);
      setEnableNextStep(true);
      setApprovedStep(step);
    }else{
      setEnableNextStep(true);
      setApprovedStep(step);
      setIsNextStepValid(true);
    }
  }

  const handleNewAssignee = (nextStep: ApprovalConfigurationStep, value: string, exists: ApprovalStepDTO) => {
    const uid = parseInt(value);
    
    //if(uid > 0) {
      
      let temp = newAprovalStep;
      let user = nextStep.approvers ? nextStep.approvers.filter(user => user.id === uid) : [];

    if(temp.order === undefined) {
      let ns = {
        createdBy: userDetails 
            ? {id: userDetails.id, ppnName: userDetails.ppnName}
            : {},
          configurationStep: nextStep.id ? {id: nextStep.id} : {},
          approvalStep: nextStep.approvalStep,
          isEnabled: nextStep.isEnabled,
          action: "Assigned",
          approver: user.length > 0 ? user[0] : {},
          order: { id: orderId }
      } as ApprovalStepDTO;
      temp = ns;
    } else {
      temp.approver = user.length > 0 ? user[0] : {};
    }

    if(exists && exists.id !== undefined) temp.id = exists.id;
    setIsNextStepValid(validatePost([temp]));
    setNewApprovalStep(temp);
    // } else {
    //   setIsNextStepValid(false);
    //   setNewApprovalStep({} as ApprovalStepDTO);
    // }
  }

  const refresh = () => {
    setSaveSuccess(true);
    setSaveSuccessMsg("Submitted successfully");
    setTimeout(() => loadInit(), 1000);
  }

  const handleCustomSnakeClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  return(
    <>
      <div style={{marginLeft: "-20px", marginTop:"-32px"}}>
        <PDivider color="neutral-contrast-low"></PDivider><br></br>
        <PFlex justifyContent={"space-between"}>
          <PFlexItem style={{ width: "30%" }} className="sub-headding"><PorscheFont>{t("Approval steps")}</PorscheFont></PFlexItem>
          <PFlexItem style={{ width: "100%" }} className="sub-headding"><PorscheFont>{t("User assigned")}</PorscheFont></PFlexItem>
          <PFlexItem style={{ width: "20%" }}></PFlexItem>
        </PFlex><br></br>
        <PDivider color="neutral-contrast-high"></PDivider> 
        {/** First Commission Check */}
        {/* { firstCommission.length === 0 && 
          approvals.length > 0 && 
          approvals[1].approvers && approvals[1].approvers.length > 0 && (
            <>
            { (
                <CommissionApprovalRow 
                  orderId={orderId}
                  approval={approvals[1]} 
                  commApproval={{} as ApprovalStepDTO} 
                  label={"1st commission check"} 
                  edit={edit} 
                  userDetails={userDetails} 
                  handleMessage={(value, msg) => handleMessage(value, msg)}
                  handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
                  enableNextStep={true}
                  handleApproved= {(step) => handleApproved(step)}
                  handleNewAssignee= {(step, value) => handleNewAssignee(step, value)}
                  refreshOnSuccess= {refresh}
                  handleClose={(reload) => handleClose(reload)}
                />
              )
            }
            <PDivider color="neutral-contrast-low"></PDivider>
            </>
          )
        } */}
        { firstCommission.length === 0 && (
          <>
            <PFlex justifyContent={"space-between"}>
              <PFlexItem style={{ width: "30%" }}>
                <PFlex>
                    <PFlexItem>
                        <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                    </PFlexItem>&nbsp;&nbsp;
                    <PFlexItem style={{ marginTop: "20px" }}>
                      <img src={require("../../../assets/images/approvals/1stordercheck.png")}></img>
                    </PFlexItem>&nbsp;&nbsp;
                    <PFlexItem style={{ marginTop: "20px" }}>
                        <PorscheFont className="tn-text-14-600">{t("1st commission check")}<span style={{ color: "#D5001B" }}>{" *"}</span></PorscheFont>
                    </PFlexItem>
                </PFlex>
              </PFlexItem>
              <PFlexItem style={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}>
                <PFlexItem>
                  <div className='user-assigned' style={{width: "40%"}}>
                    <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                  </div>                           
                </PFlexItem>
              </PFlexItem>
              <PFlexItem style={{ width: "20%" }}></PFlexItem>
            </PFlex>
          </>
        )}
        { firstCommission.length > 0 && (
          <>
            <CommissionApprovalRow 
              orderId={orderId}
              approval={approvals[1]} 
              commApproval={firstCommission[0]} 
              label={`${t("1st Commission check")}`}
              edit={edit} 
              userDetails={userDetails} 
              handleMessage={(value, msg) => handleMessage(value, msg)}
              handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
              enableNextStep={false}
              handleApproved= {(step) => handleApproved(step)}
              handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
              refreshOnSuccess= {refresh}
              handleClose={(reload) => handleClose(reload)}
            />
            <PDivider color="neutral-contrast-low"></PDivider>
          </>
        )}


        {/** Second Commission check */}
        { secondCommission.length === 0   && 
          approvals.length > 0 && 
          approvals[2].isEnabled &&
          approvals[2].approvers && approvals[2].approvers.length > 0 && (
            <>
              { nextStep === "CommissionCheck2"
                ? (
                  <CommissionApprovalRow 
                    orderId={orderId}
                    approval={approvals[2]} 
                    commApproval={secondCommission.length > 0 ? secondCommission[0] : {} as ApprovalStepDTO} 
                    label={"2nd commission check"} 
                    edit={edit} 
                    userDetails={userDetails} 
                    handleMessage={(value, msg) => handleMessage(value, msg)}
                    handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
                    enableNextStep={true}
                    handleApproved= {(step) => handleApproved(step)}
                    handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
                    refreshOnSuccess= {refresh}
                    handleClose={(reload) => handleClose(reload)}
                  />
                ) : (
                  <PFlex justifyContent={"space-between"}>
                    <PFlexItem style={{ width: "30%" }}>
                      <PFlex>
                          <PFlexItem>
                              <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                          </PFlexItem>&nbsp;&nbsp;
                          <PFlexItem style={{ marginTop: "20px" }}>
                            <img src={require("../../../assets/images/approvals/2ndordercheck.png")}></img>
                          </PFlexItem>&nbsp;&nbsp;
                          <PFlexItem style={{ marginTop: "20px" }}>
                              <PorscheFont className="tn-text-14-600">{t("2nd commission check")}</PorscheFont>
                          </PFlexItem>
                      </PFlex>
                    </PFlexItem>
                    <PFlexItem style={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}>
                      <PFlexItem>
                        <div className='user-assigned' style={{width: "40%"}}>
                          <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                        </div>                           
                      </PFlexItem>
                    </PFlexItem>
                    <PFlexItem style={{ width: "20%" }}></PFlexItem>
                  </PFlex>
                )
              }
              <PDivider color="neutral-contrast-low"></PDivider>
            </>
          )
        }
        { secondCommission.length > 0 && secondCommission[0].action === "UnAssigned" && secondCommission[0].isEnabled && (
          <>
            { nextStep === "CommissionCheck2"
              ? (
                <CommissionApprovalRow 
                  orderId={orderId}
                  approval={approvals[2]} 
                  commApproval={secondCommission.length > 0 ? secondCommission[0] : {} as ApprovalStepDTO} 
                  label={"2nd commission check"} 
                  edit={edit} 
                  userDetails={userDetails} 
                  handleMessage={(value, msg) => handleMessage(value, msg)}
                  handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
                  enableNextStep={true}
                  handleApproved= {(step) => handleApproved(step)}
                  handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
                  refreshOnSuccess= {refresh}
                  handleClose={(reload) => handleClose(reload)}
                />
              ) : (
                <CommissionApprovalRow 
                  orderId={orderId}
                  approval={approvals[2]} 
                  commApproval={secondCommission[0]} 
                  label={"2nd commission check"} 
                  edit={edit} 
                  userDetails={userDetails} 
                  handleMessage={(value, msg) => handleMessage(value, msg)}
                  handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
                  enableNextStep={false}
                  handleApproved= {(step) => handleApproved(step)}
                  handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
                  refreshOnSuccess= {refresh}
                  handleClose={(reload) => handleClose(reload)}
                />
              )
            }
            <PDivider color="neutral-contrast-low"></PDivider>
          </>
        )}

        { secondCommission.length > 0 && secondCommission[0].action !== "UnAssigned" && secondCommission[0].isEnabled && (
          <>
            <CommissionApprovalRow 
              orderId={orderId}
              approval={approvals[2]} 
              commApproval={secondCommission[0]} 
              label={"2nd commission check"} 
              edit={edit} 
              userDetails={userDetails} 
              handleMessage={(value, msg) => handleMessage(value, msg)}
              handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
              enableNextStep={false}
              handleApproved= {(step) => handleApproved(step)}
              handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
              refreshOnSuccess= {refresh}
              handleClose={(reload) => handleClose(reload)}
            />
            <PDivider color="neutral-contrast-low"></PDivider>
          </>
        )}

        {/** Fixed Commission check */}
        { fixedCommission.length === 0 && 
          //calc.commissionCalculation?.vehicleCommission &&  
          (reCalculation?.commissionCalculation?.vehicleCommission==="Fixed"||(reCalculation?.commissionCalculation?.vehicleCommission==="Standard" && (reCalculation?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MaxCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MinCommission")) ) &&
          //(calc?.commissionCalculation?.vehicleCommission==="Fixed"||(calc?.commissionCalculation?.vehicleCommission==="Standard" && (calc?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||calc?.commissionCalculation?.commissionTypeText==="MaxCommission"||calc?.commissionCalculation?.commissionTypeText==="MinCommission"))) &&
          approvals.length > 0 && 
          approvals[3].isEnabled &&
          approvals[3].approvers && approvals[3].approvers.length > 0 && (
            <>
              { nextStep === "FixedCommission" 
                ? (
                  <>
                    <CommissionApprovalRow 
                      orderId={orderId}
                      approval={approvals[3]} 
                      commApproval={{} as ApprovalStepDTO} 
                      label={"Fixed commission"} 
                      edit={edit} 
                      userDetails={userDetails} 
                      handleMessage={(value, msg) => handleMessage(value, msg)}
                      handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
                      enableNextStep={true}
                      handleApproved= {(step) => handleApproved(step)}
                      handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
                      refreshOnSuccess= {refresh}
                      handleClose={(reload) => handleClose(reload)}
                    />
                    <PDivider color="neutral-contrast-low"></PDivider>
                  </>
                ) : (
                  <>
                    <PFlex justifyContent={"space-between"}>
                      <PFlexItem style={{ width: "30%" }}>
                        <PFlex>
                            <PFlexItem>
                                <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                            </PFlexItem>&nbsp;&nbsp;
                            <PFlexItem style={{ marginTop: "20px" }}>
                              <img src={require("../../../assets/images/approvals/FinalCommission.png")}></img>
                            </PFlexItem>&nbsp;&nbsp;
                            <PFlexItem style={{ marginTop: "20px" }}>
                                <PorscheFont className="tn-text-14-600">{t("Fixed commission")}<span style={{ color: "#D5001B" }}>{" *"}</span></PorscheFont>
                            </PFlexItem>
                        </PFlex>
                      </PFlexItem>
                      <PFlexItem style={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}>
                        <PFlexItem>
                          <div className='user-assigned' style={{width: "40%"}}>
                            <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                          </div>                           
                        </PFlexItem>
                      </PFlexItem>
                      <PFlexItem style={{ width: "20%" }}></PFlexItem>
                    </PFlex>
                    <PDivider color="neutral-contrast-low"></PDivider>
                  </>
                )
              }
            </>
          )
        }

        { fixedCommission.length > 0 && fixedCommission[0].action === "UnAssigned" &&
          //calc.commissionCalculation?.vehicleCommission && 
          (reCalculation?.commissionCalculation?.vehicleCommission==="Fixed"||(reCalculation?.commissionCalculation?.vehicleCommission==="Standard" && (reCalculation?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MaxCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MinCommission")) ) &&
          //(calc?.commissionCalculation?.vehicleCommission==="Fixed"||(calc?.commissionCalculation?.vehicleCommission==="Standard" && (calc?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||calc?.commissionCalculation?.commissionTypeText==="MaxCommission"||calc?.commissionCalculation?.commissionTypeText==="MinCommission"))) && 
          (
            <>
              { nextStep === "FixedCommission" 
                ? (
                  <>
                    <CommissionApprovalRow 
                      orderId={orderId}
                      approval={approvals[3]} 
                      commApproval={fixedCommission[0]} 
                      label={"Fixed commission"} 
                      edit={edit} 
                      userDetails={userDetails} 
                      handleMessage={(value, msg) => handleMessage(value, msg)}
                      handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
                      enableNextStep={true}
                      handleApproved= {(step) => handleApproved(step)}
                      handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
                      refreshOnSuccess= {refresh}
                      handleClose={(reload) => handleClose(reload)}
                    />
                    <PDivider color="neutral-contrast-low"></PDivider>
                  </>
                ) : (
                  <CommissionApprovalRow 
                    orderId={orderId}
                    approval={approvals[3]} 
                    commApproval={fixedCommission[0]} 
                    label={"Fixed commission"} 
                    edit={edit} 
                    userDetails={userDetails} 
                    handleMessage={(value, msg) => handleMessage(value, msg)}
                    handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
                    enableNextStep={false}
                    handleApproved= {(step) => handleApproved(step)}
                    handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
                    refreshOnSuccess= {refresh}
                    handleClose={(reload) => handleClose(reload)}
                  />
                )
              }
              <PDivider color="neutral-contrast-low"></PDivider>
            </>
          )
        }

        { fixedCommission.length > 0 && fixedCommission[0].action !== "UnAssigned" &&
          //calc.commissionCalculation?.vehicleCommission && 
          //(calc?.commissionCalculation?.vehicleCommission==="Fixed"||(calc?.commissionCalculation?.vehicleCommission==="Standard" && (calc?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||calc?.commissionCalculation?.commissionTypeText==="MaxCommission"||calc?.commissionCalculation?.commissionTypeText==="MinCommission"))) && 
          (reCalculation?.commissionCalculation?.vehicleCommission==="Fixed"||(reCalculation?.commissionCalculation?.vehicleCommission==="Standard" && (reCalculation?.commissionCalculation?.commissionTypeText==="NoProfitCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MaxCommission"||reCalculation?.commissionCalculation?.commissionTypeText==="MinCommission")) ) &&
          (
          <>
            <CommissionApprovalRow 
              orderId={orderId}
              approval={approvals[3]} 
              commApproval={fixedCommission[0]} 
              label={"Fixed commission"} 
              edit={edit} 
              userDetails={userDetails} 
              handleMessage={(value, msg) => handleMessage(value, msg)}
              handleAprrovalStepAssignee={(approvalStep, config, value, newApprover) => handleAssignee(approvalStep, config, value, newApprover)}
              enableNextStep={false}
              handleApproved= {(step) => handleApproved(step)}
              handleNewAssignee= {(step, value, exists) => handleNewAssignee(step, value, exists)}
              refreshOnSuccess= {refresh}
              handleClose={(reload) => handleClose(reload)}
            />
            <PDivider color="neutral-contrast-low"></PDivider>
          </>
        )}

        {/** Also need to check for order creator  */}
        { firstCommission.length > 0 && (userDetails.id === order.orderCreator.id || 
        (userDetails.canEditAllOrders &&
          commissionApprovals.filter(x => x.approver.id === userDetails.id).length === 0
          )) && (
          <PFlex justifyContent={"flex-end"} className="mt16">  
            <PFlexItem alignSelf={"flex-end"}>
              <PButtonGroup>
                {/** Restrict edit option if commissions are approved.*/}
                { !edit && order.status !== "CommissionsApproved" && (
                  <PButton icon='edit' onClick={() => setEdit(!edit)} >{t("Edit")}</PButton>
                )}

                { edit && (
                  <>
                    <PButton variant="tertiary" icon='close' onClick={() => setEdit(!edit)}>{t("Cancel")}</PButton>
                    <PButton icon='save' onClick={() => handleSave()} disabled={isValid ? false : true} >{t("Save")}</PButton>
                  </>
                )}
              </PButtonGroup>
            </PFlexItem>
          </PFlex>
        )}

        { enableNextStep && (
          <PFlex justifyContent={"flex-end"} className="mt16">
          <PFlexItem alignSelf={"flex-end"}>
            <PButtonGroup>
              <PButton icon='save' onClick={() => handleSubmit()} disabled={isNextStepValid ? false : true}>{t("Submit")}</PButton>
            </PButtonGroup>
          </PFlexItem>
        </PFlex>
        )}

        <div className="flex30 pt20">
          <Snackbar
              open={saveSuccess}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleSuccessClose}
              >
              <Alert severity="success" sx={{ width: "100%" }}>
                  {t(saveSuccessMsg)}
              </Alert>
          </Snackbar>
          <Snackbar
              open={isError}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleErrorClose}
              >
              <Alert severity="error" sx={{ width: "100%" }}>
                  {t("Error while updating!")}
              </Alert>
          </Snackbar>
          <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleCustomSnakeClose()}/>

        </div>
      </div>
    </>
  );
}