import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  PButton,
  PButtonGroup,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PTextFieldWrapper
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { OrderContext } from "../../../contexts/OrderContext";
import { OrderRegistrationDTO } from "../../../interfaces/ActionsOrderProcessing";
import { OrderDetailsDTO } from "../../../interfaces/OrderDetails";
import { UserDetailsDTO } from "../../../interfaces/User";
import { HistoryOrderLogTypes } from "../../../interfaces/changeHistory";
import { type } from "../../../services/Constants";
import {
  dcsgetOrderSummary,
  dcspostChangeHistoryLogs,
  dcsputSubmitOrderRegistration
} from "../../../services/data/Helpers";
import CustomSnakebar from "../../common/CustomSnakebar";
import { Constant } from "../../../configs/Constants";

interface Props {
  orderId: number;
  handleCloseOrderRegister: () => void;
  //   isDraft:boolean,
  //   open: boolean,
}

export const OrderRegistration = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    orderId,
    handleCloseOrderRegister,
    //  isDraft, open
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editmode, setEditmode] = useState(false);
  const orderContext = useContext(OrderContext);
  const [isValid, setIsValid] = useState(false);
  const [dateOfOrderRegister, setDateOfOrderRegister] = useState("");
  const submitOrderDetails = orderContext.getCtxOrderDetails();
  const [orderRegistration, setOrderRegistration] =
    useState<OrderRegistrationDTO>({} as OrderRegistrationDTO);
  const [save, setSave] = useState(false);
  const [customerDel, setCustomerDel] = useState<OrderDetailsDTO>(
    {} as OrderDetailsDTO
  );
  const [reload, setReload] = useState("");
  const [users, setUsers] = useState<Array<UserDetailsDTO>>([]);
  const [validationError, setValidationError] = useState(false);
  const [dateOfOrder, setDateOfOrder] = useState("");
  const [edit, setEdit] = useState(false);
  const [modified, setModified] = useState(false);
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }
  

  useEffect(() => {
    const init = async () => {
      const apiResponse  = await dcsgetOrderSummary(orderId);
      if(apiResponse.status===type.SUCCESS){
      const customerDel = apiResponse.response
        ?.order as OrderDetailsDTO;
      //
      setCustomerDel(customerDel);
      if (customerDel?.registerDate == undefined) {
        setSave(true);
        setEdit(false);
      } else {
        orderRegistration.registerDate = customerDel.registerDate;
        setDateOfOrderRegister(
          new Date(customerDel.registerDate).toISOString().split("T")[0]
        );
      }
      if (submitOrderDetails?.vehicleDetails?.commissionNumber == undefined) {
        setSave(true);
        setEdit(false);
      } else {
        // setDateOfOrderRegister(new Date(submitOrderDetails.dateOfOrder).toISOString().split('T')[0])
        orderRegistration.commissionNumber =
          submitOrderDetails.vehicleDetails?.commissionNumber;
        setOrderRegistration(orderRegistration);
      }
      if (submitOrderDetails.vehicleDetails.actNumber == undefined) {
        setSave(true);
        setEdit(false);
      } else {
        // setDateOfOrderRegister(new Date(submitOrderDetails.dateOfOrder).toISOString().split('T')[0])
        orderRegistration.actNo = submitOrderDetails.vehicleDetails?.actNumber;
        setOrderRegistration(orderRegistration);
      }
    };
  }

    init();
  }, []);
  const handleSubmitOrderRegister = () => {
    const init = async () => {
      const apiResponse = await dcsputSubmitOrderRegistration(
        orderRegistration,
        orderId
      );
      
      if (apiResponse.status===type.SUCCESS) {
        
        //   handleClosePopup();
        // handleCloseOrderRegister();
        const logData: HistoryOrderLogTypes = {
          page: "",
          tab: "Order Registration Edited",
          userId: loggedInUserId,
          comment: "",
          orderId: orderId
        };
        await dcspostChangeHistoryLogs(logData,'order');
        setEdit(false);
        setSave(false);
        setShowAlert(Constant.Success)    
        setAlertMessage(Constant.Savedsuccessfully)
      } 
      if(apiResponse.status===type.ERROR){
        setShowAlert(Constant.Error)    
        setAlertMessage(apiResponse.response)
      }
    };
    init();
  };
  const handleInitialSubmit = () => {
    const init = async () => {
      const apiResponse = await dcsputSubmitOrderRegistration(
        orderRegistration,
        orderId
      );
      
      if (apiResponse.status===type.SUCCESS) {
        
        //   handleClosePopup();
        // handleCloseOrderRegister();
        const logData: HistoryOrderLogTypes = {
          page: "",
          tab: "Order Registration Submitted",
          userId: loggedInUserId,
          comment: "",
          orderId: orderId
        };
        await dcspostChangeHistoryLogs(logData, 'order');
        setSave(false);
        setEdit(false);
        setShowAlert(Constant.Success)    
        setAlertMessage(Constant.Savedsuccessfully)
      }
      if(apiResponse.status===type.ERROR){
        setShowAlert(Constant.Error)    
        setAlertMessage(apiResponse.response)
      }
    };
    init();
  };
  const enableSaveButton = (isValid: boolean) => {
    setIsValid(isValid);
  };
  useEffect(() => {}, [validationError]);

  const isValidOrderRequest = () => {
    
    if (
      orderRegistration.registerDate === undefined ||
      orderRegistration.registerDate.length === 0
    ) {
      setValidationError(true);
      return false;
    }

    if (orderRegistration.commissionNumber === undefined) {
      setValidationError(true);
      return false;
    }

    if (
      orderRegistration.actNo === undefined ||
      orderRegistration.actNo === 0
    ) {
      setValidationError(true);
      return false;
    }

    setValidationError(false);
    return true;
  };
  const handleEdit = () => {
    setEditmode(true);
  };

 
  const handleCancel = () => {
    //closeDetails();
    setEdit(false);
  };


  const handleActNum = (value: string) => {
    setModified(true);
    orderRegistration.actNo = value;
    setOrderRegistration(orderRegistration);
    setIsValid(isValidOrderRequest());
    // setReload(new Date().toLocaleString());
  };
  const handleDate = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length > 0) {
      setModified(true);
      setDateOfOrderRegister(e.target.value);
      orderRegistration.registerDate = e.target.value;
      setOrderRegistration(orderRegistration);
      setIsValid(isValidOrderRequest());
    } else {
      setModified(true);
      setDateOfOrderRegister("");
      orderRegistration.registerDate = "";
      setOrderRegistration(orderRegistration);
      setIsValid(isValidOrderRequest());
    }

    //setReload(new Date().toLocaleString());
  };

  const handleCommissionNum = (value: string) => {
    setModified(true);
    
    orderRegistration.commissionNumber = value;

    // Set the logged in user id
    setOrderRegistration(orderRegistration);
    setIsValid(isValidOrderRequest());
    //setReload(new Date().toLocaleString());
  };

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };


  return (
    <div>
      <br></br>
      <PorscheFont className="dcs-heading">
        {t("Submit order registration")}
      </PorscheFont>
      <br></br>
      {/* {editmode && (
                <> */}
      <PFlex direction="column" className="example-grid">
        <PFlexItem style={{ width: "280px" }}>
          <PTextFieldWrapper label={`${t("Order registration date")}`}>
            <input
              type="date"
              name="dateOfOrder"
              max="9999-12-31"
              value={dateOfOrderRegister}
              defaultValue={
                customerDel?.registerDate ? customerDel?.registerDate : ""
              }
              required
              onChange={handleDate}
              readOnly={edit || save ? false : true}
            />
          </PTextFieldWrapper>
        </PFlexItem>
        <br></br>

        <PFlexItem style={{ width: "280px" }}>
          <PTextFieldWrapper label={`${t("Commission number")}`}>
            <input
              type="text"
              name="comnum"
              defaultValue={
                submitOrderDetails.vehicleDetails?.commissionNumber
                  ? submitOrderDetails.vehicleDetails?.commissionNumber
                  : ""
              }
              required
              onChange={(e) => handleCommissionNum(e.target.value)}
              readOnly={edit || save ? false : true}
            />
          </PTextFieldWrapper>
        </PFlexItem>
        <br></br>
        <PFlexItem style={{ width: "280px" }}>
          {" "}
          <PTextFieldWrapper
            className="dcs-heading"
            label={`${t("Act number")}`}
          >
            <input
              onChange={(e) => handleActNum(e.target.value)}
              defaultValue={
                submitOrderDetails.vehicleDetails?.actNumber
                  ? submitOrderDetails.vehicleDetails?.actNumber
                  : ""
              }
              type="text"
              name="actnum"
              required
              readOnly={edit || save ? false : true}
            />
          </PTextFieldWrapper>
        </PFlexItem>
      </PFlex>
      {validationError && (
        <div className="mt10">
          <PorscheFont>
            <span style={{ color: "#D5001B" }}>
              {t("Please enter valid values.")}
            </span>
          </PorscheFont>
        </div>
      )}

      {edit && (
        <PGrid>
          <PGridItem offset={9} size={5} style={{ marginTop: "50px" }}>
            <PButtonGroup>
              <PButton
                variant="secondary"
                icon="save"
                disabled={modified ? false : true}
                onClick={handleSubmitOrderRegister}
              >
                {t("Save")}
              </PButton>
              <PButton variant="tertiary" icon="close" onClick={handleCancel}>
                {t("Cancel")}
              </PButton>
            </PButtonGroup>
            {/* <PButtonGroup>
              <PButton variant="secondary" icon="save" onClick={handleSave}>{t('Save')}</PButton>
              <PButton variant="tertiary" icon="close" onClick={handleCancel}>{t('Cancel')}</PButton>
            </PButtonGroup> */}
          </PGridItem>
        </PGrid>
      )}
      {!edit && !save && (
        <PGrid>
          <PGridItem offset={10} size={5} style={{ marginTop: "50px" }}>
            <PButtonGroup>
              <PButton
                variant="secondary"
                icon="edit"
                onClick={() => setEdit(true)}
              >
                {t("Edit")}
              </PButton>
            </PButtonGroup>
          </PGridItem>
        </PGrid>
      )}
      {save && (
        <PGrid>
          <PGridItem
            offset={9}
            size={5}
            style={{ marginTop: "50px", marginRight: "50px" }}
          >
            <PButtonGroup>
              <PButton
                variant="secondary"
                icon="save"
                disabled={!isValid}
                onClick={handleInitialSubmit}
              >
                {t("Save")}
              </PButton>
              {/* <PButton variant="secondary" onClick={handleCancel}>{t('Cancel')}</PButton> */}
            </PButtonGroup>
          </PGridItem>
        </PGrid>
      )}

<CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

    </div>
  );
};
