import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";
import {
  PButton,
  PButtonGroup,
  PButtonPure,
  PCheckboxWrapper,
  PDivider,
  PFlex,
  PFlexItem,
  PIcon,
  PModal,
  PSelectWrapper,
  PTextFieldWrapper,
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { PorscheCenterDTO } from "../../../interfaces/OrderDetails";
import { Scaling, UserDetails } from "../../../interfaces/UserDetails";
import { HistoryLogTypes } from "../../../interfaces/changeHistory";
import {
  formatCurrencyWithNoUnit,
  getScalingType
} from "../../../mock/helper";
import { type } from "../../../services/Constants";
import {
  dcsgetActiveCommissionScaling,
  dcsgetDealers,
  dcsgetOrderManagedVariablesNew,
  dcspostChangeHistoryLogs,
  dcsupdateUser,
  postChangeHistoryLogs
} from "../../../services/data/Helpers";
import { Constant } from "../../../configs/Constants";
import CustomSnakebar from "../../common/CustomSnakebar";
import { set } from "date-fns";

interface Props {
  user: UserDetails;
  refreshData: () => void;
  showMessage: (variant: string, msg: string) => void;
  loggedInOriginUsersCount: number;
}

export const ActiveUserRow = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user, refreshData, showMessage, loggedInOriginUsersCount } = props;
  const [inputValue, setInputValue] = useState<any>(user?.share);
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState("");
  const [showSendTo, setShowSendTo] = useState(false);
  const [showOrderRights, setShowOrderRights] = useState(false);
  const [showExportRights, setShowExportRights] = useState(false);
  const [scalings, setScalings] = useState<Array<Scaling>>([]);
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)
  const [jobTitle,setJobTitle] = useState(user?.jobTitle)
  const [staffNumber, setStaffNumber] = useState(user?.staffNumber)

  const resetLocalState= ()=>{
    setInputValue(user?.share)
    setJobTitle(user?.jobTitle)
    setStaffNumber(user?.staffNumber)
  }
  const [delaerPermissions, setDelaerPermissions] = useState<
    Array<PorscheCenterDTO>
  >([]);
  const [dealersFromSameCity, setDealersFromSameCity] = useState<
    Array<PorscheCenterDTO>
  >([]);
  const [activate, setActivate] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails>(
    {} as UserDetails
  );

  const logData: HistoryLogTypes = {
    page: "User Management",
    tab: "Active Users",
    userId: userDetails.id +"",
  };

  useEffect(() => {
    //reload the data for rerender
  }, [reload]);
  useEffect(() => {
    const init = async () => {
      const sessionUser = sessionStorage.getItem("userDetails");
      if (sessionUser) {
        const user = JSON.parse(sessionUser);
        setUserDetails(user); 
      }
      const apiResponse = await dcsgetDealers()
      if(apiResponse.status===type.SUCCESS){
        setDealersFromSameCity(apiResponse.response);
      }

    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetActiveCommissionScaling()
      if(apiResponse.status === type.SUCCESS){
        setScalings(apiResponse.response);
      }

    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      const variables = await dcsgetOrderManagedVariablesNew(1 , false);
      setDelaerPermissions(variables[1]);
    };
    init();
  }, []);

  const handleJobTitle = (
    e: ChangeEvent<HTMLInputElement>,
    title: string
  ): void => {
    let jobTitle = e.target.value;
    user.jobTitle = jobTitle;
    setReload(new Date().toLocaleString());
  };

  const handleStaffNumber = (
    e: ChangeEvent<HTMLInputElement>,
    staff: string
  ): void => {
    let staffNumber = e.target.value;
    user.staffNumber = staffNumber;
    setReload(new Date().toLocaleString());
  };
  const handleScaling = (scalingid: number, id: number): void => {
    if (scalings.length === 0 || Number.isNaN(scalingid)) {
      user.scaling = null;
    } else {
      user.scaling = {
        id: scalingid,
      };
    }
    setReload(new Date().toLocaleString());
  };

  const handleShare = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/^0+/, '');
    // Check if the input is a single '0' or within the range 1-100
    if (/^0$|^([1-9][0-9]?)$|^100$/.test(value) || value === '') {
      setInputValue(value);
      const numericValue = parseInt(value);
      if(!isNaN(numericValue)){
      user.share = numericValue;
      setReload(new Date().toLocaleString());
      }
    }
  };

  const handleBlur = () => {
    let newValue = inputValue;

    // Remove any non-numeric characters (except for the first "-")
    newValue = newValue.toString().replace(/[^0-9.-]+/g, '');

    // Add a percentage sign to the end if it's not already there
    if (!newValue.endsWith('%') && (newValue != 0)) {
      newValue = newValue + '%';
    }

    setInputValue(newValue);
  };

  const handleFocus = () => {
    // When the input field is focused, remove the percentage sign ("%")
    setInputValue(inputValue.toString().replace('%', ''));
  };


  const saveUser = (edit: boolean) => {
    user.jobTitle = jobTitle;
    user.staffNumber = staffNumber
    const initSave = async () => {
      if (scalings.length === 0) {
        user.scaling = null;
      }
      const apiResponse = await dcsupdateUser(user);
      if (apiResponse.status === type.SUCCESS) {
        setShowAlert(Constant.Success)    
        setAlertMessage(Constant.Savedsuccessfully)
        refreshData();
        await dcspostChangeHistoryLogs(logData, 'admin');
        setEdit(edit);
        setReload(new Date().toLocaleString());
      }
      if(apiResponse.status===type.ERROR){
        refreshData();
        resetLocalState()
        setShowAlert(Constant.Error)
        setAlertMessage(apiResponse.response)        
      }
    };
    initSave();
  };

  const handleEnableCommission = (
    enableCommission: boolean,
    id: number
  ): void => {
    user.enableCommission = enableCommission;
    setReload(new Date().toLocaleString());
  };

  const handleEnableVariables = (
    enableVariabales: boolean,
    id: number
  ): void => {
    user.enableVariabales = enableVariabales;
    setReload(new Date().toLocaleString());
  };
  const handleEnableScaling = (enableScaling: boolean, id: number): void => {
    user.enableScaling = enableScaling;
    setReload(new Date().toLocaleString());
  };

  const handleEnableUserMgmt = (enableUserMgmt: boolean, id: number): void => {
    user.enableUserMgmt = enableUserMgmt;
    setReload(new Date().toLocaleString());
  };

  const handleEnableApprovals = (
    enableApprovals: boolean,
    id: number
  ): void => {
    user.enableApprovals = enableApprovals;
    setReload(new Date().toLocaleString());
  };

  const handleCanViewAllOrders = (
    canViewAllOrders: boolean,
    id: number
  ): void => {
    user.canViewAllOrders = canViewAllOrders;
    setReload(new Date().toLocaleString());
  };
  const handleCanEditAllOrders = (
    canEditAllOrders: boolean,
    id: number
  ): void => {
    user.canEditAllOrders = canEditAllOrders;
    setReload(new Date().toLocaleString());
  };

  const handleCanViewOrderCommissions = (
    canViewOrderCommissions: boolean,
    id: number
  ): void => {
    user.canViewOrderCommissions = canViewOrderCommissions;
    setReload(new Date().toLocaleString());
  };

  const handleCanExportOrders = (
    canExportOrders: boolean,
    id: number
  ): void => {
    user.canExportOrders = canExportOrders;
    setReload(new Date().toLocaleString());
  };
  const handleCanExportCommissions = (
    canExportCommissions: boolean,
    id: number
  ): void => {
    user.canExportCommissions = canExportCommissions;
    setReload(new Date().toLocaleString());
  };

  const handleActivateClose = () => {
    setActivate(false);
  };
  const handleActivateOpen = () => {
    setActivate(true);
  };

  const isOrigin = (user: UserDetails, dealer: PorscheCenterDTO) => {
    if (user.origin.id == dealer.id) {
      return true;
    }

    if (user.origin.origin == dealer.origin) {
      return false;
    } else {
      return true;
    }
  };
  const isChecked = (user: UserDetails, dealer: PorscheCenterDTO) => {
    const exists = user.dealerPermissions.filter((de) => de.id == dealer.id);
    if (exists.length > 0) {
      return true;
    } else {
      return false;
    }
  }; 

  const handleDealerPermissions = (
    user: UserDetails,
    dealer: PorscheCenterDTO
  ) => {
    const exists = user.dealerPermissions.filter((de) => de.id == dealer.id);
    if (exists.length > 0) {
      user.dealerPermissions.map((de, i) => {
        if (de.id === dealer.id) {
          user.dealerPermissions.splice(i, 1);
        }
      });
    } else {
      user.dealerPermissions.push(dealer);
    }
    setReload(new Date().toLocaleString());
  };

  const deActivateUser = () => {
    const initDeactivate = async () => {
      user.active = false;
      const apiResponse = await dcsupdateUser(user);
      if (apiResponse.status === type.SUCCESS) {
        setShowAlert(Constant.Success)    
        setAlertMessage(Constant.Savedsuccessfully)
        setActivate(false);
        refreshData();
        setReload(new Date().toLocaleString());
        await dcspostChangeHistoryLogs(logData, 'admin');
      }
      if(apiResponse.status===type.ERROR){
        setShowAlert(Constant.Error)
        setAlertMessage(apiResponse.response)       
        }  
    };

    initDeactivate();
  };
  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
    sessionStorage.setItem("messageFlag", ("false" || false));
  };

  return (
    <>
      <PFlex>
        <PFlexItem style={{ width: "80%" }}>
          <Stack direction={"row"} spacing={1}>
            <img
              src={require("../../../assets/images/User.png")}
              alt={"User"}
              width="12"
              height="12"
              style={{ marginTop: "5px" }}
            />
            <PorscheFont className="dcs-heading">{user?.ppnName}</PorscheFont>
          </Stack>
        </PFlexItem>
        {!edit && (
          <>
            <PFlexItem style={{ width: "10%" }}>
              <PButtonPure
                icon={"edit"}
                disabled={!userDetails.enableUserMgmt}
                onClick={() => setEdit(!edit)}
              >
                <PorscheFont>{t("Edit")}</PorscheFont>
              </PButtonPure>
            </PFlexItem>
            <PFlexItem style={{ width: "10%" }}>
              {loggedInOriginUsersCount > 2 ? (
                <PButtonPure
                  icon={"subtract"}
                  disabled={!userDetails.enableUserMgmt}
                  onClick={() => handleActivateOpen()}
                >
                  <PorscheFont>{t("Deactivate")}</PorscheFont>
                </PButtonPure>
              ) : (
                <PButtonPure icon={"subtract"} disabled={true}>
                  <PorscheFont>{t("Deactivate")}</PorscheFont>
                </PButtonPure>
              )}
            </PFlexItem>
          </>
        )}
      </PFlex>
      {edit && (
        <>
          <PFlex className="mt12">
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Dealer Permissions")}
                </PorscheFont>
                {dealersFromSameCity.map((dealer, i) => {
                  if (!isOrigin(user, dealer)) {
                    return (
                      <div
                        className={`${
                          isChecked(user, dealer)
                            ? "dealer-btn-selected"
                            : "dealer-btn"
                        }`}
                        onClick={() => handleDealerPermissions(user, dealer)}
                      >
                        {dealer.centerName}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={"dealer-btn-disabled"}
                      >
                        <PorscheFont>{dealer.centerName}</PorscheFont>
                      </div>
                    );
                  }
                })}
                <PDivider
                  orientation={"vertical"}
                  color="neutral-contrast-medium"
                  style={{ height: "32px", color: "#C8CACB", margin: "0 16px" }}
                />
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Staff No.")}
                </PorscheFont>
                <div style={{ width: "80px" }}>
                  <PTextFieldWrapper id={`umiu-0`}>
                    <input
                      id={`iumiu-0`}
                      key={`kumiu-0`}
                      type="text"
                      //defaultValue={user.staffNumber}
                      value={staffNumber}
                      //disabled={edit ? false : true}
                      onChange={(e)=> setStaffNumber(e.target.value)}
                      // onChange={(e) => handleStaffNumber(e, user?.staffNumber)}
                      //style={{textAlign: "right"}}
                    />
                  </PTextFieldWrapper>
                </div>
                <PDivider
                  orientation={"vertical"}
                  color="neutral-contrast-medium"
                  style={{ height: "32px", color: "#C8CACB", margin: "0 16px" }}
                />
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Job Title")}
                </PorscheFont>
                <div>
                  <PTextFieldWrapper id={`umiu-0`}>
                    <input
                      id={`iumiu-0`}
                      key={`kumiu-0`}
                      type="text"
                      value={jobTitle}
                      onChange={(e)=>setJobTitle(e.target.value)}
                      // onChange={(e) => handleJobTitle(e, user?.jobTitle)}
                    />
                  </PTextFieldWrapper>
                </div>
                <PDivider
                  orientation={"vertical"}
                  color="neutral-contrast-medium"
                  style={{ height: "32px", color: "#C8CACB", margin: "0 16px" }}
                />
              </Stack>
            </PFlexItem>
            {/* {!commission && ( */}
            <PFlexItem>
              <Stack direction={"row"} spacing={1} style={{alignItems: "center"}}>
                <PorscheFont className="tn-text-14-400">
                  {t("Commission")}
                </PorscheFont>
                <div>
                  <PSelectWrapper>
                    <select
                      name="commissionScaling"
                      onChange={(e) =>
                        handleScaling(parseInt(e.target.value), user?.id)
                      }
                      disabled={edit ? false : true}
                    >
                      <option value="null">{t("None")}</option>
                      {scalings.map((scaling, i) => {
                        return (
                          <option
                            value={scaling.id}
                            selected={
                              scaling.id === user?.scaling?.id ? true : false
                            }
                          >
                            {`${t(
                              getScalingType(scaling.name ? scaling.name : "")
                            )}`}{" "}
                          </option>
                        );
                      })}
                    </select>
                  </PSelectWrapper>
                </div>
                {user?.scaling && user?.scaling?.id !== 0 ? 
                  <>
                    <PDivider
                      orientation={"vertical"}
                      color="neutral-contrast-medium"
                      style={{ height: "32px", color: "#C8CACB", margin: "0 16px" }}
                    />
                    <PorscheFont className="tn-text-14-400">
                      {t("Share")}
                    </PorscheFont>
                    <div>
                      <PTextFieldWrapper id={`umshare-0`}>
                        <input
                          type="text"
                          value={inputValue}
                          onChange={(e) => handleShare(e)}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                        />
                      </PTextFieldWrapper>
                    </div>
                  </> 
                :null}
              </Stack>
            </PFlexItem>
            {/* )} */}
          </PFlex>

          {/** TODO RAina */}
          <PFlex className="mt18" justifyContent={"space-between"}>
            <PFlexItem>
              <PFlex>
                <PFlexItem style={{ marginRight: "15px", position: "relative" }}>
                  <div
                    className="rights tn-title"
                    onClick={() => setShowSendTo(!showSendTo)}
                  >
                    <PFlex>
                      <PFlexItem style={{ minWidth: "175px" }}>
                        <PorscheFont> {t("Admin Rights")}</PorscheFont>
                      </PFlexItem>
                      <PFlexItem
                        style={{
                          width: "15%",
                          marginTop: "-7px",
                          marginRight: "4px",
                        }}
                      >
                        <PIcon
                          name={
                            showSendTo ? "arrow-head-up" : "arrow-head-down"
                          }
                          className="mt8"
                        />
                      </PFlexItem>
                    </PFlex>
                  </div>
                  {showSendTo && (
                    <div
                      className="dropdown-adminRights"
                      style={{ marginTop: "2px" }}
                    >
                      <ul>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("Variables")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.enableVariabales}
                              onClick={(e) =>
                                handleEnableVariables(
                                  !user?.enableVariabales,
                                  user?.id
                                )
                              }
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("Scaling")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.enableScaling}
                              onClick={(e) =>
                                handleEnableScaling(
                                  !user?.enableScaling,
                                  user?.id
                                )
                              }
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("User Mgmt.")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.enableUserMgmt}
                              onClick={(e) =>
                                handleEnableUserMgmt(
                                  !user?.enableUserMgmt,
                                  user?.id
                                )
                              }
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("Approvals")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.enableApprovals}
                              onClick={(e) =>
                                handleEnableApprovals(
                                  !user?.enableApprovals,
                                  user?.id
                                )
                              }
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* </Stack> */}
                </PFlexItem>
                <PFlexItem style={{ marginRight: "15px", position: "relative" }}>
                  <div
                    className="rights tn-title"
                    onClick={() => setShowOrderRights(!showOrderRights)}
                  >
                    <PFlex>
                      <PFlexItem style={{ minWidth: "175px" }}>
                        <PorscheFont>{t("Order Rights")}</PorscheFont>
                      </PFlexItem>
                      <PFlexItem
                        style={{
                          width: "15%",
                          marginTop: "-7px",
                          marginRight: "4px",
                        }}
                      >
                        <PIcon
                          name={
                            showOrderRights
                              ? "arrow-head-up"
                              : "arrow-head-down"
                          }
                          className="mt8"
                        />
                      </PFlexItem>
                    </PFlex>
                  </div>
                  {showOrderRights && (
                    <div
                      className="dropdown-adminRights"
                      style={{ marginTop: "2px" }}
                    >
                      <ul>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("View all")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.canViewAllOrders}
                              onClick={(e) =>
                                handleCanViewAllOrders(
                                  !user?.canViewAllOrders,
                                  user?.id
                                )
                              }
                            />
                          </PCheckboxWrapper>
                        </li>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("Edit all")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.canEditAllOrders}
                              onClick={(e) =>
                                handleCanEditAllOrders(
                                  !user?.canEditAllOrders,
                                  user?.id
                                )
                              }
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("View all commissions")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.canViewOrderCommissions}
                              onClick={(e) =>
                                handleCanViewOrderCommissions(
                                  !user?.canViewOrderCommissions,
                                  user?.id
                                )
                              }
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                      </ul>
                    </div>
                  )}
                  {/* </Stack> */}
                </PFlexItem>
                <PFlexItem style={{ marginRight: "4px", position: "relative" }}>
                  <div
                    className="rights tn-title"
                    onClick={() => setShowExportRights(!showExportRights)}
                  >
                    <PFlex>
                      <PFlexItem style={{ minWidth: "175px" }}>
                        <PorscheFont>{t("Export Rights")}</PorscheFont>
                      </PFlexItem>
                      <PFlexItem
                        style={{
                          width: "15%",
                          marginTop: "-7px",
                          marginRight: "4px",
                        }}
                      >
                        <PIcon
                          name={
                            showExportRights
                              ? "arrow-head-up"
                              : "arrow-head-down"
                          }
                          className="mt8"
                        />
                      </PFlexItem>
                    </PFlex>
                  </div>
                  {showExportRights && (
                    <div
                      className="dropdown-adminRights"
                      style={{ marginTop: "2px" }}
                    >
                      <ul>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("Orders")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.canExportOrders}
                              onClick={(e) =>
                                handleCanExportOrders(
                                  !user?.canExportOrders,
                                  user?.id
                                )
                              }
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                        <li>
                          <PCheckboxWrapper
                            label={`${t("Commissions")}`}
                            hideLabel={false}
                          >
                            <input
                              key={`idealer`}
                              type="checkbox"
                              checked={user?.canExportCommissions}
                              onClick={(e) =>
                                handleCanExportCommissions(
                                  !user?.canExportCommissions,
                                  user?.id
                                )
                              }
                              disabled={edit ? false : true}
                            />
                          </PCheckboxWrapper>
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* </Stack> */}
                </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem>
              <PButtonGroup>
                <PButton
                  variant="tertiary"
                  icon={"close"}
                  onClick={() => {
                    setEdit(!edit);
                    refreshData();
                    resetLocalState()
                  }}
                >
                  <PorscheFont> {t("Cancel")}</PorscheFont>
                </PButton>
                <PButton icon={"save"} disabled={inputValue  !=0 ? false : true} onClick={() => saveUser(!edit)}>
                  {t("Save")}
                </PButton>
              </PButtonGroup>
            </PFlexItem>
          </PFlex>
        </>
      )}

      {!edit && (
        <>
          {/* <PFlex className='mt12' justifyContent={"space-between"}> */}
          <PFlex className="mt12">
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-text-14-600">
                  {t("Dealer Permissions")}
                </PorscheFont>
                <PorscheFont className="tn-title">
                  {`${user?.origin.centerName}`}
                  {user?.dealerPermissions.length > 0 &&
                    user?.dealerPermissions.map((dealer, i) => {
                      return <>{`, ${dealer.centerName}`}</>;
                    })}
                </PorscheFont>
              </Stack>
            </PFlexItem>
            <PFlexItem>
              <PDivider
                orientation={"vertical"}
                color="neutral-contrast-medium"
                style={{
                  height: "20px",
                  color: "#C8CACB",
                  marginRight: "16px",
                  marginLeft: "16px",
                }}
              />
            </PFlexItem>

            {user?.staffNumber && (
              <>
                <PFlexItem>
                  <Stack direction={"row"} spacing={1}>
                    <PorscheFont className="tn-text-14-600">
                      {t("Staff No.")}{" "}
                    </PorscheFont>
                    <PorscheFont className="tn-title">
                      {user?.staffNumber}
                    </PorscheFont>
                  </Stack>
                </PFlexItem>
                <PFlexItem>
                  <PDivider
                    orientation={"vertical"}
                    color="neutral-contrast-medium"
                    style={{
                      height: "20px",
                      color: "#C8CACB",
                      marginRight: "16px",
                      marginLeft: "16px",
                    }}
                  />
                </PFlexItem>
              </>
            )}
            {user?.jobTitle && (
              <>
                <PFlexItem>
                  <Stack direction={"row"} spacing={1}>
                    <PorscheFont className="tn-text-14-600">
                      {t("Job Title")}{" "}
                    </PorscheFont>
                    <PorscheFont className="tn-title">
                      {user?.jobTitle}
                    </PorscheFont>
                  </Stack>
                </PFlexItem>
                <PFlexItem>
                  <PDivider
                    orientation={"vertical"}
                    color="neutral-contrast-medium"
                    style={{
                      height: "20px",
                      color: "#C8CACB",
                      marginRight: "16px",
                      marginLeft: "16px",
                    }}
                  />
                </PFlexItem>
              </>
            )}
            <PFlexItem>
              <Stack direction={"row"} spacing={1}>
                <PorscheFont className="tn-text-14-600">
                  {t("Origin")}
                </PorscheFont>
                <PorscheFont className="tn-title">
                  {user?.origin.centerName}
                </PorscheFont>
              </Stack>
            </PFlexItem>
                <PFlexItem>   
                  {user?.scaling && user?.scaling?.id!==0? <Stack direction={"row"} spacing={1}>
                  <PFlexItem>
                  <PDivider
                    orientation={"vertical"}
                    color="neutral-contrast-medium"
                    style={{
                      height: "20px",
                      color: "#C8CACB",
                      marginRight: "16px",
                      marginLeft: "16px",
                    }}
                  />
                </PFlexItem>
                    <PorscheFont className="tn-text-14-600">
                      {t("Commission")}{" "}
                    </PorscheFont>
                    <PorscheFont className="tn-title">{`${t(
                      getScalingType(
                        user?.scaling?.name ? user?.scaling?.name : ""
                      )
                    )}`}</PorscheFont>
                    <PDivider
                      orientation={"vertical"}
                      color="neutral-contrast-medium"
                      style={{ height: "20px", color: "#C8CACB" }}
                    />
                    <PorscheFont className="tn-title">
                      {formatCurrencyWithNoUnit(user?.share)}%
                    </PorscheFont>
                  </Stack>:null}
                </PFlexItem>
            {/* : {user?.scaling?.name === "null" && (<></>)
            } */}
          </PFlex>

          <PFlex className="mt12" justifyContent={"flex-start"}>
            {(user?.enableVariabales ||
              user?.enableScaling ||
              user?.enableUserMgmt ||
              user?.enableApprovals) && (
              <>
                <PFlexItem style={{ marginRight: '14px', minWidth: "400px" }}>
                  <Stack direction={"row"} spacing={1}>
                    <PorscheFont className="tn-title">
                      {t("Admin Rights")}
                    </PorscheFont>
                    {user?.enableVariabales && (
                      <div className="tn-btn">
                        <PorscheFont>{t("Variables")}</PorscheFont>
                      </div>
                    )}
                    {user?.enableScaling && (
                      <div className="tn-btn">
                        <PorscheFont>{t("Comm. Scaling")}</PorscheFont>
                      </div>
                    )}
                    {user?.enableUserMgmt && (
                      <div className="tn-btn">
                        <PorscheFont>{t("User Mgmt.")}</PorscheFont>
                      </div>
                    )}
                    {user?.enableApprovals && (
                      <div className="tn-btn">
                        <PorscheFont>{t("Approval process")}</PorscheFont>
                      </div>
                    )}
                  </Stack>
                </PFlexItem>
              </>
            )}
            {(user?.canViewAllOrders ||
              user?.canEditAllOrders ||
              user?.canViewOrderCommissions) && (
              <>
                <PFlexItem style={{ marginRight: '14px', minWidth: "400px" }}>
                  <Stack direction={"row"} spacing={1}>
                    <PorscheFont className="tn-title">
                      {t("Order Rights")}
                    </PorscheFont>
                    {user?.canViewAllOrders && (
                      <div className="tn-btn">
                        <PorscheFont>{t("View all")}</PorscheFont>
                      </div>
                    )}
                    {user?.canEditAllOrders && (
                      <div className="tn-btn">
                        <PorscheFont>{t("Edit all")}</PorscheFont>
                      </div>
                    )}
                    {user?.canViewOrderCommissions && (
                      <div className="tn-btn">
                        <PorscheFont>{t("View all commissions")}</PorscheFont>
                      </div>
                    )}
                  </Stack>
                </PFlexItem>
              </>
            )}
            {(user?.canExportCommissions || user?.canExportOrders) && (
              <>
                <PFlexItem style={{ marginRight: '14px' }}>
                  <Stack direction={"row"} spacing={1}>
                    <PorscheFont className="tn-title">
                      {t("Export Rights")}
                    </PorscheFont>
                    {user?.canExportOrders && (
                      <div className="tn-btn">
                        <PorscheFont>{t("Orders")}</PorscheFont>
                      </div>
                    )}
                    {user?.canExportCommissions && (
                      <div className="tn-btn">
                        <PorscheFont>{t("Commissions")}</PorscheFont>
                      </div>
                    )}
                  </Stack>
                </PFlexItem>
              </>
            )}
          </PFlex>
        </>
      )}
      <PDivider
        color="neutral-contrast-high"
        style={{ marginTop: "12px", marginBottom: "12px" }}
      />
      <PModal
        className="overview-modal"
        open={activate}
        onClose={handleActivateClose}
      >
        <PFlex>
          <PFlexItem style={{ marginRight: "4px", marginTop: "6px" }}>
            <PorscheFont className="dcs-section-heading">
              {t("Deactivate this user?")}
            </PorscheFont>
            <PorscheFont style={{fontSize: "16px"}}>
              {t("Deactivate intro")}
            </PorscheFont>
          </PFlexItem>
        </PFlex>
        <PFlex dir="rtl" style={{ marginTop: "14px" }}>
          <PFlexItem style={{ marginRight: "4px" }}>
            <PButtonGroup>
              <PButton 
                icon={"arrow-head-right"} 
                onClick={deActivateUser}
              >
                {t("Yes, deactivate")}
              </PButton>
              <PButton
                variant="tertiary"
                icon={"close"}
                onClick={handleActivateClose}
              >
                <PorscheFont>
                  {t("Cancel")}
                </PorscheFont>
              </PButton>
            </PButtonGroup>
          </PFlexItem>
        </PFlex>
      </PModal>

      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

    </>
  );
};
