import React, { SetStateAction, useState } from "react";
import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { useTranslation } from "react-i18next";
import {
  PButton,
  PButtonGroup,
  PSelectWrapper,
} from "@porsche-design-system/components-react";
import CustomSnakebar from "../../common/CustomSnakebar";
import { Constant } from "../../../configs/Constants";
import { dscgetExportOrder } from "../../../services/data/Helpers";
import * as ExcelJS from "exceljs";
import { type } from "../../../services/Constants";

type Props = {
  handleExportModalClose: () => void;
  orderExportAlert :React.Dispatch<SetStateAction<string>>
  orderExporAlertMessage: React.Dispatch<SetStateAction<string>>
};

export default function ExportOrder({ handleExportModalClose, orderExportAlert, orderExporAlertMessage }: Props) {
  const { t } = useTranslation();
  const catergoryList = [
    { id: 1, name: "New Car" },
    { id: 2, name: "Demo Car" },
    { id: 3, name: "Used Car" },
  ];
  const [disableExport, setDisableExport] = useState(true);
  const [showAlert, setShowAlert] = useState(Constant.None);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(e.target.value);
    if (selectedCategory.length > 0) {
      setDisableExport(false);
    } else {
      setDisableExport(true);
    }
  };

  const fileExportDate = (): string => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  };

  const handleStartExport = async () => {
    const fileName = ` ${fileExportDate()}${
      selectedCategory === "New Car"
        ? "_Export_Auftragscontrolling_Neuwagen"
        : selectedCategory === "Demo Car"
        ? "_Export_Auftragscontrolling_Vorführwagen"
        : selectedCategory === "Used Car"
        ? "_Export_Auftragscontrolling_Gebrauchtwagen"
        : ""
    }`;
    setLoading(true);
    const apiResponse = await dscgetExportOrder(
      selectedCategory.replace(/\s+/g, "")
      );
      setLoading(false);
      setAlertMessage(`Export is created, please wait`);
      setShowAlert(Constant.Success);
    if (apiResponse.status === type.SUCCESS) {
      const exportData = apiResponse.response;

      if (exportData.length > 0) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        // Adding headers in table
        const headers = Object.keys(exportData[0]);
        worksheet.addRow(headers);

        // Add data rows in table
        exportData.forEach((row: any) => {
          const values = Object.values(row);
          worksheet.addRow(values);
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const data = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.click();
        });
      }
      setTimeout(() => {
        handleExportModalClose()
        setSelectedCategory('')
        orderExportAlert(Constant.Success)
        orderExporAlertMessage('Export created successfully')
      }, 1000);
    }
    if(apiResponse.status===type.ERROR){
      setShowAlert(Constant.Error)
      setAlertMessage(apiResponse.response)
    }
  
  };

  const handleClose = () => {
    setShowAlert(Constant.None);
    setAlertMessage("");
  };
  return (
    <>
      <PorscheFont className="sub-headding" style={{ padding: "0px" }}>
        {t("Export order controlling")}
      </PorscheFont>
      <PorscheFont style={{ marginTop: "8px" }}>
        {t("Please select the vehicle category for the export")}.
      </PorscheFont>
      <br></br>

      <PSelectWrapper
        id="porscheCenterWrap"
        label={`${t("Vehicle category")}`}
        style={{ marginBottom: "190px" }}
      >
        <select
          id="porscheCenter"
          data-testid="porscheCenter"
          name="porscheCenter"
          onChange={(e) => {
            handleSelectCategory(e);
          }}
          required
        >
          <option key="default" value="">
            {t("Select Vehicle Category")}
          </option>
          {catergoryList?.map((item) => {
            return (
              <option key={item.id} value={item.name}>
                {t(item.name)}
              </option>
            );
          })}
        </select>
      </PSelectWrapper>

      <PButtonGroup>
        <PButton
          variant="secondary"
          disabled={disableExport || loading}
          onClick={handleStartExport}
          loading={loading}
        >
          {t("Start export")}
        </PButton>
        <PButton
          variant="tertiary"
          icon="close"
          onClick={handleExportModalClose}
        >
          {t("Cancel")}
        </PButton>
      </PButtonGroup>
      <CustomSnakebar
        alertType={showAlert}
        alertMessage={alertMessage}
        handleClose={() => handleClose()}
      />
    </>
  );
}
