import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';
import {
  PDivider, PFlex, PFlexItem, PGrid,
  PGridItem, PIcon,
  PPopover, PSelectWrapper,
  PText,
  PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { TradeInOptions } from '../../../configs/Enums';
import { OrderContext } from '../../../contexts/OrderContext';
import {
  AdditionalCostsPriceDTO, CalculationCustomerDTO, CalculationDraftDTO, CalculationHeaderDTO,
  CalculationPaymentDetailsDTO, CommissionCalculationDTO, CommissionSharingDTO,
  DiscountNetPriceDTO, KickbackNetPriceDTO, OnlineCarSaleInfoDTO,
  TradeInPriceDTO, VehiclePriceDTO
} from '../../../interfaces/Calculation';
import {
  CategoryCommissionDTO, CommissionScalingDTO, VehicleCommissionDTO
} from '../../../interfaces/commission';
import { MarginsDTO, ReferralCommissionDTO } from '../../../interfaces/GeneralData';
import { Model } from '../../../interfaces/Model';
import { OrderDTO } from '../../../interfaces/Order';
import { ReCalculation } from '../../../interfaces/ReCalculation';
import { UpsaleDetailsDTO, UpsaleItemDTO } from '../../../interfaces/UpsaleNew';
import { UserDetailsDTO } from '../../../interfaces/User';
import { GetRvm } from '../../../interfaces/Variables';
import {
  formatCurrencyToDecimal, formatCurrencyWithNoUnit, formatToWholeNumber, getPayementTypeName,
  getReferenceType, getTradeInHeaderLabel, getTradeInName, getUpsaleItemsTotal,
  getWorkshopServiceName,
  negativeValueHandler
} from '../../../mock/helper';
import {
  commissionScalingByUser, dcsGetGeneralTabs, dcscommissionScalingByUser, dcsfindRecalculationByUserIdAndOrderId, dcsgetCityBasedUsers, dcsgetModels, dcsgetRvm, dcsgetVat, findRecalculationByUserIdAndOrderId, getCityBasedUsers,
  getGeneralTabs, getModels,
  getRvm,
  getVat
} from '../../../services/data/Helpers';
import { type } from '../../../services/Constants';
import getSessionStorage from '../../../helpers/getSessionStorage';
import { Constant } from '../../../configs/Constants';

interface Props {
  handleModified: () => void;
  enableSaveButton: (enable: boolean) => void;
  subsequentActive?: boolean;
  persistRecalData?:CalculationDraftDTO;
  setSharingVehicleCommission?: React.Dispatch<React.SetStateAction<number | undefined>>
  setPersistSingleCommissionValue?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const RecalculationUsedCar = (props: Props): JSX.Element => {
    const { handleModified, enableSaveButton, subsequentActive,persistRecalData, setSharingVehicleCommission, setPersistSingleCommissionValue } = props;
    const orderContext = useContext(OrderContext); 
    const orderValues = getSessionStorage(Constant.SingleOrdDetail);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
    const [calDraft, setCalDraft] = useState<CalculationDraftDTO>({} as CalculationDraftDTO)
    const [vehiclePrices, setVehiclePrices] = useState<Array<VehiclePriceDTO>>([]);
    const [discountNetPrices, setDiscountNetPrices] = useState<Array<DiscountNetPriceDTO>>([]);
    const [kickbackNetPrices, setKickbackNetPrices] = useState<Array<KickbackNetPriceDTO>>([]);
    const [additionalCosts, setAdditionalCosts] = useState<Array<AdditionalCostsPriceDTO>>([]);
    const [workshopCosts, setWorkshopCosts] = useState<UpsaleDetailsDTO>({} as UpsaleDetailsDTO);
    const [tradeInFields, setTradeInFields] = useState<Array<TradeInPriceDTO>>([]);
    const [calPaymentDetails, setCalPaymentDetails] = useState<CalculationPaymentDetailsDTO>({} as CalculationPaymentDetailsDTO);
    const [category, setCategory] = useState("");
    const [vat, setVat] = useState(0.00);
    const [reload, setReload] = useState("");
    const [margins, setMargins] = useState<Array<MarginsDTO>>([]);
    const [referals, setReferals] = useState<Array<ReferralCommissionDTO>>([]);
    const [models, setModels] = useState<Array<Model>>([]);
    const [rvmData, setRvmData] = useState<Array<GetRvm>>([]);
    const [rvm, setRvm] = useState<GetRvm>({} as GetRvm);
    const [openExit, setOpenExit] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [mode, setMode] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [scalingobj, setScalingobj] = useState<CommissionScalingDTO>({} as CommissionScalingDTO);
    const [commissionObj, setCommissionObj] = useState<CategoryCommissionDTO>({} as CategoryCommissionDTO);
    const [parts, setParts] = useState<Array<UpsaleItemDTO>>([]);
    const [tires, setTires] = useState<Array<UpsaleItemDTO>>([]);
    const [discount, setDiscount] = useState(0.00);
    const [showErrorCarInternal, setShowErrorCarInternal] = useState(false);
    const [showErrorCarCustomer, setShowErrorCarCustomer] = useState(false);
    const [showCs, setShowCs] = useState(false);
    const [csError, setCsError] = useState(false);
    const [sellers, setSellers] = useState<Array<UserDetailsDTO>>([]);
    const [orderCreator, setOrderCreator] = useState("");
    const [reCalculation , setReCalculation] = useState<ReCalculation>({} as ReCalculation)
    const [userCommissionShares,setUserCommissionShares]= useState<Array<CommissionSharingDTO>>([]);
    const [calculationCustomer,setCalculationCustomer]= useState<CalculationCustomerDTO>({} as CalculationCustomerDTO);
    const [finalCalculation,setFinalCalculation]= useState<CalculationHeaderDTO>({} as CalculationHeaderDTO);
    const [calculationHeader,setCalculationHeader]= useState<CalculationHeaderDTO>({} as CalculationHeaderDTO);
    const [precalculation, setPrecalculation] = useState<CalculationDraftDTO>({} as CalculationDraftDTO)
    const [commissionCalculation, setCommissionCalculation] = useState<CommissionCalculationDTO>({} as CommissionCalculationDTO)
    const [onlineCarSaleInfo, setOnlineCarSaleInfo] = useState<OnlineCarSaleInfoDTO>({} as OnlineCarSaleInfoDTO)
    const [sharePcPercent, setSharePcPercent] = useState(0.00);
    const [commissionType, setCommissionType] = useState("");
    const [purchasers, setPurchasers] = useState<Array<UserDetailsDTO>>([]);
    const [final, setFinal] = useState({
      invoiceNet: 0.00,
      invoiceGross: 0.00,
      preNet: 0.00,
      preGross: 0.00,
      paymentNet: 0.00,
      paymentGross: 0.00,
    })
    /**
    * recalculation variables
    */
    const [gross, setGross] = useState("");
   const [recalvehiclePrices, setRecalVehiclePrices] = useState<Array<VehiclePriceDTO>>([]);
   const [recalDiscountNetPrices, setRecalDiscountNetPrices] = useState<Array<DiscountNetPriceDTO>>([]);
   const [recalUserCommissionShares,setRecalUserCommissionShares]= useState<Array<CommissionSharingDTO>>([]);
   const [recalKickbackNetPrices, setRecalKickbackNetPrices] = useState<Array<KickbackNetPriceDTO>>([]);
   const [recalAdditionalCosts, setRecalAdditionalCosts] = useState<Array<AdditionalCostsPriceDTO>>([]);
   const [recalWorkshopCosts, setRecalWorkshopCosts] = useState<UpsaleDetailsDTO>({} as UpsaleDetailsDTO);
   const [recalTradeInFields, setRecalTradeInFields] = useState<Array<TradeInPriceDTO>>([]);
   const [recalCalculationCustomer,setRecalCalculationCustomer]= useState<CalculationCustomerDTO>({} as CalculationCustomerDTO);
   const [recalFinalCalculation,setRecalFinalCalculation]= useState<CalculationHeaderDTO>({} as CalculationHeaderDTO);
   const [recalCalculationPayment,setRecalCalculationPayment]= useState<CalculationPaymentDetailsDTO>({} as CalculationPaymentDetailsDTO);
   const [recalCalculationHeader,setRecalCalculationHeader]= useState<CalculationHeaderDTO>({} as CalculationHeaderDTO);
   const [recalculation, setRecalculation] = useState<CalculationDraftDTO>({} as CalculationDraftDTO)
   const [recalCommissionCalculation, setRecalCommissionCalculation] = useState<CommissionCalculationDTO>({} as CommissionCalculationDTO)
   const [recalOnlineCarSaleInfo, setRecalOnlineCarSaleInfo] = useState<OnlineCarSaleInfoDTO>({} as OnlineCarSaleInfoDTO)

    const [grossPercent, setGrosspercent] = useState(0.00);
    const [addError, setAddError] = useState<Array<String>>([]);
    const [workShopError, setWorkShopError]=useState(false);
    const [canViewCommission, setCanViewCommission] = useState(false);
    const [recalData, setrecalData]=useState<ReCalculation>({} as ReCalculation)
    const inputFieldMaxLength:number = 15

    const vp = [
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) },
    ];
    
    const dref = [
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) }
    ];
    const kbRef = [
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) }
    ];
    const acRef = [
      { net: useRef<HTMLInputElement>(null), share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) }
    ];
    const cwtRef = [
      { share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null),} 
    ];
    const cwtServiceRef = [
      { share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null)},
      { share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
    ];
    const pdsRef = [
      { share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null),} 
    ];
    const pdsServiceRef = [
      { share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null)},
      { share: useRef<HTMLInputElement>(null), euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
    ];
  
    const tRef = [
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) },
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) }
    ];
    const dpRef = [
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) },
    ];
    const upRef = [
      { net: useRef<HTMLInputElement>(null), gross: useRef<HTMLInputElement>(null) },
    ];
    const csRef = [
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
      { euro: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) }
    ];
    const pfsRef = useRef<HTMLInputElement>(null);
    const cpRef = useRef<HTMLInputElement>(null);
    const pcsRef = useRef<HTMLInputElement>(null);
    const iRef = useRef<HTMLInputElement>(null);
    const vcRef = useRef<HTMLInputElement>(null);
  
    const extRef = [
      { net: useRef<HTMLInputElement>(null), percent: useRef<HTMLInputElement>(null) },
    ];
  
    const standingRef = useRef<HTMLInputElement>(null);   
    const repairRef = useRef<HTMLInputElement>(null); 
    const lgbRef = useRef<HTMLInputElement>(null); 

    let userDetails = {} as UserDetailsDTO;
    const sessionUser = sessionStorage.getItem("userDetails");
    if (sessionUser) {
      userDetails = JSON.parse(sessionUser);
    }

    const getPartTotals = () => {
      let cdd = orderContext.getCtxReCalculation()?.preCalculation;
      let usDetails = cdd?.workshopCosts ? cdd.workshopCosts : {} as UpsaleDetailsDTO;
      const partsTotals = {
        amountNet: 0.00,
        customerShare: 0.00,
        discountEuro: 0.00,
        discountPercent: 0.00,
        serviceNet: 0.00,
        serviceShare: 0.00,
        serviceEuro: 0.00,
        servicePercent: 0.00,
      };
      let partsData = usDetails?.upsaleItems?.filter(x => x.partType === "part");
      partsData?.map((item, i) => {
        //
        partsTotals.customerShare = item.customerShare ? partsTotals.customerShare + item.customerShare : partsTotals.customerShare + 0.00;
  
        if (item.customerShare > 0) {
          partsTotals.amountNet = item.part.priceBeforeCustomerNet ? partsTotals.amountNet + item.part.priceBeforeCustomerNet : partsTotals.amountNet + 0.00;
        } else {
          partsTotals.amountNet = item.part.priceInternal ? partsTotals.amountNet + item.part.priceInternal : partsTotals.amountNet + 0.00;
        }
  
        if (item.serviceItems && item.serviceItems.length > 0) {
          partsTotals.serviceShare = + item.serviceItems[0].customerShare;
          if (item.serviceItems[0].customerShare > 0 && item.serviceItems[0].timeUnits) {
            partsTotals.serviceNet = + item.serviceItems[0].services.priceBeforeCustomerNet * item.serviceItems[0].timeUnits / 100;
          } else if (item.serviceItems[0].timeUnits) {
            partsTotals.serviceNet = + item.serviceItems[0].services.priceInternal * item.serviceItems[0].timeUnits / 100;
          }
        }
      })
  
      if (partsData && partsData.length > 0) {
        if (partsTotals.amountNet > 0) {
          partsTotals.discountEuro = partsTotals.amountNet - partsTotals.customerShare;
          
          switch (cdd?.calculationType) {
            case "NewCar":
              
              if (cdd.vehiclePrices && cdd.vehiclePrices[2].amountNet > 0) {
                partsTotals.discountPercent = partsTotals.discountEuro / cdd.vehiclePrices[2].amountNet * 100;
              } else {
                partsTotals.discountPercent = 0.00
              }
              break;
            case "DemoCar":
              if (cdd.vehiclePrices && cdd.vehiclePrices[0].amountNet > 0) {
                partsTotals.discountPercent = partsTotals.discountEuro / cdd.vehiclePrices[0].amountNet * 100;
              } else {
                partsTotals.discountPercent = 0.00
              }
              break;
  
            case "UsedCar":
              if (cdd.vehiclePrices && cdd.vehiclePrices[0].amountNet > 0) {
                partsTotals.discountPercent = partsTotals.discountEuro / cdd.vehiclePrices[0].amountNet * 100;
              } else {
                partsTotals.discountPercent = 0.00
              }
              break;
          }
        }
  
        if (partsTotals.serviceNet > 0) {
          partsTotals.serviceEuro = partsTotals.serviceNet - partsTotals.serviceShare;
          
          switch (category) {
            case "NewCar":
              
              if (cdd.vehiclePrices && cdd.vehiclePrices[2].amountNet > 0) {
                partsTotals.servicePercent = partsTotals.serviceEuro / cdd.vehiclePrices[2].amountNet * 100;
              } else {
                partsTotals.servicePercent = 0.00
              }
              break;
            case "DemoCar":
              if (cdd.vehiclePrices && cdd.vehiclePrices[0].amountNet > 0) {
                partsTotals.servicePercent = partsTotals.serviceEuro / cdd.vehiclePrices[0].amountNet * 100;
              } else {
                partsTotals.servicePercent = 0.00
              }
              break;
  
            case "UsedCar":
              if (cdd.vehiclePrices && cdd.vehiclePrices[0].amountNet > 0) {
                partsTotals.servicePercent = partsTotals.serviceEuro / cdd.vehiclePrices[0].amountNet * 100;
              } else {
                partsTotals.servicePercent = 0.00
              }
              break;
          }
        }
      }
  
      return partsTotals;
    }

    useEffect(() => {
      const id = orderValues?.id ? orderValues?.id+'' :''
      const mode =orderValues?.mode ? orderValues?.mode+'' :''
      setMode(mode)
      if (id && (mode === "view" || mode === "edit")) {
        if (mode === "view") {
          setDisabled(true)
        } else {
          setDisabled(false)
        }
      }
    }, [])
    useEffect(() => {
      const init = async () => {
        const vatResp = await dcsgetVat();
        if(vatResp.status===type.SUCCESS){
          setVat(vatResp.response?.value);
        }
        
        
  
        let ord = orderContext.getCtxOrderDetails()
        setOrder(ord);
        let commResp = await dcscommissionScalingByUser(orderContext.getCtxOrderDetails().orderCreator.id);
        
        if (commResp.status===type.SUCCESS) {
          setScalingobj(commResp.response);
        }
        const rvmResp = await dcsgetRvm();
        if(rvmResp.status===type.SUCCESS){
          setRvmData(rvmResp.response);
          if (ord?.paymentDetails?.paymentType === "Leasing_PFS" && ord?.vehicleDetails?.series?.id) {
            // Calulate based on RVM
            const rvm = rvmData?.filter(x => x.seriesDTO.id === ord.vehicleDetails.series.id);
            setRvm(rvm[0]);
          }
        }
       
        let recalResp = await dcsfindRecalculationByUserIdAndOrderId(orderValues?.id ? orderValues?.id+'' :'');
        
        
        if (recalResp.status===type.SUCCESS) {
          
          const recalData = recalResp.response as ReCalculation;
          const recalDeepCopy = JSON.parse(JSON.stringify(recalResp.response)) as ReCalculation;
          orderContext.updateCtxReCalculation(recalData);
          setReCalculation(recalData);
          setrecalData(recalDeepCopy)
          
  
          // let usDetails = orderContext.getCtxUpsaleDetails();
          // 
          const parts = recalData?.reCalculation?.workshopCosts?.upsaleItems?.filter(x => x.partType === "part");
          if(parts && parts.length > 0) {
            setParts(parts);
          }
          
          const tires = recalData?.reCalculation?.workshopCosts?.upsaleItems?.filter(x => x.partType === "tire");
          if(tires && tires.length > 0) {
            setTires(tires);
          }
  
          const genResp = await dcsGetGeneralTabs();
          if(genResp.status===type.SUCCESS){
            const general = genResp.response;
          if (general?.margins) {
            setMargins(general.margins);
          }
  
          }
          
          const modelResp = await dcsgetModels("");
          if(modelResp.status===type.SUCCESS){
            if (modelResp.response.length > 0) {
              setModels(modelResp.response);
            }
          }
          
          const cityResp = await dcsgetCityBasedUsers();
          if (cityResp.status===type.SUCCESS) {
                cityResp.response.length>0 && setPurchasers(cityResp.response);
          }

          const sessionUser = sessionStorage.getItem("userDetails");
          if (sessionUser) {
            const user = JSON.parse(sessionUser);
            let viewCommission = user.canViewOrderCommissions ? user.canViewOrderCommissions : false;
            if(ord?.orderCreator?.id === user.id) viewCommission = true;
            setCanViewCommission(viewCommission);
          }

          setReload(new Date().toLocaleString());
        }
        // if(subsequentActive){
          // debugger
         
            // 
            // 
            // 
            // persistRecalData && setPersistSingleCommissionValue! && setPersistSingleCommissionValue(calculateTotalCommission( persistRecalData!))
          
        // }

        
      };
      init();
  
    }, []);

    useEffect(()=>{
      if(subsequentActive)
      persistRecalData && setPersistSingleCommissionValue! && setPersistSingleCommissionValue(calculateTotalCommission( persistRecalData!))

    },[scalingobj, persistRecalData, ])

    const orderId = orderContext.getCtxOrderDetails().id
  useEffect(() => {
    const isFirstVisit = sessionStorage.getItem('isFirstVisit');
    if (isFirstVisit!==`${orderId && orderId}` && subsequentActive) {
      sessionStorage.setItem('isFirstVisit', `${orderId && orderId}`);
      setTimeout(() => {
        window.location.reload();
      }, 10);
    }
  }, []);

    useEffect(() => {
      let ord = orderContext.getCtxOrderDetails();
      //setOrder(ord);
      if (ord?.vehicleDetails?.category?.type) {
        setCategory(ord?.vehicleDetails?.category?.type);
        let cdd = orderContext.getCtxReCalculation()?.preCalculation;
  
        let recal = orderContext.getCtxReCalculation()?.reCalculation;
        setReCalculation(orderContext.getCtxReCalculation());
        setVehiclePrices(cdd?.vehiclePrices!);
        setDiscountNetPrices(cdd?.discountNetPrices!);
        setKickbackNetPrices(cdd?.kickbackNetPrices!);
        setAdditionalCosts(cdd?.additionalCostsPrices!);
        setWorkshopCosts(cdd?.workshopCosts!);
        setCalPaymentDetails(cdd?.calculationPaymentDetails!);
        setTradeInFields(cdd?.tradeInPrices!)
  
        if(cdd?.isCommissionSharingActive){
         setUserCommissionShares(cdd?.commissionSharing!);
         setRecalUserCommissionShares(recal?.commissionSharing!);
         setSharingVehicleCommission! && setSharingVehicleCommission( recalCommissionCalculation?.vehicleCommissionEuro)
        }
        setCalculationCustomer(cdd?.calculationCustomer!)
        setFinalCalculation(cdd?.calculationHeader!)
        setCalculationHeader(cdd?.calculationHeader!)
        setPrecalculation(cdd);
        setCommissionCalculation(cdd?.commissionCalculation!)
        setOnlineCarSaleInfo(cdd?.onlineCarSaleInfo!)
        
        //setCalculationHeader(cdd?.calculationHeader!)
        setRecalVehiclePrices(recal?.vehiclePrices!);
        setRecalDiscountNetPrices(recal?.discountNetPrices!);
        setRecalKickbackNetPrices(recal?.kickbackNetPrices!);
        setRecalAdditionalCosts(recal?.additionalCostsPrices!);
        setRecalWorkshopCosts(recal?.workshopCosts!);
        setRecalTradeInFields(recal?.tradeInPrices!);
        setRecalCalculationCustomer(recal?.calculationCustomer!);
        setRecalCalculationPayment(recal?.calculationPaymentDetails!);
        setRecalFinalCalculation(recal?.calculationHeader!)
        setRecalCalculationHeader(recal?.calculationHeader!)
        setRecalCommissionCalculation(recal?.commissionCalculation!)
        setRecalOnlineCarSaleInfo(recal?.onlineCarSaleInfo!)
      //  setShowCs(cdd.isCommissionSharingActive);
        //Calculate Header
        if(recal?.calculationType === "UsedCar") {
          calculateHeader(recal);
        }
       
        getSharePcPercent();
      }

      getGrossProfitPercent();
    }, [reload, scalingobj, rvm, models, margins]);

    useEffect(() => {
      if(standingRef && standingRef.current) {
        standingRef.current.value = recalOnlineCarSaleInfo?.standingDays ? recalOnlineCarSaleInfo.standingDays.toString() : "0";
      }
  
      if(repairRef && repairRef.current) {
        repairRef.current.value = formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.repairCosts ? recalOnlineCarSaleInfo.repairCosts : 0.00);
      }
 
      if(lgbRef && lgbRef.current) {
        lgbRef.current.value = formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.lastGrossBiddingPrice ? recalOnlineCarSaleInfo.lastGrossBiddingPrice : 0.00);
      }
    }, [recalOnlineCarSaleInfo])
  
    useEffect(() => {
      if(dpRef[0].net.current) {
        dpRef[0].net.current.value = formatCurrencyWithNoUnit(recalCalculationPayment?.paymentNet ? recalCalculationPayment.paymentNet : 0.00 );
      }
  
      if(dpRef[0].gross.current) dpRef[0].gross.current.value = formatCurrencyWithNoUnit(recalCalculationPayment?.paymentGross ? recalCalculationPayment?.paymentGross : 0.00);
  
      if(upRef[0].net.current) upRef[0].net.current.value = formatCurrencyWithNoUnit(recalCalculationPayment?.upFrontPaymentNet ? recalCalculationPayment.upFrontPaymentNet : 0.00 );
  
      if(upRef[0].gross.current) upRef[0].gross.current.value  = formatCurrencyWithNoUnit(recalCalculationPayment?.upFrontPaymentGross ? recalCalculationPayment?.upFrontPaymentGross : 0.00);
    }, [recalCalculationPayment])

    useEffect(() => {
      if(iRef.current) {
        iRef.current.value = formatCurrencyWithNoUnit(recalCommissionCalculation.incentive ? recalCommissionCalculation.incentive : 0.00 );
      }
  
    }, [recalCommissionCalculation])

    const handleVehicleTaxation = (value: string) => {
      if(recalvehiclePrices) {
        recalvehiclePrices[0].taxation = value;
        recalvehiclePrices[1].taxation = value;
  
        if(value === "Regular"){
          recalvehiclePrices[0].amountNet = recalvehiclePrices[0].amountGross / (1 + vat/100);
          if (vp[0].net.current) {
            vp[0].net.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet);
          }
        }
  
        if(value === "Differential"){
          recalvehiclePrices[0].amountNet = recalvehiclePrices[0].amountGross - ((recalvehiclePrices[0].amountGross - recalvehiclePrices[1].amountNet) / (1 + vat/100));
          if (vp[0].net.current) {
            vp[0].net.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet);
          }
         }
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        recal.vehiclePrices=recalvehiclePrices;
        wholeObject.reCalculation=recal;
        setRecalVehiclePrices(recalvehiclePrices);
        orderContext.updateCtxReCalculation(wholeObject);
        handleModified();
        calculateHeader(recal);
        setReload(new Date().toLocaleString());
      }
    }
    const handleAmountGross = (value: string, index: number, elementId: string) => {
      // if(validateCurrencyFormat(value)) {
        recalvehiclePrices[index].amountGross = formatCurrencyToDecimal(value);
  
        if(recalvehiclePrices[index].taxation === "Regular") {
          recalvehiclePrices[index].amountNet = recalvehiclePrices[index].amountGross / (1 + vat/100);
        }
  
        if(recalvehiclePrices[index].taxation === "Differential") {
          recalvehiclePrices[index].amountNet = recalvehiclePrices[0].amountGross -(( recalvehiclePrices[index].amountGross -  recalvehiclePrices[1].amountNet)/ (1 + vat/100));
        }
        switch (index) {
          case 0:
            if (vp[0].net.current) {
              vp[0].net.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet);
            }
            break;
          case 1:
            if (vp[1].net.current) {
              vp[1].net.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[1].amountNet);
            }
            break;
        }
         // updateDiscountForRRPValue();
        //   updateKickBackBasedOnRRP();
       // updateAdditionalCostsBasedRRP();
        
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        recal.vehiclePrices=recalvehiclePrices;
        wholeObject.reCalculation=recal;
        setRecalVehiclePrices(recalvehiclePrices);
        orderContext.updateCtxReCalculation(wholeObject);
        calculateHeader(recal);
        handleModified()
        setReload(new Date().toLocaleString());
    }

    const updateDiscountForRRPValue=()=>{
      const rrp = recalvehiclePrices[0].amountNet ;
        if (rrp > 0 && dref) {
          
          for (let i = 0; i < recalDiscountNetPrices.length; i++) {
            let euro = (recalDiscountNetPrices[i].discountInPercent * rrp) / 100;
            recalDiscountNetPrices[i].discountInEuro = euro;
    
          }
    
          if (dref[0] && dref[0].euro && dref[0].euro.current) {
    
            dref[0].euro.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices[0].discountInEuro);
          }
          if (dref[1] && dref[1].euro && dref[1].euro.current) {
    
            dref[1].euro.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices[1].discountInEuro);
          }
         
          let wholeObject=orderContext.getCtxReCalculation();
          let recal=wholeObject.reCalculation;
          recal.discountNetPrices=recalDiscountNetPrices;
          wholeObject.reCalculation=recal;
          setRecalDiscountNetPrices(recalDiscountNetPrices);
          orderContext.updateCtxReCalculation(wholeObject);
          handleModified()
          calculateHeader(calDraft);
          setReload(new Date().toLocaleString());
        }
    }
   
    const updateAdditionalCostsBasedRRP = () => {
      const rrp = recalvehiclePrices[0].amountNet ;
      if (rrp > 0 && acRef) {
        
        for (let i = 0; i < recalAdditionalCosts.length; i++) {
          let percent = recalAdditionalCosts[i].discountInEuro / rrp * 100 ;
          recalAdditionalCosts[i].discountInPercent = percent;
        }
        
        if (acRef[0] && acRef[0].percent && acRef[0].percent.current) {
          acRef[0].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[0].discountInPercent);
        }
        if (acRef[1] && acRef[1].percent && acRef[1].percent.current) {
          acRef[1].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[1].discountInPercent);
        }
        if (acRef[2] && acRef[2].percent && acRef[2].percent.current) {
          acRef[2].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[2].discountInPercent);
        }
        if (acRef[3] && acRef[3].percent && acRef[3].percent.current) {
          acRef[3].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[3].discountInPercent);
        }
        if (acRef[4] && acRef[4].percent && acRef[4].percent.current) {
          acRef[4].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[4].discountInPercent);
        };
  
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        recal.additionalCostsPrices=recalAdditionalCosts;
        wholeObject.reCalculation=recal;
        setRecalAdditionalCosts(recalAdditionalCosts)
        orderContext.updateCtxReCalculation(wholeObject);
        handleModified()
        calculateHeader(recal);
        setReload(new Date().toLocaleString());
     
      }
    }
    const handleAmountNet = (value: string, index: number, elementId: string) => {
        recalvehiclePrices[index].amountNet = formatCurrencyToDecimal(value);
  
        if(recalvehiclePrices[index].taxation === "Differential") {
          recalvehiclePrices[0].amountNet = (recalvehiclePrices[0].amountGross - recalvehiclePrices[1].amountNet)/ (1 + vat/100);
          if (vp[0].net.current) {
            vp[0].net.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet);
          }
        }

        let wholeObject = orderContext.getCtxReCalculation();
        let recal = wholeObject.reCalculation;
        recal.vehiclePrices=recalvehiclePrices;
        wholeObject.reCalculation = recal;
        setRecalVehiclePrices(recalvehiclePrices);
        orderContext.updateCtxReCalculation(wholeObject);
        handleModified()
        calculateHeader(recal);
        setReload(new Date().toLocaleString());
      
    }

    const handleStandingDays = (value: string, elementId: string) => {
      if(recalOnlineCarSaleInfo){
        recalOnlineCarSaleInfo.standingDays = parseInt(value);
        
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        recal.onlineCarSaleInfo=recalOnlineCarSaleInfo;
        wholeObject.reCalculation=recal;
        setRecalOnlineCarSaleInfo(recalOnlineCarSaleInfo);
        orderContext.updateCtxReCalculation(wholeObject);
        handleModified()
        calculateHeader(recal);
        setReload(new Date().toLocaleString());
      }
   
    }
  
  const handleOnlineRepair = (value: string) : void => {
    // if(validateCurrencyFormat(value)) {
    if (recalOnlineCarSaleInfo) {
      recalOnlineCarSaleInfo.repairCosts = formatCurrencyToDecimal(value)
      
      let wholeObject=orderContext.getCtxReCalculation();
      let recal=wholeObject.reCalculation;
      recal.onlineCarSaleInfo=recalOnlineCarSaleInfo;
      wholeObject.reCalculation=recal;
      setRecalOnlineCarSaleInfo(recalOnlineCarSaleInfo);
      orderContext.updateCtxReCalculation(wholeObject);
      handleModified()
      calculateHeader(recal);
      setReload(new Date().toLocaleString());
    }
  }
  
  const handleBiddingPrice = (value: string, elementId: string) => {
  
      if(recalOnlineCarSaleInfo){
        recalOnlineCarSaleInfo.lastGrossBiddingPrice = formatCurrencyToDecimal(value);
      
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        recal.onlineCarSaleInfo=recalOnlineCarSaleInfo;
        wholeObject.reCalculation=recal;
        setRecalOnlineCarSaleInfo(recalOnlineCarSaleInfo);
        orderContext.updateCtxReCalculation(wholeObject);
        handleModified()
        setReload(new Date().toLocaleString());
      }
    
  }

  const handleOnlineComment = (value: string) => {
    if(recalOnlineCarSaleInfo) {
      recalOnlineCarSaleInfo.comments = value;

      let wholeObject=orderContext.getCtxReCalculation();
      let recal=wholeObject.reCalculation;
      recal.onlineCarSaleInfo=recalOnlineCarSaleInfo;
      wholeObject.reCalculation=recal;
      setRecalOnlineCarSaleInfo(recalOnlineCarSaleInfo);
      orderContext.updateCtxReCalculation(wholeObject);
      handleModified()
      setReload(new Date().toLocaleString());
    }
  };
  const handlePurchaser = (e: string): void => {
    let orderDto = orderContext.getCtxOrderDetails();
    const vehicleDetails = orderDto.vehicleDetails || {}
    const value = parseInt(e);

   
    if (value > 0 && recalOnlineCarSaleInfo) {
      vehicleDetails.purchaser = { id: value };
      recalOnlineCarSaleInfo.buyer = { id: value };
    } else {
      if(recalOnlineCarSaleInfo) recalOnlineCarSaleInfo.buyer = null;
    }

    let wholeObject=orderContext.getCtxReCalculation();
    let recal=wholeObject.reCalculation;
    recal.onlineCarSaleInfo=recalOnlineCarSaleInfo;
    wholeObject.reCalculation=recal;
    setRecalOnlineCarSaleInfo(recalOnlineCarSaleInfo);
    orderContext.updateCtxReCalculation(wholeObject);
    handleModified()
    // calculateHeader(recal);
    setReload(new Date().toLocaleString());
  }

  // Discount Price handlers
  const labels = {
    usedCar: ["Referral commission", "Dealer contribution"],
  };
  const handleDiscountEuro = (value: string, i: number, elementId: string) => {
    // if(validateCurrencyFormat(value)) {
    recalDiscountNetPrices[i].discountInEuro = formatCurrencyToDecimal(value);
    let disPercent = 0.00;
    
    const rrp = (recalvehiclePrices[0].amountNet);
    if (rrp > 0) {
      
      disPercent = (recalDiscountNetPrices[i].discountInEuro / rrp) * 100;
      recalDiscountNetPrices[i].discountInPercent = disPercent;
    }
    // Setting discount percent value using useRef
    switch (i) {
      case 0:
        if (dref[0] && dref[0].percent && dref[0].percent.current) {
          dref[0].percent.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices[i].discountInPercent);
        }
        break;
      case 1:
        if (dref[1] && dref[1].percent && dref[1].percent.current) {
          dref[1].percent.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices[i].discountInPercent);
        }
        break;
    }

    let wholeObject=orderContext.getCtxReCalculation();
    let recal=wholeObject.reCalculation;
    recal.discountNetPrices=recalDiscountNetPrices;
    wholeObject.reCalculation=recal;
    orderContext.updateCtxReCalculation(wholeObject);
    setReCalculation(wholeObject);
    setRecalDiscountNetPrices(recalDiscountNetPrices);
    //Calculate Header
    calculateHeader(recal);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleDiscountPercentage = (value: string, i: number, elementId: string) => {
    const dom = document.getElementById(elementId);
    recalDiscountNetPrices[i].discountInPercent = formatCurrencyToDecimal(value);
    const refer = referals.filter(x => x.referralType === "UsedCar");
    if (refer.length > 0 && order.referral && i === 0) {
      if (refer[0].value < recalDiscountNetPrices[i].discountInPercent) {
        if (dom) {
          dom.setAttribute("state", "error");
          dom.setAttribute("message", "Referral commission is too high.");
        }
      } else {
        if (dom) {
          dom.setAttribute("state", "none");
          dom.setAttribute("message", "");
        }
      }
    }
    let disEuro = 0.00;
    
    const rrp = (recalvehiclePrices[0].amountNet);
    if (rrp > 0) {
      
      disEuro = rrp * formatCurrencyToDecimal(value) / 100;
      recalDiscountNetPrices[i].discountInEuro = disEuro;
    }
    switch (i) {
      case 0:
        if (dref[0] && dref[0].euro && dref[0].euro.current) {
          dref[0].euro.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices[i].discountInEuro);
        }
        break;
      case 1:
        if (dref[1] && dref[1].euro && dref[1].euro.current) {
          dref[1].euro.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices[i].discountInEuro);
        }
        break;
    }    
    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.discountNetPrices = recalDiscountNetPrices;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);
    setReCalculation(wholeObject);
    setRecalDiscountNetPrices(recalDiscountNetPrices);
    calculateHeader(recal);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleDiscountComment = (value: string) => {
    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.discountComment = value;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);
    setReCalculation(wholeObject);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const getDiscountColorCode = (index: number): string => {
    let color = "#FAFAFA";
    let indexList: Array<number> = [];
    discountNetPrices?.map((item, i) => {
      if (item.displayLabel === "Referral commission") {
        if (order.referral) {
          if (order.referral.referralType !== "Internal_Empl") {
            indexList.push(i);
          }
        }
      } else {
        indexList.push(i);
      }
    })
    
    if(indexList.length > 0) {
      let exists = indexList.indexOf(index);
      if(exists !== -1)
      {
        if(exists % 2 === 0) { 
          return "#FAFAFA"; 
        }else
          return "#FFFFFF";
      }    
    }
    return color;
  }

  // Handler for kickback net prices
  const kickbackLabels = {
    usedCar: ["Sales promotion"],
  };
  const handleKickbackEuro = (value: string, i: number, elementId: string) => {
    recalKickbackNetPrices[i].kickbackInEuro = formatCurrencyToDecimal(value);
    let kPercent = 0.00;
    
    const rrp = (recalvehiclePrices[0].amountNet);
    if (rrp > 0) {
      
      kPercent = (recalKickbackNetPrices[i].kickbackInEuro / rrp) * 100;
      recalKickbackNetPrices[i].kickbackInPercent = kPercent;
    }
    switch (i) {
      case 0:
        if (kbRef[0].percent.current) {
          kbRef[0].percent.current.value = formatCurrencyWithNoUnit(recalKickbackNetPrices[i].kickbackInPercent);
        }
        break;
    }
    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.kickbackNetPrices = recalKickbackNetPrices;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);      
    calculateHeader(recal);     
    handleModified();
    setReload(new Date().toLocaleString());    
  }
  const handleKickbackPercentage = (value: string, i: number, elementId: string) => {
    recalKickbackNetPrices[i].kickbackInPercent = formatCurrencyToDecimal(value);
    let kEuro = 0.00;
    const rrp = (recalvehiclePrices[0].amountNet);
    if (rrp > 0) {
      kEuro = rrp * formatCurrencyToDecimal(value) / 100;
      recalKickbackNetPrices[i].kickbackInEuro = kEuro;
    }

    switch (i) {
      case 0:
        if (kbRef[0].euro.current) {
          kbRef[0].euro.current.value = formatCurrencyWithNoUnit(recalKickbackNetPrices[i].kickbackInEuro);
        }
        break;
    }

    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.kickbackNetPrices = recalKickbackNetPrices;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);
    setReCalculation(wholeObject);
    calculateHeader(recal);
    handleModified();
    setReload(new Date().toLocaleString());  
  }

  const handleKickbackDescription = (value: string) => {
    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.kickbackDescription = value;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);
    setReCalculation(wholeObject);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const updateKickBackBasedOnRRP = () => {
    const rrp = recalvehiclePrices[0].amountNet ;
    if (rrp > 0) {
      for (let i = 0; i < recalKickbackNetPrices.length; i++) {
        let euro = (recalKickbackNetPrices[i].kickbackInPercent * rrp) /100;
        recalKickbackNetPrices[i].kickbackInEuro = euro;
      }
      if (kbRef[0].euro && kbRef[0].euro.current) {
        kbRef[0].euro.current.value = formatCurrencyWithNoUnit(recalKickbackNetPrices[0].kickbackInEuro);
      }
      if (kbRef[1].euro.current) {
        kbRef[1].euro.current.value = formatCurrencyWithNoUnit(recalKickbackNetPrices[1].kickbackInEuro);
      }
     
      let wholeObject = orderContext.getCtxReCalculation();
      let recal = wholeObject.reCalculation;
      recal.kickbackNetPrices = recalKickbackNetPrices;
      wholeObject.reCalculation = recal;
      orderContext.updateCtxReCalculation(wholeObject);
      calculateHeader(recal);
      handleModified();
      setReload(new Date().toLocaleString());
    }
  }

  {/** Additional costs handlers and related functions */ }
  const handleAddAmountNet = (value: string, i: number, elementId: string) => {
    recalAdditionalCosts[i].amountNet = formatCurrencyToDecimal(value);
    recalAdditionalCosts[i].discountInEuro = recalAdditionalCosts[i].amountNet - recalAdditionalCosts[i].customerShare;
    if (recalvehiclePrices) {
      if (recalvehiclePrices[0].amountNet > 0) {
        recalAdditionalCosts[i].discountInPercent = recalAdditionalCosts[i].discountInEuro / recalvehiclePrices[0].amountNet * 100;
      } else {
        recalAdditionalCosts[i].discountInPercent = 0.00;
      }
      switch(i) {
        case 0:
          if(acRef[0].euro.current) acRef[0].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[0].percent.current) acRef[0].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
        case 1:
          if(acRef[1].euro.current) acRef[1].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[1].percent.current) acRef[1].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
        case 2:
          if(acRef[2].euro.current) acRef[2].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[2].percent.current) acRef[2].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
        case 3:
          if(acRef[3].euro.current) acRef[3].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[3].percent.current) acRef[3].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
        case 4:
          if(acRef[4].euro.current) acRef[4].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[4].percent.current) acRef[4].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
      }  
    }
    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.additionalCostsPrices = recalAdditionalCosts;
    wholeObject.reCalculation=recal;
    orderContext.updateCtxReCalculation(wholeObject);
    //Calculate header
    calculateHeader(recal);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleAddAmountDiscount = (value: string, i: number, elementId: string) => {
    recalAdditionalCosts[i].discountInEuro = formatCurrencyToDecimal(value);
    recalAdditionalCosts[i].customerShare = recalAdditionalCosts[i].amountNet - recalAdditionalCosts[i].discountInEuro;
    //additionalCosts[i].discountInPercent = additionalCosts[i].discountInEuro / additionalCosts[i].amountNet * 100;

    if (recalvehiclePrices) {
      if (recalvehiclePrices[0].amountNet > 0) {
        recalAdditionalCosts[i].discountInPercent = recalAdditionalCosts[i].discountInEuro / recalvehiclePrices[0].amountNet * 100;
      } else {
        recalAdditionalCosts[i].discountInPercent = 0.00;
      }
      switch(i) {
        case 0:
          //if(acRef[0].euro.current) acRef[0].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[0].percent.current) acRef[0].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
        case 1:
          //if(acRef[1].euro.current) acRef[1].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[1].percent.current) acRef[1].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
        case 2:
          //if(acRef[2].euro.current) acRef[2].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[2].percent.current) acRef[2].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
        case 3:
          //if(acRef[3].euro.current) acRef[3].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[3].percent.current) acRef[3].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
        case 4:
          //if(acRef[4].euro.current) acRef[4].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
          if(acRef[4].percent.current) acRef[4].percent.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent);
        break;
      }
    }

    const dom = document.getElementById(elementId);
    let enable = false;
    if(recalAdditionalCosts[i].discountInEuro > recalAdditionalCosts[i].amountNet){ 
      if(dom){
        dom.setAttribute("state", "error"); 
        const exists = addError.indexOf(elementId);
        if(exists === -1)
        {
          addError.push(elementId);
          setAddError(addError);         
        }        
      }  
    } else {
      if(dom){
        dom.setAttribute("state", "none");
        const exists = addError.indexOf(elementId);
        if(exists !== -1)
        {
          addError.splice(exists,1);
          setAddError(addError);
        }
        if(addError.length === 0){
          enable=true;
        }
      }
    }  

    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.additionalCostsPrices = recalAdditionalCosts;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);
    if(enable){
      //enableSaveButton(true);
      // Cross check if workshop costs is also valid or not.
      enableSaveButton(validateWorkShopCost(recalWorkshopCosts.upsaleItems));
    }else{
      enableSaveButton(false);
    }
    handleModified();
    //Calculate header
    calculateHeader(recal);
    setReload(new Date().toLocaleString());
  }

  const handleAdditionalCostComment = (value: string) => {
    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.additionalCostPriceComment = value;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const getAdditonalCostsColorCode = (index: number): string => {
    let color = "#FAFAFA";
    let indexList: Array<number> = [];
    additionalCosts?.map((item, i) => {
      if (item.type === "Registration" && item.label === "Registration by customer" ) return (<></>);
      if (item.type === "Paw" && item.label === item.type) return (<></>);
      if (item.type === "Assistance" && item.label === item.type ) return (<></>);
      if(item.label.length > 0 && item.label !== "Registration") {
        indexList.push(i);
      }
    })

    
    if(indexList.length > 0) {
      let exists = indexList.indexOf(index);
      if(exists !== -1) {
        if(exists % 2 === 0) {
          return "#FAFAFA";
        } else {
          return "#FFFFFF";
        }
      }
    }
    
    return color;
  }

  {/** Handlers and functions related to Workshop costs */ }
  const handleWsDiscountEuro = (value: string, partType: string, services: boolean, i: number, elementId: string) => {
    const dom = document.getElementById(elementId);

    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    //let usDetails = wholeObject.reCalculation.workshopCosts as UpsaleDetailsDTO;
    let usDetails = recalWorkshopCosts;
    if (services) {
      usDetails.upsaleItems.map((item, j) => {
        if (item.partType === partType) {
          item.serviceItems.map((service, k) => {
            if(i === k) {
              usDetails.upsaleItems[j].serviceItems[i].discount = formatCurrencyToDecimal(value);
              // Need review for below
              //usDetails.upsaleItems[j].serviceItems[i].customerShare = usDetails.upsaleItems[j].serviceItems[i].services.priceBeforeCustomerNet - usDetails.upsaleItems[j].serviceItems[i].discount;
              usDetails.upsaleItems[j].serviceItems[i].customerShare = service.services.priceBeforeCustomerNet - usDetails.upsaleItems[j].serviceItems[i].discount;
              if (usDetails.upsaleItems[j].serviceItems[i].discount > service.services.priceBeforeCustomerNet){
                if(dom) dom.setAttribute("state", "error");              
              } else {
                if(dom) dom.setAttribute("state", "none");
              }
            }
          })
        }
      })
    } else {
      usDetails.upsaleItems.map((item, j) => {
        if (item.partType === partType) {
          usDetails.upsaleItems[j].discount = formatCurrencyToDecimal(value);
          // Need review for below
          usDetails.upsaleItems[j].customerShare = usDetails.upsaleItems[j].part.priceBeforeCustomerNet !== undefined
          ? usDetails.upsaleItems[j].part.priceBeforeCustomerNet! - usDetails.upsaleItems[j].discount
          : 0.00
          if (usDetails.upsaleItems[j].discount > usDetails.upsaleItems[j].part.priceBeforeCustomerNet!){  
            if(dom) dom.setAttribute("state", "error");
           } else {
          if(dom) dom.setAttribute("state", "none");
          }
        }
      })
      //usDetails.upsaleItems[i].customerShare = formatCurrencyToDecimal(value);
    }

    recal.workshopCosts = usDetails;
    wholeObject.reCalculation = recal;
    setReCalculation( wholeObject);
    orderContext.updateCtxReCalculation(wholeObject);
    const isValid = validateWorkShopCost(usDetails.upsaleItems);
    if(!isValid) {
      setWorkShopError(true);
      enableSaveButton(false);
    } else {
      setWorkShopError(false);
      enableSaveButton(addError.length > 0 ? false : true);
    }

    handleModified();    
    // Calculate header
    calculateHeader(recal);
    setReload(new Date().toLocaleString());
  }

  const validateWorkShopCost = (parts: Array<UpsaleItemDTO>) => {
    // const parts = usItems.filter(x => x.partType === "part");
    if(parts.length > 0){
      for(let p of parts){
        if(p.discount && p.part.priceBeforeCustomerNet !== undefined && p.discount > p.part.priceBeforeCustomerNet) {
          return false;
        }
        if(p.serviceItems.length>0){
          if(p.serviceItems[0].discount && p.serviceItems[0].services.priceBeforeCustomerNet && 
            p.serviceItems[0].discount > p.serviceItems[0].services.priceBeforeCustomerNet) {
              return false;
          }
        }
        if(p.serviceItems.length>1 && p.partType === "tire"){
          if(p.serviceItems[1].discount && p.serviceItems[1].services.priceBeforeCustomerNet && 
            p.serviceItems[1].discount > p.serviceItems[1].services.priceBeforeCustomerNet) {
              return false;
          }
        }
      }
    }
    return true;
  }

  const getWorkshopCostsColorCode = (wcType: string, service: boolean, index: number): string => {
    const tires = workshopCosts?.upsaleItems?.filter(x  => x.partType === "tire");
    if(wcType === "tire" && service === false && tires.length > 0) return "#FAFAFA";
    if(wcType === "tire" && service === true && tires.length > 0) {
      if(index === 0) return "#FFFFF"
      if(index === 1) return "#FAFAFA"
    }

    const parts = workshopCosts?.upsaleItems?.filter(x  => x.partType === "part");
    if(wcType === "part" && service === false && parts?.length > 0){
      if(tires.length === 0) return "#FAFAFA";
      if(tires[0].serviceItems.length > 1) {
        return "#FFFFFF";
      } else {
        return "#FAFAFA";
      }
    } 
     
    if(wcType === "part" && service === true && parts?.length > 0) {
      if(tires.length === 0) return "#FFFFF";
      if(tires[0].serviceItems.length > 1) {
        return "#FAFAFA";
      } else {
        return "#FFFFFF";
      }
    }

    return "";
  }

  const getAmountNet = (item: UpsaleItemDTO, service: boolean, i: number) => {
    if (service) {
      if (item.serviceItems && item.serviceItems.length > 0 && item.serviceItems[i]) {
        if (item.serviceItems[i].customerShare > 0) {
          const ret = item.serviceItems[i].services.priceBeforeCustomerNet ? item.serviceItems[i].services.priceBeforeCustomerNet : 0.00;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return ret * item?.serviceItems?.[i].timeUnits! / 100;
            } else {
              return 0.00;
            }
          } else {
            return ret;
          }
        } else {
          const ret1 = item.serviceItems[i].services.priceInternal ? item.serviceItems[i].services.priceInternal : 0.00;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return ret1 * item?.serviceItems?.[i].timeUnits! / 100;
            } else {
              return 0.00;
            }
          } else {
            return ret1;
          }
        }
      } else {
        return 0.00;
      }
    } else {
      if (item.customerShare > 0) {
        return item.part.priceBeforeCustomerNet ? item.part.priceBeforeCustomerNet : 0.00;
      } else {
        return item.part.priceInternal ? item.part.priceInternal : 0.00;
      }
    }
  }

  const getPercent = (item: UpsaleItemDTO, service: boolean, i: number, calType: string) => {
    let disPercent = 0.00;
    let dis = 0.00
    if (service) {
      const net = getAmountNet(item, service, i);
      if(item.serviceItems && item.serviceItems.length > 0 && item.serviceItems[i]) {
        dis = net - item.serviceItems[i].customerShare;
      }
      // if (dis > 0 ) {
      //   disPercent =  (dis / net) * 100;
      // }
    } else {
      const net = getAmountNet(item, service, i);
      dis = net - item.customerShare;
      // if (net > 0) {
      //   disPercent =   (dis / net) * 100;
      // }
    }
    if (calType === "re" && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0) {
      disPercent = dis / recalvehiclePrices[0].amountNet * 100;
    }

    if(calType === "pre" && vehiclePrices && vehiclePrices[0].amountNet > 0) {
      disPercent = dis / vehiclePrices[0].amountNet * 100;
    }
    
    return disPercent;
  }

  // Handlers for TradeIn
  const handleTradeInAmountNet = (value: string, index: number, elementId: string) => {
    recalTradeInFields[index].amountNet = formatCurrencyToDecimal(value);
    if (order.tradeInDetails) {
      switch (order.tradeInDetails.tradeInType) {
        case "TradeIn_Regular":
        case "Financing_Redemption_Regular":
        case "Leasing_Return":
        case "Leasing_Redemption":
          recalTradeInFields[index].amountGross = formatCurrencyToDecimal(value) * (1 + vat / 100);
          break;
        case "TradeIn_Differential":
        case "Financing_Redemption_Differential":
          recalTradeInFields[index].amountGross = formatCurrencyToDecimal(value);
          break;
      }
    }

    if (index < 2) {
      recalTradeInFields[2].amountNet = recalTradeInFields[0].amountNet - recalTradeInFields[1].amountNet;
      recalTradeInFields[2].amountGross = recalTradeInFields[0].amountGross - recalTradeInFields[1].amountGross;
      if (tRef[2] && tRef[2].gross && tRef[2].gross.current) {
        tRef[2].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[2].amountGross);
      }
      if (tRef[2] && tRef[2].net && tRef[2].net.current) {
        tRef[2].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields[2].amountNet);
      }

      recalTradeInFields[3].amountNet = recalTradeInFields[0].amountNet - recalTradeInFields[1].amountNet;
      recalTradeInFields[3].amountGross = recalTradeInFields[0].amountGross - recalTradeInFields[1].amountGross;
      recalTradeInFields[4].amountNet = 0.00;
      recalTradeInFields[4].amountGross = 0.00;

      if (recalTradeInFields[2].amountNet < 0) {
        recalTradeInFields[2].tradeInColumn = TradeInOptions.LossBooking;
        if (recalvehiclePrices && recalvehiclePrices.length > 0) {
          if (tRef[3] && tRef[3].percent && tRef[3].percent.current) {
            tRef[3].percent.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0  ? recalTradeInFields[3].amountNet/recalvehiclePrices[0].amountNet * -1 * 100 : 0.00)
          }
        }
      } else {
        recalTradeInFields[2].tradeInColumn = TradeInOptions.ProfitBooking;

        if (recalvehiclePrices && recalvehiclePrices.length > 0) {
          if (tRef[2] && tRef[2].percent && tRef[2].percent.current) {
            tRef[2].percent.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0 ? recalTradeInFields[2].amountNet/recalvehiclePrices[0].amountNet * -1 * 100 : 0.00)
          }
        }
      }
    }

    switch (index) {
      case 0:
        if (tRef[0] && tRef[0].gross && tRef[0].gross.current) {
          tRef[0].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[0].amountGross);
        }
        break;
      case 1:
        if (tRef[1] && tRef[1].gross && tRef[1].gross.current) {
          tRef[1].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[1].amountGross);
        }
        break;
      case 2:
        if (tRef[2] && tRef[2].gross && tRef[2].gross.current) {
          tRef[2].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[2].amountGross);
        }
        break;
      case 3:
        if (tRef[3] && tRef[3].gross && tRef[3].gross.current) {
          tRef[3].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[3].amountGross);
        }
        break;
      case 4:
        if (tRef[4] && tRef[4].gross && tRef[4].gross.current) {
          tRef[4].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[4].amountGross);
        }
        break;
    }

    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.tradeInPrices = recalTradeInFields;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);
    calculateHeader(recal);
    handleModified();
    getSharePcPercent();
    setReload(new Date().toLocaleString());
  }

  const handleTradeInAmountGross = (value: string, index: number, elementId: string) => {
    recalTradeInFields[index].amountGross = formatCurrencyToDecimal(value);
    if (order.tradeInDetails) {
      switch (order.tradeInDetails.tradeInType) {
        case "TradeIn_Regular":
        case "Financing_Redemption_Regular":
        case "Leasing_Return":
        case "Leasing_Redemption":
          recalTradeInFields[index].amountNet = formatCurrencyToDecimal(value) / (1 + vat / 100);
          break;
        case "TradeIn_Differential":
        case "Financing_Redemption_Differential":
          recalTradeInFields[index].amountNet = formatCurrencyToDecimal(value);
          break;
      }
    }
    if (index < 2) {
      recalTradeInFields[2].amountNet = recalTradeInFields[0].amountNet - recalTradeInFields[1].amountNet;
      recalTradeInFields[2].amountGross = recalTradeInFields[0].amountGross - recalTradeInFields[1].amountGross;
      if (tRef[2] && tRef[2].gross && tRef[2].gross.current) {
        tRef[2].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[2].amountGross);
      }
      if (tRef[2] && tRef[2].net && tRef[2].net.current) {
        tRef[2].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields[2].amountNet);
      }

      recalTradeInFields[3].amountNet = recalTradeInFields[0].amountNet - recalTradeInFields[1].amountNet;
      recalTradeInFields[3].amountGross = recalTradeInFields[0].amountGross - recalTradeInFields[1].amountGross;
      recalTradeInFields[4].amountNet = 0.00;
      recalTradeInFields[4].amountGross = 0.00;

      if (recalTradeInFields[2].amountNet < 0) {
        recalTradeInFields[2].tradeInColumn = TradeInOptions.LossBooking;
        if (recalvehiclePrices && recalvehiclePrices.length > 0) {
          if (tRef[3] && tRef[3].percent && tRef[3].percent.current) {
            tRef[3].percent.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0  ? recalTradeInFields[3].amountNet/recalvehiclePrices[0].amountNet * -1 * 100 : 0.00)
          }
        }
      } else {
        recalTradeInFields[2].tradeInColumn = TradeInOptions.ProfitBooking;
        if (recalvehiclePrices && recalvehiclePrices.length > 0) {
          if (tRef[2] && tRef[2].percent && tRef[2].percent.current) {
            tRef[2].percent.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0 ? recalTradeInFields[2].amountNet/recalvehiclePrices[0].amountNet * -1 * 100 : 0.00)
          }
        }
      }
    } 
    
    switch (index) {
      case 0:
        if (tRef[0] && tRef[0].net && tRef[0].net.current) {
          tRef[0].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields[0].amountNet);
        }
        break;
      case 1:
        if (tRef[1] && tRef[1].net && tRef[1].net.current) {
          tRef[1].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields[1].amountNet);
        }
        break;
      case 2:
        if (tRef[2] && tRef[2].net && tRef[2].net.current) {
          tRef[2].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields[2].amountNet);
        }
        break;
      case 3:
        if (tRef[3] && tRef[3].net && tRef[3].net.current) {
          tRef[3].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields[3].amountNet);
        }
        break;
      case 4:
        if (tRef[4] && tRef[4].net && tRef[4].net.current) {
          tRef[4].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields[4].amountNet);
        }
        break;
    }

    let wholeObject=orderContext.getCtxReCalculation();
    let recal=wholeObject.reCalculation;
    recal.tradeInPrices=recalTradeInFields;
    wholeObject.reCalculation=recal;
    orderContext.updateCtxReCalculation(wholeObject);
    calculateHeader(recal);
    handleModified();
    getSharePcPercent();
    setReload(new Date().toLocaleString());
  }

  const validateTradeInFields = (value: string, index: number, elementId: string) => {
    let val = parseInt(value);
    
    switch (index) {
      case 0:
        if (val > 0) {
          setShowErrorCarInternal(false);
        } else {
          setShowErrorCarInternal(true);
        }
        break;
      case 1:
        if (val > 0) {
          setShowErrorCarCustomer(false);
        } else {
          setShowErrorCarCustomer(true);
        }
        break;
    }
  }

  const getSharePcPercent = () => {
    let rtpc = 0.00;
    let tpc = 0.00;
    if(recalTradeInFields && recalTradeInFields.length > 0 && tradeInFields && tradeInFields.length > 0) {
      if(recalTradeInFields[2].amountNet < 0 && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0) {
        rtpc = recalTradeInFields[3].amountNet/recalvehiclePrices[0].amountNet * -1 * 100;
        
        //rtpc = Number(rtpc.toFixed(2));
      }
        
      if(tradeInFields[2].amountNet < 0 && vehiclePrices && vehiclePrices[0].amountNet > 0) {
        tpc = tradeInFields[3].amountNet/vehiclePrices[0].amountNet * -1 * 100;
        
        //tpc = Number(tpc.toFixed(2));
      }
    }

    
    setSharePcPercent(rtpc - tpc);
    return rtpc - tpc;
  }

  const handleTradeInComment = (value: string) => {
    let wholeObject=orderContext.getCtxReCalculation();
    let recal=wholeObject.reCalculation;
    recal.tradeInComment=value;
    wholeObject.reCalculation=recal;
    orderContext.updateCtxReCalculation(wholeObject);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  // Handlers for payment section
  const handlePaymentAmountNet = (value: string, type: string, elementId: string) => {
    switch (type) {
      case "down payment":
        recalCalculationPayment.paymentNet = formatCurrencyToDecimal(value);
        recalCalculationPayment.paymentGross = formatCurrencyToDecimal(value) * (1 + vat / 100);
        if (dpRef[0].gross.current) {
          dpRef[0].gross.current.value = formatCurrencyWithNoUnit(recalCalculationPayment.paymentGross);
        }
        break;

      case "upfront payment":
        recalCalculationPayment.upFrontPaymentNet = formatCurrencyToDecimal(value);
        recalCalculationPayment.upFrontPaymentGross = formatCurrencyToDecimal(value) * (1 + vat / 100);
        if (upRef[0].gross.current) {
          upRef[0].gross.current.value = formatCurrencyWithNoUnit(recalCalculationPayment.upFrontPaymentGross);
        }
        break;
    }

    let wholeObject=orderContext.getCtxReCalculation();
    let recal=wholeObject.reCalculation;
    recal.calculationPaymentDetails=recalCalculationPayment;
    wholeObject.reCalculation=recal;
    orderContext.updateCtxReCalculation(wholeObject);
    calculateHeader(recal);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handlePaymentAmountGross = (value: string, type: string, elementId: string) => {
    switch (type) {
      case "down payment":
        recalCalculationPayment.paymentGross = formatCurrencyToDecimal(value);
        recalCalculationPayment.paymentNet = formatCurrencyToDecimal(value) / (1 + vat / 100);
        if (dpRef[0].net.current) {
          dpRef[0].net.current.value = formatCurrencyWithNoUnit(recalCalculationPayment.paymentNet);
        }
        break;

      case "upfront payment":
        recalCalculationPayment.upFrontPaymentGross = formatCurrencyToDecimal(value);
        recalCalculationPayment.upFrontPaymentNet = formatCurrencyToDecimal(value) / (1 + vat / 100);
        if (upRef[0].net.current) {
          upRef[0].net.current.value = formatCurrencyWithNoUnit(recalCalculationPayment.upFrontPaymentNet);
        }
        break;
    }

    let wholeObject = orderContext.getCtxReCalculation();
    let recal = wholeObject.reCalculation;
    recal.calculationPaymentDetails = recalCalculationPayment;
    wholeObject.reCalculation = recal;
    orderContext.updateCtxReCalculation(wholeObject);
    calculateHeader(recal);
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const getIncludeOptionsLabel = (key: string): string => {
    let str = key;
    switch(key) {
      case "Factory pickup Zuffenhausen":
      case "Factory pickup Leipzig":
        str = "Factory Pickup";
        break;
      case "Others": 
        str = "Other Transfer";
        break;
      case "PAW (12 months)":
      case "PAW (24 months)":
      case "PAW (36 months)":
        str = "PAW";
        break;
      
      case "Assistance (12 months)":
      case "Assistance (24 months)":
      case "Assistance (36 months)":
        str = "Assistance";
        break;
      
      case "Mounting (Winter tires)":
        str = "Mounting WCT";
        break;
      case "Storage (Winter tires)":
        str = "Storage WCT";
        break;
      case "Shipment (Winter tires)":
        str = "Shipment WCT";
        break;
      case "Pickup (Winter tires)":
        str = "Pickup WCT";
        break;

      case "Storage (Summer tires)":
        str = "Storage SCT";
        break;
      case "Shipment (Summer tires)":
        str = "Shipment SCT";
        break;
      case "Pickup  (Summer tires)":
        str = "Pickup SCT";
        break;

      default:
        let x = key.includes("registration");
        if(x === true) str = "Registration";
        break;
    }

    return str;
  }
  
  const isValidCommissionSharing = (commissionSharing: Array<CommissionSharingDTO>) => {
    let percent = 0.00;
    commissionSharing.forEach(sharing => {
      percent += sharing.shareInPercent;
    })

    // Sum of percentages 100
    if (percent === 100) {
      return true;
    } else if (percent === 99.99 || (percent > 99.99 && percent < 100)) {
      return true;
    } else {
      return false;
    }
  }

  const handleSharingPercent = (value: string, i: number, elementId: string) => {
      recalUserCommissionShares[i].shareInPercent = formatCurrencyToDecimal(value);
      let vc = recalCommissionCalculation.vehicleCommissionEuro ? recalCommissionCalculation.vehicleCommissionEuro : 0.00;
      
       recalUserCommissionShares[i].shareInEuro = vc * recalUserCommissionShares[i].shareInPercent / 100;
        switch (i) {
          case 0:
            if (csRef[0] && csRef[0].euro && csRef[0].euro.current) {
              csRef[0].euro.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInEuro);
            }
            break;
          case 1:
            if (csRef[1] && csRef[1].euro && csRef[1].euro.current) {
              csRef[1].euro.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInEuro);
            }
            break;
          case 2:
            if (csRef[2] && csRef[2].euro && csRef[2].euro.current) {
              csRef[2].euro.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInEuro);
            }
            break;
        }
    //  }
      setCsError(!isValidCommissionSharing(recalUserCommissionShares));

      let wholeObject=orderContext.getCtxReCalculation();
      let recal=wholeObject.reCalculation;
      recal.commissionSharing=recalUserCommissionShares;
      wholeObject.reCalculation=recal;
      //setRecalUserCommissionShares(recalUserCommissionShares);
      setReCalculation(wholeObject);
      orderContext.updateCtxReCalculation(wholeObject);
      calculateHeader(recal);
      handleModified();
      setReload(new Date().toLocaleString());
  }

  const handleSharingEuro = (value: string, i: number, elementId: string) => {
      recalUserCommissionShares[i].shareInEuro = formatCurrencyToDecimal(value);
      let vc = recalCommissionCalculation.vehicleCommissionEuro ? recalCommissionCalculation.vehicleCommissionEuro : 0.00;
        recalUserCommissionShares[i].shareInPercent = recalUserCommissionShares[i].shareInEuro / vc * 100;
        switch (i) {
          case 0:
            if (csRef[0] && csRef[0].percent && csRef[0].percent.current) {
              csRef[0].percent.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInPercent);
            }
            break;
          case 1:
            if (csRef[1] && csRef[1].percent && csRef[1].percent.current) {
              csRef[1].percent.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInPercent);
            }
            break;
          case 2:
            if (csRef[2] && csRef[2].percent && csRef[2].percent.current) {
              csRef[2].percent.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInPercent);
            }
            break;
        }
      setCsError(isValidCommissionSharing(recalUserCommissionShares));
      
      
      let wholeObject=orderContext.getCtxReCalculation();
      let recal=wholeObject.reCalculation;
      recal.commissionSharing=recalUserCommissionShares;
      wholeObject.reCalculation=recal;
      orderContext.updateCtxReCalculation(wholeObject);
      calculateHeader(recal);
      handleModified();
      setReload(new Date().toLocaleString());
  }
    //its junk

    const handleVehicleCommission = (value: string) => {
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        if(recal.commissionCalculation) {
          recal.commissionCalculation.vehicleCommission = value;
          recal.commissionCalculation.vehicleCommissionEuro = 0.00;
          wholeObject.reCalculation = recal;
          orderContext.updateCtxReCalculation(wholeObject);
          setReCalculation(wholeObject);
          calculateHeader(wholeObject.reCalculation);
          updateSeller();
          handleModified();
          setReload(new Date().toLocaleString());
        }
      }
    
      const handleVehicleCommissionEuro = (value: string, elementId: string) => {
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        if(recal.commissionCalculation) {
          recal.commissionCalculation.vehicleCommissionEuro = formatCurrencyToDecimal(value);
          wholeObject.reCalculation = recal;
          orderContext.updateCtxReCalculation(wholeObject);
          setReCalculation(wholeObject);
          calculateHeader(wholeObject.reCalculation);
          updateSeller()
          handleModified();
          setReload(new Date().toLocaleString());
        }
      }
    
      // Commission calculation
      const getCommissionColorCode = (index :string): string => {
        let i=0;
        let color = "#FAFAFA";
        let indexList: Array<String> = [];
        if(order?.paymentDetails){     
          if(order?.paymentDetails?.paymentType === "Leasing_PFS" ||order?.paymentDetails?.paymentType === "Financing_PFS") {          
            indexList.push("PFS");                
          }
          if(order?.paymentDetails?.carPolicy){
            indexList.push("carPolicy");
          }
          if(order?.paymentDetails?.porscheCardS){
            indexList.push("porscheCardS");
          }
          indexList.push("Incentive");
        }

        if(indexList.length > 0) {
          let exists = indexList.indexOf(index);
          if(exists !== -1)
            {
            if(exists % 2 === 0) { 
              return "#FAFAFA"; 
            }else
              return "#FFFFFF";
          }    
        }
        return color;
      }


      const handlePFS = (value: string, elementId: string) => {
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        if(recal.commissionCalculation) {
          recal.commissionCalculation.pfsCommission = formatCurrencyToDecimal(value);
          wholeObject.reCalculation = recal;
          orderContext.updateCtxReCalculation(wholeObject);
          setReCalculation(wholeObject);
          handleModified();
          calculateHeader(recal);
          setReload(new Date().toLocaleString());
        }
      }
    
      const handleCpc = (value: string, elementId: string) => {
        // if (calDraft.commissionCalculation) {
        //   calDraft.commissionCalculation.carPolicyCommission = formatCurrencyToDecimal(value);
        //   orderContext.updateCtxCalculationDraftDetails(calDraft, "NewCar");
        //   calculateHeader(calDraft);
        //   // handleModified();
        //   //setReload(new Date().toLocaleString());
        // }
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        if(recal.commissionCalculation) {
          recal.commissionCalculation.carPolicyCommission = formatCurrencyToDecimal(value);
          wholeObject.reCalculation = recal;
          orderContext.updateCtxReCalculation(wholeObject);
          setReCalculation(wholeObject);
          handleModified();
          calculateHeader(recal);
          setReload(new Date().toLocaleString());
        }
      }
    
      const handlePcs = (value: string, elementId: string) => {

        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        if(recal.commissionCalculation) {
          recal.commissionCalculation.porscheCardS = formatCurrencyToDecimal(value);
          wholeObject.reCalculation = recal;
          orderContext.updateCtxReCalculation(wholeObject);
          setReCalculation(wholeObject);
          handleModified();
          calculateHeader(recal);
          setReload(new Date().toLocaleString());
        }
      }
    
      const handleIncentive = (value: string, elementId: string) => {
        // if (calDraft.commissionCalculation) {
        //   calDraft.commissionCalculation.incentive = formatCurrencyToDecimal(value);
        //   orderContext.updateCtxCalculationDraftDetails(calDraft, "NewCar");
        //   calculateHeader(calDraft);
        //   // handleModified();
        //   // setReload(new Date().toLocaleString());
        // }
        let wholeObject=orderContext.getCtxReCalculation();
        let recal=wholeObject.reCalculation;
        if(recal.commissionCalculation) {
          recal.commissionCalculation.incentive = formatCurrencyToDecimal(value);
          wholeObject.reCalculation = recal;
          orderContext.updateCtxReCalculation(wholeObject);
          setReCalculation(wholeObject);
          handleModified();
          calculateHeader(recal);
          setReload(new Date().toLocaleString());
        }
      }
    
    const calculateHeader = (cdd: CalculationDraftDTO) => {
      
      let dicountNetInternalDicount = 0.00;
      if (cdd.discountNetPrices) {
        const length = cdd.discountNetPrices.length;
        for(let i=0; i<length; i++) {
          dicountNetInternalDicount += cdd.discountNetPrices[i].discountInEuro;
        }
      }
  
      let kickbackNetInternalDicount = 0.00;
      if (cdd.kickbackNetPrices) {
        const length = cdd.kickbackNetPrices.length;
        for(let i=0; i<length; i++) {
          kickbackNetInternalDicount += cdd.kickbackNetPrices[i].kickbackInEuro;
        }
      }
  
      let additionalCostsDicount = 0.00;
      if (cdd.additionalCostsPrices) {
        const length = cdd.additionalCostsPrices.length;
        for(let i=0; i<length; i++) {
          additionalCostsDicount += cdd.additionalCostsPrices[i].discountInEuro;
        }
      }
  
      let workshopCostsDicount = 0.00;
      if (cdd.workshopCosts && cdd.workshopCosts.upsaleItems && cdd.workshopCosts.upsaleItems.length > 0) {
        const total = getUpsaleItemsTotal(cdd.workshopCosts.upsaleItems);
        workshopCostsDicount += total.discount;
      }
  
      let tradeInPrices = 0.00;
      if (cdd.tradeInPrices) {
        tradeInPrices += cdd.tradeInPrices[3].amountNet;
      }
  
      if(cdd.calculationHeader) {
        if(cdd.onlineCarSaleInfo) {
          cdd.calculationHeader.totalCosts = cdd.onlineCarSaleInfo.repairCosts ? cdd.onlineCarSaleInfo.repairCosts : 0.00;
        }
        cdd.calculationHeader.totalCosts += dicountNetInternalDicount -
                                            kickbackNetInternalDicount +
                                            additionalCostsDicount + 
                                            workshopCostsDicount - 
                                            tradeInPrices;
      }
  
      if (cdd.calculationHeader && cdd.vehiclePrices) {
          cdd.calculationHeader.grossProfit = cdd.vehiclePrices[0].amountNet - 
                                              cdd.vehiclePrices[1].amountNet -
                                              cdd.calculationHeader.totalCosts;
      }
  
      calculateFinal(cdd);
  
      // Calulation for total commission
      if (cdd.calculationHeader && cdd.commissionCalculation) {
        if(cdd.commissionCalculation.vehicleCommission === "Standard") {
          cdd.commissionCalculation.vehicleCommissionEuro = calculateVehicleCommissionForUsedCar(cdd);
          cdd.commissionCalculation.totalCommission = calculateTotalCommission(cdd);
        }
        if (cdd.commissionCalculation.vehicleCommission === "Fixed") {
          cdd.commissionCalculation.totalCommission = calculateTotalCommission(cdd);
          cdd.calculationHeader.totalCommission = cdd.commissionCalculation.totalCommission;
        }
      }
  
      let wholeObject = orderContext.getCtxReCalculation();
      wholeObject.reCalculation = cdd;
      if(cdd.calculationHeader) {
        setRecalFinalCalculation(cdd.calculationHeader);
        setRecalCalculationHeader(cdd.calculationHeader)
      }
      if(cdd.commissionCalculation) {
        setRecalCommissionCalculation(cdd.commissionCalculation)
        
      }
      setReCalculation(wholeObject);
      orderContext.updateCtxReCalculation(wholeObject);

      if(cdd.isCommissionSharingActive){
        updateSeller()
      }
    }
    const calculateTotalCommission = (calDraft: CalculationDraftDTO) => {
      let total = 0.0;
      if (calDraft.commissionCalculation && calDraft.commissionCalculation.vehicleCommissionEuro !== undefined) {
        if(calDraft.commissionCalculation.vehicleCommission === "Standard") {
          total = calDraft.commissionCalculation.vehicleCommissionEuro;
          // If commission sharing is active
          if(calDraft.isCommissionSharingActive && calDraft.commissionSharing) {
            total = total * calDraft.commissionSharing[0].shareInPercent / 100;
          } 
        } 
  
        // vehicle commission is of fixed type then vehicle cmmission is enterd value
        if(calDraft.commissionCalculation.vehicleCommission === "Fixed") {
          total = calDraft.commissionCalculation.vehicleCommissionEuro;
           // If commission sharing is active
           if(calDraft.isCommissionSharingActive && calDraft.commissionSharing) {
            total = total * calDraft.commissionSharing[0].shareInPercent / 100;
          } 
        }
      }
        
      if (calDraft.commissionCalculation && calDraft.commissionCalculation.carPolicyCommission)
        total = total + (calDraft.commissionCalculation.carPolicyCommission * scalingobj.carPolicy) / 100;
      if (calDraft.commissionCalculation && calDraft.commissionCalculation.porscheCardS)
        total = total + (calDraft.commissionCalculation.porscheCardS * scalingobj.porscheCardS) / 100;
      if (calDraft.commissionCalculation && calDraft.commissionCalculation?.pfsCommission)
        total = total + (calDraft.commissionCalculation.pfsCommission * calculatePFSMaxExtraData()) / 100;
      if (calDraft.commissionCalculation && calDraft.commissionCalculation?.incentive)
        total = total + calDraft.commissionCalculation?.incentive;
      return total;
    }
  
    const calculatePFSMaxExtraData = () => {
      let pfs = scalingobj.pfs;
      let pfsList: Array<number> = [];
      let order = orderContext.getCtxOrderDetails();
      if (order.paymentDetails.paymentType === "Leasing_PFS" || order.paymentDetails.paymentType === "Financing_PFS") {
        pfsList.push(scalingobj.pfs);
      }
      if (order.paymentDetails.gap) {
        pfsList.push(scalingobj.gap);
      }
      if (order.paymentDetails.leasing_s) {
        pfsList.push(scalingobj.leasings);
      }
      if (order.paymentDetails.servicePlus) {
        pfsList.push(scalingobj.servicePlus);
      }
     let sortedList= pfsList.sort(function(x,y) {
        return x-y
        });
      
       sortedList.reverse();
        
        return sortedList[0];
    }
    // Final calucation 
    const calculateFinal = (cdd: CalculationDraftDTO) =>{
      final.invoiceNet = getInvoiceNet(cdd);
      final.invoiceGross = getInvoiceGross(cdd);
      final.preNet = getPredeliveryNet(cdd);
      final.preGross = getPredeliveryGross(cdd) ;
      final.paymentNet = getPaymentNet(cdd);
      final.paymentGross = getPaymentGross(cdd);
  
      if(cdd.calculationCustomer && cdd.calculationPaymentDetails) {
        cdd.calculationCustomer.invoiceNet = final.invoiceNet;
        cdd.calculationCustomer.invoiceGross = final.invoiceGross;
        cdd.calculationCustomer.preDeliveryNet = final.preNet;
        cdd.calculationCustomer.preDeliveryGross = final.preGross;
  
        if(cdd.calculationPaymentDetails.paymentType !== "DebitCredit") {
          cdd.calculationCustomer.paymentNet = final.paymentNet;
          cdd.calculationCustomer.paymentGross = final.paymentGross;
        }
  
       // orderContext.updateCtxCalculationDraftDetails(calDraft, "UsedCar");
       // setCalDraft(calDraft);
      }
  
      setFinal(final);
    }
  
    const getPredeliveryGross = (cdd: CalculationDraftDTO) => {
      let pdg = getPredeliveryNet(cdd) * (1 + vat/100);
      if (order.tradeInDetails) {
        if (cdd.tradeInPrices) {
          switch(order.tradeInDetails.tradeInType) {
            case "TradeIn_Differential":
              // const tips = cdd.tradeInPrices.filter(x => x.tradeInColumn === "CarValueCustomer")
              // if (tips.length > 0) {
              //   pdg = pdg - tips[0].amountNet*vat/100;
              // }
              pdg = pdg - cdd.tradeInPrices[1].amountNet * vat / 100;
              break;
          }
        }
      }
  
      if(cdd.calculationPaymentDetails){
        const checkPaw = cdd.calculationPaymentDetails.includeOptionals?.filter(x => x === "Paw")
        if(checkPaw?.length === 0) {
          if(cdd.additionalCostsPrices) {
            const addCost = cdd.additionalCostsPrices.filter(x => x.type === "Paw");
            if(addCost.length > 0){
              pdg = pdg - addCost[0].customerShare * (vat / 100);
            }
          }
        }
      }
  
      return pdg;
    }
  
    const getPaymentNet = (cdd: CalculationDraftDTO) => {
      let pn = 0.00;
      if (cdd.vehiclePrices) {
        pn = pn + cdd.vehiclePrices[0].amountNet;
      }
  
      if(cdd.calculationPaymentDetails) {
        pn = pn - cdd.calculationPaymentDetails.paymentNet;
      }
  
      pn = pn + getIncludedCustomerShare(cdd);
      return pn
    }
   
    const getPaymentGross = (cdd: CalculationDraftDTO) => {
      let pg = getPaymentNet(cdd) * (1 + vat / 100);
      if(cdd.calculationPaymentDetails){
        const checkPaw = cdd.calculationPaymentDetails.includeOptionals?.filter(x => x === "Paw");
        if(checkPaw && checkPaw.length > 0) {
          if(cdd.additionalCostsPrices) {
            const addCost = cdd.additionalCostsPrices.filter(x => x.type === "Paw");
            if(addCost.length > 0){
              pg = pg - addCost[0].amountNet * (vat / 100);
            }
          }
        }
      }
      return pg;
    }
  
  
    const calculateVehicleCommissionForUsedCar = (calDraft: CalculationDraftDTO) => {
      let vc = 0.00;
      
      
      if (scalingobj.commissions ) {
  
        let order = orderContext.getCtxOrderDetails();
        const catObj = order.vehicleDetails.category;
        
        if (catObj.type === "UsedCar" && catObj.option === "Stock" && order.customerDetails.customerType==="DealerBiddingPlatform" && (order.vehicleDetails.usedCarCollection==="LeasingReturnPFS" || order.vehicleDetails.usedCarCollection === "LeasingReturnThirdParty")) {
          
          calculateVariable("UsedCarSpecialCaseDealerBiddingPlatform");
          if (commissionObj.variable === "StandingDays" &&  calDraft?.onlineCarSaleInfo && calDraft?.onlineCarSaleInfo.standingDays !== undefined) {
            let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
            let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined = calculateRangeValue(standingdays, "UsedCarSpecialCaseDealerBiddingPlatform",calDraft);
            if (vehicleCommissionDTO != null && commissionObj != null) {
              vc = vehicleCommissionByDiscount(vehicleCommissionDTO,calDraft);
            }
          }
  
        } else if (catObj.type === "UsedCar" && catObj.option === "Other" && order.customerDetails.customerType==="DealerBiddingPlatform" && (order.vehicleDetails.usedCarCollection==="LeasingReturnPFS" || order.vehicleDetails.usedCarCollection === "LeasingReturnOther")) {
          
          calculateVariable("UsedCarSpecialCaseDealerBiddingPlatform");
          if (commissionObj.variable === "StandingDays" &&  calDraft?.onlineCarSaleInfo && calDraft?.onlineCarSaleInfo.standingDays !== undefined) {
            let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
            let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined = calculateRangeValue(standingdays, "UsedCarSpecialCaseDealerBiddingPlatform",calDraft);
            if (vehicleCommissionDTO != null && commissionObj != null) {
              vc = vehicleCommissionByDiscount(vehicleCommissionDTO,calDraft);
            }
          }
  
        } else  if (catObj.type === "UsedCar" && (order.customerDetails.customerType==="DealerBiddingPlatform"||order.customerDetails.customerType==="Dealer") ) {
          calculateVariable("UsedCarSpecialCaseDealer");
          
          if (commissionObj.variable === "StandingDays" &&  calDraft?.onlineCarSaleInfo && calDraft?.onlineCarSaleInfo.standingDays !== undefined) {
            let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
            let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined = calculateRangeValue(standingdays, "UsedCarSpecialCaseDealer",calDraft);
            if (vehicleCommissionDTO != null && commissionObj != null) {
              vc = vehicleCommissionByDiscount(vehicleCommissionDTO,calDraft);
            }
          }
  
        }else if (catObj.type === "UsedCar" && order.customerDetails.customerGroupType==="PrivateCustomer" && (order.vehicleDetails.usedCarCollection==="LeasingTakeover"||order.vehicleDetails.usedCarCollection==="ServiceLoaner")) {
          calculateVariable("UsedCarSpecialCasePrivateCustomer");
          
          if (commissionObj.variable === "StandingDays" && calDraft?.onlineCarSaleInfo && calDraft?.onlineCarSaleInfo.standingDays !== undefined) {
            let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
            let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined = calculateRangeValue(standingdays, "UsedCarSpecialCasePrivateCustomer",calDraft);
            if (vehicleCommissionDTO != null && commissionObj != null) {
              vc = vehicleCommissionByDiscount(vehicleCommissionDTO,calDraft);
            }
          }
        }
        else if (catObj.type === "UsedCar" && catObj.option === "Stock") {
        
          calculateVariable("UsedCarStock");
          
          if (commissionObj.variable === "StandingDays" &&  calDraft?.onlineCarSaleInfo && calDraft?.onlineCarSaleInfo.standingDays !== undefined) {
            let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
            let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined = calculateRangeValue(standingdays, "UsedCarStock",calDraft);
            if (vehicleCommissionDTO != null && commissionObj != null) {
              vc = vehicleCommissionByDiscount(vehicleCommissionDTO,calDraft);
            }
          }
  
        } else{
          calculateVariable("UsedCarStock");
          
          if (commissionObj.variable === "StandingDays" &&  calDraft?.onlineCarSaleInfo && calDraft?.onlineCarSaleInfo.standingDays !== undefined) {
            let standingdays = calDraft?.onlineCarSaleInfo?.standingDays;
            let vehicleCommissionDTO: VehicleCommissionDTO | null | undefined = calculateRangeValue(standingdays, "UsedCarStock",calDraft);
            if (vehicleCommissionDTO != null && commissionObj != null) {
              vc = vehicleCommissionByDiscount(vehicleCommissionDTO,calDraft);
            }
          }
        }
      };
      setCommissionType("ScalingCommission");
      
      if(order?.orderCreator?.share!=undefined ){
      vc=vc*order?.orderCreator?.share/100;
      
      }
      
      if(commissionObj.noProfitCommission && commissionObj.noProfitCommissionReference && calDraft?.calculationHeader?.grossProfit && calDraft?.calculationHeader?.grossProfit < 0){
        
        if(calDraft?.calculationHeader?.grossProfit && commissionObj?.noProfitCommissionValue){
            
          vc=vehicleTotalCommissionByReferenceType(commissionObj?.noProfitCommissionReference,commissionObj?.noProfitCommissionValue,calDraft)
             setCommissionType("NoProfitCommission");
             
             if(calDraft?.commissionCalculation){
              calDraft.commissionCalculation.commissionTypeText="NoProfitCommission";
              calDraft.commissionCalculation.referenceType = commissionObj?.noProfitCommissionReference;
              calDraft.commissionCalculation.referenceValue = commissionObj?.noProfitCommissionValue;
            }
          }
  
      }else if(commissionObj.minCommission && commissionObj?.minCommissionReference && commissionObj?.minCommissionValue &&( vc< vehicleTotalCommissionByReferenceType(commissionObj?.minCommissionReference,commissionObj?.minCommissionValue,calDraft))){
        
        let refCal=vehicleTotalCommissionByReferenceType(commissionObj?.minCommissionReference,commissionObj?.minCommissionValue,calDraft)
        if(vc<refCal){
          vc=refCal
          setCommissionType("MinCommission");
          if(calDraft?.commissionCalculation){
            calDraft.commissionCalculation.commissionTypeText = "MinCommission";
            calDraft.commissionCalculation.referenceType = commissionObj?.minCommissionReference;
            calDraft.commissionCalculation.referenceValue = commissionObj?.minCommissionValue;
          }
          
        }
      }else if(commissionObj.maxCommission && commissionObj?.maxCommissionReference && commissionObj?.maxCommissionValue ){
        let refCal=vehicleTotalCommissionByReferenceType(commissionObj?.maxCommissionReference,commissionObj?.maxCommissionValue,calDraft)
        if (vc>refCal){
          vc=refCal;
          setCommissionType("MaxCommission");
          if(calDraft?.commissionCalculation){
            calDraft.commissionCalculation.commissionTypeText="MaxCommission";
            calDraft.commissionCalculation.referenceType = commissionObj?.maxCommissionReference;
            calDraft.commissionCalculation.referenceValue = commissionObj?.maxCommissionValue;
          }
          
        }
      }
      let wholeObject = orderContext.getCtxReCalculation();
      wholeObject.reCalculation = calDraft;
      setReCalculation(wholeObject);
      orderContext.updateCtxReCalculation(wholeObject);
      
      return parseFloat(vc.toFixed(2));
    };
    const vehicleTotalCommissionByReferenceType = (referenceType:string,value:number,calDraft: CalculationDraftDTO) => {
      let tc = 0.00;
    
      switch (referenceType) {
        case "Euro":
          tc = value;
          break;
  
        case "GrossProfit":
          if (calDraft?.calculationHeader?.grossProfit) {
            tc = calDraft.calculationHeader.grossProfit* (value / 100) ;
          }
          break;
        case "NetTurnover":
          if (calDraft?.vehiclePrices && calDraft?.vehiclePrices[0] != undefined) {
            tc =( value / 100) * calDraft?.vehiclePrices[0]?.amountNet;
          }
          break;
  
      }
    
  
      return tc;
    }
    //need to pass the internal discount/standay as range value
  
    const calculateRangeValue = (rangeValue: number, category: string,calDraft: CalculationDraftDTO) => {
      const filterConf: Array<CategoryCommissionDTO> = scalingobj.commissions.filter(x => x.commissionScalingCategoryType === category);
      if (filterConf.length > 0) {
        const conf = filterConf[0];
        setCommissionObj(conf);
        if(rangeValue>=0){
        for (let i = 0; i < conf.vehicleCommissions.length; i++) {
          if (conf.vehicleCommissions[i].fromValue <= rangeValue && conf.vehicleCommissions[i].toValue >= rangeValue) {
            
            calDraft.referenceType=conf.vehicleCommissions[i].payoutRefence1;
            calDraft.referenceValue=conf.vehicleCommissions[i].payoutValue1;
            if(calDraft.commissionCalculation) {
              calDraft.commissionCalculation.commissionTypeText="ScalingCommission";
              calDraft.commissionCalculation.referenceType = conf.vehicleCommissions[i].payoutRefence1;
              calDraft.commissionCalculation.referenceValue= conf.vehicleCommissions[i].payoutValue1;
            }
            if(conf.payOut2){
              calDraft.payout2=conf.payOut2
              calDraft.referenceType2 = conf.vehicleCommissions[i].payoutRefence2;
              calDraft.referenceValue2 = conf.vehicleCommissions[i].payoutValue2;
              if(calDraft.commissionCalculation) {
                calDraft.commissionCalculation.payout2 = conf.payOut2
                calDraft.commissionCalculation.referenceType2 = conf.vehicleCommissions[i].payoutRefence2;
                calDraft.commissionCalculation.referenceValue2 = conf.vehicleCommissions[i].payoutValue2;
              }
            }
            let wholeObject = orderContext.getCtxReCalculation();
          wholeObject.reCalculation = calDraft;
          if(calDraft.commissionCalculation) setRecalCommissionCalculation(calDraft.commissionCalculation);
          setReCalculation(wholeObject);
          orderContext.updateCtxReCalculation(wholeObject);
          return conf.vehicleCommissions[i];
          }
        }
      }else{
          calDraft.referenceType=conf.vehicleCommissions[0].payoutRefence1;
          calDraft.referenceValue=conf.vehicleCommissions[0].payoutValue1;
          if(calDraft.commissionCalculation){
            calDraft.commissionCalculation.commissionTypeText="ScalingCommission";
            calDraft.commissionCalculation.referenceType = conf.vehicleCommissions[0].payoutRefence1;
            calDraft.commissionCalculation.referenceValue = conf.vehicleCommissions[0].payoutValue1;
          }
          if(conf.payOut2){
            calDraft.payout2=conf.payOut2
            calDraft.referenceType2 = conf.vehicleCommissions[0].payoutRefence2;
            calDraft.referenceValue2 = conf.vehicleCommissions[0].payoutValue2;
            if(calDraft.commissionCalculation) {
              calDraft.commissionCalculation.payout2 = conf.payOut2
              calDraft.commissionCalculation.referenceType2 = conf.vehicleCommissions[0].payoutRefence2;
              calDraft.commissionCalculation.referenceValue2 = conf.vehicleCommissions[0].payoutValue2;
            }
          }
          
            let wholeObject = orderContext.getCtxReCalculation();
            wholeObject.reCalculation = calDraft;
            setReCalculation(wholeObject);
            if(calDraft.commissionCalculation) setRecalCommissionCalculation(calDraft.commissionCalculation);
            orderContext.updateCtxReCalculation(wholeObject);
            return conf.vehicleCommissions[0];
        }
        // if  we wont find value and within category range.
        return null;
      }
  
    }
    const calculateVariable = ( category: string) => {
      
      const filterConf: Array<CategoryCommissionDTO> = scalingobj.commissions.filter(x => x.commissionScalingCategoryType === category);
      
      if (filterConf.length > 0) {
        const conf = filterConf[0];
        setCommissionObj(conf)
        //return null;
      }
  
    }
  
    const vehicleCommissionByDiscount = (vehicleCommission: VehicleCommissionDTO, calDraft: CalculationDraftDTO) => {
      let vc = 0.00;
      
      switch (vehicleCommission.payoutRefence1) {
        case "Euro":
          vc = vehicleCommission.payoutValue1;
          break;
  
        case "GrossProfit":
          if (calDraft?.calculationHeader?.grossProfit) {
            vc = vehicleCommission.payoutValue1 / 100 * calDraft.calculationHeader.grossProfit;
          }
          break;
        case "NetTurnover":
          //vehiclePrices[1] -->selling price net
          if (calDraft?.vehiclePrices && calDraft?.vehiclePrices[0] != undefined) {
            vc = vehicleCommission.payoutValue1 / 100 * calDraft?.vehiclePrices[0]?.amountNet;
          }
          break;
  
      }
      if (commissionObj.payOut2 === true) {
        switch (vehicleCommission.payoutRefence2) {
          case "Euro":
            if (vehicleCommission.payoutValue2) {
              vc += vehicleCommission.payoutValue2;
            }
            break;
  
          case "GrossProfit":
            if (vehicleCommission.payoutValue2 && calDraft?.calculationHeader?.grossProfit) {
              vc += vehicleCommission.payoutValue2 / 100 * calDraft.calculationHeader.grossProfit;
            }
            break;
          case "NetTurnover":
              //vehiclePrices[1] -->selling price net
            if (calDraft?.vehiclePrices && vehicleCommission.payoutValue2 && calDraft?.vehiclePrices[1] != undefined ) {
              vc += vehicleCommission.payoutValue2 / 100 * (calDraft?.vehiclePrices[0]?.amountNet) ;
            }
            break;
        }
      }
      return vc;
    }
    const getInvoiceNet = (cdd: CalculationDraftDTO) => {
      let net = 0.00;
      if (cdd.vehiclePrices) {
        net = cdd.vehiclePrices[0].amountNet
        net += getAdditionalCostsCustomerShareSum(cdd);
        // const upsale = orderContext.getCtxUpsaleDetails();
        // if (upsale.upsaleItems) {
        //   const totals = getUpsaleItemsTotal(upsale.upsaleItems);
        //   net += totals.customerShare;
        // }
        const wcs = cdd.workshopCosts;
        if (wcs && wcs.upsaleItems) {
          const totals = getUpsaleItemsTotal(wcs.upsaleItems);
          net += totals.customerShare;
        }
      }
      return net;
    }
    const getInvoiceGross = (cdd: CalculationDraftDTO) => {
      let invoice = getInvoiceNet(cdd);
      let gross = invoice * (1 + vat / 100);
      if (cdd.additionalCostsPrices) {
        const addCost = cdd.additionalCostsPrices.filter(x => x.type === "Paw");
        if (addCost.length > 0) {
          gross = gross - addCost[0].customerShare * vat / 100;
        }
      }
      return gross;
    }
    const getPredeliveryNet = (cdd: CalculationDraftDTO) => {
      let pdn = 0.00;
      if (cdd.calculationPaymentDetails) {
        switch (order.paymentDetails?.paymentType) {
          case "DebitCredit":
            pdn = getInvoiceNet(cdd) - cdd.calculationPaymentDetails.upFrontPaymentNet;
            break;
          case "Leasing_PFS":
          case "Leasing_Other":
          case "SUBSCRIPTION":
          case "Financing_PFS":
          case "Financing_Other":
            pdn = cdd.calculationPaymentDetails.paymentNet - cdd.calculationPaymentDetails.upFrontPaymentNet;
            pdn += getNotIncludedCustomerShare(cdd);
            break;
        }
  
        if (order.tradeInDetails) {
          if (cdd.tradeInPrices) {
            switch (order.tradeInDetails.tradeInType) {
              case "Financing_Redemption":
              case "Leasing_Redemption":
                // const tip = cdd.tradeInPrices.filter(x => x.tradeInColumn === "ShareCustomer")
                // if (tip.length > 0) {
                //   pdn = pdn - tip[0].amountNet;
                // }
                pdn = pdn - cdd?.tradeInPrices[4].amountNet;
                break;
              case "TradeIn_Regular":
              case "TradeIn_Differential":
                // const tips = cdd.tradeInPrices.filter(x => x.tradeInColumn === "CarValueCustomer")
                // if (tips.length > 0) {
                //   pdn = pdn - tips[0].amountNet;
                // }
                pdn = pdn - cdd?.tradeInPrices[1].amountNet;
                break;
            }
          }
        }
      }
      return pdn;
    }
  
    const getNotIncludedCustomerShare = (cdd: CalculationDraftDTO) => {
      let customerShare = 0.00;
      cdd.additionalCostsPrices?.map((x, i) => {
        const optionals = cdd.calculationPaymentDetails?.includeOptionals;
        if (optionals && optionals?.length > 0) {
          const selected = optionals.filter(option => option === x.type);
          if (selected.length === 0) {
            customerShare += x.customerShare;
          }
        } else {
          customerShare += x.customerShare;
        }
      })
  
      if (cdd.workshopCosts) {
        let usItems = cdd.workshopCosts.upsaleItems;
        usItems?.map((item, i) => {
          const optionals = cdd.calculationPaymentDetails?.includeOptionals;
          if (optionals && optionals?.length > 0) {
            if (item.partType === "tire") {
              const selectedTire = optionals.filter(option => option === "CompleteWinterTires");
              if (selectedTire.length === 0) {
                customerShare += item.customerShare;
              }
  
              item.serviceItems.map((service) => {
                const selectedService = optionals.filter(option => option === service.services.serviceNameUpsale);
                if (selectedService.length === 0) {
                  customerShare += service.customerShare;
                }
              })
            }
  
            if (item.partType === "part") {
              const selectedPart = optionals.filter(option => option === "PDS / Tequipment");
              if (selectedPart.length === 0) {
                customerShare += item.customerShare;
              }
  
              item.serviceItems.map((service) => {
                const selectedService = optionals.filter(option => option === service.services.serviceNameUpsale);
                if (selectedService.length === 0) {
                  customerShare += service.customerShare;
                }
              })
            }
          } else {
            customerShare += item.customerShare;
            item.serviceItems.map((service) => {
              customerShare += service.customerShare;
            })
          }
        })
      }
      return customerShare;
    }
  
    const getIncludedCustomerShare = (cdd: CalculationDraftDTO) => {
      let customerShare = 0.00;
      cdd.additionalCostsPrices?.map((x, i) => {
        const optionals = cdd.calculationPaymentDetails?.includeOptionals;
        if (optionals && optionals?.length > 0) {
          const selected = optionals.filter(option => option === x.type);
          if (selected.length > 0) {
            customerShare += x.customerShare;
          }
        }
      })
  
      if (cdd.workshopCosts) {
        let usItems = cdd.workshopCosts.upsaleItems;
        usItems?.map((item, i) => {
          const optionals = cdd.calculationPaymentDetails?.includeOptionals;
          if (optionals && optionals?.length > 0) {
            if (item.partType === "tire") {
              const selectedTire = optionals.filter(option => option === "CompleteWinterTires");
              if (selectedTire.length > 0) {
                customerShare += item.customerShare;
              }
  
              item.serviceItems.map((service) => {
                const selectedService = optionals.filter(option => option === service.services.serviceNameUpsale);
                if (selectedService.length > 0) {
                  customerShare += service.customerShare;
                }
              })
            }
  
            if (item.partType === "part") {
              const selectedPart = optionals.filter(option => option === "PDS / Tequipment");
              if (selectedPart.length > 0) {
                customerShare += item.customerShare;
              }
  
              item.serviceItems.map((service) => {
                const selectedService = optionals.filter(option => option === service.services.serviceNameUpsale);
                if (selectedService.length > 0) {
                  customerShare += service.customerShare;
                }
              })
            }
          }
        })
      }
      return customerShare;
    }
    const getAdditionalCostsCustomerShareSum = (cdd: CalculationDraftDTO) => {
      let sum = 0.00;
      if (cdd.additionalCostsPrices) {
        cdd.additionalCostsPrices.map((x, i) => {
          sum += x.customerShare;
        })
      }
      return sum;
    }
    const updateSeller = () => {
      // CalDraft point to recalutaion
      let wholeObject = orderContext.getCtxReCalculation();
      let calDraft = wholeObject.reCalculation;
      
      if (calDraft.commissionCalculation && calDraft.commissionCalculation.vehicleCommission) {
        let vc = 0.00;
        if (calDraft.calculationHeader && calDraft.commissionCalculation) {
          vc = calDraft.commissionCalculation.vehicleCommissionEuro ? calDraft.commissionCalculation.vehicleCommissionEuro : 0.00;
        }
  
        calDraft.commissionSharing?.map((sharing, i) => {
          switch (i) {
            case 0:
              if (csRef[0] && csRef[0].euro && csRef[0].euro.current) {
                sharing.shareInEuro = vc * sharing.shareInPercent / 100;
                csRef[0].euro.current.value = formatCurrencyWithNoUnit(sharing.shareInEuro);
              }
              if (csRef[0] && csRef[0].percent && csRef[0].percent.current) {
  
                csRef[0].percent.current.value = formatCurrencyWithNoUnit(sharing.shareInPercent);
              }
              break;
            case 1:
              if (csRef[1] && csRef[1].euro && csRef[1].euro.current) {
                sharing.shareInEuro = vc * sharing.shareInPercent / 100;
                csRef[1].euro.current.value = formatCurrencyWithNoUnit(sharing.shareInEuro);
              }
              if (csRef[1] && csRef[1].percent && csRef[1].percent.current) {
                csRef[1].percent.current.value = formatCurrencyWithNoUnit(sharing.shareInPercent);
              }
              break;
            case 2:
              if (csRef[2] && csRef[2].euro && csRef[2].euro.current) {
                sharing.shareInEuro = vc * sharing.shareInPercent / 100;
                csRef[2].euro.current.value = formatCurrencyWithNoUnit(sharing.shareInEuro);
              }
              if (csRef[2] && csRef[2].percent && csRef[2].percent.current) {
                csRef[2].percent.current.value = formatCurrencyWithNoUnit(sharing.shareInPercent);
              }
              break;
          }
        })
      }
  
      wholeObject.reCalculation = calDraft; 
      orderContext.updateCtxReCalculation(wholeObject);
      setReCalculation(wholeObject);
      //calculateHeader(wholeObject.reCalculation);
    }

    const getGrossProfitPercent = () => {
      let rgppc = 0.00;
      let gppc = 0.00;
  
      if(vehiclePrices && vehiclePrices.length > 0 && finalCalculation) {
        gppc = vehiclePrices[0].amountNet !== undefined && vehiclePrices[0].amountNet > 0 ? finalCalculation.grossProfit/vehiclePrices[0].amountNet * 100 : 0.00;
      }
  
      if(recalvehiclePrices && recalvehiclePrices.length > 0 && recalFinalCalculation) {
        rgppc = recalvehiclePrices[0].amountNet !== undefined && recalvehiclePrices[0].amountNet > 0  ? recalFinalCalculation.grossProfit/recalvehiclePrices[0].amountNet * 100 : 0.00;
      }
      
      setGrosspercent(rgppc - gppc);
      return (rgppc - gppc);
    }

    return (
    <>
      <div className="order-content">
      <div className="gross subsequent-header" style={{ position: "fixed", zIndex: 3, marginTop: "14px", padding:'15px'}}>
        {subsequentActive && 
         <PFlex justifyContent={"space-between"}>
           <PFlexItem style={{width:'140px'}}>
        <PFlex direction={'column'}>
              <span className='dcs-heading'>{t('Recalculation')}</span>
              <span className='dcs-heading'>{t('Subsequent change')}</span>
        </PFlex>
          </PFlexItem>
         <PFlexItem>
         <PFlex direction={'column'}>
         <PText><span className='dcs-title'>{t("Gross profit") + ": "}</span>
              
              <span className='dcs-heading'>
                {`${ recalData  && recalData?.reCalculation && recalData?.reCalculation.vehiclePrices && recalData?.reCalculation.calculationHeader?.grossProfit && recalData?.reCalculation?.vehiclePrices[0].amountNet > 0
                  ? formatCurrencyWithNoUnit(recalData?.reCalculation.calculationHeader?.grossProfit  / recalData?.reCalculation?.vehiclePrices[0].amountNet * 100  ) 
                  : "0,00"
                  } %`}
              </span>
              <span className='dcs-title'>&nbsp;{` ${ recalData   && recalData?.reCalculation  && recalData?.reCalculation.calculationHeader?.grossProfit && formatCurrencyWithNoUnit(recalData?.reCalculation.calculationHeader?.grossProfit ? recalData?.reCalculation.calculationHeader?.grossProfit : 0.00)} €  `}</span></PText>

           <PText><span className='dcs-title'>{t("Gross profit") + ": "}</span>
             <span className='dcs-heading'>
               {`${recalCalculationHeader?.grossProfit && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0
                 ? formatCurrencyWithNoUnit(recalCalculationHeader.grossProfit / recalvehiclePrices[0].amountNet * 100)
                 : "0,00"
                 } %`}
             </span>
             <span className='dcs-title'>&nbsp;{` ${formatCurrencyWithNoUnit(recalCalculationHeader?.grossProfit ? recalCalculationHeader?.grossProfit : 0.00)} €  `}</span></PText>
             </PFlex>
         </PFlexItem>
         <PFlexItem>
         <PFlex direction={'column'}>
         <PText><span className='dcs-title'>{t("External discount") + ": "}</span>
              <span className='dcs-heading'>
                {`${ recalData   && recalData?.reCalculation && recalData?.reCalculation.vehiclePrices && recalData?.reCalculation.calculationHeader?.externalDiscount && recalData?.reCalculation?.vehiclePrices[0].amountNet &&  recalData?.reCalculation?.vehiclePrices[0].amountNet > 0
                  ? formatCurrencyWithNoUnit(recalData?.reCalculation.calculationHeader?.externalDiscount / recalData?.reCalculation.vehiclePrices[0].amountNet * 100)

                  // ? formatCurrencyWithNoUnit(preSubsequentHeaderValues.externalDiscount / recalvehiclePrices[2].amountNet * 100)
                  : "0,00"  
                  } %`}
              </span>
              <span className='dcs-title'>&nbsp;{` ${ recalData   && recalData?.reCalculation  && recalData?.reCalculation.calculationHeader?.externalDiscount ?  formatCurrencyWithNoUnit( Number(recalData?.reCalculation.calculationHeader?.externalDiscount) ):0.00} €`}</span></PText>

           <PText><span className='dcs-title'>{t("External discount") + ": "}</span>
             <span className='dcs-heading'>
               {`${recalCalculationHeader?.externalDiscount && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0
                 ? formatCurrencyWithNoUnit(recalCalculationHeader.externalDiscount /recalvehiclePrices[0].amountNet * 100)
                 : "0,00"
                 } %`}
             </span>
             <span className='dcs-title'>&nbsp;{` ${formatCurrencyWithNoUnit(recalCalculationHeader?.externalDiscount ? recalCalculationHeader?.externalDiscount : 0.00)} €  `}</span></PText>
            </PFlex>  
         </PFlexItem>
         <PFlexItem>
         <PFlex direction={'column'}>
         <PText>
              <span className='dcs-title'>{t("Internal discount") + ": "}</span>
              <span className='dcs-heading'>
                {`${ recalData   && recalData?.reCalculation && recalData?.reCalculation.vehiclePrices && recalData?.reCalculation.calculationHeader?.internalDiscount && recalData?.reCalculation?.vehiclePrices[0].amountNet && recalData?.reCalculation?.vehiclePrices[0].amountNet > 0
                  ? formatCurrencyWithNoUnit(recalData?.reCalculation.calculationHeader?.internalDiscount / recalData?.reCalculation.vehiclePrices[0].amountNet * 100)
                  : "0,00"
                  } %`}
              </span>
              <span className='dcs-title'>&nbsp;{` ${recalData  && recalData?.reCalculation  && recalData?.reCalculation.calculationHeader?.internalDiscount ?  formatCurrencyWithNoUnit(recalData?.reCalculation.calculationHeader?.internalDiscount): "0,00"} €  `}</span>
            </PText>
           <PText>
             <span className='dcs-title'>{t("Internal discount") + ": "}</span>
             <span className='dcs-heading'>
               {`${recalCalculationHeader?.internalDiscount && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0
                 ? formatCurrencyWithNoUnit(recalCalculationHeader?.internalDiscount / recalvehiclePrices[0].amountNet * 100)
                 : "0,00"
                 } %`}
             </span>
             <span className='dcs-title'>&nbsp;{` ${formatCurrencyWithNoUnit(recalCalculationHeader?.internalDiscount ? recalCalculationHeader?.internalDiscount : 0.00)} €  `}</span>
           </PText>
           </PFlex>
         </PFlexItem>
         <PFlexItem>
         <PFlex direction={'column'}>
         <PText>
              <span className='dcs-title'>{t("Total commission") + ": "}</span>
              <span className='dcs-heading'>
                { canViewCommission && (
                  <>
                  {commissionCalculation.totalCommission
                    ? `${formatCurrencyWithNoUnit(commissionCalculation.totalCommission)} €`
                    : "0.00 €"
                  }
                  </>
                )}
                {/* {`${formatCurrencyWithNoUnit(tc)} €`} */}
              </span>
            </PText>
           <PText>
             <span className='dcs-title'>{t("Total commission" + ": ")}</span>
             <span className='dcs-heading'>
               { canViewCommission && (
                 <>
                   { recalCommissionCalculation?.totalCommission
                     ? `${formatCurrencyWithNoUnit(recalCommissionCalculation?.totalCommission)} €`
                     : "0.00 €"
                   }
                 </>
               )}
             </span>
           </PText>
           </PFlex>
         </PFlexItem>
       </PFlex>}
      {!subsequentActive &&  
      // <PFlex justifyContent={"space-between"}>
      //     <PFlexItem>
      //       <PText><span className='dcs-title'>{t("Gross profit") + ": "}</span>
      //         <span className='dcs-heading'>
      //           {`${recalCalculationHeader?.grossProfit && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0
      //             ? formatCurrencyWithNoUnit(recalCalculationHeader.grossProfit / recalvehiclePrices[0].amountNet * 100)
      //             : "0,00"
      //             } %`}
      //         </span>
      //         <span className='dcs-title'>&nbsp;{` ${formatCurrencyWithNoUnit(recalCalculationHeader?.grossProfit ? recalCalculationHeader?.grossProfit : 0.00)} €  `}</span></PText>
      //     </PFlexItem>
      //     <PFlexItem>
      //       <PText><span className='dcs-title'>{t("External discount") + ": "}</span>
      //         <span className='dcs-heading'>
      //           {`${recalCalculationHeader?.externalDiscount && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0
      //             ? formatCurrencyWithNoUnit(recalCalculationHeader.externalDiscount /recalvehiclePrices[0].amountNet * 100)
      //             : "0,00"
      //             } %`}
      //         </span>
      //         <span className='dcs-title'>&nbsp;{` ${formatCurrencyWithNoUnit(recalCalculationHeader?.externalDiscount ? recalCalculationHeader?.externalDiscount : 0.00)} €  `}</span></PText>
      //     </PFlexItem>
      //     <PFlexItem>
      //       <PText>
      //         <span className='dcs-title'>{t("Internal discount") + ": "}</span>
      //         <span className='dcs-heading'>
      //           {`${recalCalculationHeader?.internalDiscount && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0
      //             ? formatCurrencyWithNoUnit(recalCalculationHeader?.internalDiscount / recalvehiclePrices[0].amountNet * 100)
      //             : "0,00"
      //             } %`}
      //         </span>
      //         <span className='dcs-title'>&nbsp;{` ${formatCurrencyWithNoUnit(recalCalculationHeader?.internalDiscount ? recalCalculationHeader?.internalDiscount : 0.00)} €  `}</span>
      //       </PText>
      //     </PFlexItem>
      //     <PFlexItem>
      //       <PText>
      //         <span className='dcs-title'>{t("Total comission" + ": ")}</span>
      //         <span className='dcs-heading'>
      //           {/* { recalCalculationHeader?.totalCommission
      //             ? `${formatCurrencyWithNoUnit(recalCalculationHeader.totalCommission)} €`
      //             : "0.00 €"
      //           } */}
      //           { canViewCommission && (
      //             <>
      //               { recalCommissionCalculation?.totalCommission
      //                 ? `${formatCurrencyWithNoUnit(recalCommissionCalculation?.totalCommission)} €`
      //                 : "0.00 €"
      //               }
      //             </>
      //           )}
      //         </span>
      //       </PText>
      //     </PFlexItem>
      //   </PFlex>
      <PFlex justifyContent={"space-between"}>
          <PFlexItem style={{width: "30%"}}>
            <PFlex justifyContent={"space-between"}>
              <PFlexItem>
                <PText><span className='dcs-title'>{t("Gross profit") + ": "}</span>
                <span className='dcs-heading'>
                  {`${recalCalculationHeader?.grossProfit && recalvehiclePrices && recalvehiclePrices[0].amountNet && recalvehiclePrices[0].amountNet > 0
                      ? formatCurrencyWithNoUnit(recalCalculationHeader.grossProfit / recalvehiclePrices[0].amountNet * 100)
                      : "0,00"
                  } %`}
                </span>
                <span className='dcs-title'>&nbsp;{` ${formatCurrencyWithNoUnit(recalCalculationHeader?.grossProfit ? recalCalculationHeader?.grossProfit : 0.00)} €  `}</span></PText>
              </PFlexItem>
              <PFlexItem>
                <PText><span className='dcs-title'>{t("Total costs" + ": ") }</span> 
                <span className='dcs-heading'>{` ${formatCurrencyWithNoUnit(recalCalculationHeader?.totalCosts ? recalCalculationHeader?.totalCosts : 0.00)} €  `}</span></PText>
              </PFlexItem>
            </PFlex>
          </PFlexItem>
          <PFlexItem>
            <PText>
              <span className='dcs-title'>{t("Total comission" + ": ")}</span> 
              <span className='dcs-heading'>
                { canViewCommission && (
                    <>
                    { recalCommissionCalculation?.totalCommission
                      ? `${formatCurrencyWithNoUnit(negativeValueHandler(recalCommissionCalculation?.totalCommission))} €`
                      : "0.00 €"
                    }
                    </>
                )}
              </span>
            </PText>
          </PFlexItem>
        </PFlex>
        
        }
      </div>
      <div style={{ marginTop: "97px" }}>


        </div>

        <div className="Recal-card vehicle-price" style={{marginTop: subsequentActive? "122px":"110px"}}>
          <div>
            <PorscheFont style={{ paddingLeft: "20px", paddingRight: "20px" }}><span className="dcs-section-heading">{t("Vehicle Price")}</span></PorscheFont>&ensp;
            <div style={{ backgroundColor: "#FFFFFF" }}>
              {/* Header block start */}
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont style={{ paddingBottom: "4px" }}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont style={{ paddingBottom: "4px" }}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
              {/* Header block End */}
              {/* Divider Block Start */}
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={9}>
                      <PDivider style={{ backgroundColor: "#C8CACB", height: "2px" }}></PDivider>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={9}>
                      <PDivider style={{ backgroundColor: "#909090", height: "2px" }}></PDivider>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
              {/* Divider Block End */}
            </div>
            {/* Sub headers section Start*/}
            <div style={{ backgroundColor: "#FFFFFF", height: "46px" }}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header1">{t("Amount (net)")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header1">{t("Amount (gross)")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                      {/* <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header1">{t("Percentage")}</span></PorscheFont>
                      </div>                     */}
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (gross)")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      {/* <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header2">{t("Percentage")}</span></PorscheFont>
                      </div>  */}
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "8px", textAlign: "right", paddingRight: "20px" }} >
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                              <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                              <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
            </div>
            {/* Sub headers section End*/}
            {/* Gray Box div  */}
            {vehiclePrices&& vehiclePrices.length>0 && recalvehiclePrices&& recalvehiclePrices.length>0 && (<>
            <div style={{ backgroundColor: "#FAFAFA", height: "56px" }}>
              <PGrid >
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("Selling price")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(vehiclePrices[0].amountNet)}{" €"}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(vehiclePrices[0].amountGross)}{" €"}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="vpon0" unit="€" unitPosition="suffix" >
                        <input
                          aria-autocomplete='none'
                          autoComplete='off'
                          id="ivpon0"
                          ref={vp[0].net}
                          key="ivpon0"
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet ? recalvehiclePrices[0].amountNet : 0.00)}
                          style={{ textAlign: "right" }}
                          readOnly
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="vpbpn0" unit="€" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete='none'
                          autoComplete='off'
                          id="ivpog0"
                          key="ivpog0"
                          ref={vp[0].gross}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(recalvehiclePrices[0].amountGross)}
                          //onChange={(e) =>  handleAmountGross(e.target.value,0, "vpog0")}
                          onBlur={(e) => {
                            handleAmountGross(e.target.value,0, "vpog0")
                            if(vp[0].gross.current) vp[0].gross.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[0].amountGross ? recalvehiclePrices[0].amountGross : 0.00)
                          }
                        }
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} className='dcs-heading'>
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                          <Stack direction="row" spacing={1}>
                                {(recalvehiclePrices[0].amountNet-vehiclePrices[0].amountNet)!==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet-vehiclePrices[0].amountNet)}{" €"}</PorscheFont>
                                    { (recalvehiclePrices[0].amountNet-vehiclePrices[0].amountNet) > 0?(
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                )}
                                {(recalvehiclePrices[0].amountNet-vehiclePrices[0].amountNet) === 0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{"0,00 €"}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                  </>
                                  )
                                }
                                </Stack>
                              </PFlexItem>
                            </PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
              </PGrid>
            </div>
            {/* White Box div  */}
            <div style={{ backgroundColor: "#FFFFFF", height: "56px" }}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("Purchase price")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(vehiclePrices[1].amountNet)}{" €"}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      {/* <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(vehiclePrices[1].amountGross)}{" €"}</span></PorscheFont>
                      </div> */}
                    </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="vpon1" unit="€" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete='none'
                          autoComplete='off'
                          id="ivpon1"
                          key="ivpon1"
                          ref={vp[1].net}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(recalvehiclePrices[1].amountNet ? recalvehiclePrices[1].amountNet : 0.00)}
                          //onChange={ (e) => handleAmountNet(e.target.value, 1, "vpon1")}
                          onBlur={(e) => {
                              handleAmountNet(e.target.value, 1, "vpon1")
                              if(vp[1].net.current) vp[1].net.current.value = formatCurrencyWithNoUnit(recalvehiclePrices[1].amountNet ? recalvehiclePrices[1].amountNet : 0.00)
                            }
                          }
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} className='dcs-heading'>
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                              {(recalvehiclePrices[1].amountNet-vehiclePrices[1].amountNet)!==0 && (
                                <>
                                  <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalvehiclePrices[1].amountNet-vehiclePrices[1].amountNet)}{" €"}</PorscheFont>
                                  { (recalvehiclePrices[1].amountNet-vehiclePrices[1].amountNet) > 0?(
                                    <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                  }
                                </>
                              )}
                              {(recalvehiclePrices[1].amountNet-vehiclePrices[1].amountNet) === 0 && (
                                <>
                                  <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{"0,00 €"}</PorscheFont>
                                  <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                </>
                                )
                              }
                            </Stack>
                          </PFlexItem>
                        </PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
              </PGrid>
            </div>
           
            <div style={{ backgroundColor: "#FFFFFF", height: "52px" }}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px",color:"#909090" }}><span>{t("Taxation")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                     
                    </PGridItem>
                    <PGridItem size={3}>
                     </PGridItem>
                    <PGridItem size={3}>
                      
                    </PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "left",marginLeft:"-20px" }}>
                        <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px",color:"#313639" }}><span>{t("Taxation")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                     
                    </PGridItem>
                    <PGridItem size={3}>
                  
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
            </div>
            {/* White Box div  */}
            <div style={{ backgroundColor: "#FFFFFF", height: "52px",marginLeft:"20px",marginRight:"20px" }}>
              <PGrid style={{marginTop:"-15px"}}>
                <PGridItem size={6}>
                  <PGrid>
                  <PGridItem size={3}>
                    <div
                          className={`mt12 include-btn ${vehiclePrices && vehiclePrices[0].taxation === "Regular" ? "include-btn-selected" : ""} disabled`}
                          style={{ paddingRight: "6px", textAlign: "center", height: "48px", paddingTop: "3px"}}
                        
                        >
                          <PorscheFont className='dcs-title'>{t("Regular")}</PorscheFont>
                        </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    <div
                          className={`mt12 include-btn ${vehiclePrices && vehiclePrices[0].taxation === "Differential" ? "include-btn-selected" : ""} disabled `}
                          style={{ paddingRight: "6px", textAlign: "center", marginLeft: "-15px", height: "48px", paddingTop: "3px" }}
                        
                        >
                          <PorscheFont className='dcs-title'>{t("Differential")}</PorscheFont>
                        </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div
                         className={`mt12 include-btn ${recalvehiclePrices && recalvehiclePrices[0].taxation === "Regular" ? "include-btn-selected" : ""} `}
                          style={{ paddingRight: "6px", textAlign: "center", height: "48px", paddingTop: "3px"}}
                          onClick={() => handleVehicleTaxation("Regular")}
                        >
                          <PorscheFont className='dcs-title'>{t("Regular")}</PorscheFont>
                        </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    <div
                          className={`mt12 include-btn ${recalvehiclePrices && recalvehiclePrices[0].taxation === "Differential" ? "include-btn-selected" : ""}  `}
                          style={{ paddingRight: "6px", textAlign: "center", marginLeft: "-15px", height: "48px", paddingTop: "3px" }}
                          onClick={() => handleVehicleTaxation("Differential")}
                        >
                          <PorscheFont className='dcs-title'>{t("Differential")}</PorscheFont>
                        </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
            </div>
            </>)}
          </div>
        </div>


        {/** Information from online car sales **/}
        <div className="Recal-card vehicle-price">
          <div>
            <PorscheFont><span className="dcs-section-heading" style={{ marginLeft: "20px" }}>{t("Information from Online Car Sales")}</span></PorscheFont>&ensp;
            <div style={{ backgroundColor: "#FFFFFF" }}>
              {/* Header block start */}
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont style={{ paddingBottom: "4px" }}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont style={{ paddingBottom: "4px" }}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
              {/* Header block End */}
              {/* Divider Block Start */}
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={9}>
                      <PDivider style={{ backgroundColor: "#C8CACB", height: "2px" }}></PDivider>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={9}>
                      <PDivider style={{ backgroundColor: "#909090", height: "2px" }}></PDivider>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
              {/* Divider Block End */}
            </div>
            {/* Sub headers section Start*/}
            <div style={{ backgroundColor: "#FFFFFF", height: "46px" }}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px", color: "#909090" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Information")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Information")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                     
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "8px", textAlign: "right", paddingRight: "20px" }} >
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                              <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                              <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
            </div>
            {/* Sub headers section End*/}
            {/* Gray Box div  */}
            {onlineCarSaleInfo && recalOnlineCarSaleInfo && (<>
            <div style={{ backgroundColor: "#FAFAFA", height: "56px" }}>
              <PGrid >
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("Standing days")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatToWholeNumber( onlineCarSaleInfo?.standingDays ? onlineCarSaleInfo?.standingDays : 0)}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      
                    </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id="vpbpn0" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete='none'
                          autoComplete='off'
                          ref={standingRef}
                          id={`ioisd`}
                          key={`koisd`}
                          type="text"
                          defaultValue={formatToWholeNumber( recalOnlineCarSaleInfo?.standingDays ? recalOnlineCarSaleInfo.standingDays : 0)}
                          //onChange={ (e) => handleStandingDays(e.target.value, "oisd")}
                          onBlur={(e) => {
                            handleStandingDays(e.target.value, "oisd")
                            if(standingRef.current) standingRef.current.value = formatToWholeNumber(recalOnlineCarSaleInfo?.standingDays ? recalOnlineCarSaleInfo.standingDays : 0)
                          }
                        }   
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                     
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} className='dcs-heading'>
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                                { onlineCarSaleInfo?.standingDays !== undefined && recalOnlineCarSaleInfo?.standingDays !== undefined &&
                                  (recalOnlineCarSaleInfo?.standingDays - onlineCarSaleInfo?.standingDays) !== 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                        {formatToWholeNumber(recalOnlineCarSaleInfo?.standingDays - onlineCarSaleInfo?.standingDays)}
                                      </PorscheFont>
                                      { (recalOnlineCarSaleInfo?.standingDays - onlineCarSaleInfo?.standingDays) < 0 
                                        ? (<img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>) 
                                        : ( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )
                                }
                                { onlineCarSaleInfo?.standingDays !== undefined && recalOnlineCarSaleInfo?.standingDays !== undefined &&
                                  (recalOnlineCarSaleInfo?.standingDays - onlineCarSaleInfo?.standingDays) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                    </>
                                  )
                                }
                            </Stack>
                          </PFlexItem>
                        </PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
              </PGrid>
            </div>
            {/* White Box div  */}
            <div style={{ backgroundColor: "#FFFFFF", height: "56px" }}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("Repair costs")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit( onlineCarSaleInfo?.repairCosts ? onlineCarSaleInfo?.repairCosts : 0.00 )}{" €"}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      
                    </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="rc" unit="€" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete='none'
                          autoComplete='off'
                          ref={repairRef}
                          id={`irc`}
                          key={`krc`}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.repairCosts ? recalOnlineCarSaleInfo?.repairCosts : 0.00)}
                          //onChange={ (e) => handleOnlineRepair(e.target.value)}
                          onBlur={(e) => {
                              handleOnlineRepair(e.target.value)
                              if(repairRef.current) repairRef.current.value = formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.repairCosts ? recalOnlineCarSaleInfo?.repairCosts : 0.00)
                            }
                          }
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                      
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} className='dcs-heading'>
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                          <Stack direction="row" spacing={1}>
                                {(recalOnlineCarSaleInfo?.repairCosts!-onlineCarSaleInfo?.repairCosts!)!==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.repairCosts!-onlineCarSaleInfo?.repairCosts!)}{" €"}</PorscheFont>
                                    { (recalOnlineCarSaleInfo?.repairCosts!-onlineCarSaleInfo?.repairCosts!) > 0?(
                                      <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                )}
                                {(recalOnlineCarSaleInfo?.repairCosts!-onlineCarSaleInfo?.repairCosts!) === 0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.repairCosts!-onlineCarSaleInfo?.repairCosts!)}{" €"}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                  </>
                                  )
                                }
                                </Stack>
                              </PFlexItem></PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
              </PGrid>
            </div>
            {/* Gray Box div  */}
            <div style={{ backgroundColor: "#FAFAFA", height: "56px" }}>
              <PGrid >
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("Last gross bidding price")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit( onlineCarSaleInfo?.lastGrossBiddingPrice ? onlineCarSaleInfo?.lastGrossBiddingPrice : 0.00 )}{" €"}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      
                    </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id="lgbp" unit="€" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete='none'
                          autoComplete='off'
                          id={`ilgbp`}
                          key={`klgbp`}
                          type="text"
                          ref={lgbRef}
                          defaultValue={formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.lastGrossBiddingPrice ? recalOnlineCarSaleInfo?.lastGrossBiddingPrice : 0.00)}
                          //onChange={ (e) => handleBiddingPrice(e.target.value, "lgbp")}
                          onBlur={(e) => {
                              handleBiddingPrice(e.target.value, "lgbp")
                              if(lgbRef.current) lgbRef.current.value = formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.lastGrossBiddingPrice ? recalOnlineCarSaleInfo?.lastGrossBiddingPrice : 0.00)
                            }
                          }
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                     
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} className='dcs-heading'>
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                          <Stack direction="row" spacing={1}>
                                {(recalOnlineCarSaleInfo?.lastGrossBiddingPrice!-onlineCarSaleInfo?.lastGrossBiddingPrice!)!==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.lastGrossBiddingPrice!-onlineCarSaleInfo?.lastGrossBiddingPrice!)}{" €"}</PorscheFont>
                                    { (recalOnlineCarSaleInfo?.lastGrossBiddingPrice!-onlineCarSaleInfo?.lastGrossBiddingPrice!) > 0?(
                                      <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                )}
                                {(recalOnlineCarSaleInfo?.lastGrossBiddingPrice!-onlineCarSaleInfo?.lastGrossBiddingPrice!) === 0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalOnlineCarSaleInfo?.lastGrossBiddingPrice!-onlineCarSaleInfo?.lastGrossBiddingPrice!)}{" €"}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                  </>
                                  )
                                }
                                </Stack>
                              
                              
                              </PFlexItem></PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
              </PGrid>
            </div>
            <div style={{ backgroundColor: "#FFFFFF", height: "56px" }}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("Buyer")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{onlineCarSaleInfo?.buyer?.ppnName}</span></PorscheFont>
                       
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      
                    </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={5}>
                    <PSelectWrapper label={`${t("Purchaser")}`} hideLabel={true} style={{ marginTop: "0px" }}>
                  <select name="purchaser" required onChange={(e) => handlePurchaser(e.target.value)} disabled={disabled}>
                    <option key="default" value={0}>{t("Select purchaser")}</option>
                    {purchasers.map((user, i) => {
                      return (
                        <option key={user.id} value={user.id} selected={user.id === recalOnlineCarSaleInfo?.buyer?.id}>{user.ppnName}</option>
                      )
                    })
                    }
                  </select>
                </PSelectWrapper>
                    </PGridItem>
                    <PGridItem size={4}>
                    </PGridItem>
                    <PGridItem size={3}>
                      
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
              </PGrid>
            </div>
            </>)}
          </div>
          {/* Comment Section Start*/}
         {onlineCarSaleInfo && recalOnlineCarSaleInfo && (<><div style={{ backgroundColor: "#FFFFFF", height: "79px" }}>
            <PGrid>
              <PGridItem size={6}>
                <div style={{ textAlign: "left", paddingLeft: "20px" }}>
                  <Stack direction="column">
                    <PorscheFont><span className="sub-header1">{t("Comment (Optional)")}</span></PorscheFont>
                    <PorscheFont><span className="sub-header2">{onlineCarSaleInfo?.comments}</span></PorscheFont>
                  </Stack>
                </div>
              </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={9}>
                    <div style={{ marginTop: "-4px" }}>
                      <PTextFieldWrapper id="comment" label={`${t("Comment (optional)")}`}>
                        <input
                          aria-autocomplete='none'
                          autoComplete='off'
                          id={`icomment`}
                          key={`kcomment`}
                          type="text"
                          defaultValue={recalOnlineCarSaleInfo?.comments ? recalOnlineCarSaleInfo?.comments : ""}
                          onChange={ (e) => handleOnlineComment(e.target.value)}
                        />
                      </PTextFieldWrapper>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                </PGrid>&ensp;
              </PGridItem>
            </PGrid>
          </div></>)}
        
          {/* Comment Section End*/}

        </div>
        {/* Discount Start */}
        <div className="Recal-card vehicle-price">
          <div style={{paddingBottom:"16px"}}>
            <>
              <PorscheFont style={{paddingLeft:"20px",paddingRight:"20px"}}><span className="dcs-section-heading">{t("Discount (net)")}</span></PorscheFont>&ensp;
              <div style={{ backgroundColor: "#FFFFFF"}}>
                {/* Header block start */}
                  <PGrid>
                    <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      </PGridItem>
                      <PGridItem size={3}>                  
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "center" }}>
                          <PorscheFont  style={{paddingBottom:"4px"}}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>                                    
                      </PGridItem>
                    </PGrid>
                    </PGridItem>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>                   
                      </PGridItem>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                          <PorscheFont  style={{paddingBottom:"4px"}}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={3}>                  
                      </PGridItem>
                      <PGridItem size={3}>                 
                      </PGridItem>
                    </PGrid>        
                  </PGridItem>
                  </PGrid>
                {/* Header block End */}
                {/* Divider Block Start */}
                  <PGrid>
                    <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      </PGridItem>                    
                      <PGridItem size={9}>
                          <PDivider style={{backgroundColor:"#C8CACB",height:"2px"}}></PDivider>
                        </PGridItem>                   
                    </PGrid>
                    </PGridItem>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={9}>                     
                      <PDivider style={{backgroundColor:"#909090",height:"2px"}}></PDivider>                                    
                      </PGridItem>                  
                      <PGridItem size={3}>                 
                      </PGridItem>
                    </PGrid>        
                  </PGridItem>
                  </PGrid>
                {/* Divider Block End */}
              </div>         
              {/* Sub headers section Start*/}
              <div style={{ backgroundColor: "#FFFFFF", height: "46px"}}>
                <PGrid>
                  <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"8px"}}><span className="sub-header1">{t("Discount in €")}</span></PorscheFont>
                    </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header1">{t("Discount in %")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>                                     
                    </PGridItem>
                  </PGrid>&ensp;
                  </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"8px"}}><span className="sub-header2">{t("Discount in €")}</span></PorscheFont>
                    </div>
                        </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header2">{t("Discount in %")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>                   
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{textAlign: "right"}}>
                      <PorscheFont style={{marginTop:"8px",paddingRight:"40px"}}><span className="sub-header2">{t("Deviation")}</span></PorscheFont>
                    </div> 
                    </PGridItem>
                  </PGrid>       
                </PGridItem>
                </PGrid>
              </div>
              {/* Sub headers section End*/}   
              {discountNetPrices?.map((item, i) => {
                if (i === 0 && order.referral === null) return <></>;

                if (item.displayLabel === "Referral commission") {
                  if (order.referral) {
                    if (order.referral.referralType === "Internal_Empl") {
                      return <></>;
                    }
                  } else {
                    return <></>;
                  }
                }  
                {/* Gray Box div  */}
                return( 
                  <div style={{ backgroundColor: `${getDiscountColorCode(i)}`,height: "56px"}}>                
                    <PGrid>
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "left" }}>
                              <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span className="sub-header2">{t(labels.usedCar[i])}{""}</span></PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(item.discountInEuro)}{" €"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "right" }}>
                              <PorscheFont  style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(item.discountInPercent)}{" %"}</span></PorscheFont>
                            </div></PGridItem>
                          <PGridItem size={3}></PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                          <PTextFieldWrapper style={{marginTop: "4px", height: "40px" }}  id={`dnde${i}`}  unit="€" unitPosition="suffix" showCharacterCount={false}>
                                <input
                                  maxLength={inputFieldMaxLength}
                                  aria-autocomplete='none'
                                  autoComplete='off'                            
                                  id={`idnde${i}`}
                                  key={`idnde${i}`}
                                  type="text"
                                  ref={dref[i].euro}
                                  defaultValue={formatCurrencyWithNoUnit(recalDiscountNetPrices?.[i].discountInEuro)}
                                  //onChange={(e) => handleDiscountEuro(e.target.value, i, `idnde${i}`)}
                                  onBlur={(e) => {
                                      handleDiscountEuro(e.target.value, i, `idnde${i}`)
                                      if(i === 0 && dref[0].euro.current) dref[0].euro.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices?.[0].discountInEuro)
                                      if(i === 1 && dref[1].euro.current) dref[1].euro.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices?.[1].discountInEuro)
                                    }
                                  }
                                  style={{ textAlign: "right" }}
                                />
                              </PTextFieldWrapper>
                              </PGridItem>
                          <PGridItem size={3}>
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`dndp${i}`} unit="%" unitPosition="suffix" showCharacterCount={false}>
                                <input
                                  maxLength={inputFieldMaxLength}
                                  aria-autocomplete='none'
                                  autoComplete='off'                            
                                  id={`dndp${i}`}
                                  key={`dndp${i}`}
                                  type="text"
                                  ref={dref[i].percent}
                                  defaultValue={formatCurrencyWithNoUnit(recalDiscountNetPrices?.[i].discountInPercent)}
                                  //onChange={(e) => handleDiscountPercentage(e.target.value, i, `dndp${i}`)}
                                  onBlur={(e) => {
                                      handleDiscountPercentage(e.target.value, i, `dndp${i}`)
                                      if(i === 0 && dref[0].percent.current) dref[0].percent.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices?.[i].discountInPercent)
                                      if(i === 1 && dref[1].percent.current) dref[1].percent.current.value = formatCurrencyWithNoUnit(recalDiscountNetPrices?.[i].discountInPercent)
                                    }
                                  }
                                  style={{ textAlign: "right" }}
                                />
                              </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                          </PGridItem>
                          <PGridItem size={3}>
                          <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }} className='dcs-heading'>
                            <PFlex justifyContent={"flex-end"}>
                              <PFlexItem>
                              <Stack direction="row" spacing={1}>
                                        {(recalDiscountNetPrices[i].discountInPercent-discountNetPrices[i].discountInPercent)!==0 && (
                                          <>
                                          <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>{formatCurrencyWithNoUnit(recalDiscountNetPrices[i].discountInPercent-discountNetPrices[i].discountInPercent)}{" %"}</PorscheFont>
                                          { (recalDiscountNetPrices[i].discountInPercent-discountNetPrices[i].discountInPercent)<0 ? (
                                            <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                          }
                                          </>
                                          )
                                        }
                                        {
                                          (recalDiscountNetPrices[i].discountInPercent-discountNetPrices[i].discountInPercent)===0 && (
                                            <>
                                            <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{formatCurrencyWithNoUnit(recalDiscountNetPrices[i].discountInPercent-discountNetPrices[i].discountInPercent)}{" %"}</PorscheFont>
                                            <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                            </>
                                          )
                                        }
                                      </Stack>
                              </PFlexItem>
                            </PFlex>
                          </div>
                          </PGridItem>
                        </PGrid>&ensp;        
                      </PGridItem>
                    </PGrid>
                  </div>
                );
              })
              }                   
            
            {/* Comment Section Start*/}
              <div style={{ backgroundColor: "#FFFFFF", height: "56px" }}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={12}>
                  <div style={{ textAlign: "left",paddingLeft:"20px", marginTop: "15px"}}>    
                    <Stack direction="column">                 
                      <PorscheFont><span className="sub-header1">{t("Comment (Optional)")}</span></PorscheFont>
                      <PorscheFont><span className="sub-header2">{precalculation?.discountComment ? precalculation?.discountComment : ""}</span></PorscheFont>
                    </Stack>
                  </div>              
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={9}>  
                      <div style={{marginTop:"15px"}}>                             
                        <PTextFieldWrapper id={`dncomment`} label={`${t("Comment (optional)")}`}>
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              id={`dncomment`}
                              key={`dncomment`}
                              type="text"
                              defaultValue={reCalculation?.reCalculation?.discountComment ? reCalculation?.reCalculation?.discountComment : ""}
                              onChange={(e) => handleDiscountComment(e.target.value)}
                              // style={{ textAlign: "left"}}                                                      
                        />
                        </PTextFieldWrapper>
                      </div>
                    </PGridItem>                                  
                    <PGridItem size={3}>                 
                    </PGridItem>               
                  </PGrid>&ensp;
                </PGridItem>
              </PGrid>
              </div>&ensp;&ensp;      
            {/* Comment Section End*/}
            </>
          </div>         
        </div>        
        {/* Discount End */} 

    {/* Kickback Start   */}
    <div className="Recal-card vehicle-price">
      <div>
        <PorscheFont style={{paddingLeft:"20px",paddingRight:"20px"}}><span className="dcs-section-heading">{t("Kickback (net)")}</span></PorscheFont>&ensp;
        <div style={{ backgroundColor: "#FFFFFF"}}>
            {/* Header block start */}
              <PGrid>
                <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>                  
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "center" }}>
                      <PorscheFont  style={{paddingBottom:"4px"}}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                    </div></PGridItem>
                  <PGridItem size={3}>                                    
                  </PGridItem>
                </PGrid>
                </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>                   
                  </PGridItem>
                  <PGridItem size={3}>
                  <div style={{ textAlign: "center" }}>
                      <PorscheFont  style={{paddingBottom:"4px"}}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>                  
                  </PGridItem>
                  <PGridItem size={3}>                 
                  </PGridItem>
                </PGrid>        
              </PGridItem>
              </PGrid>
            {/* Header block End */}
            {/* Divider Block Start */}
              <PGrid>
                <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>                    
                  <PGridItem size={9}>
                      <PDivider style={{backgroundColor:"#C8CACB",height:"2px"}}></PDivider>
                    </PGridItem>                   
                </PGrid>
                </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={9}>                     
                  <PDivider style={{backgroundColor:"#909090",height:"2px"}}></PDivider>                                    
                  </PGridItem>                  
                  <PGridItem size={3}>                 
                  </PGridItem>
                </PGrid>        
              </PGridItem>
              </PGrid>
            {/* Divider Block End */}
        </div>         
        {/* Sub headers section Start*/}
        <div style={{ backgroundColor: "#FFFFFF", height: "46px"}}>
          <PGrid>
            <PGridItem size={6}>
            <PGrid>
              <PGridItem size={3}>
              </PGridItem>
              <PGridItem size={3}>
              <div style={{ textAlign: "right" }}>
              <PorscheFont style={{marginTop:"8px"}}><span className="sub-header1">{t("Kickback in €")}</span></PorscheFont>
              </div>
              </PGridItem>
              <PGridItem size={3}>
                <div style={{ textAlign: "right" }}>
                  <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header1">{t("Kickback in %")}</span></PorscheFont>
                </div></PGridItem>
              <PGridItem size={3}>                                     
              </PGridItem>
            </PGrid>&ensp;
            </PGridItem>
          <PGridItem size={6}>
          <PGrid>
              <PGridItem size={3}>
              <div style={{ textAlign: "right" }}>
              <PorscheFont style={{marginTop:"8px"}}><span className="sub-header2">{t("Kickback in €")}</span></PorscheFont>
              </div>
                  </PGridItem>
              <PGridItem size={3}>
              <div style={{ textAlign: "right" }}>
                  <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header2">{t("Kickback in %")}</span></PorscheFont>
                </div>
              </PGridItem>
              <PGridItem size={3}>                   
              </PGridItem>
              <PGridItem size={3}>
              <div style={{textAlign: "right"}}>
              <PorscheFont style={{marginTop:"8px",paddingRight:"40px"}}><span className="sub-header2">{t("Deviation")}</span></PorscheFont>
            </div> 
              </PGridItem>
            </PGrid>       
          </PGridItem>
          </PGrid>
        </div>
        {/* Sub headers section End*/}             
        {/* Gray Box div  */}
        {kickbackNetPrices?.map((item, i) => {
          if(item.displayLabel !== "Price compensation") {
            return(
            <div style={{ backgroundColor: `#FAFAFA`,height: "56px"}}>
              <PGrid>
                <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  <div style={{ textAlign: "left" }}>
                      <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span className="sub-header2">{t(kickbackLabels.usedCar[i])}{" "}</span></PorscheFont>
                    </div></PGridItem>
                  <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                  <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(item.kickbackInEuro)}{" €"}</span></PorscheFont>
                  </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont  style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(item.kickbackInPercent)}{" %"}</span></PorscheFont>
                    </div></PGridItem>
                  <PGridItem size={3}></PGridItem>
                </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{marginTop: "4px", height: "40px" }} id={`knde${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete='none'
                          autoComplete='off'                            
                          id={`iknde${i}`}
                          key={`iknde${i}`}
                          type="text"
                          ref={kbRef[i].euro}
                          defaultValue={formatCurrencyWithNoUnit(recalKickbackNetPrices?.[i].kickbackInEuro)}
                          //onChange={(e) => handleKickbackEuro(e.target.value, i, `iknde${i}`)}
                          onBlur={(e) => {
                            handleKickbackEuro(e.target.value, i, `iknde${i}`)
                            if(i === 0 && kbRef[0].euro.current) kbRef[0].euro.current.value = formatCurrencyWithNoUnit(recalKickbackNetPrices?.[i].kickbackInEuro)
                            if(i === 1 && kbRef[1].euro.current) kbRef[1].euro.current.value = formatCurrencyWithNoUnit(recalKickbackNetPrices?.[i].kickbackInEuro)
                          }
                        }                           
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`kndp${i}`} unit="%" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete='none'
                          autoComplete='off'                            
                          id={`ikndp${i}`}
                          key={`idndp${i}`}
                          type="text"
                          ref={kbRef[i].percent}
                          defaultValue={formatCurrencyWithNoUnit(recalKickbackNetPrices[i].kickbackInPercent)}
                          //onChange={(e) => handleKickbackPercentage(e.target.value, i, `kndp${i}`)}
                          onBlur={(e) => {
                              handleKickbackPercentage(e.target.value, i, `kndp${i}`)
                              if(i === 0 && kbRef[0].percent.current) kbRef[0].percent.current.value = formatCurrencyWithNoUnit(recalKickbackNetPrices[i].kickbackInPercent)
                              if(i === 1 && kbRef[1].percent.current) kbRef[1].percent.current.value = formatCurrencyWithNoUnit(recalKickbackNetPrices[i].kickbackInPercent)
                            }
                          }                                                  
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }} className='dcs-heading'>
                            <PFlex justifyContent={"flex-end"}>
                              <PFlexItem>
                              <Stack direction="row" spacing={1}>
                                  {(recalKickbackNetPrices[i].kickbackInPercent-kickbackNetPrices[i].kickbackInPercent)!==0 && (
                                    <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>{formatCurrencyWithNoUnit(recalKickbackNetPrices[i].kickbackInPercent-kickbackNetPrices[i].kickbackInPercent)}{" %"}</PorscheFont>
                                    { (recalKickbackNetPrices[i].kickbackInPercent-kickbackNetPrices[i].kickbackInPercent)>0?(
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                    </>
                                  )}
                                  {
                                    (recalKickbackNetPrices[i].kickbackInPercent-kickbackNetPrices[i].kickbackInPercent)===0 && (
                                      <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{formatCurrencyWithNoUnit(recalKickbackNetPrices[i].kickbackInPercent-kickbackNetPrices[i].kickbackInPercent)}{" %"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                      </>
                                    )
                                  }
                                  </Stack>
                              </PFlexItem>
                            </PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>&ensp;        
                </PGridItem>
              </PGrid>
            </div>
            );
          }
          })
        }            
        {/* Comment Section Start*/}
        <div style={{ backgroundColor: "#FFFFFF", height: "56px" }}>
          <PGrid>
            <PGridItem size={6}>
              <PGrid>
                <PGridItem size={12}>
                  <div style={{ textAlign: "left",paddingLeft:"20px", marginTop: "15px"}}>
                    <Stack direction="column">                 
                      <PorscheFont><span className="sub-header1">{t("Description (Sales promotion)")}</span></PorscheFont>
                      <PorscheFont><span className="sub-header2">{reCalculation?.preCalculation?.kickbackDescription ? t(reCalculation?.preCalculation?.kickbackDescription) : ""}</span></PorscheFont>
                    </Stack>
                  </div>              
                </PGridItem>
              </PGrid>&ensp;
            </PGridItem>
            <PGridItem size={6}>
            <PGrid>
                <PGridItem size={9}>                 
                </PGridItem>                                  
                <PGridItem size={3}>                 
                </PGridItem>               
              </PGrid>&ensp;
            </PGridItem>
          </PGrid>
        </div>     
        {/* Comment Section End*/}
      </div>   
    </div>
    {/* Kickback End   */}

    {/** Additional Costs start*/}
    <div className="Recal-card vehicle-price">
      <div>
        <PorscheFont><span className="dcs-section-heading" style={{ marginLeft: "20px" }}>{t("Additional costs")}</span></PorscheFont>&ensp;
        <div style={{ backgroundColor: "#FFFFFF" }}>
          {/* Header block start */}
          <PGrid>
            <PGridItem size={6}>
              <PGrid>
                <PGridItem size={3}>
                </PGridItem>
                <PGridItem size={3}>
                </PGridItem>
                <PGridItem size={3}>
                  <div style={{ textAlign: "center" }}>
                    <PorscheFont style={{ paddingBottom: "4px" }}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                  </div></PGridItem>
                <PGridItem size={3}>
                </PGridItem>
              </PGrid>
            </PGridItem>
            <PGridItem size={6}>
              <PGrid>
                <PGridItem size={3}>
                </PGridItem>
                <PGridItem size={3}>
                  <div style={{ textAlign: "center" }}>
                    <PorscheFont style={{ paddingBottom: "4px" }}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={3}>
                </PGridItem>
                <PGridItem size={3}>
                </PGridItem>
              </PGrid>
            </PGridItem>
          </PGrid>
          {/* Header block End */}
          {/* Divider Block Start */}
          <PGrid>
            <PGridItem size={6}>
              <PGrid>
                <PGridItem size={3}>
                </PGridItem>
                <PGridItem size={9}>
                  <PDivider style={{ backgroundColor: "#C8CACB", height: "2px" }}></PDivider>
                </PGridItem>
              </PGrid>
            </PGridItem>
            <PGridItem size={6}>
              <PGrid>
                <PGridItem size={9}>
                  <PDivider style={{ backgroundColor: "#909090", height: "2px" }}></PDivider>
                </PGridItem>
                <PGridItem size={3}>
                </PGridItem>
              </PGrid>
            </PGridItem>
          </PGrid>
          {/* Divider Block End */}
        </div>
        {/* Sub headers section Start*/}
        <div style={{ backgroundColor: "#FFFFFF", height: "46px" }}>
          <PGrid>
            <PGridItem size={6}>
              <PGrid>
                <PGridItem size={3}>
                </PGridItem>
                <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{ marginTop: "8px", color: "#909090" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Discount in €")}</span></PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Discount in %")}</span></PorscheFont>
                  </div>
                </PGridItem>
              </PGrid>&ensp;
            </PGridItem>
            <PGridItem size={6}>
              <PGrid>
                <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Discount in €")}</span></PorscheFont>
                  </div>
                </PGridItem>
                <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Discount in %")}</span></PorscheFont>
                  </div>
                </PGridItem>

                <PGridItem size={3}>
                  <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                    <PFlex justifyContent={"flex-end"}>
                      <PFlexItem>
                        <Stack direction="row" spacing={1}>
                          <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                          <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                  </div>
                </PGridItem>
              </PGrid>
            </PGridItem>
          </PGrid>
        </div>
        {/* Sub headers section End*/}
        { recalAdditionalCosts&& recalAdditionalCosts.length>0 && additionalCosts?.map((item, i) => {
          if (item.type === "Registration" && item.label === "Registration by customer" ) return (<></>);
          if (item.type === "Paw" && item.label === item.type) return (<></>);
          if (item.type === "Assistance" && item.label === item.type ) return (<></>);

          if(item.label.length > 0 && item.label !== "Registration") {
            return (
              <>
                <div style={{ backgroundColor: `${getAdditonalCostsColorCode(i)}`, height: "56px" }}>
                  <PGrid >
                    <PGridItem size={6}>
                      <PGrid>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "left" }}>
                            <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px", marginRight: "-150px" }}><span>{t(item.label)}</span></PorscheFont>
                          </div></PGridItem>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(item.amountNet)}{" €"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(item.discountInEuro)}{" €"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(item.discountInPercent)}{" %"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                      </PGrid>&ensp;
                    </PGridItem>
                    <PGridItem size={6}>
                      <PGrid>
                        <PGridItem size={3}>{ (item.label === "Transfer (Other)" ||
                        item.label === "Home Delivery" ||
                        item.label === "Export (European union)" ||
                        item.label === "Export (European economy area)" ||
                        item.label === "Export (Third country)" ||
                        item.label === "Other") 
                        ? (
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`acan${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                            <input
                              maxLength={inputFieldMaxLength}
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={acRef[i].net}
                              id={`iacan${i}`}
                              key={`iacan${i}`}
                              type="text"
                              defaultValue={formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet)}
                              //onChange={ (e) => handleAddAmountNet(e.target.value, i, `acan${i}`)}
                              onBlur={(e) => {
                                handleAddAmountNet(e.target.value, i, `acan${i}`)
                                if(i === 0 && acRef[0].net.current) acRef[0].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                                if(i === 1 && acRef[1].net.current) acRef[1].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                                if(i === 2 && acRef[2].net.current) acRef[2].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                                if(i === 3 && acRef[3].net.current) acRef[3].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                                if(i ===4 && acRef[4].net.current) acRef[4].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                              }}
                              style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                        ) : (
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`acan${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                            <input
                              maxLength={inputFieldMaxLength}
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={acRef[i].net}
                              id={`iacan${i}`}
                              key={`kacan${i}`}
                              type="text"
                              defaultValue={formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet)}
                              //onChange={ (e) => handleAddAmountNet(e.target.value, i, `acan${i}`)}
                              onBlur={(e) => {
                                handleAddAmountNet(e.target.value, i, `acan${i}`)
                                if(i === 0 && acRef[0].net.current) acRef[0].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                                if(i === 1 && acRef[1].net.current) acRef[1].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                                if(i === 2 && acRef[2].net.current) acRef[2].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                                if(i === 3 && acRef[3].net.current) acRef[3].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                                if(i ===4 && acRef[4].net.current) acRef[4].net.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].amountNet);
                              }}
                              style={{ textAlign: "right" }}
                              readOnly
                            />
                          </PTextFieldWrapper>
                        )
                        }
                        </PGridItem>
                        <PGridItem size={3}>
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`acde${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                            <input
                              maxLength={inputFieldMaxLength}
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={acRef[i].euro}
                              id={`iacde${i}`}
                              key={`kacde${i}`}
                              type="text"
                              defaultValue={formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro)}
                              //onChange={ (e) => handleAddAmountDiscount(e.target.value, i, `acde${i}`)}
                              onBlur={(e) => {
                                handleAddAmountDiscount(e.target.value, i, `acde${i}`)
                                if(i === 0 && acRef[0].euro.current) acRef[0].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
                                if(i === 1 && acRef[1].euro.current) acRef[1].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
                                if(i === 2 && acRef[2].euro.current) acRef[2].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
                                if(i === 3 && acRef[3].euro.current) acRef[3].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
                                if(i ===4 && acRef[4].euro.current) acRef[4].euro.current.value = formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInEuro);
                              }}
                              style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                        </PGridItem>
                        <PGridItem size={3}>
                        <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`acdp${i}`} unit="%" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={acRef[i].percent}
                              id={`iacdp${i}`}
                              key={`kacdp${i}`}
                              type="text"
                              defaultValue={formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent)}
                              style={{ textAlign: "right" }}
                              readOnly
                            />
                          </PTextFieldWrapper>
                        </PGridItem>
                        <PGridItem size={3}>
                          <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                            <PFlex justifyContent={"flex-end"}>
                              <PFlexItem>
                                <Stack direction="row" spacing={1}>
                                  {
                                  (recalAdditionalCosts[i].discountInPercent-additionalCosts[i].discountInPercent)!==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>{formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent-additionalCosts[i].discountInPercent)}{" %"}</PorscheFont>
                                    { (recalAdditionalCosts[i].discountInPercent-additionalCosts[i].discountInPercent)<0?(
                                      <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                    )
                                  }
                                  {
                                    (recalAdditionalCosts[i].discountInPercent-additionalCosts[i].discountInPercent)===0 && (
                                      <>
                                        <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalAdditionalCosts[i].discountInPercent-additionalCosts[i].discountInPercent)}{" %"}</PorscheFont>
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                      </>
                                    )
                                  }
                                </Stack>
                              </PFlexItem>
                            </PFlex>
                          </div>
                        </PGridItem>
                      </PGrid>&ensp;
                    </PGridItem>
                  </PGrid>
                </div>
              </>
            )
          }
          })
        }
      </div>&ensp;
      {addError.length > 0 && (
        <div style={{backgroundColor:"#FFFFFF" , height:"20px"}}>          
          <PGrid>
            <PGridItem size={6}>                  
            </PGridItem>
            <PGridItem size={6}>
              <Stack direction="row">
              <PIcon name="information" color="brand"></PIcon>&ensp;
              <PorscheFont><span style={{ color: "#D5001B",fontWeight:"400px"}}>{t("Discount in € can not be higher than Amount (net).")}</span></PorscheFont>                 
              </Stack>
            </PGridItem>
          </PGrid>                       
        </div>
      )} &ensp;
      {/* Comment Section Start*/}
      <div style={{ backgroundColor: "#FFFFFF", height: "79px" }}>
        <PGrid>
          <PGridItem size={6}>
            <div style={{ textAlign: "left", paddingLeft: "20px" }}>
              <Stack direction="column">
                <PorscheFont><span className="sub-header1">{t("Comment (Optional)")}</span></PorscheFont>
                <PorscheFont><span className="sub-header2">{reCalculation?.preCalculation?.additionalCostPriceComment ? t(reCalculation?.preCalculation?.additionalCostPriceComment) : ""}</span></PorscheFont>
              </Stack>
            </div>
          </PGridItem>
          <PGridItem size={6}>
            <PGrid>
              <PGridItem size={9}>
                <div style={{ marginTop: "-4px" }}>
                  <PTextFieldWrapper id="vpbpn0" label={`${t("Comment (optional)")}`}>
                    <input
                      aria-autocomplete='none'
                      autoComplete='off'
                      type="text"
                      //style={{ textAlign: "right" }}
                      defaultValue={reCalculation?.reCalculation?.additionalCostPriceComment ?  reCalculation?.reCalculation?.additionalCostPriceComment : ""}
                      onChange={ (e) => handleAdditionalCostComment(e.target.value)}
                    />
                  </PTextFieldWrapper>
                </div>
              </PGridItem>
              <PGridItem size={3}>
              </PGridItem>
            </PGrid>&ensp;
          </PGridItem>
        </PGrid>
      </div>
      {/* Comment Section End*/}
    </div>
    {/** Additional Costs end*/}

    {/** Workshop Costs start*/}
    {workshopCosts?.upsaleItems?.length > 0 && (
      <div className="Recal-card vehicle-price">
        <div>
          <PorscheFont><span className="dcs-section-heading" style={{ marginLeft: "20px" }}>{t("Workshop costs")}</span></PorscheFont>&ensp;
          <div style={{ backgroundColor: "#FFFFFF" }}>
            {/* Header block start */}
            <PGrid>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "center" }}>
                      <PorscheFont style={{ paddingBottom: "4px" }}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                    </div></PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                </PGrid>
              </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "center" }}>
                      <PorscheFont style={{ paddingBottom: "4px" }}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </PGrid>
            {/* Header block End */}
            {/* Divider Block Start */}
            <PGrid>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={9}>
                    <PDivider style={{ backgroundColor: "#C8CACB", height: "2px" }}></PDivider>
                  </PGridItem>
                </PGrid>
              </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={9}>
                    <PDivider style={{ backgroundColor: "#909090", height: "2px" }}></PDivider>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </PGrid>
            {/* Divider Block End */}
          </div>
          {/* Sub headers section Start*/}
          <div style={{ backgroundColor: "#FFFFFF", height: "46px" }}>
            <PGrid>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px", color: "#909090" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Discount in €")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Discount in %")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                </PGrid>&ensp;
              </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Discount in €")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Discount in %")}</span></PorscheFont>
                    </div>
                  </PGridItem>

                  <PGridItem size={3}>
                    <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                      <PFlex justifyContent={"flex-end"}>
                        <PFlexItem>
                          <Stack direction="row" spacing={1}>
                            <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                            <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                    </div>
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </PGrid>
          </div>
          {/* Sub headers section End*/}

          { recalWorkshopCosts && recalWorkshopCosts?.upsaleItems && recalWorkshopCosts?.upsaleItems.length > 0
            && workshopCosts?.upsaleItems?.map((item, i) => {
              if (item.partType === "tire") {
                return (
                  <>
                  <div style={{ backgroundColor: `${getWorkshopCostsColorCode("tire", false, 0)}`, height: "56px" }}>
                    <PGrid >
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "left" }}>
                              <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("Complete Winter Tires")}</span></PorscheFont>
                            </div></PGridItem>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "right" }}>
                              <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getAmountNet(item, false, 0))}{" €"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "right" }}>
                              <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getAmountNet(item, false, 0) - item.customerShare)}{" €"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                              <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getPercent(item, false, 0, "pre"))}{" %"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`wscwtrcnet`} unit="€" unitPosition="suffix" >
                              <input
                                aria-autocomplete='none'
                                autoComplete='off'
                                //ref={cwtRef[0].euro}
                                id={`iwscwtrcnet`}
                                key={`kwscwtrcnet`}
                                type="text"
                                defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(getAmountNet(recalWorkshopCosts?.upsaleItems[i], false, 0)) : "0,00"}
                                style={{ textAlign: "right" }}
                                readOnly
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`wscwtde`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                              <input
                                maxLength={inputFieldMaxLength}
                                aria-autocomplete='none'
                                autoComplete='off'
                                ref={cwtRef[0].euro}
                                id={`iwscwtde`}
                                key={`kwscwtde`}
                                type="text"
                                //defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(getAmountNet(recalWorkshopCosts?.upsaleItems[i], false, 0) - recalWorkshopCosts?.upsaleItems[i].customerShare) : "0,00" }
                                defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].discount) : "0,00" }
                                //onChange={ (e) => handleWsDiscountEuro(e.target.value, "tire", false, 0, `iwscwtde`)}
                                onBlur={(e) => {
                                    handleWsDiscountEuro(e.target.value, "tire", false, 0, `wscwtde`)
                                    if(cwtRef[0].euro.current) cwtRef[0].euro.current.value = recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].discount) : "0,00"
                                  }
                                }
                                style={{ textAlign: "right" }}
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`wscwtdp`} unit="%" unitPosition="suffix" >
                              <input
                                aria-autocomplete='none'
                                autoComplete='off'
                                ref={cwtRef[0].percent}
                                id={`iwscwtdp`}
                                key={`kwscwtdp`}
                                type="text"
                                value={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re")) : "0.00" }
                                style={{ textAlign: "right" }}
                                readOnly
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                              <PFlex justifyContent={"flex-end"}>
                                <PFlexItem>
                                  <Stack direction="row" spacing={1}>
                                    { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                      && (getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re") - getPercent(item, false, 0, "pre")) !== 0.00 && (
                                        <>
                                          <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                            {formatCurrencyWithNoUnit(getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re") - getPercent(item, false, 0, "pre"))}{" %"}
                                          </PorscheFont>
                                          { (getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re") - getPercent(item, false, 0, "pre"))<0?(
                                            <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                          }
                                        </>
                                      )
                                    }
                                    { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                      && (getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re") - getPercent(item, false, 0, "pre")) === 0 && (
                                        <>
                                          <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00  %"}</PorscheFont>
                                          <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                        </>
                                      )
                                    }
                                    </Stack>
                                  </PFlexItem>
                                </PFlex>
                            </div>
                          </PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                    </PGrid>
                  </div>
                  
                  { item.serviceItems.map((service, j) => {
                    if(service.services !== null) {
                      return (
                        <div style={{ backgroundColor: `${getWorkshopCostsColorCode("tire", true, j)}`, height: "56px" }}>
                          <PGrid >
                            <PGridItem size={6}>
                              <PGrid>
                                <PGridItem size={3}>
                                  <div style={{ textAlign: "left" }}>
                                    <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>
                                    {getWorkshopServiceName(item.serviceItems[j].services?.serviceNameUpsale, j === 0 ? t("winter") : t("summer"))}{" "}
                                    </span></PorscheFont>
                                  </div></PGridItem>
                                <PGridItem size={3}>
                                  <div style={{ textAlign: "right" }}>
                                    <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getAmountNet(item, true, j))}{" €"}</span></PorscheFont>
                                  </div>
                                </PGridItem>
                                <PGridItem size={3}>
                                  <div style={{ textAlign: "right" }}>
                                    <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{ formatCurrencyWithNoUnit(getAmountNet(item, true, j) > 0 ? getAmountNet(item, true, j) - service.customerShare : 0.00) }{" €"}</span></PorscheFont>
                                  </div>
                                </PGridItem>
                                <PGridItem size={3}>
                                  <div style={{ textAlign: "right" }}>
                                    {/* <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{vehiclePrices && vehiclePrices.length > 0 ? formatCurrencyWithNoUnit( (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100) : "0,00"}{" %"}</span></PorscheFont> */}
                                    <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getPercent(item, true, j, "pre") )}{" %"}</span></PorscheFont>
                                  </div>
                                </PGridItem>
                              </PGrid>&ensp;
                            </PGridItem>
                            <PGridItem size={6}>
                              <PGrid>
                                <PGridItem size={3}>
                                  <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`wscwts${j}`} unit="€" unitPosition="suffix" >
                                    <input
                                      aria-autocomplete='none'
                                      autoComplete='off'
                                      //ref={cwtServiceRef[j].euro}
                                      id={`iwscwts${j}`}
                                      key={`kwscwts${j}`}
                                      type="text"
                                      defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(getAmountNet(recalWorkshopCosts?.upsaleItems[i], true, j)): 0.00}
                                      style={{ textAlign: "right" }}
                                      readOnly
                                    />
                                  </PTextFieldWrapper>
                                </PGridItem>
                                <PGridItem size={3}>
                                  <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`wscwtrede${j}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                                    <input
                                      maxLength={inputFieldMaxLength}
                                      aria-autocomplete='none'
                                      autoComplete='off'
                                      ref={cwtServiceRef[j].euro}
                                      id={`iwscwtrede${j}`}
                                      key={`kwscwtrede${j}`}
                                      type="text"
                                      //defaultValue={ recalWorkshopCosts?.upsaleItems[i] && getAmountNet(recalWorkshopCosts?.upsaleItems[i], true, j) > 0 ? formatCurrencyWithNoUnit(getAmountNet(recalWorkshopCosts?.upsaleItems[i], true, j) - service.customerShare) : "0,00" }
                                      defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].serviceItems[j].discount) : "0,00" }
                                      //onChange={ (e) => handleWsDiscountEuro(e.target.value, "tire", true, j, `iwscwtrede`)}
                                      onBlur={(e) => {
                                          handleWsDiscountEuro(e.target.value, "tire", true, j, `wscwtrede${j}`)
                                          if(j === 0 && cwtServiceRef[0].euro.current) 
                                            cwtServiceRef[0].euro.current.value = recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].serviceItems[j].discount) : "0,00"
                                          if(j === 1 && cwtServiceRef[1].euro.current) 
                                            cwtServiceRef[1].euro.current.value = recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].serviceItems[j].discount) : "0,00"
                                        }
                                      }
                                      style={{ textAlign: "right" }}
                                    />
                                  </PTextFieldWrapper>
                                </PGridItem>
                                <PGridItem size={3}>
                                <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`wscwtdp`} unit="%" unitPosition="suffix" >
                                    <input
                                      aria-autocomplete='none'
                                      autoComplete='off'
                                      ref={cwtServiceRef[0].percent}
                                      id={`iwscwtdp`}
                                      key={`kwscwtdp`}
                                      type="text"
                                      value={ recalWorkshopCosts?.upsaleItems[i] && recalvehiclePrices && recalvehiclePrices.length >0 
                                        ? formatCurrencyWithNoUnit( (getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - recalWorkshopCosts?.upsaleItems[i].serviceItems[j].customerShare) / recalvehiclePrices[0].amountNet * 100)
                                        : "0,00"
                                      }
                                      style={{ textAlign: "right" }}
                                      readOnly
                                    />
                                  </PTextFieldWrapper>
                                </PGridItem>
                                <PGridItem size={3}>
                                  <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                                    <PFlex justifyContent={"flex-end"}>
                                      <PFlexItem>
                                        <Stack direction="row" spacing={1}>
                                          {/* { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0 && recalvehiclePrices && vehiclePrices
                                            && ((getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - service.customerShare) / recalvehiclePrices[0].amountNet * 100 - (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100) !== 0.00 && (
                                              <>
                                                <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                                  {formatCurrencyWithNoUnit((getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - service.customerShare) / recalvehiclePrices[0].amountNet * 100 - (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100)}{" %"}
                                                </PorscheFont>
                                                { (getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - service.customerShare) / recalvehiclePrices[0].amountNet * 100 - (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100 < 0?(
                                                  <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                                }
                                              </>
                                            )
                                          }
                                          { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                            && ((getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - service.customerShare) / recalvehiclePrices[0].amountNet * 100 - (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100) === 0.00 && (
                                              <>
                                                <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00  %"}</PorscheFont>
                                                <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                              </>
                                            )
                                          } */}
                                          { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                            && (getPercent(recalWorkshopCosts?.upsaleItems[i], true, j, "re") - getPercent(item, true, j, "pre")) !== 0.00 && (
                                              <>
                                                <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                                  {formatCurrencyWithNoUnit(getPercent(recalWorkshopCosts?.upsaleItems[i], true, j, "re") - getPercent(item, true, j, "pre"))}{" %"}
                                                </PorscheFont>
                                                { (getPercent(recalWorkshopCosts?.upsaleItems[i], true, j, "re") - getPercent(item, true, j, "pre")) < 0 
                                                  ? (<img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                                  : ( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                                }
                                              </>
                                            )
                                          }
                                          { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                            && (getPercent(recalWorkshopCosts?.upsaleItems[i], true, j, "re") - getPercent(item, true, j, "pre")) === 0 && (
                                              <>
                                                <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00  %"}</PorscheFont>
                                                <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                              </>
                                            )
                                          }
                                        </Stack>
                                      </PFlexItem>
                                    </PFlex>
                                  </div>
                                </PGridItem>
                              </PGrid>&ensp;
                            </PGridItem>
                          </PGrid>
                        </div>
                      )
                      }
                    })
                  }
                  </>
                )
              }
            })
          }

          { parts && parts.length === 1 && recalWorkshopCosts && recalWorkshopCosts?.upsaleItems && recalWorkshopCosts?.upsaleItems.length > 0
            && workshopCosts?.upsaleItems?.map((item, i) => {
              if (item.partType === "part") {
                return (
                  <>
                    <div style={{ backgroundColor: `${getWorkshopCostsColorCode("part", false, 0)}`, height: "56px" }}>
                    <PGrid >
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "left" }}>
                              <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("PDS / Tequipment")}</span></PorscheFont>
                            </div></PGridItem>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "right" }}>
                              <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getAmountNet(item, false, 0))}{" €"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "right" }}>
                              <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getAmountNet(item, false, 0) - item.customerShare)}{" €"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                              <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getPercent(item, false, 0, "pre"))}{" %"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`pdsrcnet`} unit="€" unitPosition="suffix" >
                              <input
                                aria-autocomplete='none'
                                autoComplete='off'
                                //ref={cwtRef[0].euro}
                                id={`ipdsrcnet`}
                                key={`kpdsrcnet`}
                                type="text"
                                defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(getAmountNet(recalWorkshopCosts?.upsaleItems[i], false, 0)) : "0,00"}
                                style={{ textAlign: "right" }}
                                readOnly
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdsde`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                              <input
                                maxLength={inputFieldMaxLength}
                                aria-autocomplete='none'
                                autoComplete='off'
                                ref={pdsRef[0].euro}
                                id={`ipdsde`}
                                key={`kpdsde`}
                                type="text"
                                //defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(getAmountNet(recalWorkshopCosts?.upsaleItems[i], false, 0) - recalWorkshopCosts?.upsaleItems[i].customerShare) : "0,00" }
                                defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].discount) : "0,00" }
                                //onChange={ (e) => handleWsDiscountEuro(e.target.value, "part", false, 0, `ipdsde`)}
                                onBlur={(e) => {
                                    handleWsDiscountEuro(e.target.value, "part", false, 0, `pdsde`)
                                    if(pdsRef[0].euro.current) pdsRef[0].euro.current.value = recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].discount) : "0,00"
                                  }
                                }
                                style={{ textAlign: "right" }}
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdsdp`} unit="%" unitPosition="suffix" >
                              <input
                                aria-autocomplete='none'
                                autoComplete='off'
                                ref={pdsRef[0].percent}
                                id={`ipdsdp`}
                                key={`kpdsdp`}
                                type="text"
                                value={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re")) : "0.00" }
                                style={{ textAlign: "right" }}
                                readOnly
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }}>
                              <PFlex justifyContent={"flex-end"}>
                                <PFlexItem>
                                  <Stack direction="row" spacing={1}>
                                    { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                      && (getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re") - getPercent(item, false, 0, "pre")) !== 0.00 && (
                                        <>
                                          <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                            {formatCurrencyWithNoUnit(getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re") - getPercent(item, false, 0, "pre"))}{" %"}
                                          </PorscheFont>
                                          { (getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re") - getPercent(item, false, 0, "pre"))<0?(
                                            <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                          }
                                        </>
                                      )
                                    }
                                    { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                      && (getPercent(recalWorkshopCosts?.upsaleItems[i], false, 0, "re") - getPercent(item, false, 0, "pre")) === 0.00 && (
                                        <>
                                          <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00  %"}</PorscheFont>
                                          <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                        </>
                                      )
                                    }
                                    </Stack></PFlexItem></PFlex>
                            </div>
                          </PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                    </PGrid>
                    </div>

                    { item.serviceItems.map((service, j) => {
                        if (!(service.services.priceBeforeCustomerNet > 0)) return (<></>);
                      return (
                        <div style={{ backgroundColor: `${getWorkshopCostsColorCode("part", true, j)}`, height: "56px" }}>
                          <PGrid >
                            <PGridItem size={6}>
                              <PGrid>
                                <PGridItem size={3}>
                                  <div style={{ textAlign: "left" }}>
                                    <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>
                                    {t("Installation  (PDS/TEQ)")}
                                    </span></PorscheFont>
                                  </div></PGridItem>
                                <PGridItem size={3}>
                                  <div style={{ textAlign: "right" }}>
                                    <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(getAmountNet(item, true, j))}{" €"}</span></PorscheFont>
                                  </div>
                                </PGridItem>
                                <PGridItem size={3}>
                                  <div style={{ textAlign: "right" }}>
                                    <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{ formatCurrencyWithNoUnit(getAmountNet(item, true, j) > 0 ? getAmountNet(item, true, j) - service.customerShare : 0.00) }{" €"}</span></PorscheFont>
                                  </div>
                                </PGridItem>
                                <PGridItem size={3}>
                                <div style={{ textAlign: "right" }}>
                                    <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{vehiclePrices && vehiclePrices.length > 0 ? formatCurrencyWithNoUnit( (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100) : "0,00"}{" %"}</span></PorscheFont>
                                  </div>
                                </PGridItem>
                              </PGrid>&ensp;
                            </PGridItem>
                            <PGridItem size={6}>
                              <PGrid>
                                <PGridItem size={3}>
                                  <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`pdss${j}`} unit="€" unitPosition="suffix" >
                                    <input
                                      aria-autocomplete='none'
                                      autoComplete='off'
                                      //ref={cwtServiceRef[j].euro}
                                      id={`ipdss${j}`}
                                      key={`kpdss${j}`}
                                      type="text"
                                      defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(getAmountNet(recalWorkshopCosts?.upsaleItems[i], true, j)): 0.00}
                                      style={{ textAlign: "right" }}
                                      readOnly
                                    />
                                  </PTextFieldWrapper>
                                </PGridItem>
                                <PGridItem size={3}>
                                  <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdssde`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                                    <input
                                      maxLength={inputFieldMaxLength}
                                      aria-autocomplete='none'
                                      autoComplete='off'
                                      ref={pdsServiceRef[0].euro}
                                      id={`ipdssde`}
                                      key={`kpdssde`}
                                      type="text"
                                      //defaultValue={ recalWorkshopCosts?.upsaleItems[i] && getAmountNet(recalWorkshopCosts?.upsaleItems[i], true, j) > 0 ? formatCurrencyWithNoUnit(getAmountNet(recalWorkshopCosts?.upsaleItems[i], true, j) - recalWorkshopCosts?.upsaleItems[i].serviceItems[j].customerShare) : "0,00" }
                                      defaultValue={ recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].serviceItems[j].discount) : "0,00" }
                                      //onChange={ (e) => handleWsDiscountEuro(e.target.value, "part", true, j, `ipdssde`)}
                                      onBlur={(e) => {
                                          handleWsDiscountEuro(e.target.value, "part", true, j, `pdssde`)
                                          if(j === 0 && pdsServiceRef[0].euro.current) pdsServiceRef[0].euro.current.value = recalWorkshopCosts?.upsaleItems[i] ? formatCurrencyWithNoUnit(recalWorkshopCosts?.upsaleItems[i].serviceItems[j].discount) : "0,00"
                                        }
                                      }
                                      style={{ textAlign: "right" }}
                                    />
                                  </PTextFieldWrapper>
                                </PGridItem>
                                <PGridItem size={3}>
                                <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdssdp`} unit="%" unitPosition="suffix" >
                                    <input
                                      aria-autocomplete='none'
                                      autoComplete='off'
                                      //ref={cwtServiceRef[0].percent}
                                      id={`ipdssdp`}
                                      key={`kpdssdp`}
                                      type="text"
                                      value={ recalWorkshopCosts?.upsaleItems[i] && recalvehiclePrices && recalvehiclePrices.length >0
                                        ? formatCurrencyWithNoUnit( (getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - recalWorkshopCosts?.upsaleItems[i].serviceItems[j].customerShare) / recalvehiclePrices[0].amountNet * 100)
                                        : "0,00"
                                      }
                                      style={{ textAlign: "right" }}
                                      readOnly
                                    />
                                  </PTextFieldWrapper>
                                </PGridItem>
                                <PGridItem size={3}>
                                  <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }}>
                                    <PFlex justifyContent={"flex-end"}>
                                      <PFlexItem>
                                        <Stack direction="row" spacing={1}>
                                          {/* { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0 && recalvehiclePrices && vehiclePrices
                                            && ((getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - service.customerShare) / recalvehiclePrices[0].amountNet * 100 - (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100) !== 0.00 && (
                                              <>
                                                <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                                  {formatCurrencyWithNoUnit((getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - service.customerShare) / recalvehiclePrices[0].amountNet * 100 - (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100)}{" %"}
                                                </PorscheFont>
                                                { (getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - service.customerShare) / recalvehiclePrices[0].amountNet * 100 - (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100 < 0?(
                                                  <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                                }
                                              </>
                                            )
                                          }
                                          { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                            && ((getAmountNet(recalWorkshopCosts?.upsaleItems[i] , true, j) - service.customerShare) / recalvehiclePrices[0].amountNet * 100 - (getAmountNet(item, true, j) - service.customerShare) / vehiclePrices[0].amountNet * 100) === 0.00 && (
                                              <>
                                                <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00  %"}</PorscheFont>
                                                <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                              </>
                                            )
                                          } */}
                                          { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                              && (getPercent(recalWorkshopCosts?.upsaleItems[i], true, j, "re") - getPercent(item, true, j, "pre")) !== 0.00 && (
                                                <>
                                                  <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                                    {formatCurrencyWithNoUnit(getPercent(recalWorkshopCosts?.upsaleItems[i], true, j, "re") - getPercent(item, true, j, "pre"))}{" %"}
                                                  </PorscheFont>
                                                  { (getPercent(recalWorkshopCosts?.upsaleItems[i], true, j, "re") - getPercent(item, true, j, "pre")) < 0 
                                                    ? (<img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                                    : (<img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                                  }
                                                </>
                                              )
                                            }
                                            { recalWorkshopCosts && recalWorkshopCosts.upsaleItems.length > 0 && workshopCosts && workshopCosts.upsaleItems.length > 0
                                              && (getPercent(recalWorkshopCosts?.upsaleItems[i], true, j, "re") - getPercent(item, true, j, "pre")) === 0 && (
                                                <>
                                                  <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00  %"}</PorscheFont>
                                                  <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                                </>
                                              )
                                            }
                                        </Stack>
                                      </PFlexItem>
                                    </PFlex>
                                  </div>
                                </PGridItem>
                              </PGrid>&ensp;
                            </PGridItem>
                          </PGrid>
                        </div>
                      )
                    })}
                  </>
                )
                }
              })
            }

          {/** Multiple parts */}
          { parts && parts.length > 1 && (
              <>
                <div style={{ backgroundColor: `${getWorkshopCostsColorCode("part", false, 0)}`, height: "56px" }}>
                  <PGrid >
                    <PGridItem size={6}>
                      <PGrid>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "left" }}>
                            <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("PDS / Tequipment")}</span></PorscheFont>
                          </div></PGridItem>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{ formatCurrencyWithNoUnit(getPartTotals().amountNet) }{" €"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{ formatCurrencyWithNoUnit(getPartTotals().discountEuro) }{" €"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                        <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{ formatCurrencyWithNoUnit(getPartTotals().discountPercent) }{" %"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                      </PGrid>&ensp;
                    </PGridItem>
                    <PGridItem size={6}>
                      <PGrid>
                        <PGridItem size={3}>
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`pdsrcnet`} unit="€" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              //ref={cwtRef[0].euro}
                              id={`ipdsrcnet`}
                              key={`kpdsrcnet`}
                              type="text"
                              value={ formatCurrencyWithNoUnit(getPartTotals().amountNet) }
                              style={{ textAlign: "right" }}
                              readOnly
                            />
                          </PTextFieldWrapper>
                        </PGridItem>
                        <PGridItem size={3}>
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdsde`} unit="€" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={pdsRef[0].euro}
                              id={`ipdsde`}
                              key={`kpdsde`}
                              type="text"
                              value={ formatCurrencyWithNoUnit(getPartTotals().discountEuro) }
                              readOnly
                              style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                        </PGridItem>
                        <PGridItem size={3}>
                        <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdsdp`} unit="%" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={pdsRef[0].percent}
                              id={`ipdsdp`}
                              key={`kpdsdp`}
                              type="text"
                              value={formatCurrencyWithNoUnit(getPartTotals().discountPercent) }
                              style={{ textAlign: "right" }}
                              readOnly
                            />
                          </PTextFieldWrapper>
                        </PGridItem>
                        <PGridItem size={3}>
                          <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }}>
                            <PFlex justifyContent={"flex-end"}>
                              <PFlexItem>
                                <Stack direction="row" spacing={1}>
                                </Stack></PFlexItem></PFlex>
                          </div>
                        </PGridItem>
                      </PGrid>&ensp;
                    </PGridItem>
                  </PGrid>
                </div>

                { getPartTotals().serviceNet > 0 && (
                  <div style={{ backgroundColor: `${getWorkshopCostsColorCode("part", true, 0)}`, height: "56px" }}>
                  <PGrid >
                    <PGridItem size={6}>
                      <PGrid>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "left" }}>
                            <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t("Installation  (PDS/TEQ)")}</span></PorscheFont>
                          </div></PGridItem>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{ formatCurrencyWithNoUnit(getPartTotals().serviceNet) }{" €"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                        <PGridItem size={3}>
                          <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{ formatCurrencyWithNoUnit(getPartTotals().serviceEuro) }{" €"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                        <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                            <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{ formatCurrencyWithNoUnit(getPartTotals().servicePercent) }{" %"}</span></PorscheFont>
                          </div>
                        </PGridItem>
                      </PGrid>&ensp;
                    </PGridItem>
                    <PGridItem size={6}>
                      <PGrid>
                        <PGridItem size={3}>
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`ppdssnet`} unit="€" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              //ref={cwtRef[0].euro}
                              id={`ipdssnet`}
                              key={`kpdssnet`}
                              type="text"
                              value={ formatCurrencyWithNoUnit(getPartTotals().serviceNet) }
                              style={{ textAlign: "right" }}
                              readOnly
                            />
                          </PTextFieldWrapper>
                        </PGridItem>
                        <PGridItem size={3}>
                          <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdssde`} unit="€" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={pdsRef[0].euro}
                              id={`ipdssde`}
                              key={`kpdssde`}
                              type="text"
                              value={ formatCurrencyWithNoUnit(getPartTotals().serviceEuro) }
                              readOnly
                              style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                        </PGridItem>
                        <PGridItem size={3}>
                        <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdssdp`} unit="%" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={pdsRef[0].percent}
                              id={`ipdssdp`}
                              key={`kpdssdp`}
                              type="text"
                              value={formatCurrencyWithNoUnit(getPartTotals().servicePercent) }
                              style={{ textAlign: "right" }}
                              readOnly
                            />
                          </PTextFieldWrapper>
                        </PGridItem>
                        <PGridItem size={3}>
                          <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }}>
                            <PFlex justifyContent={"flex-end"}>
                              <PFlexItem>
                                <Stack direction="row" spacing={1}>
                                </Stack></PFlexItem></PFlex>
                          </div>
                        </PGridItem>
                      </PGrid>&ensp;
                    </PGridItem>
                  </PGrid>
                </div>
                )}
              </>
            )}
        </div>&ensp;
        {workShopError === true && (
            <div style={{backgroundColor:"#FFFFFF" , height:"20px"}}>          
              <PGrid>
                <PGridItem size={6}>                  
                </PGridItem>
                <PGridItem size={6}>
                  <Stack direction="row">
                  <PIcon name="information" color="brand"></PIcon>&ensp;
                  <PorscheFont><span style={{ color: "#D5001B",fontWeight:"400px"}}>{t("Discount in € can not be higher than Amount (net).")}</span></PorscheFont>                 
                  </Stack>
                </PGridItem>
              </PGrid>                       
            </div>
          )} &ensp; 
      </div>
    )}
    {/** Workshop Costs end*/}

    {/* Trade-in start */}
    {order?.tradeInDetails && ( 
      <div className="Recal-card vehicle-price">
        <div>
          <PorscheFont><span className="dcs-section-heading" style={{ marginLeft: "20px" }}>{t(getTradeInHeaderLabel(order?.tradeInDetails?.tradeInType))}</span></PorscheFont>&ensp;
          <div style={{ backgroundColor: "#FFFFFF" }}>
            {/* Header block start */}
            <PGrid>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "center" }}>
                      <PorscheFont style={{ paddingBottom: "4px" }}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                    </div></PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                </PGrid>
              </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "center" }}>
                      <PorscheFont style={{ paddingBottom: "4px" }}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </PGrid>
            {/* Header block End */}
            {/* Divider Block Start */}
            <PGrid>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={9}>
                    <PDivider style={{ backgroundColor: "#C8CACB", height: "2px" }}></PDivider>
                  </PGridItem>
                </PGrid>
              </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={9}>
                    <PDivider style={{ backgroundColor: "#909090", height: "2px" }}></PDivider>
                  </PGridItem>
                  <PGridItem size={3}>
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </PGrid>
            {/* Divider Block End */}
          </div>
          {/* Sub headers section Start*/}
          <div style={{ backgroundColor: "#FFFFFF", height: "46px" }}>
            <PGrid>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px", color: "#909090" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Amount (gross)")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Discount in %")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                </PGrid>&ensp;
              </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (gross)")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Discount in %")}</span></PorscheFont>
                    </div>
                  </PGridItem>

                  <PGridItem size={3}>
                    <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                      <PFlex justifyContent={"flex-end"}>
                        <PFlexItem>
                          <Stack direction="row" spacing={1}>
                            <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                            <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                    </div>
                  </PGridItem>
                </PGrid>
              </PGridItem>
            </PGrid>
          </div>
          {/* Sub headers section End*/}

          { recalTradeInFields && recalTradeInFields.length>0 && tradeInFields && tradeInFields?.map((trade, i) => {
              if (i > 2) {
                let show = false;
                if (order.tradeInDetails && (order.tradeInDetails.tradeInType === "Leasing_Redemption" || 
                  order.tradeInDetails.tradeInType === "Financing_Redemption_Regular" ||
                  order.tradeInDetails.tradeInType === "Financing_Redemption_Differential" ) && (tradeInFields[2].amountNet < 0 || recalTradeInFields[2].amountNet < 0)) {
                  show = true;
                }
              // if (tradeInFields[2].amountNet < 0) show = true;
                if (!show) return (<></>);
              }

              if ( i === 2) {
                return (
                  <div style={{ backgroundColor: "#FAFAFA", height: "56px" }}>
                    <PGrid >
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "left" }}>
                              <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>
                                { (tradeInFields[2].amountNet >= 0 && recalTradeInFields[2].amountNet >= 0)
                                  ? t("Profit booking")
                                  :  (tradeInFields[2].amountNet < 0 && recalTradeInFields[2].amountNet < 0)
                                  ? t("Loss booking")
                                  : t("Booking")
                                }
                              </span></PorscheFont>
                            </div></PGridItem>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "right" }}>
                              <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(tradeInFields[i].amountNet)}{" €"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "right" }}>
                              <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(tradeInFields[i].amountGross)}{" €"}</span></PorscheFont>
                            </div>
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{textAlign: "right"}}>
                              {order?.tradeInDetails && (order?.tradeInDetails?.tradeInType !== "Leasing_Redemption" && 
                              order.tradeInDetails.tradeInType !== "Financing_Redemption_Regular" &&
                              order.tradeInDetails.tradeInType !== "Financing_Redemption_Differential" )  && (
                                <>
                                  <PorscheFont style={{ marginTop: "15px", color: "#909090"}}>
                                  { vehiclePrices && vehiclePrices[0].amountNet > 0 
                                      ? formatCurrencyWithNoUnit(tradeInFields[2].amountNet/( vehiclePrices[0]?.amountNet)* -1 * 100)
                                      : "0.00"
                                  }{" %"}
                                  </PorscheFont>
                                </>
                              )}

                              {order.tradeInDetails && (order.tradeInDetails.tradeInType === "Leasing_Redemption" ||
                              order.tradeInDetails.tradeInType === "Financing_Redemption_Regular" ||
                              order.tradeInDetails.tradeInType === "Financing_Redemption_Differential" ) &&
                              (tradeInFields[2].amountNet >= 0) && (
                                <PorscheFont style={{ marginTop: "15px", color: "#909090"}}>
                                  { vehiclePrices && vehiclePrices[0].amountNet > 0 
                                    ? formatCurrencyWithNoUnit(tradeInFields[2].amountNet/(vehiclePrices[0]?.amountNet)* -1 * 100)
                                    : "0.00"
                                  }{" %"}
                                </PorscheFont>
                                )
                              }
                            </div>
                          </PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`tian${i}`} unit="€" unitPosition="suffix" >
                              <input
                                aria-autocomplete='none'
                                autoComplete='off'
                                //ef={tRef[i].net}
                                id={`itian${i}`}
                                key={`itian${i}`}
                                type="text"
                                value={formatCurrencyWithNoUnit(recalTradeInFields.length > 0 ? recalTradeInFields[i].amountNet : 0.00 )}
                                style={{ textAlign: "right" }}
                                readOnly
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`tiag${i}`} unit="€" unitPosition="suffix" >
                              <input
                                aria-autocomplete='none'
                                autoComplete='off'
                                //ref={tRef[i].gross}
                                id={`itiag${i}`}
                                key={`ktiag${i}`}
                                type="text"
                                value={formatCurrencyWithNoUnit(recalTradeInFields.length > 0 ? recalTradeInFields[i].amountGross : 0.00 )}
                                style={{ textAlign: "right" }}
                                readOnly
                              />
                            </PTextFieldWrapper>
                          </PGridItem>
                          <PGridItem size={3}>
                          {order?.tradeInDetails && (order?.tradeInDetails?.tradeInType !== "Leasing_Redemption" && 
                              order.tradeInDetails.tradeInType !== "Financing_Redemption_Regular" &&
                              order.tradeInDetails.tradeInType !== "Financing_Redemption_Differential" )  && (
                                <>
                                  <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`tiad${i}`} unit="%" unitPosition="suffix" >
                                    <input
                                      aria-autocomplete='none'
                                      autoComplete='off'
                                      //ref={tRef[i].percent}
                                      id={`itiad${i}`}
                                      key={`ktiad${i}`}
                                      type="text"
                                      //defaultValue={formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0 ? (recalTradeInFields[2].amountNet/recalvehiclePrices[0].amountNet * -1 * 100) : 0.00 )}
                                      value={formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0 ? (recalTradeInFields[2].amountNet/recalvehiclePrices[0].amountNet * -1 * 100) : 0.00 )}
                                      style={{ textAlign: "right" }}
                                      readOnly
                                    />
                                  </PTextFieldWrapper>
                                </>
                              )}

                              {order.tradeInDetails && (order.tradeInDetails.tradeInType === "Leasing_Redemption" ||
                              order.tradeInDetails.tradeInType === "Financing_Redemption_Regular" ||
                              order.tradeInDetails.tradeInType === "Financing_Redemption_Differential" ) &&
                              (recalTradeInFields[2].amountNet >= 0) && (
                                <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`tiad${i}`} unit="%" unitPosition="suffix" >
                                  <input
                                    aria-autocomplete='none'
                                    autoComplete='off'
                                    //ref={tRef[i].percent}
                                    id={`itiad${i}`}
                                    key={`ktiad${i}`}
                                    type="text"
                                    //defaultValue={formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0 ? (recalTradeInFields[2].amountNet/recalvehiclePrices[0].amountNet * -1 * 100) : 0.00 )}
                                    value={formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0 ? (recalTradeInFields[2].amountNet/recalvehiclePrices[0].amountNet * -1 * 100) : 0.00 )}
                                    style={{ textAlign: "right" }}
                                    readOnly
                                  />
                                </PTextFieldWrapper>
                                )
                              }
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }}>
                              <PFlex justifyContent={"flex-end"}>
                                <PFlexItem>
                                  <Stack direction="row" spacing={1}>
                                  { (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)}{" €"}</PorscheFont>
                                      { (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet) > 0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)}{" €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }
                                  </Stack>
                                </PFlexItem>
                              </PFlex>
                            </div>
                          </PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                    </PGrid>
                  </div>
                )
              }

              return (
                <div style={{ backgroundColor: i%2 === 0 ? "#FAFAFA" : "#FFFFFF", height: "56px" }}>
                    <PGrid >
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                            <div style={{ textAlign: "left" }}>
                              <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}><span>{t(getTradeInName(trade.tradeInColumn))}</span></PorscheFont>
                            </div></PGridItem>
                          <PGridItem size={3}>
                            {i < 3 && (
                              <div style={{ textAlign: "right" }}>
                                <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(tradeInFields[i].amountNet)}{" €"}</span></PorscheFont>
                              </div>
                            )}
                            {(order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
                            order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
                            order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential" ) &&
                            (tradeInFields[2].amountNet < 0) &&
                            i > 2 && (
                              <div style={{ textAlign: "right" }}>
                                <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(tradeInFields[i].amountNet)}{" €"}</span></PorscheFont>
                              </div>
                            )}
                          </PGridItem>
                          <PGridItem size={3}>
                            { i < 3 && (
                              <div style={{ textAlign: "right" }}>
                                <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(tradeInFields[i].amountGross)}{" €"}</span></PorscheFont>
                              </div>
                            )}
                            {(order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
                              order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
                              order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential" ) &&
                              (tradeInFields[2].amountNet < 0) &&
                              i > 2 && (
                              <div style={{ textAlign: "right" }}>
                                <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(tradeInFields[i].amountGross)}{" €"}</span></PorscheFont>
                              </div>
                            )}
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{textAlign: "right"}}>
                            {(order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
                              order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
                              order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential" ) &&
                              (tradeInFields[2].amountNet < 0) &&
                              i === 3 && (
                                <PorscheFont style={{ marginTop: "15px", color: "#909090"}}>
                                {vehiclePrices && vehiclePrices[0].amountNet > 0 ? formatCurrencyWithNoUnit(tradeInFields[3].amountNet/( vehiclePrices[0]?.amountNet)* -1 * 100) : "0,00"}{" %"}
                                </PorscheFont>
                              )}
                            </div>
                          </PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                      <PGridItem size={6}>
                        <PGrid>
                          <PGridItem size={3}>
                          {i < 3 && (
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`tian${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                              <input
                                maxLength={inputFieldMaxLength}
                                aria-autocomplete='none'
                                autoComplete='off'
                                ref={tRef[i].net}
                                id={`itian${i}`}
                                key={`itian${i}`}
                                type="text"
                                defaultValue={formatCurrencyWithNoUnit(recalTradeInFields.length > 0 ? recalTradeInFields[i].amountNet : 0.00 )}
                                //onChange={ (e) => handleTradeInAmountNet(e.target.value, i, `itian${i}`)}
                                onBlur={(e) => {
                                  handleTradeInAmountNet(e.target.value, i, `itian${i}`)
                                  if(i === 0 || i === 1) validateTradeInFields(e.target.value, i, `itian${i}`)
                                  if(i === 0 && tRef[0].net.current) tRef[0].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  if(i === 1 && tRef[1].net.current) tRef[1].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  if(i === 2 && tRef[2].net.current) tRef[2].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  if(i === 3 && tRef[3].net.current) tRef[3].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  if(i === 4 && tRef[4].net.current) tRef[4].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  }
                                }
                                style={{ textAlign: "right" }}
                              />
                            </PTextFieldWrapper>
                          )}
                          {(order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
                            order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
                            order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential" ) &&
                            (recalTradeInFields[2].amountNet < 0) &&
                            i > 2 && (
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`tian${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                              <input
                                maxLength={inputFieldMaxLength}
                                aria-autocomplete='none'
                                autoComplete='off'
                                ref={tRef[i].net}
                                id={`itian${i}`}
                                key={`itian${i}`}
                                type="text"
                                defaultValue={formatCurrencyWithNoUnit(recalTradeInFields.length > 0 ? recalTradeInFields[i].amountNet : 0.00 )}
                                //onChange={ (e) => handleTradeInAmountNet(e.target.value, i, `itian${i}`)}
                                onBlur={(e) => {
                                  handleTradeInAmountNet(e.target.value, i, `itian${i}`)
                                  if(i === 0 || i === 1) validateTradeInFields(e.target.value, i, `itian${i}`)
                                  if(i === 0 && tRef[0].net.current) tRef[0].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  if(i === 1 && tRef[1].net.current) tRef[1].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  if(i === 2 && tRef[2].net.current) tRef[2].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  if(i === 3 && tRef[3].net.current) tRef[3].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  if(i === 4 && tRef[4].net.current) tRef[4].net.current.value = formatCurrencyWithNoUnit(recalTradeInFields?.[i].amountNet);
                                  }
                                }
                                style={{ textAlign: "right" }}
                              />
                            </PTextFieldWrapper>
                          )}
                          </PGridItem>
                          <PGridItem size={3}>
                          {i < 3 && (
                            <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`tiag${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                              <input
                                maxLength={inputFieldMaxLength}
                                aria-autocomplete='none'
                                autoComplete='off'
                                ref={tRef[i].gross}
                                id={`itiag${i}`}
                                key={`itiag${i}`}
                                type="text"
                                defaultValue={formatCurrencyWithNoUnit(recalTradeInFields.length > 0 ? recalTradeInFields[i].amountGross : 0.00 )}
                                //onChange={ (e) => handleTradeInAmountGross(e.target.value, i, `tiag${i}`)}
                                onBlur={(e) => {
                                    handleTradeInAmountGross(e.target.value, i, `tiag${i}`)
                                    if(i === 0 || i === 1) validateTradeInFields(e.target.value, i, `tiag${i}`)
                                    if(i === 0 && tRef[0].gross.current) tRef[0].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                    if(i === 1 && tRef[1].gross.current) tRef[1].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                    if(i === 2 && tRef[2].gross.current) tRef[2].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                    if(i === 3 && tRef[3].gross.current) tRef[3].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                    if(i === 4 && tRef[4].gross.current) tRef[4].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                  }
                                }
                                style={{ textAlign: "right" }}
                              />
                            </PTextFieldWrapper>
                          )}
                          {(order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
                            order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
                            order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential" ) &&
                            (recalTradeInFields[2].amountNet < 0) &&
                            i > 2 && (
                              <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`tiag${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                              <input
                                maxLength={inputFieldMaxLength}
                                aria-autocomplete='none'
                                autoComplete='off'
                                ref={tRef[i].gross}
                                id={`itiag${i}`}
                                key={`itiag${i}`}
                                type="text"
                                defaultValue={formatCurrencyWithNoUnit(recalTradeInFields.length > 0 ? recalTradeInFields[i].amountGross : 0.00 )}
                                //onChange={ (e) => handleTradeInAmountGross(e.target.value, i, `tiag${i}`)}
                                onBlur={(e) => {
                                    handleTradeInAmountGross(e.target.value, i, `tiag${i}`)
                                    if(i === 0 || i === 1) validateTradeInFields(e.target.value, i, `tiag${i}`)
                                    if(i === 0 && tRef[0].gross.current) tRef[0].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                    if(i === 1 && tRef[1].gross.current) tRef[1].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                    if(i === 2 && tRef[2].gross.current) tRef[2].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                    if(i === 3 && tRef[3].gross.current) tRef[3].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                    if(i === 4 && tRef[4].gross.current) tRef[4].gross.current.value = formatCurrencyWithNoUnit(recalTradeInFields[i].amountGross);
                                  }
                                }
                                style={{ textAlign: "right" }}
                              />
                            </PTextFieldWrapper>
                            )}
                          </PGridItem>
                          <PGridItem size={3}>
                          {(order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
                            order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
                            order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential" ) &&
                            (recalTradeInFields[2].amountNet < 0) &&
                            i === 3 && (
                              <>
                                <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`tiad${i}`} unit="%" unitPosition="suffix" >
                                  <input
                                    aria-autocomplete='none'
                                    autoComplete='off'
                                    //ref={tRef[i].gross}
                                    id={`itiad${i}`}
                                    key={`ktiad${i}`}
                                    type="text"
                                    value={formatCurrencyWithNoUnit(recalvehiclePrices[0].amountNet > 0 ? (recalTradeInFields[3].amountNet/recalvehiclePrices[0].amountNet * -1 * 100) : 0.00 )}
                                    style={{ textAlign: "right" }}
                                    readOnly
                                  />
                                </PTextFieldWrapper>
                              </>
                              )}
                          </PGridItem>
                          <PGridItem size={3}>
                            <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }}>
                              <PFlex justifyContent={"flex-end"}>
                                <PFlexItem>
                                  <Stack direction="row" spacing={1}>
                                  { i === 0 && (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)}{" €"}</PorscheFont>
                                      { (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet) > 0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {i === 0 && (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)}{" €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }

                                  { i === 1 && (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)}{" €"}</PorscheFont>
                                      { (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet) < 0?(
                                        <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {i === 1 && (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)}{" €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }
                                  { i === 3 && sharePcPercent !==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >
                                        { formatCurrencyWithNoUnit(sharePcPercent) + " %" }
                                      </PorscheFont>
                                      { sharePcPercent < 0?(
                                        <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {i === 3 && sharePcPercent === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{ formatCurrencyWithNoUnit(sharePcPercent) }{" %"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }

                                  { i === 4 && (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)}{" €"}</PorscheFont>
                                      { (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet) > 0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {i === 4 && (recalTradeInFields[i].amountNet-tradeInFields[i].amountNet) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalTradeInFields[i].amountNet-tradeInFields[i].amountNet)}{" €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }
                                  </Stack>
                                </PFlexItem>
                              </PFlex>
                            </div>
                          </PGridItem>
                        </PGrid>&ensp;
                      </PGridItem>
                    </PGrid>
                  </div>
              )
            })
          }
          
        </div>&ensp;
          {/* Comment Section Start*/}
          <div style={{ backgroundColor: "#FFFFFF", height: "79px" }}>
          <PGrid>
            <PGridItem size={6}>
              <div style={{ textAlign: "left", paddingLeft: "20px" }}>
                <Stack direction="column">
                  <PorscheFont><span className="sub-header1">{t("Comment (Optional)")}</span></PorscheFont>
                  <PorscheFont><span className="sub-header2">{reCalculation?.preCalculation?.tradeInComment ? reCalculation?.preCalculation?.tradeInComment : ""}</span></PorscheFont>
                </Stack>
              </div>
            </PGridItem>
            <PGridItem size={6}>
              <PGrid>
                <PGridItem size={9}>
                  <div style={{ marginTop: "-4px" }}>
                    <PTextFieldWrapper id="vpbpn0" label={`${t("Comment (optional)")}`}>
                      <input
                        aria-autocomplete='none'
                        autoComplete='off'
                        id={`iticomment`}
                        key={`kticomment`}
                        type="text"
                        defaultValue={ reCalculation?.reCalculation?.tradeInComment ?  reCalculation?.reCalculation?.tradeInComment : ""}
                        onChange={ (e) => handleTradeInComment(e.target.value)}
                        //style={{ textAlign: "right" }}
                      />
                    </PTextFieldWrapper>
                  </div>
                </PGridItem>
                <PGridItem size={3}>
                </PGridItem>
              </PGrid>&ensp;
            </PGridItem>
          </PGrid>
        </div>
        {/* Comment Section End*/}
      </div>
    )}
    {/* Trade-in End */}

      {/** Payment Details*/}
      {order?.paymentDetails?.paymentType !== "SUBSCRIPTION" && (
        <div className="Recal-card vehicle-price">
          <div>
            <PorscheFont><span className="dcs-section-heading" style={{ marginLeft: "20px" }}>{getPayementTypeName(order?.paymentDetails?.paymentType!)}</span></PorscheFont>&ensp;
            <div style={{ backgroundColor: "#FFFFFF" }}>
              {/* Header block start */}
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont style={{ paddingBottom: "4px" }}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont style={{ paddingBottom: "4px" }}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
              {/* Header block End */}
              {/* Divider Block Start */}
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={9}>
                      <PDivider style={{ backgroundColor: "#C8CACB", height: "2px" }}></PDivider>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={9}>
                      <PDivider style={{ backgroundColor: "#909090", height: "2px" }}></PDivider>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
              {/* Divider Block End */}
            </div>
            {/* Sub headers section Start*/}
            <div style={{ backgroundColor: "#FFFFFF", height: "46px" }}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px", color: "#909090" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Amount (net)")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2" style={{ color: "#909090" }}>{t("Amount (gross)")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont style={{ marginTop: "8px" }}><span className="sub-header2">{t("Amount (gross)")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                              <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                              <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
            </div>
            {/* Sub headers section End*/}

            {/** Down */}
            { calPaymentDetails && calPaymentDetails.paymentType !== "DebitCredit" && (
              <div style={{ backgroundColor: "#FAFAFA", height: "56px" }}>
                <PGrid >
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "left" }}>
                          <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}>
                          <span>
                            {getPayementTypeName(order?.paymentDetails?.paymentType!) ===
                            "Leasing"
                              ? "Special leasing payment "
                              : "Down payment "}
                          </span>
                          </PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(calPaymentDetails?.paymentNet ? calPaymentDetails?.paymentNet : 0.00)}{" €"}</span></PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(calPaymentDetails?.paymentGross ? calPaymentDetails?.paymentGross : 0.00)}{" €"}</span></PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={3}></PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                        <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`pddpan`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete='none'
                            autoComplete='off'
                            ref={dpRef[0].net}
                            id={`ipddpan`}
                            key={`kpddpan`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(recalCalculationPayment?.paymentNet ? recalCalculationPayment?.paymentNet : 0.00)}
                            //onChange={ (e) => handlePaymentAmountNet(e.target.value, "down payment", `pddpan`)}
                            onBlur={(e) => {
                                handlePaymentAmountNet(e.target.value, "down payment", `pddpan`)
                                if(dpRef[0].net.current) dpRef[0].net.current.value = formatCurrencyWithNoUnit(recalCalculationPayment?.paymentNet ? recalCalculationPayment.paymentNet : 0.00 )
                              }
                            }
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={3}>
                        <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pddpag`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete='none'
                            autoComplete='off'
                            ref={dpRef[0].gross}
                            id={`ipddpag`}
                            key={`kpddpag`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(recalCalculationPayment?.paymentGross ? recalCalculationPayment?.paymentGross : 0.00)}
                            //onChange={ (e) => handlePaymentAmountGross(e.target.value, "down payment", `pddpag`)}
                            onBlur={(e) => {
                                handlePaymentAmountGross(e.target.value, "down payment", `pddpag`)
                                if(dpRef[0].gross.current) dpRef[0].gross.current.value = formatCurrencyWithNoUnit(recalCalculationPayment?.paymentGross ? recalCalculationPayment?.paymentGross : 0.00)
                              }
                            }
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={3}>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                                { recalCalculationPayment?.paymentNet !== undefined && calPaymentDetails.paymentNet !== undefined &&
                                  (recalCalculationPayment.paymentNet-calPaymentDetails.paymentNet)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>{formatCurrencyWithNoUnit(recalCalculationPayment.paymentNet-calPaymentDetails.paymentNet)}{" €"}</PorscheFont>
                                      { (recalCalculationPayment.paymentNet-calPaymentDetails.paymentNet)>0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )
                                }
                                { recalCalculationPayment?.paymentNet !== undefined && calPaymentDetails.paymentNet !== undefined &&
                                  (recalCalculationPayment.paymentNet - calPaymentDetails.paymentNet)===0 && (
                                      <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00 €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                      </>
                                  )
                                }
                              </Stack>
                            </PFlexItem>
                          </PFlex>
                        </div>
                      </PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                </PGrid>
              </div>
            )}

            {calPaymentDetails && recalCalculationPayment &&(
            <div style={{ backgroundColor: calPaymentDetails && calPaymentDetails.paymentType !== "DebitCredit" ? "#FFFFFF": "#FAFAFA", height: "56px" }}>
                <PGrid >
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "left" }}>
                          <PorscheFont style={{ marginTop: "15px", paddingLeft: "20px" }}>
                          <span>
                            {t("Up-front payment ")}
                          </span>
                          </PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(calPaymentDetails.upFrontPaymentNet)}{" €"}</span></PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont style={{ marginTop: "15px", color: "#909090" }}><span>{formatCurrencyWithNoUnit(calPaymentDetails.upFrontPaymentGross)}{" €"}</span></PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={3}></PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                        <PTextFieldWrapper style={{ marginTop: "4px", height: "40px", color: "#313639" }} id={`pdufpan`} unit="€"  unitPosition="suffix" showCharacterCount={false}>
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete='none'
                            autoComplete='off'
                            ref={upRef[0].net}
                            id={`ipdufpan`}
                            key={`kpdufpan`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(recalCalculationPayment?.upFrontPaymentNet  ? recalCalculationPayment.upFrontPaymentNet : 0.00)}
                            onChange={ (e) => handlePaymentAmountNet(e.target.value, "upfront payment", `pdufpan`)}
                            onBlur={(e) => {
                                handlePaymentAmountNet(e.target.value, "upfront payment", `pdufpan`)
                                if(upRef[0].net.current) upRef[0].net.current.value = formatCurrencyWithNoUnit(recalCalculationPayment?.upFrontPaymentNet ? recalCalculationPayment.upFrontPaymentNet : 0.00 )
                              }
                            }
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={3}>
                        <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`pdufpag`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete='none'
                            autoComplete='off'
                            ref={upRef[0].gross}
                            id={`ipdufpag`}
                            key={`kpdufpag`}
                            type="text"
                            defaultValue={formatCurrencyWithNoUnit(recalCalculationPayment?.upFrontPaymentGross ? recalCalculationPayment?.upFrontPaymentGross : 0.00)}
                            //onChange={ (e) => handlePaymentAmountGross(e.target.value, "upfront payment", `pdufpag`)}
                            onBlur={(e) => {
                                handlePaymentAmountGross(e.target.value, "upfront payment", `pdufpag`)
                                if(upRef[0].gross.current) upRef[0].gross.current.value  = formatCurrencyWithNoUnit(recalCalculationPayment?.upFrontPaymentGross ? recalCalculationPayment?.upFrontPaymentGross : 0.00)
                              }
                            }
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={3}>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                                { recalCalculationPayment?.upFrontPaymentNet !== undefined && calPaymentDetails.upFrontPaymentNet !== undefined &&
                                  (recalCalculationPayment.upFrontPaymentNet-calPaymentDetails.upFrontPaymentNet)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>{formatCurrencyWithNoUnit(recalCalculationPayment.upFrontPaymentNet-calPaymentDetails.upFrontPaymentNet)}{" €"}</PorscheFont>
                                      { (recalCalculationPayment.upFrontPaymentNet-calPaymentDetails.upFrontPaymentNet)>0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )
                                }
                                { recalCalculationPayment?.upFrontPaymentNet !== undefined && calPaymentDetails.upFrontPaymentNet !== undefined &&
                                  (recalCalculationPayment.upFrontPaymentNet-calPaymentDetails.upFrontPaymentNet)===0 && (
                                      <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00 €"}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                      </>
                                  )
                                }
                              </Stack>
                            </PFlexItem>
                          </PFlex>
                        </div>
                      </PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                </PGrid>
              </div>
            )}
          </div>
          {order?.paymentDetails?.paymentType !== "DebitCredit" && (
            <>
              {/* <PDivider color="neutral-contrast-high"></PDivider> */}
              <div style={{ backgroundColor: calPaymentDetails && calPaymentDetails.paymentType !== "DebitCredit" ? "#FAFAFA" : "#FFFFFF", minHeight: "56px", paddingBottom: "4px"}} >
                <PGrid>
                  <PGridItem size={3}>
                    <PorscheFont style={{ paddingTop: "5px", paddingLeft: "20px" }}>
                      <span style={{ color: "#313639", fontSize: "16px", fontWeight: "400" }}>
                        {t(`Include in ${getPayementTypeName(order?.paymentDetails?.paymentType)} (Optional)`)}
                      </span>
                    </PorscheFont>
                  </PGridItem>
                  <PGridItem size={9}></PGridItem>
                </PGrid>
                <PFlex style={{ paddingLeft: "20px" }}>
                  {additionalCosts?.map((item, i) => {
                    let index = calPaymentDetails?.includeOptionals ? calPaymentDetails.includeOptionals.indexOf(item.type) : null;

                    if (item.customerShare > 0) {
                      return (
                        <PFlexItem style={{ marginRight: "8px" }}>
                          <div className={`mt12 include-btn ${index !== null && index > -1 ? "include-btn-selected" : ""} disabled`} style={{ paddingRight: "14px", paddingLeft: "14px" }} >
                            <Stack direction={"row"} spacing={1}>
                              <PorscheFont className='dcs-title'>
                                {getIncludeOptionsLabel(item.label)}
                              </PorscheFont>
                            </Stack>
                          </div>
                        </PFlexItem>
                      );
                    }
                  })
                  }
                </PFlex>
                <PFlex style={{ paddingLeft: "20px" }}>
                  {tires?.map((item, i) => {
                    let index = calPaymentDetails?.includeOptionals ? calPaymentDetails?.includeOptionals.indexOf("CompleteWinterTires") : null;
                    return (
                      <>
                        {item.customerShare > 0 && (
                          <PFlexItem style={{ marginRight: "8px" }}>
                            <div className={`mt12 include-btn ${index !== null && index > -1 ? "include-btn-selected" : ""} disabled`} style={{ paddingRight: "14px", paddingLeft: "14px" }}>
                              <Stack direction={"row"} spacing={1}>
                                <PorscheFont className='dcs-title'>
                                  {"WCT"}
                                </PorscheFont>
                              </Stack>
                            </div>
                          </PFlexItem>
                        )}

                        {item.serviceItems.length > 0 && (
                          <>
                            {item.serviceItems.map((service, j) => {
                              let index1 = calPaymentDetails?.includeOptionals ? calPaymentDetails?.includeOptionals.indexOf(service.services.serviceNameUpsale) : null;
                              if (service.customerShare > 0) {
                                return (
                                  <PFlexItem style={{ marginRight: "8px" }}>
                                    <div className={`mt12 include-btn ${index1 !== null && index1 > -1 ? "include-btn-selected" : ""} disabled`} style={{ paddingRight: "14px", paddingLeft: "14px" }}>
                                      <Stack direction={"row"} spacing={1}>
                                        <PorscheFont className='dcs-title'>
                                          {getIncludeOptionsLabel( getWorkshopServiceName(item.serviceItems[j].services.serviceNameUpsale, j === 0 ? "winter" : "summer"))}
                                        </PorscheFont>
                                      </Stack>
                                    </div>
                                  </PFlexItem>
                                )
                              }
                            })
                            }
                          </>
                        )}
                      </>
                    )
                  })
                  }
                  {parts?.map((item, i) => {
                    if (i > 0) return (<></>);
                    return (
                      <>
                        {item.customerShare > 0 && (
                          <PFlexItem style={{ marginRight: "8px" }}>
                            <div className='mt12 include-btn disabled' style={{ paddingRight: "14px", paddingLeft: "14px" }} >
                              <Stack direction={"row"} spacing={1}>
                                <PorscheFont className='dcs-title'>
                                  {"Parts"}
                                </PorscheFont>
                              </Stack>
                            </div>
                          </PFlexItem>
                        )}

                        {item.serviceItems.length > 0 && item.serviceItems[0].customerShare > 0 && (
                          <PFlexItem style={{ marginRight: "8px" }}>
                            <div className='mt12 include-btn disabled' style={{ paddingRight: "14px", paddingLeft: "14px" }}>
                              <Stack direction={"row"} spacing={1}>
                                <PorscheFont className='dcs-title'>
                                  {"Installation"}
                                </PorscheFont>
                              </Stack>
                            </div>
                          </PFlexItem>
                        )}
                      </>
                    )
                  })
                  }
                </PFlex>
              </div>
            </>
          )}
        </div>
      )}
     
        {/** Final Calculation */}
        <div className="Recal-card vehicle-price">
        <div>
          <PorscheFont style={{paddingLeft:"20px",paddingRight:"20px"}}><span className="dcs-section-heading">{t("Final calculation")}</span></PorscheFont>&ensp;
          <div style={{ backgroundColor: "#FFFFFF"}}>
              {/* Header block start */}
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>                  
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont  style={{paddingBottom:"4px"}}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>                                    
                    </PGridItem>
                  </PGrid>
                 </PGridItem>
                <PGridItem size={6}>
                 <PGrid>
                    <PGridItem size={3}>                   
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "center" }}>
                        <PorscheFont  style={{paddingBottom:"4px"}}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>                  
                    </PGridItem>
                    <PGridItem size={3}>                 
                    </PGridItem>
                  </PGrid>        
                </PGridItem>
                </PGrid>
              {/* Header block End */}
             {/* Divider Block Start */}
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>                    
                    <PGridItem size={9}>
                        <PDivider style={{backgroundColor:"#C8CACB",height:"2px"}}></PDivider>
                     </PGridItem>                   
                  </PGrid>
                 </PGridItem>
                <PGridItem size={6}>
                 <PGrid>
                    <PGridItem size={9}>                     
                    <PDivider style={{backgroundColor:"#909090",height:"2px"}}></PDivider>                                    
                    </PGridItem>                  
                    <PGridItem size={3}>                 
                    </PGridItem>
                  </PGrid>        
                </PGridItem>
                </PGrid>
             {/* Divider Block End */}
           </div>         
           {/* Sub headers section Start*/}
           <div style={{ backgroundColor: "#FFFFFF", height: "46px"}}>
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"8px"}}><span className="sub-header1">{t("Amount in €")}</span></PorscheFont>
                   </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header1">{t("% of Sale (net)")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header1">{t("Days")}</span></PorscheFont>
                      </div>                                     
                    </PGridItem>
                  </PGrid>&ensp;
                 </PGridItem>
                <PGridItem size={6}>
                <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"8px"}}><span className="sub-header2">{t("Amount in €")}</span></PorscheFont>
                   </div>
                        </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header2">{t("% of Sale (net)")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}> 
                    <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header2">{t("Days")}</span></PorscheFont>
                      </div>                  
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "8px", textAlign: "right", paddingRight: "20px" }} >
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                              <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                              <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>       
                </PGridItem>
                </PGrid>
           </div>
           {/* Sub headers section End*/}             
         {/* Gray Box div  */}
         {finalCalculation && recalFinalCalculation && (<>
              <div style={{ backgroundColor: "#FAFAFA", height: "56px"}}>
                <PGrid >
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("Total costs")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(finalCalculation.totalCosts)}{" "}</span></PorscheFont>
                   </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                 </PGridItem>
                <PGridItem size={6}>
                <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{marginTop: "4px", height: "40px" }} id="vpbpn0" unit="€" unitPosition="suffix" >
                        <input
                          aria-autocomplete='none'
                          autoComplete='off'
                          readOnly
                          id="ivpbpn0"
                          key="ivpbpn0"
                          type="text"
                          value={formatCurrencyWithNoUnit(recalFinalCalculation?.totalCosts ? recalFinalCalculation?.totalCosts : 0.00)}                           
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                    
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }} className='dcs-heading'>
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                            { recalFinalCalculation?.totalCosts !== undefined && finalCalculation?.totalCosts !== undefined &&
                                  (recalFinalCalculation?.totalCosts-finalCalculation?.totalCosts) !== 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>{formatCurrencyWithNoUnit(recalFinalCalculation?.totalCosts-finalCalculation?.totalCosts)}{" €"}</PorscheFont>
                                      { (recalFinalCalculation?.totalCosts-finalCalculation?.totalCosts) < 0
                                        ? (<img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                        : ( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )
                                }
                                { recalFinalCalculation?.totalCosts !== undefined && finalCalculation?.totalCosts !== undefined &&
                                  (recalFinalCalculation?.totalCosts-finalCalculation?.totalCosts) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00 €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                    </>
                                  )
                                }
                            </Stack>
                          </PFlexItem>
                        </PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>&ensp;        
                </PGridItem>
                </PGrid>
              </div>
              {/* White Box div  */}
              <div style={{ backgroundColor: "#FFFFFF", height: "56px"}}>
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("Gross profit")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(finalCalculation.grossProfit ? finalCalculation.grossProfit : 0.00)}</span></PorscheFont>
                   </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"15px"}}><span className="sub-header1">
                        {`${finalCalculation.grossProfit && vehiclePrices && vehiclePrices[0].amountNet > 0
                              ? formatCurrencyWithNoUnit(finalCalculation.grossProfit / vehiclePrices[0].amountNet * 100)
                              : "0,00"
                          } %`}
                        </span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                 </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="fcgpe" unit="€" unitPosition="suffix" >
                        <input
                          aria-autocomplete='none'
                          autoComplete='off'
                          readOnly
                          id="ifcgpe"
                          key="kfcgpe"
                          type="text"
                          value={formatCurrencyWithNoUnit(recalFinalCalculation.grossProfit ? recalFinalCalculation.grossProfit : 0.00)}
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{  marginTop: "4px", height: "40px" }} unit="%" id="fcgpp" unitPosition="suffix" >
                          <input
                            aria-autocomplete='none'
                            autoComplete='off'
                            readOnly
                            id="ifcgpp"
                            key="ifcgpp"
                            type="text"
                            value={
                              `${recalFinalCalculation.grossProfit && recalvehiclePrices && recalvehiclePrices[0].amountNet > 0
                                ? formatCurrencyWithNoUnit(recalFinalCalculation.grossProfit / recalvehiclePrices[0].amountNet * 100)
                                : "0,00"
                            }`}                     
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px"}} className='dcs-heading'>
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                              { grossPercent !==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                      {formatCurrencyWithNoUnit(grossPercent)}{" %"}
                                    </PorscheFont>
                                    { grossPercent >0?(
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                )
                              }
                              { grossPercent ===0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0,00 %"}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                  </>
                                )
                              }
                            </Stack>
                          </PFlexItem>
                        </PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>&ensp;        
                </PGridItem>
                </PGrid>
              </div>
              {/* Gray Box div  */}
              <div style={{ backgroundColor: "#FAFAFA", height: "56px" }}>
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("Standing days")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                  
                    </PGridItem>
                    <PGridItem size={3}>
                      </PGridItem>
                    <PGridItem size={3}> 
                    <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"15px"}}><span className="sub-header1">{formatToWholeNumber(onlineCarSaleInfo.standingDays ? onlineCarSaleInfo.standingDays : 0)}</span></PorscheFont>
                      </div>                 
                    </PGridItem>
                  </PGrid>&ensp;
                 </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}> 
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="fcsd" >
                        <input
                          aria-autocomplete='none'
                          autoComplete='off'
                          readOnly
                          id="ifcsd"
                          key="kfcsd"
                          type="text"
                          value={formatToWholeNumber( recalOnlineCarSaleInfo?.standingDays ? recalOnlineCarSaleInfo?.standingDays : 0)}                           
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>                  
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }} className='dcs-heading'>
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                                {/* {(recalOnlineCarSaleInfo?.standingDays!-onlineCarSaleInfo?.standingDays!)!==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatToWholeNumber(recalOnlineCarSaleInfo?.standingDays!-onlineCarSaleInfo?.standingDays!)}</PorscheFont>
                                    { (recalOnlineCarSaleInfo?.standingDays!-onlineCarSaleInfo?.standingDays!) > 0?(
                                      <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                )}
                                {(recalOnlineCarSaleInfo?.standingDays!-onlineCarSaleInfo?.standingDays!) === 0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{'0'}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                  </>
                                  )
                                } */}
                                { onlineCarSaleInfo?.standingDays !== undefined && recalOnlineCarSaleInfo?.standingDays !== undefined &&
                                  (recalOnlineCarSaleInfo?.standingDays - onlineCarSaleInfo?.standingDays) !== 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading'>
                                        {formatToWholeNumber(recalOnlineCarSaleInfo?.standingDays - onlineCarSaleInfo?.standingDays)}
                                      </PorscheFont>
                                      { (recalOnlineCarSaleInfo?.standingDays - onlineCarSaleInfo?.standingDays) < 0 
                                        ? (<img src={require("../../../assets/images/Arrow-green-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>) 
                                        : ( <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )
                                }
                                { onlineCarSaleInfo?.standingDays !== undefined && recalOnlineCarSaleInfo?.standingDays !== undefined &&
                                  (recalOnlineCarSaleInfo?.standingDays - onlineCarSaleInfo?.standingDays) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title'>{"0"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px",marginTop:"3px",visibility:"hidden"}}></img>
                                    </>
                                  )
                                }
                              </Stack>
                            </PFlexItem>                            
                          </PFlex>
                        </div>
                    </PGridItem>
                  </PGrid>&ensp;        
                </PGridItem>
                </PGrid>
              </div>
            </>
          )
        }
        </div>    
       </div>

      {/** Calculation customer */}
      { calculationCustomer && recalCalculationCustomer && (<div className="Recal-card vehicle-price">
          <div>
            <PorscheFont style={{paddingLeft:"20px",paddingRight:"20px"}}><span className="dcs-section-heading">{t("Calculation customer")}</span></PorscheFont>&ensp;
            <div style={{ backgroundColor: "#FFFFFF"}}>
                {/* Header block start */}
                  <PGrid>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      </PGridItem>
                      <PGridItem size={3}>                  
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "center" }}>
                          <PorscheFont  style={{paddingBottom:"4px"}}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>                                    
                      </PGridItem>
                    </PGrid>
                  </PGridItem>
                  <PGridItem size={6}>
                  <PGrid>
                      <PGridItem size={3}>                   
                      </PGridItem>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                          <PorscheFont  style={{paddingBottom:"4px"}}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={3}>                  
                      </PGridItem>
                      <PGridItem size={3}>                 
                      </PGridItem>
                    </PGrid>        
                  </PGridItem>
                  </PGrid>
                {/* Header block End */}
              {/* Divider Block Start */}
                  <PGrid>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      </PGridItem>                    
                      <PGridItem size={9}>
                          <PDivider style={{backgroundColor:"#C8CACB",height:"2px"}}></PDivider>
                      </PGridItem>                   
                    </PGrid>
                  </PGridItem>
                  <PGridItem size={6}>
                  <PGrid>
                      <PGridItem size={9}>                     
                      <PDivider style={{backgroundColor:"#909090",height:"2px"}}></PDivider>                                    
                      </PGridItem>                  
                      <PGridItem size={3}>                 
                      </PGridItem>
                    </PGrid>        
                  </PGridItem>
                  </PGrid>
              {/* Divider Block End */}
            </div>         
            {/* Sub headers section Start*/}
            <div style={{ backgroundColor: "#FFFFFF", height: "46px"}}>
                  <PGrid>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      </PGridItem>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{marginTop:"8px"}}><span className="sub-header1">{t("Amount (net)")}</span></PorscheFont>
                    </div>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header1">{t("Amount (gross)")}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>                                     
                      </PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                  <PGridItem size={6}>
                  <PGrid>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{marginTop:"8px"}}><span className="sub-header2">{t("Amount (net)")}</span></PorscheFont>
                    </div>
                          </PGridItem>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                          <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header2">{t("Amount (gross)")}</span></PorscheFont>
                        </div>
                      </PGridItem>
                      <PGridItem size={3}>                   
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                                <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                                <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                        </div>
                      </PGridItem>
                    </PGrid>       
                  </PGridItem>
                  </PGrid>
            </div>
            {/* Sub headers section End*/}             
          {/* Gray Box div  */}
                <div style={{ backgroundColor: "#FAFAFA", height: "56px"}}>
                  <PGrid >
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                          <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("Total invoice amount")}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(calculationCustomer.invoiceNet)}{" €"}</span></PorscheFont>
                    </div>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont  style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(calculationCustomer.invoiceGross)}{" €"}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}></PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                  <PGridItem size={6}>
                  <PGrid>
                      <PGridItem size={3}>
                      <PTextFieldWrapper style={{marginTop: "4px", height: "40px" }} id="vpbpn0" unit="€" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              readOnly
                              id="ivpbpn0"
                              key="ivpbpn0"
                              type="text"
                              value={formatCurrencyWithNoUnit(recalCalculationCustomer.invoiceNet)}                           
                              style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                          </PGridItem>
                      <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="vpbpn0" unit="€" unitPosition="suffix" >
                            <input
                              aria-autocomplete='none'
                              autoComplete='off'
                              readOnly
                              id="ivpbpn0"
                              key="ivpbpn0"
                              type="text"
                              value={formatCurrencyWithNoUnit(recalCalculationCustomer.invoiceGross)}                           
                              style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={3}>
                      </PGridItem>
                      <PGridItem size={3}>
                      <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }} >
                            <PFlex justifyContent={"flex-end"}>
                              <PFlexItem>
                                <Stack direction="row" spacing={1}>
                                {(recalCalculationCustomer.invoiceNet-calculationCustomer.invoiceNet)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCalculationCustomer.invoiceNet-calculationCustomer.invoiceNet)}{" €"}</PorscheFont>
                                      { (recalCalculationCustomer.invoiceNet-calculationCustomer.invoiceNet) > 0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {(recalCalculationCustomer.invoiceNet-calculationCustomer.invoiceNet) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalCalculationCustomer.invoiceNet-calculationCustomer.invoiceNet)}{" €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }
                                  
                                  </Stack></PFlexItem></PFlex>
                          </div>
                      </PGridItem>
                    </PGrid>&ensp;        
                  </PGridItem>
                  </PGrid>
                </div>
          {/* White Box div  */}
                <div style={{ backgroundColor: "#FFFFFF", height: "56px"}}>
                  <PGrid>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                          <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("Pre-delivery payment")}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(calculationCustomer.preDeliveryNet)}{" €"}</span></PorscheFont>
                    </div>
                      </PGridItem>
                      <PGridItem size={3}>
                        <div style={{ textAlign: "right" }}>
                          <PorscheFont  style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(calculationCustomer.preDeliveryGross)}{" €"}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}></PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                  <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="ccpn" unit="€" unitPosition="suffix" >
                        <input
                          aria-autocomplete='none'
                          autoComplete='off'
                          readOnly                           
                          id="iccpn"
                          key="kccpn"
                          type="text"
                          value={formatCurrencyWithNoUnit(recalCalculationCustomer?.preDeliveryNet ? recalCalculationCustomer?.preDeliveryNet : 0.00)}                           
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{  marginTop: "4px", height: "40px" }} id="ccpg"  unit="€" unitPosition="suffix" >
                        <input
                          aria-autocomplete='none'
                          autoComplete='off'
                          readOnly                           
                          id="iccpg"
                          key="kccpg"
                          type="text"
                          value={formatCurrencyWithNoUnit(recalCalculationCustomer?.preDeliveryGross ? recalCalculationCustomer?.preDeliveryGross : 0.00)}                           
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px"}} className='dcs-heading'>
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                              {(recalCalculationCustomer.preDeliveryNet-calculationCustomer.preDeliveryNet)!==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCalculationCustomer.preDeliveryNet-calculationCustomer.preDeliveryNet)}{" €"}</PorscheFont>
                                    { (recalCalculationCustomer.preDeliveryNet-calculationCustomer.preDeliveryNet) > 0?(
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                )}
                                {(recalCalculationCustomer.preDeliveryNet-calculationCustomer.preDeliveryNet) === 0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalCalculationCustomer.preDeliveryNet-calculationCustomer.preDeliveryNet)}{" €"}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                  </>
                                  )
                                }</Stack></PFlexItem></PFlex>
                        </div>
                    </PGridItem>
                    </PGrid>&ensp;        
                  </PGridItem>
                  </PGrid>
                </div>
        
          </div>
      </div>)}
      {/* Commission calculation */}
      {canViewCommission && commissionCalculation && recalCommissionCalculation && (<div className="Recal-card vehicle-price">
        <div>
          <PorscheFont style={{paddingLeft:"20px",paddingRight:"20px"}}><span className="dcs-section-heading">{t("Commission calculation")}</span></PorscheFont>&ensp;
          <div style={{ backgroundColor: "#FFFFFF"}}>
              {/* Header block start */}
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>                  
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont  style={{paddingBottom:"4px"}}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>                                    
                    </PGridItem>
                  </PGrid>
                 </PGridItem>
                <PGridItem size={6}>
                 <PGrid>
                    <PGridItem size={3}>                   
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "center" }}>
                        <PorscheFont  style={{paddingBottom:"4px"}}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>                  
                    </PGridItem>
                    <PGridItem size={3}>                 
                    </PGridItem>
                  </PGrid>        
                </PGridItem>
                </PGrid>
              {/* Header block End */}
             {/* Divider Block Start */}
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>                    
                    <PGridItem size={9}>
                        <PDivider style={{backgroundColor:"#C8CACB",height:"2px"}}></PDivider>
                     </PGridItem>                   
                  </PGrid>
                 </PGridItem>
                <PGridItem size={6}>
                 <PGrid>
                    <PGridItem size={9}>                     
                    <PDivider style={{backgroundColor:"#909090",height:"2px"}}></PDivider>                                    
                    </PGridItem>                  
                    <PGridItem size={3}>                 
                    </PGridItem>
                  </PGrid>        
                </PGridItem>
                </PGrid>
             {/* Divider Block End */}
           </div>         
           {/* Sub headers section Start*/}
           <div style={{ backgroundColor: "#FFFFFF", height: "46px"}}>
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"8px"}}><span className="sub-header1">{t("Amount in €")}</span></PorscheFont>
                   </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      {/* <div style={{ textAlign: "right" }}>
                        <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header1">{t("Amount (gross)")}</span></PorscheFont>
                      </div> */}
                      </PGridItem>
                    <PGridItem size={3}>                                     
                    </PGridItem>
                  </PGrid>&ensp;
                 </PGridItem>
                <PGridItem size={6}>
                <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"8px"}}><span className="sub-header2">{t("Amount in €")}</span></PorscheFont>
                   </div>
                        </PGridItem>
                    <PGridItem size={3}>
                 
                    </PGridItem>
                    <PGridItem size={3}>                   
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                              <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                              <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>       
                </PGridItem>
                </PGrid>
           </div>
           {/* Sub headers section End*/}             
         {/* Gray Box div  */}
              <div style={{ backgroundColor: "#FAFAFA", height: "56px"}}>
                <PGrid >
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}} className="dcs-heading"><span>{t("Total commission")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"15px"}}><span className="header1">{formatCurrencyWithNoUnit(commissionCalculation.totalCommission)}{" €"}</span></PorscheFont>
                   </div>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                 </PGridItem>
                <PGridItem size={6}>
                <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"15px"}}><span className="header2">{formatCurrencyWithNoUnit(negativeValueHandler(recalCommissionCalculation.totalCommission))}{" €"}</span></PorscheFont>
                   </div>
                        </PGridItem>
                    <PGridItem size={3}>
                    {/* <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id="vpbpn0" unit="€" unitPosition="suffix" >
                          <input
                            aria-autocomplete='none'
                            autoComplete='off'
                            readOnly
                            id="ivpbpn0"
                            key="ivpbpn0"
                            type="text"
                            defaultValue={"142.800,00"}                           
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper> */}
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }} >
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                              {(recalCommissionCalculation.totalCommission-commissionCalculation.totalCommission)!==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCommissionCalculation.totalCommission-commissionCalculation.totalCommission)}{" €"}</PorscheFont>
                                    { (recalCommissionCalculation.totalCommission-commissionCalculation.totalCommission) > 0?(
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                )}
                                {(recalCommissionCalculation.totalCommission-commissionCalculation.totalCommission) === 0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCommissionCalculation.totalCommission-commissionCalculation.totalCommission)}{" €"}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                  </>
                                  )
                                }
                               </Stack></PFlexItem></PFlex>
                        </div>
                    </PGridItem>
                </PGrid>&ensp;        
                </PGridItem>
                </PGrid>
              </div>

             
              {/* White Box div  */}
              <div style={{ backgroundColor: "#FFFFFF", height: "56px"}}>
                <PGrid>
                  <PGridItem size={6}>
                    <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("Vehicle commission")}</span></PorscheFont>
                      </div></PGridItem>
                      <PGridItem size={3}>

                        <div
                          className={`mt12 include-btn ${commissionCalculation && commissionCalculation.vehicleCommission === "Standard" ? "include-btn-selected" : ""} disabled`}
                          style={{ paddingRight: "6px", textAlign: "center", height: "48px", paddingTop: "3px" }}
                        
                        >
                          <PorscheFont className='dcs-title'>Standard</PorscheFont>
                        </div>

                      </PGridItem >
                      <PGridItem size={3}>
                        <div
                          className={`mt12 include-btn ${commissionCalculation && commissionCalculation.vehicleCommission === "Fixed" ? "include-btn-selected" : ""} disabled`}
                          style={{ paddingRight: "6px", textAlign: "center", marginLeft: "-15px", height: "48px", paddingTop: "3px" }}
                        >
                          <PorscheFont className='dcs-title'>{t("Fixed")}</PorscheFont>
                        </div>
                      </PGridItem >
                      <PGridItem size={3}></PGridItem>
                    </PGrid>
                  </PGridItem>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                        <div
                          className={`mt12 include-btn ${recalCommissionCalculation && recalCommissionCalculation.vehicleCommission === "Standard" ? "include-btn-selected" : ""} ${disabled ? "disabled" : ""}`}
                          style={{ paddingRight: "6px", textAlign: "center", height: "48px", paddingTop: "3px" }}
                          onClick={() => handleVehicleCommission("Standard")}
                        >
                          <PorscheFont className='dcs-title'>Standard</PorscheFont>
                        </div>

                      </PGridItem >
                      <PGridItem size={3}>
                        <div
                          className={`mt12 include-btn ${recalCommissionCalculation && recalCommissionCalculation.vehicleCommission === "Fixed" ? "include-btn-selected" : ""} ${disabled ? "disabled" : ""}`}
                          style={{ paddingRight: "6px", textAlign: "center", marginLeft: "-15px", height: "48px", paddingTop: "3px" }}
                          onClick={() => handleVehicleCommission("Fixed")}
                        >
                          <PorscheFont className='dcs-title'>{t("Fixed")}</PorscheFont>
                        </div>
                      </PGridItem >
                      <PGridItem size={3}></PGridItem>
                      <PGridItem size={3}>
                      </PGridItem>
                    </PGrid>
                  </PGridItem>
                </PGrid>
              </div>

              <div style={{ backgroundColor: "#FFFFFF", height: "69px"}}>
              <PGrid>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right", marginTop:"21px" }}>
                        <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(commissionCalculation.vehicleCommissionEuro ? commissionCalculation.vehicleCommissionEuro : 0.00)}{" €"}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{marginTop:"22px", marginLeft:"-30px"}}>
                        {/* <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>10,00% <span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>of gross profit</span></PorscheFont> */}
                        <PPopover direction="right">
                          {commissionCalculation.vehicleCommission === "Standard" && commissionCalculation.commissionTypeText==="ScalingCommission" && (
                            <>
                            <Stack direction={"row"} spacing={1}>
                            {commissionCalculation && commissionCalculation.referenceType==="Euro" && commissionCalculation?.referenceValue && commissionCalculation?.referenceValue >0 && ( 
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>
                                {formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}
                                <span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}> 
                                  {commissionCalculation.referenceType}  &nbsp; {`${commissionCalculation.payout2 && commissionCalculation.referenceType2 !== undefined && commissionCalculation?.referenceValue2 !== undefined && commissionCalculation?.referenceValue2>0 ? "&" : ""}`}
                                </span>
                              </PorscheFont>
                              )
                            }
                            { commissionCalculation.referenceType!=="Euro" && commissionCalculation?.referenceValue && commissionCalculation?.referenceValue >0 && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>
                                {formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}
                                <span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>
                                {" %"} of {getReferenceType(commissionCalculation.referenceType).toLocaleLowerCase()} &nbsp; {`${commissionCalculation.payout2 && commissionCalculation.referenceType2 !== undefined && commissionCalculation?.referenceValue2 !== undefined && commissionCalculation?.referenceValue2>0 ? "&" : ""}`}
                                </span>
                              </PorscheFont>
                              )
                            }
                            
                            {commissionCalculation.payout2 && commissionCalculation.referenceType2 && commissionCalculation.referenceType2==="Euro" && commissionCalculation?.referenceValue2 && commissionCalculation?.referenceValue2>0  && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue2 ? commissionCalculation.referenceValue2 : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>  {commissionCalculation.referenceType2}</span></PorscheFont>
                            )}

                            {commissionCalculation.payout2 && commissionCalculation.referenceType2 && commissionCalculation.referenceType2!=="Euro" &&  commissionCalculation?.referenceValue && commissionCalculation?.referenceValue >0  && commissionCalculation?.referenceValue2 && commissionCalculation?.referenceValue2>0  && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue2 ? commissionCalculation.referenceValue2 : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of {getReferenceType(commissionCalculation.referenceType2).toLocaleLowerCase()}</span></PorscheFont>
                            )}
                            </Stack>
                            </>
                          )}
                            {commissionCalculation.vehicleCommission === "Standard" && commissionCalculation.commissionTypeText==="NoProfitCommission" && (
                            <>
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{t("Loss deal commission")}</PorscheFont>
                            {/* {commissionCalculation && commissionCalculation.referenceType==="Euro"  && 
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{commissionCalculation.referenceType}</span></PorscheFont>)
                            }
                          {commissionCalculation && commissionCalculation.referenceType!=="Euro"  && 
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}> {" %"} of {commissionCalculation.referenceType}</span></PorscheFont>)
                            }
                              {commissionCalculation.payout2 && commissionCalculation.referenceType2 && commissionCalculation.referenceType==="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>  {commissionCalculation.referenceType2}</span></PorscheFont>
                            )}
                            {commissionCalculation.payout2 && commissionCalculation.referenceType2 &&  commissionCalculation.referenceType!=="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}> {" %"} of {commissionCalculation.referenceType2}</span></PorscheFont>
                            )} */}
                            </>
                          )}
                          {commissionCalculation.vehicleCommission === "Standard" && commissionCalculation.commissionTypeText==="MinCommission" && (
                            <>
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{t("Minimum commission")}</PorscheFont>
                            {/* {commissionCalculation && commissionCalculation.referenceType==="Euro" &&
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{commissionCalculation.referenceType}</span></PorscheFont>)
                            }
                              {commissionCalculation && commissionCalculation.referenceType!=="Euro"&&
                                (<PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of {commissionCalculation.referenceType}</span></PorscheFont>)
                            }
                            {commissionCalculation.payout2 && commissionCalculation.referenceType2 && commissionCalculation.referenceType2==="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{commissionCalculation.referenceType2}</span></PorscheFont>
                            )}
                              {commissionCalculation.payout2 && commissionCalculation.referenceType2 && commissionCalculation.referenceType2!=="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of{commissionCalculation.referenceType2}</span></PorscheFont>
                            )} */}
                            </>
                          )}
                          {commissionCalculation.vehicleCommission === "Standard" && commissionCalculation.commissionTypeText==="MaxCommission" && (
                            <>
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{t("Maximum commission")}</PorscheFont>
                              {/* {commissionCalculation && commissionCalculation.referenceType==="Euro" && 
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{commissionCalculation.referenceType}</span></PorscheFont>)
                            }
                              {commissionCalculation && commissionCalculation.referenceType!=="Euro" &&
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of{commissionCalculation.referenceType}</span></PorscheFont>)
                            }
                            {commissionCalculation.payout2 && commissionCalculation.referenceType2 && commissionCalculation.referenceType2==="Euro"  && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{commissionCalculation.referenceType2}</span></PorscheFont>
                            )}
                            {commissionCalculation.payout2 && commissionCalculation.referenceType2 && commissionCalculation.referenceType2!=="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(commissionCalculation.referenceValue ? commissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of{commissionCalculation.referenceType2}</span></PorscheFont>
                            )} */}
                            </>
                          )}
                        </PPopover>
                      </div>
                    </PGridItem >
                    <PGridItem size={3}></PGridItem>
                  </PGrid>
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      {recalCommissionCalculation && recalCommissionCalculation.vehicleCommission === "Fixed" && (
                        <PTextFieldWrapper style={{ marginTop: "12px", height: "40px" }} id={`ccvc`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                          <input
                            maxLength={inputFieldMaxLength}
                            aria-autocomplete='none'
                            autoComplete='off'
                            id={`iccvc`}
                            key={`kccvc`}
                            type="text"
                            ref={vcRef}
                            defaultValue={formatCurrencyWithNoUnit(recalCommissionCalculation.vehicleCommissionEuro ? recalCommissionCalculation.vehicleCommissionEuro : 0.00)}
                            //onChange={(e) => handleVehicleCommissionEuro(e.target.value, `iccvc`)}
                            onBlur={(e) => {
                                handleVehicleCommissionEuro(e.target.value, `iccvc`)
                                if(vcRef.current) vcRef.current.value = formatCurrencyWithNoUnit(recalCommissionCalculation.vehicleCommissionEuro ? recalCommissionCalculation.vehicleCommissionEuro : 0.00)
                              }
                            }                        
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                      )}
                      {recalCommissionCalculation && recalCommissionCalculation.vehicleCommission === "Standard" && (
                        <PTextFieldWrapper style={{ marginTop: "12px", height: "40px" }} id={`ccvc`} unit="€" unitPosition="suffix" >
                          <input
                            aria-autocomplete='none'
                            autoComplete='off'
                            id={`iccvc`}
                            key={`kccvc`}
                            type="text"
                            ref={vcRef}
                            value={formatCurrencyWithNoUnit(recalCommissionCalculation.vehicleCommissionEuro ? recalCommissionCalculation.vehicleCommissionEuro : 0.00)}
                            readOnly                      
                            style={{ textAlign: "right" }}
                          />
                        </PTextFieldWrapper>
                      )}
                    </PGridItem >
                    
                    <PGridItem size={3}>
                      <div style={{ marginTop: "24px" , textAlign: "left",paddingRight:"20px", marginLeft: "-30px", paddingLeft:"-20px" }} >
                        <PFlex justifyContent={"flex-start"}>
                        <PFlexItem alignSelf={"center"}>
                        {recalCommissionCalculation && recalCommissionCalculation.vehicleCommission === "Standard" && (
                          <>
                          {recalCommissionCalculation.vehicleCommission === "Standard" && recalCommissionCalculation.commissionTypeText==="ScalingCommission" && (
                            <>
                              <PPopover direction="right">
                              <Stack direction={"row"} spacing={1}>
                              {recalCommissionCalculation && recalCommissionCalculation.referenceType==="Euro" && recalCommissionCalculation?.referenceValue && recalCommissionCalculation?.referenceValue >0 && ( 
                                <PorscheFont className='dcs-title' style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>
                                  {formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}
                                  <span  style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}> 
                                    {recalCommissionCalculation.referenceType} &nbsp; {`${recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 !== undefined && recalCommissionCalculation?.referenceValue2 !== undefined && recalCommissionCalculation?.referenceValue2>0 ? "&" : ""}`}
                                  </span>
                                </PorscheFont>
                                )
                              }
                              { recalCommissionCalculation.referenceType!=="Euro" && recalCommissionCalculation?.referenceValue && recalCommissionCalculation?.referenceValue >0 && (
                                <PorscheFont className='dcs-title' style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>
                                  {formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}
                                  <span  style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>
                                  {" %"} of {getReferenceType(recalCommissionCalculation.referenceType).toLocaleLowerCase()} &nbsp; {`${recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 !== undefined && recalCommissionCalculation?.referenceValue2 !== undefined && recalCommissionCalculation?.referenceValue2>0 ? "&" : ""}`}
                                  </span>
                                </PorscheFont>
                                )
                              }
                              
                              {recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 && recalCommissionCalculation.referenceType2==="Euro" && recalCommissionCalculation?.referenceValue2 && recalCommissionCalculation?.referenceValue2>0  && (
                                <PorscheFont className='dcs-title' style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue2 ? recalCommissionCalculation.referenceValue2 : 0.00)}<span  style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>  {recalCommissionCalculation.referenceType2}</span></PorscheFont>
                              )}

                              {recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 && recalCommissionCalculation.referenceType2!=="Euro" &&  recalCommissionCalculation?.referenceValue && recalCommissionCalculation?.referenceValue >0  && recalCommissionCalculation?.referenceValue2 && recalCommissionCalculation?.referenceValue2>0  && (
                                <PorscheFont className='dcs-title' style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue2 ? recalCommissionCalculation.referenceValue2 : 0.00)}<span  style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>{" %"} of {getReferenceType(recalCommissionCalculation.referenceType2).toLocaleLowerCase()}</span></PorscheFont>
                              )}
                              </Stack>
                            </PPopover>
                            </>
                          )}
                        
                          {recalCommissionCalculation.vehicleCommission === "Standard" && recalCommissionCalculation.commissionTypeText==="NoProfitCommission" && (
                            <>
                              <PPopover direction="right">
                              <PorscheFont className='dcs-title' style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>{t("Loss deal commission")}</PorscheFont>
                            {/* {recalCommissionCalculation && recalCommissionCalculation.referenceType==="Euro"  && 
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{recalCommissionCalculation.referenceType}</span></PorscheFont>)
                            }
                          {recalCommissionCalculation && recalCommissionCalculation.referenceType!=="Euro"  && 
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}> {" %"} of {recalCommissionCalculation.referenceType}</span></PorscheFont>)
                            }
                              {recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 && recalCommissionCalculation.referenceType==="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>  {recalCommissionCalculation.referenceType2}</span></PorscheFont>
                            )}
                            {recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 &&  recalCommissionCalculation.referenceType!=="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}> {" %"} of {recalCommissionCalculation.referenceType2}</span></PorscheFont>
                            )} */}
                            </PPopover>
                            </>
                          )}
                          {recalCommissionCalculation.vehicleCommission === "Standard" && recalCommissionCalculation.commissionTypeText==="MinCommission" && (
                            <>
                              <PPopover direction="right">
                              <PorscheFont className='dcs-title' style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>{t("Minimum commission")}</PorscheFont>
                            {/* {recalCommissionCalculation && recalCommissionCalculation.referenceType==="Euro" &&
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{recalCommissionCalculation.referenceType}</span></PorscheFont>)
                            }
                              {recalCommissionCalculation && recalCommissionCalculation.referenceType!=="Euro"&&
                                (<PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of {recalCommissionCalculation.referenceType}</span></PorscheFont>)
                            }
                            {recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 && recalCommissionCalculation.referenceType2==="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{recalCommissionCalculation.referenceType2}</span></PorscheFont>
                            )}
                              {recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 && recalCommissionCalculation.referenceType2!=="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of{recalCommissionCalculation.referenceType2}</span></PorscheFont>
                            )} */}
                            </PPopover>
                            </>
                          )}
                          {recalCommissionCalculation.vehicleCommission === "Standard" && recalCommissionCalculation.commissionTypeText==="MaxCommission" && (
                            <>
                              <PPopover direction="right">
                              <PorscheFont className='dcs-title' style={{color:"#313639",fontWeight:"400",fontSize:"16px"}}>{t("Maximum commission")}</PorscheFont>
                              {/* {recalCommissionCalculation && recalCommissionCalculation.referenceType==="Euro" && 
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{recalCommissionCalculation.referenceType}</span></PorscheFont>)
                            }
                              {recalCommissionCalculation && recalCommissionCalculation.referenceType!=="Euro" &&
                                ( <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of{recalCommissionCalculation.referenceType}</span></PorscheFont>)
                            }
                            {recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 && recalCommissionCalculation.referenceType2==="Euro"  && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{recalCommissionCalculation.referenceType2}</span></PorscheFont>
                            )}
                            {recalCommissionCalculation.payout2 && recalCommissionCalculation.referenceType2 && recalCommissionCalculation.referenceType2!=="Euro" && (
                              <PorscheFont className='dcs-title' style={{color:"#909090",fontWeight:"600",fontSize:"16px"}}>{formatCurrencyWithNoUnit(recalCommissionCalculation.referenceValue ? recalCommissionCalculation.referenceValue : 0.00)}<span  style={{color:"#909090",fontWeight:"400",fontSize:"16px"}}>{" %"} of{recalCommissionCalculation.referenceType2}</span></PorscheFont>
                            )} */}
                            </PPopover>
                            </>
                          )}
                          </>
                        )}
                        </PFlexItem>
                        </PFlex>
                      </div>
                    </PGridItem >
                    <PGridItem size={3}></PGridItem>
                    <PGridItem size={3}>
                      <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }} >
                        <PFlex justifyContent={"flex-end"}>
                          <PFlexItem>
                            <Stack direction="row" spacing={1}>
                              {recalCommissionCalculation.vehicleCommissionEuro !== undefined  && commissionCalculation.vehicleCommissionEuro !== undefined && (recalCommissionCalculation.vehicleCommissionEuro-commissionCalculation.vehicleCommissionEuro)!==0 && (
                                <>
                                  <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCommissionCalculation.vehicleCommissionEuro-commissionCalculation.vehicleCommissionEuro)}{" €"}</PorscheFont>
                                  { (recalCommissionCalculation.vehicleCommissionEuro-commissionCalculation.vehicleCommissionEuro) > 0?(
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                  }
                                </>
                              )}
                              {recalCommissionCalculation.vehicleCommissionEuro !== undefined  && commissionCalculation.vehicleCommissionEuro !== undefined && (recalCommissionCalculation.vehicleCommissionEuro - commissionCalculation.vehicleCommissionEuro) === 0 && (
                                <>
                                  <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{"0,00 €"}</PorscheFont>
                                  <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                </>
                                )
                              }
                            </Stack>
                          </PFlexItem>
                        </PFlex>
                      </div>
                    </PGridItem>
                  </PGrid>
                </PGridItem>
              </PGrid>
              </div>
            {/* Gray Box div  pfs commission */}
            {(order?.paymentDetails?.paymentType === "Leasing_PFS" ||
              order?.paymentDetails?.paymentType === "Financing_PFS") && (
              <div style={{ backgroundColor: `${getCommissionColorCode("PFS")}`, height: "56px" }}>
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("PFS commission ")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(commissionCalculation.pfsCommission)}{" €"}</span></PorscheFont>
                   </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      </PGridItem>
                    <PGridItem size={3}>                  
                    </PGridItem>
                  </PGrid>&ensp;
                 </PGridItem>
                <PGridItem size={6}>
                <PGrid>
                    <PGridItem size={3}>
                    <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`ccvpfs`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                      <input
                        maxLength={inputFieldMaxLength}
                        aria-autocomplete='none'
                        autoComplete='off'
                        ref={pfsRef}
                        id={`iccvpfs`}
                        key={`kccvpfs`}
                        type="text"
                        defaultValue={formatCurrencyWithNoUnit(recalCommissionCalculation.pfsCommission ? recalCommissionCalculation.pfsCommission : 0.00)}
                        //onChange={(e) => handlePFS(e.target.value, `iccvpfs`)}
                        onBlur={(e) => {
                            handlePFS(e.target.value, `iccvpfs`)
                            if(pfsRef.current) pfsRef.current.value = formatCurrencyWithNoUnit(recalCommissionCalculation.pfsCommission ? recalCommissionCalculation.pfsCommission : 0.00)
                          }
                        }                         
                        style={{ textAlign: "right" }}
                      />
                    </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                   
                    </PGridItem>
                    <PGridItem size={3}>                   
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }} >
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                              {(recalCommissionCalculation.pfsCommission-commissionCalculation.pfsCommission)!==0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCommissionCalculation.pfsCommission-commissionCalculation.pfsCommission)}{" €"}</PorscheFont>
                                    { (recalCommissionCalculation.pfsCommission-commissionCalculation.pfsCommission) > 0?(
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                    }
                                  </>
                                )}
                                {(recalCommissionCalculation.pfsCommission-commissionCalculation.pfsCommission) === 0 && (
                                  <>
                                    <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalCommissionCalculation.pfsCommission-commissionCalculation.pfsCommission)}{" €"}</PorscheFont>
                                    <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                  </>
                                  )
                                }
                              </Stack>
                            </PFlexItem>                            
                          </PFlex>
                        </div>
                    </PGridItem>
                  </PGrid>&ensp;        
                </PGridItem>
                </PGrid>
              </div>
              )
            }
              {/* whte  Box div  car policy commission */}

              {order?.paymentDetails?.carPolicy && (
                <div style={{ backgroundColor: `${getCommissionColorCode("carPolicy")}`, height: "56px" }}>
                  <PGrid>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                          <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("Car Policy commission ")}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(commissionCalculation.carPolicyCommission)}{" €"} </span></PorscheFont>
                    </div>
                      </PGridItem>
                      <PGridItem size={3}>
                        </PGridItem>
                      <PGridItem size={3}>                  
                      </PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                  <PGridItem size={6}>
                  <PGrid>
                      <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`cccpc`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                            <input
                              maxLength={inputFieldMaxLength}
                              aria-autocomplete='none'
                              autoComplete='off'
                              ref={cpRef}
                              id={`icccpc`}
                              key={`kcccpc`}
                              type="text"
                              //value={recalCommissionCalculation.carPolicyCommission} 
                              defaultValue={formatCurrencyWithNoUnit(recalCommissionCalculation.carPolicyCommission ? recalCommissionCalculation.carPolicyCommission : 0.00)}
                              //onChange={(e) => handleCpc(e.target.value, `icccpc`)}
                              onBlur={(e) => {
                                handleCpc(e.target.value, `icccpc`)
                                  if(cpRef.current) cpRef.current.value = formatCurrencyWithNoUnit(recalCommissionCalculation.carPolicyCommission ? recalCommissionCalculation.carPolicyCommission : 0.00)
                                }
                              } 
                              style={{ textAlign: "right" }}
                            />
                          </PTextFieldWrapper>
                          </PGridItem>
                      <PGridItem size={3}>
                    
                      </PGridItem>
                      <PGridItem size={3}>                   
                      </PGridItem>
                      <PGridItem size={3}>
                      <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }}>
                            <PFlex justifyContent={"flex-end"}>
                              <PFlexItem>
                                <Stack direction="row" spacing={1}>
                                {(recalCommissionCalculation.carPolicyCommission-commissionCalculation.carPolicyCommission)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCommissionCalculation.carPolicyCommission-commissionCalculation.carPolicyCommission)}{" €"}</PorscheFont>
                                      { (recalCommissionCalculation.carPolicyCommission-commissionCalculation.carPolicyCommission) > 0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {(recalCommissionCalculation.carPolicyCommission-commissionCalculation.carPolicyCommission) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalCommissionCalculation.carPolicyCommission-commissionCalculation.carPolicyCommission)}{" €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }
                                </Stack>
                              </PFlexItem>                            
                            </PFlex>
                          </div>
                      </PGridItem>
                    </PGrid>&ensp;        
                  </PGridItem>
                  </PGrid>
                </div>
                )
              }
                 
              {/* gray   Box div  porsche cards s  commission */}
              {order?.paymentDetails?.porscheCardS && (
                <div style={{ backgroundColor: `${getCommissionColorCode("porscheCardS")}`, height: "56px" }}>
                  <PGrid>
                  <PGridItem size={6}>
                    <PGrid>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "left" }}>
                          <PorscheFont style={{marginTop:"15px",paddingLeft:"20px", marginRight: "-50px"}}><span>{t("Porsche Card S commission")}</span></PorscheFont>
                        </div></PGridItem>
                      <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(commissionCalculation.porscheCardS)}{" €"} </span></PorscheFont>
                    </div>
                      </PGridItem>
                      <PGridItem size={3}>
                        </PGridItem>
                      <PGridItem size={3}>                  
                      </PGridItem>
                    </PGrid>&ensp;
                  </PGridItem>
                  <PGridItem size={6}>
                  <PGrid>
                      <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`cccpcs`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          aria-autocomplete='none'
                          autoComplete='off'
                          ref={pcsRef}
                          id={`icccpcs`}
                          key={`kcccpcs`}
                          type="text"
                          //value={recalCommissionCalculation.porscheCardS} 
                          defaultValue={formatCurrencyWithNoUnit(recalCommissionCalculation.porscheCardS ? recalCommissionCalculation.porscheCardS : 0.00)}
                          //onChange={(e) => handlePcs(e.target.value, `icccpcs`)}
                          onBlur={(e) => {
                              handlePcs(e.target.value, `icccpcs`)
                              if(pcsRef.current) pcsRef.current.value = formatCurrencyWithNoUnit(recalCommissionCalculation.porscheCardS ? recalCommissionCalculation.porscheCardS : 0.00)
                            }
                          }                          
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                      </PGridItem>
                      <PGridItem size={3}>
                    
                      </PGridItem>
                      <PGridItem size={3}>                   
                      </PGridItem>
                      <PGridItem size={3}>
                      <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }}>
                            <PFlex justifyContent={"flex-end"}>
                              <PFlexItem>
                                <Stack direction="row" spacing={1}>
                                {(recalCommissionCalculation.porscheCardS-commissionCalculation.porscheCardS)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCommissionCalculation.porscheCardS-commissionCalculation.porscheCardS)}{" €"}</PorscheFont>
                                      { (recalCommissionCalculation.porscheCardS-commissionCalculation.porscheCardS) > 0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {(recalCommissionCalculation.porscheCardS-commissionCalculation.porscheCardS) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalCommissionCalculation.porscheCardS-commissionCalculation.porscheCardS)}{" €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }
                                </Stack>
                              </PFlexItem>                            
                            </PFlex>
                          </div>
                      </PGridItem>
                    </PGrid>&ensp;        
                  </PGridItem>
                  </PGrid>
                </div>
              )}
                
              {/* whte    Box div incentive /bobus  */}
              <div style={{ backgroundColor: `${getCommissionColorCode("Incentive")}`, height: "56px" }}>
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{t("Incentive / Bonus")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(commissionCalculation.incentive)} {" €"}</span></PorscheFont>
                   </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      </PGridItem>
                    <PGridItem size={3}>                  
                    </PGridItem>
                  </PGrid>&ensp;
                 </PGridItem>
                <PGridItem size={6}>
                <PGrid>
                    <PGridItem size={3}>
                    <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`cci`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                      <input
                        maxLength={inputFieldMaxLength}
                        aria-autocomplete='none'
                        autoComplete='off'
                        ref={iRef}
                        id={`icci`}
                        key={`kcci`}
                        type="text"
                        defaultValue={formatCurrencyWithNoUnit(recalCommissionCalculation.incentive ? recalCommissionCalculation.incentive : 0.00)}
                        //onChange={(e) => handleIncentive(e.target.value, `icci`)}
                        onBlur={(e) => {
                            handleIncentive(e.target.value, `icci`)
                            if(iRef.current) iRef.current.value = formatCurrencyWithNoUnit(recalCommissionCalculation.incentive ? recalCommissionCalculation.incentive : 0.00)
                          }
                        }                      
                        style={{ textAlign: "right" }}
                      />
                    </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                   
                    </PGridItem>
                    <PGridItem size={3}>                   
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }}>
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                                  {(recalCommissionCalculation.incentive-commissionCalculation.incentive)!==0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit(recalCommissionCalculation.incentive-commissionCalculation.incentive)}{" €"}</PorscheFont>
                                      { (recalCommissionCalculation.incentive-commissionCalculation.incentive) > 0?(
                                        <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                      }
                                    </>
                                  )}
                                  {(recalCommissionCalculation.incentive-commissionCalculation.incentive) === 0 && (
                                    <>
                                      <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit(recalCommissionCalculation.incentive-commissionCalculation.incentive)}{" €"}</PorscheFont>
                                      <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                    </>
                                    )
                                  }
                              </Stack>
                            </PFlexItem>                            
                          </PFlex>
                        </div>
                    </PGridItem>
                  </PGrid>&ensp;        
                </PGridItem>
                </PGrid>
              </div>
              
              <br></br>
              {/* Comment Section Start*/}
          <div style={{ backgroundColor: "#FFFFFF", height: "79px" }}>
            <PGrid>
              <PGridItem size={6}>
                <div style={{ textAlign: "left", paddingLeft: "20px" }}>
                  <Stack direction="column">
                    <PorscheFont><span className="sub-header1">{t("Incentive Programm")}</span></PorscheFont>
                    <PorscheFont><span className="sub-header2">{ commissionCalculation.incentiveProgram ?  t(commissionCalculation.incentiveProgram) : ""}</span></PorscheFont>
                  </Stack>
                </div>
              </PGridItem>
             
            </PGrid>
          </div>
          {/* Comment Section End*/}

              
        </div>
      </div>)}
      {/* Commission sharing  */}
      {canViewCommission && precalculation && precalculation.isCommissionSharingActive && 
        ( 
        <div className="Recal-card vehicle-price">
        <div>
          <PorscheFont  style={{paddingLeft:"20px",paddingRight:"20px"}}><span className="dcs-section-heading">{t("Commission sharing")}</span></PorscheFont>&ensp;
          <div style={{ backgroundColor: "#FFFFFF"}}>
              {/* Header block start */}
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>                  
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "center" }}>
                        <PorscheFont  style={{paddingBottom:"4px"}}><span className="header1">{t("Precalculation")}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>                                    
                    </PGridItem>
                  </PGrid>
                 </PGridItem>
                <PGridItem size={6}>
                 <PGrid>
                    <PGridItem size={3}>                   
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "center" }}>
                        <PorscheFont  style={{paddingBottom:"4px"}}><span className="header2">{t("Recalculation")}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}>                  
                    </PGridItem>
                    <PGridItem size={3}>                 
                    </PGridItem>
                  </PGrid>        
                </PGridItem>
                </PGrid>
              {/* Header block End */}
             {/* Divider Block Start */}
                <PGrid>
                 <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    </PGridItem>                    
                    <PGridItem size={9}>
                        <PDivider style={{backgroundColor:"#C8CACB",height:"2px"}}></PDivider>
                     </PGridItem>                   
                  </PGrid>
                 </PGridItem>
                <PGridItem size={6}>
                 <PGrid>
                    <PGridItem size={9}>                     
                    <PDivider style={{backgroundColor:"#909090",height:"2px"}}></PDivider>                                    
                    </PGridItem>                  
                    <PGridItem size={3}>                 
                    </PGridItem>
                  </PGrid>        
                </PGridItem>
                </PGrid>
             {/* Divider Block End */}
           </div> 
          {/* Sub headers section Start*/}
          <div style={{ backgroundColor: "#FFFFFF", height: "46px"}}>
            <PGrid>
                <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  </PGridItem>
                  <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                  <PorscheFont style={{marginTop:"8px"}}><span className="sub-header1">{t("Share in %")}</span></PorscheFont>
                  </div>
                  </PGridItem>
                  <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                  <PorscheFont style={{marginTop:"8px"}}><span className="sub-header1">{t("Share in €")}</span></PorscheFont>
                  </div>
                    </PGridItem>
                  <PGridItem size={3}>                    
                  </PGridItem>
                </PGrid>&ensp;
                </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                  <PorscheFont style={{marginTop:"8px"}}><span className="sub-header2">{t("Share in %")}</span></PorscheFont>
                  </div>
                      </PGridItem>
                  <PGridItem size={3}>
                  <div style={{ textAlign: "right" }}>
                      <PorscheFont  style={{marginTop:"8px"}}><span className="sub-header2">{t("Share in €")}</span></PorscheFont>
                    </div>
                  </PGridItem>
                  <PGridItem size={3}>                  
                  </PGridItem>
                  <PGridItem size={3}>
                    <div style={{ marginTop: "15px", textAlign: "right", paddingRight: "20px" }} >
                      <PFlex justifyContent={"flex-end"}>
                        <PFlexItem>
                          <Stack direction="row" spacing={1}>
                            <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} >{t("Deviation")}</PorscheFont>
                            <img src={require("../../../assets/images/Arrow-red-up.png")} style={{ width: "16px", height: "18px", marginTop: "3px", visibility: "hidden" }}></img></Stack></PFlexItem></PFlex>
                    </div>
                  </PGridItem>
                </PGrid> 
              </PGridItem>
            </PGrid>
          </div>
          {/* Sub headers section End*/}    
          {/* Gray Box div  */}
          {userCommissionShares && recalUserCommissionShares&& userCommissionShares.map((item,i)=>{
            return (
            <> 
              <div style={{ backgroundColor: i%2===0?"#FAFAFA":"#FFFFFF", height: "56px"}}>
                <PGrid >
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "left" }}>
                        <PorscheFont style={{marginTop:"15px",paddingLeft:"20px"}}><span>{item.sellerNames.ppnName}</span></PorscheFont>
                      </div></PGridItem>
                    <PGridItem size={3}>
                    <div style={{ textAlign: "right" }}>
                    <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(item.shareInPercent)}{"%"}</span></PorscheFont>
                  </div>
                    </PGridItem>
                    <PGridItem size={3}>
                      <div style={{ textAlign: "right" }}>
                      <PorscheFont style={{marginTop:"15px"}}><span className="sub-header1">{formatCurrencyWithNoUnit(item.shareInEuro)}{" €"}</span></PorscheFont>
                      </div>
                    </PGridItem>
                    <PGridItem size={3}></PGridItem>
                  </PGrid>&ensp;
                </PGridItem>
                <PGridItem size={6}>
                  <PGrid>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{marginTop: "4px", height: "40px" }} id={`fcsp${i}`} unit="%" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          ref={csRef[i].percent}
                          aria-autocomplete='none'
                          autoComplete='off'
                          id={`ifcsp${i}`}
                          key={`kfcsp${i}`}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInPercent)}
                          //onChange={(e) => handleSharingPercent(e.target.value, i, `kfcsp${i}`)}
                          onBlur={(e) => {
                              handleSharingPercent(e.target.value, i, `kfcsp${i}`)
                              // if(i === 0 && csRef[0].percent.current) csRef[0].percent.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInPercent)
                              // if(i === 1 && csRef[1].percent.current) csRef[1].percent.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInPercent)
                              // if(i === 2 && csRef[2].percent.current) csRef[2].percent.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInPercent)
                            }
                          }                      
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                      <PTextFieldWrapper style={{ marginTop: "4px", height: "40px" }} id={`fcse${i}`} unit="€" unitPosition="suffix" showCharacterCount={false}>
                        <input
                          maxLength={inputFieldMaxLength}
                          ref={csRef[i].euro}
                          aria-autocomplete='none'
                          autoComplete='off'
                          id={`ifcse${i}`}
                          key={`kfcse${i}`}
                          type="text"
                          defaultValue={formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInEuro)}
                          //onChange={(e) => handleSharingEuro(e.target.value, i, `kfcse${i}`)} 
                          onBlur={(e) => {
                              handleSharingEuro(e.target.value, i, `ifcse${i}`)
                              // if(i === 0 && csRef[0].euro.current) csRef[0].euro.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInEuro)
                              // if(i === 1 && csRef[1].euro.current) csRef[1].euro.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInEuro)
                              // if(i === 2 && csRef[2].euro.current) csRef[2].euro.current.value = formatCurrencyWithNoUnit(recalUserCommissionShares[i].shareInEuro)
                            }
                          }              
                          style={{ textAlign: "right" }}
                        />
                      </PTextFieldWrapper>
                    </PGridItem>
                    <PGridItem size={3}>
                    </PGridItem>
                    <PGridItem size={3}>
                    <div style={{ marginTop: "15px" , textAlign: "right",paddingRight:"20px" }}>
                          <PFlex justifyContent={"flex-end"}>
                            <PFlexItem>
                              <Stack direction="row" spacing={1}>
                              
                                {(recalUserCommissionShares[i].shareInEuro-userCommissionShares[i].shareInEuro)!==0 && (
                                        <>
                                          <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-heading' >{formatCurrencyWithNoUnit((recalUserCommissionShares[i].shareInEuro-userCommissionShares[i].shareInEuro))}{" €"}</PorscheFont>
                                          { (recalUserCommissionShares[i].shareInEuro-userCommissionShares[i].shareInEuro) > 0?(
                                            <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px"}}></img>):( <img src={require("../../../assets/images/Arrow-red-down.png")} style={{ width: "16px", height: "18px",marginTop:"3px"}}></img>)
                                          }
                                        </>
                                      )}
                                      {(recalUserCommissionShares[i].shareInEuro-userCommissionShares[i].shareInEuro) === 0 && (
                                        <>
                                          <PorscheFont style={{ color: "#313639", marginLeft: "-5px" }} className='dcs-title' >{formatCurrencyWithNoUnit((recalUserCommissionShares[i].shareInEuro-userCommissionShares[i].shareInEuro))}{" €"}</PorscheFont>
                                          <img src={require("../../../assets/images/Arrow-green-up.png")} style={{ width: "16px", height: "18px", marginTop:"3px", visibility:"hidden"}}></img>
                                        </>
                                        )
                                      }</Stack></PFlexItem></PFlex>
                        </div>
                    </PGridItem>
                  </PGrid>&ensp;        
                </PGridItem>
                </PGrid>
              </div>
            </>
            )
          })  
          }
                           
         {/* Comment Section Start*/}
          <div style={{ backgroundColor: "#FFFFFF", height: "56px" }}>
            <PGrid>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={12}>
                  <div style={{ textAlign: "left",paddingLeft:"20px", marginTop: "15px"}}>             
                  <Stack direction="column">                 
                    <PorscheFont><span className="sub-header1">{t("Vehicle commission")}</span></PorscheFont>
                    <PorscheFont><span className="sub-header1">{formatCurrencyWithNoUnit(commissionCalculation.vehicleCommissionEuro ? commissionCalculation.vehicleCommissionEuro : 0.00)}{" €"}</span></PorscheFont>
                  </Stack>
                  </div>              
                  </PGridItem>
                </PGrid>&ensp;
              </PGridItem>
              <PGridItem size={6}>
                <PGrid>
                  <PGridItem size={9}>  
                  <div style={{ textAlign: "left", marginTop: "15px"}}>             
                  <Stack direction="column">                 
                    <PorscheFont><span className="sub-header2">{t("Vehicle commission")}</span></PorscheFont>
                    <PorscheFont><span className="sub-header2">{formatCurrencyWithNoUnit(recalCommissionCalculation?.vehicleCommissionEuro ? recalCommissionCalculation?.vehicleCommissionEuro : 0.00)}{" €"}</span></PorscheFont>
                  </Stack>
                  </div> 
                  </PGridItem>                                  
                  <PGridItem size={3}>                 
                  </PGridItem>               
                </PGrid>&ensp;
              </PGridItem>
           </PGrid>
          </div> &ensp;     
         {/* Comment Section End*/}
        </div>
        {csError && (
          <PGrid>
            <PGridItem size={12}>
              <PorscheFont style={{ color: "#D5001B" }}>{t("Commission sharing invalid. Sum of the shares must add up to 100%.")}</PorscheFont>
            </PGridItem>
          </PGrid>
        )}
    </div>)}
        {/* NewCar Commission sharing End */}
      

      </div>
    </>
  );
};
