import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/system';
import {
  PCheckboxWrapper, PFlex, PFlexItem, PIcon, PPopover, PSwitch, PText, SwitchChangeEvent
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { sortApprovalConfiguration } from '../../../helpers/sorter';
import { UserDetailsDTO } from '../../../interfaces/User';
import { UserDetails } from '../../../interfaces/UserDetails';
import {
  ApprovalConfiguration, ApprovalConfigurationStep, User
} from '../../../interfaces/adminApproval/ApprovalConfiguration';
import { type } from '../../../services/Constants';
import {
  dcsgetAdminApprovalConfiguration,
  dcsgetUsers
} from '../../../services/data/Helpers';
import ScrollToTop from '../../common/ScrollToTop';

interface Props {
  edit: boolean;
  save: boolean;
  cancel: boolean;
  handleUsersPost: (approvalConfig: ApprovalConfiguration) => void;
  modified: () => void;
  enableSave: (value: boolean) => void;
  showHeader:() => void;
  removeHeader:()=> void;
}

export const ApprovalNewCar = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, save, cancel, handleUsersPost, modified, enableSave,showHeader,removeHeader } = props;
  const [managerDetails, setManagerDetails] = useState(false);
  const [approvalConfig, setApprovalConfig] = useState<ApprovalConfiguration>({} as ApprovalConfiguration );
  const [reload, setReload] = useState("");
  const [activeUsers, setActiveUsers] = useState<Array<UserDetails>>([]);
  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if(sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }

  useEffect(() => {}, [reload]);

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetUsers()
      if(apiResponse.status===type.SUCCESS){
        setActiveUsers(apiResponse.response);
      }
    };
    init();
  }, []);

  //Add logged in user origin
  const approvalConfigurationData = () => {
    if(userDetails.id) {
      var config:ApprovalConfiguration={
        category:"NewCar",
        orderApproval:getIntilaDataForOrderApproval(),
        commissionApproval:getIntilaDataForCommissionApproval(),
        origin:{
          id: userDetails.origin.id
        }
      };

      return config;
    } else {
      var config:ApprovalConfiguration={
        category:"NewCar",
        orderApproval:getIntilaDataForOrderApproval(),
        commissionApproval:getIntilaDataForCommissionApproval(),
        origin:{
          id:1
        }
      };
      return config;
    }
  }

  const getIntilaDataForOrderApproval=()=>{
    var approvalOrderList:Array<ApprovalConfigurationStep>=[];
    const  step1:ApprovalConfigurationStep={
      isEnabled:true,
      approvalStep:"OrderCheck1",
      displayLabel:"1st Order Check", 
      approvers:[]
    };
    const  step2:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"OrderCheck2",
      displayLabel:"2nd Order Check",
      approvers:[]
    };
    const  step3:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"Export",
      displayLabel:"Export",
      approvers:[]
    };
    const  step4:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"Discount",
      displayLabel:"Discount",
      approvers:[]
    };
    const  step5:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"Others",
      displayLabel:"Others",
      approvers:[]
    };
    const  step6:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"PEPMatch",
      displayLabel:"PEP Match",
      approvers:[]
    };
    const  step7:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"DispatchOrderProcessing",
      displayLabel:"Dispatch Order Processing",
      approvers:[]
    };

    approvalOrderList.push(step1);
    approvalOrderList.push(step2);
    approvalOrderList.push(step3);
    approvalOrderList.push(step4);
    approvalOrderList.push(step5);
    approvalOrderList.push(step6);
    approvalOrderList.push(step7);

    return approvalOrderList;

  };
  const getIntilaDataForCommissionApproval=()=>{
    var commissionApprovalList:Array<ApprovalConfigurationStep>=[];

    const  step1:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"Recalculation",
      displayLabel:"Recalculation",
      approvers:[]
    };
    const  step2:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"CommissionCheck1",
      displayLabel:"1st Commission check",
      approvers:[]
    };
    const  step3:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"CommissionCheck2",
      displayLabel:" 2nd Commission check",
      approvers:[]
    };
    const  step4:ApprovalConfigurationStep={
      isEnabled:false,
      approvalStep:"FixedCommission",
      displayLabel:"Fixed Commission",
      approvers:[]
    };
    commissionApprovalList.push(step1);
    commissionApprovalList.push(step2);
    commissionApprovalList.push(step3);
    commissionApprovalList.push(step4);
   return commissionApprovalList;
  }
  
  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetAdminApprovalConfiguration("NewCar")
        if(apiResponse.status===type.SUCCESS){
          const approvalConfig = apiResponse.response as ApprovalConfiguration;
          if (approvalConfig.orderApproval) {
            approvalConfig.orderApproval = sortApprovalConfiguration(approvalConfig.orderApproval);
          }
          if (approvalConfig.commissionApproval) {
            approvalConfig.commissionApproval = sortApprovalConfiguration(approvalConfig.commissionApproval);
          }
  
          setApprovalConfig(approvalConfig);
        }else{
        setApprovalConfig(approvalConfigurationData())
      }
      setReload(new Date().toLocaleString());
    };
    init();
  }, []);

  const orderApprovalUserAssignment=(orderStep:ApprovalConfigurationStep,user:User, index: number)=>{
    const dom = document.getElementById(`orderErr${index}`);
    if(dom) {
      dom.innerHTML = "";
    }
    let isError = false;
    approvalConfig.orderApproval?.map((order, i)=>{
      if(order.approvalStep==orderStep.approvalStep){
        const exists =order?.approvers?.filter(de=>de.id==user.id);
        if(exists!=undefined && exists.length>0){
          order?.approvers?.map((de,i)=>{
            if(de.id===user.id){
              order?.approvers?.splice(i,1);
            }
          })
        }else{
          if(order.approvers && order.approvers?.length > 4) {
            if(dom) {
              dom.innerHTML = t("A maximum of five persons can be selected.");
              isError = true;
            }
          }
          order?.approvers?.push(user);
        }

        if(i>0 && i<6 && order.isEnabled && order.approvers && dom) {
          if(i === 3 && order.approvers?.length < 2) {
            dom.innerHTML = t("At least 2 users must be selected.");
          } else if(order.approvers?.length === 0) {
            dom.innerHTML = t("At least 1 user must be selected.");
          }
        }
      }
    });
              
    setApprovalConfig(approvalConfig);
    setReload(new Date().toLocaleString());
    modified();
    if (isError) {
      enableSave(false);
    } else {
      handleUsersPost(approvalConfig);
    }
    
    setReload(new Date().toLocaleString());
  };

  const isChecked=(orderStep:ApprovalConfigurationStep,user:User)=>{
    const exists =orderStep?.approvers?.filter(de=>de.id==user.id);
    if(exists!=undefined && exists.length>0){
      return true;
    }else{
      return false;
    }
  };
  
  const handleEnable = (e: CustomEvent<SwitchChangeEvent>, type: string, index: number) => {
    switch(type) {
      case "order":
        if(approvalConfig.orderApproval){
          approvalConfig.orderApproval[index].isEnabled = e.detail.checked;
          const dom = document.getElementById(`orderErr${index}`);
          if(dom) {
            dom.innerHTML = "";
          }
          if(e.detail.checked) {
            if(approvalConfig.orderApproval[index].approvers ) {
              if(dom) {
                if (index === 3 && 
                  approvalConfig.orderApproval[3].approvers &&
                  approvalConfig.orderApproval[3].approvers.length < 2) {
                  dom.innerHTML = t("At least 2 users must be selected.");
                } else if(approvalConfig.orderApproval[index].approvers?.length === 0) {
                  dom.innerHTML = t("At least 1 user must be selected.");
                }
              }
            }
          }
        }
        break;
      case "commission":
        if(approvalConfig.commissionApproval){
          approvalConfig.commissionApproval[index].isEnabled = e.detail.checked;
          const dom = document.getElementById(`commissionErr${index}`);
          if(dom) {
            dom.innerHTML = "";
          }
          if(e.detail.checked) {
            if(approvalConfig.commissionApproval[index].approvers ) {
              if(dom) {
                if(approvalConfig.commissionApproval[index].approvers?.length === 0) {
                  dom.innerHTML = t("At least 1 user must be selected.");
                }
              }
            }
          }
        }
        break;
    }
    setApprovalConfig(approvalConfig);
    setReload(new Date().toLocaleString());
    modified();
    handleUsersPost(approvalConfig);
  }

  const commissinApprovalUserAssignment=(orderStep: ApprovalConfigurationStep, user: User, index: number)=>{
    const dom = document.getElementById(`commissionErr${index}`);
    if(dom) {
      dom.innerHTML = "";
    }
    let isError = false;
    approvalConfig.commissionApproval?.map((order)=>{
      if(order.approvalStep==orderStep.approvalStep){
        const exists =order?.approvers?.filter(de=>de.id==user.id);
        
        if(exists!=undefined && exists.length>0){

          order?.approvers?.map((de,i)=>{
            if(de.id===user.id){
              order?.approvers?.splice(i,1);
            }
            
            if((i=== 2 || i === 3) && order.isEnabled && order.approvers?.length === 0 && dom) {
              dom.innerHTML = t("At least 1 user must be selected.");
            }
          })

        }else{
          if(order.approvers && order.approvers?.length > 4) {
            if(dom) {
              dom.innerHTML = t("A maximum of five persons can be selected.");
              isError = true;
            }
          }
          order?.approvers?.push(user);
        }
      }
    });
    setApprovalConfig(approvalConfig);
    setReload(new Date().toLocaleString());
    modified();
    if (isError) {
      enableSave(false);
    } else {
      handleUsersPost(approvalConfig);
    }
    setReload(new Date().toLocaleString());
  };

  const getIcon = (step: number) => {
    let ret = <></>;
    switch(step) {
      case 0: 
        ret = (<img src={require("../../../assets/images/approvals/1stordercheck.png")} alt=""/>);
        break;
      case 1: 
        ret = (<img src={require("../../../assets/images/approvals/2ndordercheck.png")} alt=""/>);
        break;
      case 2: 
        ret = (<img src={require("../../../assets/images/approvals/Export.png")} alt=""/>);
        break;
      case 3: 
        ret = (<img src={require("../../../assets/images/approvals/Discount.png")} alt=""/>);
        break;
      case 4: 
        ret = (<img src={require("../../../assets/images/approvals/Others.png")} alt=""/>);
        break;
      case 5: 
        ret = (<img src={require("../../../assets/images/approvals/PEPmatch.png")} alt=""/>);
        break;
      case 6: 
        ret = (<img src={require("../../../assets/images/approvals/Dispatchorderprocessing.png")} alt=""/>);
        break;
    }
    return ret;
  }

  const getCommissionIcon = (step: number) => {
    let ret = <></>;
    switch(step) {
      case 0: 
        ret = (<img src={require("../../../assets/images/approvals/Recalculation.png")} alt=""/>);
        break;
      case 1: 
        ret = (<img src={require("../../../assets/images/approvals/1stordercheck.png")} alt=""/>);
        break;
      case 2: 
        ret = (<img src={require("../../../assets/images/approvals/2ndordercheck.png")} alt=""/>);
        break;
      case 3: 
        ret = (<img src={require("../../../assets/images/approvals/FinalCommission.png")} alt=""/>);
        break;
    }
    return ret;
  }

  const getOrderPopover = [
    "Initial order approval by management",
    "Second order approval by management",
    "Approval by export representative in case of an export deal",
    "Approval by management in case of >9,99% internal discount",
    "Special approval for individual cases that is commented in the order",
    "Approval by PEP representative in case of a PEP match",
    "Fill in order registration and customer delivery, perform recalculation and subsequent change"
  ]

  const getCommissionPopover = [
    "Repeated calculation by disposition",
    "Initial commission approval by management",
    "Second commissionapproval by management",
    "Approval by management in case of fixed commission",
    "Define monthly payroll period and start accounting ",
    "Approval by PEP representative in case of a PEP match",
    "Approve order lists for accounting"
  ]

  const getOrderActivationMsg = [
    "",
    "2nd order check is not activated. To enable this as an approval step go to “Edit entries”.",
    "Export is not activated. To enable this as an approval step go to “Edit entries”.",
    "Discount is not activated. To enable this as an approval step go to “Edit entries”.",
    "Others is not activated. To enable this as an approval step go to “Edit entries”.",
    "PEP match is not activated. To enable this as an approval step go to “Edit entries”.",
    ""
  ]

  const getCommissionActivateMsg = [
    "",
    "",
    "2nd commission check is not activated. To enable this as an approval step go to “Edit entries”.",
    "Fixed commission is not activated. To enable this as an approval step go to “Edit entries”.",
    "",
    ""
  ]

  return(
    <>
      <div className="container-variable" onMouseOver={props.removeHeader} onMouseOut={props.showHeader}>
        <ScrollToTop />
        <PText weight="bold" size="medium">
        {t('Order approval')}
        </PText>
        <div className="container-element">
          {/** Order Check */}
          
              {/** View mode */}
              {!edit && (
                approvalConfig.orderApproval?.map((order,i)=>{
                  return(   
                  <>
                  <div className={`${i > 0 ? "mt24" : "" }`}>
                 {i!==5 &&<PFlex direction={"column"} >
                    <PFlexItem>
                      <Stack direction={"row"} spacing={1}>
                        { getIcon(i) }
                        <PorscheFont className="dcs-title">{t(order.displayLabel)}</PorscheFont>
                      </Stack>
                    </PFlexItem>
                    { (i>0 && i<6) && !order.isEnabled && (
                        <PFlexItem className="mt4">
                          <Stack direction={"row"} spacing={1}>
                            <PIcon name="information" />
                            <PorscheFont className="dcs-title">{t(getOrderActivationMsg[i])}</PorscheFont>
                          </Stack>
                        </PFlexItem>
                    )}

                    {(order.approvers?.length==0) &&
                      ( i === 0 || i === 6 || (i>0 && i<6 && order.isEnabled === true) ) && (
                      <PFlexItem className="mt8">
                       <PFlex>
                         {/** Loop selected users here under view mode*/}
                         <PFlexItem style={{width: "25%", marginRight: "4px"}}>
                           <div className="read dcs-title" style={{textAlign: "left", paddingLeft: "4px"}} >{""}</div>
                         </PFlexItem>
                       </PFlex>
                      </PFlexItem>
                    )
                    }

                    {(order.approvers && order.approvers?.length>0 && ( i === 0 || i === 6 || (i>0 && i<6 && order.isEnabled === true))) &&(
                      <PFlexItem className="mt8">
                       <PFlex>
                        {
                          order.approvers.map((user,i)=>{
                              return( <PFlexItem style={{width: "20%", marginRight: "4px"}}>
                              <div className="read dcs-title" style={{textAlign: "left", paddingLeft: "4px"}} >{user.ppnName}</div>
                            </PFlexItem>)
                          })
                        }
                       </PFlex>
                      </PFlexItem>
                    )
                    }
                    </PFlex>} 
                  </div>
                  </>
                );
                })
              )}

              {/** Edit Mode */}
              { edit && (
                approvalConfig.orderApproval?.map((order,i)=>{
                  return(
                  <>
                  <div className={`${i > 0 ? "mt24" : "" }`}>
                 {i!==5 && 
                 <PFlex direction={"column"} >
                 <PFlexItem>
                   <Stack direction={"row"} spacing={1}>
                     { (i > 0 && i < 6 ) && (
                       <PSwitch
                         //alignLabel="left"
                         checked={order.isEnabled}
                         disabled={edit ? false : true}
                         style={{ paddingTop: "0px" }}
                         onSwitchChange={(e) => handleEnable(e, "order", i)}
                       >
                         {" "}
                       </PSwitch>
                     )}
                     { getIcon(i) }
                     <PorscheFont className="dcs-title">{t(order.displayLabel)}</PorscheFont>
                     <PPopover direction="top">
                       <PorscheFont className="dcs-popover">
                         { t(getOrderPopover[i]) }
                       </PorscheFont>
                     </PPopover>
                   </Stack>
                 </PFlexItem>
                 { (i > 0 && i < 6 && !order.isEnabled) && (
                   <PFlexItem className="mt8">
                     <PFlex>
                       {/** Loop selected users here under view mode*/}
                       <PFlexItem style={{width: "20%", marginRight: "4px"}}>
                         <div className="read dcs-title" style={{textAlign: "left", paddingLeft: "4px"}} ></div>
                       </PFlexItem>
                     </PFlex>
                   </PFlexItem>
                 )}

                 { (i === 0 || i === 6 || (i > 0 && i < 6 && order.isEnabled)) && (
                   <PFlexItem className="mt8">
                     <div className='user-dropdown'>
                       <ul>
                           {
                           activeUsers.map((user,j)=>{
                           
                             return (   
                               <>
                                 <li key={`${j}`} className="mt4">
                                 <PCheckboxWrapper label={user.ppnName} hideLabel={false}>
                                   <input 
                                     key={`idealer${j}`}
                                     type="checkbox"  
                                     checked={isChecked(order,user)? true : false}
                                     onClick={(e) => orderApprovalUserAssignment(order, user, i)}
                                     disabled={edit? false : true}
                                   
                                   />
                                 </PCheckboxWrapper>
                                 </li>  
                               </>
                             );
                           })
                         }
                   
                       </ul>
                     </div>
                   </PFlexItem>
                 )}
                 </PFlex>} 
                </div>
                <PorscheFont><span id={`orderErr${i}`} style={{color: "red"}}></span></PorscheFont>
              </>)
            })
          )}
        </div>
      </div>

      {/** Commission approval */}
      <div className="container-variable">
        <PText weight="bold" size="medium">
        {t('Commission approval')}
        </PText>
        <div className="container-element">
          {/** View mode */}
          
          {!edit && (
            approvalConfig.commissionApproval?.map((order,i)=>{
              if (i>3) return <></>;
              // if (order.approvalStep==="Recalculation") return <></>;
              return(   
              <>
              <div className={`${i > 0 ? "mt24" : "" }`}>
                {i!==0 &&  <PFlex direction={"column"} >
                <PFlexItem>
                  <Stack direction={"row"} spacing={1}>
                    { getCommissionIcon(i) }
                    <PorscheFont className="dcs-title">{t(order.displayLabel)}</PorscheFont>
                  </Stack>
                </PFlexItem>
                { (i === 2 || i === 3) && !order.isEnabled && (
                    <PFlexItem className="mt4">
                      <Stack direction={"row"} spacing={1}>
                        <PIcon name="information" />
                        <PorscheFont className="dcs-title">{t(getCommissionActivateMsg[i])}</PorscheFont>
                      </Stack>
                    </PFlexItem>
                )}

                {(order.approvers && order.approvers?.length==0) &&
                  ( ((i === 2 || i ===3) && order.isEnabled) || (i<2 || i>3)) && (
                   <PFlexItem className="mt8">
                   <PFlex>
                     {/** Loop selected users here under view mode*/}
                     <PFlexItem style={{width: "20%", marginRight: "4px"}}>
                       <div className="read dcs-title" style={{textAlign: "left", paddingLeft: "4px"}} >{""}</div>
                     </PFlexItem>
                   </PFlex>
                 </PFlexItem>
                )
                }
                {(order.approvers && order.approvers?.length>0) &&
                  (((i === 2 || i ===3) && order.isEnabled) || i<2 || i>3) &&(
                  <PFlexItem className="mt8">
                   <PFlex>
                    {
                      order.approvers.map((user,i)=>{
                          return( <PFlexItem style={{width: "20%", marginRight: "4px"}}>
                          <div className="read dcs-title" style={{textAlign: "left", paddingLeft: "4px"}} >{user.ppnName}</div>
                        </PFlexItem>)
                      })
                     }
                    </PFlex>
                  </PFlexItem>
                )}
                </PFlex>}            
              </div>
              </>);
            })
           )}

          {/** Edit Mode */}
          { edit && (
            approvalConfig.commissionApproval?.map((order,i)=>{
              // if (i>3 || order.approvalStep==="Recalculation") return <></>;
              return(
              <>
              <div className={`${i > 0 ? "mt24" : "" }`}>
              {i!==0 && <PFlex direction={"column"} >
              <PFlexItem>
                <Stack direction={"row"} spacing={1}>
                  { (i === 2 || i === 3) && (
                    <PSwitch
                      //alignLabel="left"
                      checked={order.isEnabled}
                      disabled={edit ? false : true}
                      style={{ paddingTop: "0px" }}
                      onSwitchChange={(e) => handleEnable(e, "commission", i)}
                    >
                      {" "}
                    </PSwitch>
                  )}
                  { getCommissionIcon(i) }
                  <PorscheFont className="dcs-title">{t(order.displayLabel)}</PorscheFont>
                  <PPopover direction="top">
                    <PorscheFont className="dcs-popover">
                      {t(getCommissionPopover[i])}
                    </PorscheFont>
                  </PPopover>
                </Stack>
              </PFlexItem>
              { ((i === 2 || i === 3) && !order.isEnabled) && (
                <PFlexItem className="mt8">
                  <PFlex>
                    {/** Loop selected users here under view mode*/}
                    <PFlexItem style={{width: "20%", marginRight: "4px"}}>
                      <div className="read dcs-title" style={{textAlign: "left", paddingLeft: "4px"}} ></div>
                    </PFlexItem>
                  </PFlex>
                </PFlexItem>
              )}

              { (i<2 || i>3 || ( (i === 2 || i === 3) && order.isEnabled)) && (
                <PFlexItem className="mt8">
                  <div className='user-dropdown'>
                    <ul>
                      {
                        activeUsers.map((user,j)=>{
                          return (   
                            <>
                              <li key={`${j}`} className="mt8">
                              <PCheckboxWrapper label={user.ppnName} hideLabel={false}>
                                <input 
                                  key={`idealer${j}`}
                                  type="checkbox"  
                                  checked={isChecked(order,user)? true : false}
                                  onClick={(e) => commissinApprovalUserAssignment(order, user, i)}
                                  disabled={edit? false : true}
                                />
                              </PCheckboxWrapper>
                              </li>  
                            </>
                          );
                        })
                      }
                    </ul>
                  </div>
                </PFlexItem>
              )}
            </PFlex>}              
            </div>
            <PorscheFont><span id={`commissionErr${i}`} style={{color: "red"}}></span></PorscheFont>
          </>)
        })
      )}
    </div>

  </div>
    </>
  )
}