import { User } from 'oidc-client';
import {Constants} from "../configs/Constants";
export class AuthService {

  public getUser(): Promise<User | null> {
    // @ts-ignore
    const tokenData = JSON.parse(sessionStorage.getItem(`oidc.user`));
    if (tokenData) {
      const user = new User(tokenData);
      return Promise.resolve(user);
    }
    return Promise.resolve(null);
  }

  public logout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    let url = `${Constants.domain}`;
    window.open(url, "_self");
  };
  
}
