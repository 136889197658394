import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import { Stack } from "@mui/system";

import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { CalculationDraftDTO } from "../../../interfaces/Calculation";
import { OrderDTO, SaveAsDraftDTO } from "../../../interfaces/Order";
import { GetRvm } from "../../../interfaces/Variables";
import {
  getCustomerType,
  getPayementType,
  getReferralType,
  getTradeInHeaderLabel,
  getUsedCarCollectionType,
  showDashToValue
} from "../../../mock/helper";
import { type } from "../../../services/Constants";
import {
  dcsgetOrderDetails,
  dcsgetOrderSummary,
  dcsgetRvm
} from "../../../services/data/Helpers";
import getSessionStorage from "../../../helpers/getSessionStorage";
import { Constant } from "../../../configs/Constants";
import draftValueHandler from "../../../helpers/sessionDraftValueHandler";

export const OrderDetailsPrint = React.forwardRef((props, ref: any) => {
  const { t } = useTranslation();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);

  const [searchParams, setSearchParams] = useSearchParams();
  const [orderData, setOrderData] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );
  const [orderResult, setOrderResult] = useState<SaveAsDraftDTO>(
    {} as SaveAsDraftDTO
  );
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [rvm, setRvm] = useState<GetRvm>({} as GetRvm);
  const mode = orderValues?.id ? orderValues?.id+'' :'' ;
  const draft = draftValueHandler(orderValues?.draft);
  // const { orderId, isDraft, order, handleClose } = props;

  useEffect(() => {
    const init = async () => {
      const apiResponse =
        draft === "true"
          ? await dcsgetOrderDetails(true, parseInt(mode))
          : await dcsgetOrderSummary(parseInt(mode));
     
      // setOrderResult(orderData?.rawResponse?.data['result'])
      if(apiResponse.status===type.SUCCESS){
        setOrderData(apiResponse.response?.calculation);
        setOrder(apiResponse.response?.order);
        const rvmResponse = await dcsgetRvm();
        if(rvmResponse.status===type.SUCCESS){
          const rvmData = rvmResponse.response as Array<GetRvm>;
          const rvm = rvmData.filter(
            (x) =>
              x.seriesDTO.id ===
              apiResponse.response?.order?.vehicleDetails
                ?.series?.id
          );
          if (rvm.length > 0 && rvm[0].grossProfit === true) {
            setRvm(rvm[0]);
          }
        }
      }
    };
    init();
  }, []);

  const formateDate = (strDate: string): string => {
    if (!strDate) return "";
    const date = new Date(strDate).toISOString().split("T")[0];
    const formatDate = date.toString().split("-");
    return `${formatDate[2]}.${formatDate[1]}.${formatDate[0]}`;
  };

  const getSeriesAndModelName = (order: OrderDTO) => {
    if ([1, 2, 3, 5, 6, 7].includes(order?.vehicleDetails?.category?.id)) {
      return `${order?.vehicleDetails?.series?.seriesName} ${order?.vehicleDetails?.model?.modelName}`;
    } else if ([8].includes(order?.vehicleDetails?.category?.id)) {
      return `${order?.vehicleDetails?.series?.seriesName}`;
    } else {
      return "-";
    }
  };

  return (
    <>
      <div ref={ref}>
        <div className="print-header-container">
          <div className="header-left-side">
            <PorscheFont className="print-heading-name">
              1. {t("Order details")}
            </PorscheFont>
            <div className="seller-info-label">
              <table>
                <tr>
                  <td className="table-label-td font-14">Seller</td>
                  <td className="font-14">
                    {showDashToValue(order?.orderCreator?.seller)}
                  </td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Comm No.</td>
                  <td className="font-14">
                    {showDashToValue(order?.vehicleDetails?.commissionNumber)}
                  </td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Act No.</td>
                  <td className="font-14">
                    {showDashToValue(order?.vehicleDetails?.actNumber)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="header-right-side">
            <Stack direction="column" className="right-header-container">
              <PorscheFont className="order-number">
                {t("Order No. 20120230001")}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Creation date")}: {formateDate(order?.createdOn)}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Last edited")}: {formateDate(order?.updatedOn)}
              </PorscheFont>
            </Stack>
          </div>
        </div>
        {/* vehicle start */}
        <div className="print-section-wrapper">
          <div className="table-title-wrapper">
            <PorscheFont>
              <span className="table-title">{t("Vehicle")}</span>
            </PorscheFont>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Porsche center")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.porscheCenter?.centerName)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Date of order")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {formateDate(
                        order?.dateOfOrder ? order?.dateOfOrder : ""
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Vehicle category")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.vehicleDetails?.category?.label)}
                    </PorscheFont>
                  </td>
                </tr>
                {[4, 9].includes(order?.vehicleDetails?.category?.id) ? null : (
                  <tr>
                    <td className="table-col-lable">
                      <PorscheFont>{t("Series & model")}</PorscheFont>
                    </td>
                    <td className="table-col-data">
                      <PorscheFont>{getSeriesAndModelName(order)}</PorscheFont>
                    </td>
                  </tr>
                )}
                {[4, 8, 9].includes(order?.vehicleDetails?.category?.id) ? (
                  <tr>
                    <td className="table-col-lable">
                      <PorscheFont>{t("Model Description")}</PorscheFont>
                    </td>
                    <td className="table-col-data">
                      <PorscheFont>
                        {showDashToValue(
                          order?.vehicleDetails?.modelDescription
                        )}
                      </PorscheFont>
                    </td>
                  </tr>
                ) : null}
                {[4, 5, 6, 8, 9].includes(
                  order?.vehicleDetails?.category?.id
                ) ? (
                  <tr>
                    <td className="table-col-lable">
                      <PorscheFont>{t("Act number")}</PorscheFont>
                    </td>
                    <td className="table-col-data">
                      <PorscheFont>
                        {showDashToValue(order?.vehicleDetails?.actNumber)}
                      </PorscheFont>
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Comission number")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.vehicleDetails?.commissionNumber)}
                    </PorscheFont>
                  </td>
                </tr>
                {[5].includes(order?.vehicleDetails?.category?.id) ? (
                  <tr>
                    <td className="table-col-lable">
                      <PorscheFont>{t("License plate")}</PorscheFont>
                    </td>
                    <td className="table-col-data">
                      <PorscheFont>
                        {showDashToValue(order?.vehicleDetails?.licensePlate)}
                      </PorscheFont>
                    </td>
                  </tr>
                ) : null}
                {[2, 4, 5, 6, 8].includes(
                  order?.vehicleDetails?.category?.id
                ) ? (
                  <tr>
                    <td className="table-col-lable">
                      <PorscheFont>{t("VIN (optional)")}</PorscheFont>
                    </td>
                    <td className="table-col-data">
                      <PorscheFont>
                        {showDashToValue(order?.vehicleDetails?.vin)}
                      </PorscheFont>
                    </td>
                  </tr>
                ) : null}
                {[8].includes(order?.vehicleDetails?.category?.id) ? (
                  <>
                    <tr>
                      <td className="table-col-lable">
                        <PorscheFont>{t("Used Car Collection")}</PorscheFont>
                      </td>
                      <td className="table-col-data">
                        <PorscheFont>
                          {showDashToValue(
                            getUsedCarCollectionType(
                              order?.vehicleDetails?.usedCarCollection
                            )
                          )}
                        </PorscheFont>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-col-lable">
                        <PorscheFont>{t("Purchaser")}</PorscheFont>
                      </td>
                      <td className="table-col-data">
                        <PorscheFont>
                          {showDashToValue(
                            order?.vehicleDetails?.purchaser?.seller
                          )}
                        </PorscheFont>
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
        {/* vehicle End */}
        {/* Customer */}
        <div className="print-section-wrapper">
          <div className="table-title-wrapper">
            <PorscheFont>
              <span className="table-title">{t("Customer")}</span>
            </PorscheFont>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Customer group")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {getCustomerType(
                        order?.customerDetails?.customerGroup
                          ? order?.customerDetails?.customerGroup
                          : "-"
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Customer type")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {`${getCustomerType(
                        order?.customerDetails?.customerGroupType
                          ? order?.customerDetails?.customerGroupType
                          : ""
                      )} - ${getCustomerType(
                        order?.customerDetails?.customerType
                          ? order?.customerDetails?.customerType
                          : ""
                      )}`}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Company name")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.customerDetails?.companyName)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("First name")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.customerDetails?.firstName)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Last name")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.customerDetails?.lastName)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("C@P GP number")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.customerDetails?.copNumber)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            fontFamily: "Porsche Next",
                            "&.Mui-checked": { color: "black" },
                          }}
                          checked={
                            order?.customerDetails?.deviantDriver ? true : false
                          }
                        />
                      }
                      label="Deviant driver"
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Driver first name")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    {showDashToValue(order?.customerDetails?.driverFirstName)}
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Driver last name")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    {showDashToValue(order?.customerDetails?.driverLastName)}
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "black",
                              fontFamily: "Porsche Next",
                            },
                          }}
                          checked={
                            order?.customerDetails?.confidential ? true : false
                          }
                        />
                      }
                      label="Confidential"
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            fontFamily: "Porsche Next",
                            "&.Mui-checked": { color: "black" },
                          }}
                          checked={
                            order?.customerDetails?.differentAddress
                              ? true
                              : false
                          }
                        />
                      }
                      label="Different Address"
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Street")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    {showDashToValue(
                      order?.customerDetails?.differentAddress?.street
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("House number")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    {showDashToValue(
                      order?.customerDetails?.differentAddress?.houseNumber
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Postal code")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    {showDashToValue(
                      order?.customerDetails?.differentAddress?.postalCode
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("City")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    {showDashToValue(
                      order?.customerDetails?.differentAddress?.city
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Customer  End*/}
        {/* Payment start */}
        <div className="print-section-wrapper">
          <div className="table-title-wrapper">
            <PorscheFont>
              <span className="table-title">{t("Payment")}</span>
            </PorscheFont>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Payment type")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {getPayementType(
                        order?.paymentDetails?.paymentType
                          ? order?.paymentDetails?.paymentType
                          : "-"
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Program (Optional)")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {getCustomerType(
                        order?.paymentDetails?.program_company
                          ? order?.paymentDetails?.program_company
                          : "-"
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Duration in months")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.paymentDetails?.durationInMonths)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            fontFamily: "Porsche Next",
                            "&.Mui-checked": { color: "black" },
                          }}
                          checked={
                            order?.paymentDetails?.leasing_s ? true : false
                          }
                        />
                      }
                      label="Leasing S"
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            fontFamily: "Porsche Next",
                            "&.Mui-checked": { color: "black" },
                          }}
                          checked={
                            order?.paymentDetails?.servicePlus ? true : false
                          }
                        />
                      }
                      label="Service Plus"
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            fontFamily: "Porsche Next",
                            "&.Mui-checked": { color: "black" },
                          }}
                          checked={order?.paymentDetails?.gap ? true : false}
                        />
                      }
                      label="GAP"
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            fontFamily: "Porsche Next",
                            "&.Mui-checked": { color: "black" },
                          }}
                          checked={
                            order?.paymentDetails?.carPolicy ? true : false
                          }
                        />
                      }
                      label={`${t("Car Policy")}`}
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            fontFamily: "Porsche Next",
                            "&.Mui-checked": { color: "black" },
                          }}
                          checked={
                            order?.paymentDetails?.porscheCardS ? true : false
                          }
                        />
                      }
                      label="Porsche Card S"
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Payment End */}
        {/* Delivery start */}
        <div className="print-section-wrapper">
          <div className="table-title-wrapper">
            <PorscheFont>
              <span className="table-title">{t("Delivery")}</span>
            </PorscheFont>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="table-col-lable">
                    <FormControlLabel
                      className="print-checkbox"
                      control={
                        <Checkbox
                          sx={{
                            fontFamily: "Porsche Next",
                            "&.Mui-checked": { color: "black" },
                          }}
                          checked={
                            order?.deliveryDetails?.exportDeal ? true : false
                          }
                        />
                      }
                      label="Export Deal"
                    />
                  </td>
                  <td className="table-col-data"></td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Delivery location")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(
                        order?.deliveryDetails?.deliveryLocation?.name
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Planned delivery")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {formateDate(
                        order?.deliveryDetails?.plannedDelivery
                          ? order?.deliveryDetails?.plannedDelivery
                          : ""
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Delivery present")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(
                        order?.deliveryDetails?.deliveryPresentDto === null
                          ? "None"
                          : order?.deliveryDetails?.deliveryPresentDto
                              ?.presentName
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Registration")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(
                        order?.deliveryDetails?.registrationDto?.label
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>
                      {t("Pref. license plate (Optional)")}
                    </PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(
                        order?.deliveryDetails?.preferredLicensePlate
                      )}
                    </PorscheFont>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Delivery End */}
        {/* Trade-in start */}
        <div className="print-section-wrapper">
          <div className="table-title-wrapper">
            <PorscheFont>
              <span className="table-title">{t("Trade-in")}</span>
            </PorscheFont>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Trade-In Type")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {getTradeInHeaderLabel(
                        order?.tradeInDetails?.tradeInType
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Manufacturer")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.tradeInDetails?.value)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("VIN")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.tradeInDetails?.vin)}
                    </PorscheFont>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Trade-in End */}
        {/* Referral start  */}
        <div className="print-section-wrapper">
          <div className="table-title-wrapper">
            <PorscheFont>
              <span className="table-title">{t("Referral")}</span>
            </PorscheFont>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Referral Type")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {getReferralType(
                        order?.referral?.referralType
                          ? order?.referral?.referralType
                          : "-"
                      )}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("First name")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.referral?.surName)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Last name")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.referral?.lastName)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Street")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.referral?.street)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("House number ")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.referral?.houseNumber)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("Postcal code ")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.referral?.postalCode)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("City")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.referral?.city)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>{t("ID card number (Optional)")}</PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(order?.referral?.idCardNumber)}
                    </PorscheFont>
                  </td>
                </tr>
                <tr>
                  <td className="table-col-lable">
                    <PorscheFont>
                      {t("Commercial register excerpt number (Optional)")}
                    </PorscheFont>
                  </td>
                  <td className="table-col-data">
                    <PorscheFont>
                      {showDashToValue(
                        order?.referral?.commercialRegisterNumber
                      )}
                    </PorscheFont>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Referral End  */}
      </div>
    </>
  );
});
