
import { format } from 'path';

import { CustomerType, OrderStatus, ServiceNameAdmin } from '../configs/Enums';
import { CustomerDetailsDTO } from '../interfaces/OrderDetails';
import { UpsaleError, UpsaleItemsTotal } from '../interfaces/Upsale';
import { UpsaleItemDTO } from '../interfaces/UpsaleNew';
import { upsaleTire } from './data';
import { variables } from './managed';
import { upsaleVariables } from './upsale';

export const vehicleCategories = variables[0].category;

export const getCWTTotal = (searchResult: any) => {};

export const searchCWT = (searchQuery: string) => {
  if (searchQuery.length === 0) {
    return [];
  }

  let result = [];
  const regex = new RegExp(searchQuery);
  const isNumber = parseInt(searchQuery);

  // Not a number
  if (isNaN(isNumber)) {
    // Search by name
    result = upsaleTire.filter((x) => x.tireName.toLocaleLowerCase().search(regex) !== -1);
  } else {
    // Search by number
    result = upsaleTire.filter((x) => x.tireId.toString().search(regex) !== -1);
  }

  return result;
};

export const formatCurrency = (value: number) => {
  let valueStr = value ? value.toFixed(2).toString() : "0.00";
  let result = "";
  valueStr = valueStr.replace(".", ",");
  if (valueStr.length > 6) {
    const dStr = valueStr.substring(valueStr.length - 6);
    const tStr = valueStr.substring(0, valueStr.length - 6);
    result = tStr + "." + dStr;

    if(result.length > 10) {
      const dStr1 = result.substring(result.length - 10);
      const tStr1 = result.substring(0, result.length - 10);
      result = tStr1 + "." + dStr1;
    }

    result = result + " €";
  } else {
    result = valueStr + " €";
  }
  return result;
};

export const formatCurrencyPrecision = (value: number, digits: number) => {
  let valueStr = value ? value.toFixed(digits).toString() : "0.00";
  let result = "";
  valueStr = valueStr.replace(".", ",");
  if (valueStr.length > 6) {
    const dStr = valueStr.substring(valueStr.length - 6);
    const tStr = valueStr.substring(0, valueStr.length - 6);
    result = tStr + "." + dStr;

    if(result.length > 10) {
      const dStr1 = result.substring(result.length - 10);
      const tStr1 = result.substring(0, result.length - 10);
      result = tStr1 + "." + dStr1;
    }

    result = result + " €";
  } else {
    result = valueStr + " €";
  }
  return result;
};

export const formatToWholeNumber = (value: number) => {
  //
  let negative = value < 0 ? true : false
  let newVal = parseInt(value.toString())
  let valueStr = newVal.toString();
  let result = "";
  valueStr = valueStr.replace(".", ",");
  valueStr = valueStr.replace("-", "");
  const beforeArray = valueStr.split("").reverse();
  let adc: Array<string> = [];
  for(let i=1; i<= beforeArray.length; i++) {
    if(i < 4) adc.push(beforeArray[i-1]);
    if(i === 4){
      adc.push(".");
      adc.push(beforeArray[i-1]);
    }
    if(i > 4){
      adc.push(beforeArray[i-1]);
      if(i%3 === 0 && i!== beforeArray.length)
      adc.push(".");
    }
  }

  result = adc.reverse().join("");
  
  return negative ? "-" + result : result;
}

export const formatCurrencyWithNoUnitOld = (value: number) => {
  
  let negative = value < 0 ? true : false
  let valueStr = value ? value.toFixed(2).toString() : "0.00";
  let result = "";
  valueStr = valueStr.replace(".", ",");
  valueStr = valueStr.replace("-", "");
  if (valueStr.length > 6) {
    const dStr = valueStr.substring(valueStr.length - 6);
    const tStr = valueStr.substring(0, valueStr.length - 6);
    result = tStr + "." + dStr;

    if(result.length > 10) {
      const dStr1 = result.substring(result.length - 10);
      const tStr1 = result.substring(0, result.length - 10);
      result = tStr1 + "." + dStr1;
    }
  } else {
    result = valueStr;
  }
//
  return negative ? "-" + result : result;
}

export const formatCurrencyWithNoUnit = (value: number) => {
  //
  let negative = value < 0 ? true : false
  let valueStr = value ? value.toFixed(2).toString() : "0.00";
  let result = "";
  valueStr = valueStr.replace(".", ",");
  valueStr = valueStr.replace("-", "");
  const afterDecimal = valueStr.substring(valueStr.length-2)
  const beforeDecimal = valueStr.substring(0, valueStr.length-3)
  const beforeArray = beforeDecimal.split("").reverse();
  let adc: Array<string> = [];
  for(let i=1; i<= beforeArray.length; i++) {
    if(i < 4) adc.push(beforeArray[i-1]);
    if(i === 4){
      adc.push(".");
      adc.push(beforeArray[i-1]);
    }
    if(i > 4){
      adc.push(beforeArray[i-1]);
      if(i%3 === 0 && i!== beforeArray.length)
      adc.push(".");
      
    }
  }

  result = adc.reverse().join("") +  "," + afterDecimal;
  return negative ? "-" + result : result;
}

export const formatCurrencyToDecimal = (value: string) => {
  let valueStr = value.replace(" €", "");
    valueStr = valueStr.trim();
    const noPeriods = valueStr.replace(/\./g, '');
    const withCommas = noPeriods.replace(/,/g, '.');
    const convertedValue = withCommas.length > 0 ? parseFloat(withCommas) : 0.00;
    return convertedValue ;
    
}

export const adminCurrencyFormatter = (value: string) => {
  let valueStr = value.replace(" €", "");
  valueStr = valueStr.trim();
  valueStr = valueStr.replace(".", "");
  valueStr = valueStr.replace(",", ".");
////
  const convertedValue = valueStr.length > 0 ? parseFloat(valueStr) : 0.00;
  return convertedValue;
}
export const formatCurrencyToDecimalPrecision = (value: string, digits: number) => {
  let valueStr = value.replace(" €", "");
  valueStr = valueStr.trim();
  valueStr = valueStr.replace(".", "");
  valueStr = valueStr.replace(",", ".");
////
  const convertedValue = parseFloat(valueStr).toPrecision(digits);

  return convertedValue;
}

export const getUpsaleItemsTotal = (usItems: Array<UpsaleItemDTO>) : UpsaleItemsTotal => {
  let total = {} as UpsaleItemsTotal;
  total.priceInternal = 0.00;
  total.sellingPriceNet = 0.00;
  total.customerShare = 0.00;
  total.discount = 0.00;
  total.commission = 0.00;
    
  usItems?.map((usItem, i) => {
    total.priceInternal += usItem.part.priceInternal !== undefined ? usItem.part.priceInternal : 0.00;
    total.sellingPriceNet += usItem.part.priceBeforeCustomerNet !== undefined ? usItem.part.priceBeforeCustomerNet : 0.00;
    total.customerShare += usItem.customerShare;
    // if (usItem.customerShare > 0) {
    //   total.discount += usItem.part.priceBeforeCustomerNet !== undefined ? usItem.part.priceBeforeCustomerNet - usItem.customerShare : 0.00;
    // } else {
    //   total.discount += usItem.part.priceInternal ? usItem.part.priceInternal : 0.00;
    // }
    total.discount += usItem.discount;
    total.commission += usItem.commission;

    usItem.serviceItems.map((serviceItem) => {
      if (serviceItem.timeUnits && serviceItem.timeUnits > 0) {
        total.priceInternal += serviceItem.services.priceInternal * serviceItem.timeUnits / 100;
        total.sellingPriceNet += serviceItem.services.priceBeforeCustomerNet * serviceItem.timeUnits / 100;
        total.customerShare += serviceItem.customerShare;
        // if (serviceItem.customerShare > 0) {
        //   total.discount += (serviceItem.services.priceBeforeCustomerNet * serviceItem.timeUnits / 100) - serviceItem.customerShare;
        // } else {
        //   total.discount += serviceItem.services.priceInternal * serviceItem.timeUnits / 100;
        // }
        total.discount += serviceItem.discount;
        total.commission += serviceItem.commission !== undefined ? serviceItem.commission  : 0.00;
      } else {
        total.priceInternal += serviceItem?.services?.priceInternal;
        total.sellingPriceNet += serviceItem?.services?.priceBeforeCustomerNet;
        total.customerShare += serviceItem?.customerShare;
        // if (serviceItem.customerShare > 0) {
        //   total.discount += serviceItem?.services?.priceBeforeCustomerNet - serviceItem?.customerShare;
        // } else {
        //   total.discount += serviceItem?.services?.priceInternal;
        // }
        total.discount += serviceItem?.discount;
        total.commission += serviceItem?.commission !== undefined ? serviceItem?.commission : 0.00;
      }
    })
  })

  return total;
}

export const formatDate= (date: string) => {
    const  da = date.length === 0 ? new Date() : new Date(date);
    let dateFormat = "";
    
    let dd=da.getDate();
    if(dd<10)
      dateFormat+='0'+dd;
    else
      dateFormat+=dd;

    if((da.getMonth()+1)<10)
      dateFormat+='.0'+(da.getMonth()+1);
    else
      dateFormat+="."+(da.getMonth()+1);
   
    dateFormat+="."+da.getFullYear();
    return dateFormat;

}

export const formatDateTime = (date: string) => {
  const dt = formatDate(date);
  const dtt = date.length > 0 ? new Date(date) : new Date();
  const dateTime = dt + " " + dtt.getHours() + ":" + dtt.getMinutes();
  return dateTime;
}

export const addError = (field: string, msg: string, errors: Array<UpsaleError>) => {
  const errExist = errors.filter(x => x.field === field);
  if ( errExist.length > 0 ) {
    return errors;
  } else {
    const err = {
      field: field,
      message: msg
    }
  
    return [...errors, err];
  }
} 

export const removeError = (field: string, errors: Array<UpsaleError>) => {
  let index = -1;
  errors.map((error, i) => {
    if (error.field === field) {
      index = i;
    }
  })
  
  if (index > -1) {
    errors.splice(index, 1);
  } 

  return errors;
} 

export const isError = (field: string, errors: Array<UpsaleError>) => {
  const errExists = errors.filter( x => x.field === field);

  return errExists.length > 0 ? "error" : "none";
} 

export const getStatusColor = (status: string) => {
  let color = "order-draft";
  let colorCode = "#313639";
  switch(status.toLowerCase()) {
    case "inreview":
      color = "order-inreview";
      colorCode = "#0061BD";
      break;

    case "converted":
      color = "order-converted";
      colorCode = "#FF9B00";
      break;

    case "cancelled":
      color = "order-cancelled";
      colorCode = "#E00000";
      break;

    case "approved":
    case "commissionsapproved":
      color = "order-confirmed";
      colorCode = "#00aa00";
      break;
    case "paid":
      color = "order-paid";
      colorCode = "#00610F";
      break;
    default:
      break;
  }

  return color;
}

export const getOrderStatusString = (id : OrderStatus) => {
  let status = "Draft";
  switch(id) {
    case OrderStatus.Draft:
      status = "Draft";
      break;
    
    case OrderStatus.InReview:
      status = "In Review";
      break;

    case OrderStatus.Cancelled:
      status = "Cancelled";
      break;

    case OrderStatus.Converted:
      status = "Converted";
      break;
    
    case OrderStatus.Approved:
      status = "Approved";
      break;

    case OrderStatus.Paid:
      status = "Paid";
      break;
  }

  return status;
}

export const getStatusString = (str: string) => {
  let status = "Draft";
  switch(str.toLowerCase()) {
    case "draft":
      status = "Draft";
      break;

    case "inreview":
      status = "In Review";
      break;

    case "converted":
      status = "Converted";
      break;

    case "cancelled":
      status = "Cancelled";
      break;

    case "approved":
    case "commissionsapproved":
      status = "Approved";
      break;
    
    case "paid":
      status = "Paid";
      break;
    
    default:
      break;
  }

  return status;
}

export const getCustomerName = (customerDetails: CustomerDetailsDTO) => {
  let customer = "";

  switch(customerDetails.customerType) {
    case CustomerType.PrivateCustomer:
    case CustomerType.VIPPress:
    case CustomerType.Multiplier:
      customer = customerDetails.surName.substring(0,1) + ". " +  customerDetails.lastName;
      break;

    case CustomerType.BusinessClient:
    case CustomerType.Dealer:
    case CustomerType.DealerBiddingPlatform:
    case CustomerType.KeyAccount:
    case CustomerType.Supplier:
      customer = customerDetails.companyName ? customerDetails.companyName : "";
      break;
  }

  return customer;
}

export const getCustomer = (customerDetails: any) => {
  let customer = "";

  switch(customerDetails.customerType) {
    case "PrivateCustomer":
    case "VIPPress":
    case "Multiplier":
      customer = customerDetails.surName.substring(0,1) + ". " +  customerDetails.lastName;
      break;

    case "BusinessClient":
    case "Dealer":
    case "DealerBiddingPlatform":
    case "KeyAccount":
    case "Supplier":
      customer = customerDetails.companyName ? customerDetails.companyName : "";
      break;
  }

  return customer;
}

export const checkVat = (value: string) => {
  const regxVat = /^[0-9]\d{0,3}((\,\d{0,2})?)$/;

  if(regxVat.test(value)) {
    return true;
  } else {
    return false;
  }
}

export const validatePercentage = (value: string) => {
  const regxVat = /^[0-9]\d{0,3}((\,\d{0,2})?)$/;

  if(regxVat.test(value)) {
    const range = formatCurrencyToDecimal(value);
    //
    if(range >= 0.00 && range <= 100.00) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const validateStrandingDays = (value: string) => {
  const regxVat = /^[0-9]+$/;

if(regxVat.test(value)) {
    const range = parseInt(value);
    //
    if(range >= 0 && range <= 9999) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const validateDuration = (value: string) => {
  const regxInt = /^[0-9]\d{0,2}$/;

  if(regxInt.test(value)) {
    return  parseInt(value) > 100 ? false : true;
  } else {
    return false;
  }
}

export const validateCurrency = (value: string) => {
  const regxVat = /^[0-9]+(\.[0-9]{3})*(\,\d{0,2})?$/;

  const withoutDot = value.replace(/\./g, "");
  const formattedString = withoutDot.replace(",", ".");

  return regxVat.test(value);
};

export const validateCurrencyFormat = (value: string) => {
  const regxVat = /^[0-9]\d{0,7}((\.\d{3})?)((\.\d{3})?)((\,\d{0,2})?)$/;

  if(regxVat.test(value)) {
    return true;
  } else {
    return false;
  }
}

export const getServiceAdminName = (value: string) => {
  let ret = "";
  switch(value) {
    case "TireChangePorscheCenter":
    case "Tire Change Porsche Center":
      ret = "Tire Change Porsche Center (Standard)";
      break;

    case "TireChangePorscheCenterCenterLock":
    case "Tire Change Porsche Center CenterLock":
      ret = "Tire Change Porsche Center (Center Lock)"
      break;

    case "TireChangeFactory":
    case "Tire Change Factory":
      ret = "Tire Change Factory";
      break;

    case "TireStorage":
    case "Tire Storage":
      ret = "Tire Storage";
      break;

    case "TireShipment":
    case "Tire Shipment":
      ret = "Tire Shipment";
      break;

    case "WorkshopWork":
    case "Workshop Work":
      ret = "Workshop work (100 Time Units / 1h)";
      break;
  }

  return ret;
}

export const currencyFormat = (num: string | undefined) => {
  return num?.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const getNumberValue = (value: string | undefined) => {
  if (value !== undefined) {
    const numberFormat = value?.replace(".", "").replace(",", ".");
    return numberFormat;
  }
  return value;
};

export const getWorkshopServiceName = (key: string, type: string) => {
  let str = "";
  switch (key) {
    case "Mounting (Standard)":
    case "Mounting (Center Lock)" :
      str = "Mounting (Winter tires)";
      break;
    case "Storage" :
      str = type === "winter" ? "Storage (Winter tires)" : "Storage (Summer tires)";
      break;
    case "Shipment" :
      str = type === "winter" ? "Shipment (Winter tires)" : "Shipment (Summer tires)";
      break;
    case "Pickup by Customer" :
    case "Pick-up by Customer" :
      str = type === "winter" ? "Pickup (Winter tires)" : "Pickup  (Summer tires)";
      break;
  }
  return str;
}

export const getTradeInHeaderLabel = (key: string | null | undefined) => {
  let str = "";
  switch (key) {
    case "TradeIn_Regular":
    case "Trade-In (regularly taxed)" :
      str = "Trade-In (regularly taxed)";
      break;
    case "TradeIn_Differential" :
    case "Trade-In (differential taxed)" :
      str = "Trade-In (differential taxed)";
      break;
    case "Leasing_Return" :
    case "Leasing return" :
      str = "Leasing return";
      break;
    case "Leasing_Redemption" :
    case "Leasing redemption" :
      str = "Leasing redemption";
      break;
    case "Financing_Redemption_Regular" :
    case "Financing redemption (regularly taxed)" :
      str = "Financing redemption (regularly taxed)";
      break;
    case "Financing_Redemption_Differential" :
    case "Financing redemption (differential taxed)" :
          str = "Financing redemption (differential taxed)";
        break;  
  }

  return str;
}

export const getTradeInName = (key: string) => {
  let str = "";
  switch (key) {
    case "CarValueInternal":
    case "Car Value (internal)" :
      str = "Car value (internal)";
      break;
    case "CarValueCustomer" :
    case "Car Value (customer)" :
      str = "Car value (customer)";
      break;
    case "LossBooking" :
    case "Loss booking" :
      str = "Loss booking";
      break;
    case "ProfitBooking" :
    case "Profit booking" :
      str = "Profit booking";
      break;
    case "SharePc" :
    case "Share PC" :
      str = "Share PC";
      break;
    case "ShareCustomer" :
    case "Share Customer" :
      str = "Share customer";
      break;
  }

  return str;
}

export const getPayementTypeName = (key: string | null) => {
  let str = "";
  switch(key) {
    case "DebitCredit":
      str = "Debit / Credit";
      break;
    case "SUBSCRIPTION":
      str = "Subscription";
      break;
    case "Leasing_PFS":
    case "Leasing_Other":
      str = "Leasing";
      break;
    case "Financing_PFS":
    case "Financing_Other":
      str = "Financing"
      break;
  }

  return str;
}
export const getPayementType = (key: string) => {
  let str = "";
  switch(key) {
    case "DebitCredit":
      str = "Debit / Credit";
      break;
    case "SUBSCRIPTION":
      str = "Subscription";
      break;
    case "Leasing_PFS":
      str = "Leasing (PFS)";
      break;
    case "Leasing_Other":
      str = "Leasing (Other)";
      break;
    case "Financing_PFS":
      str = "Financing (PFS)";
      break;
    case "Financing_Other":
      str = "Financing (Other)"
      break;
  }

  return str;
}
export const getPayementRowName = (key: string) => {
  let str = "";
  switch(key) {
    case "Leasing":
      str = "Special leasing payment";
      break;
    case "Financing":
      str = "Down payment";
      break;
    case "DebitCredit":
      str = "Up-front payment";
      break;
  }
  return str;
}
export const getReferralType = (key: string) => {
  let str = "";
  switch(key) {
    case "Internal_Empl":
      str = "Internal (employee)";
    break;
    break;
    case "External_Common":
      str = "External (commercial)";
      break;
    case "External_Private":
      str = "External (private)";
      break;
  }
  return str;
}
export const getCustomerType = (key: string) => {
  let str = "";
  switch(key) {
    case "PrivateCustomer":
      str = "Private customer";
      break;
    case "VIPPress":
      str = "VIP press";
      break;
    case "Multiplier":
      str = "Multiplier";
      break;
    case "BusinessClient":
      str = "Business client";
      break;
    case "Dealer":
      str = "Business client";
      break;
    case "DealerBiddingPlatform":
      str = "Dealer bidding platform";
      break;
    case "KeyAccount":
      str = "Key account";
      break;
    case "Supplier":
      str = "Supplier";
      break;    
    }
  return str;
}
export const getCollectionType = (key: string) => {
  let str = "";
  switch(key) {
    case "Tradein":
      str = "Trade in";
      break;
    case "LeasingRedemptionThirdParty":
      str = "Leasing redemption third party";
      break;
    case "LeasingRedemption PFS":
      str = "Leasing redemption PFS";
      break;
    case "LeasingReturn PFS":
      str = "Leasing return PFS";
      break;
    case "LeasingReturn":
      str = "Leasing return";
      break;
    case "LeasingReturnThirdParty":
      str = "Leasing return third party";
      break;
    case "LeasingTakeover":
      str = "Leasing takeover";
      break;
    case "FinancingTakeover":
      str = "Financing takeover";
      break;   
    case "FreePurchase":
      str = "Free purchase";
      break; 
    case "FactoryCar":
      str = "Factory car";
      break; 
    case "ServiceLoaner":
      str = "Service loaner";
      break;
    }
  return str;
}


export const getReferenceType = (key: string | undefined) => {
  let str: string|undefined = "";
  switch(key) {
    case "Euro":
      str = "Euro";
      break;
    case "GrossProfit":
      str = "Gross Profit";
      break;
    case "NetTurnover":
      str = "Net Turnover";
      break;
    case "RRPNet":
      str = "RRP (net)";
      break;
    case "RRPGross":
      str = "RRP (gross)";
      break;
    case "SellingPriceNet":
      str = "Selling price (net)";
      break;
    case "SellingPriceGross":
      str = "Selling price (gross)";
      break;
    case "GrossTurnover":
      str = "Gross Turnover";
      break;   
    case "SellingPrice":
      str = "Selling Price";
      break; 
    case "CustomerShare":
      str = "Customer Share";
      break;
    default:
      str = key;
      break;
  }

  return str ? str : "-";
};

export const getUsedCarCollectionType = (key: string | null) => {
  let str: string | null | undefined = "";
  switch (key) {
    case "TradeIn":
      str = "Trade in";
      break;
    case "LeasingRedemptionPFS":
      str = "Leasing redemption PFS";
      break;
    case "LeasingRedemptionThirdParty":
      str = "Leasing redemption third party";
      break;
    case "LeasingReturnPFS":
      str = "Leasing return PFS";
      break;
    case "LeasingReturnThirdParty":
      str = "Leasing return third party";
      break;
    case "LeasingTakeover":
      str = "Leasing takeover";
      break;
    case "FinancingTakeover":
      str = "Financing takeover";
      break;
    case "FreePurchase":
      str = "Free purchase";
      break;
    case "FactoryCar":
      str = "Factory car";
      break;
    case "ServiceLoaner":
      str = "Service Loaner";
      break;

    default:
      str = key;
      break;
  }

  return str ? str : "-";
};

export const getScalingType = (key: string) => {
  let str = "";
  switch(key) {
    case "scaling1":
      str = "Scaling 1";
      break;
    case "scaling2":
      str = "Scaling 2";
      break;
    case "scaling3":
      str = "Scaling 3";
      break;
    case "scaling4":
      str = "Scaling 4";
      break;
    case "scaling5":
      str = "Scaling 5";
      break;
    }
    return str;
  }

export const getExportRegion = (str: string, i: number) => {
  const strArray = str.split(" ");
  if(strArray.length > 0 && i === 0) {
     return strArray[0];
  }

  if(strArray.length > 1 && i === 1) {
     strArray.splice(0,1);
     return strArray.join(" ");
  }

  return "";
};

export const showDashToValue = (value: any) => {
  return value ? value : "-";
};


export const onBlurPercentageFormatter = (
  e: React.FocusEvent<HTMLInputElement, Element>
) => {
  let currentInputValue = e.currentTarget.value;
  const withoutDot = currentInputValue.replace(".", "");
  const formattedString = withoutDot.replace(",", ".");
  if (validatePercentage(currentInputValue))
    e.currentTarget.value = formatCurrencyWithNoUnit(Number(formattedString));
};

export const onBlurCurrencyFormatter = (
  e: React.FocusEvent<HTMLInputElement, Element>
) => {
  let currentInputValue = e.currentTarget.value;
  const withoutDot = currentInputValue.replace(".", "");
  const formattedString = withoutDot.replace(",", ".");
  if (validateCurrency(currentInputValue))
    e.currentTarget.value = formatCurrencyWithNoUnit(Number(formattedString));
};

export const negativeValueHandler=(value:number):number=>{
  if(value<0){
   return 0
  }
  else{
   return value
  }
 }