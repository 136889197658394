import Stack from '@mui/material/Stack';
import {
  PButton, PButtonGroup, PDivider, PFlex, PFlexItem, PPopover, PSelectWrapper, PText,
  PTextFieldWrapper
} from '@porsche-design-system/components-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { Constant } from '../../../../configs/Constants';
import { OrderContext } from '../../../../contexts/OrderContext';
import { sortApprovalConfiguration } from '../../../../helpers/sorter';
import { ApprovalStepDTO, ApprovalsRequestDTO } from '../../../../interfaces/Approvals';
import { CalculationDraftDTO } from '../../../../interfaces/Calculation';
import { OrderDTO, OrderSummaryDTO } from '../../../../interfaces/Order';
import { SingleOrderDetailType } from '../../../../interfaces/OrderDetails';
import { UserDTO, UserDetailsDTO } from '../../../../interfaces/User';
import {
  ApprovalConfiguration, ApprovalConfigurationStep
} from '../../../../interfaces/adminApproval/ApprovalConfiguration';
import { HistoryOrderLogTypes } from '../../../../interfaces/changeHistory';
import { type } from '../../../../services/Constants';
import {
  dcsgetAdminApprovalConfiguration,
  dcspostChangeHistoryLogs,
  dcspostOrderSummary,
  getLogedInUser
} from '../../../../services/data/Helpers';
import CustomSnakebar from '../../../common/CustomSnakebar';

interface Props {
  handleModalClose: () => void;
  showMessage: (variant: string, msg: string) => void;
  id: string | null;
  draft: string | null;
}

export const FirstApproval = (props: Props): JSX.Element => {
  const {handleModalClose, showMessage, id, draft } = props;
  const [approvalConfig, setApprovalConfig] = useState<ApprovalConfiguration>({} as ApprovalConfiguration ); 
  const [approvals, setApprovals] = useState<Array<ApprovalConfigurationStep>>([])
  const [approvalRequest, setApprovalRequest] = useState<ApprovalsRequestDTO>({} as ApprovalsRequestDTO);
  //const [approvalPost, setApprovalPost] = useState<Array<ApprovalStepDTO>>([]);
  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const orderContext = useContext(OrderContext);
  let userDetails = {} as UserDetailsDTO;
  const [subjectOpen, setSubjectOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const [ isValid, setIsValid ] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0.00);
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState("")
  const [orderBeingSubmit, setOrderBeingSubmitState] = useState(false)

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
      const user = JSON.parse(sessionUser);
      loggedInUserId = user.id;
  }

  useEffect(() => {
    const init = async () => {
      const ord = orderContext.getCtxOrderDetails();
      setOrder(ord);
      
      if(ord?.vehicleDetails?.category?.type.length > 0 && ord.porscheCenter) {
        const category = ord.vehicleDetails.category.type;
        const apiResponse = await dcsgetAdminApprovalConfiguration(ord.vehicleDetails.category.type)
        if(apiResponse.status===type.SUCCESS){
          const approvalConfig = apiResponse.response as ApprovalConfiguration;
          if (approvalConfig?.orderApproval) {
            approvalConfig.orderApproval = sortApprovalConfiguration(approvalConfig.orderApproval);
            setApprovals(approvalConfig.orderApproval);
          }
          
          setApprovalConfig(approvalConfig);
        }

        // Set the discount %
        let calculation = {} as CalculationDraftDTO;
        calculation = orderContext.getCtxCalculationDraftDetails(category);
        let discount = 0.00;
        if(calculation?.calculationHeader && calculation?.vehiclePrices) {
          switch(category) {
            case "NewCar":
              // RRP net value
              if(calculation?.vehiclePrices[2] && calculation?.vehiclePrices[2].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount !== undefined) {
                discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[2].amountNet * 100;
              }
              break;
            case "DemoCar":
              if(calculation?.vehiclePrices[0] && calculation?.vehiclePrices[0].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount !== undefined) {
                discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[0].amountNet * 100;
              }
              break;
            case "UsedCar":
              if(calculation?.vehiclePrices[0] && calculation?.vehiclePrices[0].amountNet > 0.00 && calculation?.calculationHeader.internalDiscount !== undefined) {
                discount = calculation?.calculationHeader.internalDiscount / calculation?.vehiclePrices[0].amountNet * 100;
              }
              break;
          }

          setDiscountPercent(discount);
        }
      }
    };
    init();
  }, [])

  const handleSubmit = async () => {
    const orderSummary = {
      order : orderContext.getCtxOrderDetails(),
      upsale: orderContext.getCtxUpsaleDetails(),
      calculation: orderContext.getCtxCalculationDraftDetails(orderContext.getCtxOrderDetails().vehicleDetails.category.type),
      approvalsStep: approvalRequest,
    } as OrderSummaryDTO;
    const upsaleDetails = orderContext.getCtxUpsaleDetails();
    if(upsaleDetails && upsaleDetails.includeAssistance === undefined && 
      upsaleDetails.durationInMonths === undefined && 
      upsaleDetails.upsaleItems === undefined
      ) {
        orderSummary.upsale = null;
    }

    if(draft && draft === "true" && id && parseInt(id) > 0) {
      orderSummary.draft = {id: parseInt(id)}
    }

    orderSummary.order.orderCreator = order?.orderCreator?.id !== undefined ?  order.orderCreator : {id: getLogedInUser().id};
    orderSummary.order.status = "InReview";

    //orderSummary.approvalsStep.createdBy = {id: getLogedInUser().id};
    setOrderBeingSubmitState(true)
    const apiResponse = await dcspostOrderSummary(orderSummary)
    
    if(apiResponse.status === type.SUCCESS) {
      const orderId = apiResponse.response?.orderId; 
      setShowAlert(Constant.Success);
      setAlertMessage(Constant.Savedsuccessfully);

      const call = async () => {
        props.handleModalClose();
        if(orderId) {
          const logData: HistoryOrderLogTypes = {
            page: "",
            tab: "Order Submitted",
            userId: loggedInUserId,
            comment: "",
            orderId: orderId
          };
          await dcspostChangeHistoryLogs(logData, 'order')
          const orderDetails:SingleOrderDetailType = { id: orderId, mode: 'view', draft: false };
            const newWindow= window.open(`/#/order/create`, "_blank")
            newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(orderDetails));
       
            window.close();
            setOrderBeingSubmitState(false)
            
        }
      }
      setTimeout(() => call(), 1000);
    }
    if(apiResponse.status===type.ERROR){
      setOrderBeingSubmitState(false)
      setShowAlert(Constant.Error);
      setAlertMessage(apiResponse.response);
    }

  }

  const handleAssignee = (approval: ApprovalConfigurationStep, users: Array<UserDTO>, aprroversIndex: number, value: string) => {
    let sessionUser = sessionStorage.getItem("userDetails");
    if(sessionUser) {
      userDetails = JSON.parse(sessionUser);
    }

    let user = users.filter(user => user.id === parseInt(value));
    if(approvalRequest.approvalSteps && approvalRequest.approvalSteps.length > 0){
        let exists = false;
        
        approvalRequest.approvalSteps.map((step, i) => {
          if(step.approvalStep === approval.approvalStep) {
            if(step.approvalStep === "Discount") {
                const count = approvalRequest.approvalSteps.filter(x => x.approvalStep === "Discount");
                if(count.length === 2) {
                    exists = true;
                    approvalRequest.approvalSteps[i].approver = user.length > 0 ? user[0] : {};
                } 
            } else {
                exists = true;
                approvalRequest.approvalSteps[i].approver = user.length > 0 ? user[0] : {};
            }
          }
        })

        if(!exists) {
             const newApprover = {
                createdBy: userDetails ? {id: userDetails.id, ppnName: userDetails.ppnName} : {},
                configurationStep: {id: approval?.id!},
                approvalStep: approval.approvalStep,
                isEnabled: approval.isEnabled,
                action: "Assigned",
                approver: user.length > 0 ? user[0] : {}
            } as ApprovalStepDTO;
            
            approvalRequest.approvalSteps.push(newApprover);
        }
    } else {
        let  newApprover = {
            createdBy: userDetails ? {id: userDetails.id, ppnName: userDetails.ppnName} : {},
            configurationStep: {id: approval?.id!},
            approvalStep: approval.approvalStep,
            isEnabled: approval.isEnabled,
            action: "Assigned",
            approver: user.length > 0 ? user[0] : {}
        } as ApprovalStepDTO;

        approvalRequest.approvalSteps = [newApprover];
    }

    if(approval.approvalStep === "Others" && parseInt(value) === 0) {
      setSubjectOpen(false);
      // need to remove others
    }

    if(parseInt(value) === 5){
      const filterReqData = approvalRequest.approvalSteps.filter(item => item.approvalStep !== "Others");
      setApprovalRequest({approvalSteps : filterReqData});
    }

    
    setIsValid(isValidApproval(approvalRequest));
      }

  const isValidApproval = (approvalRequest: ApprovalsRequestDTO) : boolean => {
    
    
    if(approvalRequest.approvalSteps.length > 0 ) {
        if(approvals.length > 0) {
            if (approvals[0].approvers && approvals[0].approvers.length > 0) {
                const exists0 = approvalRequest.approvalSteps.filter( x => x.approvalStep === approvals[0].approvalStep);
                if(exists0.length === 0) return false;
                if(exists0[0].approver.id === undefined) return false;
            }
            if (approvals[4].approvers && approvals[4].isEnabled && approvals[4].approvers.length > 0) {
              const exists4 = approvalRequest.approvalSteps.filter( x => x.approvalStep === approvals[4].approvalStep);
              // if(exists5.length === 0) return false;
              // if(exists5[0].approver.id === undefined) return false;
              
              if (exists4.length > 0) {
                  if(exists4[0].approver && exists4[0].approver.id && exists4[0].approver.id > 0) {
                      if(exists4[0].otherSubject === undefined) {
                        return false;
                      } else if(exists4[0].otherSubject.length === 0) {
                        return false;
                      }
                  }
              }
          }
            return true
        }
    } else {
        return false;
    };

    return true;
  }

  const handleSubject = (value : string) => {
        setSubjectOpen(true);
    if(parseInt(value) === 5){
      setSubjectOpen(!subjectOpen);
       if(approvalRequest.approvalSteps[1].otherSubject != ""){
        approvalRequest.approvalSteps[1].otherSubject = "";
      }
      } 
  };

  const handleOtherSubject = (value: string, step: string) => {
      approvalRequest.approvalSteps.map((app, i) => {
          if(app.approvalStep === step) {
              approvalRequest.approvalSteps[i].otherSubject = value;
          }
      })
      setApprovalRequest(approvalRequest);
            setIsValid(isValidApproval(approvalRequest));
  }

 

 
  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };
  return (
    <div className='tn-layout'>
      <div className="dcs-section-heading">
          <PorscheFont>{t("Approvals")}</PorscheFont>
      </div>
      <PDivider color="neutral-contrast-low"></PDivider>
      <PFlex>
        <PFlexItem width="one-quarter" className="dcs-heading"><PorscheFont>{t("Approval steps")}</PorscheFont></PFlexItem>
        <PFlexItem width="one-quarter"className="dcs-heading"><PorscheFont>{t("User assigned")}</PorscheFont></PFlexItem>
        <PFlexItem></PFlexItem>
      </PFlex>
      { approvals.length > 0 && approvals[0] && approvals[0].approvers && approvals[0].approvers.length > 0 && (
        <>
          <PFlex>
            <PFlexItem width="one-quarter">
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../../assets/images/approvals/1stordercheck.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont>{t("1st order check")}<span style={{ color: "#D5001B" }}>*</span></PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PFlexItem>
                <PSelectWrapper hideLabel={false}>
                    <select name="1stordercheck" onChange={(e) => handleAssignee(approvals[0], approvals[0].approvers ? approvals[0].approvers : [] , 0, e.target.value)} >
                    <option value={0}>{t("Assign to user")}</option>
                    {approvals[0].approvers.map((user, index) => {
                      // if (getLogedInUser().id !== user.id) {
                      if(order?.orderCreator?.id !== user.id) {
                        return(
                          <option key={index} value={user.id} >
                            {user.ppnName}
                          </option>
                        );
                      }
                    })}
                    </select>
                </PSelectWrapper>                              
              </PFlexItem>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { approvals.length > 0 && approvals[1] && approvals[1].isEnabled && approvals[1].approvers && approvals[1].approvers.length > 0 && (
        <>
          <PFlex>
            <PFlexItem width="one-quarter">
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../../assets/images/approvals/2ndordercheck.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont>{t("2nd order check")}<span style={{ color: "#D5001B" }}></span></PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "100%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}
      
      { approvals.length > 0 && approvals[2] && approvals[2].isEnabled && approvals[2].approvers && approvals[2].approvers.length > 0 && 
        order?.deliveryDetails?.exportDeal &&(
        <>
          <PFlex>
            <PFlexItem width="one-quarter">
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../../assets/images/approvals/Export.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont>{t("Export")}<span style={{ color: "#D5001B" }}></span></PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "100%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { discountPercent > 9.99 && approvals.length > 0 && approvals[3] && approvals[3].isEnabled && approvals[3].approvers && approvals[3].approvers.length > 0 &&(
        <>
          <PFlex>
            <PFlexItem width="one-quarter">
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                    <img src={require("../../../../assets/images/approvals/Discount.png")}></img>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                      <PorscheFont>{t("Discount")}</PorscheFont>
                      <PorscheFont style={{color: "#909090", fontSize:"12px", fontWeight:"400", marginTop:"-8px"}}>2 approvals required</PorscheFont>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "100%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
          </PFlex>
          <PFlex>
            <PFlexItem width="one-quarter">
              <PFlex>
                  <PFlexItem>
                      <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                  </PFlexItem>&nbsp;&nbsp;
                  <PFlexItem style={{ marginTop: "20px" }}>
                  </PFlexItem>
              </PFlex>
            </PFlexItem>
            <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PFlexItem>
                <div className='user-assigned' style={{width: "100%"}}>
                  <PorscheFont style={{ padding: "5px" }}></PorscheFont>
                </div>                           
              </PFlexItem>
            </PFlexItem>
          </PFlex>
          <PDivider color="neutral-contrast-low"></PDivider>
        </>
      )}

      { approvals.length > 0 && approvals[4] && approvals[4].isEnabled && approvals[4].approvers && approvals[4].approvers.length > 0 && (
      <>
      <PFlex>
        <PFlexItem width="one-quarter">
          <PFlex>
              <PFlexItem>
                  <PDivider orientation="vertical" style={{ marginTop: "10px", width: "5px", height: "50px", backgroundColor: "#C8CACB" }} />
              </PFlexItem>&nbsp;&nbsp;
              <PFlexItem style={{ marginTop: "20px" }}>
                <img src={require("../../../../assets/images/approvals/Others.png")}></img>
              </PFlexItem>&nbsp;&nbsp;
              <PFlexItem style={{ marginTop: "20px" }}>
                  <PorscheFont>{t("Others (Optional)")}<span style={{ color: "#D5001B" }}></span></PorscheFont>
              </PFlexItem>
          </PFlex>
        </PFlexItem>
        <PFlexItem width="one-quarter" style={{ marginTop: "10px", marginBottom: "10px" }}>
          <PFlexItem>
            <PSelectWrapper hideLabel={false}>
              <select name="1stordercheck" onChange={(e) => {
                handleSubject(e.target.value)
                handleAssignee(approvals[4], approvals[4].approvers ? approvals[4].approvers : [] , 5, e.target.value)
              }} >
              <option value={5}>{t("Assign to user")}</option>
              {approvals[4] && approvals[4].approvers?.map((user, index) => {
                //if (getLogedInUser().id !== user.id) {
                if(order?.orderCreator?.id !== user.id) {
                  return(
                    <option key={index} value={user.id} >
                      {user.ppnName}
                    </option>
                  );
                }
              })}
              </select>
            </PSelectWrapper>            
          </PFlexItem>
          {subjectOpen && (
            <Stack direction="row" style={{ marginLeft: "18px", marginRight: "-240px" }}>
                <PorscheFont style={{ marginTop: "10px", marginLeft: "-92px" }}>{t("Subject")}</PorscheFont><span style={{ color: "#D5001B", marginTop: "10px" }}>*</span> &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                <div style={{ marginTop: "4px", width: "900px" }}>
                    <PTextFieldWrapper >
                        <input type="text" name="other"   maxLength={50} required onChange={(e) => handleOtherSubject(e.target.value, approvals[4].approvalStep)} />
                    </PTextFieldWrapper>
                </div>&nbsp;
                <PText>
                    <PPopover style={{ marginTop: "12px" }} aria={{ 'aria-label': 'Some more descriptive label' }}>{t("This approval step is used for individual cases, please summarize the context.")}</PPopover>
                </PText>
            </Stack>
          )}
        </PFlexItem>
      </PFlex>
      <PDivider color="neutral-contrast-low"></PDivider>
      </>
      )}

      <PFlex justifyContent={"flex-end"} className="mt16">
        <PFlexItem alignSelf={"flex-end"}>
            <PButtonGroup>
              <PButton icon='save' onClick={handleSubmit} disabled={!isValid || orderBeingSubmit}>{t("Submit")}</PButton>
            </PButtonGroup>
             </PFlexItem>
      </PFlex>

      <div className="flex30 pt20">
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

      </div>
    </div>
  );
}