
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {
  PButton, PButtonGroup
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { HistoryOrderLogTypes } from '../../../interfaces/changeHistory';
import { type } from '../../../services/Constants';
import { dcspostChangeHistoryLogs, dcsputConvertOrder } from '../../../services/data/Helpers';

//import { TaskRow } from './TaskRow';

interface Props {
  handleClosePopup: () => void;
  orderId: number;
  isDraft: boolean;
}

export const ConvertOrder = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { handleClosePopup, orderId, isDraft } = props;
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  let loggedInUserId = 1;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    const user = JSON.parse(sessionUser);
    loggedInUserId = user.id;
  }

  const logData: HistoryOrderLogTypes = {
    page: "",
    tab: "Order Converted",
    userId: loggedInUserId,
    comment: "",
    orderId: orderId
  };

  const handleCancel = () => {
    handleClosePopup();
  }
  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };
  const handleErrorClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setIsError(false);
  };

  const handleKeepOrder = () => {
    const init = async () => {
      const apiResponse = await dcsputConvertOrder(orderId, isDraft, "Converted")
      if(apiResponse.status===type.SUCCESS){
        await dcspostChangeHistoryLogs(logData, 'order')
        setSaveSuccess(true);
        setTimeout(handleClosePopup, 2000);
        window.location.reload();  
      }
      else {
        setIsError(true);
      }
    }
    init();
  }

  return (
    <>
      <div style={{ height: "120px" }}>

        <PorscheFont className="sub-headding">{t("Convert this Order")}?</PorscheFont><br></br>
        <PorscheFont>{t("This action cannot be undone.")}</PorscheFont><br></br>
        <PButtonGroup>
          <PButton variant="tertiary" icon='close' onClick={handleCancel}>{t("No, keep order")}</PButton>
          <PButton variant="secondary" onClick={handleKeepOrder}>{t("Yes, convert order")}</PButton>
        </PButtonGroup>
      </div>
      
      <div className="flex30 pt20">
        <Snackbar
          open={saveSuccess}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleSuccessClose}
          message={"Successfully converted"}
          style={{ zIndex: 9999, marginTop: "14px" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            {"Successfully converted"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleErrorClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {t("Error while converting!")}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

