import {
    AdditionalCostsPriceDTO, CalculationCustomerDTO, CalculationDraftDTO,
    CalculationPaymentDetailsDTO, CommissionCalculationDTO, CommissionSharingDTO,
    CommissionSharingUserDetailsDTO, DiscountNetPriceDTO, KickbackNetPriceDTO, OnlineCarSaleInfoDTO,
    TradeInPriceDTO, VehiclePriceDTO
} from '../interfaces/Calculation';
import { OrderDTO, SaveAsDraftDTO } from '../interfaces/Order';
import { ReCalculation } from '../interfaces/ReCalculation';
import { UpsaleError } from '../interfaces/Upsale';
import { UpsaleDetailsDTO, UpsaleItemDTO } from '../interfaces/UpsaleNew';
import { Next } from '../interfaces/Validation';
import { getUpsaleItemsTotal } from '../mock/helper';
import {
  dcspostSaveDraftOrderDetails,
    getVat, postOrderDetails, postSaveDraftOrderDetails, postUpsaleDetails, putOrderDetails,
    putUpsaleDetails
} from './data/Helpers';

let orderDetails = {} as OrderDTO;
let upsaleDetails = {
  includeAssistance: true,
  durationInMonths: 0,
  upsaleItems: [] as Array<UpsaleItemDTO>,
  isTiresActivated: false,
  isPartsActivated: false,
  isPawActivated: false,
} as UpsaleDetailsDTO;


let ncCalculationDetails = {
  calculationType: "",
  calculationHeader: {
    grossProfit: 0.00,
    grossDiscount: 0.00,
    externalDiscount: 0.00,
    internalDiscount: 0.00,
    totalCommission: 0.00,
    reasonDiscount: "",

  },
  vehiclePrices: [
    {
      displayLabel:"Vehicle base price",
      priceType: "VehicleBasePrice",
      amountNet: 0.0,
      amountGross: 0.0,
    } as unknown as VehiclePriceDTO,
    {
      displayLabel:"Options",
      priceType: "Options",
      amountNet: 0.0,
      amountGross: 0.0,
    } as unknown as VehiclePriceDTO,
    {
      displayLabel:"RRP",
      priceType: "RRP",
      amountNet: 0.0,
      amountGross: 0.0,
    } as unknown as VehiclePriceDTO,
  ],
  discountNetPrices: [
    {
      displayLabel:"Vehicle discount",
      discountNetPriceType: "VehicleDiscountNet",
      discountInEuro: 0.0,
      discountInPercent: 0.0,
    } as unknown as DiscountNetPriceDTO,
    {
      displayLabel:"Referral commission",
      discountNetPriceType: "ReferralCommissionNew",
      discountInEuro: 0.0,
      discountInPercent: 0.0,
    } as unknown as DiscountNetPriceDTO,
    {
      displayLabel:"Dealer contribution",
      discountNetPriceType: "DealerContributionNew",
      discountInEuro: 0.0,
      discountInPercent: 0.0,
    } as unknown as DiscountNetPriceDTO,
  ],
  kickbackNetPrices: [
    {
      displayLabel:"Special condition",
      kickbackNetPriceType: "SpecialConditions", 
      kickbackInEuro: 0.00,
      kickbackInPercent: 0.00,
    } as unknown as KickbackNetPriceDTO,
    {
      displayLabel:"Price compensation",
      kickbackNetPriceType: "PriceCompensationNew", 
      kickbackInEuro: 0.00,
      kickbackInPercent: 0.00,
    } as unknown as KickbackNetPriceDTO,
    {
      displayLabel:"Sales promotion",
      kickbackNetPriceType: "SalesPromotionNew", 
      kickbackInEuro: 0.00,
      kickbackInPercent: 0.00,
    } as unknown as KickbackNetPriceDTO
  ],
  additionalCostsPrices: [
    {
      label:"",
      type: "Pickup",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"",
      type: "Registration",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"",
      type: "Paw",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"",
      type: "Assistance",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"Other",
      type: "Others",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
  ],
  workshopCosts: {} as UpsaleDetailsDTO,
  tradeInPrices: [
    {
      displayLabel:"Car value (internal)",
      tradeInColumn: "CarValueInternal",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Car value (customer)",
      tradeInColumn: "CarValueCustomer",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Profit booking",
      tradeInColumn: "ProfitBooking",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Share PC",
      tradeInColumn: "SharePc",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Share customer",
      tradeInColumn: "ShareCustomer",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO
  ],
  calculationPaymentDetails: {
    displayLabel:"Debit / Credit",
    paymentType: "DebitCredit",
    paymentNet: 0.00,
    paymentGross: 0.00,
    upFrontPaymentNet: 0.00,
    upFrontPaymentGross: 0.00,
    includeOptionals: [] as Array<string>,
  },
  discountComment: "",
  kickbackDescription: "",
  copTicketId: null,
  tradeInComment: null,
  additionalCostPriceComment: "",
  calculationCustomer: {
    invoiceNet: 0.00,
    invoiceGross: 0.00,
    preDeliveryNet: 0.00,
    preDeliveryGross: 0.00,
    paymentType: "DebitCredit",
    paymentNet: 0.00,
    paymentGross: 0.00,
  } as unknown as CalculationCustomerDTO,
  // commissionSharing: null,
  commissionCalculation: {
    vehicleCommission: "Standard",
    vehicleCommissionEuro: 50.00, 
    pfsCommission: 0.00,
    carPolicyCommission: 0.00,
    porscheCardS: 0.00,
    incentive: 0.00,
    totalCommission: 0.00,
    incentiveProgram: "",
    //commissionType: "",
  } as unknown as CommissionCalculationDTO,
  isCommissionSharingActive: false,
} as unknown as CalculationDraftDTO;

let dcCalculationDetails = {
  calculationType: "",
  calculationHeader: {
    grossDiscount: 0.00,
    externalDiscount: 0.00,
    internalDiscount: 0.00,
    totalCommission: 0.00,
  },
  vehiclePrices: [
    {
      displayLabel:"Vehicle base price",
      priceType: "RRP",
      amountNet: 0.0,
      amountGross: 0.0,
    } as unknown as VehiclePriceDTO,
    {
      displayLabel:"Selling price",
      priceType: "SellingPriceDemo",
      amountNet: 0.0,
      amountGross: 0.0,
    } as unknown as VehiclePriceDTO,
    {
      displayLabel:"Purchase price",
      priceType: "PurchasePriceDemo",
      amountNet: 0.0,
      amountGross: 0.0,
    } as unknown as VehiclePriceDTO,
    {
      displayLabel:"Discount",
      priceType: "Discount",
      amountNet: 0.0,
      amountGross: 0.0,
    } as unknown as VehiclePriceDTO,
  ],
  onlineCarSaleInfo: {
    standingDays: 0,
    initialRegistration: " ",
    mileage: 0.00,
    lastGrossBiddingPrice: 0.00,
    repairCosts: 0.00,
  } as unknown as OnlineCarSaleInfoDTO,
  discountNetPrices: [
    {
      displayLabel:"Referral commission",
      discountNetPriceType: "ReferralCommissionDemo",
      discountInEuro: 0.0,
      discountInPercent: 0.0,
    } as unknown as DiscountNetPriceDTO,
    {
      displayLabel:"Dealer contribution",
      discountNetPriceType: "DealerContributionDemo",
      discountInEuro: 0.0,
      discountInPercent: 0.0,
    } as unknown as DiscountNetPriceDTO,
  ],
  kickbackNetPrices: [
    {
      displayLabel:"Sales promotion",
      kickbackNetPriceType: "SalesPromotionDemo", 
      kickbackInEuro: 0.00,
      kickbackInPercent: 0.00,
    } as unknown as KickbackNetPriceDTO
  ],
  additionalCostsPrices: [
    {
      label:"",
      type: "Pickup",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"",
      type: "Registration",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"",
      type: "Paw",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"",
      type: "Assistance",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"Other",
      type: "Others",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
  ],
  workshopCosts: {} as UpsaleDetailsDTO,
  tradeInPrices: [
    {
      displayLabel:"Car value (internal)",
      tradeInColumn: "CarValueInternal",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Car value (customer)",
      tradeInColumn: "CarValueCustomer",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Profit booking",
      tradeInColumn: "ProfitBooking",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Share PC",
      tradeInColumn: "SharePc",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Share customer",
      tradeInColumn: "ShareCustomer",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO
  ],
  discountComment: "",
  kickbackDescription: "",
  copTicketId: null,
  additionalCostPriceComment: "",
  tradeInComment: "",
  calculationCustomer: {
    invoiceNet: 0.00,
    invoiceGross: 0.00,
    preDeliveryNet: 0.00,
    preDeliveryGross: 0.00,
    paymentType: "DebitCredit",
    paymentNet: 0.00,
    paymentGross: 0.00,
  } as unknown as CalculationCustomerDTO,
  calculationPaymentDetails: {
    displayLabel:"Debit / Credit",
    paymentType: "DebitCredit",
    paymentNet: 0.00,
    paymentGross: 0.00,
    upFrontPaymentNet: 0.00,
    upFrontPaymentGross: 0.00,
    includeOptionals: []
  } as unknown as CalculationPaymentDetailsDTO,
  // commissionSharing: [
  //   {
  //     shareCommission: true,
  //     sellerNames: {} as CommissionSharingUserDetailsDTO,
  //     shareInPercent: 50.00,
  //     shareInEuro: 0.00,
  //     isOrderCreator: true,
  //   } as unknown as CommissionSharingDTO,
  //   {
  //     shareCommission: true,
  //     sellerNames: {} as CommissionSharingUserDetailsDTO,
  //     shareInPercent: 50.00,
  //     shareInEuro: 0.0,
  //     isOrderCreator: false,
  //   } as unknown as CommissionSharingDTO,
  // ],
  commissionCalculation: {
    vehicleCommission: "Standard",
    vehicleCommissionEuro: 50.00, 
    pfsCommission: 0.00,
    carPolicyCommission: 0.00,
    porscheCardS: 0.00,
    incentive: 0.00,
    totalCommission: 0.00,
    incentiveProgram: ""
  } as unknown as CommissionCalculationDTO,
  isCommissionSharingActive: false,
} as unknown as CalculationDraftDTO;

let ucCalculationDetails = {
  calculationType: "",
  calculationHeader: {
    grossDiscount: 0.00,
    totalCosts: 0.00,
    totalCommission: 0.00,
  },
  vehiclePrices: [
    {
      displayLabel:"Selling price",
      priceType: "SellingPriceUsed",
      amountNet: 0.0,
      amountGross: 0.0,
      taxation: "Regular",
    } as unknown as VehiclePriceDTO,
    {
      displayLabel:"Purchase price",
      priceType: "PurchasePriceUsed",
      amountNet: 0.0,
      amountGross: 0.0,
      taxation: "Regular",
    } as unknown as VehiclePriceDTO,
  ],
  onlineCarSaleInfo: {
    standingDays: 0,
    initialRegistration: " ",
    mileage: 0.00,
    lastGrossBiddingPrice: 0.00,
    repairCosts: 0.00,
    comments: "",
  } as unknown as OnlineCarSaleInfoDTO,
  discountNetPrices: [
    {
      displayLabel:"Referral commission",
      discountNetPriceType: "ReferralCommissionUsed",
      discountInEuro: 0.0,
      discountInPercent: 0.0,
    } as unknown as DiscountNetPriceDTO,
    {
      displayLabel:"Dealer contribution",
      discountNetPriceType: "DealerContributionUsed",
      discountInEuro: 0.0,
      discountInPercent: 0.0,
    } as unknown as DiscountNetPriceDTO,
  ],
  kickbackNetPrices: [
    {
      displayLabel:"Sales promotion",
      kickbackNetPriceType: "SalesPromotionUsed", 
      kickbackInEuro: 0.00,
      kickbackInPercent: 0.00,
    } as unknown as KickbackNetPriceDTO
  ],
  additionalCostsPrices: [
    {
      label:"Pickup",
      type: "Pickup",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"Registration",
      type: "Registration",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"Paw",
      type: "Paw",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"Assistance",
      type: "Assistance",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
    {
      label:"Other",
      type: "Others",
      amountNet: 0.00,
      customerShare: 0.00,
      discountInEuro: 0.00,
      discountInPercent: 0.00,
    } as unknown as AdditionalCostsPriceDTO,
  ],
  workshopCosts: {} as UpsaleDetailsDTO,
  tradeInPrices: [
    {
      displayLabel:"Car value (internal)",
      tradeInColumn: "CarValueInternal",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Car value (customer)",
      tradeInColumn: "CarValueCustomer",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Profit booking",
      tradeInColumn: "ProfitBooking",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Share PC",
      tradeInColumn: "SharePc",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO,
    {
      displayLabel:"Share customer",
      tradeInColumn: "ShareCustomer",
      amountNet: 0.00,
      amountGross: 0.00
    } as unknown as TradeInPriceDTO
  ],
  discountComment: "",
  kickbackDescription: "",
  copTicketId: null,
  additionalCostPriceComment: "",
  tradeInComment: "",
  calculationCustomer: {
    invoiceNet: 0.00,
    invoiceGross: 0.00,
    preDeliveryNet: 0.00,
    preDeliveryGross: 0.00,
    paymentType: "DebitCredit",
    paymentNet: 0.00,
    paymentGross: 0.00,
  } as unknown as CalculationCustomerDTO,
  calculationPaymentDetails: {
    displayLabel:"Debit / Credit",
    paymentType: "DebitCredit",
    paymentNet: 0.00,
    paymentGross: 0.00,
    upFrontPaymentNet: 0.00,
    upFrontPaymentGross: 0.00,
    includeOptionals: []
  } as unknown as CalculationPaymentDetailsDTO,
  // commissionSharing: [
  //   {
  //     shareCommission: true,
  //     sellerNames: {} as CommissionSharingUserDetailsDTO,
  //     shareInPercent: 50.00,
  //     shareInEuro: 0.00,
  //     isOrderCreator: true,
  //   } as unknown as CommissionSharingDTO,
  //   {
  //     shareCommission: true,
  //     sellerNames: {} as CommissionSharingUserDetailsDTO,
  //     shareInPercent: 50.00,
  //     shareInEuro: 0.0,
  //     isOrderCreator: false,
  //   } as unknown as CommissionSharingDTO,
  // ],
  commissionCalculation: {
    vehicleCommission: "Standard",
    vehicleCommissionEuro: 50.00, 
    pfsCommission: 0.00,
    carPolicyCommission: 0.00,
    porscheCardS: 0.00,
    incentive: 0.00,
    totalCommission: 0.00,
    incentiveProgram: ""
  } as unknown as CommissionCalculationDTO,
  isCommissionSharingActive: false,
} as unknown as CalculationDraftDTO;

let reCalculation={} as ReCalculation;
let saveAsDraftOrderDetails = {} as SaveAsDraftDTO;
let errors: Array<UpsaleError> = [];
let errorExits: boolean = false;

let orderErrors: Array<string> = [];

export const allowNext: Array<Next> = [
  {
    tabIndex: 0,
    isValid: false,
  },
  {
    tabIndex: 1,
    isValid: false,
  },
  {
    tabIndex: 2,
    isValid: false,
  },
  {
    tabIndex: 3,
    isValid: false,
  },
  {
    tabIndex: 4,
    isValid: false,
  },
];

export const Order = {
  getFormValidation: (tabIndex: number) => {
    let next = allowNext.filter((next) => next.tabIndex === tabIndex);
    return next.length > 0 ? next[0].isValid : false;
  },

  updateFormValidation: (tabIndex: number, isValid: boolean) => {
    allowNext.map((next) => {
      if (next.tabIndex === tabIndex) {
        next.isValid = isValid;
      }
    });
  },

  /** Order  **/
  getCtxOrderDetails: () => {
    return orderDetails;
  },

  updateCtxOrderDetails: (oDetails: OrderDTO) => {
    orderDetails = oDetails;
    return true;
  },

  getCtxSaveDraftOrderDetails: () => {
    return saveAsDraftOrderDetails;
  },

  updateCtxSaveDraftOrderDetails: (sdOrderDetails: SaveAsDraftDTO) => {
    saveAsDraftOrderDetails = sdOrderDetails;
    return true;
  },

  saveAsDraftOrderDetails: (saveAsDraftOrderDetails: SaveAsDraftDTO) => {
    return dcspostSaveDraftOrderDetails(saveAsDraftOrderDetails)
  },

  saveOrderDetails: (ordDetails: OrderDTO) => {
    return postOrderDetails(ordDetails);
  },

  updateOrderDetails: (ordDetails: OrderDTO) => {
    return putOrderDetails(ordDetails);
  },

  /** Upsale  **/
  getCtxUpsaleDetails: () => {
    return upsaleDetails;
  },

  updateCtxUpsaleDetails: (usDetails: UpsaleDetailsDTO) => {
    upsaleDetails = usDetails;
    return true;
  },

  saveUpsaleDetails: (usDetails: UpsaleDetailsDTO) => {
    return postUpsaleDetails(usDetails);
  },

  updateUpsaleDetails: (usDetails: UpsaleDetailsDTO) => {
    return putUpsaleDetails(usDetails);
  },

  getCtxReCalculation: () => {
    return reCalculation;
  },

  updateCtxReCalculation: (recalculationdata: ReCalculation) => {
    reCalculation = recalculationdata;
    return true;
  },

  /** Calculation  **/
  getCtxCalculationDraftDetails: (category: string) => {
    let calDetails = {} as CalculationDraftDTO;
    switch (category) {
      case "NewCar":
        calDetails = ncCalculationDetails;
        break;
      case "DemoCar":
        calDetails = dcCalculationDetails;
        break;
      case "UsedCar":
        calDetails = ucCalculationDetails;
        break;
    }
    return calDetails;
  },

  updateCtxCalculationDraftDetails: (
    calDetails: CalculationDraftDTO,
    category: string
  ) => {
    switch (category) {
      case "NewCar":
        ncCalculationDetails = calDetails;
        break;
      case "DemoCar":
        dcCalculationDetails = calDetails;
        break;
      case "UsedCar":
        ucCalculationDetails = calDetails;
        break;
    }
    return calDetails;
  },

  getCalculationHeaderDetails: (category: string) => {
    //return calculateHeader(category);
  },

  getErrorExists: () => {
    return errorExits;
  },

  setErrorExists: (error: boolean) => {
    errorExits = error;
    return true;
  },

  getErrors: () => {
    return errors;
  },

  clearErrors: () => {
    errors = [];
  },

  setErrors: (error: UpsaleError) => {
    const errExist = errors.filter((x) => x.field === error.field);
    if (errExist.length > 0) {
      return errors;
    } else {
      const err = {
        field: error.field,
        message: error.message,
        section: error.section,
      };

      errors = [...errors, err];
      return errors;
    }
  },

  removeError: (field: string) => {
    let index = -1;
    errors.map((error, i) => {
      if (error.field === field) {
        index = i;
      }
    });

    if (index > -1) {
      errors.splice(index, 1);
    }

    return errors;
  },

  removeSectionError: (section: string) => {
    let index = -1;
    errors.map((error, i) => {
      if (error.section === section) {
        index = i;
      }
    });

    if (index > -1) {
      errors.splice(0, index + 1);
    }

    return errors;
  },

  getOrderErrors: () => {
    return orderErrors
  },

  updateOrderErrors: (errs: Array<string>) => {
    orderErrors = errs;
    return orderErrors
  }
};

const calculateInternalDiscount = (category: string) => {
  let vat = 19.00;
  const init = async () => {
    const response = await getVat();
    const vatRes = response["result"];
    vat = vatRes.vatValue;
  }
  let intDisc = 0.00;
  intDisc = getDicountNetInternalDicount(category) - 
          getKickbackNetInternalDicount(category) + 
          getAdditionalCostsDicount(category) +
          getWorkshopCostsDicount(category) - 
          (getTradeInNetBooking(category) / ( 1 + vat/100))

  return intDisc;
}

const getDicountNetInternalDicount = (category: string) => {
  let calDraft = Order.getCtxCalculationDraftDetails(category);
  let sum = 0.00;
  if (calDraft.discountNetPrices) {
    const length = calDraft.discountNetPrices.length;
    for(let i=0; i<length; i++) {
      sum += calDraft.discountNetPrices[i].discountInEuro;
    }
  }
  return sum;
}

const getKickbackNetInternalDicount = (category: string) => {
  let calDraft = Order.getCtxCalculationDraftDetails(category);
  let sum = 0.00;
  if (calDraft.kickbackNetPrices) {
    const length = calDraft.kickbackNetPrices.length;
    for(let i=0; i<length; i++) {
      sum += calDraft.kickbackNetPrices[i].kickbackInEuro;
    }
  }
  return sum;
}

const getAdditionalCostsDicount = (category: string) => {
  let calDraft = Order.getCtxCalculationDraftDetails(category);
  let sum = 0.00;
  if (calDraft.additionalCostsPrices) {
    const length = calDraft.additionalCostsPrices.length;
    for(let i=0; i<length; i++) {
      sum += calDraft.additionalCostsPrices[i].discountInEuro;
    }
  }
  return sum;
}

const getWorkshopCostsDicount = (category: string) => {
  let calDraft = Order.getCtxCalculationDraftDetails(category);
  let sum = 0.00;
  if (calDraft.workshopCosts && calDraft.workshopCosts.upsaleItems && calDraft.workshopCosts.upsaleItems.length > 0) {
    const total = getUpsaleItemsTotal(calDraft.workshopCosts.upsaleItems);
    sum += total.discount;
  }
  return sum;
}

const getTradeInNetBooking = (category: string) => {
  let calDraft = Order.getCtxCalculationDraftDetails(category);
  let sum = 0.00;
  if (calDraft.tradeInPrices) {
    sum += calDraft.tradeInPrices[3].amountNet;
  }
  return sum;
}

const calculateExternalDiscount = (category: string) => {
  let extDisc = 0.00;
  let calDraft = Order.getCtxCalculationDraftDetails(category);
  if (calDraft.discountNetPrices) {
    extDisc = calDraft.discountNetPrices[0].discountInEuro + 
              getAdditionalCostsDicount(category) + 
              getWorkshopCostsDicount(category);
  }
  return extDisc;
}

const calculateGrossProfit = (category: string) => {
  let gross = 0.00;

  return gross;
}