import { Alert, Snackbar, Stack, Tab, Tabs } from "@mui/material";
import {
  IconName,
  PButton,
  PButtonGroup,
  PDivider,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PModal,
  PSelectWrapper,
  PTextFieldWrapper,
} from "@porsche-design-system/components-react";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { PorscheFont } from "../../assets/styles/CustomStyles";
import { CustomSeries } from "../../components/common/CustomSeries";
import { CustomSeriesModel } from "../../components/common/CustomSeriesModel";
import { CustomTextField } from "../../components/common/CustomTextField";
import { OrderContext } from "../../contexts/OrderContext";
import { isValidEuropeanAccountingFormat } from "../../helpers/formatters";
import { isValidUserForDispatchOrderProcessing } from "../../helpers/sorter";
import {
  UsedCarCollection,
  VehicleCategoryDto,
} from "../../interfaces/Order";
import { Series } from "../../interfaces/Upsale";
import { UserDetailsDTO } from "../../interfaces/User";
import { UserDetails } from "../../interfaces/UserDetails";
import {
  ManualCommissionUserObject
} from "../../interfaces/commission";
import {
  formatCurrencyToDecimal,
  formatCurrencyWithNoUnit,
  formatDate,
} from "../../mock/helper";
import { type } from "../../services/Constants";
import {
  dcsdeleteManualCommission,
  dcsgetActiveUsers,
  dcsgetManualCommission,
  dcsgetOrderManagedVariables,
  dcspostCreateManualCommission,
  getManualCommission
} from "../../services/data/Helpers";
import { ManualCommissionApproval } from "./ManualCommissionApproval";
import getSessionStorage from "../../helpers/getSessionStorage";
import { Constant } from "../../configs/Constants";
import { SingleOrderDetailType } from "../../interfaces/OrderDetails";
import CustomSnakebar from "../../components/common/CustomSnakebar";
interface ManualCommissionObject {
  vehicleData: vehicleDataType;
  customerSellerDetails: CustomerSellerDetails;
  commissionDetails: CommissionDetails;
  commissionApproval: commissionApprovalData[];
  creationDate?: string;
  updateDateTime?: string;
  status?: string;
}

interface vehicleDetailsType {
  category: any;
  model: any;
  modelDescription: string;
  series: any;
  origin: any;
  usedCarCollection: string;
}

interface vehicleDataType {
  vehicleDetails: vehicleDetailsType;
}

interface CustomerSellerDetails {
  customer: string;
  seller: SellerDetails;
}

interface SellerDetails {
  id: number | string;
  ppnName: string;
}

interface CommissionDetails {
  description: string;
  serviceDate: string;
  accountingDate: string;
  commission: string;
  incentiveBonus: string;
}

interface commissionApprovalData {
  id: null | number;
  commissionRank: string;
  action: string;
  comment: string;
  ppnName: string;
}

const commissionApproval: commissionApprovalData[] = [
  {
    id: null,
    commissionRank: "1st",
    action: "Pending",
    comment: "",
    ppnName: "",
  },
  {
    id: null,
    commissionRank: "2nd",
    action: "Pending",
    comment: "",
    ppnName: "",
  },
];

const vehicleDetails: vehicleDetailsType = {
  category: null,
  model: null,
  modelDescription: "",
  series: null,
  origin: null,
  usedCarCollection: "",
};

const vehicleData: vehicleDataType = {
  vehicleDetails: vehicleDetails,
};

const initialFormObject: ManualCommissionObject = {
  vehicleData: vehicleData,
  customerSellerDetails: {
    customer: "",
    seller: {
      id: "default",
      ppnName: "",
    },
  },
  commissionDetails: {
    description: "",
    serviceDate: "",
    accountingDate: "",
    commission: "0",
    incentiveBonus: "0",
  },
  commissionApproval: commissionApproval,
};
interface Props {
  manualCommissionDispatchData: ManualCommissionUserObject;
}
export const ManualCommissionPage = (props: Props): JSX.Element => {
  const inputFieldMaxLength:number = 15
  const { t } = useTranslation();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);
  const i18nextLng = localStorage.getItem('i18nextLng');
  const isEnglishLang = i18nextLng === "en"; 
  const { manualCommissionDispatchData } = props;
  const orderContext = useContext(OrderContext);
  let userDetails = {} as UserDetailsDTO;
  const sessionUser = sessionStorage.getItem("userDetails");
  if (sessionUser) {
    userDetails = JSON.parse(sessionUser);
  }
  const [formData, setFormData] =
    useState<ManualCommissionObject>(initialFormObject);
  const [isSavedApprovalData, setIsSavedApprovalData] = useState<Array<any>>(
    []
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const toggleref = useRef<HTMLDivElement | null>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const idValue = orderValues?.id ? orderValues?.id+'' :'' ;
  const [disabled, setDisabled] = useState<boolean>(!!idValue);
  const [vehicleCategories, setVehicleCategories] = useState<
    Array<VehicleCategoryDto>
  >([]);
  const [usedCarCollections, setUsedCarCollections] = useState<
    Array<UsedCarCollection>
  >([]);
  const [series, setSeries] = useState<Array<Series>>([]);
  const [edit, setEdit] = useState<boolean>(!idValue);

  const [isOpenExitModal, setIsOpenExitModal] = useState<boolean>(false);
  const [isOpenCancelCommission, setIsOpenCancelCommission] =
    useState<boolean>(false);
  const [activeOriginUsers, setOriginUsers] = useState<Array<UserDetails>>([]);
  const [vehicleCategoryId, setVehicleCategoryId] = useState<number>(0);
  const [modified, setModified] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValidApprovalSubmit, setisValidApprovalSubmit] =
    useState<boolean>(false);
  // const [saveSuccess, setSaveSuccess] = useState(false);
  // const [cancelSuccess, setCancelSuccess] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [reload, setReload] = useState("");
  const [initialValueCommission, setInitialValueCommission] = useState("");
  const [initialValueIncentiveBonus, setInitialValueIncentiveBonus] =
    useState("");
  const commissionId = idValue;
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)


  let isDisableCancelCommissionButton = isValidUserForDispatchOrderProcessing(userDetails);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setEdit(!idValue);
    setDisabled(!!idValue);
  }, []);

  useEffect(() => {
    // Assuming formData.commissionDetails.commission is asynchronously fetched
    if (formData.commissionDetails.commission) {
      setInitialValueCommission(
        formatCurrencyWithNoUnit(
          parseInt(formData.commissionDetails.commission)
        )
      );
    }
    if (formData.commissionDetails.incentiveBonus) {
      setInitialValueIncentiveBonus(
        formatCurrencyWithNoUnit(
          parseInt(formData.commissionDetails.incentiveBonus)
        )
      );
    }
  }, [
    formData.commissionDetails.commission,
    formData.commissionDetails.incentiveBonus,
  ]);

  useEffect(() => {
    const init = async () => {
      await loadManagedVariables();
    };

    const getManualCommissionInit = async () => {
      try {
        if (commissionId) {
          const apiResponse = await dcsgetManualCommission(commissionId);
          if (apiResponse.status===type.SUCCESS) {
            let responseData = apiResponse.response as ManualCommissionObject;
            let commissionApprovalData = JSON.parse(
              JSON.stringify(responseData?.commissionApproval)
            );
            setIsSavedApprovalData(commissionApprovalData);
            setFormData(responseData);
            setVehicleCategoryId(
              responseData?.vehicleData?.vehicleDetails?.category?.id
            );
          }

        }
      } catch (error) {
        console.error(error);
      }
    };

    const userInit = async () => {
      const apiResponse = await dcsgetActiveUsers();
      if (apiResponse.status===type.SUCCESS) {
        const activeUsersResponse = apiResponse.response as Array<UserDetails>;
        const sameOriginUsers = activeUsersResponse.filter(
          (x) => x.origin.id === userDetails.origin.id
        );
        setOriginUsers(sameOriginUsers);
      }
    };

    init();
    userInit();

    if (!edit) {
      getManualCommissionInit();
    }

    setFormData((prev) => ({
      ...prev,
      vehicleData: {
        ...prev.vehicleData,
        vehicleDetails: {
          ...prev.vehicleData.vehicleDetails,
          origin: {
            ...userDetails.origin,
            centerId: null,
            orderSequence: null,
          },
        },
      },
    }));
  }, []);


  useEffect(() => {
    setIsValid(validateData(formData, vehicleCategoryId));
    setisValidApprovalSubmit(validateApprovalData(formData));
  }, [formData, vehicleCategoryId]);

  const handleModified = () => {
    setModified(true);
  };

  const handleActions = () => {
    setToggle(!toggle);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toggleref.current &&
      !toggleref.current.contains(event.target as Node)
    ) {
      setToggle(false);
    }
  };

  const handleExit = (action: string) => {
    if (modified) {
      if (action === "exit-button") {
        setIsOpenExitModal(true);
      } else if (action === "exit-confirm") {
        window.close();
      }
    } else {
      window.close();
    }
  };

  const handleCancelModal = () => {
    setIsOpenExitModal(false);
  };

  const handleCancelCommission = (open: boolean) => {
    setIsOpenCancelCommission(open);
  };

  const loadManagedVariables = async () => {
    const variables = await dcsgetOrderManagedVariables(1, orderValues? true:false);
    if (variables) {
      setVehicleCategories(variables[0]);
      setSeries(variables[8]);
      setUsedCarCollections(variables[9]);
    }
  };

  const handleDeleteManualCommission = async () => {
      const apiResponse = await dcsdeleteManualCommission(commissionId);
      if (apiResponse.status===type.SUCCESS ) {
        // refreshPage();
        handleCancelCommission(false);
        setShowAlert(Constant.Success)    
        setAlertMessage("Cancelled successfully.")
        setModified(false);
        setFormData((prev) => ({
          ...prev,
          status: "Cancel",
        }));
      }
      if(apiResponse.status===type.ERROR){
        setShowAlert(Constant.Error)
        setAlertMessage(apiResponse.response) 
      }
  };

  const validateData = (data: any, vehicleId: number) => {
    if (!data || typeof data !== "object") {
      return false; // Return false if data is missing or not an object
    }

    if (
      !data?.vehicleData ||
      typeof data?.vehicleData !== "object" ||
      !data?.vehicleData?.vehicleDetails ||
      typeof data?.vehicleData?.vehicleDetails !== "object" ||
      !data?.vehicleData?.vehicleDetails?.category ||
      typeof data?.vehicleData?.vehicleDetails?.category !== "object" ||
      !data?.vehicleData?.vehicleDetails?.category?.id ||
      (![4, 9].includes(vehicleId) &&
        ((![8].includes(vehicleId) &&
          !data?.vehicleData?.vehicleDetails?.model?.id) ||
          !data?.vehicleData?.vehicleDetails?.series?.id)) ||
      ([4, 8, 9].includes(vehicleId) &&
        !data?.vehicleData?.vehicleDetails?.modelDescription) ||
      ([8, 9].includes(vehicleId) &&
        !data?.vehicleData?.vehicleDetails?.usedCarCollection)
    ) {
      return false;
    }

    // Validate customerSellerDetails
    if (
      !data?.customerSellerDetails ||
      typeof data?.customerSellerDetails !== "object" ||
      !data?.customerSellerDetails?.customer ||
      !data?.customerSellerDetails?.seller ||
      typeof data?.customerSellerDetails?.seller !== "object" ||
      !data?.customerSellerDetails?.seller?.id ||
      !data?.customerSellerDetails?.seller?.ppnName
    ) {
      return false;
    }

    // Validate commissionDetails
    if (
      !data?.commissionDetails ||
      typeof data?.commissionDetails !== "object" ||
      !data?.commissionDetails?.description ||
      !data?.commissionDetails?.serviceDate ||
      !data?.commissionDetails?.accountingDate ||
      !data?.commissionDetails?.commission ||
      !data?.commissionDetails?.incentiveBonus
    ) {
      return false;
    }

    // Validate commissionApproval
    if (
      (edit && !data?.commissionApproval[0]?.id) ||
      !data?.commissionApproval[0]?.commissionRank ||
      !data?.commissionApproval[0]?.action ||
      !data?.commissionApproval[0]?.ppnName
    ) {
      return false;
    }
    return true;
  };

  const validateApprovalData = (data: any): boolean => {
    if (!data?.commissionApproval?.length) {
      return false;
    }
    if (
      data?.commissionApproval[0]?.id &&
      !["Pending", "Assigned"].includes(data.commissionApproval[0]?.action) &&
      (manualCommissionDispatchData?.commissionCheck2Users?.length
        ? data?.commissionApproval[1]?.id
        : true)
    ) {
      return true;
    }
    return false;
  };
  const clearVehicleDetails = () => {
    setFormData((prev) => ({
      ...prev,
      vehicleData: {
        ...prev.vehicleData,
        vehicleDetails: {
          ...prev.vehicleData.vehicleDetails,
          category: {
            id: 0,
            label: "",
            type: "",
            option: "",
          },
          modelDescription: "",
          model: null,
          series: null,
          usedCarCollection: "",
        },
      },
    }));
    setVehicleCategoryId(0);
  };

  const handleModelDescription = (e: ChangeEvent<HTMLInputElement>): void => {
    const updatedFormData = { ...formData.vehicleData };
    const vehicleDetails = updatedFormData.vehicleDetails || {};
    const categoryType = vehicleDetails?.category?.id;
    if (categoryType === 4 || categoryType === 8 || categoryType === 9) {
      vehicleDetails.modelDescription = e.target.value;

      // Remove the error for "modelDescription" using setErrors on updatedFormData
      updatedFormData.vehicleDetails.modelDescription = e.target.value;
      updatedFormData.vehicleDetails.category.id = categoryType;
      updatedFormData.vehicleDetails.category.label = "";
      updatedFormData.vehicleDetails.category.type = "";

      // Handle the rest of your error logic here, if needed
    } else {
      return;
    }
    setFormData((prev) => ({ ...prev, vehicleData: updatedFormData }));

    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleCategory = (e: ChangeEvent<HTMLSelectElement>): void => {
    setVehicleCategoryId(0);
    if (e.target.value === "default") {
      clearVehicleDetails();
      orderContext.setErrors({
        field: "category",
        message: "",
        section: "vehicleDetails",
      });
      setReload(new Date().toLocaleString());
      return;
    }
    const category_models = vehicleCategories?.filter(
      (x) => x.id === parseInt(e.target.value)
    );
    const selectedCategory = category_models[0];
    const formObject = { ...formData.vehicleData };
    const vehicleDetails = formObject.vehicleDetails || {}; // Ensure the structure is preserved
    vehicleDetails.category = vehicleDetails.category || {};
    vehicleDetails.category.id = selectedCategory.id;
    vehicleDetails.category.type = selectedCategory.type;
    vehicleDetails.category.label = selectedCategory.label;
    vehicleDetails.category.option = selectedCategory.option;
    if ([4, 8, 9].includes(parseInt(e.target.value))) {
      formObject.vehicleDetails = {
        ...vehicleDetails,
        modelDescription: "",
        model: null,
        series: null,
      };
    }
    setVehicleCategoryId(selectedCategory.id);
    setFormData((prev) => ({
      ...prev,
      vehicleData: formObject, // Preserve the structure
    }));
    handleModified();
    orderContext.removeError("category");
  };

  const handleUsedCarCollection = (e: ChangeEvent<HTMLSelectElement>): void => {
    const formObject = { ...formData.vehicleData };
    const vehicleDetails = formObject.vehicleDetails || {};
    if (!e.target.value?.length) {
      orderContext.setErrors({
        field: "usedCarCollection",
        message: "",
        section: "vehicleDetails",
      });
      setReload(new Date().toLocaleString());
    }
    vehicleDetails.usedCarCollection = e.target.value;
    setFormData((prev) => ({
      ...prev,
      vehicleData: formObject, // Preserve the structure
    }));
    handleModified();
    orderContext.removeError("usedCarCollection");
  };

  const handleSeriesAndModel = (seriesId: number, modelId: number) => {
    setFormData((prev) => {
      const formData = { ...prev };
      const vehicleDetails = formData.vehicleData.vehicleDetails || {};
      vehicleDetails.series = vehicleDetails.series || {};
      vehicleDetails.model = vehicleDetails.model || {};
      const previousSeriesId = vehicleDetails.series.id || 0;
      const previousModelId = vehicleDetails.model.id || 0;

      series.map((s) => {
        if (seriesId === s.id) {
          vehicleDetails.series.id = seriesId;
          vehicleDetails.series.seriesName = s.seriesName;

          if (modelId !== previousModelId) {
            const modelName = s.models.find((model) => model.id === modelId);
            if (modelName) {
              vehicleDetails.model.id = modelId;
              vehicleDetails.model.modelName = modelName.modelName;
            }
          }
        }
      });

      if (previousSeriesId !== seriesId || previousModelId !== modelId) {
        handleModified();
      }

      formData.vehicleData.vehicleDetails = vehicleDetails;
      orderContext.removeError("seriesAndModel");
      setReload(new Date().toLocaleString());

      return { ...formData };
    });
  };

  const handleSeries = (seriesId: number) => {
    setFormData((prev) => {
      const formData = { ...prev };
      const vehicleDetails = formData.vehicleData.vehicleDetails || {};
      vehicleDetails.series = vehicleDetails.series || {};
      const previousSeriesId = vehicleDetails.series.id || 0;

      series.map((s) => {
        if (seriesId === s.id) {
          vehicleDetails.series.id = seriesId;
          vehicleDetails.series.seriesName = s.seriesName;
        }
      });

      if (previousSeriesId !== seriesId) {
        handleModified();
      }

      formData.vehicleData.vehicleDetails = vehicleDetails;

      orderContext.removeError("seriesAndModel");
      setReload(new Date().toLocaleString());
      return { ...formData };
    });
  };

  const handleSubmitManualCommission = async () => {
    
      const apiResponse = await dcspostCreateManualCommission(formData);
      if (apiResponse.status===type.SUCCESS) {
        const data = apiResponse.response
        const singOrderDetails:SingleOrderDetailType = {id: data?.id};
        const newWindow=  window.open(`/#/commission/manualcommission`, "_self") 
        newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(singOrderDetails));
        window.location.reload();
        setIsSavedApprovalData(data);
        setFormData(data);
        setShowAlert(Constant.Success)    
        setAlertMessage(Constant.Savedsuccessfully)
        setModified(false);
      }
      if(apiResponse.status===type.ERROR){
        setShowAlert(Constant.Error)
        setAlertMessage(apiResponse.response) 
      }
  };

  const handleSubmitApprovalCommission = async () => {
  
      const apiResponse = await dcspostCreateManualCommission(formData);
      if (apiResponse.status===type.SUCCESS) {
        setShowAlert(Constant.Success)    
        setAlertMessage(Constant.Savedsuccessfully)
        window.location.reload();
      }
      if(apiResponse.status===type.ERROR){
        setShowAlert(Constant.Error)
        setAlertMessage(apiResponse.response) 
      }
  };



  const filteredActiveOriginUsers = activeOriginUsers.filter(
    (user) => formData.customerSellerDetails.seller.id !== user.id
  );
  const filteredCommissionCheck1Users =
    manualCommissionDispatchData &&
    manualCommissionDispatchData?.commissionCheck1Users?.filter(
      (user) => formData?.customerSellerDetails?.seller?.id !== user?.id
    );
  const filteredCommissionCheck2Users =
    manualCommissionDispatchData &&
    manualCommissionDispatchData?.commissionCheck2Users?.filter(
      (user) => formData?.customerSellerDetails?.seller?.id !== user?.id
    );

    const handleClose = ( ) => {
      setShowAlert(Constant.None)
      setAlertMessage("")
    };

  return (
    <>
      <div className="manual-commission-wrapper">
        <div className="header-fixed manual-header">
          <div className="order-header">
            <PFlex>
              <PFlexItem className="header-left manual-header-left"></PFlexItem>
              <PFlexItem
                className="header-middle"
                style={{ paddingTop: "40px" }}
              >
                <img
                  src={require("../../assets/images/porsche1.png")}
                  alt="porsche-logo"
                  width="125px"
                  height="66px"
                />
              </PFlexItem>
              {!edit ? (
                <PFlexItem className="header-right">
                  <PFlex
                    alignItems={{ base: "flex-start", l: "flex-end" }}
                    direction="column"
                  >
                    <PFlexItem style={{ paddingTop: "16px" }}>
                      <PorscheFont className="status-title">
                        {formData?.status === "Cancel"
                          ? t("Commission cancelled")
                          : t("Manual commission")}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem>
                      <PorscheFont
                        className="status-subtitle"
                        style={{ paddingLeft: "125px" }}
                      >
                        {t("Creation date")}:{" "}
                        {formatDate(
                          formData?.creationDate ? formData?.creationDate : ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                    <PFlexItem>
                      <PorscheFont
                        className="status-subtitle"
                        style={{ paddingLeft: "125px" }}
                      >
                        &ensp; {t("Last edited")}:{" "}
                        {formatDate(
                          formData?.updateDateTime
                            ? formData?.updateDateTime
                            : ""
                        )}
                      </PorscheFont>
                    </PFlexItem>
                  </PFlex>
                </PFlexItem>
              ) : null}
            </PFlex>
            {/* Change starting */}
            <PFlex justifyContent="space-between">
              <PFlexItem>
                <PFlex className="flex80 pt20">
                  <div style={{ float: "left" }}>
                    <Tabs
                      textColor="primary"
                      indicatorColor="secondary"
                      // onChange={handleChange}
                      // onClick={handleShowHeader}
                      value="ManualCommission"
                      sx={{
                        minHeight: "24px!important",
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#D5001B",
                          height: 2,
                        },
                        "& .MuiTab-root.Mui-selected": {
                          color: "#313639",
                          textTransform: "inherit",
                          fontFamily: "Porsche Next",
                          paddingBottom: "8px",
                        },
                      }}
                    >
                      <Tab
                        // disabled={!tabPanel.enabled}
                        label={`${t("Manual commission")}`}
                        value="ManualCommission"
                        sx={{
                          // borderLeft: "1px solid #C8CACB ",
                          padding: "6px 12px !important",
                          minHeight: "16px!important",
                          textTransform: "inherit",
                          fontFamily: "Porsche Next",
                          fontSize: "24px",
                          fontWeight: "700",
                        }}
                      />
                    </Tabs>
                  </div>
                </PFlex>
              </PFlexItem>
              <PFlexItem alignSelf={"center"}>
                <PFlex>
                  <PFlexItem>
                    <Stack direction="row" className="actions-button-container">
                      <div className="exit-modal-container">
                        <PButton
                          variant="tertiary"
                          icon="close"
                          className={`exit-button ${
                            formData?.status === "Cancel" ? "Canceled" : ""
                          }`}
                          onClick={() =>
                            handleExit(
                              formData?.status === "Cancel"
                                ? "exit-confirm"
                                : "exit-button"
                            )
                          }
                        >
                          {t("Exit")}
                        </PButton>
                        {isOpenExitModal ? (
                          <div className="exit-modal-box" style={!isEnglishLang ? {width:"475px"} : {width: "285px"}}>
                            <PorscheFont className="modal-header-text">
                              {t("Exit manual commission?")}
                            </PorscheFont>
                            <PorscheFont className="modal-discription-text">
                              {t("Unsaved changes will be discarded.")}
                            </PorscheFont>
                            <PButtonGroup className="modal-actions-button">
                              <Stack className="action-btns">
                                <PButton
                                  className="cancel-btn"
                                  variant="tertiary"
                                  icon="close"
                                  onClick={handleCancelModal}
                                  style={!isEnglishLang ? {width:"194px"} : {width:"140px"}}
                                >
                                  <PorscheFont>{t("No, continue")}</PorscheFont>
                                </PButton>
                                <PButton
                                  className="submit-btn"
                                  variant="secondary"
                                  onClick={() => handleExit("exit-confirm")}
                                  style={!isEnglishLang ? {width:"194px"} : {width:"140px"}}
                                >
                                  <PorscheFont>{t("Yes, exit")}</PorscheFont>
                                </PButton>
                              </Stack>
                            </PButtonGroup>
                          </div>
                        ) : null}
                      </div>

                      {formData?.status === "Cancel" ? null : edit ? (
                        <PButton
                          variant="primary"
                          className="submit-btn"
                          onClick={handleSubmitManualCommission}
                          disabled={!isValid}
                        >
                          {t("Submit")}
                        </PButton>
                      ) : (
                        <div className="action-container" ref={toggleref}>
                          <PButton
                            variant="secondary"
                            icon={"menuLines" as IconName}
                            className="actions-btn"
                            onClick={handleActions}
                          >
                            {t("Actions")}
                          </PButton>
                          {toggle ? (
                            <div className="actions-menu-container">
                              <ul>
                                {!!isDisableCancelCommissionButton ? (
                                  <li
                                    onClick={() => handleCancelCommission(true)}
                                  >
                                    <PorscheFont className="sub-menu-item">
                                      {t("Cancel commission")}
                                    </PorscheFont>
                                  </li>
                                ) : (
                                  <li>
                                    <PorscheFont
                                      className="sub-menu-item"
                                      style={{
                                        color: "#C8CACB",
                                        cursor: "default",
                                      }}
                                    >
                                      {t("Cancel commission")}
                                    </PorscheFont>
                                  </li>
                                )}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Stack>
                  </PFlexItem>
                </PFlex>
              </PFlexItem>
            </PFlex>
          </div>
        </div>
        <div className="manual-commission-container">
          <div className="manual-commission-box">
            <PorscheFont data-testid="vehicle" className="header-text">
              {t("Vehicle")}
            </PorscheFont>
            <PGrid className="vehicle-category-center">
              <PGridItem size={3}>
                <PSelectWrapper
                  id="categoryWrap"
                  label={`${t("Vehicle category")}`}
                >
                  <select
                    id="category"
                    name="category_id"
                    required
                    onChange={(e) => handleCategory(e)}
                    value={formData?.vehicleData?.vehicleDetails?.category?.id}
                    disabled={disabled}
                  >
                    <option key="default" value="default">
                      {t("Select Vehicle Category")}
                    </option>
                    {vehicleCategories?.map((category, index) => {
                      return (
                        <option
                          key={index}
                          value={category?.id}
                          selected={
                            category?.id ===
                            formData?.vehicleData?.vehicleDetails?.category?.id
                          }
                        >
                          {t(category?.label)}{" "}
                        </option>
                      );
                    })}
                  </select>
                </PSelectWrapper>
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField
                  id="porscheCenterWrap"
                  label={`${t("Porsche center")}`}
                  type="text"
                  name="porscheCenter"
                  value={
                    formData?.vehicleData?.vehicleDetails?.origin?.centerName
                  }
                  required={true}
                  disabled={true}
                />
              </PGridItem>

              {[8, 9].includes(vehicleCategoryId) ? (
                <>
                  <PGridItem size={3}>
                    <PSelectWrapper
                      id="usedCarCollectionWrap"
                      label={`${t("Used Car Collection")}`}
                    >
                      <select
                        id="usedCarCollection"
                        name="used_car_collection_id"
                        required
                        onChange={(e) => handleUsedCarCollection(e)}
                        disabled={disabled}
                        value={
                          formData?.vehicleData?.vehicleDetails
                            ?.usedCarCollection
                        }
                      >
                        <option key="default" value="">
                          {t("Select Used Car Collection")}
                        </option>
                        {usedCarCollections?.map((usedCar, index) => {
                          return (
                            <option
                              key={index}
                              value={usedCar.second}
                              selected={
                                usedCar.second ===
                                formData?.vehicleData?.vehicleDetails
                                  ?.usedCarCollection
                              }
                            >
                              {t(usedCar.first.trim())}
                            </option>
                          );
                        })}
                      </select>
                    </PSelectWrapper>
                  </PGridItem>
                </>
              ) : null}
            </PGrid>
            <PGrid>
              {vehicleCategoryId > 0 &&
              ![4, 8, 9].includes(vehicleCategoryId) &&
              series.length > 0 ? (
                <>
                  <PGridItem size={12}>
                    <div style={{ paddingBottom: "10px" }}>
                      <PorscheFont className={`${disabled ? "disabled" : ""}`}>
                        {t("Select series and model")}{" "}
                        <span style={{ color: "#D5001B" }}>*</span>
                      </PorscheFont>
                    </div>
                    <div id="seriesModel" className={"series-model-error-hide"}>
                      <PorscheFont>
                        {t("Please select series and model to proceed further")}{" "}
                      </PorscheFont>
                    </div>
                    <CustomSeriesModel
                      allSeries={series}
                      handleChange={handleSeriesAndModel}
                      mode={(!idValue ? "edit" : "view") || ""}
                      selectedSeriesId={
                        formData?.vehicleData?.vehicleDetails?.series?.id
                      }
                      selectedModel={
                        formData?.vehicleData?.vehicleDetails?.model
                      }
                      disabled={disabled}
                    />
                  </PGridItem>
                </>
              ) : null}

              {/** need to modfify id 7 to 8 */}
              {vehicleCategoryId > 0 &&
                vehicleCategoryId === 8 &&
                series.length > 0 && (
                  <>
                    <PGridItem size={12}>
                      <div style={{ paddingBottom: "10px" }}>
                        <PorscheFont>
                          {t("Select series")}{" "}
                          <span style={{ color: "#D5001B" }}>*</span>
                        </PorscheFont>
                      </div>
                      <div
                        id="seriesOnly"
                        className={"series-model-error-hide"}
                      >
                        <PorscheFont>
                          {t("Please select series to proceed further")}{" "}
                        </PorscheFont>
                      </div>
                      <CustomSeries
                        allSeries={series}
                        handleChange={handleSeries}
                        mode={(!idValue ? "edit" : "view") || ""}
                        selectedSeriesId={
                          formData?.vehicleData?.vehicleDetails?.series?.id
                        }
                        disabled={disabled}
                      />
                    </PGridItem>
                  </>
                )}
            </PGrid>
            <PGrid>
              {[4, 8, 9].includes(vehicleCategoryId) ? (
                <>
                  <PGridItem size={3}>
                    <CustomTextField
                      id="modelDescription"
                      type="text"
                      name="modelDescription"
                      value={
                        formData?.vehicleData?.vehicleDetails?.modelDescription
                      }
                      label={`${t("Model Description")}`}
                      onChange={(e) => handleModelDescription(e)}
                      required={true}
                      disabled={disabled}
                    />
                  </PGridItem>
                </>
              ) : null}
            </PGrid>
          </div>
          <div className="manual-commission-box">
            <PorscheFont data-testid="Customer-Seller" className="header-text">
              {t("Customer & Seller")}
            </PorscheFont>
            <PGrid className="vehicle-category-center">
              <PGridItem size={3}>
                <CustomTextField
                  id="customer"
                  type="text"
                  name="customer"
                  value={formData.customerSellerDetails.customer}
                  label={`${t("Customer")}`}
                  onChange={(e) => {
                    handleModified();
                    setFormData((prev) => ({
                      ...prev,
                      customerSellerDetails: {
                        ...prev.customerSellerDetails,
                        customer: e.target.value,
                      },
                    }));
                  }}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
              <PGridItem size={3} className="seller-select-item">
                <PSelectWrapper
                  className="seller-select"
                  label={`${t("Seller")}`}
                >
                  <select
                    id={"Seller"}
                    name="seller"
                    value={formData.customerSellerDetails.seller.id}
                    onChange={(e) => {
                      handleModified();
                      setFormData((prev) => {
                        const selectedId = parseInt(e.target.value);
                        const user = activeOriginUsers.find(
                          (user) => user.id === selectedId
                        );
                        return {
                          ...prev,
                          customerSellerDetails: {
                            ...prev.customerSellerDetails,
                            seller: {
                              id: selectedId || "",
                              ppnName: user?.ppnName || "",
                            },
                          },
                          commissionApproval: prev.commissionApproval.map(
                            (approvalUser, index) => {
                              if (approvalUser.id === user?.id) {
                                return {
                                  id: null,
                                  commissionRank: index === 0 ? "1st" : "2nd",
                                  action: "Pending",
                                  comment: "",
                                  ppnName: "",
                                };
                              } else {
                                return approvalUser;
                              }
                            }
                          ),
                        };
                      });
                    }}
                    required
                    disabled={disabled}
                    aria-selected={true}
                  >
                    <option key="default" value="default">
                      {t("Select Seller")}
                    </option>

                    {activeOriginUsers.map((user, index) => (
                      <option key={index} value={user.id}>
                        {t(user.ppnName)}
                      </option>
                    ))}
                  </select>
                </PSelectWrapper>
              </PGridItem>
            </PGrid>
          </div>
          <div className="manual-commission-box">
            <PorscheFont
              data-testid="Commission-details"
              className="header-text"
            >
              {t("Commission details")}
            </PorscheFont>
            <PGrid className="vehicle-category-center">
              <PGridItem size={7} className="discription-grid">
                <CustomTextField
                  id="CommissionDescription"
                  type="text"
                  name="CommissionDescription"
                  onChange={(e) => {
                    handleModified();
                    setFormData((prev) => ({
                      ...prev,
                      commissionDetails: {
                        ...prev.commissionDetails,
                        description: e.target.value,
                      },
                    }));
                  }}
                  value={formData.commissionDetails.description}
                  label={`${t("Commission description")}`}
                  required={true}
                  disabled={disabled}
                />
              </PGridItem>
            </PGrid>
            <PFlex className="date-row-grid">
              <PFlexItem className="common-selection-items">
                <PTextFieldWrapper label={`${t("Service date")}`}>
                  <input
                    id="serviceDate"
                    type="date"
                    max="9999-12-31"
                    name="serviceDate"
                    disabled={disabled}
                    onChange={(e) => {
                      handleModified();
                      setFormData((prev) => ({
                        ...prev,
                        commissionDetails: {
                          ...prev.commissionDetails,
                          serviceDate: e.target.value,
                        },
                      }));
                    }}
                    value={formData.commissionDetails.serviceDate}
                    required
                  />
                </PTextFieldWrapper>
              </PFlexItem>
              <PFlexItem className="common-selection-items">
                <PTextFieldWrapper label={`${t("Accounting date")}`}>
                  <input
                    type="date"
                    name="accountingDate"
                    required
                    onChange={(e) => {
                      handleModified();
                      setFormData((prev) => ({
                        ...prev,
                        commissionDetails: {
                          ...prev.commissionDetails,
                          accountingDate: e.target.value,
                        },
                      }));
                    }}
                    value={formData.commissionDetails.accountingDate}
                    disabled={disabled}
                  />
                </PTextFieldWrapper>
              </PFlexItem>
              <PFlexItem className="common-selection-items">
                <PTextFieldWrapper
                  id={"commission"}
                  label={`${t("Commission")}`}
                  hideLabel={false}
                  unit="€"
                  unitPosition="suffix"
                  showCharacterCount={false}
                >
                  <input
                    maxLength={inputFieldMaxLength}
                    id={"commission"}
                    type="text"
                    name={"commission"}
                    required
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Remove all non-numeric, non-dot, non-comma characters
                      const cleanedValue = inputValue.replace(/[^0-9.,]/g, "");

                      // Format the cleaned value according to European accounting format
                      const formattedValue = formatCurrencyWithNoUnit(
                        formatCurrencyToDecimal(cleanedValue)
                      );

                      setFormData((prev) => ({
                        ...prev,
                        commissionDetails: {
                          ...prev.commissionDetails,
                          commission: formattedValue,
                        },
                      }));
                    }}
                    onBlur={(e) => {
                      const currentValue = e.target.value;

                      // If the current value is not a valid European accounting format, correct it
                      if (!isValidEuropeanAccountingFormat(currentValue)) {
                        // Correct the format
                        e.target.value = formatCurrencyWithNoUnit(
                          formatCurrencyToDecimal(currentValue)
                        );
                      }
                    }}
                    defaultValue={
                      formData.commissionDetails.commission == "0"
                        ? ""
                        : formData.commissionDetails.commission
                    }
                    disabled={!disabled ? false : true}
                    style={{ textAlign: "left" }}
                  />
                </PTextFieldWrapper>
              </PFlexItem>
              <PFlexItem className="common-selection-items">
                <PTextFieldWrapper
                  id={"IncentiveBonus"}
                  label={`${t("Incentive / Bonus")}`}
                  hideLabel={false}
                  unit="€"
                  unitPosition="suffix"
                  showCharacterCount={false}
                >
                  <input
                    maxLength={inputFieldMaxLength} 
                    id={"IncentiveBonus"}
                    type="text"
                    name={"IncentiveBonus"}
                    required
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Remove all non-numeric, non-dot, non-comma characters
                      const cleanedValue = inputValue.replace(/[^0-9.,]/g, "");

                      // Format the cleaned value according to European accounting format
                      const formattedValue = formatCurrencyWithNoUnit(
                        formatCurrencyToDecimal(cleanedValue)
                      );

                      setFormData((prev) => ({
                        ...prev,
                        commissionDetails: {
                          ...prev.commissionDetails,
                          incentiveBonus: formattedValue,
                        },
                      }));
                    }}
                    onBlur={(e) => {
                      const currentValue = e.target.value;

                      // If the current value is not a valid European accounting format, correct it
                      if (!isValidEuropeanAccountingFormat(currentValue)) {
                        // Correct the format
                        e.target.value = formatCurrencyWithNoUnit(
                          formatCurrencyToDecimal(currentValue)
                        );
                      }
                    }}
                    defaultValue={
                      formData.commissionDetails.incentiveBonus == "0"
                        ? ""
                        : formData.commissionDetails.incentiveBonus
                    }
                    disabled={!disabled ? false : true}
                    style={{ textAlign: "left" }}
                  />
                </PTextFieldWrapper>
              </PFlexItem>
            </PFlex>
          </div>
          <div className="manual-commission-box">
            <PorscheFont
              data-testid="Commission-details"
              className="header-text commission-approval-header"
            >
              {t("Commission approval")}
            </PorscheFont>
            <PFlex className={`approval-header ${disabled ? "disabled" : ""}`}>
              <PFlexItem width="one-quarter" className="sub-headding-m steps-col">
                <PorscheFont>{t("Approval steps")}</PorscheFont>
              </PFlexItem>
              <PFlexItem
                width="one-quarter"
                className="sub-headding-m assign-col"
              >
                <PorscheFont>{t("User assigned")}</PorscheFont>
              </PFlexItem>
            </PFlex>
            <PDivider
              color="neutral-contrast-high"
              className={`approval-divider ${disabled ? "disabled" : ""}`}
            ></PDivider>
            <ManualCommissionApproval
              edit={edit}
              activeOriginUsers={filteredActiveOriginUsers}
              setFormData={(value: any) => setFormData(value)}
              formData={formData}
              handleModified={handleModified}
              isCommissionDeleted={formData?.status === "Cancel"}
              isSavedApprovalData={isSavedApprovalData}
              commissionCheck1Users={filteredCommissionCheck1Users}
              commissionCheck2Users={filteredCommissionCheck2Users}
            />
            {!(
              isSavedApprovalData[0]?.id &&
              ["Approved", "Rejected"].includes(
                isSavedApprovalData[0]?.action
              ) &&
              isSavedApprovalData[1]?.id &&
              ["Approved", "Rejected"].includes(isSavedApprovalData[1]?.action)
            ) &&
            !edit &&
            formData?.status !== "Cancel" ? (
              <div className="approval-submit-container">
                <PButton
                  variant="secondary"
                  className="submit-btn"
                  onClick={handleSubmitApprovalCommission}
                  disabled={!isValidApprovalSubmit}
                >
                  {t("Submit")}
                </PButton>
              </div>
            ) : null}
          </div>
        </div>
        <PModal open={isOpenCancelCommission} disableCloseButton>
          <div className="delete-modal-box">
            <PorscheFont className="delete-modal-header-text">
              {t("Cancel this commission?")}
            </PorscheFont>
            <PorscheFont className="delete-modal-discription-text">
              {t("This action can not be undone. Commission will be deleted.")}
            </PorscheFont>
            <PButtonGroup className="delete-modal-actions-button">
              <PButton
                className="cancel-btn"
                variant="tertiary"
                icon="close"
                onClick={() => handleCancelCommission(false)}
              >
                <PorscheFont> {t("No, keep commisison")}</PorscheFont>
              </PButton>
              <PButton
                className="submit-btn"
                variant="secondary"
                onClick={handleDeleteManualCommission}
              >
                <PorscheFont> {t("Yes, cancel commission")}</PorscheFont>
              </PButton>
            </PButtonGroup>
          </div>
        </PModal>
      </div>
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>
    </>
  );
};
