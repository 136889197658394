import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
  IconName,
  PGrid, PGridItem, PIcon, PSwitch,
  PTextFieldWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { CommissionScalingDTO } from '../../../../interfaces/commission';
import { formatCurrencyToDecimal } from '../../../../mock/helper';
import ScrollToTop from '../../../common/ScrollToTop';

interface Props {
  handleCommissionPost: (categoryCommission: CommissionScalingDTO) => void;
  commissions: CommissionScalingDTO;
  edit: boolean;
}

export const Activation = (props:Props): JSX.Element => {
  const { t } = useTranslation();
  const { commissions, handleCommissionPost, edit } = props;
  // const [showNew, setShowNew] = useState<boolean>(commissions.activation);
  const [reload, setReload] = useState("");
  const [commissionData, setCommissionData] = useState<CommissionScalingDTO>(commissions);
  // const [commissionData1, setCommissionData1] = useState<CommissionScalingDTO>(commissions);

  const handleClickNew = () => {  
    commissionData.activation =!commissionData.activation;
    // setShowNew(!showNew);
    setCommissionData(commissionData);
    handleCommissionPost(commissionData);
    setReload(new Date().toLocaleString());
  };
  
  const handleValue = (value: string, type : string)=> {

    switch(type){
      case "new": commissionData.salaryTypeNewCar = formatCurrencyToDecimal(value);
      break;
      case "demo": commissionData.salaryTypeDemoCar = formatCurrencyToDecimal(value);
      break;
      case "used":  commissionData.salaryTypeUsedCar =  formatCurrencyToDecimal(value);
      break;
      case "it":  commissionData.salaryTypeIT1415 =  formatCurrencyToDecimal(value);
      break; 
    
    }
    //setShowNew(showNew);
    setCommissionData(commissionData);
    handleCommissionPost(commissionData);
    setReload(new Date().toLocaleString());

  }
  return ( 
    <>
      <div>
        <div>
          <div className="p1">
            <ScrollToTop />
            <Stack direction="row" spacing={1}>
              <PorscheFont className="dcs-section-heading">{t('Activation')}</PorscheFont>
              <PSwitch
                alignLabel="left"
                checked={commissionData.activation}
                disabled={edit ? false : true}
                style={{ paddingTop: "8px" }}
                onClick={handleClickNew}
              >
                {" "}
              </PSwitch>{" "}
            </Stack>
            <br></br>
            <PIcon name={"information" as IconName}></PIcon>&nbsp;{t("Only activated commission scalings can be assigned to users in User Management.")}<br></br>

          </div>

          {/* salaryTypeNewCar: 13,
        salaryTypeDemoCar: 13,
        salaryTypeUsedCar: 13,
        salaryTypeIT1415: 13, */}



          <div className="p1">
            <PorscheFont className="dcs-section-heading">{t('Salary type')}</PorscheFont><br></br>
            <PGrid direction="row">
              <PGridItem size={2}>
                <PTextFieldWrapper label={`${t("New Car")}`}>
                  <input type="number" aria-autocomplete='none' autoComplete='off' name="some-name" disabled={edit ? false : true} defaultValue={commissionData.salaryTypeNewCar} onChange={(e)=>handleValue(e.target.value,"new")} required />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={2}>
                <PTextFieldWrapper label={`${t("Demo Car")}`}>
                  <input type="number" aria-autocomplete='none' autoComplete='off' name="some-name"  disabled={edit ? false : true}defaultValue={commissionData.salaryTypeDemoCar} onChange={(e)=>handleValue(e.target.value,"demo")} required />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={2}>
                <PTextFieldWrapper label={`${t("Used Car")}`}>
                  <input type="number" aria-autocomplete='none' autoComplete='off' name="some-name" disabled={edit ? false : true} defaultValue={commissionData.salaryTypeUsedCar} onChange={(e)=>handleValue(e.target.value,"used")} required />
                </PTextFieldWrapper>
              </PGridItem>
              <PGridItem size={2}>
                <PTextFieldWrapper label="IT 14/15">
                  <input type="number"
                   name="some-name"
                   aria-autocomplete='none'
                   autoComplete='off' 
                  //  disabled={edit ? false : true}
                   defaultValue={commissionData.salaryTypeIT1415} 
                   disabled={edit ? false : true}
                   onChange={(e)=>handleValue(e.target.value,"it")} required />
                </PTextFieldWrapper>
              </PGridItem>
            </PGrid><br></br>
          </div>

        </div>
      </div>
    </>
  );
}