import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Alert, Stack } from '@mui/material';
import {
    PButton, PButtonGroup
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../contexts/OrderContext';

interface Props {
    orderId: number,
    handleResubmitApproval: () => void,
    handleCancel:()=> void,
}

export const ResubmitConfirmation = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleResubmitApproval,handleCancel, orderId } = props;
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
    const [isError, setIsError] = useState(false);
    const [reload, setReload] = useState("");
    const orderContext = useContext(OrderContext);
 
    let loggedInUserId = 1;
    const sessionUser = sessionStorage.getItem("userDetails");
    if (sessionUser) {
        const user = JSON.parse(sessionUser);
        loggedInUserId = user.id;
    }

    const handleCancelButton = () => {
        handleCancel();
    }
    const handleResubmit = () => {
        handleResubmitApproval();
        // let order = orderContext.getCtxOrderDetails();
        // if (order.id) {
        //     let urlParams = `?id=${order.id}&mode=view&draft=false`
        //     navigate(`/order/create/${urlParams}`);
        // }
    }

    return (
        <>
            <div style={{ height: "146px", minWidth: "414px",margin:"-22px",paddingLeft:"10px" }}>
           
                <PorscheFont style={{fontWeight:"700",fontSize:"16px",lineHeight:"23.42px"}}>{t("Submit edited recalculation?")}</PorscheFont><br></br>
                <PorscheFont style={{fontWeight:"400",fontSize:"16px",lineHeight:"23.42px",marginTop:"-16px"}}>{t("Edited recalculation must be approved again.")}</PorscheFont><br></br>
                <PButtonGroup style={{marginTop:"12px"}}>
                    <Stack direction="row" spacing={1}>
                    <PButton style={{fontWeight:"400",fontSize:"16px",lineHeight:"23.42px"}} variant="tertiary" icon='close' onClick={handleCancelButton}>{t("Cancel")}</PButton>
                    <PButton style={{fontWeight:"400",fontSize:"16px",lineHeight:"23.42px"}} variant="secondary" onClick={handleResubmit}>{t("Understood, open approvals popup")}</PButton>
                    </Stack>
                </PButtonGroup>
            </div>
            { saveSuccessMsg && (
                <Alert severity="success" sx={{ width: "100%" }}>
                    {saveSuccessMsg}
                </Alert>)
            }

            { isError && (
                <Alert severity="error" sx={{ width: "100%" }}>
                    {t("Error while converting!")}
                </Alert>)
            }
        </>
    );
}
