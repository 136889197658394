import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Alert, Stack, Tab, Tabs } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {
  PButton, PButtonGroup, PFlex, PFlexItem,
  TabChangeEvent
} from '@porsche-design-system/components-react';

import { PMainHeader, PorscheFont } from '../../assets/styles/CustomStyles';
import { NavSideBar } from '../../components/NavSideBar';
import { Models } from '../../components/administration/variables/Models';
import { PartsAndService } from '../../components/administration/variables/PartsAndService';
import { Paw } from '../../components/administration/variables/Paw';
import { Rvm } from '../../components/administration/variables/Rvm';
import { GeneralTab } from '../../components/administration/variables/tabs/GeneralTab';
import {
  TransferPickupRegistrationTab
} from '../../components/administration/variables/tabs/TransferPickupRegistrationTab';
import ScrollToTop from '../../components/common/ScrollToTop';
import { AuthContext } from '../../contexts/AuthContext';
import { GeneralContext } from '../../contexts/GeneralContext';
import {
  TransferPickupRegistrationContext
} from '../../contexts/TransferPickupRegistrationContext';
import { IsUserAllowed } from '../../helpers/permissions';
import { Model } from '../../interfaces/Model';
import { GetPaw, GetRvm } from '../../interfaces/Variables';
import { WorkshopAndServiceCostsDTO } from '../../interfaces/WorkService';
import { HistoryLogTypes } from '../../interfaces/changeHistory';
import { type } from '../../services/Constants';
import { variablesError } from '../../services/General';
import {
  dcsUpdateGeneralData,
  dcspostChangeHistoryLogs,
  dcsputModels,
  dcsputPaw,
  dcsputRvm,
  dcsputServices,
  dcsputTransferPickupRegistrationData,
  postChangeHistoryLogs
} from '../../services/data/Helpers';
import CustomSnakebar from '../../components/common/CustomSnakebar';
import { Constant } from '../../configs/Constants';


interface HeaderTabPanels {
  id: number;
  name: string;
  enabled: boolean;
  className: string;
}
export const VariablesPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [ edit, setEdit ] = useState(false);
  const [ save, setSave ] = useState(false);
  const [ cancel, setCancel ] = useState(false);
  const [ saveSuccess, setSaveSuccess ] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [reload, setReload] = useState("");
  const [ pawPost, setPawPost ] = useState<Array<GetPaw>>([]);
  const [ rvmPost, setRvmPost ] = useState<Array<GetRvm>>([]);
  const [ workshopServices, setWorkshopServices ] = useState<Array<WorkshopAndServiceCostsDTO>>([]);
  const [ modelPost, setModelPost ] = useState<Array<Model>>([]);
  const [ varriableError, setVariableError ] = useState<variablesError>({} as variablesError);
  const [ isModified, setIsModified ] = useState(false);
  const [ showSaveButton, setShowSaveButton ] = useState<boolean>(false);
  const authCtx = useContext(AuthContext);
  const [isAllowed, setIsAllowed] = useState(false);
  const [tabPanels, setTabPanels] = useState<Array<HeaderTabPanels>>([]);
  const [panelIndex, setPanelIndex] = useState<number>(1);
  const [modeChange, setChangeMode] = useState("");
  const [ searchParams, setSearchParams] = useSearchParams();
  const [showHeader, setShowHeader] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState(Constant.Savedsuccessfully)

  const currPageName = "Variables";
  const sessionUser = sessionStorage.getItem("userDetails");
  let userId ="" 
  if(sessionUser) {
    userId = JSON.parse(sessionUser).id;
  }
   
  const logData: HistoryLogTypes = {
    page: currPageName,
    tab: tabPanels[panelIndex - 1]?.name,
    userId: userId,
  };

  const messageData: boolean = JSON.parse(sessionStorage.getItem("messageFlag") || "false") || (false);

  const generalContext = useContext(GeneralContext)
  const transferPickupRegistrationContext = useContext(TransferPickupRegistrationContext)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanelIndex(newValue);
};
  useEffect(() => {
    setVariableError(generalContext.getErrors());
  }, [varriableError]);
  useEffect(() => {
    const mode = searchParams.get("mode") || "";
    setChangeMode(mode);
  }, [])
  useEffect(() => {
  }, [edit]);
  

  const onTabChange = (e: CustomEvent<TabChangeEvent>) => {
    setTabIndex(e.detail.activeTabIndex);
  };
  const handleShowHeader =()=>{
    setShowHeader(true);
};
  const handleRemoveHeader =()=>{
    setShowHeader(false);
};
  const onNextTabChange = (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const handleEdit = () => {
    setEdit(true);
    sessionStorage.setItem("changeFlag", "true");

  };

  const handleSave = () => {
    sessionStorage.setItem("changeFlag",("false" || false));
    switch(panelIndex) {
      case 1:
        setSave(true);
        const updateGeneral = async () => {
          const apiResponse = await dcsUpdateGeneralData(generalContext.getGeneralctx());
          if(apiResponse.status === type.SUCCESS) {
            setShowAlert(Constant.Success)
            setAlertMessage(Constant.Savedsuccessfully)
            window.location.reload();
            setSaveSuccess(true);
            sessionStorage.setItem("messageFlag",("true" || true));
            setReload(new Date().toString())
            await dcspostChangeHistoryLogs(logData, 'admin')
          }
          if (apiResponse.status === type.ERROR) {
            setShowAlert(Constant.Error)
            setAlertMessage(apiResponse.response)
            }
        };

        updateGeneral();
        setEdit(false);
        break;
      case 2:
        try {
          const init = async() => {
            const apiResponse = await dcsputServices(workshopServices);
            if(apiResponse.status === type.SUCCESS) {
              setShowAlert(Constant.Success)
              setAlertMessage(Constant.Savedsuccessfully)
              setSaveSuccess(true);
              sessionStorage.setItem("messageFlag",("true" || true));
              await dcspostChangeHistoryLogs(logData, "admin")
            }          
            if (apiResponse.status === type.ERROR) {
              setShowAlert(Constant.Error)
              setAlertMessage(apiResponse.response)
              }
          }

          init();
        } catch (e) {
        }

        setEdit(false);
        break;
      case 3:
        try {
          const init = async() => {
            const apiResponse =  await dcsputModels(modelPost)
           if(apiResponse.status ===type.SUCCESS){
            setShowAlert(Constant.Success)
            setAlertMessage(Constant.Savedsuccessfully)
            setSaveSuccess(true);
            await dcspostChangeHistoryLogs(logData, "admin")
           }
           if (apiResponse?.status === type.ERROR) {
            setShowAlert(Constant.Error)
            setAlertMessage(apiResponse.response)
            window.location.reload()
          }
          }
          init();
        } catch (e) {
        }
        setEdit(false);
        break;
      case 4:
        try {
          const init = async() => {
              const apiResponse  = await dcsputRvm(rvmPost)
              if (apiResponse?.status === type.SUCCESS) {
                setShowAlert(Constant.Success)    
                setAlertMessage(Constant.Savedsuccessfully)

                setSaveSuccess(true);
                await dcspostChangeHistoryLogs(logData,"admin")
            }
            if (apiResponse?.status === type.ERROR) {
              setShowAlert(Constant.Error)
              setAlertMessage(apiResponse.response)
            }
          }
          init();
        } catch (e) {
        }
        setEdit(false);
        break;
      case 5:

        const putTransferPickupRegistration = async () => {
         const apiResponse =  await dcsputTransferPickupRegistrationData(transferPickupRegistrationContext.getTransferPickupRegistrationctx())
          if(apiResponse.status === type.SUCCESS) {
            setSaveSuccess(true);
            setShowAlert(Constant.Success)    
            setAlertMessage(Constant.Savedsuccessfully)
            await dcspostChangeHistoryLogs(logData,"admin")
          }
          if (apiResponse?.status === type.ERROR) {
            setShowAlert(Constant.Error)
            setAlertMessage(apiResponse.response)          }
        };

        putTransferPickupRegistration();
        setEdit(false);
        break;
      case 6:
        try {
          const init = async() => {
            const apiResponse = await dcsputPaw(pawPost);
            if(apiResponse.status === type.SUCCESS) {
              setShowAlert(Constant.Success)    
              setAlertMessage(Constant.Savedsuccessfully)
              setSaveSuccess(true);
              await dcspostChangeHistoryLogs(logData,'admin')
            }
            if (apiResponse?.status === type.ERROR) {
              setShowAlert(Constant.Error)
              setAlertMessage(apiResponse.response) 
            }
          }
          init();
        } catch (e) {
        }
        setEdit(false);
        break;
    }
  }


  const handleCancel = () => {
    sessionStorage.setItem("changeFlag",("false" || false));
    switch(panelIndex) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      case 6:

        break;
      default:
        setShowSaveButton(false);
    }

    setEdit(false);
    setCancel(true);
    window.location.reload();
  };

  const onEditMode = (enableSave: boolean) => {
    setShowSaveButton(enableSave);
  }

  const handleClose = () => {
    setShowAlert(Constant.None)
    setAlertMessage("")
    setSaveSuccess(false);
    setReload(new Date().toString());    
    sessionStorage.setItem("messageFlag", ("false" || false));
  };

  const handlePaw = (paw: Array<GetPaw>) => {
    setPawPost(paw);
    setIsModified(true);
  }

  const handleRvm = (rvm: Array<GetRvm>) => {
    setRvmPost(rvm);
    setIsModified(true);
  }

  const handleWorkshop = (ser: Array<WorkshopAndServiceCostsDTO>) => {
    setWorkshopServices(ser);
    setIsModified(true);
  }

  const handleModels = (models: Array<Model>) => {
    setModelPost(models);
    setIsModified(true);
  }

  const handleIsModified = () => {
    setIsModified(true);
    setShowSaveButton(true);
    // sessionStorage.setItem("changeFlag",("true" || true));
  }

  useEffect(() => {}, [pawPost, workshopServices])

  const enableSave = (): boolean => {
    let isEnable = false;
    switch (panelIndex) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      case 6:
        if (isModified) {
          isEnable = varriableError.paw.length > 0 ? false : true;
        } else {
          isEnable = false;
        }
        break;

    }
    return isEnable;
  }
  useEffect(() => {
    var panels = [];
    panels.push({
        id: 1, name: "General", enabled: true, className: ""
    });
    panels.push({
        id: 2, name: "Parts & Services", enabled: true, className: ""
    });
    panels.push({
        id: 3, name: "Models", enabled: true, className: ""
    });
    panels.push({
        id: 4, name: "RVM", enabled: true, className: ""
    });
    panels.push({
        id: 5, name: "Transfers & Registration", enabled: true, className: ""
    });
    panels.push({
        id: 6, name: "PAW", enabled: true, className: ""
  });
    setTabPanels(panels);
}, []);
const setEnableTabs = (event: React.SyntheticEvent, tabCount: number) => {
  setPanelIndex(tabCount);
  tabPanels.map((ele, i) => {
      if (tabCount == ele.id) {
          ele.enabled = true;
      }
  });
};



  return (
    <>
      <div className="custombody" style={{ marginBottom: "-40px" }}>
        <div
          className="navbar"
          style={{ position: "fixed", top: "85px", zIndex: "9999" }}
        >
          <NavSideBar
            disabled={!showSaveButton}
            variableSave={handleSave}
            main="Administration"
            subPage="Variables"
          />
        </div>
        <div>
          <div
            className="custombody"
            style={{
              width: "100%",
              backgroundColor: "#F2F2F2",
              padding: "100px 0px 10px 71px",
            }}
          >
            <div
              className="admin-header"
              style={{
                position: "fixed",
                marginTop: "-22px",
                padding: "40px 40px 16px 40px",
                marginLeft: "-10px",
                backgroundColor: "#F2F2F2",
                zIndex: "999",
              }}
            >
              <div className="main-header-left">
                <PMainHeader>
                  <PorscheFont
                    data-testid="page-variables"
                    className="main-header-text"
                  >
                    {t("Variables")}
                  </PorscheFont>
                  <PorscheFont className="main-header-description">
                    {t(
                      "Define the values for all relevant variables and parameters of vehicle orders."
                    )}
                  </PorscheFont>
                </PMainHeader>
                <PFlex>
                  <PFlexItem width="three-quarters">
                    {
                      <Tabs
                        textColor="primary"
                        indicatorColor="secondary"
                        onChange={handleChange}
                        value={panelIndex}
                        sx={{
                          minHeight: "37px!important",
                          "& .MuiTabs-indicator": {
                            backgroundColor: "#D5001B",
                            height: 2,
                          },
                          "& .MuiTab-root.Mui-selected": {
                            color: "#313639",
                            textTransform: "inherit",
                            fontFamily: "Porsche Next",
                            paddingBottom: "5px",
                          },
                          "& .MuiTab-root": {
                            color: "#313639",
                          },
                        }}
                      >
                        {tabPanels.map((tabPanel, i) => {
                          return (
                            <Tab
                              //className={classes.tab}
                              disabled={!tabPanel.enabled}
                              label={t(tabPanel.name)}
                              value={tabPanel.id}
                              sx={{
                                borderLeft: "1px solid #C8CACB ",
                                height: "21px",
                                padding: "0px 24px !important",
                                minHeight: "16px !important",
                                textTransform: "inherit",
                                fontFamily: "Porsche Next",
                                fontSize: "16px",
                                marginBottom: "-30px",
                                marginTop: "8px",
                              }}
                            />
                          );
                        })}
                      </Tabs>
                    }
                  </PFlexItem>
                  <PFlexItem
                    style={
                      window.innerHeight > 1000
                        ? { paddingLeft: "250px", marginTop: "-45px" }
                        : { paddingLeft: "125px", marginTop: "-45px" }
                    }
                  >
                    {!edit && (
                      <PButton
                        icon="edit"
                        onClick={handleEdit}
                        disabled={
                          IsUserAllowed("adminVariables", authCtx)
                            ? false
                            : true
                        }
                      >
                        {t("Edit entries")}
                      </PButton>
                    )}
                    {edit && (
                      <PButtonGroup>
                        <Stack
                          direction="row"
                          style={
                            window.innerHeight > 1000
                              ? { paddingRight: "80px" }
                              : { paddingRight: "60px" }
                          }
                        >
                          <PButton
                            variant="tertiary"
                            icon="close"
                            onClick={handleCancel}
                          >
                            {t("Cancel")}
                          </PButton>
                          &ensp;
                          <PButton
                            icon="save"
                            onClick={handleSave}
                            disabled={!showSaveButton}
                          >
                            {t("Save")}
                          </PButton>
                        </Stack>
                      </PButtonGroup>
                    )}
                  </PFlexItem>
                </PFlex>
              </div>
            </div>
            <div
              style={{
                padding: "124px 125px 52px 32px",
                minHeight: "100vh",
                width: "100vw",
              }}
            >
              <ScrollToTop />
              {/* <Stack direction="column">   */}
              {panelIndex === 1 && (
                <GeneralTab
                  edit={edit}
                  modified={handleIsModified}
                  setShowSaveButton={setShowSaveButton}
                  enableSaveButton={onEditMode}
                  changedMode={modeChange}
                />
              )}

              {panelIndex === 2 && (
                <PartsAndService
                  edit={edit}
                  handleWorkshop={handleWorkshop}
                  modified={handleIsModified}
                  changedMode={modeChange}
                  setShowSaveButton={setShowSaveButton}
                />
              )}

              {panelIndex === 3 && (
                <Models
                  edit={edit}
                  handleModels={handleModels}
                  modified={handleIsModified}
                  changedMode={modeChange}
                  setShowSaveButton={setShowSaveButton}
                />
              )}

              {panelIndex === 4 && (
                <Rvm
                  edit={edit}
                  save={save}
                  cancel={cancel}
                  handleRvmPost={handleRvm}
                  modified={handleIsModified}
                  changedMode={modeChange}
                  showHeader={handleShowHeader}
                  removeHeader={handleRemoveHeader}
                  setShowSaveButton={setShowSaveButton}
                />
              )}

              {panelIndex === 5 && (
                <TransferPickupRegistrationTab
                  edit={edit}
                  modified={handleIsModified}
                  changedMode={modeChange}
                  showHeader={handleShowHeader}
                  removeHeader={handleRemoveHeader}
                  setShowSaveButton={setShowSaveButton}
                />
              )}

              {panelIndex === 6 && (
                <Paw
                  edit={edit}
                  save={save}
                  cancel={cancel}
                  handlePawPost={handlePaw}
                  modified={handleIsModified}
                  changedMode={modeChange}
                  showHeader={handleShowHeader}
                  removeHeader={handleRemoveHeader}
                  setShowSaveButton={setShowSaveButton}
                />
              )}
              {/* </Stack> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex30 pt20">
        <Snackbar
          open={saveSuccess}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            {t("saved successfully")}!
          </Alert>
        </Snackbar>
        <Snackbar
          open={isError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {t("Please enter valid values.")}
          </Alert>
        </Snackbar>
      </div>
      <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

    </>
  );
};
