import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabChangeEvent } from '@porsche-design-system/components-react';

import { OrderHeader } from '../../components/order/OrderHeader';
import { CalculationTab } from '../../components/order/tabs/calculation/CalculationTab';
import { OrderDetailsTab } from '../../components/order/tabs/details/OrderDetailsTab';
import { SummaryTab } from '../../components/order/tabs/summary/SummaryTab';
import { UpsaleTab } from '../../components/order/tabs/upsale/UpsaleTab';
import { OrderContext } from '../../contexts/OrderContext';
import { CalculationDraftDTO } from '../../interfaces/Calculation';
import { OrderDTO } from '../../interfaces/Order';
import { UpsaleError } from '../../interfaces/Upsale';
import { UpsaleDetailsDTO } from '../../interfaces/UpsaleNew';
import { type } from '../../services/Constants';
import { validateUpsale } from '../../services/Validate';
import {
  ApiReturnType,
  dcsgetOrderDetails,
  dcsgetOrderSummary,
  getLogedInUser
} from '../../services/data/Helpers';
import CustomSnakebar from '../../components/common/CustomSnakebar';
import { Constant } from '../../configs/Constants';
import getSessionStorage from '../../helpers/getSessionStorage';
import { SingleOrderDetailType } from '../../interfaces/OrderDetails';
import draftValueHandler from '../../helpers/sessionDraftValueHandler';

interface HeaderTabPanels {
  id: number;
  name: string;
  enabled: boolean;
  className: string;
}

export const CreateOrderPage = (): JSX.Element => {
  const { t } = useTranslation();
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);
  const [ tabIndex, setTabIndex ] = useState<number>(1);
  const [ upsaleDetails, setUpsaleDetails ] = useState<UpsaleDetailsDTO>({} as UpsaleDetailsDTO);
  const [calDraft, setCalDraft] = useState<CalculationDraftDTO>({} as CalculationDraftDTO)
  const [ errors, setErrors ] = useState<Array<UpsaleError>>([])
  const [ reload, setReload ] = useState("");
  const [ isValidToSaveAsDraft, setValidToSaveAsDraft ] = useState<boolean>(false);
  const [ isOrderSavedAsDraft, setOrderSavedAsDraft ] = useState<boolean | undefined>(undefined);
  const orderContext = useContext(OrderContext);
  const [ searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [modeChange, setChangeMode] = useState(orderValues?.mode? orderValues.mode: "");
  const [orderDetails, setOrderDetails] = useState<OrderDTO>({} as OrderDTO);
  const [modified, setModified] = useState(false);
  const [cdd, setCdd] =useState<CalculationDraftDTO>({} as CalculationDraftDTO);
  const [showAlert, setShowAlert] = useState(Constant.None)
  const [alertMessage, setAlertMessage] = useState("")

  // const [orderDto, setOrderDto] = useState<OrderDTO>({} as OrderDTO);
  
  // Tabs Start
  const [selectedTab, setSelectedTab] = useState(1);
  var tabCount = 1;

  const [tabPanels, setTabPanels] = useState<Array<HeaderTabPanels>>([]);
  const [panelIndex, setPanelIndex] = useState<number>(1);
  // const [panels, setPanels] = useState<Array<panels>>([])
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPanelIndex(newValue);
    // setSelectedTab(newValue);
  };
 
    
  const onTabChange = (e: CustomEvent<TabChangeEvent>) => {
    setTabIndex(e.detail.activeTabIndex);
  };

  const onNextTabChange = (tabCount: number) => {
    setTabIndex(tabCount);
    setPanelIndex(tabCount);
    tabPanels.map((ele, i) => {
      if (tabCount == ele.id) {
          ele.enabled = true;
          // tabPanel.className = ""
      }
    });
  };

  const onUpsaleNewChange = () => {
    const updatedState = [...tabPanels];
    updatedState[2] = {
      "id": 3,
      "name": "3. Calculation",
      "enabled": false,
      "className": "disabled"
    };
    updatedState[3] = {
      "id": 4,
    "name": "4. Summary",
    "enabled": false,
    "className": "disabled"
    }
    setTabPanels(updatedState);  
  };

  useEffect(() => {
    const init = async () => {
      const tab = orderValues?.tab ? orderValues?.tab+'' : '';
      if(tab === "calculation") {
        setPanelIndex(3)
      }
     
     const isDraftVersion = draftValueHandler(orderValues?.draft)
      const id = orderValues?.id ? orderValues?.id+'' :''
      let orderData: ApiReturnType;
      let datas: any;
      let orderDetails: OrderDTO = {} as OrderDTO;
      let upsaleDetails: UpsaleDetailsDTO;
      let calculationDetails: CalculationDraftDTO;
      let allowUpsale = false;
      let allowCalculation = false;
      if(id && isDraftVersion !== null ) {
        orderData = (isDraftVersion === "false" ||  orderValues?.inReviewRejected==="Yes")
                    ? await dcsgetOrderSummary(parseInt(id))
                    : await dcsgetOrderDetails(true, parseInt(id));        
        // orderData = await dcsgetOrderSummary(parseInt(id))    
        if (orderData.status === type.SUCCESS) {
          orderDetails = orderData.response?.order as OrderDTO;
          upsaleDetails = orderData.response?.upsale as UpsaleDetailsDTO;
          if (upsaleDetails?.durationInMonths > 0 && !allowUpsale) {
            allowUpsale = true;
          }
          if (upsaleDetails && upsaleDetails.upsaleItems && upsaleDetails.upsaleItems.length > 0 && !allowUpsale) {
            allowUpsale = true;
          }
          calculationDetails = orderData.response?.calculation as CalculationDraftDTO;
          if (calculationDetails?.calculationType !== undefined && calculationDetails?.calculationType.length > 0 && !allowCalculation) {
            allowCalculation = true;
          }
          // Enable upsale if the calulation is enabe, regardless of PAW,CWT, PDS is selected or not
          if (allowCalculation) {
            allowUpsale = true;
          }
        }
        
        
        // datas = orderContext.getCtxCalculationDraftDetails(orderDetails?.vehicleDetails?.category?.type)
      }
      
      var panels = [];
      if (isDraftVersion === null) {
        panels.push({
          id: 1, name: "1. Order Details", enabled: true, className: ""
        });
        panels.push({
          id: 2, name: "2. Upsale", enabled: false, className: "disabled"
        });
        panels.push({
          id: 3, name: "3. Calculation", enabled: false, className: "disabled"
        });
        // panels.push({
        //   id: 4, name: "4. ICS Checklist", enabled: false, className: "disabled"
        // });
        panels.push({
          id: 4, name: "4. Summary", enabled: false, className: "disabled"
        });
      } else if(isDraftVersion === "false" || (orderDetails?.vehicleDetails?.category?.type !== undefined)) {
        // panels.push({
        //   id: 1, name: "1. Order Details", enabled: true, className: ""
        // });
        // panels.push({
        //   id: 2, name: "2. Upsale", enabled: (isDraftVersion === 'false') ? true : (datas !== undefined && isDraftVersion === "true") ? true : false, className: "disabled"
        // });
        // panels.push({
        //   id: 3, name: "3. Calculation", enabled: (isDraftVersion === 'false') ? true : (datas !== undefined) && isDraftVersion === "true" ? true : false, className: "disabled"
        // });
        // panels.push({
        //   id: 4, name: "4. ICS Checklist", enabled: (isDraftVersion === 'false') ? true : (datas !== undefined && isDraftVersion === "true") ? false : true, className: "disabled"
        // });
        // panels.push({
        //   id: 5, name: "5. Summary", enabled: (isDraftVersion === 'false') ? true : (datas !== undefined && isDraftVersion === "true") ? false : true, className: "disabled"
        // });
        panels.push({
          id: 1, name: "1. Order Details", enabled: true, className: ""
        });
        panels.push({
          id: 2, name: "2. Upsale", enabled: allowUpsale ? true : false, className: "disabled"
        });
        panels.push({
          id: 3, name: "3. Calculation", enabled: allowCalculation ? true : false, className: "disabled"
        });
        // panels.push({
        //   id: 4, name: "4. ICS Checklist", enabled: allowCalculation && isDraftVersion === "false" ? true : false, className: "disabled"
        // });
        panels.push({
          id: 4, name: "4. Summary", enabled: allowCalculation && isDraftVersion === "false" ? true : false, className: "disabled"
        });
      }
    setTabPanels(panels);
    }
    init();
  }, []);



  const updateDetails = (usDetails: UpsaleDetailsDTO) => {
    const errors = validateUpsale(usDetails);
    setErrors(errors);
    setUpsaleDetails(usDetails);

    setReload(new Date().toLocaleString());
  }

  const handleSaveUpsale = async(): Promise<number> => {
    let saveOrderId = 0;
    // Call api here;
    let orderDetails = orderContext.getCtxOrderDetails()
    let saveAsDraft = orderContext.getCtxSaveDraftOrderDetails()
    
    const id = orderValues?.id ? orderValues?.id+'' :null

    if (id && parseInt(id) > 0) {
      
     const isDraftVersion=  draftValueHandler(orderValues?.draft)
      const apiResponse = await dcsgetOrderDetails(isDraftVersion === "true" ? true : false , parseInt(id))
      if(apiResponse.status===type.SUCCESS){
        saveAsDraft.id = parseInt(id);
        saveOrderId = saveAsDraft.id;
      } else {
        throw new Error("Not a valid order");
      }
    } else {
      orderDetails.createdOn = new Date().toISOString();
      orderDetails.orderCreator=getLogedInUser();
      //saveAsDraft.porscheCenter=getLogedInOrigin();
      orderDetails.status = "Draft";
      if(orderDetails.porscheCenter) {
        saveAsDraft.porscheCenter=orderDetails.porscheCenter;
      }
      saveAsDraft.orderCreator=getLogedInUser();
    }
    orderDetails.updatedOn = new Date().toISOString()

    saveAsDraft.order = orderDetails
    saveAsDraft.category = orderDetails.vehicleDetails.category.type
    //saveAsDraft.upsale = orderContext.getCtxUpsaleDetails()
    const upsaleDetails = orderContext.getCtxUpsaleDetails();
    if(upsaleDetails.includeAssistance === undefined && 
      upsaleDetails.durationInMonths === undefined && 
      upsaleDetails.upsaleItems === undefined
      ) {
        saveAsDraft.upsale = null;
    } else {
      saveAsDraft.upsale = upsaleDetails;
    }
    let calData = orderContext.getCtxCalculationDraftDetails(orderDetails.vehicleDetails.category.type);
    if(calData.calculationType && calData.calculationType.length > 0) {
      saveAsDraft.calculation = calData;
    } else {
      saveAsDraft.calculation = null;
    }
    
    const apiResponse = await orderContext.saveAsDraftOrderDetails(saveAsDraft)
    if(apiResponse.status === type.SUCCESS) {
      saveAsDraft = apiResponse.response;
      saveOrderId = saveAsDraft.id;
      orderContext.updateCtxSaveDraftOrderDetails(saveAsDraft);
      setOrderSavedAsDraft(true);
      setShowAlert(Constant.Success);
      setAlertMessage(Constant.Savedsuccessfully);
      setReload(new Date().toLocaleString());
    }
    if(apiResponse.status===type.ERROR){
      setShowAlert(Constant.Error);
      setAlertMessage(apiResponse.response);
      setOrderSavedAsDraft(false)
    }
    
    setModified(false);
    return saveOrderId;
  }

  const handleSaveOrder = async():Promise<number> => {
    const id = orderValues?.id ? orderValues?.id+'' :null
    const isDraftVersion = draftValueHandler(orderValues?.draft)
    let orderDetails = orderContext.getCtxOrderDetails();
    let saveAsDraft = orderContext.getCtxSaveDraftOrderDetails();
    let saveOrderId = 0;
    if (!orderDetails.status) orderDetails.status = "Draft"
    if (id && parseInt(id) > 0) {
      const apiResponse =  await dcsgetOrderDetails(isDraftVersion === "true" ? true : false , parseInt(id))
      if(apiResponse.status===type.SUCCESS){
        saveAsDraft.id = parseInt(id);
        saveOrderId = saveAsDraft.id;
      }else {
        throw new Error("Not a valid order");
      }
    } else {
      orderDetails.createdOn = new Date().toISOString() 
      orderDetails.orderCreator=getLogedInUser();
      orderDetails.status = "Draft";
      //saveAsDraft.order.status = "Draft";
      if(orderDetails.porscheCenter) {
        saveAsDraft.porscheCenter=orderDetails.porscheCenter;
      }
      saveAsDraft.orderCreator=getLogedInUser();
    }
    
    orderDetails.updatedOn = new Date().toISOString()
    saveAsDraft.order = orderDetails
    saveAsDraft.category = orderDetails.vehicleDetails.category.type
    const upsaleDetails = orderContext.getCtxUpsaleDetails();
    if(upsaleDetails.includeAssistance === undefined && 
      upsaleDetails.durationInMonths === undefined && 
      upsaleDetails.upsaleItems === undefined
      ) {
        saveAsDraft.upsale = null;
    } else {
      saveAsDraft.upsale = upsaleDetails;
    }
    let calData = orderContext.getCtxCalculationDraftDetails(orderDetails.vehicleDetails.category.type);
    if(calData.calculationType && calData.calculationType.length > 0) {
      saveAsDraft.calculation = calData;
    } else {
      saveAsDraft.calculation = null;
    }
    orderContext.updateCtxSaveDraftOrderDetails(saveAsDraft);
    
    const apiResponse = await orderContext.saveAsDraftOrderDetails(saveAsDraft)
    if(apiResponse.status === type.SUCCESS) {
      saveAsDraft = apiResponse.response
      saveOrderId = saveAsDraft.id;
      orderContext.updateCtxSaveDraftOrderDetails(saveAsDraft)
      setOrderSavedAsDraft(true)
      setReload(new Date().toLocaleString());
      setShowAlert(Constant.Success);
      setAlertMessage(Constant.Savedsuccessfully);
    } else {
      setShowAlert(Constant.Error);
      setAlertMessage(apiResponse.response);
      throw new Error("Not a valid order");
    }

    setModified(false);
    return saveOrderId;
  }

  const handleSaveCalculation = async(): Promise<number> => {
    let saveOrderId = 0;
    // Call api here;
    let orderDetails = orderContext.getCtxOrderDetails()
    let saveAsDraft = orderContext.getCtxSaveDraftOrderDetails()
    
    const id = orderValues?.id ? orderValues?.id+'' :null

    if (id && parseInt(id) > 0) {
      const isDraftVersion = draftValueHandler(orderValues?.draft)
      const apiResponse = await dcsgetOrderDetails(isDraftVersion === "true" ? true : false , parseInt(id))
      if(apiResponse.status===type.SUCCESS){
        saveAsDraft.id = parseInt(id);
        saveOrderId = saveAsDraft.id;
      }else {
        throw new Error("Not a valid order");
      }
    } else {
      orderDetails.createdOn = new Date().toISOString()
      orderDetails.orderCreator=getLogedInUser();
      //saveAsDraft.porscheCenter=getLogedInOrigin();
      orderDetails.status = "Draft";
      if(orderDetails.porscheCenter) {
        saveAsDraft.porscheCenter=orderDetails.porscheCenter;
      }
      saveAsDraft.orderCreator=getLogedInUser();
    }
    orderDetails.updatedOn = new Date().toISOString()

    saveAsDraft.order = orderDetails
    saveAsDraft.category = orderDetails.vehicleDetails.category.type
    const upsaleDetails = orderContext.getCtxUpsaleDetails();
    if(upsaleDetails.includeAssistance === undefined && 
      upsaleDetails.durationInMonths === undefined && 
      upsaleDetails.upsaleItems === undefined
      ) {
        saveAsDraft.upsale = null;
    } else {
      saveAsDraft.upsale = upsaleDetails;
    }
    
    saveAsDraft.calculation = orderContext.getCtxCalculationDraftDetails(orderDetails.vehicleDetails.category.type);


    const apiResponse = await orderContext.saveAsDraftOrderDetails(saveAsDraft)
    if(apiResponse.status === type.SUCCESS) {
      saveAsDraft = apiResponse.response;
      saveOrderId = saveAsDraft.id;
      orderContext.updateCtxSaveDraftOrderDetails(saveAsDraft);
      setOrderSavedAsDraft(true);
      setShowAlert(Constant.Success);
      setAlertMessage(Constant.Savedsuccessfully);
      setReload(new Date().toLocaleString());
    } 
    if(apiResponse.status===type.ERROR) {
      setOrderSavedAsDraft(false)
      setShowAlert(Constant.Error);
      setAlertMessage(apiResponse.response);
      throw new Error("Not a valid order");
    }

    setModified(false);
    return saveOrderId;
  }

  const handleMinMandatoryFields = (isValid: boolean) => {
   const orderDto = orderContext.getCtxOrderDetails();
   const porscheCenterData = orderDto.porscheCenter;
   
   const vehicleDetails = orderDto?.vehicleDetails?.category ? orderDto?.vehicleDetails?.category : null
    if(porscheCenterData && vehicleDetails)
    setValidToSaveAsDraft(isValid)
  }

  const changeMode = (id: number, mode: string) => {
    setModified(false);
    setChangeMode(mode);
    setReload(new Date().toString());
    // let urlParams = `?id=${id}&mode=${mode}&draft=${orderValues?.draft}`;
    const draft =draftValueHandler(orderValues?.draft)
    if(draft){
    let orderDetails:SingleOrderDetailType = { id: id, mode:mode, draft: !!draft, inReviewRejected: orderValues?.inReviewRejected ? orderValues?.inReviewRejected:"No"  };
    const newWindow=  window.open(`/#/order/create`, "_self") 
    newWindow?.sessionStorage.setItem(Constant.SingleOrdDetail, JSON.stringify(orderDetails));
    window.location.reload()
  }
  }

  const handleModified = () => {
    setModified(true);
    //const order = orderContext.getCtxOrderDetails();
    // if(order?.vehicleDetails?.category?.type) {
    //   const cdd = orderContext.getCtxCalculationDraftDetails(order?.vehicleDetails?.category?.type)
    //   setCdd(cdd);
    // }
    setReload(new Date().toString());
    onUpsaleNewChange()
  }

  useEffect(() => {
    let ord = orderContext.getCtxOrderDetails();
    if(ord?.vehicleDetails?.category?.type) {
      let cddCtx = orderContext.getCtxCalculationDraftDetails(ord?.vehicleDetails?.category?.type);
      setCdd(cddCtx);
    }
  }, [reload])
  
  const setEnableTabs = (event: React.SyntheticEvent, tabCount: number) => {
      setPanelIndex(tabCount);
      tabPanels.map((ele, i) => {
          if (tabCount == ele.id) {
              ele.enabled = true;
              // tabPanel.className = ""
          }
      });
  };
  const refreshHeader = (cdd: CalculationDraftDTO) => {
    setCdd(cdd);
    setReload(new Date().toLocaleDateString());
  }

  const handleClose = ( ) => {
    setShowAlert(Constant.None)
    setAlertMessage("")
  };

  return (
    <>
      <OrderHeader
        handleTab={(e) => onTabChange(e)}
        errors={errors}
        handleSaveUpsale={handleSaveUpsale}
        handleSaveOrder={handleSaveOrder}
        handleSaveCalculation={handleSaveCalculation}
        handleChangeMode={(id, mode) => changeMode(id, mode)}
        saveAsDraft={isValidToSaveAsDraft}
        onNextTabIndex={tabIndex}
        modified={modified}
        cdd={cdd}
        pIndex={panelIndex}
        handleModified={handleModified}
        reload={reload}
        children={ <Tabs 
          textColor="primary"
          indicatorColor="secondary"
          onChange={handleChange}
          value={panelIndex}          

          sx={{
            minHeight: '40px!important',
              "& .MuiTabs-indicator": {
                  backgroundColor: "#D5001B",
                  height: 2,
              },
              "& .MuiTab-root.Mui-selected": {
                  color: "#313639",
                  textTransform:"inherit",
                  fontFamily:"Porsche Next",
                  paddingBottom:"5px"
                },
                "& .MuiTab-root":{
                  color: "#313639"
                }
          }}
          
          // aria-label="lab API tabs example"
        >
         {tabPanels.map((tabPanel, i) => {
              return (
                  <Tab
                      //className={classes.tab}
                      disabled={!tabPanel?.enabled}
                      label={t(tabPanel?.name)}              
                      value={tabPanel?.id}
                      sx={{ 
                        borderLeft: "1px solid #C8CACB ",
                        height: "21px",
                        padding: "0px 24px !important",
                        minHeight: '16px !important',
                        textTransform:"inherit",
                        fontFamily:"Porsche Next",
                        fontSize:"16px",
                        marginBottom:"-30px",
                        marginTop: "8px"
                      }}
                  />
              ); 
          })}
        </Tabs>}      
      /><br></br>


      <div className="order-content">
        
        { panelIndex === 1 && ( <OrderDetailsTab onNextTabChange={onNextTabChange} handleMinMandatoryFields={handleMinMandatoryFields} isOrderSavedAsDraft={isOrderSavedAsDraft} changedMode={modeChange} handleModified={handleModified} /> )}

        { panelIndex === 2 && ( <UpsaleTab onNextTabChange={onNextTabChange} onUpsaleNewChange={onUpsaleNewChange} changedMode={modeChange} handleModified={handleModified}/> )}

        { panelIndex === 3 && ( <CalculationTab onNextTabChange={onNextTabChange}  changedMode={modeChange} refreshHeader={(cdd) => refreshHeader(cdd)} handleModified={handleModified} setStateCalDraft={setCalDraft}/> )}
  
        {/* { panelIndex === 2 && (<></>) } */}
        {/* { panelIndex === 3 && (<></>) } */}
        {/* { panelIndex === 4 && ( <ICSChecklistTab onNextTabChange={onNextTabChange} changedMode={modeChange}/> )} */}

        { panelIndex === 4 && ( <SummaryTab changedMode={modeChange} calDraftPropData={calDraft} /> )}
        

        <CustomSnakebar alertType={showAlert} alertMessage={alertMessage} handleClose={()=>handleClose()}/>

      </div>
    </>
  );
}

