import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { UserDetails } from '../../../interfaces/UserDetails';
import { type } from '../../../services/Constants';
import { dcsgetActiveUsers, getLoggedInUserDetails } from '../../../services/data/Helpers';
import ScrollToTop from '../../common/ScrollToTop';
import { ActiveUserRow } from './ActiveUserRow';

export const ActiveUsersNew= (): JSX.Element => {
  const { t } = useTranslation();
  const [activeUsers, setActiveUsers] = useState<Array<UserDetails>>([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState("");
  const [reload, setReload] = useState("");
  const [usersCount, setUsersCount] = useState(0);
  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetActiveUsers()
     if(apiResponse.status  === type.SUCCESS){
      const userDetails =  getLoggedInUserDetails();
        const activeUsers = apiResponse.response as Array<UserDetails>;
        setActiveUsers(activeUsers);

        const sameOriginUsers = activeUsers.filter( x => x.origin.id === userDetails.origin.id);
        setUsersCount(sameOriginUsers.length);
     }
    };
    init();
  }, []);

  useEffect(() => {
    
  }, [reload]);

  const handleSuccessClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return false;
    }
    setSaveSuccess(false);
  };

  const refreshData = () => {
    const initRefresh = async () => {
      const apiResponse = await dcsgetActiveUsers()
      if(apiResponse.status  === type.SUCCESS){
        const userDetails =  getLoggedInUserDetails();
          const activeUsers = apiResponse.response as Array<UserDetails>;
          setActiveUsers(activeUsers);
  
          const sameOriginUsers = activeUsers.filter( x => x.origin.id === userDetails.origin.id);
          setUsersCount(sameOriginUsers.length);
       }
    };
    initRefresh();
  };

  const showMessage = (variant: string, msg: string) => {
    if(variant === "success") {
      setSaveSuccess(true);
      setSaveSuccessMsg(msg)
    }
  }
  
  return(
    <>
      <div className="container-variable">
        <ScrollToTop />
        <PorscheFont className="dcs-section-heading"style={{marginTop:"14px"}}>{t('Active Users')}</PorscheFont>
      </div>
      <div className="container-variable">
      {
        activeUsers.map((user, i) => {
          return (
            <ActiveUserRow  
              refreshData={refreshData}  
              user={user} 
              showMessage={(v, m) => showMessage(v, m)}
              loggedInOriginUsersCount={usersCount}
            />  
          );
        })
      }
      </div>
    </>
  );
}