import { UserDetailsDTO } from '../interfaces/User';
import { AuthService } from './AuthService';
import { dcsgetUserWithPermissions } from './data/Helpers';
import { type } from './Constants';
import {Constants} from "../configs/Constants";

let idToken = "";
let authService = new AuthService();
let userDetails = {} as UserDetailsDTO;

export const Auth = {
  getIdToken: async () => {
    const token = sessionStorage.getItem("access_token");
    const idToken = "";
    if(token) {
      return token;
    } else {
      await authService.getUser().then(async (user) => {
        if (user) {
          const idToken = user.id_token;
          sessionStorage.setItem("access_token", idToken);
        } else {
          let url = `${Constants.domain}`;
          window.open(url, "_self");
        }
      });

      return idToken;
    }
  },

  login: async () => {
    await authService.getUser().then(async (user) => {
      if (user) {
        idToken = user.access_token;
        sessionStorage.setItem("access_token", idToken);

        const apiResponse = await dcsgetUserWithPermissions()
        if(apiResponse.status===type.SUCCESS){
          userDetails = apiResponse.response
          sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
        }
      } else {
        idToken = "";
      }
    });
  },

  logout: async () => {
    localStorage.clear();
    sessionStorage.clear();
    idToken = "";
    await authService.logout();

  },

  isLoggedIn: () => {
    const token = sessionStorage.getItem("access_token");
    return token ? true : false;
  },

  getUserDetails: async () => {
    const sessionUser = sessionStorage.getItem("userDetails");
    if(sessionUser) {
      const parseUser = JSON.parse(sessionUser);
      userDetails = parseUser as UserDetailsDTO;
    } else {
      await authService.getUser().then(async(user) => {
        if (user) {
          sessionStorage.setItem("access_token", user.id_token);
          const apiResponse = await dcsgetUserWithPermissions()
          if(apiResponse.status===type.SUCCESS){
            userDetails = apiResponse.response 
            sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
          } 
        } else {
          return null;
        }
      });
    }
    return userDetails;
  },

  updateUserDetails: (userDetails: UserDetailsDTO) => {
    return userDetails;
  }
  
};
