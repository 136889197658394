//export const API_DEV_URL = "http://13.233.57.102:8080/api/v1";

// export const API_DEV_URL = "http://3.70.177.18:8080/api/v1";
export const API_DEV_URL = "http://3.70.106.40:8080/api/v1";
export const API_DEV_URL2 = "https://prod.porsche-dcs.cloud/api/v2";
//export const API_DEV_URL = "https://demo.porsche-dcs.ient.io/api/v1";

//export const API_DEV_URL = "http://localhost:8080/api/v1";

//export const API_DEV_URL = "http://192.168.0.62:8080/api/v1";

// Cognito interation
//export const API_DEV_URL = "https://b47a-223-196-198-216.in.ngrok.io/api/v1";

export const IMG_PATH = "../..";

export class Constants {
    public static domain = "https://ppnlite.porsche.com/";
}


export const Constant  = {
    Savedsuccessfully: 'Saved successfully',
    Error:'error',
    Success:'success',
    None:'none',
    NoScalingErrorMsg:'Scaling is not assigned for this user, Please contact the admin.',
    SingleOrdDetail:"SingleOrderDetail"
}
