import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PDivider, PFlex, PFlexItem, PSwitch, PTable,
  PTableBody, PTableCell, PTableHead, PTableHeadCell, PTableRow, PTextFieldWrapper,
  SwitchChangeEvent
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import {
  TransferPickupRegistrationContext
} from '../../../../contexts/TransferPickupRegistrationContext';
import { TransferPickupRegistration } from '../../../../interfaces/TransferPickupRegistration';
import {
  formatCurrencyToDecimal, formatCurrencyWithNoUnit, onBlurCurrencyFormatter, validateCurrency
} from '../../../../mock/helper';
import { type } from '../../../../services/Constants';
import { dcsgetTransferPickupRegistrationData, dcsgetVat } from '../../../../services/data/Helpers';
import ScrollToTop from '../../../common/ScrollToTop';

interface Props {
  edit: boolean;
  changedMode: string;
  modified: () => void;
  showHeader:() => void;
  removeHeader:()=> void; 
  setShowSaveButton:React.Dispatch<React.SetStateAction<boolean>>
}
export const TransferPickupRegistrationTab = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { edit, modified,showHeader,removeHeader, setShowSaveButton} = props;
  const [transferPickupRegistration, setTransferPickupRegistration] =
  useState<TransferPickupRegistration>({} as TransferPickupRegistration);
  const [reload, setReload] = useState("");
  const transferPickupRegistrationContext = useContext(TransferPickupRegistrationContext);
  const [vat, setVat] = useState(0);
  const [errorState, setErrorState] = useState<Record<string,boolean>>()


  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetVat()
      if(apiResponse.status===type.SUCCESS){
        const vatValue = apiResponse.response;
        setVat(vatValue?.value);
      }
      
  };
    init();
  }, []);
  useEffect(()=>{
    if(errorState){
    const isErrorPresent  = Object.values(errorState).includes(true)
    setShowSaveButton(!isErrorPresent)
  }
  },[errorState])
  
  useEffect(() => {
    transferPickupRegistrationContext.intialize(transferPickupRegistration);
  }, [reload]);
  
  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetTransferPickupRegistrationData()
      if(apiResponse.status===type.SUCCESS){
        const transferPickupRegistration = apiResponse.response;
        transferPickupRegistrationContext.intialize(transferPickupRegistration);
        setTransferPickupRegistration(transferPickupRegistration);
      }
         
    };
    init();
  }, []);

  const handlePriceBeforeCustomerNet = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    const dom = document.getElementById(`pbcn${id}`);
    const registrationsWrapperId = dom?.id as string

    if(validateCurrency(e.target.value)) {
      if(dom) {
        dom.setAttribute("state", "none");
        setErrorState((prev)=>{
          return {...prev, [registrationsWrapperId]:false}
        })
      }

      transferPickupRegistration?.registrations.map((registration) => {
        if (registration.id == id) {
          registration.priceBeforeCustomerNet = formatCurrencyToDecimal(e.target.value);
          registration.priceBeforeCustomerGross = registration.priceBeforeCustomerNet * (1 + vat/100);
          return true;
        }
      });

      modified();
      setReload(new Date().toLocaleString());
    } else {
      if(dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [registrationsWrapperId]:true}
        })
      }
    }
  };

  const handleNewCar = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    let dom = document.getElementById(`tnc${id}`);
    const newCarInputWrapperId = dom?.id as string

    if (validateCurrency(e.target.value)) {
      if(dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [newCarInputWrapperId]:false}
      })
      transferPickupRegistration?.transferPickups.map((transferpickup) => {
        if (transferpickup.id == id) {
          transferpickup.newCar = formatCurrencyToDecimal(e.target.value);
          return true;
        }
      });
      modified();
      setReload(new Date().toLocaleString());
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [newCarInputWrapperId]:true}
        })
        //dom.setAttribute("message", "Please enter avalid value.");
      }
    }
  };

  const handleDemoCarPresold = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    let dom = document.getElementById(`tdcp${id}`);
    const demopreCarInputWrapperId = dom?.id as string

    if (validateCurrency(e.target.value)) {
      if(dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [demopreCarInputWrapperId]:false}
      })
      transferPickupRegistration?.transferPickups.map((transferpickup) => {
        if (transferpickup.id == id) {
          transferpickup.demoCarPreSold = formatCurrencyToDecimal(e.target.value);
          return true;
        }
      });
      modified();
      setReload(new Date().toLocaleString());
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [demopreCarInputWrapperId]:true}
        })
        //dom.setAttribute("message", "Please enter avalid value.");
      }
    }
  };

  const handleDemoCarStock = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    let dom = document.getElementById(`tdcs${id}`);
    const demostockCarInputWrapperId = dom?.id as string

    if (validateCurrency(e.target.value)) {
      if(dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [demostockCarInputWrapperId]:false}
      })
      transferPickupRegistration?.transferPickups.map((transferpickup) => {
        if (transferpickup.id == id) {
          transferpickup.demoCarStock = formatCurrencyToDecimal(e.target.value);
          return true;
        }
      });
      modified();
      setReload(new Date().toLocaleString());
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [demostockCarInputWrapperId]:true}
        })
        //dom.setAttribute("message", "Please enter avalid value.");
      }
    }
  };

  const handleFactoryCarAndServiceLoaner= (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    let dom = document.getElementById(`tdfc${id}`);
    const factoryCarInputWrapperId = dom?.id as string

    if (validateCurrency(e.target.value)) {
      dom?.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [factoryCarInputWrapperId]:false}
      })
      transferPickupRegistration?.transferPickups.map((transferpickup) => {
        if (transferpickup.id == id) {
          transferpickup.factoryCarAndServiceLoaner = formatCurrencyToDecimal(e.target.value);
          return true;
        }
      });
      modified();
      setReload(new Date().toLocaleString());
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [factoryCarInputWrapperId]:true}
        })
        //dom.setAttribute("message", "Please enter a valid value.");
      }
    }
  };

  const handleFactoryPickUpLeipzig= (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    let dom = document.getElementById(`tfpul${id}`);
    const factoryPickUpLeipWrapperId = dom?.id as string

    if (validateCurrency(e.target.value)) {
      if(dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [factoryPickUpLeipWrapperId]:false}
      })
      transferPickupRegistration?.transferPickups.map((transferpickup) => {
        if (transferpickup.id == id) {
          transferpickup.factoryPickUpLeipzig = formatCurrencyToDecimal(e.target.value);
          return true;
        }
      });
      modified();
      setReload(new Date().toLocaleString());
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [factoryPickUpLeipWrapperId]:true}
        })
        //dom.setAttribute("message", "Please enter avalid value.");
      }
    }
  };

  const handleFactoryPickUpZuffenhausen= (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    let dom = document.getElementById(`tfpuz${id}`);
    const factoryPickUpZuffWrapperId = dom?.id as string

    if (validateCurrency(e.target.value)) {
      if(dom) dom.setAttribute("state", "none");
      setErrorState((prev)=>{
        return {...prev, [factoryPickUpZuffWrapperId]:false}
      })
      transferPickupRegistration?.transferPickups.map((transferpickup) => {
        if (transferpickup.id == id) {
          transferpickup.factoryPickUpZuffenhausen = formatCurrencyToDecimal(e.target.value);
          return true;
        }
      });
      modified();
      setReload(new Date().toLocaleString());
    } else {
      if (dom) {
        dom.setAttribute("state", "error");
        setErrorState((prev)=>{
          return {...prev, [factoryPickUpZuffWrapperId]:true}
        })
        //dom.setAttribute("message", "Please enter avalid value.");
      }
    }
  };

  const handleVisible = (
    e: CustomEvent<SwitchChangeEvent>,
    id: number
  ): void => {
   
    transferPickupRegistration?.registrations.map((registration) => {
      if (registration.id == id) {
        registration.visiable = e.detail.checked;
        return true;
      }
    });
    modified();
    setReload(new Date().toLocaleString());
  };
  
  return (
    <>
      <div className="trans" onMouseOver={props.removeHeader} onMouseOut={props.showHeader} style={{paddingTop: "30px"}}>
        <ScrollToTop />
        <PorscheFont className="trans-headding">{t("Transfer & Pickup")}</PorscheFont>
        <br />
        <div>
          <PTable>
            <PTableHead>
              <PTableHeadCell>
                <span style={{fontSize: "16px", fontWeight: "700" }}>{t('Transfer costs (net)')}</span> 
              </PTableHeadCell>
              {transferPickupRegistration?.transferPickups?.map((item, i) => {
                return (
                  <PTableHeadCell key={`h${i}`} style={{fontSize: "16px", fontWeight: "700", textAlign: "right", width: "300px", marginLeft:"-10px", marginRight:"-20px" }}>
                    <span style={{fontSize: "16px", fontWeight: "700" }}>{item.seriesDTO.seriesName}</span>
                  </PTableHeadCell>
                );
              })}
            </PTableHead>
            <PTableBody>
              <PTableRow>
                <PTableCell key={"nc"}>{t('New Car')}</PTableCell>
                { transferPickupRegistration?.transferPickups?.map((item, i) => {
                    return (
                      <PTableCell>
                        <PTextFieldWrapper id={`tnc${item.id}`} unit="€" unitPosition="suffix" style={{marginLeft:"-10px", marginRight:"-10px"}}>
                        {/* <PTextFieldWrapper id={`tnc${item.id}`} style={{marginLeft:"-10px", marginRight:"-10px"}}> */}
                            <input 
                              type="text" 
                              defaultValue={ formatCurrencyWithNoUnit(item.newCar) }
                              onChange={(e) => {
                                handleNewCar(e, item.id)
                                
                                //e.target.value = formatCurrencyWithNoUnit(formatCurrencyToDecimal(e.target.value))
                              }}
                              onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                              disabled={edit ? false : true} 
                              style={{textAlign:"right"}}
                            />
                          </PTextFieldWrapper> 
                      </PTableCell>
                    )
                  })
                }
              </PTableRow>
              <PTableRow>
                <PTableCell key={"nc"}>{t('Demo Car (pre-sold)')}</PTableCell>
                { transferPickupRegistration?.transferPickups?.map((item, i) => {
                    return (
                      <PTableCell >
                        <PTextFieldWrapper id={`tdcp${item.id}`} unit="€" unitPosition="suffix" style={{marginLeft:"-10px", marginRight:"-10px"}}>
                            <input 
                              type="text" 
                              defaultValue={ formatCurrencyWithNoUnit(item.demoCarPreSold) }
                              onChange={(e) => {
                                handleDemoCarPresold(e, item.id)
                                //e.target.value = formatCurrencyWithNoUnit(formatCurrencyToDecimal(e.target.value))
                              }}
                              onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                              disabled={edit ? false : true} 
                              style={{textAlign:"right"}}
                            />
                          </PTextFieldWrapper> 
                      </PTableCell>
                    )
                  })
                }
              </PTableRow>
              <PTableRow>
                <PTableCell key={"nc"}>{t("Demo Car (stock)")}</PTableCell>
                { transferPickupRegistration?.transferPickups?.map((item, i) => {
                    return (
                      <PTableCell>
                        <PTextFieldWrapper id={`tdcs${item.id}`} unit="€" unitPosition="suffix" style={{marginLeft:"-10px", marginRight:"-10px"}}>
                            <input 
                              type="text" 
                              disabled={edit ? false : true} 
                              defaultValue={ formatCurrencyWithNoUnit(item.demoCarStock) }
                              onChange={(e) => {
                                handleDemoCarStock(e, item.id)
                                //e.target.value = formatCurrencyWithNoUnit(formatCurrencyToDecimal(e.target.value))
                              }}
                              onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                              style={{textAlign:"right"}}
                            />
                          </PTextFieldWrapper> 
                      </PTableCell>
                    )
                  })
                }
              </PTableRow>
              <PTableRow>
                <PTableCell key={"fcsl"}>{t('Factory Car & Service Loaner')}</PTableCell>
                { transferPickupRegistration?.transferPickups?.map((item, i) => {
                    return (
                      <PTableCell>
                        <PTextFieldWrapper id={`tdfc${item.id}`} unit="€" unitPosition="suffix" style={{marginLeft:"-10px", marginRight:"-10px"}}>
                          <input 
                            type="text" 
                            disabled={edit ? false : true}
                            defaultValue={ formatCurrencyWithNoUnit(item.factoryCarAndServiceLoaner) }
                            onChange={(e) => {
                              handleFactoryCarAndServiceLoaner(e, item.id)
                              //e.target.value = formatCurrencyWithNoUnit(formatCurrencyToDecimal(e.target.value))
                            }}
                            onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                            style={{textAlign:"right"}}
                          />
                          </PTextFieldWrapper>    
                      </PTableCell>
                    )
                  })
                }
              </PTableRow>
            </PTableBody>
          </PTable>
        </div>
        <div style={{marginTop:"10px"}}>
          <PTable>
            <PTableHead>
              <PTableHeadCell>
                <span style={{fontSize: "16px", fontWeight: "700" }}>{t("Factory pickup (net)")}</span> 
              </PTableHeadCell>
              {transferPickupRegistration?.transferPickups?.map((item, i) => {
                return (
                  <PTableHeadCell key={`h${i}`} style={{fontSize: "16px", fontWeight: "700", textAlign: "right" }}>
                    <span style={{fontSize: "16px", fontWeight: "700" }}>{item.seriesDTO.seriesName}</span>
                  </PTableHeadCell>
                );
              })}
            </PTableHead>
            <PTableBody>
              <PTableRow className="pickup-table-parent">
                <PTableCell key={"zu"} className="pickup-table-content">Zuffenhausen</PTableCell>
                { transferPickupRegistration?.transferPickups?.map((item, i) => {
                  return(
                    <PTableCell>
                        <PTextFieldWrapper id={`tfpuz${item.id}`} unit="€" unitPosition="suffix" style={{marginLeft:"-10px", marginRight:"-10px"}}>
                          <input 
                            type="text" 
                            defaultValue={ formatCurrencyWithNoUnit(item.factoryPickUpZuffenhausen) }
                            onChange={(e) => {
                              handleFactoryPickUpZuffenhausen(e, item.id)
                              //e.target.value = formatCurrencyWithNoUnit(formatCurrencyToDecimal(e.target.value))
                            }}
                            onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                            disabled={edit ? false : true}
                            style={{textAlign:"right"}}
                          />  
                        </PTextFieldWrapper>
                    </PTableCell>
                  )
                })}
              </PTableRow>
              <PTableRow className="pickup-table-parent">
                <PTableCell key={"le"} className="pickup-table-content">Leipzig</PTableCell>
                { transferPickupRegistration?.transferPickups?.map((item, i) => {
                  return(
                    <PTableCell>
                        <PTextFieldWrapper id={`tfpul${item.id}`} unit="€" unitPosition="suffix" style={{marginLeft:"-10px", marginRight:"-10px"}}>
                          <input 
                            type="text" 
                            defaultValue={ formatCurrencyWithNoUnit(item.factoryPickUpLeipzig) }
                            onChange={(e) => {
                              handleFactoryPickUpLeipzig(e, item.id)
                              //e.target.value = formatCurrencyWithNoUnit(formatCurrencyToDecimal(e.target.value))
                            }} 
                            onBlur={(e)=>{onBlurCurrencyFormatter(e)}}
                            disabled={edit ? false : true}
                            style={{textAlign:"right"}}
                          />  
                        </PTextFieldWrapper>
                    </PTableCell>
                  )
                })}
              </PTableRow>
            </PTableBody>
          </PTable>
        </div>
      </div>

      {/* Registration block */}
      <div className="trans">
        <PTable>
          <PTableHead>
            <PTableRow>
              <PTableHeadCell style={{textAlign: "left"}}>
                <PorscheFont>
                  {t('Registration packages')}
                </PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell>
                <PorscheFont>
                  {t('Price before customer (net)')}
                </PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell>
                <PorscheFont>
                  {t('Price before customer (gross)')}
                </PorscheFont>
              </PTableHeadCell>
              <PTableHeadCell style={{textAlign: "center"}}>
                <PorscheFont>
                  {t("Visible")}
                  </PorscheFont>
                </PTableHeadCell>
            </PTableRow>
          </PTableHead>
          <PTableBody>
            {transferPickupRegistration?.registrations?.map((registration, i) => (
              <PTableRow key={i}>
                <PTableCell><PorscheFont>{t(registration.label)}</PorscheFont></PTableCell>
                <PTableCell>
                  <PTextFieldWrapper id={`pbcn${registration.id}`} unit="€" unitPosition="suffix" style={{ width: "200px" }}>
                    <input
                      type="text"
                      name="priceBeforeCustomerNet"
                      defaultValue={formatCurrencyWithNoUnit(registration.priceBeforeCustomerNet)}
                      onChange={(e) => handlePriceBeforeCustomerNet(e, registration.id)}
                      onBlur={(e) => onBlurCurrencyFormatter(e)}
                      disabled={!edit}
                      style={{ textAlign: "right" }}
                    />
                  </PTextFieldWrapper>
                </PTableCell>
                <PTableCell>
                  <PorscheFont style={{paddingLeft: "45px"}}>
                    {formatCurrencyWithNoUnit(registration.priceBeforeCustomerNet * (1 + vat / 100))} €
                  </PorscheFont>
                </PTableCell>
                <PTableCell style={{textAlign: "center"}}>
                  <PSwitch
                    style={{display: "flex", justifyContent:"center"}}
                    checked={registration.visiable}
                    onSwitchChange={(e) =>
                      handleVisible(e, registration.id)
                    }
                    disabled={edit ? false : true}
                    ></PSwitch>
                  </PTableCell>
              </PTableRow>
            ))}
          </PTableBody>
        </PTable>
      </div>
    </>
  );
};
