import { CommissionScalingDTO } from '../interfaces/commission';

export const defaultScaling ={

    name: "scaling1",
    activation: false,
    salaryTypeNewCar: 1118,
    salaryTypeDemoCar: 1121,
    salaryTypeUsedCar: 1119,
    salaryTypeIT1415: 1112,
    pfs: 13,
    leasings: 13,
    servicePlus: 13,
    carPolicy: 13,
    commissions: [
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: true,
            displayLabel: "New Car (Configured)",
            payOut2: false,
            commissionScalingCategoryType: "NewCarConfigured",
            category: {
                id: 1,
                label: "New Car (Configured)",
                type: "NewCar",
                option: "Configured",
            },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro",
            //customerType: "PrivateCustomer",
            //collection: "TradeIn"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                  
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: true,
            displayLabel: "New Car (Stock)",
            payOut2: false,
            commissionScalingCategoryType: "NewCarStock",
            // id: 3,
            category: {
                id: 2,
                label: "New Car (Stock)",
                type: "NewCar",
                option: "Stock",
            },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro",
            //customerType: "PrivateCustomer",
            //collection: "TradeIn"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                 
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: true,
            displayLabel: "New Car (Special case)",
            payOut2: false,
            commissionScalingCategoryType: "NewCarSpecialCase",
            // id: 3,
            category: {
                id: 3,
                label: "New Car (Special case)",
                type: "NewCar",
                option: "Other",
            },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro",
            customerType: "KeyAccount",
            //collection: "TradeIn"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                  
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: false,
            displayLabel: "Demo Car (Pre-sold)",
            payOut2: false,
            commissionScalingCategoryType: "DemoCarPreSold",
            // id: 3,
            category: {
                id: 6,
                label: "Demo Car (Pre-sold)",
                type: "DemoCar",
                option: "PreSold",
            },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro",
            //customerType: "KeyAccount",
            //collection: "TradeIn"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                  
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: false,
            displayLabel: "Demo Car (Stock)",
            payOut2: false,
            commissionScalingCategoryType: "DemoCarStock",
            // id: 3,
            category: {
                id: 4,
                label: "Demo Car (Stock)",
                type: "DemoCar",
                option: "Stock",
            },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro",
            //customerType: "KeyAccount",
            //collection: "TradeIn"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                 
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: false,
            displayLabel: "Used Car (Stock)",
            payOut2: false,
            commissionScalingCategoryType: "UsedCarStock",
            // id: 3,
            category: {
                id: 7,
                label: "Used Car (Stock)",
                type: "UsedCar",
                option: "Stock",
            },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro"
            //customerType: "KeyAccount",
            //collection: "TradeIn"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: false,
            displayLabel: "Used Car (Special case)",
            payOut2: false,
            commissionScalingCategoryType: "UsedCarSpecialCasePrivateCustomer",
            // id: 3,
            category: {
                id: 8,
                label: "Used Car (Special case)",
                type: "UsedCar",
                option: "Other",
            },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro",
            customerType: "Regular",
            collection: "LeasingTakeover"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
               
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: false,
            displayLabel: "Used Car (Special case)",
            payOut2: false,
            commissionScalingCategoryType: "UsedCarSpecialCaseDealer",
            // category: {
            //     id: 8,
            //     label: "Used Car (Special case)",
            //     type: "UsedCar",
            //     option: "Other",
            // },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro",
            customerType: "Dealer"
            //collection: "TradeIn"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                  
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: false,
            displayLabel: "Used Car (Special case)",
            payOut2: false,
            commissionScalingCategoryType: "UsedCarSpecialCaseDealerBiddingPlatform",
            // id: 3,
            // category: {
            //     id: 8,
            //     label: "Used Car (Special case)",
            //     type: "UsedCar",
            //     option: "Other",
            // },
            minCommissionValue: 0,
            minCommissionReference: "Euro",
            maxCommissionValue: 0,
            maxCommissionReference: "Euro",
            noProfitCommissionValue: 0.0,
            noProfitCommissionReference: "Euro",
            customerType: "DealerBiddingPlatform",
            collection: "LeasingReturn"
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                   
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: false,
            displayLabel: "Tires & Parts",
            payOut2: false,
            commissionScalingCategoryType: "CompleteWinterTires",
            // id: 3,
        },
        {
            variable: "Discount",
            vehicleCommissions: [
                {
                    fromValue: 0,
                    toValue: 0,
                    payoutValue1: 0,
                    payoutRefence1: "Euro",
                  
                    payoutValue2: 0,
                    payoutRefence2: "Euro"
                },
            ],
            minCommission: false,
            maxCommission: false,
            noProfitCommission: false,
            specialCase: false,
            displayLabel: "Tires & Parts",
            payOut2: false,
            commissionScalingCategoryType: "PDSTequipment",
        }
    ],
    // id: 3
} as unknown as CommissionScalingDTO
