import { useTranslation } from 'react-i18next';

import { PDivider, PTabs, PTabsItem } from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../assets/styles/CustomStyles';
import { Notifications } from './Notifications';
import { Tasks } from './Tasks';

interface Props {
  orderId: number,
  isDraft:boolean,
  open:boolean,
}

export const TasksAndNotifications = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { orderId, isDraft, open } = props;

  return (
    <>
      <div className='tn-layout'>
        <div>
          <PorscheFont className="dcs-section-heading" >{t('Tasks & Notifications')}</PorscheFont>
        </div>
        <div>
          <PTabs>
            <PTabsItem label={`${t("Tasks")}`}>
              <PDivider style={{height: "1px", background: "rgba(0, 0, 0, 0.25)", marginTop: "-8px"}}/>
              <Tasks orderId={orderId} isDraft={isDraft} open={open}/>
            </PTabsItem>
            <PTabsItem label={t('Notifications')}>
              <PDivider style={{height: "1px", background: "rgba(0, 0, 0, 0.25)", marginTop: "-8px"}}/>
              <Notifications orderId={orderId} isDraft={isDraft}/>
            </PTabsItem>
          </PTabs>
        </div>
      </div>
    </>
  );
};
