import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import {
  PDivider, PFlex, PFlexItem, PIcon
} from '@porsche-design-system/components-react';
import { PorscheFont } from '../../assets/styles/CustomStyles';
import {
  RecipientNotificationResponseDTO, TaskResponseDTO
} from '../../interfaces/TasksAndNotifications';
import { type } from '../../services/Constants';
import { dcsgetDashboardNotifications, dcsgetDashboardTasks } from '../../services/data/Helpers';
import { NotificationRow } from './NotificationRow';
import { TaskRow1 } from './TaskRow1';
import { fontWeight } from '@porsche-design-system/utilities';

export const Tasks = (): JSX.Element => {
  const { t } = useTranslation();
  const [showAllNotification, setShowAllNotification] = useState<boolean>(false);
  const [showPage, setShowPage] = useState<boolean>(false);
  const [tasks, setTasks] = useState<Array<TaskResponseDTO>>([]);
  const [taskCount,setTaskCount]=useState(0);
  //added below items
  const [notificationCount,setNotificationCount]=useState(0);
  const [notifications, setNotifications] = useState<Array<RecipientNotificationResponseDTO>>([]);
  let loggedInUserId = 1;
  const [showAll, setShowAll] = useState(true);
  const [showAllNotes, setShowAllNotes] = useState(true);
  const [reload, setReload] = useState("");

  const sessionUser = sessionStorage.getItem("userDetails");
  if(sessionUser) {
      const user = JSON.parse(sessionUser);
      loggedInUserId = user.id;
  }

  useEffect(() => {
    let sesUser = sessionStorage.getItem("userDetails");
    const init = async () => {
      const apiResponse = await dcsgetDashboardTasks(false)
      if(apiResponse.status===type.SUCCESS){
        setTaskCount(apiResponse.response?.count)
        setTasks(apiResponse.response?.tasks)
      } else {
        if(sesUser === null) {
          setReload(new Date().toLocaleString());
        }
      }
    };
    init();
  }, [reload]);
  
  
  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetDashboardNotifications(false)
      if(apiResponse.status===type.SUCCESS){
        setNotificationCount(apiResponse.response?.count)
        setNotifications(apiResponse.response?.notifications)
      }
    };
    init();
  }, [reload]);
  useEffect(() => {}, [reload, tasks])

  const handlePage = () => {
    setShowPage(showPage);
  }

  const showAllTasks = () => {
    const init = async () => {
     const apiResponse = await dcsgetDashboardTasks(showAll)
      if(apiResponse.status===type.SUCCESS){
        setTaskCount(apiResponse.response?.count)
        setTasks(apiResponse.response?.tasks)
      }
      setShowAll(!showAll)
    };
    init();
  };

  const showAllNotifications = () => {
    const init = async () => {
      const apiResponse = await dcsgetDashboardNotifications(showAllNotes)
      if(apiResponse.status===type.SUCCESS){
        setNotificationCount(apiResponse.response?.count)
        setNotifications(apiResponse.response?.notifications)
      }

      setShowAllNotes(!showAllNotes)
    };
    init();
  };

  const handleReload = () => {
    setReload(new Date().toLocaleString());
  }

  const handleDelete = () => {
    const init = async () => {
      const apiResponse = await dcsgetDashboardTasks(true)
      if(apiResponse.status===type.SUCCESS){
        setTaskCount(apiResponse.response?.count)
        setTasks(apiResponse.response?.tasks)
      }
    };
    init();
  }

  return (
    <>
      <div style={{marginRight:"30px", padding: "12px", backgroundColor: "#FFFFFF"}}>
        <div className="Task-headding">
          <PFlex justifyContent={"space-between"}>
            <PFlexItem>
              <PorscheFont>{t('Tasks')} </PorscheFont>
            </PFlexItem>
            <PFlexItem style={{ color: "#D5001B", fontWeight:"800" }}>
              <PorscheFont>{taskCount} </PorscheFont>
            </PFlexItem>
          </PFlex>
        </div>
        <PDivider color="neutral-contrast-high" />
        <div>
          {
            tasks.map((task,index)=>{
              if(task.status === "Pending") {
                return (<TaskRow1 task={task} index={index} handleDelete={handleDelete}/>);
              }
            })
          }
          <PFlex style={{cursor: "pointer", marginTop: "15px"}}>
            <PFlexItem onClick={showAllTasks}>
            <div>
              <Stack direction={'row'}>
                <PIcon name={showAll ? 'arrow-head-down' : 'arrow-head-up'}></PIcon>
                <PorscheFont style={{marginLeft: "5px"}}>{showAll ? t('Show all') : t('Show less')}</PorscheFont>
              </Stack>
            </div>
            </PFlexItem>
          </PFlex>
        </div>
      </div>

      <div style={{marginRight:"30px", marginTop: "10px", padding: "12px", backgroundColor: "#FFFFFF"}}>
        <div className="Task-headding">
          <PFlex>
            <PFlexItem style={{ width: "100%" }}>
              <PorscheFont>{t('Notifications')}</PorscheFont>
            </PFlexItem>
            <PFlexItem style={{ color: "#D5001B" , fontWeight:"800" }}>
              <PorscheFont>{notificationCount}</PorscheFont>
            </PFlexItem>
          </PFlex>
        </div>
        <PDivider color="neutral-contrast-high" />
        <div>
          {
            notifications.map((notificationdata,index)=>{
              return (
                <NotificationRow notificationDetails={notificationdata} handleReload={handleReload}/>
              );
            })
          }
          <PFlex style={{cursor: "pointer", marginTop: "15px"}}>
            <PFlexItem onClick={showAllNotifications} >
              {!showAllNotification && (
                <div>
                  <Stack direction={'row'} spacing={1}>
                    <PIcon name={showAllNotes ? 'arrow-head-down' : 'arrow-head-up'}></PIcon>
                    <PorscheFont style={{marginLeft: "5px"}}>{showAllNotes ? t('Show all') : t('Show less')}</PorscheFont>
                  </Stack>
                </div>
              )}
            </PFlexItem>
          </PFlex>
        </div>
      </div>
    </>
  );
}