import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import {
    PButtonGroup, PCheckboxWrapper, PGrid, PGridItem, PSelectWrapper
} from '@porsche-design-system/components-react';

import { PorscheFont } from '../../../../assets/styles/CustomStyles';
import { OrderContext } from '../../../../contexts/OrderContext';
import { CustomerType, DifferentAddressDTO, OrderDTO } from '../../../../interfaces/Order';
import { CustomTextField } from '../../../common/CustomTextField';

interface Props {
  customerTypes: Array<CustomerType>;
  enableNextButton: (isValid: boolean) => void;
  tradeInToggle: boolean;
  referralToggle: boolean;
  disabled: boolean;
  customerDetails?: OrderDTO["customerDetails"];
  handleModified: () => void;
  updateError: (id: string) => void;
}

const customerGroups = [
  {
    value: 'New Customer',
    selected: false
  },
  {
    value: 'Regular Customer',
    selected: false
  }
];

const customerGroupTypes = [
  {
    value: 'Private Customer',
    selected: false
  },
  {
    value: 'Business Client',
    selected: false
  }
]

export const Customer = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { customerTypes, enableNextButton, tradeInToggle, referralToggle, disabled, customerDetails, handleModified, updateError } = props
  const orderContext = useContext(OrderContext)
  const errors = orderContext.getErrors()
  const [deviantDriver, setDeviantDriver] = useState<boolean>(false)
  const [diffAddress, setDiffAddress] = useState<boolean>(false)

  const [differentAddress, setDifferentAddress] = useState<boolean>(false)

  const [confidential, setConfidential] = useState<boolean>(false)
  const [group, setCustomerGroup] = useState<string>("")
  const [groups, setCustomerGroups] = useState(customerGroups)
  const [groupType, setGroupType] = useState<string>("")
  const [groupTypes, setGroupTypes] = useState(customerGroupTypes)
  const [reload, setReload] = useState("")
  const [showMinCusDetails, setMinCusDetails] = useState<boolean>(false)
  const [showMaxCusDetails, setMaxCusDetails] = useState<boolean>(false)
  const [isHovering, setIsHovering] = useState(false)
  const [disabledClassName, setDisabledClassName] = useState("")
  const [customerType, setCustomerType] = useState(false);
  const [gross, setGross] = useState("");

  useEffect(() => {

  }, [group, groupType, errors, reload])

  useEffect(() => {
    if(customerDetails?.customerGroupType) {
      const gt = customerDetails?.customerGroupType.split(/(?=[A-Z])/).join(' ');
      setGroupType(gt)
      showCustomerDetails(gt)
    }

    if(customerDetails?.customerType) {
      const ct = customerDetails?.customerType.split(/(?=[A-Z])/).join(' ');
      if(ct !== "default") {
        setCustomerType(true);
      }
    }
    
    if (customerDetails?.deviantDriver) setDeviantDriver(customerDetails.deviantDriver)

    if (disabled) setDisabledClassName("disabled")
    else setDisabledClassName("")

    if(customerDetails?.confidential) setConfidential(true);
    if(customerDetails?.differentAddressFlag) {
      setDifferentAddress(true);
      setDiffAddress(true);
    }
    
  }, [customerDetails, disabled])

  useEffect(() => {
    orderContext.removeSectionError("vehicleDetails")
    orderContext.setErrors({ field: "customerGroup", message: "", section: "customerDetails" })
  }, [])

  useEffect(() => {
    let isValid = isValidCustomerDetailsSection()
    orderContext.updateFormValidation(0, isValid)
    enableNextButton(isValid)
  })

  const isValidCustomerDetailsSection = (): boolean => {
    const errors = orderContext.getErrors();
    return errors.every(error => {
      if (error.section === "vehicleDetails") return false
      if (error.section === "customerDetails") return false
      if (error.section === "paymentDetails") return false
      if (error.section === "deliveryDetails") return false
      if (tradeInToggle && error.section === "tradeInDetails") return false
      if (referralToggle && error.section === "referalDetails") return false

      return true
    })
  }

  const updateCustomerDetails = (customerDetails: OrderDTO["customerDetails"]) => {
    let orderDto = getOrderDetails()
    orderDto["customerDetails"] = customerDetails
    orderContext.updateCtxOrderDetails(orderDto)
  }

  const getOrderDetails = (): OrderDTO => {
    return orderContext.getCtxOrderDetails();
  }

  const getCustomerDetailsFromOrder = (orderDto: OrderDTO): OrderDTO["customerDetails"] => {
    return orderDto["customerDetails"] || {}
  }

  const handleDriver = () => {
    setDeviantDriver(!deviantDriver)
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails())
    customerDetails.deviantDriver = !deviantDriver
    updateCustomerDetails(customerDetails)
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const enableDiffAddress = () => {
    setDifferentAddress(!differentAddress);
    setDiffAddress(!diffAddress);
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    customerDetails.differentAddressFlag = !diffAddress;
    if(customerDetails.differentAddressFlag === false) {
      customerDetails.differentAddress = null;
    }
    updateCustomerDetails(customerDetails)
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const enableConfidential = () => {
    setConfidential(!confidential)
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails())
    customerDetails.confidential = !confidential
    if(customerDetails.confidential) {
      customerDetails.differentAddressFlag = false;
      customerDetails.differentAddress = null;
    }
    updateCustomerDetails(customerDetails)
    handleModified();
    setReload(new Date().toLocaleString());


    setDifferentAddress(!differentAddress);
    setDiffAddress(!diffAddress);
    let customerDetailsDiffAdd = getCustomerDetailsFromOrder(getOrderDetails());
    customerDetails.differentAddressFlag = !diffAddress;
    if(customerDetails.differentAddressFlag === false) {
      customerDetails.differentAddress = null;
    }
    updateCustomerDetails(customerDetailsDiffAdd)
  }

  const clearCustomerDetails = () => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    customerDetails.companyName = ''
    customerDetails.copNumber = ''
    customerDetails.deviantDriver = false
    customerDetails.driverFirstName = ''
    customerDetails.driverLastName = ''
    customerDetails.firstName = ''
    customerDetails.lastName = ''
    customerDetails.confidential = false;
    customerDetails.differentAddressFlag = false;
    updateCustomerDetails(customerDetails)
  }
  const handleCustomerType = (e: ChangeEvent<HTMLSelectElement>): void => {
    //clearCustomerDetails()
    let selectedCustomerType = e.target.value
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    showCustomerDetails(groupType);
    if(e.target.value === "default") {
      setCustomerType(false);
      customerDetails.customerType = null;
      customerDetails.companyName = null;
      customerDetails.firstName = null;
      customerDetails.lastName = null;
      customerDetails.copNumber  = null;
      updateCustomerDetails(customerDetails)
    } else {
      setCustomerType(true);
      customerDetails.customerType = selectedCustomerType;
      updateCustomerDetails(customerDetails)
      updateError("customerType");
    }
    
    handleModified();
    setReload(new Date().toLocaleString());
    //handleMandatoryFields(selectedCustomerType)
  }

  const showCustomerDetails = (customerGroupType: string) => {
    if (customerGroupType === "") return
    //
    if (customerGroupType === "Private Customer") {
      setMinCusDetails(true)
      setMaxCusDetails(false)
    } else {
      setMaxCusDetails(true)
      setMinCusDetails(false)
    }
  }

  const handleMandatoryFields = (categoryId: string) => {
    if (categoryId === "PrivateCustomer" || categoryId === "VIPPress" || categoryId === "Multiplier" || categoryId === "Employee") {
      orderContext.setErrors({ field: "firstName", message: "", section: "customerDetails" })
      orderContext.setErrors({ field: "lastName", message: "", section: "customerDetails" })
      orderContext.setErrors({ field: "copNumber", message: "", section: "customerDetails" })
      orderContext.removeError("companyName")
    } else if (categoryId === "BusinessClient" || categoryId === "Dealer" || categoryId === "DealerBiddingPlatform" || categoryId === "KeyAccount" || categoryId === "Supplier") {
      orderContext.setErrors({ field: "firstName", message: "", section: "customerDetails" })
      orderContext.setErrors({ field: "lastName", message: "", section: "customerDetails" })
      orderContext.setErrors({ field: "copNumber", message: "", section: "customerDetails" })
      orderContext.setErrors({ field: "companyName", message: "", section: "customerDetails" })
    }
  }

  const handleCompanyName = (e: ChangeEvent<HTMLInputElement>): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails())
    if (e.target.value.length > 0) {
      customerDetails.companyName = e.target.value
      updateCustomerDetails(customerDetails)
      updateError("cusCompanyName");
      orderContext.removeError("companyName")
    } else {
      customerDetails.companyName = null;
      updateCustomerDetails(customerDetails)
      orderContext.setErrors({ field: "companyName", message: "", section: "customerDetails" })
    }
    handleModified();
    setReload(new Date().toLocaleString())
  }

  const handleFirstName = (e: ChangeEvent<HTMLInputElement>): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails())
    if (e.target.value.length > 0) {
      customerDetails.firstName = e.target.value
      updateCustomerDetails(customerDetails)
      updateError("cusFirstName");
      orderContext.removeError("firstName")
    } else {
      customerDetails.firstName = null
      updateCustomerDetails(customerDetails)
      orderContext.setErrors({ field: "firstName", message: "", section: "customerDetails" })
    }
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleLastName = (e: ChangeEvent<HTMLInputElement>): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails())
    if (e.target.value.length > 0) {
      customerDetails.lastName = e.target.value
      updateCustomerDetails(customerDetails)
      updateError("cusLastName");
      orderContext.removeError("lastName")
    } else {
      customerDetails.lastName = null
      updateCustomerDetails(customerDetails)
      orderContext.setErrors({ field: "lastName", message: "", section: "customerDetails" })
    }
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleCopNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails())
    if (e.target.value.length > 0) {
      customerDetails.copNumber = e.target.value
      updateCustomerDetails(customerDetails)
      orderContext.removeError("copNumber")
      updateError("copNumber");
    } else {
      customerDetails.copNumber = null;
      updateCustomerDetails(customerDetails);
      orderContext.setErrors({ field: "copNumber", message: "", section: "customerDetails" })
    }
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleDriverFirstName = (e: ChangeEvent<HTMLInputElement>): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    customerDetails.driverFirstName = e.target.value.length > 0 ?  e.target.value : null;
    updateCustomerDetails(customerDetails)
    updateError("driverFirstName");
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleDriverLastName = (e: ChangeEvent<HTMLInputElement>): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    customerDetails.driverLastName = e.target.value
    updateCustomerDetails(customerDetails)
    updateError("driverLastName");
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleCustomerGroup = (value: string) => {
    orderContext.removeError("vehicleDetails")
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    customerDetails.customerGroup = value.replace(/ +/g, "");
    updateCustomerDetails(customerDetails)
    setCustomerGroup(value)

    if (groups) {
      groups.map((v) => {
        if (v.value === value) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      })

      setCustomerGroups(groups);
      updateError("cusGroup");
      handleModified();
      setReload(new Date().toLocaleString());
      orderContext.removeError("customerGroup")
    }
  }

  const handleCustomerGroupType = (value: string) => {
    orderContext.removeError("vehicleDetails")
    //
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    customerDetails.customerGroupType = value.replace(/ +/g, "");
    updateCustomerDetails(customerDetails)
    showCustomerDetails(value);
    setGroupType(value)

    if (groupTypes) {
      groupTypes.map((v) => {
        if (v.value === value) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      })

      setGroupTypes(groupTypes);
      updateError("cusType");
      handleModified();
      setReload(new Date().toLocaleString());
      orderContext.removeError("customerGroupType")
    }
  }

  const handleStreet = (value: string): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    if(customerDetails.differentAddress) {
      customerDetails.differentAddress.street = value.length > 0 ? value : null;
    } else {
      customerDetails.differentAddress = {
        street: value.length > 0 ? value : null,
        houseNumber : null,
        postalCode : null,
        city : null,
      } as DifferentAddressDTO;
    }
    
    handleModified();
    updateCustomerDetails(customerDetails);
    if(value.length > 0) {
      updateError("dStreet");
    }
    setReload(new Date().toLocaleString());
  }

  const handleHouseNumber = (value: string): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    if(customerDetails.differentAddress) {
      customerDetails.differentAddress.houseNumber = value.length > 0 ? value : null;
    } else {
      customerDetails.differentAddress = {
        street: null,
        houseNumber : value.length > 0 ? value : null,
        postalCode : null,
        city : null,
      } as DifferentAddressDTO;
    }
    
    updateCustomerDetails(customerDetails)
    if(value.length > 0) {
      updateError("dHouseNumber");
    }
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handlePostalCode = (value: string): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    if(customerDetails.differentAddress) {
      customerDetails.differentAddress.postalCode = value.length > 0 ? value : null;
    } else {
      customerDetails.differentAddress = {
        street: null,
        houseNumber : null,
        postalCode : value.length > 0 ? value : null,
        city : null,
      } as DifferentAddressDTO;
    }
    
    updateCustomerDetails(customerDetails);
    if(value.length > 0) {
      updateError("dPostalCode");
    }
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleCity = (value: string): void => {
    let customerDetails = getCustomerDetailsFromOrder(getOrderDetails());
    if(customerDetails.differentAddress) {
      customerDetails.differentAddress.city = value.length > 0 ? value : null
    } else {
      customerDetails.differentAddress = {
        street: null,
        houseNumber : null,
        postalCode : null,
        city : value.length > 0 ? value : null,
      } as DifferentAddressDTO;
    }
    
    updateCustomerDetails(customerDetails);
    if(value.length > 0) {
      updateError("dCity");
    }
    handleModified();
    setReload(new Date().toLocaleString());
  }

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (groups) {
      groups.map((v) => {
        if (v.value === group) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      })

      setCustomerGroups(groups);
    }

    if (groupTypes) {
      groupTypes.map((v) => {
        if (v.value === groupType) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      })

      setGroupTypes(groupTypes);
    }
  }, [reload])

  const addClassName = (groupSelected: boolean): string => {
    let className = ""
    if (groupSelected && isHovering) {
      className = "btn-group btn-group-selected bg-salmon"
    }
    if (groupSelected && !isHovering) {
      className = "btn-group btn-group-selected"
    }

    if (!groupSelected && isHovering) {
      className = "btn-group bg-salmon"
    }

    if (!groupSelected && !isHovering) {
      className = "btn-group"
    }

    return className
  }

  return (
    <>
      <div style={{ backgroundColor: "white" }}> 
        <PorscheFont data-testid="customer" className="card-header">{t("Customer")}</PorscheFont>    
        <Stack direction={"row"}>
            <div style={{marginRight: "16px"}}>
              <PorscheFont><span>{t("Customer group")}<span style={{ color:"#D5001B" }}> *</span></span></PorscheFont>
              <PButtonGroup className="pt10">
                {
                  groups.map((group, index) => {
                    ////
                    if (customerDetails?.customerGroup && group.value === customerDetails?.customerGroup.split(/(?=[A-Z])/).join(' ')) group.selected = true
                    return (
                      <div
                        id={`customerGroup${index}`}
                        className={group.selected ? `btn-group btn-group-selected ${disabledClassName}` : ` customerGroup btn-group ${disabledClassName}`}
                        onClick={() => handleCustomerGroup(group.value)}
                        style={{fontFamily: "Porsche Next"}}
                      >
                        {t(group.value)}
                      </div>
                    )
                  })
                }
              </PButtonGroup>
              <div id="cusGroup" className={"series-model-error-hide"} style={{marginTop: "8px"}}><PorscheFont >{t("Please select customer group to proceed further")} </PorscheFont></div>
            </div>
            <div style={{marginRight: "12px"}}>
              <PorscheFont><span>{t("Customer Type")}<span style={{ color: "#D5001B" }}> *</span></span></PorscheFont>

              <PButtonGroup className="pt10">
                {
                  customerGroupTypes.map((group, index) => {
                    if (customerDetails?.customerGroupType && group.value === customerDetails?.customerGroupType.split(/(?=[A-Z])/).join(' ')) group.selected = true
                    return (
                      <div
                        id={`customerGroupType${index}`}
                        className={group.selected ? `btn-group btn-group-selected ${disabledClassName}` : ` customerGroup btn-group ${disabledClassName}`}
                        onClick={() => handleCustomerGroupType(group.value)}
                        style={{fontFamily: "Porsche Next"}}
                      >
                        {t(group.value)}
                      </div>
                    )
                  })
                }
              </PButtonGroup>
              <div id="cusType" className={"series-model-error-hide"} style={{marginTop: "8px"}}><PorscheFont >{t("Please select customer type to proceed further")} </PorscheFont></div>
            </div>
            { groupType.length > 0 &&
              (groupType === "Private Customer" || groupType === "Business Client") && (
                <div>
                  <PSelectWrapper id={"customerTypeWrap"} className="sel-customer-type" style={{marginTop: "34px"}} >
                    <select id={"customerType"}  name="category_id" onChange={(e) => handleCustomerType(e)} disabled={disabled} required>
                      <option key="default" value="default">{t("Select Customer Type")}</option>
                      {customerTypes?.map((category, index) => {
                        if (groupType === "Private Customer" && 
                          (category.second === "Dealer" || category.second === "DealerBiddingPlatform" ||
                          category.second === "KeyAccount" || category.second === "Supplier"
                          )
                        ) { return <></> }

                        if (groupType === "Business Client" && category.second === "Employee") { return <></> }

                        return <option key={index} value={category.second} selected={category.second === customerDetails?.customerType}>{t(category.first)}</option>
                      })
                      }
                    </select>
                  </PSelectWrapper>
                </div>
              )
            }
           </Stack>
     
          <PGrid>
          {showMinCusDetails && customerType && (
            <>
            <PGrid style={{paddingLeft:"16px", paddingRight:"16px"}}>
              <PGridItem size={4}>
                <CustomTextField id="cusFirstName" type="text" name="firstName" defaultValue={customerDetails?.firstName ? customerDetails?.firstName : ""} label={`${t("First name")}`}
                // onClick={(e) => handleFirstName(e)} 
                onChange={(e) => handleFirstName(e)} required={true} disabled={disabled} />
              </PGridItem>
              <PGridItem size={4}>
                <CustomTextField id="cusLastName" type="text" name="lastName" defaultValue={customerDetails?.lastName ? customerDetails?.lastName : ""} label={`${t("Last name")}`}
                // onClick={(e) => handleLastName(e)} 
                onChange={(e) => handleLastName(e)} required={true} disabled={disabled} />
              </PGridItem>
              <PGridItem size={4}>
                <CustomTextField id="copNumber" type="text" name="copNumber" defaultValue={customerDetails?.copNumber ? customerDetails?.copNumber : ""} label={`${t("C@P GP number")}`} 
                // onClick={(e) => handleCopNumber(e)} 
                onChange={(e) => handleCopNumber(e)} required={true} disabled={disabled} />
              </PGridItem>
            </PGrid>
            </>
          )}
          {showMaxCusDetails && customerType && (
            <>
              <PGrid style={{paddingLeft:"16px", paddingRight:"16px"}}>
              <PGridItem size={4}>
                <CustomTextField id="cusCompanyName" type="text" name="companyName" defaultValue={customerDetails?.companyName ? customerDetails?.companyName : "" }  label={`${t("Company name")}`} 
                // onClick={(e) => handleCompanyName(e)}
                 onChange={(e) => handleCompanyName(e)} required={true} disabled={disabled} />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField id="cusFirstName" type="text" name="firstName" defaultValue={customerDetails?.firstName ? customerDetails?.firstName: ""}  label={`${t("First name")}`}
                // onClick={(e) => handleFirstName(e)} 
                onChange={(e) => handleFirstName(e)} required={true} disabled={disabled} />
              </PGridItem>
              <PGridItem size={3}>
                <CustomTextField id="cusLastName" type="text" name="lastName" defaultValue={customerDetails?.lastName ? customerDetails?.lastName : ""} label={`${t("Last name")}`}
                // onClick={(e) => handleLastName(e)} 
                onChange={(e) => handleLastName(e)} required={true} disabled={disabled} />
              </PGridItem>
              <PGridItem size={2}>
                <CustomTextField id="copNumber" type="text" name="copNumber" defaultValue={customerDetails?.copNumber ? customerDetails?.copNumber : ""} label={`${t("C@P GP number")}`}
                // onClick={(e) => handleCopNumber(e)} 
                onChange={(e) => handleCopNumber(e)} required={true} disabled={disabled} />
              </PGridItem>
              </PGrid>
              <PGridItem size={12}>
                <div className="chkbox-deviant-driver">
                  <PCheckboxWrapper id="deviantDriverWrap" label={`${t("Deviant driver")}`} hideLabel={false}>
                    <input
                      id="deviantDriver"
                      type="checkbox"
                      name="deviantDriver"
                      checked={deviantDriver ? true : false}
                      onClick={handleDriver}
                      disabled={disabled}
                    />
                  </PCheckboxWrapper>
                </div>
              </PGridItem>

              {deviantDriver && (
                <>
                  <PGridItem size={2}>
                    <CustomTextField id="driverFirstName" type="text" name="driverFirstName" defaultValue={customerDetails?.driverFirstName ? customerDetails?.driverFirstName : ""} onChange={(e) => handleDriverFirstName(e)} label={`${t("First name")}`} required={true} disabled={disabled} />
                  </PGridItem>
                  <PGridItem size={3}>
                    <CustomTextField id="driverLastName" type="text" name="driverLastName" defaultValue={customerDetails?.driverLastName ? customerDetails?.driverLastName : ""} onChange={(e) => handleDriverLastName(e)} label={`${t("Last name")}`} required={true} disabled={disabled} />
                  </PGridItem>
                </>
              )
              }
              <PGridItem size={12}>
                <div className="chkbox-deviant-driver">
                  <PCheckboxWrapper id="confidentialWrap" label={`${t("Confidential")}`} hideLabel={false} >
                    <input
                      id="confidential"
                      type="checkbox"
                      name="confidential"
                      checked={confidential ? true : false}
                      onClick={enableConfidential}
                      disabled={disabled}
                    />
                  </PCheckboxWrapper>
                </div>
              </PGridItem>
              {confidential && (
                <>
                  <PGridItem size={12}>
                    <div className="chkbox-deviant-driver">
                      <PCheckboxWrapper id="differentAddressWrap" label={`${t("Different Address")}`} hideLabel={false} >
                        <input
                          id="differentAddress"
                          type="checkbox"
                          name="differentAddress"
                          checked={differentAddress ? true : false}
                          onClick={enableDiffAddress}
                          disabled={disabled}
                        />
                      </PCheckboxWrapper>
                    </div>
                  </PGridItem>
                  {diffAddress && (
                    <>
                      <PGridItem size={3}>
                        <CustomTextField id="dStreet" type="text" name="dStreet" defaultValue={customerDetails?.differentAddress?.street ? customerDetails?.differentAddress?.street : ""}  onChange={(e) => handleStreet(e.target.value)} label={`${t("Street")}`} required={true} disabled={disabled} />
                      </PGridItem>
                      <PGridItem size={3}>
                        <CustomTextField id="dHouseNumber" type="text" name="dHouseNumber" defaultValue={customerDetails?.differentAddress?.houseNumber ? customerDetails?.differentAddress?.houseNumber : ""} onChange={(e) => handleHouseNumber(e.target.value)} label={`${t("House number")}`} required={true} disabled={disabled} />
                      </PGridItem>
                      <PGridItem size={3}>
                        <CustomTextField id="dPostalCode" type="text" name="dPostalCode" defaultValue={customerDetails?.differentAddress?.postalCode ? customerDetails?.differentAddress?.postalCode : ""} onChange={(e) => handlePostalCode(e.target.value)} label={`${t("Postal code")}`} required={true} disabled={disabled} />
                      </PGridItem>
                      <PGridItem size={3}>
                        <CustomTextField id="dCity" type="text" name="dCity" defaultValue={customerDetails?.differentAddress?.city ? customerDetails?.differentAddress?.city : ""} onChange={(e) => handleCity(e.target.value)} label={`${t("City")}`} required={true} disabled={disabled} />
                      </PGridItem>
                    </>
                  )}
                </>
                )
              }
            </>
          )}
        </PGrid>
      </div>
    </>
  );
}
