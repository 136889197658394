import { Alert, Snackbar } from "@mui/material";
import { Constant } from "../../configs/Constants";
import { t } from "i18next";

type Props = {
  alertType: String;
  alertMessage: String;
  handleClose: () => void;
};

function CustomSnakebar({ alertMessage, alertType, handleClose }: Props) {
  return (
    <>
      {alertType === Constant.Success && (
        <Snackbar
          open={alertType === Constant.Success}
          style={{ zIndex: 999, marginTop: "50px" }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            {t(`${alertMessage}`)}.
          </Alert>
        </Snackbar>
      )}

      {alertType === Constant.Error && (
        <Snackbar
          open={alertType === Constant.Error}
          style={{ zIndex: 999, marginTop: "50px" }}
          message={alertMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {t(`${alertMessage}`)}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default CustomSnakebar;
